import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Row, Input, Button, Card } from "antd";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import MTable from "../utils/MTable";
import LeadDetails from "./component/LeadDetails";

import leadColumns from "./component/LeadColumns";

import { getAllLeads } from "./duck/LeadsActions";

const SocialMediaLead = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({});
  const [filter, setFilter] = useState({});
  const [boardKey, setBoardKey] = useState("");

  const { mLeads, leadMap } = useSelector(({ leads }) => {
    const leadMap = leads.leadMap;
    const mLeads = leads.leads[boardKey];

    return { leadMap, mLeads };
  });

  const loadMore = useCallback(
    (offset = 0, limit = 30) => {
      const params = {};
      if (filter.text) params.text = filter.text;

      setBoardKey(JSON.stringify({ ...params }));

      params.offset = offset;
      params.limit = limit;
      dispatch(getAllLeads({ ...params }));
    },
    [filter, dispatch]
  );

  useEffect(() => {
    if (!boardKey) loadMore();
  }, [boardKey]);

  const onSearch = useCallback(() => loadMore(0, 30), [loadMore]);
  const onReset = useCallback(() => {
    setFilter({});
    setBoardKey("");
  }, []);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value;

      setFilter((preState) => ({
        ...preState,
        [name]: value,
      }));
    },
    []
  );

  const handleModal = useCallback((show = false, leadId) => {
    show = typeof show === "boolean" && show;
    setState((preState) => ({ ...preState, visible: show, leadId }));
  }, []);

  const data = useMemo(() => {
    const data = [];

    if (Array.isArray(mLeads?.list)) {
      mLeads.list.forEach((id, index) => {
        const lead = leadMap[id];

        data.push({
          key: id,
          sn: ++index,
          name: lead?.name,
          email: lead?.email,
          mobile: lead?.phone,
          created_at: lead?.created_at,
          type: lead?.ad_id,
          action: <EyeOutlined onClick={() => handleModal(true, id)} />,
        });
      });
    }

    return data;
  }, [mLeads?.list, leadMap, handleModal]);

  const isSpin = useMemo(() => !mLeads?.list && mLeads?.loading, [mLeads]);

  return (
    <div className="mainMember">
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Social Media Leads</span>
      </Row>

      <Card title="Filter" size="small" bordered={false}>
        <Input
          size="middle"
          className="custom-input mr10"
          style={{ width: "25%", fontWeight: "500", marginRight: "15px" }}
          placeholder="Search Name, Email, Phone"
          prefix={<SearchOutlined />}
          value={filter.text}
          onChange={handleChange("text")}
          allowClear
        />

        <Button className="mr10" type="primary" onClick={onSearch}>
          Search
        </Button>

        <Button className="mr10" type="primary-outline" onClick={onReset}>
          Reset
        </Button>
      </Card>

      <MTable
        className="mt20"
        bordered={false}
        columns={leadColumns}
        dataSource={data}
        loading={isSpin}
      />

      <LeadDetails
        visible={state.visible}
        leadId={state.leadId}
        handleModal={handleModal}
      />
    </div>
  );
};

export default SocialMediaLead;
