import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Input, Row, Select, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { changeBookingRequest } from "../../booking/duck/BookingActions";
import CustomModal from "../../utils/CustomModal";
import { resourceTypes } from "../../utils/CommonFunctions";
import { SwapRightOutlined } from "@ant-design/icons";

const RequestDetails = (props) => {
  const { editable, visible, selectedBookingId, handleModal, selectBooking } =
    props;

  const prevProp = useRef();
  const dispatch = useDispatch();
  const selectedBooking = useSelector(
    (state) => state.booking?.bookingMap?.[selectedBookingId]
  );

  const [state, setState] = useState({});
  const [error, handleError] = useState({});

  const preload = useCallback(() => {
    let _start = moment(selectedBooking?.from);
    let _end = moment(selectedBooking?.to);
    let duration = moment.duration(_end.diff(_start));

    setState({
      name: selectedBooking?.name,
      email: selectedBooking?.email,
      number: selectedBooking?.number,
      from: selectedBooking?.from,
      to: selectedBooking?.to,
      duration:
        (selectedBooking?.type === "meeting_room" &&
          Math.round(duration?.asHours() * 10) / 10) ||
        null,
      capacity: selectedBooking?.capacity,
      resource_id: selectedBooking?.resource_id,
      type: selectedBooking?.type,
      booking_status: selectedBooking?.booking_status,
      booking_customer_remark: selectedBooking?.booking_customer_remark,
      booking_admin_remark: selectedBooking?.booking_admin_remark,
    });
  }, [selectedBooking]);

  useEffect(() => {
    if (prevProp?.current?.loading && !selectedBooking?.loading) {
      selectBooking(null);
      handleModal();
      handleError({});
    } else if (!prevProp?.current?.visible && visible) {
      preload();
      handleError({});
    }

    return () => {
      prevProp.current = { loading: selectedBooking?.loading, visible };
    };
  }, [handleModal, preload, selectBooking, selectedBooking?.loading, visible]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setState((pre) => ({
        ...pre,
        [name]: value,
      }));
      handleError({});
    },
    []
  );

  const {
    name,
    email,
    number,
    resource_id,
    type,
    from,
    to,
    capacity,
    duration,
    booking_status,
    booking_customer_remark,
    booking_admin_remark,
  } = state;

  const hasError = useCallback(() => {
    let { new_status, admin_remark } = state;
    let error = {};
    if (!new_status) {
      error.statusError = "Select approval status";
    } else if (new_status === "Rejected") {
      if (!admin_remark) {
        error.adminRemarkError = "Please enter your remark";
      }
    }

    handleError(error);
    return Object.keys(error).length;
  }, [state]);

  const onUpdate = useCallback(() => {
    if (!hasError() && selectedBooking?._id) {
      const { new_status, admin_remark } = state;

      const payload = {
        _id: selectedBooking?._id,
        booking_status: new_status,
      };
      if (admin_remark) payload.booking_admin_remark = admin_remark;
      dispatch(changeBookingRequest(payload));
    }
  }, [dispatch, hasError, selectedBooking?._id, state]);

  return (
    <CustomModal
      title={"Booking Request"}
      width={750}
      visible={visible}
      onCancel={handleModal}
      footer={
        <>
          <Button onClick={handleModal}>Close</Button>
          {editable && booking_status === "Assigned" && (
            <>
              <Button
                type="primary"
                onClick={onUpdate}
                loading={selectedBooking?.loading}
              >
                Submit
              </Button>
            </>
          )}
        </>
      }
    >
      <div
        className="--avatar mb15 mt15"
        style={{ width: "100%", backgroundColor: "#EEEDFD", fontWeight: 500 }}
      >
        Customer Information
      </div>

      {
        <Row align="middle">
          <Col span={6}>
            <label className="label">Full name</label>
          </Col>

          <Col span={18}>{name}</Col>
        </Row>
      }

      {
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Email</label>
          </Col>

          <Col span={18}>{email || "-"}</Col>
        </Row>
      }

      {
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Phone number</label>
          </Col>

          <Col span={18}>{number || "-"}</Col>
        </Row>
      }

      <div
        className="--avatar mb15 mt15"
        style={{ width: "100%", backgroundColor: "#EEEDFD", fontWeight: 500 }}
      >
        Booking Info
      </div>

      {type && (
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Resource</label>
          </Col>

          <Col span={18}>
            {resourceTypes?.[type]?.name} - {resource_id?.name}
          </Col>
        </Row>
      )}
      {type === "meeting_room" ? (
        <>
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">From</label>
            </Col>

            <Col span={18}>
              <span className="mr20">{moment(from).format("DD-MM-YYYY")}</span>
              <span>{moment(from).format("HH:mm")}</span>
            </Col>
          </Row>
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">Duration</label>
            </Col>

            <Col span={18}>
              <span>{duration} hours</span>
            </Col>
          </Row>
        </>
      ) : (
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Booking Date</label>
          </Col>

          <Col span={18}>
            <span>
              {moment(from).format("DD-MM-YYYY")}
              <SwapRightOutlined className="ml10 mr10" />
              {moment(to).format("DD-MM-YYYY")}
            </span>
          </Col>
        </Row>
      )}

      {
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Capacity</label>
          </Col>

          <Col span={18}>{capacity}</Col>
        </Row>
      }
      {
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Booking Status</label>
          </Col>

          <Col span={18}>
            <Tag>{booking_status}</Tag>
          </Col>
        </Row>
      }

      {(booking_customer_remark ||
        booking_admin_remark ||
        booking_status === "Assigned") && (
        <div
          className="--avatar mb15 mt15"
          style={{ width: "100%", backgroundColor: "#EEEDFD", fontWeight: 500 }}
        >
          Remarks
        </div>
      )}

      {booking_customer_remark && (
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Customer's Remark</label>
          </Col>

          <Col span={18}>{booking_customer_remark}</Col>
        </Row>
      )}

      {booking_status === "Assigned" && (
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Admin Approval</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col span={18}>
            <Select
              className="custom-select fw"
              size="large"
              placeholder="Select your approval"
              value={state?.new_status}
              onChange={handleChange("new_status")}
              allowClear
              disabled={!editable}
            >
              {[
                {
                  _id: "Approved",
                  value: "Approve",
                },
                {
                  _id: "Rejected",
                  value: "Reject",
                },
              ].map((item) => (
                <Select.Option key={item?._id} value={item?._id}>
                  {item?.value}
                </Select.Option>
              ))}
            </Select>
            <Row className="error mt5">{error?.statusError}</Row>
          </Col>
        </Row>
      )}

      {booking_admin_remark && (
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Admin's Remark</label>
          </Col>

          <Col span={18}>{selectedBooking?.booking_admin_remark} </Col>
        </Row>
      )}
      {state?.new_status === "Rejected" && (
        <Row align="top" className="mt10">
          <Col span={6}>
            <label className="label">Admin's Remark</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col span={18}>
            <Input.TextArea
              className="custom-input"
              placeholder="Add remark"
              allowClear
              value={state?.admin_remark}
              onChange={handleChange("admin_remark")}
              disabled={!editable}
            />

            <Row className="error mt5">{error?.adminRemarkError}</Row>
          </Col>
        </Row>
      )}
    </CustomModal>
  );
};

export default RequestDetails;
