import React from "react";
import { Button, Card, Col, Row, Typography, Tag } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { resourceTypes } from "../../utils/CommonFunctions";

const ResourcesItem = (props = {}) => {
  const { resource = {}, filter } = props;

  const mFilter = filter && JSON.parse(filter);
  const resource_type = mFilter?.type || "";
  const resType = resourceTypes[resource_type];
  const imageSrc = resType?.image;

  const amenities = resource?.amenities_id;
  const actual_price = resource?.actual_price?.net || 0;
  const dynamic_price = resource?.dynamic_price?.net || 0;
  const priceIncreased = dynamic_price - actual_price;
  const priceIncreasedPercent = (priceIncreased * 100) / actual_price || 0;

  return (
    <>
      <Col lg={24} xl={12} className="lr_pad_spacing">
        <Card
          bodyStyle={{ padding: 0 }}
          bordered={true}
          className="boxContainer"
        >
          <Row style={{ minHeight: 200 }}>
            <Col xs={24} sm={10}>
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src={imageSrc}
              />
            </Col>
            <Col
              xs={24} sm={14}
              className="p20 nw_c_mob_set"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {resource_type?.includes?.("desk") ? (
                <Col>
                  <p className="nw_c_cb">
                    {resType?.name}{" "}
                    {resource?.items.map(
                      (item, i) =>
                        `${item?.seat_no}${resource?.items?.length - 1 > i ? ", " : ""
                        }`
                    )}
                  </p>
                  {/* <Typography.Paragraph
                                        style={{ fontWeight: 400 }}
                                        ellipsis={true && { rows: 2, tooltip: resource?.description }}
                                    ><span style={{ fontWeight: "bold" }}>Area:</span> {resource?.name}</Typography.Paragraph> */}
                </Col>
              ) : (
                <Col>
                  <p className="nw_c_cb">
                    {resource?.name}
                  </p>
                  {/* <Typography.Paragraph
                                        ellipsis={true && { rows: 2, tooltip: resource?.description }}
                                        style={{ fontWeight: 400 }}
                                    >{resource?.description}</Typography.Paragraph> */}
                </Col>
              )}
              <Typography.Paragraph className="nw_mob_space"
                ellipsis={true && { rows: 1 }}
                style={{ fontWeight: 400, marginBottom: 0 }}
              >
                {amenities
                  ?.filter((a) => a?.active)
                  ?.map((a) => (
                    <Tag key={a?._id}>{a?.title}</Tag>
                  ))}
              </Typography.Paragraph>

              <Row align="middle" justify="space-between" className="nw_mob_con_full">
                <Row align="middle">
                  {!resource_type?.includes("desk") && (
                    <samp className="mr10 nw_cap_cb nw_font_bold_card">
                      <UserOutlined /> {resource?.capacity}
                    </samp>
                  )}

                  <samp className="mr10 nw_cap_cb nw_font_bold">
                    {`£ ${parseFloat(resource?.dynamic_price?.net).toFixed(2)}`}
                  </samp>
                </Row>

                <Button
                  type="primary" className="nw_btn_black ant-btn"
                  onClick={() => props.onBookNow?.(resource?._id)}
                  size="large"
                >
                  Book Now
                </Button>
              </Row>
            </Col>
          </Row>
          {resource?.actual_price?.net &&
            resource?.actual_price?.net !== resource?.dynamic_price?.net && (
              <Row style={{ backgroundColor: "#e2e2e2", padding: "3px 10px" }}>
                Due to high occupancy price has been increased by{" "}
                {parseFloat(priceIncreasedPercent || 0).toFixed(2)}%
              </Row>
            )}
        </Card>
      </Col>

    </>



  );
};

export default ResourcesItem;
