export const rolePermissions = {
  showAllBooking: false,
  showAllWorkSpaces: false,
  dashboard: {
    name: "Dashboard",
    show: false,
    editable: false,
  },
  resources: {
    name: "Resources",
    show: false,
    editable: false,
    meetingRooms: {
      name: "Meeting Rooms",
      show: false,
      editable: false,
    },
    privateOffices: {
      name: "Private Offices",
      show: false,
      editable: false,
    },
    desk: {
      name: "Desk Inventory",
      show: false,
      editable: false,
    },
  },
  members: {
    name: "Members",
    show: false,
    editable: false,
  },
  adminAndTeams: {
    name: "Admin & Teams",
    show: false,
    editable: false,
  },
  roles: {
    name: "Roles",
    show: false,
    editable: false,
  },
  booking: {
    name: "Booking",
    show: false,
    editable: false,
    booking: {
      name: "Booking",
      show: false,
      editable: false,
    },
    calendar: {
      name: "Calendar",
      show: false,
      editable: false,
    },
  },
  deskSwap: {
    name: "Desk Swap",
    show: false,
    editable: false,
  },
  bookingRequest: {
    name: "Booking Request",
    show: false,
    editable: false,
  },
  invoice: {
    name: "Invoice",
    show: false,
    editable: false,
  },
  xero: {
    name: "Xero",
    show: false,
    editable: false,
  },
  billing: {
    name: "Billing",
    show: false,
    editable: false,
  },
  userActivity: {
    name: "User Activity",
    show: false,
    editable: false,
  },
  plan: {
    name: "Plan",
    show: false,
    editable: false,
    flexiDesk: {
      name: "Fixed Desk",
      show: false,
      editable: false,
    },
    dedicatedDesk: {
      name: "Dedicated Desk",
      show: false,
      editable: false,
    },
    privateOffice: {
      name: "Private Office",
      show: false,
      editable: false,
    },
  },
  pricing: {
    name: "Pricing",
    show: false,
    editable: false,
    flexiDesk: {
      name: "Flexi Desk",
      show: false,
      editable: false,
    },
    dedicatedDesk: {
      name: "Dedicated Desk",
      show: false,
      editable: false,
    },
  },
  door: {
    name: "Door",
    show: false,
    editable: false,
  },
  mail: {
    name: "Mail",
    show: false,
    editable: false,
  },
  contactUs: {
    name: "Contact Us",
    show: false,
    editable: false,
  },
  socialMediaLead: {
    name: "Social Media Lead",
    show: false,
    editable: false,
  },
  cms: {
    name: "Website CMS",
    show: false,
    editable: false,
  },
  settings: {
    name: "Settings",
    show: false,
    editable: false,
    workspace: {
      name: "Workspace",
      show: false,
      editable: false,
    },
    amenities: {
      name: "Amenities",
      show: false,
      editable: false,
    },
  },
};
