import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Input, Switch, Button, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { editRole, getOneRole, addRole } from "../duck/RoleActions";
import { useHistory } from "react-router";
import { rolePermissions } from "../../config/RolePermissions";
import SectionItem from "./SectionItem";
import { checkPermission } from "../../utils/CommonFunctions";
import Spinner from "../../spinner/Spinner";
import Routes from "../../config/Routes";

const EditRole = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const preProps = useRef();
  const roleId = new URLSearchParams(history?.location?.search).get("id");

  let editable = checkPermission({ row: "roles" });
  const allRoles = useSelector((state) => state?.role);
  const { loading, error } = allRoles;

  let role = allRoles?.roles[roleId];

  const [state, setState] = useState({
    name: "",
    nameErr: null,
    active: true,
    show: false,
    showAllBooking: false,
    showAllWorkSpaces: false,
    permissions: JSON.parse(JSON.stringify(rolePermissions)),
  });

  const preload = () => {
    let show = true;
    let permissions = Object.assign(
      {},
      rolePermissions,
      JSON.parse(JSON.stringify(role?.permissions))
    );

    Object.keys(permissions).map((sKey) => {
      let section = permissions[sKey];
      if (
        (typeof section === "boolean" && section === "show") ||
        section === "editable"
      ) {
        show = show && section;
      } else if (typeof section === "object") {
        Object.keys(section).map((ssKey) => {
          if (
            (typeof section[ssKey] === "boolean" && ssKey === "show") ||
            ssKey === "editable"
          ) {
            show = show && section[ssKey];
          } else if (typeof section[ssKey] === "object") {
            Object.keys(section[ssKey]).map((k) => {
              if (
                (typeof section[ssKey][k] === "boolean" && k === "show") ||
                k === "editable"
              ) {
                show = show && section[ssKey][k];
              }
            });
          }
        });
      }
    });

    setState({
      name: role?.name,
      active: role?.active,
      show: show,
      permissions: permissions,
      showAllBooking: permissions?.showAllBooking,
      showAllWorkSpaces: permissions?.showAllWorkSpaces,
    });
  };

  useEffect(() => {
    if (roleId && !role) dispatch(getOneRole({ _id: roleId }));
    if (preProps?.current?.loading && !loading) {
      if (!error) {
        history.goBack();
      }
    }
    if (role && !loading) preload();

    return () => {
      preProps.current = { loading };
    };
  }, [role, roleId, loading]);

  const {
    name,
    nameErr,
    active,
    permissions,
    show,
    showAllBooking,
    showAllWorkSpaces,
  } = state;

  const addOrEditRole = () => {
    let updatedData = {
      name,
      ...(roleId && { _id: role?._id }),
      active,
      permissions,
    };
    if (!name) {
      setState({
        ...state,
        nameErr: "Role Name is Required",
      });
    } else {
      roleId ? dispatch(editRole(updatedData)) : dispatch(addRole(updatedData));
    }
  };

  const handleAllSectionChange = (value) => {
    let { permissions } = state;

    Object.keys(permissions).map((sKey) => {
      let section = permissions[sKey];

      if (typeof section === "object") {
        Object.keys(section).map((ssKey) => {
          if (typeof section[ssKey] === "boolean") {
            section[ssKey] = value;
          } else if (typeof section[ssKey] === "object") {
            Object.keys(section[ssKey]).map((k) => {
              if (typeof section[ssKey][k] === "boolean") {
                section[ssKey][k] = value;
              }
            });
          }
        });
      }
    });

    setState((preState) => ({
      ...preState,
      show: value,
      permissions: permissions,
    }));
  };
  const handleShowAllBooking = (value) => {
    setState((preState) => ({
      ...preState,
      showAllBooking: value,
      permissions: { ...permissions, showAllBooking: value },
    }));
  };

  const handleShowAllWorkSpaces = (value) => {
    setState((preState) => ({
      ...preState,
      showAllWorkSpaces: value,
      permissions: { ...permissions, showAllWorkSpaces: value },
    }));
  };

  const handleSectionChange = (name) => (value) => {
    let { permissions } = state;
    let show = true;

    permissions = { ...permissions, [name]: value };

    Object.keys(permissions).forEach((sKey) => {
      let section = permissions[sKey];
      if (
        (typeof section === "boolean" && section === "show") ||
        section === "editable"
      ) {
        show = show && section;
      } else if (typeof section === "object") {
        Object.keys(section).map((ssKey) => {
          if (
            (typeof section[ssKey] === "boolean" && ssKey === "show") ||
            ssKey === "editable"
          ) {
            show = show && section[ssKey];
          } else if (typeof section[ssKey] === "object") {
            Object.keys(section[ssKey]).map((k) => {
              if (
                (typeof section[ssKey][k] === "boolean" && k === "show") ||
                k === "editable"
              ) {
                show = show && section[ssKey][k];
              }
            });
          }
        });
      }
    });

    setState((preState) => ({
      ...preState,
      show: show,
      permissions: permissions,
    }));
  };

  return roleId && !role ? (
    <Spinner />
  ) : (
    <div className="mainMember">
      <Row justify="space-between" align="middle" className="mb20">
        <span>
          <h2 style={{ fontSize: 22, fontWeight: 600, marginBottom: 0 }}>
            {roleId ? "Edit Role" : "Add Role"}
          </h2>
        </span>
        <span>
          {editable && (
            <Button type="primary" loading={loading} onClick={addOrEditRole}>
              Save Role
            </Button>
          )}
          <Button
            className="ml10"
            disabled={loading}
            onClick={() => history.replace(Routes.roles)}
          >
            <ArrowLeftOutlined />
            Back
          </Button>
        </span>
      </Row>
      <div
        style={{
          background: "#fff",
          borderRadius: "5px",
          marginTop: "15px",
          padding: "50px 20px 20px",
        }}
      >
        <Col>
          <Row className="mb15">
            <Col span={4}>
              <label className="label mt10">Role Name</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col span={6}>
              <Input
                className="custom-input"
                type="text"
                value={name}
                disabled={loading || !editable}
                onChange={(e) =>
                  setState({
                    ...state,
                    name: e?.target?.value,
                    nameErr: null,
                  })
                }
                required
              />
              {<Row className="error mt5">{nameErr}</Row>}
            </Col>
          </Row>

          <Row className="mb15">
            <Col span={4}>
              <span style={{ fontSize: 15, fontWeight: 500 }}>Permission:</span>
            </Col>
            <Col span={18}>
              <Switch
                disabled={loading || !editable}
                checked={show}
                onChange={handleAllSectionChange}
              />
              <span className="ml15" style={{ fontSize: 15, fontWeight: 500 }}>
                Allow All
              </span>
            </Col>
          </Row>

          <Row className="mb15">
            <Col span={4}>
              <span style={{ fontSize: 15, fontWeight: 500 }}>Active:</span>
            </Col>
            <Col span={18}>
              <Switch
                checked={active}
                disabled={loading || !editable}
                onChange={(e) => setState({ ...state, active: e })}
              />
            </Col>
          </Row>

          <Row className="mb15">
            <Col span={4}>
              <span style={{ fontSize: 15, fontWeight: 500 }}>
                Show All Booking:
              </span>
            </Col>
            <Col span={18}>
              <Switch
                disabled={loading || !editable}
                checked={showAllBooking}
                onChange={handleShowAllBooking}
              />
            </Col>
          </Row>
          <Row className="mb15">
            <Col span={4}>
              <span style={{ fontSize: 15, fontWeight: 500 }}>
                Show All Work Spaces:
              </span>
            </Col>
            <Col span={18}>
              <Switch
                disabled={loading || !editable}
                checked={showAllWorkSpaces}
                onChange={handleShowAllWorkSpaces}
              />
            </Col>
          </Row>
        </Col>

        <Card title="Permission to role" bordered={false}>
          <table className="customTable">
            {Object.keys(permissions).map(
              (sName) =>
                typeof permissions[sName] === "object" && (
                  <SectionItem
                    key={sName}
                    section={permissions[sName]}
                    handleChange={handleSectionChange(sName)}
                    disabled={loading || !editable}
                    // active={permissions?.show}
                  />
                )
            )}
          </table>
        </Card>
      </div>
    </div>
  );
};

export default EditRole;
