import React, { useState } from "react";
import { Col, Row, Card } from "antd";
import { useSelector } from "react-redux";

import AddNewCard from "./components/AddNewCard";
import BlankCard from "./components/BlankCard";
import StripeCardDetails from "./components/StripeCardDetails";
import Invoices from "./components/Invoices";
import Spinner from "../spinner/Spinner";

import { checkPermission } from "../utils/CommonFunctions";

const BillingComponent = () => {
  const [show, setShow] = useState(false);

  const { sourceList, loading } = useSelector((state) => state.billing);

  const editable = checkPermission({ row: "billing" });

  const billingSpin = !sourceList.length && loading;

  return billingSpin ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Card Details</span>
      </Row>

      <Row gutter={16} style={{ marginBottom: "35px" }}>
        <Col span={24}>
          <Card
            bordered={false}
            style={{ width: "100%", overflow: "auto", borderRadius: 4 }}
            className="nw_table_container">
            <Row>
              <BlankCard handleModal={() => editable && setShow(!show)} />
              {sourceList?.map((sourceId, i) => (
                <StripeCardDetails key={i} sourceId={sourceId} index={i} />
              ))}
            </Row>
          </Card>
        </Col >
      </Row >


      <Row gutter={16}>
        <Col span={24}>
          <Card
            bordered={false}
            style={{ width: "100%", overflow: "auto", borderRadius: 4 }}
            className="nw_table_container">
            <Invoices />
          </Card>
        </Col>
      </Row>


      {show && (
        <AddNewCard visible={show} handleModal={() => setShow(!show)} />
      )}

    </div>
  );
};

export default BillingComponent;
