import React from "react";
import { Modal } from "antd";

const CustomModal = (props) => {
  return (
    <Modal
      className={props.className}
      style={props.style}
      centered
      title={props?.title}
      visible={props?.visible}
      okText={props.okButtonText || "Ok"}
      cancelText={props.cancelButtonText || "Cancel"}
      onCancel={props.onCancel}
      maskClosable={false}
      onOk={props.handleSuccess}
      confirmLoading={props.loading}
      okButtonProps={props?.okButtonProps}
      footer={props.footer}
      width={props.width || "45%"}
    >
      {props.children}
    </Modal>
  );
};

export default CustomModal;
