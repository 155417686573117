import { useEffect, useState } from "react";
import { Row, Button, Col, Popconfirm, Input } from "antd";
import AddAmenities from "./components/AddAmenities";
import { useDispatch, useSelector } from "react-redux";
import { deleteAmenity } from "./duck/AmenitiesActions";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { escapeRegex, checkPermission } from "../utils/CommonFunctions";
import Spinner from "../spinner/Spinner";
import { fetchAllAmenities } from "./duck/AmenitiesActions";

const Amenities = () => {
  const [visible, showModal] = useState(false);
  const [selectedAmenity, selectAmenity] = useState(null);
  const [search, toggleSearch] = useState(false);
  const [searchValue, handleSearch] = useState("");
  const allAmenities = useSelector((state) => state?.amenities);
  let amenities = allAmenities?.amenities;

  const amenitiesSpin = !amenities.length && allAmenities?.loading;

  const dispatch = useDispatch();

  let editable = checkPermission({ row: "settings", subRow: "amenities" });

  useEffect(() => {
    !amenities.length && dispatch(fetchAllAmenities());
  }, []);

  const toggleModal = (val) => {
    showModal(val);
    selectAmenity(null);
  };

  if (searchValue) {
    const regExp = new RegExp(escapeRegex(searchValue), "i");
    amenities = amenities.filter((amenity) => regExp.test(amenity?.title));
  }

  return amenitiesSpin ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Amenities</span>
        <span>
          <Input
            size="middle"
            onFocus={() => toggleSearch(true)}
            onBlur={() => toggleSearch(false)}
            className="custom-input mr10 white"
            style={{ width: search ? 220 : 120 }}
            placeholder="Search..."
            prefix={<SearchOutlined />}
            value={searchValue}
            onChange={(e) => handleSearch(e?.target?.value)}
          />
          {editable && (
            <Button type="primary-outline" onClick={() => showModal(true)}>
              + Add Amenity
            </Button>
          )}
        </span>
      </Row>

      <Row>
        {amenities?.map((res) => (
          <Row
            className="mr10 mb10"
            key={res?._id}
            style={{
              backgroundColor: "#fff",
              borderRadius: 4,
              width: 280,
              padding: "10px 15px",
            }}
          >
            <Col span={18}>{res?.title}</Col>

            <Col
              span={6}
              style={{
                textAlign: "right",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <span className="--avatar green small circle mr5">
                {editable ? (
                  <EditOutlined
                    onClick={() => {
                      selectAmenity(res);
                      showModal(true);
                    }}
                  />
                ) : (
                  <EyeOutlined
                    onClick={() => {
                      selectAmenity(res);
                      showModal(true);
                    }}
                  />
                )}
              </span>
              {editable && (
                <span className="--avatar red small circle">
                  <Popconfirm
                    title="Are you sure to delete this?"
                    onConfirm={() => dispatch(deleteAmenity(res?._id))}
                    okText="Yes"
                    cancelText="No"
                    placement="bottom"
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </span>
              )}
            </Col>
          </Row>
        ))}
      </Row>

      <AddAmenities
        visible={visible}
        showModal={toggleModal}
        selectedAmenity={selectedAmenity || null}
        selectAmenity={selectAmenity}
        editable={editable}
      />
    </div>
  );
};

export default Amenities;
