import { Component } from "react";
import { Row, Col, Result, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { connect } from "react-redux";
import WebLayoutContainer from "./component/WebLayoutContainer";
import { getAllWorkSpaceRequest } from "../workspace/duck/WorkSpaceActions";
import { getAllAvailableResources } from "../booking/duck/BookingActions";

import SelectResourceScreen from "./component/screens/SelectResourceScreen";
import SelectCapacityScreen from "./component/screens/SelectCapacityScreen";
import SelectDateTimeScreen from "./component/screens/SelectDateTimeScreen";

import SearchResourceHeader from "./component/search/SearchResourceHeader";
import Timelinedata from "./component/screens/Timelinedata";
import ResourcesItem from "./component/ResourcesItem";
import PlanResourcesItem from "./component/PlanResourcesItem";
import { formatDateTime } from "../utils/CommonFunctions";
import Routes from "../config/Routes";
import Spinner from "../spinner/Spinner";

class SearchWorkspace extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: "",
      showDatePicker: false,
      workspacePopover: false,
      resourcePopover: false,
      capacityPopover: false,
    };
  }

  setBookingObj = (params) => {
    const space_id = params?.spaceId;
    const resource_type = params?.resource_type || "";
    const capacity = (params?.capacity && Number(params?.capacity)) || "";
    const from_to_date = params?.from_date &&
      params?.to_date && [moment(params?.from_date), moment(params?.to_date)];

    return {
      space_id,
      resource_type,
      capacity,
      from_to_date,
    };
  };

  componentDidMount() {
    this.setState({ ...this.setBookingObj(this.props.params) });

    const { getAllWorkSpaceRequest } = this.props;
    getAllWorkSpaceRequest && getAllWorkSpaceRequest();
  }

  componentDidUpdate(prevProps) {
    const { params = {}, getAllAvailableResources, activeSpace } = this.props;
    const { spaceId, resource_type, capacity, from_date, to_date } = params;

    let newState = {
      space_id: spaceId,
      resource_type: resource_type || "",
      capacity: (capacity && Number(capacity)) || "",
      from_date: "",
      from_time: "",
      from_to_date: [],
    };

    newState.from_to_date =
      from_date &&
      to_date &&
      moment(from_date).isValid() &&
      moment(to_date).isValid()
        ? [moment(from_date), moment(to_date)]
        : [];

    if (JSON.stringify(prevProps?.params) !== JSON.stringify(params)) {
      this.setState(newState);
    }

    // call to api to fetch all the available resources
    if (
      newState?.space_id &&
      newState?.resource_type &&
      newState?.capacity &&
      activeSpace
    ) {
      let startDateTime;
      let endDateTime;

      if (!newState?.from_to_date || newState?.from_to_date?.length <= 0)
        return;

      const office_hours_start = activeSpace?.office_hours?.[0];
      const office_hours_end = activeSpace?.office_hours?.[1];

      startDateTime = moment(newState?.from_to_date[0]).set({
        hour: moment(office_hours_start).format("HH"),
        minute: moment(office_hours_start).format("mm"),
      });
      endDateTime = moment(newState?.from_to_date[1]).set({
        hour: moment(office_hours_end).format("HH"),
        minute: moment(office_hours_end).format("mm"),
      });

      let availableResParams = {
        from: startDateTime.utc().toDate(),
        to: endDateTime.utc().toDate(),
        capacity: newState?.capacity,
        space_id: newState?.space_id,
        type: newState?.resource_type,
      };

      let filter = JSON.stringify(availableResParams);
      if (filter && filter !== this.state.filter) {
        this.setState({
          filter: filter,
        });

        getAllAvailableResources(availableResParams);
      }
    } else {
      if (this.state.filter) {
        this.setState({
          filter: undefined,
        });
      }
    }
  }

  handleClickOutside = (e) => {
    const ref = document.getElementsByClassName(`ant-picker-dropdown-range`)[0];
    if (ref && !ref.contains(e.target)) {
      this.setState({
        showDatePicker: false,
      });
    }
  };
  updateUrlParameter = (url, param, value) => {
    var regex = new RegExp("(?<=[?|&])(" + param + "=)[^&]+", "i");
    return url.replace(regex, param + "=" + value);
  };

  changeWorkspace = (event) => {
    let cUrl = this.props.history.location.search;
    let newUrl = this.updateUrlParameter(cUrl, "spaceId", event);
    this.props.history.push({
      pathname: Routes.homeSearch,
      search: newUrl,
    });
    this.setState({
      space_id: event,
    });
  };
  // confirm modal
  confirmChange = (event) => {
    Modal.confirm({
      title: "Confirm change",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to change workspace ",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => this.changeWorkspace(event),
    });
  };

  handleChange = (name) => (event) => {
    let value = event?.target?.value ?? event;
    if (name === "workspace") {
      this.confirmChange(event);
    } else {
      this.setState({
        [name]: value,
        resourceTypeError: "",
        capacityError: "",
        fromDateTimeError: "",
        durationError: "",
        fromToDateError: "",
        resourceError: "",
        memberError: "",
      });
    }
  };

  onFindWorkspace = (id) => {
    let { activeWorkspace, history } = this.props;
    if (id !== activeWorkspace) {
      this.setState({ space_id: id });
      history.push(`${Routes.homeSearch}?spaceId=${id}`);
    }
    this.setState({ workspacePopover: false });
  };

  onSearch = () => {
    const { history } = this.props;
    let { space_id, from_to_date, resource_type, capacity } = this.state;

    // call to api to fetch all the available resources
    if (resource_type && from_to_date?.length === 2 && capacity > 0) {
      let search = `spaceId=${space_id}`;
      search = `${search}&resource_type=${resource_type}`;
      search = `${search}&capacity=${capacity}`;
      search = `${search}&from_date=${formatDateTime(from_to_date[0], "d")}`;
      search = `${search}&to_date=${formatDateTime(from_to_date[1], "d")}`;
      history.push(`${Routes.homeSearch}?${search}`);
    }
  };

  onBookNow = (resource_id, plan_id) => {
    let { params, history } = this.props;
    const { filter } = this.state;
    let exp_timestamp = new Date();
    exp_timestamp.setMinutes(exp_timestamp.getMinutes() + 30);

    let bookResource = {
      ...this.setBookingObj(params),
      exp_timestamp: exp_timestamp,
      resource_id,
      plan_id,
      filter,
    };

    localStorage.setItem("bookResource", JSON.stringify(bookResource));
    history.push(`${Routes.payNow}/new_booking`);
  };

  onNext = (search) => {
    let { history } = this.props;
    search && history.push(`${Routes.homeSearch}${search}`);
  };

  onBack = () => {
    let { history } = this.props;
    history?.goBack();
  };

  render() {
    const { availableResources } = this.props;

    const { space_id, resource_type, capacity, from_to_date, filter } =
      this.state;

    const mAvailableResources = availableResources?.[filter];

    return (
      <WebLayoutContainer showFooter={!!filter}>
        <SearchResourceHeader
          workspace={space_id}
          resource_type={resource_type}
          capacity={capacity}
          from_to_date={from_to_date}
          filter={filter}
          onSearch={this.onSearch}
          handleChange={this.handleChange}
        />

        <Col className="container">
          {!filter && (
            <>
              {!resource_type && (
                <SelectResourceScreen
                  onNext={this.onNext}
                  onBack={this.onBack}
                />
              )}

              {resource_type && !from_to_date?.length && (
                <SelectDateTimeScreen
                  onNext={this.onNext}
                  onBack={this.onBack}
                  resource_type={resource_type}
                />
              )}

              {resource_type && from_to_date?.length && !capacity && (
                <SelectCapacityScreen
                  onNext={this.onNext}
                  onBack={this.onBack}
                  resource_type={resource_type}
                />
              )}
            </>
          )}

          {filter &&
            (mAvailableResources?.loading ? (
              <Spinner />
            ) : (
              <>
                {mAvailableResources?.list?.length === 0 && (
                  <div className="containerCenter">
                    <div className="inner_c_b_m">
                      <div style={{ fontSize: 22, fontWeight: 600 }}>
                        Unfortunately there are no resources available at this
                        moment
                      </div>
                      <div className="pt10">
                        Try searching with other parameters that meet your
                        expectations
                      </div>
                      <Result status="404" />
                    </div>
                  </div>
                )}
              </>
            ))}
          {filter && mAvailableResources?.list?.length > 0 && (
            <Col span={24} className="spacerLR_con">
              <Row className="title_container">
                <Col span={24}>
                  You are viewing
                  <span style={{ margin: "0 4px" }}>
                    {mAvailableResources?.list?.length}
                  </span>
                  resource(s)
                </Col>
              </Row>
            </Col>
          )}
          <Row></Row>
          <Row>
            {mAvailableResources?.list?.map((resource, index) => (
              <ResourcesItem
                key={index}
                resource={resource}
                filter={filter}
                onBookNow={this.onBookNow}
              />
            ))}
          </Row>

          {mAvailableResources?.list?.length > 0 &&
            !!mAvailableResources?.list?.find(
              (resource) => resource?.plan?.length > 0
            ) && (
              <Row
                className="mb20"
                style={{ fontSize: 16, fontWeight: 600, marginTop: 30 }}
              >
                Recommended Plan
              </Row>
            )}

          <Row>
            {mAvailableResources?.list?.map((resource, index) => (
              <PlanResourcesItem
                key={index}
                resource={resource}
                filter={filter}
                onBookNow={this.onBookNow}
              />
            ))}
          </Row>
        </Col>

        {!filter && (
          <Timelinedata
            resource_type={resource_type}
            capacity={capacity}
            from_to_date={from_to_date}
          />
        )}
      </WebLayoutContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { location } = ownProps;
  let { search } = location;
  let searchString = search?.replace && search.replace("?", "");
  let params = {};

  let { active, workSpaceList, workSpaceMap } = state.workSpace;
  let workSpaces = workSpaceList?.map((id) => ({
    id,
    name: workSpaceMap[id]?.name,
    pictures: workSpaceMap[id]?.pictures,
  }));

  searchString.split("&").map((ss) => {
    let param = ss.split("=");
    params[param[0]] = param[1];
  });

  return {
    activeWorkspace: params?.spaceId,
    workSpaces: workSpaces,
    params: params,
    availableResources: state?.booking?.availableResources,
    activeSpace: workSpaceMap && workSpaceMap[params?.spaceId],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllWorkSpaceRequest: () => dispatch(getAllWorkSpaceRequest()),
    getAllAvailableResources: (obj) => dispatch(getAllAvailableResources(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchWorkspace);
