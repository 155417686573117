import Actions from "./BillingActionTypes";

export const addCardStart = (params = {}) => ({
  type: Actions.ADD_CARD,
  payload: params,
});

export const fetchAllCard = () => ({
  type: Actions.FETCH_ALL_CARD,
});

export const setDefaultCard = (params = {}) => ({
  type: Actions.SET_DEFAULT_CARD,
  payload: params,
});

export const deleteCard = (params = {}) => ({
  type: Actions.DELETE_CARD,
  payload: params,
});
