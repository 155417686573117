const Actions = {
  FETCH_ALL_ADMINS: "FETCH_ALL_ADMINS",
  FETCH_ALL_ADMINS_SUCCESS: "FETCH_ALL_ADMINS_SUCCESS",
  FETCH_ALL_ADMINS_FAILURE: "FETCH_ALL_ADMINS_FAILURE",

  ADD_ADMIN: "ADD_ADMIN",
  ADD_ADMIN_SUCCESS: "ADD_ADMIN_SUCCESS",
  ADD_ADMIN_FAILURE: "ADD_ADMIN_FAILURE",

  UPDATE_ADMIN: "UPDATE_ADMIN",
  UPDATE_ADMIN_SUCCESS: "UPDATE_ADMIN_SUCCESS",
  UPDATE_ADMIN_FAILURE: "UPDATE_ADMIN_FAILURE",
};

export default Actions;
