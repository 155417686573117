import Actions from "./AdminActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";

const initialState = {
  adminMap: {},
  admins: {},
};

const adminReducer = (state = initialState, action) => {
  let payload = Object.assign({}, action?.payload);

  switch (action.type) {
    /** fetch all admins start */

    case Actions.FETCH_ALL_ADMINS: {
      delete payload?.offset;
      delete payload?.limit;
      const mFilter = JSON.stringify(Object.assign({}, payload));
      return Object.assign({}, state, {
        admins: Object.assign({}, state.admins, {
          [mFilter]: Object.assign({}, state.admins?.[mFilter], {
            error: null,
            loading: true,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_ADMINS_SUCCESS: {
      const mFilter = payload?.filter;
      const mAdminAndTeamsBoard = state.admins?.[mFilter];

      let list = payload?.isReset ? [] : mAdminAndTeamsBoard?.list || [];
      list = [...(list || []), ...getArrOrder(payload?.data)];

      return Object.assign({}, state, {
        adminMap: Object.assign({}, state.adminMap, arrToObjMap(payload?.data)),
        admins: Object.assign({}, state.admins, {
          [mFilter]: Object.assign({}, mAdminAndTeamsBoard, {
            list: [...new Set(list)],
            meta: Object.assign({}, mAdminAndTeamsBoard?.meta, payload?.meta),
            loading: false,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_ADMINS_FAILURE: {
      const mFilter = JSON.stringify(Object.assign({}, payload));
      return Object.assign({}, state, {
        admins: Object.assign({}, state.admins, {
          [mFilter]: Object.assign({}, state.admins[mFilter], {
            error: payload,
            loading: false,
          }),
        }),
      });
    }

    /** fetch all admins end */

    /** add admin start */

    case Actions.ADD_ADMIN: {
      return Object.assign({}, state, {
        error: null,
        adding: true,
      });
    }

    case Actions.ADD_ADMIN_SUCCESS: {
      const mAdmin = payload?.data;
      const mFilter = payload?.filter;

      const mAdminAndTeamsBoard = state.admins?.[mFilter] || {};
      let list = [...(mAdminAndTeamsBoard?.list || [])];

      return Object.assign({}, state, {
        adminMap: Object.assign({}, state.adminMap, {
          [mAdmin?._id]: mAdmin,
        }),
        admins: Object.assign({}, state.admins, {
          [mFilter]: {
            list: [...new Set([mAdmin?._id, ...list])],
            meta: Object.assign({}, mAdminAndTeamsBoard?.meta, {
              totalCount: (mAdminAndTeamsBoard?.meta?.totalCount || 0) + 1,
            }),
          },
        }),
        adding: false,
      });
    }

    case Actions.ADD_ADMIN_FAILURE: {
      return Object.assign({}, state, {
        error: action?.payload,
        adding: false,
      });
    }

    /** add admin end */

    /** update admin start */
    case Actions.UPDATE_ADMIN: {
      return Object.assign({}, state, {
        error: null,
        loading: true,
      });
    }

    case Actions.UPDATE_ADMIN_SUCCESS: {
      let updatedAdmin = payload?.data;

      return Object.assign({}, state, {
        adminMap: Object.assign({}, state.adminMap, {
          [updatedAdmin?._id]: updatedAdmin,
        }),

        loading: false,
      });
    }

    case Actions.UPDATE_ADMIN_FAILURE: {
      return Object.assign({}, state, {
        error: action?.payload,
        loading: false,
      });
    }

    /** update admin end */

    default:
      return state;
  }
};

export default adminReducer;
