import { put, takeEvery, takeLatest, call } from "redux-saga/effects";
import { checkPermission, showNotification } from "../../utils/CommonFunctions";
import ACTIONS from "./BookingActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const getAllBookingResourcesRequest = (params = {}) => {
  return axios({
    method: "GET",
    url: AppUrl.BOOKING,
    params: params,
  });
};

const getBookedResourceRequest = (data) => {
  return axios({
    method: "GET",
    url: `${AppUrl.BOOKING}/${data?._id}`,
  });
};

const bookingResourceRequest = (data) => {
  return axios({
    method: "POST",
    url: AppUrl.BOOKING,
    data,
  });
};

const payChargeRequest = (data) => {
  return axios({
    method: "POST",
    url: AppUrl.CHARGE_SOURCE,
    data,
  });
};

const cancelBookingRequest = (obj) => {
  return axios({
    method: "DELETE",
    url: `${AppUrl.BOOKING}/cancel/${obj?._id}`,
  });
};

const updateBookingRequest = (data) => {
  return axios({
    method: "PUT",
    url: `${AppUrl.RESCHEDULE_BOOKING}/${data?._id}`,
    data,
  });
};
const updateBookingDragRequest = (data) => {
  if (data.start && data.end) {
    data.from = data.start;
    data.to = data.end;
  }
  return axios({
    method: "PUT",
    url: `${AppUrl.RESCHEDULE_BOOKING}/${data?._id}`,
    data,
  });
};
const refreshTokenRequest = (data) => {
  return axios({
    method: "PUT",
    url: `${AppUrl.SET_TOKEN}`,
    data,
  });
};

const bookResourceWithoutPayRequest = (data) => {
  return axios({
    method: "POST",
    url: AppUrl.BOOKING_RESOURCE_WITHOUT_PAY,
    data,
  });
};

const changeBookedResourceRequest = (data) => {
  return axios({
    method: "PUT",
    url: `${AppUrl.CHANGE_BOOKED_RESOURCE}/${data?._id}`,
    data,
  });
};
const changeBookingRequest = (data) => {
  return axios({
    method: "PUT",
    url: `${AppUrl.CHANGE_BOOKING_REQUEST}/${data?._id}`,
    data,
  });
};

const changeBookedResourceApproveRequest = (data) => {
  return axios({
    method: "PUT",
    url: `${AppUrl.CHANGE_BOOKED_RESOURCE_APPROVE}/${data?._id}`,
    data,
  });
};

function* getAllBookingResources(action) {
  try {
    let resources = yield call(getAllBookingResourcesRequest, action.payload);
    let _data = resources?.data;

    delete action.payload?.offset;
    delete action.payload?.limit;
    _data.filter = JSON.stringify(action.payload);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.FETCH_ALL_BOOKING_RESOURCES_FAILURE,
        payload: _data,
      });
    } else {
      yield put({
        type: ACTIONS.FETCH_ALL_BOOKING_RESOURCES_SUCCESS,
        payload: _data,
      });
    }
  } catch (error) {
    console.log("get all booking resources error", error);
  }
}

function* getBookedResource(action) {
  try {
    let resources = yield call(getBookedResourceRequest, action.payload);
    let _data = resources?.data;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.FETCH_BOOKING_RESOURCES_FAILURE,
        payload: _data,
      });
    } else {
      yield put({
        type: ACTIONS.FETCH_BOOKING_RESOURCES_SUCCESS,
        payload: _data,
      });
    }
  } catch (error) {
    console.log("get one booking resources error", error);
  }
}

function* bookingResource(action) {
  try {
    const isShowNotification = action.payload?.isShowNotification;
    delete action?.payload?.isShowNotification;
    let resources = yield call(bookingResourceRequest, action.payload);
    let _data = resources?.data;

    const showAllBooking = checkPermission({ initial: "showAllBooking" });

    let params = {};
    if (showAllBooking) {
      const mBooking = _data?.data;
      const mSpaceId = mBooking?.space_id?._id || mBooking?.space_id;
      params.space_id = mSpaceId;
    }
    _data.filter = JSON.stringify(params);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.BOOK_RESOURCE_FAILURE, payload: _data });
    } else {
      if (isShowNotification) {
        showNotification("success", "Booking successfully done");
      }
      yield put({ type: ACTIONS.BOOK_RESOURCE_SUCCESS, payload: _data });
    }
  } catch (error) {
    console.log("booking resources error", error);
  }
}

function* payCharge(action) {
  try {
    let resources = yield call(payChargeRequest, action.payload);
    let _data = Object.assign({}, resources?.data, action.payload);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.PAY_CHARGE_FAILURE, payload: _data });
    } else {
      showNotification("success", "Payment successfully done");
      yield put({ type: ACTIONS.PAY_CHARGE_SUCCESS, payload: _data });
    }
  } catch (error) {
    console.log("booking resources error", error);
  }
}

const getAllAvailableResourcesRequest = (params = {}) => {
  if (params.start && params.end) {
    params.from = params.start;
    params.to = params.end;
  }
  return axios({
    method: "GET",
    url: `${AppUrl.WORKSPACE}/${params.space_id}/resources-availability`,
    params: params,
  });
};

function* getAllAvailableResources(action) {
  try {
    let resources = yield call(getAllAvailableResourcesRequest, action.payload);
    let _data = resources?.data;
    _data.filter = { ...(action.payload || {}) };
    delete _data?.filter?.offset;
    delete _data?.filter?.limit;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      // showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.FETCH_ALL_AVAILABLE_RESOURCES_FAILURE,
        payload: _data,
      });
    } else {
      yield put({
        type: ACTIONS.FETCH_ALL_AVAILABLE_RESOURCES_SUCCESS,
        payload: _data,
      });
    }
  } catch (error) {
    console.log("get all booking resources error", error);
  }
}

function* cancelBooking(action) {
  try {
    let booking = yield call(cancelBookingRequest, action.payload);
    let _data = booking?.data;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.CANCEL_BOOKING_FAILURE, payload: _data });
    } else {
      showNotification("success", "Booking cancelled successfully");
      yield put({
        type: ACTIONS.CANCEL_BOOKING_SUCCESS,
        payload: _data,
      });
    }
  } catch (error) {
    console.log("There is an error in booking cancellation", error);
  }
}

function* updateBooking(action) {
  try {
    let booking = yield call(updateBookingRequest, action?.payload);
    let _data = booking?.data;
    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      _data._id = action?.payload?._id;
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.UPDATE_BOOKING_FAILURE, payload: _data });
    } else {
      showNotification("success", "Booking updated successfully");
      yield put({ type: ACTIONS.UPDATE_BOOKING_SUCCESS, payload: _data });
    }
  } catch (error) {
    console.log("There is an error in booking updation", error);
  }
}

function* updateDragBooking(action) {
  try {
    let booking = yield call(updateBookingDragRequest, action?.payload);
    let _data = booking?.data;
    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      _data._id = action?.payload?._id;
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.UPDATE_BOOKING_FAILURE, payload: _data });
    } else {
      showNotification("success", "Booking updated successfully");
      yield put({ type: ACTIONS.UPDATE_BOOKING_SUCCESS, payload: _data });
    }
  } catch (error) {
    console.log("There is an error in booking updation", error);
  }
}
function* bookResourceWithoutPay(action) {
  try {
    let resources = yield call(bookResourceWithoutPayRequest, action.payload);
    let _data = resources?.data;

    const showAllBooking = checkPermission({ initial: "showAllBooking" });

    const params = {
      booking_request: true,
    };
    if (showAllBooking) {
      const mBooking = _data?.data;
      const mSpaceId = mBooking?.space_id?._id || mBooking?.space_id;
      params.space_id = mSpaceId;
    }
    _data.filter = JSON.stringify(params);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.BOOK_RESOURCE_FAILURE, payload: _data });
    } else {
      showNotification("success", "Booking successfully done");
      yield put({ type: ACTIONS.BOOK_RESOURCE_SUCCESS, payload: _data });
    }
  } catch (error) {
    console.log("booking resources error", error);
  }
}

function* changeBookedResource(action) {
  try {
    let booking = yield call(changeBookedResourceRequest, action?.payload);
    let _data = booking?.data;
    _data._id = action?.payload?._id;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.CHANGE_BOOKED_RESOURCE_FAILURE,
        payload: _data,
      });
    } else {
      showNotification("success", "Raise change resource request successfully");
      yield put({
        type: ACTIONS.CHANGE_BOOKED_RESOURCE_SUCCESS,
        payload: _data,
      });
    }
  } catch (error) {
    console.log("There is an error in changed booked resource ", error);
    showNotification("error", "Something went wrong!");
    yield put({ type: ACTIONS.CHANGE_BOOKED_RESOURCE_FAILURE, payload: error });
  }
}
function* changeBooking(action) {
  try {
    let booking = yield call(changeBookingRequest, action?.payload);
    let _data = booking?.data;
    _data._id = action?.payload?._id;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.CHANGE_BOOKING_REQUEST_FAILURE,
        payload: _data,
      });
    } else {
      showNotification("success", "Booking updated successfully");
      yield put({
        type: ACTIONS.CHANGE_BOOKING_REQUEST_SUCCESS,
        payload: _data,
      });
    }
  } catch (error) {
    console.log("There is an error in changed booked resource ", error);
    showNotification("error", "Something went wrong!");
    yield put({ type: ACTIONS.CHANGE_BOOKED_RESOURCE_FAILURE, payload: error });
  }
}

function* changeBookedResourceApprove(action) {
  try {
    let booking = yield call(
      changeBookedResourceApproveRequest,
      action?.payload
    );
    let _data = booking?.data;
    _data._id = action?.payload?._id;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.CHANGE_BOOKED_RESOURCE_APPROVE_FAILURE,
        payload: _data,
      });
    } else {
      showNotification("success", "Booking updated successfully");
      yield put({
        type: ACTIONS.CHANGE_BOOKED_RESOURCE_APPROVE_SUCCESS,
        payload: _data,
      });
    }
  } catch (error) {
    console.log("There is an error in changed booked resource approve ", error);
    showNotification("error", "Something went wrong!");
    yield put({
      type: ACTIONS.CHANGE_BOOKED_RESOURCE_APPROVE_FAILURE,
      payload: error,
    });
  }
}
function* refreshToken(action) {
  try {
    let response = yield call(refreshTokenRequest, action?.payload);
    let _data = response?.data;
    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.SET_REFRESH_TOKEN_FAILURE });
    } else {
      showNotification("success", "Refresh Token updated successfully");
      yield put({ type: ACTIONS.SET_REFRESH_TOKEN_SUCCESS });
    }
  } catch (error) {
    console.log("There is an error updating refresh token", error);
  }
}

// ACTION WATCHER
export default function* bookingSaga() {
  // user can logout without waiting for login action.
  yield takeEvery(ACTIONS.FETCH_ALL_BOOKING_RESOURCES, getAllBookingResources);
  yield takeEvery(ACTIONS.FETCH_BOOKING_RESOURCES, getBookedResource);
  yield takeLatest(ACTIONS.BOOK_RESOURCE, bookingResource);
  yield takeLatest(ACTIONS.PAY_CHARGE, payCharge);
  yield takeLatest(
    ACTIONS.FETCH_ALL_AVAILABLE_RESOURCES,
    getAllAvailableResources
  );
  yield takeLatest(ACTIONS.CANCEL_BOOKING, cancelBooking);
  yield takeLatest(ACTIONS.UPDATE_BOOKING, updateBooking);
  yield takeLatest(ACTIONS.UPDATE_DRAG_BOOKING, updateDragBooking);
  yield takeLatest(ACTIONS.BOOK_RESOURCE_WITHOUT_PAY, bookResourceWithoutPay);
  yield takeLatest(ACTIONS.CHANGE_BOOKED_RESOURCE, changeBookedResource);
  yield takeLatest(ACTIONS.CHANGE_BOOKING_REQUEST, changeBooking);
  yield takeLatest(
    ACTIONS.CHANGE_BOOKED_RESOURCE_APPROVE,
    changeBookedResourceApprove
  );
  yield takeLatest(ACTIONS.SET_REFRESH_TOKEN, refreshToken);
}
