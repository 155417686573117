import { CalendarOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import moment from "moment";

export const memberColumns = () => {
  const columns = [
    {
      title: "S.no",
      dataIndex: "sn",
      key: "sn",
      width: 10,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Last Booking",
      dataIndex: "lastBooking",
      key: "lastBooking",
      render: (date) => {
        return date ? (
          <Row justify="start" align="middle">
            <Col
              className="--avatar green mr15 circle"
              justify="center"
              align="middle"
            >
              <CalendarOutlined />
            </Col>{" "}
            <Col>
              <h4 className="nm">
                {moment(date).format("ddd, MMMM Do, YYYY")}
              </h4>
            </Col>{" "}
          </Row>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 200,
      align: "center",
    },
  ];
  return columns;
};
