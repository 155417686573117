import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Row, Button, Table, Tag, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AddMember from "./components/AddMember";
import { getAllMembers } from "./duck/MembersActions";
import {
  EditOutlined,
  SearchOutlined,
  EyeOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Input, Card } from "antd";
import { checkPermission } from "../utils/CommonFunctions";
import { memberColumns } from "./components/MemberColumns";
import { useHistory } from "react-router-dom";
import Routes from "../config/Routes";
import MTable from "../utils/MTable";

const Members = () => {
  const dispatch = useDispatch();
  const prevProp = useRef();
  const history = useHistory();

  const allMembers = useSelector((state) => state?.members);
  const activeWorkspace = useSelector((state) => state?.workSpace?.active);

  const isMemberEditable = useMemo(
    () =>
      checkPermission({
        row: "members",
      }),
    []
  );

  const [visible, showModal] = useState(false);
  const [selectedMember, selectMember] = useState(null);

  const [filter, setFilter] = useState({});
  const [boardKey, setBoardKey] = useState();

  const mMemberBoard = useMemo(
    () => allMembers.members?.[boardKey] ?? {},
    [allMembers.members, boardKey, activeWorkspace]
  );

  const loadMore = useCallback(
    (offset = 0, limit = 30) => {
      const { meta, list, loading } = mMemberBoard;
      if (!loading && (offset === 0 || meta?.totalCount > list?.length)) {
        let params = { space_id: activeWorkspace };
        if (filter.text) params.text = filter.text;

        setBoardKey(JSON.stringify({ ...params }));

        params.offset = offset;
        params.limit = limit;
        dispatch(getAllMembers(params));
      }
    },
    [activeWorkspace, mMemberBoard, filter, dispatch]
  );

  const handleTableChange = useCallback(
    (pagination) => {
      const { current, pageSize } = pagination;
      const { list } = mMemberBoard;
      const endIndex = current * pageSize;

      if (endIndex > list?.length) {
        loadMore(list?.length, endIndex - list?.length);
      }
    },
    [mMemberBoard, loadMore]
  );

  const onSearch = () => loadMore();
  const onReset = () => {
    setFilter({});
    setBoardKey("");
  };

  useEffect(() => {
    if (prevProp?.current?.activeWorkspace !== activeWorkspace) {
      onReset();
    } else if (activeWorkspace && !Object.keys(mMemberBoard)?.length)
      loadMore();

    return () => {
      prevProp.current = { activeWorkspace };
    };
  }, [activeWorkspace, mMemberBoard]);

  const handleModal = useCallback(
    (show = false, id) => {
      show = typeof show === "boolean" && show;
      const mMember = show &&
        allMembers?.memberMap?.[id] && { ...allMembers?.memberMap?.[id] };

      showModal(show);
      selectMember(mMember);
    },
    [allMembers?.memberMap]
  );

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setFilter((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );

  let members = mMemberBoard?.list;

  const data = useMemo(() => {
    let mData = [];
    if (members) {
      members?.forEach((id, index) => {
        let mMember = allMembers?.memberMap?.[id];
        mData.push({
          key: id,
          sn: ++index,
          name: mMember?.name,
          email: mMember?.email,
          lastBooking: mMember?.booking?.created_at,
          status: mMember?.spaces?.find((sp) => sp?.space === activeWorkspace)
            ?.active ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Blocked</Tag>
          ),
          action: (
            <>
              {isMemberEditable ? (
                <EditOutlined onClick={() => handleModal(true, id)} />
              ) : (
                <EyeOutlined onClick={() => handleModal(true, id)} />
              )}
              <Tooltip placement="top" title={"Activities"}>
                <FileTextOutlined
                  className="ml10"
                  onClick={() => history.push(`${Routes.reports}/${id}`)}
                />
              </Tooltip>
            </>
          ),
        });
      });
    }
    return mData;
  }, [members, allMembers?.memberMap]);

  return (
    <div className="mainMember">
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Members</span>
        <Row>
          {isMemberEditable && (
            <Button type="primary-outline" onClick={() => handleModal(true)}>
              + Add Member
            </Button>
          )}
        </Row>
      </Row>
      <Card title="Filter" size="small" bordered={false}>
        <Input
          size="middle"
          className="custom-input mr10"
          style={{ width: "25%", fontWeight: "500", marginRight: "15px" }}
          placeholder="Search Name or Email"
          prefix={<SearchOutlined />}
          value={filter?.text}
          onChange={handleChange("text")}
          allowClear
        />

        <Button className="mr10" type="primary" onClick={onSearch}>
          Search
        </Button>
        <Button className="mr10" type="primary-outline" onClick={onReset}>
          Reset
        </Button>
      </Card>

      <MTable
        columns={memberColumns()}
        dataSource={data}
        className="mt20"
        bordered={false}
        loading={mMemberBoard?.loading}
        pagination={{
          total: mMemberBoard?.meta?.totalCount || 0,
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
      />

      <AddMember
        visible={visible}
        showModal={handleModal}
        selectedMember={selectedMember || null}
        selectMember={selectMember}
        editable={isMemberEditable}
      />
    </div>
  );
};

export default Members;
