import Actions from './PlanActionTypes'

export const getAllPlans = (params = {}) => {
    return {
        type: Actions.FETCH_ALL_PLANS,
        payload: params,
    }
}

export const getPlan = (id) => {
    return {
        type: Actions.FETCH_PLAN,
        payload: id,
    }
}

export const addNewPlan = (params = {}) => {
    return {
        type: Actions.ADD_PLAN,
        payload: params,
    }
}

export const updatePlan = (params = {}) => {
    return {
        type: Actions.UPDATE_PLAN,
        payload: params,
    }
}
