import Actions from "./ResourceActionTypes";

export const getAllWorkspaceResources = (workSpaceId) => {
  return {
    type: Actions.FETCH_ALL_WORKSPACE_RESOURCES,
    payload: workSpaceId,
  };
};

export const getResource = (payload = {}) => {
  return {
    type: Actions.FETCH_RESOURCES,
    payload: payload,
  };
};

export const addMeetingRoom = (params = {}) => {
  return {
    type: Actions.ADD_MEETING,
    payload: params,
  };
};

export const addPrivateOffice = (params = {}) => {
  return {
    type: Actions.ADD_PRIVATE_OFFICE,
    payload: params,
  };
};

export const addDesk = (params = {}) => {
  return {
    type: Actions.ADD_DESK,
    payload: params,
  };
};

export const updateMeetingRoom = (params = {}) => {
  return {
    type: Actions.UPDATE_MEETING_ROOM,
    payload: params,
  };
};

export const updatePrivateOffice = (params = {}) => {
  return {
    type: Actions.UPDATE_PRIVATE_OFFICE,
    payload: params,
  };
};

export const updateDesk = (params = {}) => {
  return {
    type: Actions.UPDATE_DESK,
    payload: params,
  };
};

export const addItem = (params = {}) => {
  return {
    type: Actions.ADD_ITEM,
    payload: params,
  };
};

export const updateItem = (params = {}) => {
  return {
    type: Actions.UPDATE_ITEM,
    payload: params,
  };
};

export const deleteItem = (id) => {
  return {
    type: Actions.DELETE_ITEM,
    payload: id,
  };
};
