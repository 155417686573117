const Actions = {
  FETCH_ALL_WORKSPACE_RESOURCES: "FETCH_ALL_WORKSPACE_RESOURCES",
  FETCH_ALL_WORKSPACE_RESOURCES_SUCCESS:
    "FETCH_ALL_WORKSPACE_RESOURCES_SUCCESS",
  FETCH_ALL_WORKSPACE_RESOURCES_FAILURE:
    "FETCH_ALL_WORKSPACE_RESOURCES_FAILURE",

  FETCH_RESOURCES: "FETCH_RESOURCES",
  FETCH_RESOURCES_SUCCESS: "FETCH_RESOURCES_SUCCESS",
  FETCH_RESOURCES_FAILURE: "FETCH_RESOURCES_FAILURE",

  ADD_MEETING: "ADD_MEETING",
  ADD_MEETING_SUCCESS: "ADD_MEETING_SUCCESS",
  ADD_MEETING_FAILURE: "ADD_MEETING_FAILURE",

  ADD_PRIVATE_OFFICE: "ADD_PRIVATE_OFFICE",
  ADD_PRIVATE_OFFICE_SUCCESS: "ADD_PRIVATE_OFFICE_SUCCESS",
  ADD_PRIVATE_OFFICE_FAILURE: "ADD_PRIVATE_OFFICE_FAILURE",

  ADD_DESK: "ADD_DESK",
  ADD_DESK_SUCCESS: "ADD_DESK_SUCCESS",
  ADD_DESK_FAILURE: "ADD_DESK_FAILURE",

  UPDATE_MEETING_ROOM: "UPDATE_MEETING_ROOM",
  UPDATE_MEETING_ROOM_SUCCESS: "UPDATE_MEETING_ROOM_SUCCESS",
  UPDATE_MEETING_ROOM_FAILURE: "UPDATE_MEETING_ROOM_FAILURE",

  UPDATE_PRIVATE_OFFICE: "UPDATE_PRIVATE_OFFICE",
  UPDATE_PRIVATE_OFFICE_SUCCESS: "UPDATE_PRIVATE_OFFICE_SUCCESS",
  UPDATE_PRIVATE_OFFICE_FAILURE: "UPDATE_PRIVATE_OFFICE_FAILURE",

  UPDATE_DESK: "UPDATE_DESK",
  UPDATE_DESK_SUCCESS: "UPDATE_DESK_SUCCESS",
  UPDATE_DESK_FAILURE: "UPDATE_DESK_FAILURE",

  ADD_ITEM: "ADD_ITEM",
  ADD_ITEM_SUCCESS: "ADD_ITEM_SUCCESS",
  ADD_ITEM_FAILURE: "ADD_ITEM_FAILURE",

  UPDATE_ITEM: "UPDATE_ITEM",
  UPDATE_ITEM_SUCCESS: "UPDATE_ITEM_SUCCESS",
  UPDATE_ITEM_FAILURE: "UPDATE_ITEM_FAILURE",

  DELETE_ITEM: "DELETE_ITEM",
  DELETE_ITEM_SUCCESS: "DELETE_ITEM_SUCCESS",
  DELETE_ITEM_FAILURE: "DELETE_ITEM_FAILURE",
};

export default Actions;
