import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";

import CustomModal from "../../utils/CustomModal";

import { updateWorkSpace } from "../../workspace/duck/WorkSpaceActions";

const UpdatePrice = (props) => {
  const { resourceName, resourceType, visible } = props;
  const prevProp = useRef();

  const [state, setState] = useState({});
  const [error, handleError] = useState({});

  const workSpace = useSelector((state) => state?.workSpace);
  let loading = workSpace?.loading;
  const activeWorkSpace =
    workSpace?.active && workSpace?.workSpaceMap?.[workSpace?.active];

  const dispatch = useDispatch();

  const preload = () => {
    if (props.visible) {
      setState((preRecord) => ({
        ...preRecord,
        price: activeWorkSpace?.price?.[resourceType] || "",
      }));

      handleError({});
    }
  };

  useEffect(() => {
    if (prevProp?.current?.loading && !loading) {
      props.showModal(false);
    } else if (!prevProp?.current?.visible && visible) preload();

    return () => {
      prevProp.current = { loading, visible };
    };
  }, [visible, loading]);

  const hasError = () => {
    let error = {};

    if (state.price === "" || Number(state.price) < 0) {
      error.price = "Price must be greater than 0";
    }

    handleError(error);
    return Object.keys(error).length > 0;
  };

  const add = () => {
    if (!hasError()) {
      let updatePriceParams = {
        _id: activeWorkSpace?._id,
        price: Object.assign({}, activeWorkSpace?.price, {
          [resourceType]: state.price,
        }),
      };

      dispatch(updateWorkSpace(updatePriceParams));
    }
  };

  const handleChange =
    (name, isCheckbox = false) =>
    (event) => {
      let value = isCheckbox ? event?.target?.checked : event?.target?.value;

      setState((preState) => ({
        ...preState,
        [name]: value,
      }));
      handleError({});
    };

  return (
    <CustomModal
      visible={visible}
      title={"Update Price"}
      okButtonText={"Update"}
      cancelButtonText="Cancel"
      onCancel={() => props.showModal(false)}
      handleSuccess={add}
      loading={loading}
    >
      <Row align="middle" className="mt10">
        <Col span={6}>
          <label className="label">{resourceName} </label>
          <i>(Base Price)</i>
          <sup style={{ color: "red" }}>*</sup>
        </Col>

        <Col span={18}>
          <Input
            className="custom-input"
            placeholder="Price"
            size="large"
            type="number"
            min={0}
            step={1}
            style={{ width: 150 }}
            value={state?.price}
            onChange={handleChange("price")}
            disabled={loading}
          />
          <span style={{ color: "darkgray" }}>&nbsp; £/day</span>

          <Row className="error mt5">{error?.price}</Row>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default UpdatePrice;
