import { useCallback, useEffect, useState } from "react";
import CustomModal from "../../utils/CustomModal";
import { Row, Col, Input, Checkbox, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createNewAmenity, updateAmenity } from "../duck/AmenitiesActions";

const AddAmenities = (props) => {
  const { selectedAmenity, editable, visible } = props;
  const workSpace = useSelector((state) => state?.workSpace);
  const dispatch = useDispatch();

  const [amenityObj, setAmenityObj] = useState({});
  const [error, handleError] = useState({});

  let modalTitle = editable
    ? selectedAmenity
      ? "Edit Amenity"
      : "Add Amenity"
    : "Amenity";

  useEffect(() => {
    setAmenityObj({
      title: selectedAmenity?.title || null,
      description: selectedAmenity?.description || null,
      active: selectedAmenity?.active ?? true,
    });
  }, [workSpace?.active, selectedAmenity]);

  const addAmenity = () => {
    if (amenityObj?.title && amenityObj.title.trim() !== "") {
      if (selectedAmenity) {
        dispatch(updateAmenity({ _id: selectedAmenity?._id, ...amenityObj }));
        props.selectAmenity(null);
      } else {
        dispatch(createNewAmenity(amenityObj));
      }

      setAmenityObj({
        title: "",
        description: null,
        active: true,
      });
      props.showModal(false);
    } else {
      let error = {
        title: null,
      };
      if (!amenityObj.title || amenityObj?.title?.trim() === "") {
        error.title = "Title cannot be blank";
      }

      handleError(error);
    }
  };

  const add = useCallback(addAmenity, [amenityObj]);

  return (
    <CustomModal
      visible={visible}
      title={modalTitle}
      onCancel={() => props.showModal(false)}
      footer={
        <>
          <Button onClick={() => props.showModal(false)}>Close</Button>
          {editable && (
            <Button type="primary" onClick={add} loading={workSpace?.loading}>
              {selectedAmenity ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      <div>
        <Row align="middle">
          <Col span={4}>
            <label className="label">Title</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={20}>
            <Input
              className="custom-input"
              placeholder="Ex: Wifi"
              size="large"
              autoFocus
              allowClear
              disabled={workSpace?.loading || !editable}
              value={amenityObj?.title}
              onChange={(e) => {
                setAmenityObj({ ...amenityObj, title: e?.target?.value });
                handleError({});
              }}
            />
            <Row className="error mt5">{error?.title}</Row>
          </Col>
        </Row>

        <Row align="top" className="mt10">
          <Col span={4}></Col>
          <Col span={20}>
            <Checkbox
              checked={amenityObj?.active}
              disabled={workSpace?.loading || !editable}
              onChange={(e) =>
                setAmenityObj({ ...amenityObj, active: e?.target?.checked })
              }
            >
              Amenity status
            </Checkbox>
          </Col>
        </Row>
      </div>
    </CustomModal>
  );
};

export default AddAmenities;
