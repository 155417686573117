import React, { useMemo } from "react";
import { Row, Card, Carousel, Image, Tag, Col, Typography } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import noImage from "../../assets/images/no-image.png";
import { resourceTypes } from "../../utils/CommonFunctions";
import { useSelector } from "react-redux";

const WorkSpaceCard = (props) => {
  const { editable, id, showModal, selectWorkspace } = props;
  const workSpaceMap = useSelector((state) => state?.workSpace?.workSpaceMap);

  const workSpace = useMemo(() => id && workSpaceMap?.[id], [id, workSpaceMap]);
  const ExtraIcon = useMemo(
    () => (editable ? EditOutlined : EyeOutlined),
    [editable]
  );

  return (
    <Col key={id} span={12}>
      <Card
        bodyStyle={{ padding: 0 }}
        key={id}
        bordered={false}
        style={{
          margin: "10px 20px 10px 0",
          borderRadius: 5,
          overflow: "auto",
        }}
        hoverable
      >
        <Row>
          <Col span={10}>
            <Carousel autoplay>
              {workSpace?.pictures?.length > 0 &&
                workSpace?.pictures?.map((picture) => (
                  <Image
                    className="cover new_image-fluid"
                    key={picture}
                    preview={false}
                    wrapperStyle={{ height: 150 }}
                    width={"100%"}
                    height={"100%"}
                    src={picture?.original || picture?.thumb_location}
                    alt={picture?.filename}
                  />
                ))}
              {workSpace?.pictures?.length === 0 && (
                <Image
                  className="cover new_image-fluid"
                  preview={false}
                  wrapperStyle={{ height: 150 }}
                  src={noImage}
                  width={"100%"}
                  height={"100%"}
                />
              )}
            </Carousel>
          </Col>
          <Col span={14} className="p15">
            <p
              style={{
                fontSize: 16,
                fontWeight: 600,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {workSpace?.name}

              <ExtraIcon
                style={{ fontSize: 12 }}
                onClick={() => {
                  selectWorkspace({ _id: id, ...workSpace });
                  showModal(true);
                }}
              />
            </p>
            <Typography.Paragraph
              ellipsis={
                true && {
                  rows: 2,
                  tooltip: workSpace?.description,
                }
              }
              style={{ fontWeight: 400 }}
            >
              {workSpace?.description}
            </Typography.Paragraph>
            <Typography.Paragraph
              ellipsis={true && { rows: 1 }}
              style={{ fontWeight: 400, marginBottom: 0 }}
            >
              {workSpace?.amenities
                ?.filter((a) => a?.active)
                ?.map((a) => (
                  <Tag key={a?._id}>{a?.title}</Tag>
                ))}
            </Typography.Paragraph>
          </Col>
        </Row>
        {workSpace?.resources_available?.length > 0 && (
          <Row style={{ backgroundColor: "#e2e2e2", padding: "3px 10px" }}>
            {workSpace?.resources_available?.map((val) => (
              <span key={val} style={{ marginRight: 12, fontSize: 12 }}>
                {resourceTypes?.[val]?.name}
              </span>
            ))}
          </Row>
        )}
      </Card>
    </Col>
  );
};

export default WorkSpaceCard;
