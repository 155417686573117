import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  Fragment,
  useMemo,
} from "react";
import CustomModal from "../../utils/CustomModal";
import { Row, Col, Input, Select, Checkbox, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addDesk, updateDesk } from "../duck/ResourceActions";

const AddHotDesk = (props) => {
  const DESK_TYPE = {
    dedicated_desk: { name: "Dedicated Desk" },
    flexi_desk: { name: "Flexi Desk" },
  };
  Object.freeze(DESK_TYPE);

  const prevProp = useRef();
  const { selectedDesk, editable, visible, handleModal } = props;

  const modalTitle = useMemo(
    () => (editable ? (selectedDesk ? "Edit Desk" : "Add Desk") : "Desk"),
    [editable, selectedDesk]
  );
  const workSpace = useSelector((state) => state?.workSpace);
  const mWorkSpace = useMemo(
    () => workSpace?.workSpaceMap?.[workSpace?.active] || {},
    [workSpace?.active, workSpace?.workSpaceMap]
  );
  const amenities = useSelector((state) => state?.amenities?.amenities);
  const dispatch = useDispatch();

  const { resources, resourceMap } = useSelector((state) => state.resources);
  const { adding } = useMemo(
    () => resources?.[workSpace?.active] || {},
    [resources, workSpace?.active]
  );
  const mResource = useMemo(
    () => resourceMap?.[selectedDesk] || {},
    [resourceMap, selectedDesk]
  );
  const { loading } = mResource;

  const [deskObj, setDeskObj] = useState({});
  const [error, handleError] = useState({});

  useEffect(() => {
    if (
      (prevProp?.current?.loading && !loading) ||
      (prevProp?.current?.adding && !adding)
    ) {
      handleModal();
    } else if (!prevProp?.current?.visible && visible) {
      let amenities =
        (Array.isArray(mResource?.amenities_id) &&
          mResource?.amenities_id.map((a) => a?.active && a?._id)) ||
        [];
      amenities = amenities.filter((a) => a && a);
      let desk_type = [];
      let desk_types_count = {};
      if (Array.isArray(mResource?.desk_type)) {
        mResource?.desk_type.map((dType) => {
          Object.keys(dType).map((mType) => {
            if (dType[mType]?.total) {
              desk_types_count[mType] = dType[mType]?.total;
              desk_type.push(mType);
            }
          });
        });
      }
      setDeskObj({
        name: mResource?.name || null,
        space_id:
          mResource?.space_id?._id || mResource?.space_id || workSpace?.active,
        description: mResource?.description || null,
        cancellation_notice: mResource?.cancellation_notice || 0,
        active: mResource?.active ?? true,
        desk_type: desk_type,
        amenities_id: amenities,
        ...desk_types_count,
      });
      handleError({});
    }

    return () => {
      prevProp.current = { loading, adding, visible };
    };
  }, [
    workSpace?.active,
    selectedDesk,
    loading,
    adding,
    visible,
    handleModal,
    mResource,
  ]);

  const hasError = useCallback(() => {
    let { name, space_id, desk_type } = deskObj;
    name = name?.trim?.();

    let deskTypes = [];
    let validDeskCapacity = true;
    deskObj.capacity = 0;
    if (!selectedDesk && Array.isArray(deskObj?.desk_type)) {
      deskObj?.desk_type.map((type) => {
        if (validDeskCapacity) {
          validDeskCapacity = validDeskCapacity && !!Number(deskObj[type] || 0);
          deskObj.capacity += Number(deskObj[type] || 0);
          deskTypes.push({ [type]: deskObj[type] });
        }
      });
    }

    let error = {};
    if (!name) {
      error.name = "Meeting room name cannot be blank";
    }

    if (!space_id) {
      error.space_id = "Please select a location";
    }

    if (!desk_type || desk_type?.length === 0) {
      error.desk_type = "Please select desk type";
    }

    if (Array.isArray(desk_type) && desk_type.length > 0) {
      desk_type?.map?.((desk_type) => {
        if (!deskObj[desk_type] || deskObj[desk_type] <= 0) {
          error[desk_type] = "Seat must be greater than 0";
        }
      });
    }

    handleError(error);

    return Object.keys(error).length;
  }, [selectedDesk, deskObj]);

  const add = useCallback(() => {
    if (!hasError()) {
      if (selectedDesk) {
        dispatch(updateDesk({ _id: selectedDesk, ...deskObj }));
      } else {
        let desk_type = [];
        let validDeskCapacity = true;
        deskObj.capacity = 0;

        if (!selectedDesk && Array.isArray(deskObj?.desk_type)) {
          deskObj?.desk_type.map((type) => {
            if (validDeskCapacity) {
              validDeskCapacity =
                validDeskCapacity && !!Number(deskObj[type] || 0);
              deskObj.capacity += Number(deskObj[type] || 0);
              desk_type?.push?.({ [type]: deskObj[type] });
            }
          });
        }

        dispatch(addDesk({ ...deskObj, desk_type }));
      }
    } else {
    }
  }, [selectedDesk, deskObj, hasError, dispatch]);

  const handleChange = useCallback(
    (name, isCheckbox) => (e) => {
      let value = (isCheckbox ? e?.target?.checked : e?.target?.value) ?? e;
      if (["capacity"].includes(name)) {
        value = value.replace(/[^0-9]/g, "");
      }

      setDeskObj((preState) => ({ ...preState, [name]: value }));
      handleError({});
    },
    []
  );

  const disabled = useMemo(
    () => adding || loading || !editable,
    [adding, loading, editable]
  );

  return (
    <CustomModal
      visible={props.visible}
      title={modalTitle}
      onCancel={handleModal}
      footer={
        <>
          <Button onClick={handleModal}>Close</Button>
          {editable && (
            <Button type="primary" onClick={add} loading={loading || adding}>
              {selectedDesk ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      <div>
        <div
          className="--avatar mb10"
          style={{ width: "100%", backgroundColor: "#EEEDFD", fontWeight: 500 }}
        >
          Area Details
        </div>

        {/* Name start */}
        <Row align="middle">
          <Col span={6}>
            <label className="label">Name</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18}>
            <Input
              className="custom-input"
              placeholder="Hot desk"
              disabled={disabled}
              size="large"
              autoFocus
              allowClear
              value={deskObj?.name}
              onChange={handleChange("name")}
            />
            <Row className="error mt5">{error?.name}</Row>
          </Col>
        </Row>
        {/* Name end */}

        {/* Location start */}
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Location</label>
            {!selectedDesk && <sup style={{ color: "red" }}>*</sup>}
          </Col>
          <Col span={18}>
            {workSpace?.workSpaceMap?.[deskObj?.space_id]?.name}
          </Col>
        </Row>
        {/* Location end */}

        {/* Description start */}
        <Row align="top" className="mt10">
          <Col span={6}>
            <label className="label">Description</label>
          </Col>
          <Col span={18}>
            <Input.TextArea
              className="custom-input"
              placeholder="Add room description"
              autoSize={{ minRows: 2, maxRows: 5 }}
              disabled={disabled}
              allowClear
              value={deskObj?.description}
              onChange={handleChange("description")}
            />
          </Col>
        </Row>
        {/* Description end */}

        {/* Amenities start */}
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Amenities</label>
          </Col>
          <Col span={18}>
            <Select
              className="custom-select fw"
              placeholder="Select amenities"
              size="large"
              mode="multiple"
              disabled={disabled}
              allowClear
              value={deskObj?.amenities_id}
              onChange={handleChange("amenities_id")}
            >
              {Array.isArray(amenities) &&
                amenities.map(
                  (amenity) =>
                    amenity?.active && (
                      <Select.Option key={amenity?._id} value={amenity?._id}>
                        {amenity?.title}
                      </Select.Option>
                    )
                )}
            </Select>
          </Col>
        </Row>
        {/* Amenities end */}

        {/* Seat Type start */}
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Seat Type</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18}>
            <Select
              className="custom-select fw"
              placeholder="Select desk type"
              size="large"
              disabled={selectedDesk || disabled}
              allowClear
              value={deskObj?.desk_type}
              onChange={handleChange("desk_type")}
              mode="multiple"
            >
              {Object.keys(DESK_TYPE)?.map((id) => (
                <Select.Option disabled={disabled} key={id} value={id}>
                  {DESK_TYPE?.[id]?.name}
                </Select.Option>
              ))}
            </Select>
            <Row className="error mt5">{error?.desk_type}</Row>
          </Col>
        </Row>
        {/* Seat Type end */}

        {/* No of seats start */}
        {Array.isArray(deskObj?.desk_type) &&
          deskObj?.desk_type.map((desk_type, index) => (
            <Fragment key={index}>
              <div
                className="--avatar mb15 mt15"
                style={{
                  width: "100%",
                  backgroundColor: "#EEEDFD",
                  fontWeight: 500,
                }}
              >
                {DESK_TYPE?.[desk_type]?.name} Details @ £{" "}
                {mWorkSpace?.price?.[desk_type] || 0}/seat
              </div>

              <Row
                key={`${desk_type}-${index}`}
                align="middle"
                className="mt10"
              >
                <Col span={6}>
                  <label className="label">
                    {selectedDesk ? "Total" : "Capacity"}
                  </label>
                  {!selectedDesk && <sup style={{ color: "red" }}>*</sup>}
                </Col>
                <Col span={18}>
                  <Input
                    className="custom-input"
                    placeholder="No of seats"
                    size="large"
                    type="number"
                    disabled={selectedDesk || disabled}
                    min={0}
                    style={{ width: 150 }}
                    value={deskObj?.[desk_type]}
                    onChange={handleChange(`${desk_type}`)}
                  />
                  <span style={{ color: "darkgray" }}>&nbsp;seat</span>
                  <Row className="error mt5">{error?.[desk_type]}</Row>
                </Col>
              </Row>
            </Fragment>
          ))}
        {/* No of seats end */}

        {/* active start */}
        <Row align="top" className="mt10">
          <Col span={6}></Col>
          <Col span={18}>
            <Checkbox
              disabled={disabled}
              checked={deskObj?.active}
              onChange={handleChange("active", true)}
            >
              Desk status
            </Checkbox>
          </Col>
        </Row>
        {/* active end */}
      </div>
    </CustomModal>
  );
};

export default AddHotDesk;
