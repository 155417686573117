import { Button, Checkbox, Col, Row, Upload } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import CustomModal from "../../utils/CustomModal";
import { createCMS, updateCMS } from "../duck/CMSActions";
import constants from "../../utils/Constants";
import { showNotification } from "../../utils/CommonFunctions";
import axios from "axios";
import AppUrl from "../../config/AppUrl";
import ReactQuill from "react-quill";
import MUploadItem from "./MUploadItem";
const { CMS_TYPES } = constants;

const AddTestimonialModal = (props) => {
  const prevProp = useRef();
  const dispatch = useDispatch();

  const { visible, selectedId, showModal, isCMSEditable } = props;

  const cms = useSelector((state) => state?.cms);
  const mCms = useMemo(
    () =>
      selectedId
        ? cms?.cmsMap?.[selectedId]
        : { loading: cms?.loading, error: cms?.error },
    [selectedId, cms]
  );

  const [state, setState] = useState({ title: "", description: "" });
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState({});

  const modalTitle = useMemo(
    () =>
      isCMSEditable
        ? selectedId
          ? "Edit Testimonial"
          : "Add Testimonial"
        : "Testimonial Details",
    [isCMSEditable, selectedId]
  );

  useEffect(() => {
    if (prevProp?.current?.loading && !mCms?.loading) {
      setState({});
      showModal?.(false);
    } else if (!prevProp?.current?.visible && visible) {
      setState({
        title: mCms?.name,
        role: mCms?.meta?.role,
        description: mCms?.description,
        active: mCms?.active,
      });
      const files = JSON.parse(JSON.stringify([...(mCms?.pictures || [])]));
      setFileList(files);
      setError({});
    }

    return () => {
      prevProp.current = {
        loading: mCms?.loading,
        visible,
      };
    };
  }, [mCms, visible, showModal]);

  const handleChange = useCallback(
    (name, isCheckbox) => (e) => {
      let value;
      if (isCheckbox) {
        value = e?.target?.checked;
      } else {
        value = e?.target?.value ?? e;
      }

      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const beforeUpload = (file) => {
    const supportedFileType = ["image/png", "image/jpg", "image/jpeg"];

    let isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification("error", "Please upload JEPG, JGP or PNG file only");
    }
    return isAcceptable || Upload.LIST_IGNORE;
  };

  const updateImageUpload = useCallback(({ file }) => {
    var img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = function () {
      URL.revokeObjectURL(img.src);

      const fileObj = {
        uid: file?.uid,
        name: file?.name,
        size: file?.size,
        type: file?.type,
        thumb_location: URL.createObjectURL(file),
        url: URL.createObjectURL(file),
        tag: "",
        file,
      };
      setError({});
      setFileList((preState) => [...preState, fileObj]);
    };
  }, []);

  const onRemoveImage = useCallback(
    (file) => {
      const fileId = file?._id || file?.uid;
      setFileList((preState) =>
        preState.filter((p) => (p?._id || p?.uid) !== fileId)
      );
    },
    [setFileList]
  );

  const handleTagChange = useCallback(
    (file) => {
      setFileList((preState) =>
        preState.map((perFile) =>
          (perFile._id && perFile._id === file._id) || perFile.uid === file.uid
            ? file
            : perFile
        )
      );
      setError({});
    },
    [setError, setFileList]
  );

  const hasError = useCallback(() => {
    let { title, description, role } = state;
    title = title?.trim?.();
    description = description?.trim?.();

    const error = {};

    if (!title) {
      error.title = "Name cannot be blank";
    }
    if (!role) {
      error.role = "Role cannot be blank";
    }
    if (!fileList?.length) {
      error.fileList = "Picture required";
    }

    if (!description) {
      error.description = "Description cannot be blank";
    }

    setError(error);
    return Object.keys(error).length;
  }, [fileList, state]);

  const onAdd = useCallback(() => {
    if (!hasError()) {
      const { title, description, role, active } = state;
      const payload = {
        type: CMS_TYPES.TESTIMONIAL,
        name: title,
        description,
        files: [],
        tags: [],
        meta: JSON.stringify({
          role,
        }),
        active,
      };
      fileList?.forEach((picture) => {
        if (picture?.file) {
          payload.files.push(picture?.file);
          payload.tags.push(picture?.tag || "");
        }
      });
      payload.tags = JSON.stringify(payload.tags);

      if (selectedId) {
        payload._id = selectedId;
        const oldFileListIds = mCms?.pictures?.map?.(
          (file) => file?._id || file?.uid
        );
        const currentUploadedFileListIds = fileList
          ?.filter?.((file) => !file?.file && file?._id)
          ?.map((file) => file?._id || file?.uid);
        const removedUploadedFileIds = oldFileListIds?.filter?.(
          (id) => !currentUploadedFileListIds?.includes(id)
        );

        removedUploadedFileIds.forEach((id) => {
          axios({
            method: "DELETE",
            url: `${AppUrl.ATTACHMENTS}/${id}`,
          });
        });

        fileList.forEach((file) => {
          if (file?._id) {
            const mTag = file.tag?.trim?.();
            const title = file.title?.trim?.();
            const imgDescription = file.imgDescription?.trim?.();
            const mPicture = mCms?.pictures?.find((p) => p?._id === file?._id);
            if (
              mTag !== mPicture.tag ||
              title !== mPicture?.title ||
              imgDescription !== mPicture?.imgDescription
            ) {
              const data = { tag: mTag, title, imgDescription };

              axios({
                method: "PUT",
                url: `${AppUrl.ATTACHMENTS}/${file?._id}`,
                data,
              });
            }
          }
        });
        dispatch(updateCMS(payload));
      } else {
        dispatch(createCMS(payload));
      }
    }
  }, [hasError, state, fileList, selectedId, mCms?.pictures, dispatch]);

  const disabled = !isCMSEditable || mCms?.loading;

  return (
    <CustomModal
      visible={visible}
      title={modalTitle}
      onCancel={props?.showModal}
      footer={
        <>
          <Button onClick={props?.showModal}>Close</Button>
          {isCMSEditable && (
            <Button type="primary" loading={mCms?.loading} onClick={onAdd}>
              {selectedId ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      <div>
        {/* Name start */}
        <Row align="middle">
          <Col span={6} xs={24} sm={24} lg={6} xl={4} xxl={4}>
            <label className="label">Name </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18} xs={24} sm={24} lg={18} xl={20} xxl={20}>
            <ReactQuill
              size="large"
              type="text"
              value={state?.title}
              onChange={handleChange("title")}
              disabled={disabled}
              theme="snow"
            />
            <Row className="error mt5">{error?.title}</Row>
          </Col>
        </Row>
        {/* Name end */}

        {/* Designation start */}
        <Row align="middle" className="mt10">
          <Col span={6} xs={24} sm={24} lg={6} xl={4} xxl={4}>
            <label className="label">Role </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18} xs={24} sm={24} lg={18} xl={20} xxl={20}>
            <ReactQuill
              size="large"
              type="text"
              value={state?.role}
              onChange={handleChange("role")}
              disabled={disabled}
              theme="snow"
            />
            <Row className="error mt5">{error?.role}</Row>
          </Col>
        </Row>
        {/* Designation end */}

        {/* description start */}
        <Row align="top" className="mt10">
          <Col span={6} xs={24} sm={24} lg={6} xl={4} xxl={4}>
            <label className="label">Description </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18} xs={24} sm={24} lg={18} xl={20} xxl={20}>
            <ReactQuill
              style={{
                height: "150px",
                marginBottom: "50px",
              }}
              placeholder="Description"
              size="large"
              type="text"
              value={state?.description}
              onChange={handleChange("description")}
              disabled={disabled}
              theme="snow"
            />
            <Row className="error mt5">{error?.description}</Row>
          </Col>
        </Row>
        {/* description end */}

        {/* Picture start */}
        <Row align="top" className="mt10">
          <Col span={6} xs={24} sm={24} lg={6} xl={4} xxl={4}>
            <label className="label">Picture </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18} xs={24} sm={24} lg={18} xl={20} xxl={20}>
            <Upload
              className="custom-upload-list"
              accept={".jpeg, .jpg, .png"}
              listType="picture"
              beforeUpload={beforeUpload}
              fileList={fileList}
              onRemove={onRemoveImage}
              customRequest={updateImageUpload}
              disabled={disabled}
              itemRender={(originNode, file, _, actions) => (
                <MUploadItem
                  originNode={originNode}
                  file={file}
                  actions={actions}
                  handleTagChange={handleTagChange}
                  disabled={disabled}
                />
              )}
            >
              {fileList.length === 0 && (
                <Row
                  className="custom-upload-image mb10"
                  align="middle"
                  justify="center"
                >
                  <Col className="mt5">
                    <UploadOutlined className="mr5" />
                    Upload<sup style={{ color: "red" }}>*</sup>
                  </Col>
                </Row>
              )}
            </Upload>

            <Row className="error mt5">{error?.fileList}</Row>
          </Col>
        </Row>
        {/* Picture end */}

        {/* active start */}
        {selectedId && (
          <Row align="top" className="mt10">
            <Col span={6} xs={24} sm={24} lg={6} xl={4} xxl={4}></Col>
            <Col span={18} xs={24} sm={24} lg={18} xl={20} xxl={20}>
              <Checkbox
                disabled={disabled}
                checked={state?.active}
                onChange={handleChange("active", true)}
              >
                Active
              </Checkbox>
            </Col>
          </Row>
        )}
        {/* active end */}
      </div>
    </CustomModal>
  );
};

export default AddTestimonialModal;
