import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Space, DatePicker, Input, Button } from "antd";
import CustomModal from "../../utils/CustomModal";
import {
  getAllAvailableResources,
  updateBooking,
  changeBookedResourceApprove,
} from "../../booking/duck/BookingActions";
import moment from "moment";
import { SwapRightOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

class DeskSwapRequest extends Component {
  resourceType = [
    {
      id: "dedicated_desk",
      name: "Dedicated Desk",
    },
    {
      id: "flexi_desk",
      name: "Flexi Desk",
    },
  ];

  state = {
    resource_type: undefined,
    capacity: undefined,
    from_date: null,
    from_time: null,
    duration: null,
    from_to_date: null,
    amenities_id: undefined,
    resource: undefined,
    member: undefined,
    filter: "",
    title: "",
    cancelClicked: false,
    seat_swap_status: "",
    newDesks: [],
    admin_remark: "",
  };

  componentDidUpdate(prevProps) {
    const {
      selectedBooking,
      visible,
      activeWorkspace,
      getAllAvailableResources,
    } = this.props;
    let { resource_type } = this.state;

    if (prevProps.visible !== visible) {
      let resource_type =
        selectedBooking?.type ||
        selectedBooking?.items[0]?.desk_type ||
        selectedBooking?.items[0]?.type ||
        selectedBooking?.resource_id?.type ||
        undefined;

      this.setState({
        resource_type: resource_type,
        resource:
          (selectedBooking?.resource_id && selectedBooking?.resource_id?._id) ||
          selectedBooking?.resource_id ||
          undefined,
        isDeskSwapRequestRaise: !!selectedBooking?.seat_swap_status,
        customer_remark: selectedBooking?.customer_remark,
        seat_swap_status: "",
        seatSwapStatusError: "",
        newDesks: [],
        newDesksError: [],
        adminRemarkError: "",
        admin_remark: "",
      });
    }

    // call to api to fetch all the available resources
    if (visible && resource_type) {
      let startDateTime = moment(selectedBooking?.from).set({
        hour: 8,
        minute: 0,
        second: 0,
      });
      let endDateTime = moment(selectedBooking?.to).set({
        hour: 18,
        minute: 0,
        second: 0,
      });

      let availableResParams = {
        from: startDateTime.utc().toDate(),
        to: endDateTime.utc().toDate(),
        capacity: selectedBooking?.capacity,
        space_id: activeWorkspace,
        type: resource_type,
        fetchAll: true,
      };
      let filter = JSON.stringify(availableResParams);

      if (filter !== this.state.filter) {
        this.setState({
          filter: filter,
        });

        getAllAvailableResources(availableResParams);
      }
    }

    if (prevProps?.selectedBooking?.loading && !selectedBooking?.loading) {
      this.props.handleModal?.();
    }
  }

  handleSeatSwap = (value, i) => {
    let desks = this.state?.newDesks?.map?.((item) => item);

    desks[i] = value && JSON.parse(value);
    this.setState({ newDesks: desks, newDesksError: [] });
  };

  filterSeat = (item) => {
    for (let i = 0; i < this.state?.newDesks?.length; i++) {
      if (this.state?.newDesks?.[i]?._id === item?._id) return true;
    }
    return false;
  };

  handleChange = (name) => (event) => {
    let { resource_type } = this.state;
    let value = event?.target?.value ?? event?.target?.checked ?? event;
    this.setState(
      {
        [name]: value,
        resourceTypeError: "",
        seatSwapStatusError: "",
        newDesksError: [],
        adminRemarkError: "",
      },
      () => {
        if (name === "resource_type" && resource_type !== value) {
          this.setState({
            newDesks: [],
          });
        }
      }
    );
  };

  hasError = () => {
    let { resource_type, seat_swap_status, newDesks, admin_remark } =
      this.state;
    const { selectedBooking } = this.props;
    let resourceTypeError = "";
    let resourceError = "";
    let customerRemarkError = "";
    let seatSwapStatusError = "";
    let newDesksError = [];
    let adminRemarkError = "";
    let isErrorHit = false;

    if (!resource_type) {
      resourceTypeError = "Please select resource type";
      isErrorHit = true;
    }

    if (seat_swap_status === "Completed") {
      selectedBooking?.items?.map((item, i) => {
        if (!newDesks[i]) {
          newDesksError[i] = "Please select desk";
          isErrorHit = true;
        } else {
          newDesksError[i] = "";
        }
      });
    } else if (seat_swap_status === "Deleted") {
      if (!admin_remark) {
        adminRemarkError = "Please enter remark";
        isErrorHit = true;
      }
    } else {
      seatSwapStatusError = "Select approval status";
      isErrorHit = true;
    }

    this.setState({
      resourceTypeError,
      resourceError,
      customerRemarkError,
      seatSwapStatusError,
      newDesksError,
      adminRemarkError,
    });
    return isErrorHit;
  };

  onBookNow = () => {
    if (!this.hasError()) {
      const { resource_type, seat_swap_status, newDesks, admin_remark } =
        this.state;
      const { activeWorkspace, selectedBooking } = this.props;

      let bookResProps = {
        _id: selectedBooking?._id,
        space_id: activeWorkspace,
        type: resource_type,
        seat_swap_status,
      };

      if (seat_swap_status === "Completed") {
        bookResProps.ids = newDesks?.map((item) => item?._id);
        bookResProps.admin_remark = admin_remark;
        this.props.changeBookedResourceApprove(bookResProps);
      } else if (seat_swap_status === "Deleted") {
        bookResProps.admin_remark = admin_remark;
        this.props.updateBooking(bookResProps);
      }
    }
  };

  render() {
    const {
      visible,
      selectedBooking,
      handleModal,
      availableResources,
      bookingStatus,
      editable,
    } = this.props;
    const {
      resource_type,
      customer_remark,
      filter,
      newDesks,
      newDesksError,
      seat_swap_status,
      seatSwapStatusError,
      admin_remark,
      adminRemarkError,
    } = this.state;

    let disabled = bookingStatus === "past" || selectedBooking?.loading;
    let isRejected = false;
    selectedBooking?.items?.forEach((item, i) => {
      if (!isRejected) {
        isRejected = newDesks?.[i]?._id === item?._id;
      }
    });

    const from_to_date = selectedBooking?.from &&
      selectedBooking?.to && [
        selectedBooking?.from && moment(selectedBooking?.from),
        selectedBooking?.to && moment(selectedBooking?.to),
      ];
    const capacity = selectedBooking?.capacity;
    return (
      <CustomModal
        width={700}
        visible={visible}
        title={"Swap Desk"}
        onCancel={handleModal}
        footer={
          <>
            <Button onClick={handleModal}>Close</Button>
            {editable && bookingStatus !== "past" && (
              <>
                <Button
                  type="primary"
                  onClick={this.onBookNow}
                  loading={selectedBooking?.loading}
                >
                  Submit
                </Button>
              </>
            )}
          </>
        }
      >
        <div
          className="--avatar mb10"
          style={{ width: "100%", backgroundColor: "#EEEDFD", fontWeight: 500 }}
        >
          Booking resources
        </div>

        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Desk Type</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col span={18}>
            <Select
              className="custom-select fw"
              placeholder="Select resource type"
              size="large"
              allowClear
              value={resource_type}
              onChange={this.handleChange("resource_type")}
              disabled={disabled || !editable}
            >
              {this.resourceType.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  <span>{name}</span>
                </Select.Option>
              ))}
            </Select>
            <Row className="error mt5">{this.state.resourceTypeError}</Row>
          </Col>
        </Row>

        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Booking Date</label>
          </Col>

          <Col span={18}>
            <Space direction="horizontal" size={12}>
              <span>
                {moment(from_to_date?.[0]).format("DD-MM-YYYY")}
                <SwapRightOutlined className="ml10 mr10" />
                {moment(from_to_date?.[1]).format("DD-MM-YYYY")}
              </span>
            </Space>

            <Row className="error mt5">{this.state.fromToDateError}</Row>
          </Col>
        </Row>

        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Capacity</label>
          </Col>
          <Col span={18}>
            <span>{capacity}</span>
          </Col>
        </Row>

        <div
          className="--avatar mb15 mt15"
          style={{ width: "100%", backgroundColor: "#EEEDFD", fontWeight: 500 }}
        >
          Desk Swap Request
        </div>

        <Row align="top" className="mt10">
          <Col span={6}>
            <label className="label">Member's Remark</label>
          </Col>

          <Col span={18}>{customer_remark}</Col>
        </Row>

        <Row align="top" className="mt10">
          <Col span={6}>
            <label className="label">Your approval</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col span={18}>
            <Select
              className="custom-select fw"
              size="large"
              placeholder="Select your approval"
              value={seat_swap_status}
              onChange={this.handleChange("seat_swap_status")}
              allowClear
              disabled={bookingStatus === "past" || !editable}
            >
              {[
                {
                  _id: "Completed",
                  value: "Accept",
                },
                {
                  _id: "Deleted",
                  value: "Reject",
                },
              ].map((item) => (
                <Select.Option key={item?._id} value={item?._id}>
                  {item?.value}
                </Select.Option>
              ))}
            </Select>

            <Row className="error mt5">{seatSwapStatusError}</Row>
          </Col>
        </Row>

        {seat_swap_status === "Completed" &&
          selectedBooking?.items?.map((item, i) => (
            <Row align="middle" className="mt10" key={i}>
              <Col span={6}>
                <label className="label">Desk {i + 1}</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>

              <Col span={18}>
                <Row align={"middle"} justify={"space-between"}>
                  <Col span={7}>
                    <Select
                      className="custom-select fw"
                      size="large"
                      defaultValue={"Accept"}
                      placeholder="Approval"
                      onChange={(e) => this.handleSeatSwap(e, i)}
                      disabled={bookingStatus === "past" || !editable}
                    >
                      <Select.Option value={null}>Accept</Select.Option>
                      <Select.Option value={JSON.stringify(item)}>
                        Reject
                      </Select.Option>
                    </Select>
                  </Col>

                  <Row>{item?.seat_no}</Row>
                  <SwapRightOutlined />

                  <Col span={7}>
                    <Select
                      className="custom-select fw"
                      size="large"
                      placeholder="Select desk"
                      value={newDesks?.[i]?.seat_no}
                      onChange={(e) => this.handleSeatSwap(e, i)}
                      allowClear
                      disabled={
                        bookingStatus === "past" ||
                        !editable ||
                        newDesks?.[i]?._id === item?._id
                      }
                    >
                      {filter &&
                        availableResources &&
                        availableResources[filter]?.list?.map(
                          (resource) =>
                            resource &&
                            resource?.items
                              ?.filter((item) => !this.filterSeat(item))
                              ?.map((item) => (
                                <Select.Option
                                  key={item?._id}
                                  value={JSON.stringify(item)}
                                >
                                  {item?.seat_no}
                                </Select.Option>
                              ))
                        )}
                    </Select>

                    <Row className="error">{newDesksError?.[i]}</Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}

        {(seat_swap_status === "Deleted" || isRejected) && (
          <Row align="top" className="mt10">
            <Col span={6}>
              <label className="label">Admin's Remark</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18}>
              <Input.TextArea
                className="custom-input"
                placeholder="Add remark"
                allowClear
                value={admin_remark}
                onChange={this.handleChange("admin_remark")}
                disabled={!editable}
              />

              <Row className="error mt5">{adminRemarkError}</Row>
            </Col>
          </Row>
        )}

        {bookingStatus === "past" && (
          <Row className="--avatar red mt15" style={{ width: "100%" }}>
            You cannot update an event from the past
          </Row>
        )}
        {/* {bookingStatus === 'ongoing' && <Row className='--avatar red mt15' style={{width:'100%'}}>You cannot update an ongoing event</Row>} */}
      </CustomModal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { bookingId } = ownProps;

  return {
    activeWorkspace: state?.workSpace?.active,
    selectedBooking: state?.booking?.bookingMap?.[bookingId],
    availableResources: state?.booking?.availableResources,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllAvailableResources: (obj) => dispatch(getAllAvailableResources(obj)),
    updateBooking: (obj) => dispatch(updateBooking(obj)),
    changeBookedResourceApprove: (obj) =>
      dispatch(changeBookedResourceApprove(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskSwapRequest);
