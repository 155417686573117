import { Button } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Routes from "../../config/Routes";
import SearchInput from "./SearchInput";

const Locationhome = () => {
  const [space, setSpace] = useState();
  const history = useHistory();

  const onFindWorkspace = () => {
    if (space) {
      history.push(`${Routes.space}/${space}`);
    }
  };

  return (
    <>
      <div className="nw_loc_con">
        <div className="nw_loc_inner_con">
          <div className="nw_i_c">
            <h1>
              The new <span>office for you</span>
            </h1>
            <p>
              Whether you’re an established enterprise or a growing startup,
              discover spaces that inspire your most impactful work.
            </p>

            <div className="nw_l_s_c">
              <div className="nw_l_s_i_c">
                <span>
                  <SearchInput
                    placeholder="Workplace"
                    value={space}
                    onChange={(value) => setSpace(value)}
                  />
                </span>
              </div>

              <Button
                block
                className="btn_l_c"
                onClick={onFindWorkspace}
                // disabled={!space}
              >
                Find Workspace
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Locationhome;
