import React, { useCallback, useEffect, useState } from "react";
import CustomModal from "../../utils/CustomModal";
import { Row, Col, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addItem, updateItem } from "../duck/ResourceActions";
import { useParams } from "react-router-dom";

const AddSeat = (props) => {
  const DESK_TYPE = {
    dedicated_desk: "Dedicated Desk",
    // hot_desk: 'Hot Desk',
    flexi_desk: "Flexi Desk",
  };
  Object.freeze(DESK_TYPE);

  const { selectedSeat, visible } = props;
  const workSpace = useSelector((state) => state?.workSpace);
  const resource_id = useParams()?.id;
  const dispatch = useDispatch();

  const [seatObj, setSeatObj] = useState({});
  const [error, handleError] = useState({});

  useEffect(() => {
    setSeatObj({
      seat_no: selectedSeat?.seat_no || undefined,
      space_id: selectedSeat?.space_id || workSpace?.active,
      resource_id: selectedSeat?.resource_id ?? resource_id,
      active: selectedSeat?.active ?? true,
      desk: selectedSeat?.desk_type || undefined,
    });
  }, [workSpace?.active, selectedSeat, visible]);

  const _addSeat = () => {
    if (seatObj?.desk) {
      seatObj.type = "desks";
      if (selectedSeat) {
        dispatch(updateItem({ _id: selectedSeat?._id, ...seatObj }));
      } else {
        dispatch(addItem(seatObj));
      }
      props.showModal(false);
    } else {
      let error = {
        seat_no: null,
        desk: null,
      };
      if (!seatObj.seat_no || seatObj?.seat_no?.trim() === "") {
        error.seat_no = "Seat no cannot be blank";
      }
      if (!seatObj.desk || seatObj?.desk?.trim() === "") {
        error.desk = "Please select seat type";
      }

      handleError(error);
    }
  };

  const add = useCallback(_addSeat, [seatObj]);

  return (
    <CustomModal
      visible={visible}
      title={selectedSeat ? "Update Seat" : "Add Seat"}
      okButtonText={selectedSeat ? "Update" : "Add"}
      cancelButtonText="Cancel"
      onCancel={() => props.showModal(false)}
      handleSuccess={add}
    >
      <div>
        <Row align="middle">
          <Col span={6}>
            <label className="label">Seat No.</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18}>
            {selectedSeat ? (
              <span>{seatObj?.seat_no}</span>
            ) : (
              <>
                <Input
                  className="custom-input"
                  placeholder="h1"
                  size="large"
                  autoFocus
                  allowClear
                  value={seatObj?.seat_no}
                  disabled={!!selectedSeat}
                  onChange={(e) => {
                    setSeatObj({ ...seatObj, seat_no: e?.target?.value });
                    handleError({});
                  }}
                />
                <Row className="error mt5">{error?.seat_no}</Row>
              </>
            )}
          </Col>
        </Row>
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Type</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18}>
            <Select
              className="custom-select fw"
              placeholder="Select desk type"
              size="large"
              allowClear
              value={seatObj?.desk}
              onChange={(e) => {
                setSeatObj({ ...seatObj, desk: e });
                handleError({});
              }}
            >
              {Object.keys(DESK_TYPE)?.map((id) => (
                <Select.Option key={id} value={id}>
                  {DESK_TYPE[id]}
                </Select.Option>
              ))}
            </Select>
            <Row className="error mt5">{error?.desk}</Row>
          </Col>
        </Row>
      </div>
    </CustomModal>
  );
};

export default AddSeat;
