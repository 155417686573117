import React, { useLayoutEffect } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import {
  isUserAuthenticated,
  showNotification,
} from "../utils/CommonFunctions";
import AppUrl from "../config/AppUrl";
import axios from "axios";
import { Result, Row } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import Routes from "../config/Routes";

const AccountActivation = () => {
  const history = useHistory();
  const token = useLocation()?.search?.split("=")?.[1];

  useLayoutEffect(() => {
    if (token) {
      const activationEmail = async () => {
        try {
          const userRes = await axios.get(AppUrl.VERIFY_EMAIL, {
            params: { token },
          });
          let userData = userRes.data;
          if (
            userData?.meta?.success === false ||
            userData?.meta?.status !== 200
          ) {
            showNotification("error", userData?.meta?.message);
          } else {
            showNotification("success", "Account activated successfully!");
            localStorage.setItem("accessToken", userData?.data?.token);
            setTimeout(() => {
              if (localStorage.getItem("bookResource")) {
                history.push(`${Routes.payNow}/new_booking`);
              } else {
                history.push(Routes.dashboard);
              }
            }, 1000);
          }
        } catch (error) {
          showNotification("error", error);
        }
      };
      activationEmail();
    }
  }, [token]);

  if (isUserAuthenticated()) {
    return <Redirect to={Routes.dashboard} />;
  }

  return (
    <Row justify="center" align="middle" className="fh">
      <Result
        icon={<SettingOutlined spin />}
        title="Please wait, your account is getting activated!"
      />
    </Row>
  );
};

export default AccountActivation;
