import { put, takeLatest, call } from "redux-saga/effects";
import { showNotification } from "../../utils/CommonFunctions";
import ACTIONS from "./InvoiceActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const getAllInvoicesRequest = (params = {}) => {
  return axios({
    method: "GET",
    url: AppUrl.INVOICE,
    params: params,
  });
};

function* getAllInvoices(action) {
  try {
    let resources = yield call(getAllInvoicesRequest, action.payload);
    let _data = resources?.data;
    delete action.payload.offset;
    delete action.payload.limit;

    _data.filter = JSON.stringify(action.payload);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.FETCH_ALL_INVOICES_FAILURE,
        payload: _data,
      });
    } else {
      yield put({
        type: ACTIONS.FETCH_ALL_INVOICES_SUCCESS,
        payload: _data,
      });
    }
  } catch (error) {
    console.log("get all invoices error", error);
  }
}

// ACTION WATCHER
export default function* invoiceSaga() {
  yield takeLatest(ACTIONS.FETCH_ALL_INVOICES, getAllInvoices);
}
