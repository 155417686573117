import React from "react";
import { Table } from "antd";

import NoDataFound from "./NoDataFound";

const MTable = (props) => {
  return (
    <Table
      className={props.className}
      dataSource={props.dataSource}
      columns={props.columns}
      pagination={false}
      bordered={false}
      locale={{
        emptyText: <NoDataFound />,
      }}
      scroll={{
        x: "calc(700px + 35%)",
      }}
      {...props}
    />
  );
};

export default MTable;
