import React from "react";
import { Redirect, Route } from "react-router-dom";
import Routes from "../config/Routes";
import { isUserAuthenticated } from "../utils/CommonFunctions";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      exact
      render={(props) => {
        return isUserAuthenticated() ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: Routes.login,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
