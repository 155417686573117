const Actions = {
  FETCH_ALL_ROLES: "FETCH_ALL_ROLES",
  FETCH_ALL_ROLES_SUCCESS: "FETCH_ALL_ROLES_SUCCESS",
  FETCH_ALL_ROLES_FAILURE: "FETCH_ALL_ROLES_FAILURE",

  GET_ONE_ROLE: "GET_ONE_ROLE",
  GET_ONE_ROLE_SUCCESS: "GET_ONE_ROLE_SUCCESS",
  GET_ONE_ROLE_FAILURE: "GET_ONE_ROLE_FAILURE",

  ADD_ROLE: "ADD_ROLE",
  ADD_ROLE_SUCCESS: "ADD_ROLE_SUCCESS",
  ADD_ROLE_FAILURE: "ADD_ROLE_FAILURE",

  UPDATE_ROLE: "UPDATE_ROLE",
  UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
  UPDATE_ROLE_FAILURE: "UPDATE_ROLE_FAILURE",
};

export default Actions;
