import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Input, Button } from "antd";
import { useSelector } from "react-redux";
import Checkbox from "antd/lib/checkbox/Checkbox";
import CustomModal from "../../utils/CustomModal";

const AddRecord = (props) => {
  const { selectedRecord, records, editable, visible } = props;
  const prevProp = useRef();

  let modalTitle = editable
    ? selectedRecord
      ? "Update Logic"
      : "Add Logic"
    : "Logic";

  const [recordObj, setRecordObj] = useState({});
  const [error, handleError] = useState({});
  const loading = useSelector((state) => state?.workSpace?.loading);

  const preload = () => {
    if (props.visible) {
      setRecordObj((preRecord) => ({
        ...preRecord,
        min: Number(selectedRecord?.min || 0),
        max: Number(selectedRecord?.max || 0),
        price: Number(selectedRecord?.value || 0),
        active: selectedRecord?.status ?? true,
      }));
      handleError({});
    }
  };

  useEffect(() => {
    if (prevProp?.current?.loading && !loading) {
      props.showModal(false);
    } else if (!prevProp?.current?.visible && visible) preload();

    return () => {
      prevProp.current = { loading, visible };
    };
  }, [visible, loading]);

  const isValidRange = (value = 0) => {
    let isErrorHit = false;

    value = value && Number(value);
    if (!value) return;

    records?.forEach?.((r) => {
      if (r?.index !== selectedRecord?.index) {
        isErrorHit =
          isErrorHit || (r?.status && r?.min <= value && value <= r?.max);
      }
    });

    return !isErrorHit;
  };

  const hasError = () => {
    let { min, max, price } = recordObj;
    min = Number(min);
    max = Number(max);
    price = Number(price);

    let error = {};

    if (!min || Number(min) <= 0) {
      error.min = "Lower limit must be greater than 0";
    } else if (min > max) {
      error.min = "Lower limit must be less then or equal to Upper limit";
    } else if (!isValidRange(min)) {
      error.min = "Lower limit is already in use";
    }

    if (!max || Number(max) <= 0) {
      error.max = "Upper limit must be greater than 0";
    } else if (min > max) {
      error.max = "Upper limit must be greater than or equal to Lower limit";
    } else if (!isValidRange(max)) {
      error.max = "Upper limit is already in use";
    }

    if (!price || Number(price) <= 0) {
      error.price = "Price percentage must be greater than 0";
    }

    handleError(error);
    return Object.keys(error).length > 0;
  };

  const add = () => {
    if (!hasError()) {
      props.onSave &&
        props.onSave({
          _id: selectedRecord?._id,
          min: recordObj?.min,
          max: recordObj?.max,
          value: recordObj?.price,
          status: recordObj?.active,
        });
    }
  };

  const handleChange =
    (name, isCheckbox = false) =>
    (event) => {
      let value = isCheckbox ? event?.target?.checked : event?.target?.value;

      setRecordObj((preState) => ({
        ...preState,
        [name]: value,
      }));
      handleError({});
    };

  return (
    <CustomModal
      visible={visible}
      title={modalTitle}
      onCancel={() => props.showModal(false)}
      footer={
        <>
          <Button onClick={() => props.showModal(false)}>Close</Button>
          {editable && (
            <Button type="primary" loading={loading} onClick={add}>
              {selectedRecord ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      <Row align="middle" className="mt10">
        <Col span={6}>
          <label className="label">Lower Limit</label>
          <sup style={{ color: "red" }}>*</sup>
        </Col>

        <Col span={18}>
          <Input
            className="custom-input"
            placeholder="Lower Limit"
            size="large"
            type="number"
            min={0}
            max={100}
            step={1}
            style={{ width: 150 }}
            value={recordObj?.min}
            onChange={handleChange("min")}
            disabled={!editable || loading}
          />
          <span style={{ color: "darkgray" }}>&nbsp; %</span>

          <Row className="error mt5">{error?.min}</Row>
        </Col>
      </Row>

      <Row align="middle" className="mt10">
        <Col span={6}>
          <label className="label">Upper Limit</label>
          <sup style={{ color: "red" }}>*</sup>
        </Col>

        <Col span={18}>
          <Input
            className="custom-input"
            placeholder="Upper Limit"
            size="large"
            type="number"
            min={0}
            max={100}
            step={1}
            style={{ width: 150 }}
            value={recordObj?.max}
            onChange={handleChange("max")}
            disabled={!editable || loading}
          />
          <span style={{ color: "darkgray" }}>&nbsp; %</span>

          <Row className="error mt5">{error?.max}</Row>
        </Col>
      </Row>

      <Row align="middle" className="mt10">
        <Col span={6}>
          <label className="label">Increase Price By</label>
          <sup style={{ color: "red" }}>*</sup>
        </Col>

        <Col span={18}>
          <Input
            className="custom-input"
            placeholder="Price"
            size="large"
            type="number"
            min={0}
            max={100}
            step={1}
            style={{ width: 150 }}
            value={recordObj?.price}
            disabled={!editable || loading}
            onChange={handleChange("price")}
          />
          <span style={{ color: "darkgray" }}>&nbsp; %</span>

          <Row className="error mt5">{error?.price}</Row>
        </Col>
      </Row>

      <Row align="top" className="mt10">
        <Col span={6}></Col>
        <Col span={18}>
          <Checkbox
            checked={recordObj?.active}
            disabled={!editable || loading}
            onChange={handleChange("active", true)}
          >
            Active
          </Checkbox>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default AddRecord;
