import React from 'react';
import { Badge, Button, Card, Col, Row, Tag, Typography } from 'antd';
import { UserOutlined, CalendarOutlined, FallOutlined } from '@ant-design/icons';

import { resourceTypes } from '../../utils/CommonFunctions';

const PlanResourcesItem = (props = {}) => {
    const { resource = {}, filter, } = props;

    const mFilter = filter && JSON.parse(filter);
    const resource_type = mFilter?.type;
    const resType = resourceTypes[resource_type];
    const plans = resource?.plan;

    return plans?.map?.((plan, pIndex) => (
        <Col key={pIndex} span={24}>
            <Badge.Ribbon
                placement="start"
                text={plan?.name}
                style={{ top: -8 }}
                color="geekblue"
            >
                <Card
                    bodyStyle={{ padding: 0 }}
                    bordered={true}
                    style={{ margin: '10px 10px 10px 0', borderRadius: 5 }}
                    hoverable
                >
                    <Row style={{ height: 150 }} align="middle">
                        <Col
                            span={21}
                            className='p20'
                            style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
                        >
                            {
                                resource_type?.includes?.("desk")
                                ? (
                                    <Col>
                                        <p
                                            style={{ fontSize: 18, fontWeight: 600 }}
                                        >{resType?.name} {resource?.items.map((item, i) => `${item?.seat_no}${(resource?.items?.length - 1) > i ? ", " : ""}`)}</p>
                                        <Typography.Paragraph
                                            style={{ fontWeight: 400 }}
                                            ellipsis={{ rows: 2, tooltip: resource?.description }}
                                        >
                                            <span style={{ fontWeight: "bold" }} >Area:</span> {resource?.name}
                                        </Typography.Paragraph>
                                    </Col>
                                ) : (
                                    <Col>
                                        <p style={{ fontSize: 18, fontWeight: 600 }}>
                                            {`${resource?.name} `}
                                            <Tag color="lime" style={{ borderRadius: 10 }}>Save {parseFloat(plan?.discount || 0).toFixed(2)}% <FallOutlined /> on your booking</Tag>
                                        </p>
                                        <Typography.Paragraph
                                            style={{ fontWeight: 400 }}
                                            ellipsis={{ rows: 2, tooltip: resource?.description }}
                                        >{resource?.description}</Typography.Paragraph>
                                    </Col>
                                )
                            }

                            <Row align="middle" justify="space-between">
                                <Row align="middle">
                                    {
                                        !resource_type?.includes?.("desk") &&
                                        <samp className="mr10">
                                            <UserOutlined className='mr10' />
                                            {resource?.capacity}
                                        </samp>
                                    }

                                    {
                                        plan?.tenure &&
                                        <samp className="mr15">
                                            <CalendarOutlined className="mr10" />
                                            {plan?.tenure} Days
                                        </samp>
                                    }

                                    <samp className="mr15" style={{ fontSize: 16, fontWeight: 600 }}>
                                        {`£ ${parseFloat(plan?.dynamic_price?.discounted_net).toFixed(2)}`}
                                    </samp>

                                    <samp className="mr15" style={{ textDecoration: "line-through" }}>
                                        {`£ ${parseFloat(plan?.dynamic_price?.net).toFixed(2)}`}
                                    </samp>
                                </Row>
                            </Row>
                        </Col>

                        <Button type="primary-outline" onClick={() => props.onBookNow?.(resource?._id, plan?._id)}>Select Plan</Button>
                    </Row>
                </Card>
            </Badge.Ribbon>
        </Col>
    ))
}

export default PlanResourcesItem;