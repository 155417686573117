import React, { useMemo } from "react";
import { Card, Tooltip, Typography } from "antd";
import { useSelector } from "react-redux";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { getTagFreeContent } from "../../utils/CommonFunctions";

const { Paragraph } = Typography;

const AboutUsTabCard = (props) => {
  const { id, isCMSEditable, showExtra = true } = props;

  const mCms = useSelector((state) => state?.cms?.cmsMap?.[id]);
  const ExtraIcon = useMemo(
    () => (isCMSEditable ? EditOutlined : EyeOutlined),
    [isCMSEditable]
  );

  return (
    <Card
      style={{
        width: 300,
        margin: "10px 20px 10px 0",
        borderRadius: 5,
      }}
      title={getTagFreeContent(mCms?.name)}
      bordered={false}
      hoverable
      extra={
        showExtra && (
          <Tooltip title={isCMSEditable ? "Edit" : "More Details"}>
            <ExtraIcon
              style={{ fontSize: 12 }}
              onClick={() => props?.showModal?.(true, id)}
            />
          </Tooltip>
        )
      }
    >
      <Paragraph ellipsis={{ rows: 5 }}>
        {getTagFreeContent(mCms?.description)}
      </Paragraph>
    </Card>
  );
};

export default AboutUsTabCard;
