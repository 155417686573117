import React, { useState, useRef, useEffect, useCallback } from "react";
import { Row, Col, Input } from "antd";
import CustomModal from "../../utils/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../../login/duck/LoginActions";
import { isValidPassword } from "../../utils/CommonFunctions";

const ChangeProfilePassword = (props) => {
  const dispatch = useDispatch();
  const prevProp = useRef();
  const { visible, showModal } = props;

  const loading = useSelector((state) => state?.user?.loading);

  const [state, setState] = useState({});
  const [error, setError] = useState({});

  const { oldPassword, newPassword, confirmPassword } = state;

  useEffect(() => {
    if (prevProp?.current?.loading && !loading) {
      showModal(false);
    } else if (!prevProp?.current?.visible && visible) {
      setState({});
      setError({});
    }
    return () => {
      prevProp.current = { loading, visible };
    };
  }, [visible, loading]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((preState) => ({
        ...preState,
        [name]: value,
      }));
      setError({});
    },
    []
  );

  const hasError = () => {
    let { oldPassword, newPassword, confirmPassword } = state;
    oldPassword = oldPassword?.trim?.();
    newPassword = newPassword?.trim?.();
    confirmPassword = confirmPassword?.trim?.();

    let error = {};

    if (!oldPassword) {
      error.oldPassword = "Please enter old password";
    }

    if (!newPassword) {
      error.newPassword = "Please enter new password";
    } else if (!isValidPassword(newPassword)) {
      error.newPassword = "Password length should be of minimum 4 characters";
    } else if (oldPassword === newPassword) {
      error.newPassword = "New Password cannot be same as old password";
    }

    if (!confirmPassword) {
      error.confirmPassword = "Please enter confirm password";
    } else if (!isValidPassword(confirmPassword)) {
      error.confirmPassword =
        "Password length should be of minimum 4 characters";
    } else if (newPassword !== confirmPassword) {
      error.confirmPassword = "New password and Confirm password not same";
    }

    setError(error);
    return !!Object.keys(error).length;
  };

  const onSubmit = () => {
    if (!hasError()) {
      let userData = {
        oldPassword,
        password: newPassword,
      };
      dispatch(updatePassword(userData));
    }
  };

  return (
    <CustomModal
      visible={visible}
      title="Change Password"
      okButtonText="Submit"
      cancelButtonText="Cancel"
      onCancel={showModal}
      handleSuccess={onSubmit}
      loading={loading}
      width={600}
    >
      <div>
        <Row align="middle" className="mb10">
          <Col lg={6} md={24} sm={24} xs={24}>
            <label className="label">Old Password </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col lg={18} md={24} sm={24} xs={24}>
            <Input.Password
              className="custom-input"
              size="large"
              name="oldPassword"
              value={oldPassword}
              onChange={handleChange("oldPassword")}
              allowClear
            />
            <Row className="error mt5">{error?.oldPassword}</Row>
          </Col>
        </Row>

        <Row align="middle" className="mb10">
          <Col lg={6} md={24} sm={24} xs={24}>
            <label className="label">New Password </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col lg={18} md={24} sm={24} xs={24}>
            <Input.Password
              className="custom-input"
              size="large"
              name="newPassword"
              value={newPassword}
              onChange={handleChange("newPassword")}
              allowClear
            />
            <Row className="error mt5">{error?.newPassword}</Row>
          </Col>
        </Row>

        <Row align="middle" className="mb10">
          <Col lg={6} md={24} sm={24} xs={24}>
            <label className="label">Confirm Password</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col lg={18} md={24} sm={24} xs={24}>
            <Input.Password
              className="custom-input"
              size="large"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleChange("confirmPassword")}
              allowClear
            />
            <Row className="error mt5">{error?.confirmPassword}</Row>
          </Col>
        </Row>
      </div>
    </CustomModal>
  );
};

export default ChangeProfilePassword;
