import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Space,
  TimePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { TeamOutlined } from "@ant-design/icons";

import CustomModal from "../../utils/CustomModal";

import {
  bookResourceWithoutPay,
  getAllAvailableResources,
} from "../../booking/duck/BookingActions";

import {
  disabledDate,
  disabledHours,
  disabledMinutes,
  formatDateTime,
  isValidMobileNumber,
  resourceTypes,
  addMinutesToTime,
  durationMinutes,
} from "../../utils/CommonFunctions";
import constants from "../../utils/Constants";

const { COUNTRY_CODES } = constants;
const { RangePicker } = DatePicker;

const AddBookingRequestModal = (props) => {
  const { visible, handleModal } = props;

  const prevProp = useRef();
  const dispatch = useDispatch();

  const [state, setState] = useState({});
  const [error, setError] = useState({});
  const [availableBookingBoardKey, setAvailableBookingBoardKey] = useState("");
  const [checkAllConditions, setCheckAllConditions] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const booking = useSelector((state) => state.booking);
  const mAvailableResources = useMemo(
    () => booking?.availableResources[availableBookingBoardKey]?.list,
    [booking?.availableResources, availableBookingBoardKey]
  );

  const activeSpace = useSelector((state) => {
    const { workSpaceMap, active } = state.workSpace;
    return workSpaceMap?.[active];
  });
  useEffect(() => {
    if (prevProp?.current?.loading && !booking?.loading) {
      handleModal();
    } else if (!prevProp?.current?.visible && visible) {
      setState({
        countryCode: COUNTRY_CODES[0],
      });
      setError({});
    }

    return () => {
      prevProp.current = { loading: booking?.loading, visible };
    };
  }, [handleModal, booking?.loading, visible]);

  useEffect(() => {
    let { resourceType, fromToDate, fromDate, fromTime, duration, capacity } =
      state;

    if (resourceType && activeSpace) {
      let startDateTime;
      let endDateTime;

      if (resourceType === resourceTypes.meeting_room._id) {
        if (!fromTime || !duration) {
          setAvailableBookingBoardKey("");
          return;
        }

        startDateTime = moment(
          `${formatDateTime(fromDate, "d")} ${formatDateTime(fromTime, "t")}`
        );
        endDateTime = moment(startDateTime).add(duration, "hours");
      } else {
        if (!fromToDate) {
          setAvailableBookingBoardKey("");
          return;
        }

        const office_hours_start = activeSpace?.office_hours?.[0];
        const office_hours_end = activeSpace?.office_hours?.[1];

        startDateTime = moment(fromToDate[0]).set({
          hour: moment(office_hours_start).format("HH"),
          minute: moment(office_hours_start).format("mm"),
        });
        endDateTime = moment(fromToDate[1]).set({
          hour: moment(office_hours_end).format("HH"),
          minute: moment(office_hours_end).format("mm"),
        });
      }

      if (!capacity) {
        setAvailableBookingBoardKey("");
        return;
      }

      const availableResParams = {
        from: startDateTime.utc().toDate(),
        to: endDateTime.utc().toDate(),
        capacity: capacity,
        space_id: activeSpace?._id,
        type: resourceType,
      };

      const filter = JSON.stringify(availableResParams);
      if (filter !== availableBookingBoardKey) {
        setAvailableBookingBoardKey(filter);
        setState((prevState) => ({
          ...prevState,
          resourceId: null,
        }));

        dispatch(getAllAvailableResources(availableResParams));
      }
    }
  }, [state, activeSpace, dispatch, availableBookingBoardKey]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      if (name === "mobile") {
        value = value.replace(/[^0-9]/g, "");
      } else if (name === "capacity") {
        value = value.replace(/[^1-9]/g, "");
      }

      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setError({});
    },
    []
  );

  const hasError = useCallback(() => {
    let {
      name,
      countryCode,
      mobile,
      resourceType,
      fromToDate,
      fromDate,
      fromTime,
      duration,
      capacity,
      resourceId,
    } = state;
    name = name?.trim?.();
    capacity = Number(capacity || 0);

    let error = {};

    if (!name) {
      error.name = "Please enter full name";
    }

    // if (!mobile) {
    //   error.mobile = "Please enter your mobile number";
    // } else if (!isValidMobileNumber(countryCode, mobile)) {
    //   error.mobile = "Please enter valid mobile number";
    // }

    if (mobile) {
      if (!isValidMobileNumber(countryCode, mobile)) {
        error.mobile = "Please enter valid mobile number";
      }
    }

    if (!resourceType) {
      error.resourceType = "Please select resource type";
    }

    if (resourceType === resourceTypes.meeting_room._id) {
      if (!fromDate || !fromTime) {
        error.fromDateTime = "Please select booking date and time";
      }

      if (!duration) {
        error.duration = "Please enter booking duration";
      }
    } else {
      if (!fromToDate) {
        error.fromToDate = "Please select booking date";
      }
    }

    if (!capacity || capacity < 1) {
      error.capacity = "Capacity must be greater than 0";
    }

    if (!resourceId) {
      error.resourceId = "Please select available booking option";
    }

    setError(error);
    return Object.keys(error).length;
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      let { name, email, countryCode, mobile, resourceId } = state;
      name = name?.trim?.();
      email = email?.trim?.();
      mobile = mobile?.trim?.();
      const availableBookingParams =
        availableBookingBoardKey && JSON.parse(availableBookingBoardKey);

      let bookingItemIds = [];
      let selectedResource = mAvailableResources?.find?.(
        (res) => res?._id === resourceId
      );

      if (selectedResource?.items?.length > 0) {
        bookingItemIds = selectedResource.items.slice(
          0,
          availableBookingParams.capacity
        );
        bookingItemIds = bookingItemIds.map((i) => i?._id);
      }

      const payload = {
        name,
        ...availableBookingParams,
        ids: bookingItemIds,
        resource_id: resourceId,
        dynamic_price: selectedResource?.dynamic_price,
      };
      if (email) payload.email = email;
      if (mobile) payload.number = `+${countryCode}${mobile}`;

      dispatch(bookResourceWithoutPay(payload));
    }
  }, [
    state,
    availableBookingBoardKey,
    mAvailableResources,
    hasError,
    dispatch,
  ]);

  useEffect(() => {
    let { resourceType, fromDate, fromTime, duration } = state;
    //const currentTime = moment.tz("Europe/London").format("HH:mm:ss");
    const office_hours_start = activeSpace?.office_hours?.[0];
    const office_hours_end = activeSpace?.office_hours?.[1];
    const startDateTime = moment(office_hours_start).format("HH:mm:ss");
    const endDateTime = moment(office_hours_end).format("HH:mm:ss");
    const durationMinutesData = durationMinutes(duration);
    const minutes = durationMinutesData * 60;

    const initialTime = moment(fromTime?._d);
    const newTime = initialTime.clone().subtract(1, "hours");
    const selectTime = newTime.format("HH:mm:ss");

    const case1 =
      resourceType === "meeting_room" &&
      fromDate !== null &&
      fromTime !== null &&
      addMinutesToTime(selectTime) < addMinutesToTime(startDateTime);

    const case2 =
      resourceType === "meeting_room" &&
      fromDate !== null &&
      fromTime !== null &&
      addMinutesToTime(selectTime, minutes) > addMinutesToTime(endDateTime);

    if (case1) {
      setCheckAllConditions(true);
    } else if (case2) {
      setCheckAllConditions(true);
    } else {
      setCheckAllConditions(false);
    }

    console.log(
      startDateTime,
      endDateTime,
      selectTime,
      addMinutesToTime(startDateTime),
      addMinutesToTime(endDateTime),
      addMinutesToTime(selectTime, minutes),

      "initialTime"
    );
  }, [activeSpace?.office_hours, state]);

  useEffect(() => {
    const office_hours_start = activeSpace?.office_hours?.[0];
    const office_hours_end = activeSpace?.office_hours?.[1];
    const startDateTime = moment(office_hours_start).format("H:mm");
    const endDateTime = moment(office_hours_end).format("H:mm");
    const msg = `Our workspace is open from ${startDateTime} to ${endDateTime}. Please contact info@go-bermondsey.co.uk for assistance with bookings outside these hours.`;
    setErrorMsg(msg);
  }, [activeSpace?.office_hours]);

  const prefixSelector = useMemo(
    () => (
      <Select
        className="custom-select"
        defaultValue="44"
        value={state.countryCode}
        onChange={handleChange("countryCode")}
      >
        {COUNTRY_CODES.map((code, i) => (
          <Select.Option key={`${code}_${i}`} value={code}>
            +{code}
          </Select.Option>
        ))}
      </Select>
    ),
    [state.countryCode, handleChange]
  );

  return (
    <CustomModal
      title="Add Booking Request"
      width={750}
      visible={visible}
      onCancel={handleModal}
      footer={
        <>
          <Button onClick={handleModal}>Close</Button>
          {/*  */}
          <Button
            type="primary"
            onClick={onSubmit}
            loading={booking?.loading}
            className={checkAllConditions ? "disableButton" : ""}
          >
            Book Now
          </Button>
        </>
      }
    >
      <>
        <div
          className="--avatar mb15 mt15"
          style={{ width: "100%", backgroundColor: "#EEEDFD", fontWeight: 500 }}
        >
          Customer Information
        </div>

        {checkAllConditions && (
          <Row align="middle" className="mt10">
            <Col span={24} style={{ color: "red", textAlign: "center" }}>
              {errorMsg}
            </Col>
          </Row>
        )}

        <Row align="middle" className="mt10">
          <Col lg={6} md={24} sm={24} xs={24}>
            <label className="label">Full name</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col lg={18} md={24} sm={24} xs={24}>
            <Input
              className="custom-input"
              placeholder="Enter full name"
              value={state.name}
              onChange={handleChange("name")}
              size="large"
            />

            <Row className="error mt5">{error.name}</Row>
          </Col>
        </Row>

        <Row align="middle" className="mt10">
          <Col lg={6} md={24} sm={24} xs={24}>
            <label className="label">Email</label>
          </Col>

          <Col lg={18} md={24} sm={24} xs={24}>
            <Input
              className="custom-input"
              placeholder="Enter email"
              value={state.email}
              onChange={handleChange("email")}
              size="large"
            />

            <Row className="error mt5">{error.email}</Row>
          </Col>
        </Row>

        <Row align="middle" className="mt10">
          <Col lg={6} md={24} sm={24} xs={24}>
            <label className="label">Phone number</label>
            {/* <sup style={{ color: "red" }}>*</sup> */}
          </Col>

          <Col lg={18} md={24} sm={24} xs={24}>
            <Input
              className="custom-input fw"
              name="mobile"
              placeholder="Your phone number"
              addonBefore={prefixSelector}
              value={state.mobile}
              onChange={handleChange("mobile")}
              size="large"
            />

            <Row className="error mt5">{error.mobile}</Row>
          </Col>
        </Row>
      </>

      <>
        <div
          className="--avatar mb15 mt15"
          style={{ width: "100%", backgroundColor: "#EEEDFD", fontWeight: 500 }}
        >
          Booking
        </div>

        {/* Resource Type start */}
        <Row align="middle" className="mt10">
          <Col lg={6} md={24} sm={24} xs={24}>
            <label className="label">Resource</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col lg={18} md={24} sm={24} xs={24}>
            <Select
              className="custom-select fw"
              placeholder="Select resource type"
              value={state.resourceType}
              onChange={handleChange("resourceType")}
              size="large"
              allowClear
            >
              {Object.values(resourceTypes).map(({ _id, name }) => (
                <Select.Option key={_id} value={_id}>
                  {name}
                </Select.Option>
              ))}
            </Select>

            <Row className="error mt5">{error?.resourceType}</Row>
          </Col>
        </Row>
        {/* Resource Type end */}

        {/* Date & time start */}
        {state.resourceType === "meeting_room" ? (
          <>
            <Row align="middle" className="mt10">
              <Col lg={6} md={24} sm={24} xs={24}>
                <label className="label">From</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>

              <Col lg={18} md={24} sm={24} xs={24}>
                <Space direction="horizontal" size={12}>
                  <DatePicker
                    className="custom-input"
                    value={state.fromDate}
                    onChange={handleChange("fromDate")}
                    size="large"
                    format="DD/MM/YYYY"
                    disabledDate={disabledDate({
                      activeDays: activeSpace?.no_of_days,
                    })}
                  />

                  <TimePicker
                    className="custom-input"
                    size="large"
                    format="HH:mm"
                    value={state.fromTime}
                    onSelect={handleChange("fromTime")}
                    onChange={handleChange("fromTime")}
                    minuteStep={5}
                    disabledHours={() =>
                      disabledHours(activeSpace, state.fromDate)
                    }
                    disabledMinutes={() =>
                      disabledMinutes(state.fromDate, state.fromTime)
                    }
                    showNow={false}
                    hideDisabledOptions={true}
                    inputReadOnly={true}
                    // allowClear={true}
                  />
                </Space>

                <Row className="error mt5">{error.fromDateTime}</Row>
              </Col>
            </Row>

            <Row align="middle" className="mt10">
              <Col lg={6} md={24} sm={24} xs={24}>
                <label className="label">Duration</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>

              <Col lg={18} md={24} sm={24} xs={24}>
                <Space direction="horizontal" size={12}>
                  <Input
                    className="custom-input"
                    style={{ width: 150 }}
                    placeholder="In hours"
                    value={state.duration}
                    size="large"
                    type="number"
                    min={1}
                    step={0.5}
                    onChange={handleChange("duration")}
                  />{" "}
                  hr
                </Space>

                <Row className="error mt5">{error.duration}</Row>
              </Col>
            </Row>
          </>
        ) : (
          <Row align="middle" className="mt10">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label className="label">Booking Date</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col lg={18} md={24} sm={24} xs={24}>
              <Space direction="horizontal" size={12}>
                <RangePicker
                  size="large"
                  format="DD/MM/YYYY"
                  value={state.fromToDate}
                  onChange={handleChange("fromToDate")}
                  disabledDate={disabledDate({
                    activeDays: activeSpace?.no_of_days,
                  })}
                  className="custom-input"
                />
              </Space>

              <Row className="error mt5">{error?.fromToDate}</Row>
            </Col>
          </Row>
        )}
        {/* Date & time end */}

        {/* Capacity start */}
        <Row align="middle" className="mt10">
          <Col lg={6} md={24} sm={24} xs={24}>
            <label className="label">Capacity</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col lg={18} md={24} sm={24} xs={24}>
            <Input
              className="custom-input"
              style={{ width: 150 }}
              placeholder="Capacity"
              value={state.capacity}
              onChange={handleChange("capacity")}
              size="large"
              type="number"
              min={1}
              step={1}
            />

            <Row className="error mt5">{error.capacity}</Row>
          </Col>
        </Row>
        {/* Capacity end */}

        {/* Available Booking Option start */}
        <Row align="middle" className="mt10">
          <Col lg={6} md={24} sm={24} xs={24}>
            <label className="label">Available Booking Option</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col lg={18} md={24} sm={24} xs={24}>
            <Select
              className="custom-select fw"
              placeholder="Select your option"
              value={state.resourceId}
              onChange={handleChange("resourceId")}
              size="large"
              allowClear
            >
              {mAvailableResources?.map(
                (resource) =>
                  resource &&
                  typeof state.resourceType === "string" && (
                    <Select.Option key={resource?._id} value={resource?._id}>
                      {resource?.name} - <TeamOutlined />
                      {state.resourceType.includes("desk")
                        ? `${resource?.items?.length ?? 0} seat available `
                        : `${resource?.capacity ?? 0} `}
                      - £{resource?.dynamic_price?.net?.toFixed(2)}
                    </Select.Option>
                  )
              )}
            </Select>

            <Row className="error mt5">{error.resourceId}</Row>
          </Col>
        </Row>
        {/* Available Booking Option end */}
      </>
    </CustomModal>
  );
};

export default AddBookingRequestModal;
