import React from "react";
import { Button, Col, Row } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";

import CustomModal from "../../utils/CustomModal";

const LeadDetails = (props) => {
  const { visible, leadId, handleModal } = props;
  const lead = useSelector(({ leads }) => leads.leadMap[leadId]);

  return (
    <CustomModal
      title={"Lead Details"}
      width={750}
      visible={visible}
      onCancel={handleModal}
      footer={
        <>
          <Button onClick={handleModal}>Close</Button>
        </>
      }
    >
      {lead?.name && (
        <Row align="middle">
          <Col span={6}>
            <label className="label">Full name</label>
          </Col>

          <Col span={18}>{lead?.name}</Col>
        </Row>
      )}

      {lead?.email && (
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Email</label>
          </Col>

          <Col span={18}>{lead?.email}</Col>
        </Row>
      )}

      {lead?.phone && (
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Phone number</label>
          </Col>

          <Col span={18}>{lead?.phone}</Col>
        </Row>
      )}

      {lead?.ad_id && (
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Social Media</label>
          </Col>

          <Col span={18}>{lead?.ad_id}</Col>
        </Row>
      )}

      {lead?.created_at && (
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Created On</label>
          </Col>

          <Col span={18}>
            {moment(lead?.created_at).format("MMM Do YY h:mm a")}
          </Col>
        </Row>
      )}
    </CustomModal>
  );
};

export default React.memo(LeadDetails);
