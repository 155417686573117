import { EyeOutlined } from "@ant-design/icons";
import { Row, Tooltip, Tag } from "antd";
import moment from "moment";

export const memberDashboardColumns = (updateMeeting) => {
  const columns = [
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (location) => <div>{location}</div>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address) => (
        <Row justify="start" align="middle">
          {address}
        </Row>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => (
        <Row justify="start" align="middle">
          {moment(date).format("DD MMM YYYY")}
        </Row>
      ),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (time) => (
        <Row justify="start" align="middle">
          {moment(time).format("hh:mm A")}
        </Row>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => (
        <Row justify="start" align="middle">
          £ {parseFloat(price || 0).toFixed(2)}
        </Row>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Row justify="start" align="middle">
          <Tag
            color={
              status === "Past"
                ? "red"
                : status === "Ongoing"
                ? "orange"
                : "green"
            }
          >
            {status}
          </Tag>
        </Row>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 80,
      align: "center",
      render: (obj) => (
        <Row align="middle" justify="center">
          <Tooltip title="Preview">
            <EyeOutlined onClick={() => updateMeeting(obj)} className="mr15" />
          </Tooltip>
        </Row>
      ),
    },
  ];
  return columns;
};
