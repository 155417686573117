import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Row, Table, Select, Card, Button, Col } from "antd";
import DeskSwapRequest from "./components/DeskSwapRequest";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import resourceSvg from "../assets/svg/resource.svg";
import "../booking/components/calendar.scss";
import { getAllBookingResources } from "../booking/duck/BookingActions";
import NewBooking from "../booking/components/NewBooking";
import { checkPermission, resourceTypes } from "../utils/CommonFunctions";
import { deskSwapColumns } from "./components/DeskSwapColumns";
import MyPagination from "../utils/MyPagination";
import MTable from "../utils/MTable";

const DeskSwap = () => {
  const preProps = useRef();

  const editable = useMemo(() => checkPermission({ row: "deskSwap" }), []);
  const showAllBooking = useMemo(
    () => checkPermission({ initial: "showAllBooking" }),
    []
  );

  const [boardKey, setBoardKey] = useState("");

  const dispatch = useDispatch();
  const activeWorkSpace = useSelector((state) => state?.workSpace?.active);

  const booking = useSelector((state) => state?.booking);
  const mBookingBoard = useMemo(
    () => booking.bookings?.[boardKey] ?? {},
    [booking.bookings, boardKey]
  );

  const bookings = mBookingBoard?.list;

  const [showAddNewBooking, setShowAddNewBooking] = useState(false);
  const [showEditDeskSwap, setShowEditDeskSwap] = useState(false);
  const [selectedBooking, selectBooking] = useState(null);
  const [bookingStatus, setBookingStatus] = useState("new");
  const [filter, setFilter] = useState({ seat_swap_status: "All" });
  const [startIndex, setStartIndex] = useState(0);

  const loadMore = useCallback(
    (offset = 0, limit = 30) => {
      const { meta, list, loading } = mBookingBoard;

      if (!loading && (offset === 0 || meta?.totalCount > list?.length)) {
        const { resource_type, seat_swap_status } = filter;

        let params = {};
        if (showAllBooking && activeWorkSpace)
          params.space_id = activeWorkSpace;
        if (seat_swap_status) params.seat_swap_status = seat_swap_status;
        if (resource_type) params.resource_type = resource_type;

        setBoardKey(JSON.stringify({ ...params }));

        params.offset = offset;
        params.limit = limit;
        dispatch(getAllBookingResources(params));
      }
    },
    [activeWorkSpace, mBookingBoard, filter, showAllBooking, dispatch]
  );

  const onSearch = () => loadMore();
  const onReset = useCallback(() => {
    setFilter({ seat_swap_status: "All" });
    setBoardKey("");
    setStartIndex(0);
  }, []);

  useEffect(() => {
    if (preProps?.current?.activeWorkSpace !== activeWorkSpace) {
      onReset();
    } else if (!Object.keys(mBookingBoard).length) {
      loadMore();
    }

    return () => {
      preProps.current = { activeWorkSpace };
    };
  }, [activeWorkSpace, mBookingBoard, loadMore, onReset]);

  const handleShowNewBooking = (show = false) => {
    show = typeof show === "boolean" && show;
    setShowAddNewBooking(show);
    if (!show) selectBooking(null);
  };

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setFilter((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );
  const handleTableChange = useCallback(
    (pagination) => {
      const { current, pageSize } = pagination;
      setStartIndex((current - 1) * pageSize);

      const { list } = mBookingBoard;
      const endIndex = current * pageSize;

      if (endIndex > list?.length) {
        loadMore(list?.length, endIndex - list?.length);
      }
    },
    [loadMore, mBookingBoard]
  );

  const updateMeeting = (e) => {
    const today = moment();
    let status = "";
    if (today.isBetween(moment(e?.from), moment(e?.to))) {
      status = "ongoing";
      // showNotification('warning', 'You cannot update an ongoing event')
    } else if (moment(e?.from).isBefore(today, "time")) {
      status = "past";
      // showNotification('warning', 'You cannot update an event from the past')
    }
    // else if( moment(e?.from).isAfter(today, 'time')){
    // }
    setBookingStatus(status);
    selectBooking(e);
    handleShowNewBooking(true);
  };

  const handleEditRequest = (show = false) => {
    show = typeof show === "boolean" && show;
    setShowEditDeskSwap(show);
  };

  const updateSwapRequest = (e) => {
    const today = moment();
    let status = "";

    if (today.isBetween(moment(e?.from), moment(e?.to))) {
      status = "ongoing";
    } else if (moment(e?.from).isBefore(today, "time")) {
      status = "past";
    }

    selectBooking(e);
    setBookingStatus(status);
    handleEditRequest(true);
  };

  const data = useMemo(() => {
    let mData = [];

    if (bookings) {
      bookings.forEach((id, index) => {
        const mBooking = booking?.bookingMap?.[id];
        const status = mBooking?.seat_swap_status;

        const resource = [
          mBooking?.items?.map((item) => item?.seat_no).join() ||
          mBooking?.resource_id?.name,
          mBooking?.resource_id?.color,
          resourceTypes?.[mBooking?.type]?.name,
        ];

        mData.push({
          key: id,
          serial: ++index,
          resource,
          date: [mBooking?.from, mBooking?.to],
          members: mBooking?.members,
          action: mBooking,
          status,
        });
      });
    }

    return mData;
  }, [bookings, booking?.bookingMap]);

  return (
    <div>
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Desk Swap</span>
      </Row>

      {!bookings && bookings?.length === 0 && (
        <Row justify="center" align="middle">
          <img src={resourceSvg} alt="" />
        </Row>
      )}
      <Card title="Filter" className="nw_filter_container" size="small" bordered={false}>
        <Select
          className="mr5 nw_mobile_container"
          style={{
            paddingTop: "5px",
            width: "15%",
            fontWeight: "500",
            marginRight: "15px",
          }}
          placeholder={"Resource Type"}
          value={filter?.resource_type}
          onChange={handleChange("resource_type")}
          allowClear
          size="large"
        >
          <Select.Option key="dedicated_desk" value="dedicated_desk">
            Dedicated Desk
          </Select.Option>
          <Select.Option key="flexi_desk" value="flexi_desk">
            Flexi Desk
          </Select.Option>
        </Select>

        <Select
          style={{
            width: "15%",
            fontWeight: "500",
            marginRight: "15px",
          }}
          className="nw_mobile_container"
          placeholder={"Status"}
          value={filter?.seat_swap_status}
          onChange={handleChange("seat_swap_status")}
          size="large"
        >
          <Select.Option key="All" value="All">
            All
          </Select.Option>
          <Select.Option key="Assigned" value="Assigned">
            Pending
          </Select.Option>
          <Select.Option key="Completed" value="Completed">
            Approved
          </Select.Option>
          <Select.Option key="Deleted" value="Deleted">
            Rejected
          </Select.Option>
        </Select>

        <Button className="mr10 nw_btn_black" size="large" type="primary" onClick={onSearch}>
          Search
        </Button>
        <Button className="mr10 nw_btn_white" size="large" type="primary-outline" onClick={onReset}>
          Reset
        </Button>
      </Card>

      <Row>
        <MTable
          className="mt20 fw"
          columns={deskSwapColumns(editable, updateMeeting, updateSwapRequest)}
          dataSource={data.slice(startIndex, startIndex + 10)}
          bordered={false}
          loading={mBookingBoard?.loading}
          pagination={false}
          onChange={handleTableChange}
        />
        <Col span={24}>
          <MyPagination
            totalCount={mBookingBoard?.meta?.totalCount}
            onChangePage={handleTableChange}
          />
        </Col>
      </Row>

      <DeskSwapRequest
        visible={showEditDeskSwap}
        handleModal={handleEditRequest}
        bookingId={selectedBooking?._id}
        selectBooking={selectBooking}
        bookingStatus={bookingStatus}
        editable={editable}
      />

      <NewBooking
        visible={showAddNewBooking}
        bookingId={selectedBooking?._id}
        handleModal={handleShowNewBooking}
        selectedBooking={selectedBooking}
        selectBooking={selectBooking}
        bookingStatus={bookingStatus}
        disabled
      />
    </div>
  );
};

export default DeskSwap;
