import { Button, Checkbox, Col, Input, Row, Select, Upload } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";

import CustomModal from "../../utils/CustomModal";
import MUploadItem from "./MUploadItem";

import AppUrl from "../../config/AppUrl";
import { createCMS, updateCMS } from "../duck/CMSActions";
import { resourceTypes, showNotification } from "../../utils/CommonFunctions";

const AddImageGallery = (props) => {
  const prevProp = useRef();
  const dispatch = useDispatch();

  const { visible, selectedId, showModal, isCMSEditable } = props;

  const cms = useSelector((state) => state?.cms);
  const mCms = useMemo(
    () =>
      selectedId
        ? cms?.cmsMap?.[selectedId]
        : { loading: cms?.loading, error: cms?.error },
    [selectedId, cms]
  );

  const [state, setState] = useState({});
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState({});
  const [dragId, setDragId] = useState();

  const modalTitle = useMemo(
    () =>
      isCMSEditable
        ? selectedId
          ? "Edit Gallery"
          : "Add Gallery"
        : "Gallery Details",
    [isCMSEditable, selectedId]
  );

  useEffect(() => {
    if (prevProp?.current?.loading && !mCms?.loading) {
      setState({});
      showModal?.(false);
    } else if (!prevProp?.current?.visible && visible) {
      const pageMeta = mCms?.meta?.pageMeta;
      setState({
        name: mCms?.name,
        resource_type: mCms?.meta?.resource_type,
        active: mCms?.active,
        metaTitle: pageMeta?.title,
        metaDescription: pageMeta?.description,
      });

      const files = JSON.parse(JSON.stringify([...(mCms?.pictures || [])]));
      setFileList(files);
      setError({});
    }

    return () => {
      prevProp.current = {
        loading: mCms?.loading,
        visible,
      };
    };
  }, [mCms, visible, showModal]);

  const beforeUpload = useCallback((file) => {
    const supportedFileType = ["image/png", "image/jpg", "image/jpeg"];

    let isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification(
        "error",
        "Please upload JEPG, JGP, PNG or GIF file only"
      );
    }
    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const updateImageUpload = useCallback(({ file }) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = function () {
      const width = img.naturalWidth,
        height = img.naturalHeight;

      URL.revokeObjectURL(img.src);
      if (width == 1440 && height == 820) {
        const fileObj = {
          uid: file?.uid,
          name: file?.name,
          size: file?.size,
          type: file?.type,
          thumb_location: URL.createObjectURL(file),
          url: URL.createObjectURL(file),
          tag: "",
          file,
        };

        setError({});
        setFileList((preState) => {
          return [
            ...preState,
            { ...fileObj, extra: { order: preState.length } },
          ];
        });
      } else {
        showNotification("error", "Please upload exact image size of 1440x820");
      }
    };
  }, []);

  const onRemoveImage = (file) => {
    const fileId = file?._id || file?.uid;
    setFileList((preState) =>
      preState
        .filter((p) => (p?._id || p?.uid) !== fileId)
        ?.map((file, i) => ({ ...file, extra: { ...file?.extra, order: i } }))
    );
  };

  const handleTagChange = useCallback((file) => {
    setFileList((preState) =>
      preState.map((perFile) =>
        (perFile._id && perFile._id === file._id) || perFile.uid === file.uid
          ? file
          : perFile
      )
    );

    setError({});
  }, []);

  const handleChange = useCallback(
    (name, isCheckbox) => (e) => {
      let value;
      if (isCheckbox) {
        value = e?.target?.checked;
      } else {
        value = e?.target?.value ?? e;
      }

      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const hasError = useCallback(() => {
    let { name, resource_type, metaTitle, metaDescription } = state;
    name = name?.trim?.();
    metaTitle = metaTitle?.trim?.();
    metaDescription = metaDescription?.trim?.();

    const error = {};

    if (!metaTitle) {
      error.metaTitle = "Title cannot be blank";
    }

    if (!metaDescription) {
      error.metaDescription = "Description cannot be blank";
    }

    if (!resource_type) {
      error.resource_type = "Please select resource type";
    }

    if (resource_type === "other" && !name) {
      error.name = "Name cannot be blank";
    }

    if (!fileList?.length) {
      error.fileList = "Please upload atleast one image";
    }

    setError(error);
    return Object.keys(error).length;
  }, [state, fileList]);

  const onAdd = useCallback(() => {
    if (!hasError()) {
      const { name, resource_type, metaTitle, metaDescription, active } = state;

      const pageMeta = { title: metaTitle, description: metaDescription };
      const payload = {
        type: "ImageGallery",
        files: [],
        tags: [],
        extra: [],
      };
      payload.meta = JSON.stringify({
        resource_type,
        pageMeta,
      });
      payload.active = active;

      if (resource_type === "other") {
        payload.name = name;
      } else {
        payload.name = "";
      }

      fileList?.forEach((picture, i) => {
        if (picture?.file) {
          // payload.files.push({ ...picture?.file, tag: picture?.tag });
          payload.files.push(picture?.file);
          payload.tags.push(picture?.tag || "");
          payload.extra.push({ ...picture?.extra, order: i } || "");
        }
      });
      payload.tags = JSON.stringify(payload.tags);
      payload.extra = JSON.stringify(payload.extra);

      if (selectedId) {
        payload._id = selectedId;
        const oldFileListIds = mCms?.pictures?.map?.(
          (file) => file?._id || file?.uid
        );
        const currentUploadedFileListIds = fileList
          ?.filter?.((file) => !file?.file && file?._id)
          ?.map((file) => file?._id || file?.uid);
        const removedUploadedFileIds = oldFileListIds?.filter?.(
          (id) => !currentUploadedFileListIds?.includes(id)
        );

        removedUploadedFileIds.forEach((id) => {
          axios({
            method: "DELETE",
            url: `${AppUrl.ATTACHMENTS}/${id}`,
          });
        });

        fileList.forEach((file, index) => {
          if (file?._id) {
            const mTag = file.tag?.trim?.();
            //const extra = file?.extra;
            const extra = { order: index, ...file?.extra };
            const mPicture = mCms?.pictures?.find((p) => p?._id === file?._id);

            if (
              mTag !== mPicture.tag ||
              index !== file?.extra?.order ||
              JSON.stringify(extra) !== JSON.stringify(mPicture?.extra)
            ) {
              const data = {
                tag: mTag,
                extra,
                //extra: file?.extra ?? {},
              };

              axios({
                method: "PUT",
                url: `${AppUrl.ATTACHMENTS}/${file?._id}`,
                data,
              });
            }
          }
        });

        dispatch(updateCMS(payload));
      } else {
        dispatch(createCMS(payload));
      }
    }
  }, [mCms?.pictures, selectedId, state, fileList, hasError, dispatch]);

  const disabled = !isCMSEditable || mCms?.loading;

  const handleDragOver = (ev) => {
    ev.preventDefault();
  };

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const dragBox = fileList.find((box) => {
      const id = box._id || box.uid;
      return id === dragId;
    });

    const dropBox = fileList.find((box) => {
      const id = box._id || box.uid;
      return id === ev.currentTarget.id;
    });

    const dragBoxOrder = dragBox?.extra?.order;
    const dropBoxOrder = dropBox?.extra?.order;
    const newBoxState = fileList.map((box) => {
      const id = box._id || box.uid;
      if (id === dragId) {
        box.extra.order = dropBoxOrder;
      }
      if (id === ev.currentTarget.id) {
        box.extra.order = dragBoxOrder;
      }
      return box;
    });
    setFileList(newBoxState);
  };

  return (
    <CustomModal
      className="cms-modal"
      visible={visible}
      title={modalTitle}
      onCancel={props?.showModal}
      footer={
        <>
          <Button onClick={props?.showModal}>Close</Button>
          {isCMSEditable && (
            <Button type="primary" loading={mCms?.loading} onClick={onAdd}>
              {selectedId ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      <div className="content">
        {/* resource start */}
        {/* page meta details start */}
        <Row className="section mb10" align="middle" justify="center">
          Page Meta Details
        </Row>

        {/* title start */}
        <Row className="mb10" align="middle">
          <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
            <label className="label">Title</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
            <Input
              className="custom-input"
              placeholder="Title"
              size="large"
              type="text"
              value={state.metaTitle}
              onChange={handleChange("metaTitle")}
              disabled={disabled}
            />

            <Row className="error mt5">{error.metaTitle}</Row>
          </Col>
        </Row>
        {/* title end */}

        {/* description start */}
        <Row className="mb10">
          <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
            <label className="label">Description </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
            <Input.TextArea
              className="custom-input"
              placeholder="Description"
              size="large"
              type="text"
              value={state.metaDescription}
              onChange={handleChange("metaDescription")}
              autoSize={{ minRows: 2, maxRows: 3 }}
              disabled={disabled}
            />

            <Row className="error mt5">{error.metaDescription}</Row>
          </Col>
        </Row>
        {/* description end */}

        {/* page meta details start */}

        <Row className="section mb10" align="middle" justify="center">
          Gallery Details
        </Row>

        <Row className="mb10" align="middle">
          <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
            <label className="label">Resource</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
            <Select
              className="custom-select fw"
              placeholder={"Select Resource Type"}
              value={state?.resource_type}
              onChange={handleChange("resource_type")}
              disabled={disabled}
              allowClear
            >
              {Object.values(resourceTypes).map((resourceType) => (
                <Select.Option
                  key={resourceType?._id}
                  value={resourceType?._id}
                >
                  {resourceType?.name}
                </Select.Option>
              ))}
              <Select.Option key="other" value="other">
                Other
              </Select.Option>
            </Select>

            <Row className="error mt5">{error?.resource_type}</Row>
          </Col>
        </Row>
        {/* resource end */}

        {/* name start */}
        {state?.resource_type === "other" && (
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Name</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input
                className="custom-input fw mt10"
                placeholder={"Name"}
                size="large"
                allowClear
                value={state?.name}
                onChange={handleChange("name")}
                disabled={disabled}
              />
              <Row className="error mt5">{error?.name}</Row>
            </Col>
          </Row>
        )}
        {/* name end */}

        {/* gallery images end */}
        <Row className="mb10">
          <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
            <label className="label">Images</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
            <Upload
              className="custom-upload-list"
              accept={".jpeg, .jpg, .png"}
              beforeUpload={beforeUpload}
              listType="picture"
              fileList={fileList?.sort(
                (a, b) => a?.extra?.order - b?.extra?.order
              )}
              onRemove={onRemoveImage}
              customRequest={updateImageUpload}
              disabled={disabled}
              itemRender={(originNode, file, _, actions) => (
                <MUploadItem
                  originNode={originNode}
                  file={file}
                  actions={actions}
                  handleTagChange={handleTagChange}
                  handleDragOver={handleDragOver}
                  handleDrag={handleDrag}
                  handleDrop={handleDrop}
                />
              )}
            >
              {/* {fileList?.length <= 4 && ( */}
              <Row
                className="custom-upload-image fw mb10"
                align="middle"
                justify="center"
              >
                <Col className="mt5">
                  <UploadOutlined className="mr5" /> Upload 1440x820
                  <sup style={{ color: "red" }}>*</sup>
                </Col>
              </Row>
              {/* )} */}
            </Upload>
            <Row className="error mt5">{error?.fileList}</Row>
          </Col>
        </Row>
        {/* gallery images end */}

        {/* active start */}
        {selectedId && (
          <Row align="top" className="mt10">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4} />

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Checkbox
                disabled={disabled}
                checked={state?.active}
                onChange={handleChange("active", true)}
              >
                Active
              </Checkbox>
            </Col>
          </Row>
        )}
        {/* active end */}
      </div>
    </CustomModal>
  );
};

export default AddImageGallery;
