import React from "react";
import { Switch } from "antd";

const SubsectionItem = ({ section = {}, handleChange, disabled }) => {
  const onChange = (name) => (value) => {
    if (name === "show" && !value) {
      Object.keys(section).forEach((s) => {
        if (typeof section[s] === "boolean") {
          section[s] = false;
        }
      });
    }

    handleChange && handleChange({ ...section, [name]: value });
  };

  return (
    <>
      <tr>
        <td>{section?.name}</td>
        <td>
          <Switch
            disabled={disabled || disabled}
            onChange={onChange("show")}
            checked={section?.show}
          />

          <span> Show /Hide</span>
        </td>
        <td>
          <Switch
            onChange={onChange("editable")}
            checked={section?.editable}
            disabled={!section?.show || disabled}
          />

          <span> Edit</span>
        </td>
      </tr>
    </>
  );
};

export default SubsectionItem;
