import { put, takeLatest, call } from "redux-saga/effects";
import { showNotification } from "../../utils/CommonFunctions";
import ACTIONS from "./LoginActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const loginRequest = ({ email, password }) => {
  return axios({
    method: "POST",
    url: AppUrl.LOGIN_URL,
    mode: "cors",
    data: {
      email,
      password,
    },
  });
};

const getLoggedInUserRequest = (id) => {
  return axios({
    method: "GET",
    url: `${AppUrl.USERS_URL}/${id}`,
  });
};

const updateLoggedInUserRequest = (params) => {
  return axios({
    method: "PUT",
    url: `${AppUrl.USERS_URL}/${params?._id}`,
    data: params,
  });
};

const updatePasswordRequest = (data) => {
  return axios({
    method: "POST",
    url: `${AppUrl.AUTH_URL}/change-password`,
    data,
  });
};

function* login(action) {
  try {
    let userRes = yield call(loginRequest, action.payload);
    let userData = userRes.data;
    if (userData?.meta?.success === false || userData?.meta?.status !== 200) {
      showNotification("error", userData?.meta?.message);
      yield put({ type: ACTIONS.LOGIN_FAIL, payload: userData?.meta });
    } else {
      localStorage.setItem("accessToken", userData?.data?.token);
      yield put({ type: ACTIONS.LOGIN_SUCCESS, payload: userData?.data?.user });
    }
  } catch (error) {
    console.log("login user error", error);
  }
}

function* getLoggedInUser(action) {
  try {
    let response = yield call(getLoggedInUserRequest, action.payload);
    let resData = response.data;

    if (resData?.meta?.success !== true || resData?.meta?.status !== 200) {
      yield put({
        type: ACTIONS.GET_LOGGED_IN_USER_FAIL,
        payload: resData.meta,
      });
    } else {
      yield put({
        type: ACTIONS.GET_LOGGED_IN_USER_SUCCESS,
        payload: resData?.data,
      });
    }
  } catch (error) {
    console.log("get one user error", error);
  }
}

function* updateLoggedInUser(action) {
  try {
    let response = yield call(updateLoggedInUserRequest, action.payload);
    let resData = response.data;

    if (resData?.meta?.success !== true || resData?.meta?.status !== 200) {
      showNotification("error", resData?.meta?.message);
      yield put({
        type: ACTIONS.UPDATE_LOGGED_IN_USER_FAIL,
        payload: resData?.meta,
      });
    } else {
      showNotification("success", resData?.meta?.message);
      yield put({
        type: ACTIONS.UPDATE_LOGGED_IN_USER_SUCCESS,
        payload: resData.data,
      });
    }
  } catch (error) {
    console.log("update one user error", error);
  }
}

function* updatePassword(action) {
  try {
    let response = yield call(updatePasswordRequest, action.payload);
    let _data = response?.data;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.UPDATE_PASSWORD_FAILURE,
        payload: _data?.meta,
      });
    } else {
      localStorage.setItem("accessToken", _data?.data?.token);
      yield put({ type: ACTIONS.UPDATE_PASSWORD_SUCCESS, payload: _data });
      showNotification("success", _data?.meta?.message);
    }
  } catch (error) {
    console.log("Error while updating password", error);
  }
}

// ACTION WATCHER
export default function* loginSaga() {
  // user can logout without waiting for login action.
  yield takeLatest(ACTIONS.LOGIN_REQUEST, login);
  yield takeLatest(ACTIONS.GET_LOGGED_IN_USER_REQUEST, getLoggedInUser);
  yield takeLatest(ACTIONS.UPDATE_LOGGED_IN_USER_REQUEST, updateLoggedInUser);
  yield takeLatest(ACTIONS.UPDATE_PASSWORD, updatePassword);
}
