import Actions from "./ResourceActionTypes";
import {
  arrToObjMap,
  getArrOrder,
  deskTypeCount,
} from "../../utils/CommonFunctions";

const initialState = {
  resourceMap: {},
  resources: {},
  deskItemsMap: {},
};

const resourcesReducer = (state = initialState, action) => {
  const payload =
    typeof action.payload === "object"
      ? Object.assign({}, action.payload)
      : action.payload;

  switch (action.type) {
    /* fetch all workspace resources start */
    case Actions.FETCH_ALL_WORKSPACE_RESOURCES: {
      const workSpaceId = payload;

      return Object.assign({}, state, {
        resources: Object.assign({}, state?.resources, {
          [workSpaceId]: Object.assign({}, state.resources[workSpaceId], {
            error: null,
            loading: true,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_WORKSPACE_RESOURCES_SUCCESS: {
      const workSpaceId = payload?.space_id;
      const desks = payload?.desks;
      const meeting = payload?.meeting_room;
      const office = payload?.private_office;
      let deskItemsMap = {};

      if (Array.isArray(desks) && desks.length > 0) {
        desks.forEach((desk) => {
          let items = desk?.items;
          items = items?.filter?.((item) => item?.active && item?.status);

          desk.desk_type = deskTypeCount(items);
          desk.items = items;
          deskItemsMap = Object.assign({}, deskItemsMap, arrToObjMap(items));
        });
      }

      return Object.assign({}, state, {
        resourceMap: Object.assign(
          {},
          state.resourceMap,
          arrToObjMap(desks),
          arrToObjMap(office),
          arrToObjMap(meeting)
        ),
        resources: Object.assign({}, state.resources, {
          [workSpaceId]: Object.assign({}, state.resources[workSpaceId], {
            desks: getArrOrder(desks),
            meetingRooms: getArrOrder(meeting),
            privateOffices: getArrOrder(office),
            loading: false,
          }),
        }),
        deskItemsMap: Object.assign({}, state.deskItemsMap, deskItemsMap),
      });
    }
    /* fetch all workspace resources end */

    /* fetch & update resource start */
    case Actions.FETCH_RESOURCES:
    case Actions.UPDATE_MEETING_ROOM:
    case Actions.UPDATE_PRIVATE_OFFICE:
    case Actions.UPDATE_DESK:
    case Actions.ADD_ITEM:
    case Actions.UPDATE_ITEM:
    case Actions.DELETE_ITEM: {
      const resourceId = payload?._id;

      return Object.assign({}, state, {
        resourceMap: Object.assign({}, state.resourceMap, {
          [resourceId]: Object.assign({}, state.resourceMap[resourceId], {
            error: null,
            loading: true,
          }),
        }),
      });
    }

    case Actions.FETCH_RESOURCES_SUCCESS:
    case Actions.UPDATE_MEETING_ROOM_SUCCESS:
    case Actions.UPDATE_PRIVATE_OFFICE_SUCCESS:
    case Actions.UPDATE_DESK_SUCCESS:
    case Actions.ADD_ITEM_SUCCESS:
    case Actions.UPDATE_ITEM_SUCCESS:
    case Actions.DELETE_ITEM_SUCCESS: {
      let { items } = payload;

      const resourceType = String(payload.type);
      if (resourceType.includes("desk")) {
        items = items?.filter?.((item) => item?.active && item?.status);
        payload.items = items;
        payload.desk_type = deskTypeCount(items);
      } else {
        items = [];
      }

      return Object.assign({}, state, {
        resourceMap: Object.assign({}, state?.resourceMap, {
          [payload?._id]: payload,
        }),
        deskItemsMap: Object.assign({}, state.deskItemsMap, arrToObjMap(items)),
      });
    }

    /* fetch & update resource end */

    /*  add meeting room start */
    case Actions.ADD_MEETING: {
      const workSpaceId = payload?.space_id;
      const mResource = state.resources?.[workSpaceId] || {};

      return Object.assign({}, state, {
        resources: Object.assign({}, state.resources, {
          [workSpaceId]: Object.assign({}, mResource, {
            error: null,
            adding: true,
          }),
        }),
      });
    }

    case Actions.ADD_MEETING_SUCCESS: {
      const workSpaceId = payload?.space_id?._id || payload?.space_id;
      const mResource = state.resources?.[workSpaceId] || {};
      const meetingRooms = [...(mResource?.meetingRooms || []), payload?._id];

      return Object.assign({}, state, {
        resourceMap: Object.assign({}, state.resourceMap, {
          [payload?._id]: payload,
        }),
        resources: Object.assign({}, state?.resources, {
          [workSpaceId]: Object.assign({}, mResource, {
            meetingRooms: [...new Set(meetingRooms)],
            adding: false,
          }),
        }),
      });
    }

    /*  add meeting room end */

    /*  add private office start */
    case Actions.ADD_PRIVATE_OFFICE: {
      const workSpaceId = payload?.space_id;
      const mResource = state.resources?.[workSpaceId] || {};

      return Object.assign({}, state, {
        resources: Object.assign({}, state.resources, {
          [workSpaceId]: Object.assign({}, mResource, {
            error: null,
            adding: true,
          }),
        }),
      });
    }

    case Actions.ADD_PRIVATE_OFFICE_SUCCESS: {
      const workSpaceId = payload?.space_id?._id || payload?.space_id;

      const mResource = state.resources?.[workSpaceId] || {};
      const privateOffices = [
        ...(mResource?.privateOffices || []),
        payload?._id,
      ];

      return Object.assign({}, state, {
        resourceMap: Object.assign({}, state.resourceMap, {
          [payload?._id]: payload,
        }),
        resources: Object.assign({}, state?.resources, {
          [workSpaceId]: Object.assign({}, mResource, {
            privateOffices: [...new Set(privateOffices)],
            adding: false,
          }),
        }),
      });
    }

    /*  add private office end */

    /*  add desk start */
    case Actions.ADD_DESK: {
      const workSpaceId = payload?.space_id;
      const mResource = state.resources?.[workSpaceId] || {};

      return Object.assign({}, state, {
        resources: Object.assign({}, state.resources, {
          [workSpaceId]: Object.assign({}, mResource, {
            error: null,
            adding: true,
          }),
        }),
      });
    }

    case Actions.ADD_DESK_SUCCESS: {
      const workSpaceId = payload?.space_id?._id || payload?.space_id;
      const mResource = state.resources?.[workSpaceId] || {};
      const desks = [...(mResource?.desks || []), payload?._id];

      let { items } = payload;
      items = items?.filter?.((item) => item?.active && item?.status);

      payload.items = items;
      payload.desk_type = deskTypeCount(items);

      return Object.assign({}, state, {
        resourceMap: Object.assign({}, state.resourceMap, {
          [payload?._id]: payload,
        }),
        resources: Object.assign({}, state.resources, {
          [workSpaceId]: Object.assign({}, mResource, {
            desks: [...new Set(desks)],
            adding: false,
          }),
        }),
        deskItemsMap: Object.assign(
          {},
          state?.deskItemsMap,
          arrToObjMap(items)
        ),
      });
    }

    /*  add desk end */

    /* Handle Failure FETCH, UPDATE & ADD start */

    case Actions.FETCH_RESOURCES_FAILURE:
    case Actions.UPDATE_MEETING_ROOM_FAILURE:
    case Actions.UPDATE_PRIVATE_OFFICE_FAILURE:
    case Actions.UPDATE_DESK_FAILURE:
    case Actions.ADD_ITEM_FAILURE:
    case Actions.UPDATE_ITEM_FAILURE:
    case Actions.DELETE_ITEM_FAILURE: {
      const resourceId = payload?._id;

      return Object.assign({}, state, {
        resourceMap: Object.assign({}, state.resourceMap, {
          [resourceId]: Object.assign({}, state.resourceMap[resourceId], {
            error: payload,
            loading: false,
          }),
        }),
      });
    }
    /* Handle Failure FETCH, UPDATE & ADD end */

    /* Handle Failure ADD start */
    case Actions.FETCH_ALL_WORKSPACE_RESOURCES_FAILURE:
    case Actions.ADD_MEETING_FAILURE:
    case Actions.ADD_PRIVATE_OFFICE_FAILURE:
    case Actions.ADD_DESK_FAILURE: {
      const workSpaceId = payload?.space_id;

      return Object.assign({}, state, {
        resources: Object.assign({}, state.resources, {
          [workSpaceId]: Object.assign({}, state.resources[workSpaceId], {
            error: payload,
            loading: false,
            adding: false,
          }),
        }),
      });
    }
    /* Handle Failure ADD end */

    default:
      return state;
  }
};

export default resourcesReducer;
