import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { Button, Col, Input, Row, Select, Image, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllMailTemplate, sendCampaignMail } from "./duck/MailActions";
import Spinner from "../spinner/Spinner";
import { getAllMembers } from "../members/duck/MembersActions";
import noImage from "../assets/images/no-image.png";

const Mail = () => {
  const preProps = useRef({});
  const dispatch = useDispatch();

  const activeWorkspace = useSelector((state) => state.workSpace?.active);
  const { members, memberMap } = useSelector((state) => state.members);

  const mail = useSelector((state) => state.mail);
  const mailTemplates = mail?.mailTemplates?.[activeWorkspace]?.list;
  const mailTemplateMap = mail?.mailTemplateMap;

  const mailSpin = !mailTemplates?.length && mail?.fetching;

  const [state, setState] = useState({});
  const [error, setError] = useState({});

  const [boardKey, setBoardKey] = useState();

  const mMemberBoard = useMemo(
    () => members?.[boardKey] ?? {},
    [members, boardKey, activeWorkspace]
  );

  const loadMore = useCallback(
    (offset = 0, limit = 100) => {
      const { meta, list, loading } = mMemberBoard;
      if (!loading && (offset === 0 || meta?.totalCount > list?.length)) {
        let params = { space_id: activeWorkspace };

        setBoardKey(JSON.stringify({ ...params }));

        params.offset = offset;
        params.limit = limit;
        dispatch(getAllMembers(params));
      }
    },
    [activeWorkspace, mMemberBoard, dispatch]
  );

  const reset = () => {
    setState({});
    setError({});
    setBoardKey("");
  };

  useEffect(() => {
    if (
      (preProps.current?.loading && !mail?.loading) ||
      preProps?.current?.activeWorkspace !== activeWorkspace
    )
      reset();

    const { list, loading } = mMemberBoard;
    if (activeWorkspace && !Object.keys(mMemberBoard)?.length) {
      dispatch(getAllMailTemplate({ space_id: activeWorkspace }));
      loadMore();
    } else if (!loading && list?.length > 0) {
      loadMore(list?.length);
    }

    return () => {
      preProps.current = {
        loading: mail?.loading,
        activeWorkspace,
      };
    };
  }, [mail?.loading, activeWorkspace, mMemberBoard]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      if (Array.isArray(value) && value.length > 1 && value.includes("All")) {
        value = ["All"];
      }

      setState((preState) => ({
        ...preState,
        [name]: value,
      }));
      setError({});
    },
    []
  );

  const hasError = () => {
    let { subject, template_id, sendTo } = state;
    subject = subject?.trim?.();

    let error = {};

    if (!subject) {
      error.subject = "Please enter subject line";
    }

    if (!template_id) {
      error.template_id = "Please select mail template";
    }

    if (!sendTo || (sendTo?.length || 0) <= 0) {
      error.sendTo = "Please select send to member";
    }

    setError(error);

    return Object.keys(error).length !== 0;
  };

  const onSend = () => {
    let { subject, template_id, sendTo } = state;
    subject = subject?.trim?.();

    if (!hasError()) {
      let params = {
        space_id: activeWorkspace,
        subject,
        template_id,
      };

      if (sendTo.includes("All")) {
        params.isSendToAll = true;
      } else {
        params.sendTo = sendTo;
      }

      dispatch(sendCampaignMail(params));
    }
  };

  let membersList = mMemberBoard?.list;
  let memberOptions = useMemo(() => {
    let mData = [];
    if (
      !state?.sendTo?.includes?.("All") &&
      Array.isArray(membersList) &&
      membersList?.length >= 1
    ) {
      let memberEmails = membersList?.map?.((mId) => {
        const mMember = memberMap?.[mId];
        const isThisSpaceActive = mMember?.spaces?.find?.(
          (space) => space?.space === activeWorkspace && space?.active
        );

        return isThisSpaceActive && mMember?.email;
      });

      mData = memberEmails.filter(
        (email) => email && !state?.sendTo?.includes?.(email)
      );
      mData = ["All", ...memberEmails];
    }
    return mData;
  }, [membersList, memberMap]);

  const { sendTo, subject, template_id } = state;

  return mailSpin ? (
    <Spinner />
  ) : (
    <Col>
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Campaign Mail</span>
      </Row>

      <Row justify="center">
        <Col
          span={16}
          style={{
            background: "#fff",
            borderRadius: "5px",
            padding: "50px 30px 20px",
          }}
        >
          <Row className="mb10">
            <Col span={6}>
              <label className="label">Send To</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18}>
              <Select
                className="custom-select fw"
                placeholder="Select Send To"
                size="large"
                mode="multiple"
                value={sendTo}
                onChange={handleChange("sendTo")}
                allowClear
                disabled={mail?.loading}
              >
                {memberOptions.map?.(
                  (email) =>
                    email && (
                      <Select.Option key={email} value={email}>
                        {email}
                      </Select.Option>
                    )
                )}
              </Select>

              <Row className="error mt5">{error?.sendTo}</Row>
            </Col>
          </Row>

          <Row className="mb10">
            <Col span={6}>
              <label className="label">Subject Line</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18}>
              <Input
                className="custom-input"
                placeholder="Subject"
                value={subject}
                onChange={handleChange("subject")}
                disabled={mail?.loading}
              />

              <Row className="error mt5">{error?.subject}</Row>
            </Col>
          </Row>

          <Row className="mb10">
            <Col span={6}>
              <label className="label">Mail Template</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18}>
              <Select
                className="custom-select fw"
                placeholder="Select Mail Template"
                size="large"
                value={template_id}
                onChange={handleChange("template_id")}
                allowClear
                disabled={mail?.loading}
              >
                {mailTemplates?.map?.(
                  (id) =>
                    mailTemplateMap?.[id] && (
                      <Select.Option key={id} value={id}>
                        {mailTemplateMap?.[id]?.name}
                      </Select.Option>
                    )
                )}
              </Select>

              <Row className="error mt5">{error?.template_id}</Row>
            </Col>
          </Row>
          {template_id && (
            <Row className="mb10">
              <Col span={6}></Col>
              <Col span={5}>
                <Image
                  className="cover new_image-fluid"
                  preview={false}
                  wrapperStyle={{ height: 150 }}
                  style={{ alignContent: "flex-start" }}
                  // src={mailTemplateMap?.[template_id]?.thumbnail || noImage}
                  src={
                    "https://mcusercontent.com/ccca26944613b8d107fabf277/template-screens/5ee539a4df5792cf8d2f5c9a3cb2485c_11409254_screen.png"
                  }
                  width={"100%"}
                  height={"100%"}
                />
              </Col>
            </Row>
          )}

          <Button type="primary" loading={mail?.loading} onClick={onSend}>
            {mail?.loading ? "Sending Mail..." : "Send Campaign Mail"}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default Mail;
