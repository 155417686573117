import React, { useMemo } from "react";
import { Card, Tooltip, Typography } from "antd";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import { checkPermission, resourceTypes } from "../../utils/CommonFunctions";
const { Paragraph } = Typography;

const ResourceCard = (props) => {
  const { id, type, onEdit = () => {} } = props;

  const isMeetingRoomsEditable = useMemo(
    () => checkPermission({ row: "resources", subRow: "meetingRooms" }),
    []
  );
  const resourceType = useMemo(() => resourceTypes[type], [type]);
  const resourceMap = useSelector((state) => state.resources?.resourceMap);
  const mResource = useMemo(() => resourceMap?.[id] || {}, [resourceMap, id]);

  const ExtraIcon = isMeetingRoomsEditable ? EditOutlined : EyeOutlined;

  return (
    <Card
      style={{
        width: 300,
        margin: "10px 20px 10px 0",
        borderRadius: 5,
      }}
      title={mResource?.name}
      bordered={false}
      hoverable
      extra={
        <Tooltip title={isMeetingRoomsEditable ? "Edit" : "More Details"}>
          <ExtraIcon
            style={{ fontSize: 12 }}
            onClick={() => onEdit(true, id)}
          />
        </Tooltip>
      }
      cover={<img alt={resourceType?.name} src={resourceType?.image} />}
    >
      <p style={{ fontWeight: 500 }}>
        <Paragraph ellipsis={{ rows: 3 }}>{mResource?.description}</Paragraph>
      </p>
      <p>{`Price: ${mResource?.price} £/${
        resourceType?._id === "meeting_room" ? "hr" : "day"
      }`}</p>
      <p>Capacity: {mResource?.capacity} people</p>
    </Card>
  );
};

export default ResourceCard;
