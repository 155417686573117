import React, { useEffect, useMemo, useState } from "react";
import { Button, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../spinner/Spinner";
import SliderTabCard from "./components/SliderTabCard";
import { getAllCMS } from "./duck/CMSActions";
import { useHistory } from "react-router";

import PageMetaModal from "./components/PageMetaModal";

import { checkPermission } from "../utils/CommonFunctions";
import Routes from "../config/Routes";
import constants from "../utils/Constants";

const { CMS_TYPES, CMS_PAGES } = constants;

const Slider = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [boardKey, setBoardKey] = useState();
  const [showPageMetaModal, setShowPageMetaModal] = useState(false);
  const isCMSEditable = useMemo(() => checkPermission({ row: "cms" }), []);

  const cmsBoard = useSelector(
    (state) => state?.cms?.boards?.[JSON.stringify(boardKey)]
  );

  const pageMetaBoardParams = useMemo(
    () => ({ type: CMS_TYPES.PAGE_META, page_name: CMS_PAGES.HOME }),
    []
  );
  const pageMeta = useSelector(({ cms }) => {
    const boards = cms.boards[JSON.stringify(pageMetaBoardParams)];
    const pageMetaId = boards?.list?.[0];

    return Object.assign({}, cms.cmsMap[pageMetaId]);
  });

  useEffect(() => {
    const params = { type: CMS_TYPES.SLIDER };
    setBoardKey(params);
    dispatch(getAllCMS(params));
    dispatch(getAllCMS(pageMetaBoardParams));
  }, [dispatch, pageMetaBoardParams]);

  const loading = cmsBoard?.loading && !cmsBoard?.list?.length;

  return loading ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb10">
        <span className="nw_heading">Sliders</span>

        <span>
          {isCMSEditable && (
            <Button
              type="primary-outline mr10"
              onClick={() => setShowPageMetaModal(true)}
            >
              Edit Page Details
            </Button>
          )}

          {cmsBoard?.list?.length < 5 && isCMSEditable && (
            <Button
              type="primary-outline"
              onClick={() => history.push(Routes.editSlider)}
            >
              + Add Tab
            </Button>
          )}
        </span>
      </Row>
      <Row>
        {cmsBoard?.list?.map((id, i) => (
          <SliderTabCard key={i} id={id} isCMSEditable={isCMSEditable} />
        ))}
      </Row>

      <PageMetaModal
        visible={showPageMetaModal}
        selectedId={pageMeta?._id}
        showModal={() => setShowPageMetaModal(false)}
        isCMSEditable={isCMSEditable}
        pageName={CMS_PAGES.HOME}
        otherDetails
        showHeadingInput={false}
        descriptionLabel={"Learn More"}
        descriptionInputType="TextEditor"
      />
    </div>
  );
};

export default Slider;
