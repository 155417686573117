import React, { useEffect, useMemo } from "react";
import { Result, Row } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { SettingOutlined } from "@ant-design/icons";
import Routes from "../config/Routes";

const PreBooking = () => {
  const { search } = useLocation();
  const history = useHistory();

  const bookResource = useMemo(() => {
    const data = new URLSearchParams(search).get("bookResource");
    return JSON.parse(data);
  }, [search]);

  useEffect(() => {
    if (!bookResource) {
      history.goBack();
    } else {
      localStorage.setItem("bookResource", JSON.stringify(bookResource));
      history.push(`${Routes.payNow}/new_booking`);
    }
  }, [bookResource, history]);

  return (
    <Row style={{ height: "100vh" }} justify="center" align="middle">
      <Result
        icon={<SettingOutlined spin />}
        title="Please wait, we are processing your request..."
      />
    </Row>
  );
};

export default PreBooking;
