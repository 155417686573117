import Actions from "./DashboardActionTypes";

export const getDashboard = (params = {}) => {
  return {
    type: Actions.FETCH_DASHBOARD,
    payload: params,
  };
};

export const getMemberDashboard = (params = {}) => {
  return {
    type: Actions.FETCH_MEMBER_DASHBOARD,
    payload: params,
  };
};
