import Actions from "./LoginActionTypes";

const initialState = {
  loggedInUser: null,
  loading: false,
  error: null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_LOGGED_IN_USER_REQUEST:
    case Actions.UPDATE_LOGGED_IN_USER_REQUEST:
    case Actions.UPDATE_PASSWORD:
    case Actions.LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case Actions.UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }

    case Actions.GET_LOGGED_IN_USER_SUCCESS:
    case Actions.UPDATE_LOGGED_IN_USER_SUCCESS:
    case Actions.LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loggedInUser: action.payload,
      };
    }

    case Actions.GET_LOGGED_IN_USER_FAIL:
    case Actions.UPDATE_LOGGED_IN_USER_FAIL:
    case Actions.UPDATE_PASSWORD_FAILURE:
    case Actions.LOGIN_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default loginReducer;
