import React, { useCallback, useEffect, useState } from "react";
import { Row, Modal, Radio, Button, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";

import AddNewCard from "../../billing/components/AddNewCard";
import StripeCardPayDetails from "./StripeCardPayDetails";
import { payCharge } from "../../booking/duck/BookingActions";

import emptyCardImg from "../../assets/svg/empty-card.svg";

import "./paymentModal.scss";

const PaymentModal = (props) => {
  const dispatch = useDispatch();

  const [card, setCard] = useState();
  const [show, setShow] = useState(false);

  const { sourceList, default_source } = useSelector((state) => state.billing);

  useEffect(() => {
    setCard(default_source);
  }, [default_source]);

  const onChange = useCallback((e) => {
    setCard(e.target.value);
  }, []);

  const onPay = useCallback(() => {
    const { bookingId, members = [], price = 1, description = "" } = props;
    const payload = {
      _id: bookingId,
      source_id: card,
      currency: "GBP",
      amount: parseInt(price) * 100,
      members,
      description,
    };

    dispatch(payCharge(payload));
  }, [props, card, dispatch]);

  return (
    <Modal
      className="payment-modal-container"
      visible={props.visible}
      title="Select Card"
      onCancel={props.handleModal}
      footer={
        <>
          <Button onClick={props.handleModal}>Cancel</Button>

          {!!sourceList.length && (
            <Button type="primary" loading={props?.loading} onClick={onPay}>
              Pay
            </Button>
          )}
        </>
      }
    >
      <Col className="content-container">
        {/* <Col className="save-card mb10">Your saved cards</Col> */}

        <Col className="mb20 fw">
          {sourceList?.length > 0 ? (
            <Radio.Group
              className="credit-cards-group fw"
              defaultValue={default_source}
              onChange={onChange}
              value={card}
              disabled={props.loading}
            >
              <Row className="fw">
                {sourceList &&
                  sourceList.map((src_id, index) => (
                    <Col sm={24} md={12} key={src_id}>
                      <Radio value={src_id}>
                        <StripeCardPayDetails sourceId={src_id} index={index} />
                      </Radio>
                    </Col>
                  ))}
              </Row>
            </Radio.Group>
          ) : (
            <Col className="empty-card-container">
              <img src={emptyCardImg} alt="empty card" />
            </Col>
          )}
        </Col>
      </Col>

      <Col className="sticky-footer fw p15">
        <Button
          className="btn-add-new-card nw_btn_white ant-btn"
          size="large"
          onClick={() => setShow(!show)}
          disabled={props.loading}
        >
          + Add New Card
        </Button>
      </Col>

      {show && <AddNewCard visible={show} handleModal={() => setShow(!show)} />}
    </Modal>
  );
};

export default PaymentModal;
