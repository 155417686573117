import { Col, Row } from "antd";
import React from "react";
import ReactQuill from "react-quill";

const TitleComponent = (props) => {
  return (
    <Row className="mb15">
      <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
        <label className="label">{props?.title ?? "Fold Title"}</label>
        {/* <sup style={{ color: "red" }}>*</sup> */}
      </Col>
      <Col span={8} xs={24} sm={24} lg={18} xl={14} xxl={10}>
        <ReactQuill
          size="large"
          type="text"
          value={props?.value}
          onChange={props?.handleChange("title")}
          disabled={props?.disabled}
          theme="snow"
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["bold", "italic", "underline", "link"], // toggled buttons
              [{ list: "ordered" }, { list: "bullet" }],

              ["clean"],
            ],
          }}
        />
        {/* <Row className="error mt5">{props?.error}</Row> */}
      </Col>
    </Row>
  );
};

export default React.memo(TitleComponent);
