import React from "react";
import { Link } from "react-router-dom";
import { Button, Layout, Menu } from "antd";
import "../../css/header.scss";
import Logo from "./../Header/Logo";
import user from "../../images/user.svg";
import { useSelector } from "react-redux";
import Routes from "../../../config/Routes";
const { Header } = Layout;

const Headermain = (props) => {
  const loggedInUser = useSelector((state) => state?.user?.loggedInUser);

  return (
    <>
      <Header
        className="header container"
        style={{ background: "transparent" }}
      >
        <Logo />
        <div className="rightContainer">
          <Menu mode="horizontal">
            <Menu.Item key="1">WorkSpace</Menu.Item>
            <Menu.Item key="2">Location</Menu.Item>
            <Menu.Item key="3">Plan</Menu.Item>
            <Menu.Item key="4" onClick={props.onClickContactUs}>
              Contact us
            </Menu.Item>
          </Menu>
          <div className="login">
            <Link to={Routes.login}>
              <span>
                <img src={user} alt="user icon" />
              </span>
              Login
            </Link>
          </div>
          <Button>BOOK A TOUR</Button>
        </div>
      </Header>
    </>
  );
};

export default Headermain;
