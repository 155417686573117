import { Col, Input, Row, Switch } from "antd";
import React from "react";

const CtaButonComponent = (props) => {
  const { handleChange, disabled, value, ctaBtnText, ctaBtnURL, error } = props;

  return (
    <Row align="top" className="mb15">
      <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
        <label className="label">CTA Button</label>
      </Col>
      <Col span={8} xs={24} sm={24} lg={18} xl={20} xxl={20}>
        <Switch
          checked={value}
          onChange={handleChange("showCTAButton")}
          checkedChildren="Show"
          unCheckedChildren="Hide"
          disabled={disabled}
        />
        {value && (
          <>
            <Row align="middle">
              <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
                <label className="label">Button text</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>
              <Col xs={24} sm={24} lg={18} xl={12} xxl={8}>
                <Input
                  className="custom-input fw"
                  placeholder={"Button Text"}
                  allowClear
                  value={ctaBtnText}
                  onChange={handleChange("ctaBtnText")}
                  disabled={disabled}
                />
                <Row className="error mt5">{error?.ctaBtnText}</Row>
              </Col>
            </Row>

            <Row align="middle">
              <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
                <label className="label">Redirect URL</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>
              <Col xs={24} sm={24} lg={18} xl={12} xxl={8}>
                <Input
                  className="custom-input fw mt10"
                  placeholder={"Redirect URL"}
                  allowClear
                  value={ctaBtnURL}
                  onChange={handleChange("ctaBtnURL")}
                  disabled={disabled}
                />
                <Row className="error mt5">{error?.ctaBtnURL}</Row>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default React.memo(CtaButonComponent);
