import { useEffect, useState, useCallback } from "react";
import { Row, Select, DatePicker, Card, Button, Table } from "antd";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAllXeros } from "./duck/XeroActions";
import moment from "moment";
import Spinner from "../spinner/Spinner";
import Routes from "../config/Routes";
import { xeroColumns } from "./components/xeroColumns";
import { resourceTypes, disabledDate } from "../utils/CommonFunctions";

const XeroList = () => {
  const xero = useSelector((state) => state?.xero);
  let length = xero.xero?.booking?.length;

  const bank = xero?.xero?.bank;

  const xeroSpin = !length && xero.loading;

  const history = useHistory();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});

  useEffect(() => {
    !length && dispatch(getAllXeros());
    setFilter({});
  }, []);

  const onSearch = () => {
    let params = {};
    if (filter?.resource_type) params.resource_type = filter?.resource_type;
    if (filter?.from)
      params.from = moment(filter?.from).startOf("day").utc().format();
    if (filter?.to) params.to = moment(filter?.to).endOf("day").utc().format();

    dispatch(getAllXeros(params));
  };
  // const handleChange = useCallback(
  //   (name) => (event) => {
  //     let value = event?.target?.value ?? event;
  //
  //     setFilter((pre) => ({
  //       ...pre,
  //       [name]: value,
  //     }));
  //   },
  //   []
  // );

  const handleClick = (id) => {
    id && history.push(`${Routes.invoicePreview}/${id}`);
  };
  const data = [];

  xero?.xero?.booking?.map((invoice, i) => {
    // let mInvoice = xero?.invoiceMap[id];
    let totalPrice = 0;
    // if (mInvoice?.payment_details?.amount) {
    //   totalPrice = mInvoice?.payment_details?.amount / 100;
    // } else {
    //   const price = mInvoice?.price?.net || 0;
    //   const tax = (price * 20) / 100;
    //   totalPrice = price + tax;
    // }

    // let bookedItem = mInvoice?.items[0];

    data.push({
      sno: invoice?.invoiceNumber,
      ref: invoice?.reference,
      to: invoice?.contact.name,
      date:  moment(invoice?.date).format("DD MMM YYYY"),
      total: invoice?.total,
      status: invoice?.status,
      currency: invoice?.currencyCode,
      action: `https://in.xero.com/${invoice?.onlineInvoiceUrl?.split("/")[invoice?.onlineInvoiceUrl?.split("/").length -1]}/Invoice/DownloadPdf/${invoice?.invoiceID}`
    });
  });

  return xeroSpin ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb20">
        <span style={{ fontSize: 20, fontWeight: 500 }}>Xero Invoice</span>
      </Row>
      <Card title={"Account"} size={"small"} bordered={false}>
          <Row justify="space-between" align="middle" className={"pl20 pr20"}>
              <p style={{ fontSize: 20, fontWeight: 500 }}><b>Bank Name: </b> {bank?.name}</p>
              <div style={{width: '30%'}}>
              <p style={{ fontSize: 20, fontWeight: 500 }}><b>Account No: </b> {bank?.bankAccountNumber}</p>
              </div>
          </Row>
          <Row justify="space-between" align="middle" className={"pl20 pr20"}>
              <p style={{ fontSize: 20, fontWeight: 500 }}><b>Code: </b> {bank?.code}</p>
              <div style={{width: '30%'}}>
              <p style={{ fontSize: 20, fontWeight: 500 }}><b>Status: </b> {bank?.status}</p>
              </div>
          </Row>
          <Row justify="space-between" align="middle" className={"pl20 pr20"}>
              <p style={{ fontSize: 20, fontWeight: 500 }}><b>Currency: </b> {bank?.currencyCode}</p>
              <div style={{width: '30%'}}>
              <p style={{ fontSize: 20, fontWeight: 500 }}><b>Type: </b> {bank?.bankAccountType}</p>
              </div>
          </Row>
      </Card>
      {/*<Card title="Filter" size="small" bordered={false} className={"mt50"}>*/}
      {/*  <DatePicker*/}
      {/*    style={{*/}
      {/*      width: "17%",*/}
      {/*      fontWeight: "500",*/}
      {/*      marginRight: "15px",*/}
      {/*    }}*/}
      {/*    onChange={handleChange("from")}*/}
      {/*    placeholder="From"*/}
      {/*    value={filter?.from}*/}
      {/*    disabledDate={disabledDate({ isFutureDateDisabled: true })}*/}
      {/*    allowClear*/}
      {/*  />*/}
      {/*  <DatePicker*/}
      {/*    style={{*/}
      {/*      width: "17%",*/}
      {/*      fontWeight: "500",*/}
      {/*      marginRight: "15px",*/}
      {/*    }}*/}
      {/*    onChange={handleChange("to")}*/}
      {/*    placeholder="To"*/}
      {/*    value={filter?.to}*/}
      {/*    disabledDate={disabledDate({ isFutureDateDisabled: true })}*/}
      {/*    allowClear*/}
      {/*  />*/}
      {/*  <Select*/}
      {/*    className="mr5"*/}
      {/*    style={{*/}
      {/*      paddingTop: "5px",*/}
      {/*      width: "15%",*/}
      {/*      fontWeight: "500",*/}
      {/*      marginRight: "15px",*/}
      {/*    }}*/}
      {/*    placeholder={"Resource Type"}*/}
      {/*    value={filter?.resource_type}*/}
      {/*    onChange={handleChange("resource_type")}*/}
      {/*    allowClear*/}
      {/*  >*/}
      {/*    {Object.values(resourceTypes).map((resourceType) => (*/}
      {/*      <Select.Option key={resourceType?._id} value={resourceType?._id}>*/}
      {/*        {resourceType?.name}*/}
      {/*      </Select.Option>*/}
      {/*    ))}*/}
      {/*  </Select>*/}

      {/*  <Button className="mr10" type="primary" onClick={() => onSearch()}>*/}
      {/*    Search*/}
      {/*  </Button>*/}
      {/*  <Button*/}
      {/*    className="mr10"*/}
      {/*    type="primary-outline"*/}
      {/*    onClick={() => {*/}
      {/*      setFilter({});*/}
      {/*      dispatch(getAllInvoices());*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Reset*/}
      {/*  </Button>*/}
      {/*</Card>*/}
      <Table
        columns={xeroColumns(handleClick)}
        dataSource={data}
        className="mt20"
        bordered={false}
      />
    </div>
  );
};

export default XeroList;
