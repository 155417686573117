import { put, takeLatest, call } from "redux-saga/effects";
import { showNotification } from "../../utils/CommonFunctions";
import ACTIONS from "./XeroActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const getAllXeroRequest = (params = {}) => {
  return axios({
    method: "GET",
    url: AppUrl.XERO,
    params: params,
  });
};

function* getAllXero(action) {
  try {
    let resources = yield call(getAllXeroRequest, action.payload);
    let _data = resources?.data;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.FETCH_ALL_XEROS_FAILURE,
        payload: _data?.data,
      });
    } else {
      yield put({
        type: ACTIONS.FETCH_ALL_XEROS_SUCCESS,
        payload: _data?.data,
      });
    }
  } catch (error) {
    console.log("get all xero error", error);
  }
}

// ACTION WATCHER
export default function* xeroSaga() {
  yield takeLatest(ACTIONS.FETCH_ALL_XEROS, getAllXero);
}
