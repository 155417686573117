import React from "react";
import { Card, Col, Image, Row, Tooltip, Typography } from "antd";

import { resourceTypes } from "../../utils/CommonFunctions";

const SelectedResource = (props) => {
  const maxListSize = 2;

  const { booking = {} } = props;
  const resource = booking.resource_id;
  const resourceType = resourceTypes[booking?.type];
  const workspace = booking?.space_id;

  const amenities =
    resource?.amenities_id
      ?.filter?.((a) => a?.active)
      .map?.((amenitie, i) => amenitie?.title) || [];
  const visiableAmenities = amenities?.splice?.(0, maxListSize);
  const extraAmenities = amenities?.splice?.(maxListSize);

  const bookingSeatNums =
    booking?.items?.map?.((item, i) => item?.seat_no) || [];

  return (
    <Card className="selected-resource-container" bordered={true}>
      <Row>
        <Col className="resource-image-container" xs={24} sm={8}>
          {resourceType && (
            <Image
              className="resource-image cover"
              preview={false}
              width={"100%"}
              height={"100%"}
              src={resourceType?.image}
            />
          )}
        </Col>

        <Col className="resource-details-container pl10" xs={24} sm={16}>
          <Col className="workspace-details-container mb10">
            <Row className="workspace-name">{workspace?.name}</Row>

            <Typography.Paragraph
              className="workspace-area"
              ellipsis={{
                rows: 2,
                tooltip: workspace?.area,
              }}
            >
              {workspace?.area}
            </Typography.Paragraph>
          </Col>

          <Row className="resource-name mb10">
            <label className="label mr5">Area: </label>

            <Col className="value">{resource?.name}</Col>
          </Row>

          {resourceType?._id?.includes?.("desk") && (
            <Row className="seat-no-container mb10">
              <label className="label mr5">Seat No:</label>

              <Col className="value">{bookingSeatNums.join(", ")}</Col>
            </Row>
          )}

          <Row className="amenities-container mb10">
            <Col className="label mr5">Amenities:</Col>

            <Row className="value">
              <span>{visiableAmenities.join(", ")}</span>

              {extraAmenities.length > 0 && (
                <Tooltip title={extraAmenities.join(", ")}>
                  , +{extraAmenities.length}
                </Tooltip>
              )}
            </Row>
          </Row>

          {!resourceType?._id?.includes?.("desk") && (
            <Row className="resource-capacity-container">
              <label className="mr5">Total Capacity :</label>

              <Col className="value">{resource?.capacity}</Col>
            </Row>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default React.memo(SelectedResource);
