import { Row, Tag, Col, Tooltip } from "antd";
import moment from "moment";
import {
  InfoCircleOutlined,
  EditOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
export const deskSwapColumns = (editable, updateMeeting, updateSwapRequest) => {
  const columns = [
    {
      title: "S.no.",
      dataIndex: "serial",
      key: "serial",
      align: "center",
      width: 60,
    },

    {
      title: "Resource Name",
      dataIndex: "resource",
      key: "resource",
      onCell: () => {
        return {
          style: {
            maxWidth: 175,
          },
        };
      },
      render: (resource) => (
        <div>
          <h4 className="nm">{resource[2]}</h4>
          <small>
            <span
              className="mr10"
              style={{
                width: 8,
                height: 8,
                display: "inline-block",
                backgroundColor: resource[1] || "grey",
                borderRadius: "50%",
              }}
            ></span>
            <span>{resource[0]}</span>
          </small>
        </div>
      ),
    },
    {
      title: "Event Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return (
          <Row justify="start" align="middle">
            <Col
              className="--avatar green mr15 circle"
              justify="center"
              align="middle"
            >
              <CalendarOutlined />
            </Col>
            <Col>
              <h4 className="nm">
                {moment(date[0]).format("ddd, MMMM Do, YYYY")}
              </h4>
              <small>
                {moment(date[0]).format("h:mm a")}
                <span style={{ margin: "0 5px", fontSize: 13 }}>to</span>
                {moment(date[1]).format("h:mm a")}
              </small>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let swapStatus = "Requested";
        if (status === "Deleted") {
          swapStatus = "Rejected";
        } else if (status === "Completed") {
          swapStatus = "Approved";
        }

        return (
          <Tag
            color={
              status === "Deleted"
                ? "red"
                : status === "Assigned"
                ? "orange"
                : "green"
            }
          >
            {swapStatus}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      render: (obj) => {
        if (obj?.seat_swap_status === "Assigned" && editable) {
          return (
            <>
              <Tooltip placement="top" title={"Edit"}>
                <EditOutlined onClick={() => updateSwapRequest(obj)} />
              </Tooltip>

              {/* <Tooltip placement='top' title={'Reject Request'}>
                                    <DislikeOutlined className="ml10" onClick={() => updateSwapRequest(obj)}/>
                                </Tooltip> */}
            </>
          );
        } else {
          return (
            <>
              <InfoCircleOutlined onClick={() => updateMeeting(obj)} />
            </>
          );
        }
      },
    },
  ];
  return columns;
};
