import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { Row, Col, Checkbox, Button, Select } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { createCMS, getAllCMS, updateCMS } from "../duck/CMSActions";
import { checkPermission, FOLD_TYPE } from "../../utils/CommonFunctions";
import axios from "axios";
import AppUrl from "../../config/AppUrl";
import Spinner from "../../spinner/Spinner";

import Routes from "../../config/Routes";

const EditFoldPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const preProps = useRef();
  const foldId = useParams()?.id;
  const { location } = history;

  const cms = useSelector((state) => state?.cms);

  const isCMSEditable = useMemo(() => checkPermission({ row: "cms" }), []);

  const modalTitle = useMemo(() => {
    const title = location?.state?.title;
    const text = isCMSEditable
      ? foldId
        ? `Edit ${title || ""} Fold`
        : `Add ${title || ""} Fold`
      : `Fold ${title || ""} Details`;

    return text?.replace?.(/\s{2,}/g, " ");
  }, [foldId, isCMSEditable, location?.state?.title]);

  const [state, setState] = useState({});
  const [fileList, setFileList] = useState([]);
  const [isPreloaded, setIsPreloaded] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState({});

  const cmsType = useMemo(
    () => location?.state?.cms_type,
    [location?.state?.cms_type]
  );

  const mCms = useMemo(
    () =>
      foldId
        ? cms?.cmsMap?.[foldId]
        : { loading: cms?.loading, error: cms?.error },
    [foldId, cms]
  );

  const preload = useCallback(() => {
    setState({
      title: mCms?.name,
      active: mCms?.active ?? true,
      description: mCms?.description,
      foldType: mCms?.meta?.foldType,
      showCTAButton: mCms?.meta?.showCTAButton,
      ctaBtnText: mCms?.meta?.ctaButton?.ctaBtnText,
      ctaBtnURL: mCms?.meta?.ctaButton?.ctaBtnURL,
      imageWidth: mCms?.meta?.imageWidth,
      imageHeight: mCms?.meta?.imageHeight,
    });

    var files = JSON.parse(JSON.stringify([...(mCms?.pictures || [])]));

    setFileList(files);
    setError({});
    setIsPreloaded(true);
  }, [mCms]);

  useEffect(() => {
    if (foldId && !mCms) dispatch(getAllCMS({ type: cmsType }));

    if (isPreloaded && preProps?.current?.loading && !mCms?.loading) {
      // history.push(location?.state?.returnPath);
      history.goBack();
    }

    if (!isPreloaded && mCms && !mCms?.loading) preload();

    return () => {
      preProps.current = { loading: mCms?.loading };
    };
  }, [
    foldId,
    mCms,
    dispatch,
    history,
    preload,
    isPreloaded,
    location?.state?.returnPath,
    cmsType,
  ]);

  useEffect(() => {
    if (state?.foldType === mCms?.meta?.foldType) {
      const mItems = JSON.parse(JSON.stringify([...(mCms?.meta?.items || [])]));
      setItems(mItems);
    } else {
      setItems([]);
    }
  }, [mCms?.meta?.foldType, mCms?.meta?.items, state?.foldType]);

  const handleChange = useCallback(
    (name, isCheckbox) => (e) => {
      let value;
      if (isCheckbox) {
        value = e?.target?.checked;
      } else {
        value = e?.target?.value ?? e;
      }
      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const hasError = useCallback(() => {
    let {
      //  title,
      foldType,
      showCTAButton,
      ctaBtnText,
      ctaBtnURL,
    } = state;
    // title = title?.trim?.();

    const error = {};

    // if (!title) {
    //   error.title = "Title cannot be blank";
    // }
    if (!foldType) {
      error.foldType = "Please select a type";
    }
    if (foldType) {
      if (
        (foldType === FOLD_TYPE.default._id ||
          foldType === FOLD_TYPE.list._id) &&
        !fileList?.length
      ) {
        error.fileList = "Please upload atleast one image";
      } else if (
        (foldType === FOLD_TYPE.faqs._id ||
          foldType === FOLD_TYPE.testimonial._id) &&
        !items?.length
      ) {
        error.foldItems = "Please add or select atleast one item";
      }
    }

    if (showCTAButton) {
      if (!ctaBtnText) {
        error.ctaBtnText = "Please enter button text";
      }
      if (!ctaBtnURL) {
        error.ctaBtnURL = "Please enter button url";
      }
    }

    setError(error);
    return Object.keys(error).length;
  }, [state, fileList?.length, items?.length]);
  // console.log({ fileList });

  const add = useCallback(() => {
    if (!hasError()) {
      const {
        title,
        description,
        active,
        foldType,
        showCTAButton,
        ctaBtnText,
        ctaBtnURL,
        imageWidth,
        imageHeight,
      } = state;

      const extraPayload = {
        foldType,
        showCTAButton,
        imageWidth,
        imageHeight,
      };
      if (showCTAButton) {
        extraPayload.ctaButton = {
          showCTAButton,
          ctaBtnText,
          ctaBtnURL,
        };
      }
      if (items?.length) {
        extraPayload.items = items;
      }

      if (location?.state?.page_name) {
        extraPayload.page_name = location?.state?.page_name;
      }

      const payload = {
        type: cmsType,
        name: title,
        description,
        active,
        files: [],
        tags: [],
        titles: [],
        imgDescription: [],
        extra: [],
        meta: JSON.stringify(Object.assign({}, mCms?.meta, extraPayload)),
      };

      fileList?.forEach((picture, i) => {
        if (picture?.file) {
          payload.files.push(picture?.file);
          payload.tags.push(picture?.tag || "");
          payload.titles.push(picture?.title || "");
          payload.imgDescription.push(picture?.imgDescription || "");
          payload.extra.push({ ...picture?.extra, order: i } || "");
        }
      });

      //debugger;
      payload.tags = JSON.stringify(payload.tags);
      payload.extra = JSON.stringify(payload.extra);
      payload.titles = JSON.stringify(payload.titles);
      payload.imgDescription = JSON.stringify(payload.imgDescription);

      if (foldId) {
        payload._id = foldId;
        const oldFileListIds = mCms?.pictures?.map?.(
          (file) => file?._id || file?.uid
        );

        const currentUploadedFileListIds = fileList
          ?.filter?.((file) => !file?.file && file?._id)
          ?.map((file) => file?._id || file?.uid);

        const removedUploadedFileIds = oldFileListIds?.filter?.(
          (id) => !currentUploadedFileListIds?.includes(id)
        );

        removedUploadedFileIds.forEach((id) => {
          axios({
            method: "DELETE",
            url: `${AppUrl.ATTACHMENTS}/${id}`,
          });
        });

        fileList.forEach((file, index) => {
          if (file?._id) {
            const fileId = file?._id ?? file?.uid;
            const mTag = file.tag?.trim?.();
            const title = file.title?.trim?.();
            const imgDescription = file.imgDescription?.trim?.();
            //const extra = file?.extra;
            const extra = { order: index, ...file?.extra };
            const mPicture = mCms?.pictures?.find((p) => p?._id === fileId);

            // debugger;
            if (
              mTag !== mPicture?.tag ||
              title !== mPicture?.title ||
              imgDescription !== mPicture?.imgDescription ||
              index !== file?.extra?.order ||
              JSON.stringify(extra) !== JSON.stringify(mPicture?.extra)
            ) {
              const data = {
                tag: mTag,
                title,
                imgDescription,
                extra,
                //extra: file?.extra ?? {},
              };

              axios({
                method: "PUT",
                url: `${AppUrl.ATTACHMENTS}/${file?._id}`,
                data,
              });
            }
          }
        });
        dispatch(updateCMS(payload));
      } else {
        dispatch(createCMS(payload));
      }
    }
  }, [
    hasError,
    state,
    cmsType,
    items,
    fileList,
    foldId,
    mCms?.pictures,
    mCms?.meta,
    dispatch,
    location?.state?.page_name,
  ]);

  if (!cmsType) history.push(Routes.slider);

  const FoldComponent = useMemo(() => {
    if (FOLD_TYPE?.[state?.foldType]) {
      return FOLD_TYPE?.[state?.foldType]?.component;
    }
  }, [state?.foldType]);

  const disabled = !isCMSEditable || mCms?.loading;

  return foldId && !mCms ? (
    <Spinner />
  ) : (
    <div className="mainMember">
      <Row align="middle" justify="space-between" className="mb20">
        <span>
          <h2 style={{ fontSize: 22, fontWeight: 600, marginBottom: 0 }}>
            {modalTitle}
          </h2>
        </span>
        <span>
          {isCMSEditable && (
            <Button type="primary" loading={mCms?.loading} onClick={add}>
              Save Fold
            </Button>
          )}

          <Button
            className="ml10"
            disabled={mCms?.loading}
            // onClick={() => history.push(location?.state?.returnPath)}
            onClick={() => history.goBack()}
          >
            <ArrowLeftOutlined />
            Back
          </Button>
        </span>
      </Row>
      <div className="mainMember-content">
        <Col>
          <Row calign="top" className="mb20">
            <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
              <label className="label">Fold Type</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col span={8} xs={24} sm={24} lg={18} xl={14} xxl={10}>
              <Select
                className="custom-select fw"
                placeholder={"Select Fold Type"}
                value={state?.foldType}
                onChange={handleChange("foldType")}
                disabled={disabled}
                allowClear
              >
                {Object.keys(FOLD_TYPE).map((fType) => (
                  <Select.Option key={fType} value={fType}>
                    {FOLD_TYPE[fType]?.title}
                  </Select.Option>
                ))}
              </Select>
              <Row className="error mt5">{error?.foldType}</Row>
            </Col>
          </Row>

          {FoldComponent && (
            <>
              <FoldComponent
                state={state}
                error={error}
                setError={setError}
                handleChange={handleChange}
                disabled={disabled}
                fileList={fileList}
                setFileList={setFileList}
                items={items}
                setItems={setItems}
                isCMSEditable={isCMSEditable}
              />
            </>
          )}

          {foldId && (
            <Row align="top" className="mt10">
              <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
                <label className="label">Status</label>
              </Col>
              <Col span={6} className="mt10">
                <Checkbox
                  checked={state?.active}
                  disabled={disabled}
                  onChange={handleChange("active", true)}
                >
                  Active
                </Checkbox>
              </Col>
            </Row>
          )}
        </Col>
      </div>
    </div>
  );
};

export default EditFoldPage;
