import Actions from "./PlanActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";

const initialState = {
  planMap: {},
  plans: {},
  loading: false,
  error: null,
};

const planReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_ALL_PLANS: {
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    }

    case Actions.FETCH_ALL_PLANS_SUCCESS: {
      let { space_id, resource_type, data } = action.payload;

      return Object.assign({}, state, {
        planMap: Object.assign({}, state.planMap, arrToObjMap(data)),
        plans: Object.assign({}, state.plans, {
          [space_id]: Object.assign({}, state.plans[space_id], {
            [resource_type]: getArrOrder(data),
          }),
        }),
        loading: false,
      });
    }
    case Actions.FETCH_ALL_PLANS_FAILURE: {
      return Object.assign({}, state, {
        loading: false,
        error: action?.payload,
      });
    }
    /* ADD plan start */
    case Actions.ADD_PLAN: {
      return Object.assign({}, state, {
        adding: true,
        error: null,
      });
    }

    case Actions.ADD_PLAN_SUCCESS: {
      let space_id = action.payload?.space_id;
      let mSpacePlans = state.plans[space_id];
      let mPlans = mSpacePlans && mSpacePlans[action.payload?.resource_type];

      return Object.assign({}, state, {
        planMap: Object.assign({}, state.planMap, {
          [action?.payload?._id]: action.payload,
        }),
        plans: Object.assign({}, state.plans, {
          [space_id]: Object.assign({}, state.plans[space_id], {
            [action.payload?.resource_type]: [
              ...(mPlans || []),
              action?.payload?._id,
            ],
          }),
        }),
        adding: false,
      });
    }
    case Actions.ADD_PLAN_FAILURE: {
      return Object.assign({}, state, {
        adding: false,
        error: action.payload,
      });
    }
    /* ADD plan start */
    case Actions.UPDATE_PLAN: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case Actions.UPDATE_PLAN_SUCCESS: {
      return Object.assign({}, state, {
        planMap: Object.assign({}, state.planMap, {
          [action?.payload?._id]: action.payload,
        }),
        loading: false,
      });
    }
    case Actions.UPDATE_PLAN_FAILURE: {
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });
    }

    default:
      return state;
  }
};

export default planReducer;
