import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Row, Select, Card, Col } from "antd";
import NewBooking from "./components/NewBooking";
import RaiseSwapRequest from "./components/RaiseSwapRequest";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  checkPermission,
  resourceTypes,
  showNotification,
} from "../utils/CommonFunctions";
import resourceSvg from "../assets/svg/resource.svg";
import "./components/calendar.scss";
import { useHistory } from "react-router-dom";
import { getAllBookingResources } from "./duck/BookingActions";
import Routes from "../config/Routes";
import { bookingColumns } from "./components/BookingColumns";
// import GoogleCalendar from "./components/GoogleCalendar";
import MyPagination from "../utils/MyPagination";
import MTable from "../utils/MTable";
import appUrl from "../config/AppUrl";
import BookingHistory from "./components/BookingHistory";

const Booking = () => {
  const STATUS_TYPE = {
    upcoming: {
      _id: "upcoming",
      name: "Upcoming",
    },
    ongoing: {
      _id: "ongoing",
      name: "Ongoing",
    },
    past: {
      _id: "past",
      name: "Past",
    },
    canceled: {
      _id: "canceled",
      name: "Canceled",
    },
  };
  Object.freeze(STATUS_TYPE);

  const PAYMENT_STATUS_TYPE = Object.freeze([
    {
      _id: "completed",
      name: "Completed",
    },
    {
      _id: "pending",
      name: "Pending",
    },
    {
      _id: "failed",
      name: "Failed",
    },
  ]);

  const preProps = useRef();

  const isBookingEditable = useMemo(
    () =>
      checkPermission({
        row: "booking",
        subRow: "booking",
      }),
    []
  );
  const calendarShow = useMemo(
    () =>
      checkPermission({
        row: "booking",
        subRow: "calendar",
        type: "show",
      }),
    []
  );

  const showAllBooking = useMemo(
    () => checkPermission({ initial: "showAllBooking" }),
    []
  );
  const showAllWorkSpaces = useMemo(
    () => checkPermission({ initial: "showAllWorkSpaces" }),
    []
  );

  const [showSwapRequest, setShowSwapRequest] = useState(false);
  const [showAddNewBooking, setShowAddNewBooking] = useState(false);
  const [showBookingHistory, setShowBookingHistory] = useState(false);
  const [selectedBooking, selectBooking] = useState(null);
  const [bookingStatus, setBookingStatus] = useState("new");
  const [filter, setFilter] = useState({});
  const [boardKey, setBoardKey] = useState();
  const [startIndex, setStartIndex] = useState(0);

  const history = useHistory();
  const dispatch = useDispatch();

  const workSpace = useSelector((state) => state?.workSpace);
  const activeWorkSpace = workSpace?.active;
  const booking = useSelector((state) => state?.booking);
  const mBookingBoard = useMemo(
    () => booking.bookings?.[boardKey] ?? {},
    [booking.bookings, boardKey]
  );

  let isWorkSpaceActive = workSpace?.workSpaceMap?.[activeWorkSpace]?.active;

  const loadMore = useCallback(
    (offset = 0, limit = 30) => {
      const { meta, list, loading } = mBookingBoard;

      if (!loading && (offset === 0 || meta?.totalCount > list?.length)) {
        const { resource_type, booking_status, payment_status } = filter;

        let params = {};
        if (showAllBooking && activeWorkSpace)
          params.space_id = activeWorkSpace;
        if (resource_type) params.resource_type = resource_type;
        if (booking_status) params.booking_status = booking_status;
        if (payment_status) params.payment_status = payment_status;

        setBoardKey(JSON.stringify({ ...params }));

        params.offset = offset;
        params.limit = limit;
        dispatch(getAllBookingResources(params));
      }
    },
    [activeWorkSpace, mBookingBoard, filter, showAllBooking, dispatch]
  );

  const handleTableChange = useCallback(
    (pagination) => {
      const { current, pageSize } = pagination;
      setStartIndex((current - 1) * pageSize);
      const { list } = mBookingBoard;
      const endIndex = current * pageSize;

      if (endIndex > list?.length) {
        loadMore(list?.length, endIndex - list?.length);
      }
    },
    [mBookingBoard, loadMore]
  );

  const onSearch = () => loadMore();
  const onReset = useCallback(() => {
    setFilter({});
    setBoardKey("");
    setStartIndex(0);
  }, []);

  useEffect(() => {
    if (preProps?.current?.activeWorkSpace !== activeWorkSpace) {
      onReset();
    } else if (!Object.keys(mBookingBoard).length) {
      loadMore();
    }
    return () => {
      preProps.current = { activeWorkSpace };
    };
  }, [activeWorkSpace, mBookingBoard, loadMore]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setFilter((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );

  const handleShowNewBooking = (show = false) => {
    show = typeof show === "boolean" && show;
    setShowAddNewBooking(show);
    if (!show) selectBooking(null);
  };

  const handleShowSwapRequest = (show = false, booking = null) => {
    show = typeof show === "boolean" && show;

    selectBooking(booking);
    setShowSwapRequest(show);
  };

  const updateMeeting = (e) => {
    const today = moment();
    let status = "";
    if (today.isBetween(moment(e?.from), moment(e?.to))) {
      status = "ongoing";
      // showNotification('warning', 'You cannot update an ongoing event')
    } else if (moment(e?.from).isBefore(today, "time")) {
      status = "past";
      // showNotification('warning', 'You cannot update an event from the past')
    }
    // else if( moment(e?.from).isAfter(today, 'time')){
    // }

    setBookingStatus(status);
    selectBooking(e);
    handleShowNewBooking(true);
  };

  const bookings = mBookingBoard?.list;

  let data = useMemo(() => {
    let mData = [];
    if (bookings) {
      bookings.forEach((id, index) => {
        const mBooking = booking?.bookingMap?.[id];
        const startDateTime = new Date(mBooking?.from).getTime();
        const endDateTime = new Date(mBooking?.to).getTime();
        const diffInTime = (endDateTime - startDateTime) / (1000 * 3600);
        const area = mBooking?.space_id?.area;
        const name = mBooking?.space_id?.name;
        const today = moment();
        let status = "Upcoming";

        if (!mBooking?.active) {
          status = "Canceled";
        } else if (mBooking?.payment_status === "failed") {
          status = "Failed";
        } else if (mBooking?.payment_status !== "completed") {
          status = "Payment Pending";
        } else if (
          today.isBetween(moment(mBooking?.from), moment(mBooking?.to))
        ) {
          status = "Ongoing";
        } else if (moment(mBooking?.from).isBefore(today, "time")) {
          status = "Past";
        }

        let resource = [];
        resource = [
          mBooking?.items?.map?.((item) => item?.seat_no).join?.() ||
            mBooking?.resource_id?.name,
          mBooking?.resource_id?.color,
          mBooking?.title || resourceTypes?.[mBooking?.type]?.name,
        ];

        mData.push({
          key: id,
          serial: ++index,
          resource,
          date: [mBooking?.from, mBooking?.to],
          price: [mBooking?.price?.total || mBooking?.price?.net, diffInTime],
          members: mBooking?.members,
          action: mBooking,
          status,
          area,
          name,
        });
      });
    }

    return mData;
  }, [bookings, booking?.bookingMap]);

  return (
    <div>
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Booking</span>

        <span className="nw_mt_spcer">
          {/* {showAllWorkSpaces && <GoogleCalendar />} */}
          {calendarShow && (
            <Button
              className="mr5 nw_btn_common nw_btn_view"
              onClick={() => {
                history.push(Routes.calendar);
              }}
            >
              Calendar View
            </Button>
          )}
          {isBookingEditable && (
            <Button
              type="primary-outline"
              className="nw_btn_common"
              onClick={() => {
                if (showAllBooking) {
                  if (!isWorkSpaceActive) {
                    showNotification(
                      "error",
                      "You can't create a new booking because this workspace is inactive"
                    );
                  } else {
                    setBookingStatus("new");
                    handleShowNewBooking(true);
                  }
                } else {
                  // history.push(`${Routes.search}?spaceId=${activeWorkSpace}`);
                  window.location = `${appUrl.WEBSITE_URL}${Routes.homeSearch}?spaceId=${activeWorkSpace}`;
                }
              }}
            >
              + New Booking
            </Button>
          )}
        </span>
      </Row>

      {!bookings && bookings?.length === 0 && (
        <Row justify="center" align="middle">
          <img src={resourceSvg} alt="" />
        </Row>
      )}

      <Card
        title="Filter"
        className="nw_filter_container"
        size="small"
        bordered={false}
      >
        <Select
          className="mr5 nw_mobile_container"
          style={{
            paddingTop: "5px",
            width: "15%",
            fontWeight: "500",
            marginRight: "15px",
          }}
          placeholder={"Resource Type"}
          value={filter?.resource_type}
          onChange={handleChange("resource_type")}
          allowClear
          size="large"
        >
          {Object.values(resourceTypes).map((resourceType) => (
            <Select.Option key={resourceType?._id} value={resourceType?._id}>
              {resourceType?.name}
            </Select.Option>
          ))}
        </Select>

        <Select
          className="nw_mobile_container"
          style={{
            width: "15%",
            fontWeight: "500",
            marginRight: "15px",
          }}
          placeholder={"Booking Status"}
          value={filter?.booking_status}
          onChange={handleChange("booking_status")}
          allowClear
          size="large"
        >
          {Object.values(STATUS_TYPE).map((statusType) => (
            <Select.Option key={statusType?._id} value={statusType?._id}>
              {statusType?.name}
            </Select.Option>
          ))}
        </Select>
        <Select
          className="nw_mobile_container"
          style={{
            width: "15%",
            fontWeight: "500",
            marginRight: "15px",
          }}
          placeholder={"Payment Status"}
          value={filter?.payment_status}
          onChange={handleChange("payment_status")}
          allowClear
          size="large"
        >
          {PAYMENT_STATUS_TYPE.map(({ _id, name }) => (
            <Select.Option key={_id} value={_id}>
              {name}
            </Select.Option>
          ))}
        </Select>

        <Button
          className="mr10 nw_btn_black"
          size="large"
          type="primary"
          onClick={onSearch}
        >
          Search
        </Button>

        <Button
          className="mr10 nw_btn_white"
          size="large"
          type="primary-outline"
          onClick={onReset}
        >
          Reset
        </Button>
      </Card>

      <Row>
        <MTable
          columns={bookingColumns(
            isBookingEditable,
            updateMeeting,
            handleShowSwapRequest
          )}
          dataSource={data.slice(startIndex, startIndex + 10)}
          className="mt20 fw nw_table_container_box"
          loading={mBookingBoard?.loading}
          onChange={handleTableChange}
        />
        <Col span={24}>
          <MyPagination
            totalCount={mBookingBoard?.meta?.totalCount}
            onChangePage={handleTableChange}
          />
        </Col>
      </Row>

      <RaiseSwapRequest
        visible={showSwapRequest}
        handleModal={handleShowSwapRequest}
        selectedBookingId={selectedBooking?._id}
      />

      <NewBooking
        visible={showAddNewBooking}
        bookingId={selectedBooking?._id}
        handleModal={handleShowNewBooking}
        selectedBooking={selectedBooking}
        selectBooking={selectBooking}
        bookingStatus={bookingStatus}
        editable={isBookingEditable}
        onShowBookingHistory={() => setShowBookingHistory(true)}
      />

      <BookingHistory
        bookingId={selectedBooking?._id}
        isOpen={showBookingHistory}
        onClose={() => setShowBookingHistory(false)}
      />
    </div>
  );
};

export default Booking;
