import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Row, Tag, Popconfirm, Tooltip } from "antd";
import { deleteItem } from "../duck/ResourceActions";

export const deskListItemsColumns = (
  dispatch,
  showModal,
  selectSeat,
  editable,
  deskItemsMap
) => {
  const columns = [
    {
      title: "S.no.",
      dataIndex: "serial",
      key: "serial",
      align: "center",
      width: 60,
    },
    {
      title: "Seat No.",
      dataIndex: "seat",
      key: "seat",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <Tag
          color={
            type === "Hot Desk"
              ? "blue"
              : type === "Flexi Desk"
              ? "magenta"
              : "green"
          }
        >
          {type}
        </Tag>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 120,
      render: (seatId) =>
        !editable ? (
          <EyeOutlined />
        ) : (
          <Row justify="center">
            <Tooltip placement="top" title={"Edit"}>
              <span
                className="--avatar green small circle mr10"
                onClick={(e) => {
                  e.stopPropagation();
                  selectSeat(deskItemsMap[seatId]);
                  showModal(true);
                }}
              >
                <EditOutlined />
              </span>
            </Tooltip>
            <Tooltip placement="top" title={"Delete"}>
              <span className="--avatar red small circle">
                <Popconfirm
                  title="Are you sure to delete this?"
                  onConfirm={() => dispatch(deleteItem(seatId))}
                  okText="Yes"
                  cancelText="No"
                  placement="left"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </span>
            </Tooltip>
          </Row>
        ),
    },
  ];
  return columns;
};
