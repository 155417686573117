import React, { useState } from "react";
import { Form, Input, Button, Select, Col, DatePicker } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import AppUrl from "../config/AppUrl";
import moment from "moment";
import { resourceTypes, showNotification } from "../utils/CommonFunctions";

const { Option } = Select;

const Tourform = ({ space_id, handlePopup }) => {
  const [formdata, setFormdata] = useState({
    fullname: null,
    email: null,
    prefix: "44",
    phone: null,
    resource_type: null,
    date: null,
    time: null,
    loading: false,
  });

  const onFinish = (values = {}) => {
    let { fullname, email, prefix, phone, date, time, resource_type } =
      formdata;

    let bookingTourTime = moment(date).startOf("days").add(time, "hours");
    let data = {
      name: fullname,
      email: email,
      mobile_no: `+${prefix}${phone}`,
      timestamp: bookingTourTime,
      resource_type: resource_type,
      space_id: space_id,
      type: "book_tour",
    };

    setFormdata((state) => ({
      ...state,
      loading: true,
    }));

    axios({
      method: "POST",
      url: AppUrl.BOOKING_TOUR,
      data: data,
    })
      .then((res) => {
        let resData = res.data;
        if (resData?.meta?.success !== true || resData?.meta?.status !== 200) {
          showNotification("error", resData?.meta?.message);
          setFormdata((state) => ({
            ...state,
            loading: false,
          }));
        } else {
          showNotification("success", "Successfully registered for the tour");
          handlePopup();
        }
      })
      .catch((err) => {
        showNotification("error", "Something went wrong while booking tour");
        setFormdata((state) => ({
          ...state,
          loading: false,
        }));
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const prefixSelector = (
    <Select
      style={{ width: 70, border: "none" }}
      value={formdata.prefix}
      onChange={(e) => {
        setFormdata({
          ...formdata,
          prefix: e,
        });
      }}
    >
      <Option key="44" value="44">
        +44
      </Option>
      <Option key="91" value="91">
        +91
      </Option>
    </Select>
  );

  return (
    <Col className="popup_main activepopup">
      {/* <div style={{backgroundColor:'gold', borderTop:'1px solid gold', padding:'5px',fontSize:12 ,textAlign:'center'}}>Filling up fast--stake your claim</div> */}
      <div className="popup_inner">
        <CloseOutlined onClick={handlePopup} className="close_form" />
        <h6 className="popup_heading">Book a tour</h6>
        <p>
          Your health and safety is a top priority. That’s why we’ve introduced
          touring guidelines to give you peace of mind.
        </p>
        <Form
          className="locationForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Col span={24} className="popup_f_w">
            <Form.Item
              className="popup_field__input"
              name="fullname"
              value={formdata.fullname}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  fullname: e.target.value,
                });
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your name",
                },
              ]}
            >
              <Input placeholder="Full Name" disabled={formdata.loading} />
            </Form.Item>
          </Col>

          <Col span={24} className="popup_f_w">
            <Form.Item
              className="popup_field__input"
              name="email"
              value={formdata.email}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  email: e.target.value,
                });
              }}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter your email",
                },
              ]}
            >
              <Input placeholder="Work Email" disabled={formdata.loading} />
            </Form.Item>
          </Col>

          <Col span={24} className="popup_f_w">
            <Form.Item
              className="popup_field__input boder_tl"
              name="phone"
              value={formdata.phone}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  phone: e.target.value,
                });
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number",
                },
              ]}
            >
              <Input
                placeholder="Your Phone Number"
                disabled={formdata.loading}
                addonBefore={prefixSelector}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={24} className="popup_f_w">
            <Form.Item
              className="popup_field__input"
              name="location"
              rules={[
                {
                  required: true,
                  message: "Please select a resource",
                },
              ]}
            >
              <Select
                placeholder="Select Resource Type"
                value={formdata.resource_type}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    resource_type: e,
                  });
                }}
                disabled={formdata.loading}
              >
                {Object.values(resourceTypes).map((resourceType) => (
                  <Option key={resourceType?._id} value={resourceType?._id}>
                    {resourceType?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} className="popup_f_w">
            <Form.Item
              name="date"
              className="popup_field__input"
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please select date",
                },
              ]}
            >
              <DatePicker
                name="date"
                value={formdata.date}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    // date: `${e._d.getFullYear()}/${e._d.getMonth() + 1}/${e._d.getDate()}`
                    date: e,
                  });
                }}
                placeholder="Select Date"
                disabledDate={(current) =>
                  current && current < moment().startOf("day")
                }
                disabled={formdata.loading}
              />
            </Form.Item>
          </Col>

          <Col span={24} className="popup_f_w">
            <Form.Item
              className="popup_field__input"
              name="time"
              rules={[
                {
                  required: true,
                  message: "Please select time",
                },
              ]}
            >
              <Select
                placeholder="Select Time"
                value={formdata.time}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    time: e,
                  });
                }}
                disabled={formdata.loading}
              >
                <Option value="10">10.00 AM</Option>
                <Option value="11">11.00 AM</Option>
                <Option value="12">12.00 AM</Option>
                <Option value="13">01.00 PM</Option>
                <Option value="14">02.00 PM</Option>
                <Option value="15">03.00 PM</Option>
                <Option value="16">04.00 PM</Option>
                <Option value="17">05.00 PM</Option>
                <Option value="18">06.00 PM</Option>
              </Select>
            </Form.Item>
          </Col>

          <Form.Item className="popup_submit_button">
            <Button
              type="primary"
              htmlType="submit"
              className="mt20"
              style={{ height: 45, fontSize: 16 }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Col>
  );
};

export default Tourform;
