import { Row, Col, Tag, Avatar, Tooltip } from "antd";
import { getAvatarColor, getNameInitials } from "../../utils/CommonFunctions";
import moment from "moment";
import { CalendarOutlined, PoundOutlined } from "@ant-design/icons";

export const dashboardColumns = () => {
  const columns = [
    {
      title: "Resource Name",
      dataIndex: "resource",
      key: "resource",
      onCell: () => {
        return {
          style: {
            maxWidth: 130,
          },
        };
      },
      render: (resource) => (
        <div>
          <h4 className="nm">{resource[2]}</h4>
          <small>
            <span
              className="mr10"
              style={{
                width: 8,
                height: 8,
                display: "inline-block",
                backgroundColor: resource[1] || "grey",
                borderRadius: "50%",
              }}
            ></span>
            <span>{resource[0]}</span>
          </small>
        </div>
      ),
    },
    {
      title: "Event Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return (
          <Row justify="start" align="middle">
            <Col
              className="--avatar green mr15 circle"
              justify="center"
              align="middle"
            >
              <CalendarOutlined />
            </Col>
            <Col>
              <h4 className="nm">
                {moment(date[0]).format("ddd, MMMM Do, YYYY")}
              </h4>
              <small>
                {moment(date[0]).format("h:mm a")}
                <span style={{ margin: "0 5px", fontSize: 13 }}>to</span>
                {moment(date[1]).format("h:mm a")}
              </small>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          color={
            status === "Past"
              ? "red"
              : status === "Ongoing"
              ? "orange"
              : "green"
          }
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Price (£)",
      dataIndex: "price",
      key: "price",
      render: (price) => {
        return (
          <Row justify="start" align="middle" className="mt5">
            <Col
              className="--avatar orange mr15 circle"
              justify="center"
              align="middle"
            >
              <PoundOutlined style={{ fontSize: 18 }} />
            </Col>
            <Col>
              <h4 className="nm">£ {parseFloat(price[0] || 0).toFixed(2)}</h4>
              <small>Booked for {parseFloat(price[1])} hour</small>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Members",
      dataIndex: "members",
      key: "members",
      render: (members) => (
        <Row justify="start" align="middle" className="mt5">
          <Avatar.Group
            maxCount={5}
            maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
          >
            {members.map((user) => (
              <Tooltip placement="bottom" title={user?.name} key={user?._id}>
                <Avatar style={{ backgroundColor: getAvatarColor(user?._id) }}>
                  {getNameInitials(user?.name)}
                </Avatar>
              </Tooltip>
            ))}
          </Avatar.Group>
        </Row>
      ),
    },
  ];
  return columns;
};

export const options = {
  legend: {
    position: "top",
    align: "end",
  },
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
        scaleLabel: {
          display: true,
          labelString: "Counts",
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
        gridLines: {
          color: "#0000",
        },
        scaleLabel: {
          display: true,
          labelString: "Time Period",
        },
      },
    ],
  },
};
