import { put, takeLatest, call } from "redux-saga/effects";
import { showNotification } from "../../utils/CommonFunctions";
import ACTIONS from "./ReportActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const getAllReportsRequest = (params = {}) => {
  return axios({
    method: "GET",
    url: AppUrl.REPORTS,
    params: params,
  });
};

function* getAllReports(action) {
  try {
    let resources = yield call(getAllReportsRequest, action.payload);
    let _data = resources?.data;

    delete action.payload.offset;
    delete action.payload.limit;

    _data.filter = JSON.stringify(action.payload);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.FETCH_REPORTS_FAILURE,
        payload: _data,
      });
    } else {
      yield put({
        type: ACTIONS.FETCH_REPORTS_SUCCESS,
        payload: _data,
      });
    }
  } catch (error) {
    console.log("get all reports error", error);
  }
}

// ACTION WATCHER
export default function* reportSaga() {
  yield takeLatest(ACTIONS.FETCH_REPORTS, getAllReports);
}
