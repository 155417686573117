const Actions = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",

  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",

  GET_LOGGED_IN_USER_REQUEST: "GET_LOGGED_IN_USER_REQUEST",
  GET_LOGGED_IN_USER_SUCCESS: "GET_LOGGED_IN_USER_SUCCESS",
  GET_LOGGED_IN_USER_FAIL: "GET_LOGGED_IN_USER_FAIL",

  UPDATE_LOGGED_IN_USER_REQUEST: "UPDATE_LOGGED_IN_USER_REQUEST",
  UPDATE_LOGGED_IN_USER_SUCCESS: "UPDATE_LOGGED_IN_USER_SUCCESS",
  UPDATE_LOGGED_IN_USER_FAIL: "UPDATE_LOGGED_IN_USER_FAIL",
};

export default Actions;
