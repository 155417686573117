import Actions from "./ContactsActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";

const initialState = {
  contactMap: {},
  contacts: {},
  filter: null,
  loading: false,
  error: null,
};

const resourcesReducer = (state = initialState, action) => {
  let payload = action?.payload;

  switch (action.type) {
    /** get all contacts start */

    case Actions.FETCH_ALL_CONTACTS: {
      let params = Object.assign({}, payload);
      delete params?.offset;
      delete params?.limit;
      const filter = JSON.stringify(params);

      return Object.assign({}, state, {
        contacts: Object.assign({}, state.contacts, {
          [filter]: Object.assign({}, state.contacts?.[filter], {
            loading: true,
            error: null,
          }),
        }),
        filter: filter,
      });
    }

    case Actions.FETCH_ALL_CONTACTS_SUCCESS: {
      const filter = payload?.filter;

      return Object.assign({}, state, {
        contactMap: Object.assign(
          {},
          state.contactMap,
          arrToObjMap(payload?.data)
        ),
        contacts: Object.assign({}, state.contacts, {
          [filter]: Object.assign({}, state.contacts?.[filter], {
            list: getArrOrder(payload?.data),
            loading: false,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_CONTACTS_FAILURE: {
      const filter = payload?.filter;

      return Object.assign({}, state, {
        contacts: Object.assign({}, state.contacts, {
          [filter]: Object.assign({}, state.contacts?.[filter], {
            error: payload,
            loading: false,
          }),
        }),
      });
    }
    /** get all contacts end */

    /** add new contact start */
    case Actions.DELETE_CONTACT:
    case Actions.ADD_CONTACT: {
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    }

    case Actions.ADD_CONTACT_SUCCESS: {
      return Object.assign({}, state, {
        contactMap: Object.assign({}, state.contactMap, {
          [payload?._id]: payload,
        }),
        loading: false,
      });
    }

    case Actions.DELETE_CONTACT_SUCCESS: {
      return Object.assign({}, state, {
        contacts: Object.assign({}, state.contacts, {
          [state.filter]: Object.assign({}, state.contacts?.[state.filter], {
            list: state.contacts?.[state.filter]?.list?.filter(
              (id) => id !== payload?._id
            ),
            loading: false,
          }),
        }),
        loading: false,
      });
    }

    case Actions.DELETE_CONTACT_FAILURE:
    case Actions.ADD_CONTACT_FAILURE: {
      return Object.assign({}, state, {
        error: payload,
        loading: false,
      });
    }

    /** add new contact end */

    /** update contact start */
    case Actions.ADMIN_ACKNOWLEDGE_CONTACT: {
      return Object.assign({}, state, {
        contactMap: Object.assign({}, state.contactMap, {
          [payload?._id]: Object.assign({}, state.contactMap?.[payload?._id], {
            error: false,
            loading: true,
          }),
        }),
      });
    }

    case Actions.UPDATE_CONTACT_SUCCESS: {
      return Object.assign({}, state, {
        contactMap: Object.assign({}, state.contactMap, {
          [payload?.data?._id]: payload?.data,
        }),
      });
    }

    case Actions.UPDATE_CONTACT_FAILURE: {
      return Object.assign({}, state, {
        contactMap: Object.assign({}, state.contactMap, {
          [payload?._id]: Object.assign({}, state.contactMap?.[payload?._id], {
            error: payload,
            loading: false,
          }),
        }),
      });
    }
    /** update contact end */

    default:
      return state;
  }
};

export default resourcesReducer;
