import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../spinner/Spinner";
import KnowMoreCard from "./components/KnowMoreCard";
import KnowMoreModal from "./components/KnowMoreModal";

import { getAllCMS } from "./duck/CMSActions";
import { checkPermission } from "../utils/CommonFunctions";
import constants from "../utils/Constants";

import "./components/cms.scss";

const { CMS_TYPES } = constants;

const KnowMore = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({});
  const [boardKey, setBoardKey] = useState();

  const cmsBoard = useSelector(
    ({ cms }) => cms.boards[JSON.stringify(boardKey)]
  );
  const isCMSEditable = useMemo(() => checkPermission({ row: "cms" }), []);

  useEffect(() => {
    const params = { type: CMS_TYPES.KNOW_MORE };
    setBoardKey(params);
    dispatch(getAllCMS(params));
  }, [dispatch]);

  const handleModal = useCallback((show = false, pageId) => {
    show = typeof show === "boolean" && show;
    setState((preState) => ({ ...preState, showModal: show, pageId }));
  }, []);

  const loading = useMemo(
    () => cmsBoard?.loading && !cmsBoard?.list?.length,
    [cmsBoard?.loading, cmsBoard?.list]
  );

  return loading ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb10">
        <span className="nw_heading">Know More</span>

        <span>
          {isCMSEditable && (
            <Button type="primary-outline" onClick={() => handleModal(true)}>
              + Add Know More
            </Button>
          )}
        </span>
      </Row>

      <Row>
        {cmsBoard?.list?.map?.((id, i) => (
          <KnowMoreCard
            key={i}
            id={id}
            showModal={() => handleModal(true, id)}
            isCMSEditable={isCMSEditable}
          />
        ))}

        {state.showModal && (
          <KnowMoreModal
            visible={state.showModal}
            selectedId={state.pageId}
            showModal={() => handleModal(false)}
            isCMSEditable={isCMSEditable}
            otherDetails
          />
        )}
      </Row>
    </div>
  );
};

export default React.memo(KnowMore);
