import { put, takeLatest, call } from "redux-saga/effects";
import axios from "axios";

import ACTIONS from "./LeadsActionTypes";

import AppUrl from "../../config/AppUrl";
import { showNotification } from "../../utils/CommonFunctions";

const getAllLeadsRequest = (params = {}) => {
  return axios({
    method: "GET",
    url: AppUrl.LEADS,
    params: params,
  });
};

function* getAllLeads(action) {
  try {
    const resources = yield call(getAllLeadsRequest, action.payload);
    let _data = resources?.data;

    let params = Object.assign({}, action?.payload);
    delete params?.offset;
    delete params?.limit;
    _data.filter = JSON.stringify(params);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.FETCH_ALL_LEADS_FAILURE, payload: _data });
    } else {
      yield put({ type: ACTIONS.FETCH_ALL_LEADS_SUCCESS, payload: _data });
    }
  } catch (error) {
    console.log("get all leads error", error);
  }
}

// ACTION WATCHER
export default function* leadSaga() {
  yield takeLatest(ACTIONS.FETCH_ALL_LEADS, getAllLeads);
}
