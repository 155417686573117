import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row } from "antd";

import Spinner from "../spinner/Spinner";
import ImageGalleryCard from "./components/ImageGalleryCard";
import AddImageGallery from "./components/AddImageGallery";

import { getAllCMS } from "./duck/CMSActions";
import { checkPermission } from "../utils/CommonFunctions";

import "./components/cms.scss";
import constants from "../utils/Constants";

const { CMS_TYPES } = constants;

const ImageGallery = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({});
  const [boardKey, setBoardKey] = useState();
  const isCMSEditable = useMemo(() => checkPermission({ row: "cms" }), []);

  const cmsBoard = useSelector(
    (state) => state?.cms?.boards?.[JSON.stringify(boardKey)]
  );

  useEffect(() => {
    const params = { type: CMS_TYPES.IMAGE_GALLERY };
    setBoardKey(params);
    dispatch(getAllCMS(params));
  }, [dispatch]);

  const handleModal = useCallback((show = false, selectedId) => {
    show = typeof show === "boolean" && show;

    setState((preState) => ({ ...preState, showModal: show, selectedId }));
  }, []);

  const loading = cmsBoard?.loading && !cmsBoard?.list?.length;

  return loading ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb10">
        <span className="nw_heading">Image Gallery</span>

        <span>
          {cmsBoard?.list?.length <= 4 && isCMSEditable && (
            <Button type="primary-outline" onClick={() => handleModal(true)}>
              + Add Image Gallery
            </Button>
          )}
        </span>
      </Row>

      <Row>
        {cmsBoard?.list?.map((id, i) => (
          <ImageGalleryCard
            key={i}
            id={id}
            showModal={handleModal}
            isCMSEditable={isCMSEditable}
          />
        ))}
      </Row>

      <AddImageGallery
        visible={state?.showModal}
        selectedId={state?.selectedId}
        showModal={handleModal}
        isCMSEditable={isCMSEditable}
      />
    </div>
  );
};

export default ImageGallery;
