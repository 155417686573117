import Actions from "./RoleActionTypes";

export const getAllRoles = (params = {}) => {
  return {
    type: Actions.FETCH_ALL_ROLES,
    payload: params,
  };
};
export const getOneRole = (params = {}) => {
  return {
    type: Actions.GET_ONE_ROLE,
    payload: params,
  };
};

export const addRole = (params = {}) => {
  return {
    type: Actions.ADD_ROLE,
    payload: params,
  };
};

export const editRole = (params = {}) => {
  return {
    type: Actions.UPDATE_ROLE,
    payload: params,
  };
};
