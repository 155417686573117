import React, { useRef } from "react";
import { Row, Col, Button } from "antd";
// import logo from "../assets/images/invoicelogo.png";
import "../assets/css/invoice.scss";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { ArrowLeftOutlined, PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import logo from "../assets/svg/logo-black.svg";
import { resourceTypes } from "../utils/CommonFunctions";

const InvoicePreview = () => {
  const ref = useRef();
  const history = useHistory();

  const { id } = useParams();
  const invoice = useSelector((state) => state.invoice?.invoiceMap[id]);
  const {
    invoice_id,
    invoice_date,
    resource_id = {},
    customer = {},
    items = [],
  } = invoice || {};

  const bookedItem = items?.length > 0 && items[0];
  const resource_type = bookedItem?.desk_type || bookedItem?.type;
  const resource_name = resource_id?.name;

  const startDateTime = new Date(invoice?.from).getTime();
  const endDateTime = new Date(invoice?.to).getTime();
  const bookingHour = (endDateTime - startDateTime) / (1000 * 3600);
  const bookingDays = Math.ceil(bookingHour / 24);

  const tax_percent = invoice?.price?.tax || 20;
  const net_price = invoice?.price?.net || 0;
  const tax = (net_price * tax_percent) / 100;
  const totalPrice = invoice?.price?.total || net_price + tax;

  const subTotalPrice = invoice?.price?.net;
  let resourcePrice = 0;
  if (resource_type === "meeting_room") {
    resourcePrice = Math.ceil(net_price / bookingHour);
  } else {
    resourcePrice = Math.ceil(net_price / bookingDays);
  }

  return (
    <Col>
      <Row justify="space-between" align="top" className="mb10">
        <Col></Col>

        <Row>
          <ReactToPrint
            pageStyle={pageStyle}
            trigger={() => (
              <Button type="primary">
                <PrinterOutlined />
                Print
              </Button>
            )}
            documentTitle={`Invoice-${invoice_id}`}
            content={() => ref.current}
          />

          <Button className="ml10" onClick={() => history.goBack()}>
            <ArrowLeftOutlined />
            Back
          </Button>
        </Row>
      </Row>

      <Row
        ref={ref}
        className="invoice-box"
        justify="space-between"
        align="middle"
      >
        <Col span={24} className="p20">
          <Row justify="space-between" align="middle">
            <Col />
            <Col span={8}>
              <img src={logo} alt="Company logo" style={{ width: 300 }} />
            </Col>
          </Row>
        </Col>

        <Col span={24} className="pb20 pl20 pr20 mb20 ml15">
          <h3 style={{ fontSize: 25, fontWeight: 600 }}>INVOICE</h3>
          <Row justify="space-between" align="top">
            <Col span={6}>
              <Row>
                {customer?.name} <br />
                {customer?.address && (
                  <>
                    {customer?.address} <br />
                  </>
                )}
                {customer?.phone && (
                  <>
                    {customer?.phone} <br />
                  </>
                )}
                {customer?.email}
              </Row>
              <Row />
            </Col>
            <Col span={4}>
              <p style={{ fontWeight: 600, marginBottom: 0 }}>Invoice Date</p>
              {invoice_date && moment(invoice_date).format("DD MMM YYYY")}
              <p style={{ fontWeight: 600, marginBottom: 0 }}>Invoice Number</p>
              #{invoice_id}
            </Col>
            <Col span={10}>
              Garden Office Bermondsey <br />
              37 Tanner Street SE1 3LF(off Bermondsey Street) and Tower Bridge
              Road SE1 3JB
              <br />
              <Row className="">Email: info@go-maltingsbermondsey.co.uk</Row>
            </Col>
          </Row>
        </Col>

        <table className="mb20 mt20">
          <thead>
            <tr className="heading borderBottom">
              <td>DESCRIPTION</td>
              <td>RATE</td>
              <td>{resource_type === "meeting_room" ? "HOUR" : "Days"}</td>
              <td>TOTAL</td>
            </tr>
          </thead>
          <tbody>
            <tr className="item borderBottomLight">
              <td>
                {resourceTypes[resource_type]?.name} - {resource_name}
                {resource_type?.includes("desk") &&
                  ` (${
                    Array.isArray(invoice?.items) &&
                    invoice?.items?.map((item, i) => item?.seat_no)
                  })`}
              </td>
              <td>£{resourcePrice}</td>
              <td>
                {resource_type === "meeting_room" ? bookingHour : bookingDays}
              </td>
              <td>£{parseFloat(subTotalPrice).toFixed(2)}</td>
            </tr>
            <tr className="total">
              <td></td>
              <td></td>
              <td className="bold">Subtotal:</td>
              <td> £{parseFloat(subTotalPrice).toFixed(2)}</td>
            </tr>
            <tr className="total">
              <td></td>
              <td></td>
              <td className="bold">Discount:</td>
              <td> {parseFloat(invoice?.plan?.discount || 0).toFixed(2)}%</td>
            </tr>
            <tr className="total">
              <td></td>
              <td className="borderBottom"></td>
              <td className="bold borderBottom">Tax:</td>
              <td className="bold borderBottom"> {tax_percent}%</td>
            </tr>
            <tr className="total">
              <td></td>
              <td></td>
              <td className="bold"> Total:</td>
              <td className="bold"> £{parseFloat(totalPrice).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        <Col className="mb10 mt20 ml10">
          For any queries, feel free to reach us at:
          info@go-maltingsbermondsey.co.uk | +44 (0) 203 465 9123
        </Col>
      </Row>
    </Col>
  );
};

const pageStyle = `
    @media print {
        html, body {
            height: auto !important;
            overflow: hidden !important;
            -webkit-print-color-adjust: exact;
        }
    }
`;

export default InvoicePreview;
