import {
  UserOutlined,
  ContainerOutlined,
  CalendarOutlined,
  CreditCardOutlined,
  TeamOutlined,
  FormOutlined,
} from "@ant-design/icons";
import React from "react";

const reportsTransformer = (model) => {
  switch (model) {
    case "Booking":
      return <CalendarOutlined className="mr15" />;

    case "Invoice":
      return <ContainerOutlined className="mr15" />;

    case "Profile":
      return <UserOutlined className="mr15" />;

    case "Billing":
      return <CreditCardOutlined className="mr15" />;

    case "Member":
      return <TeamOutlined className="mr15" />;

    default:
      return <FormOutlined className="mr15" />;
  }
};

export default reportsTransformer;
