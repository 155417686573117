import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import PriceItem from "./components/PriceItem";
import "../assets/css/pricing.scss";
import { updateWorkSpace } from "../workspace/duck/WorkSpaceActions";
import AddRecord from "./components/AddRecord";
import UpdatePrice from "./components/UpdatePrice";
import { checkPermission } from "../utils/CommonFunctions";
import Spinner from "../spinner/Spinner";

const Pricing = () => {
  const resourceTypes = Object.freeze({
    flexi_desk: {
      id: "flexi_desk",
      name: "Flexi Desk",
      editKey: "flexiDesk",
      price: 10,
    },
    dedicated_desk: {
      id: "dedicated_desk",
      name: "Dedicated Desk",
      editKey: "dedicatedDesk",
      price: 20,
    },
  });

  const dispatch = useDispatch();

  const { resource_type } = useParams();
  const resourceType = resourceTypes[resource_type];

  const workSpace = useSelector((state) => state?.workSpace);
  const activeWorkSpace = workSpace?.active;

  const mWorkSpace = workSpace?.workSpaceMap[activeWorkSpace];
  const resource_price_slabs = mWorkSpace?.dynamic_price_slab?.[resource_type];

  const pricingSpin = !workSpace?.workSpaceList.length && workSpace?.loading;

  const [state, setState] = useState({
    canEdit: false,
    slab_id: null,
    showPriceModal: false,
    showRecordModal: false,
  });
  const [records, setRecords] = useState([]);

  const preload = () => {
    let newRecords = Object.keys(resource_price_slabs || {}).map(
      (slab, index) => {
        if (slab) {
          let min_max = String(slab).split("-");

          return {
            index: index,
            _id: slab,
            min: Number(min_max[0]) || 0,
            max: Number(min_max[1]) || 0,
            value: Number(resource_price_slabs[slab]?.value) || 0,
            status: resource_price_slabs[slab]?.status,
          };
        }
      }
    );
    newRecords = newRecords.filter((newRecord) => newRecord);
    newRecords.sort((a, b) => a?.min - b?.min);

    setRecords(newRecords);
  };

  useEffect(() => {
    preload();
  }, [resource_price_slabs]);
  let editable = checkPermission({
    row: "pricing",
    subRow: resourceTypes?.[resource_type]?.editKey,
  });

  if (!resourceType) {
    return <></>;
  }

  const onSave = (newSlab) => {
    let min = newSlab?.min;
    let max = newSlab?.max;

    let resource_slab = Object.assign({}, resource_price_slabs);
    delete resource_slab[newSlab?._id];

    dispatch(
      updateWorkSpace({
        _id: activeWorkSpace,
        dynamic_price_slab: {
          [resource_type]: Object.assign({}, resource_slab, {
            [`${min}-${max}`]: {
              value: newSlab?.value,
              status: newSlab?.status,
            },
          }),
        },
      })
    );
  };

  const handleAddRecordModal = (show = false, slab_id) => {
    show = typeof show === "boolean" && show;

    setState((preState) => ({
      ...preState,
      showRecordModal: show,
      slab_id,
    }));
  };

  const handleUpdatePriceModal = (show = false) => {
    show = typeof show === "boolean" && show;

    setState((preState) => ({
      ...preState,
      showPriceModal: show,
    }));
  };

  return pricingSpin ? (
    <Spinner />
  ) : (
    <>
      <Row
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <Col xs={24} md={24} lg={24} xl={24}>
          <Row justify="space-between" align="middle" className="mb10">
            <span style={{ fontSize: "20px", fontWeight: "500" }}>
              {resourceType?.name}
            </span>

            {editable && (
              <Row>
                <Button
                  className="mr5"
                  onClick={() => handleUpdatePriceModal(true)}
                >
                  Update Base Price
                </Button>

                <Button
                  type="primary-outline"
                  onClick={() => handleAddRecordModal(true)}
                >
                  + Add Logic
                </Button>
              </Row>
            )}
          </Row>
          <Row className="mb5">
            <Col span={24}>
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                {resourceType?.name} Price(Base Price) :{" "}
                {mWorkSpace?.price?.[resource_type] || 0} £/day
              </div>
            </Col>
          </Row>

          <div
            style={{
              background: "#fff",
              borderRadius: "5px",
              marginTop: "15px",
            }}
          >
            <Col className="anttabledata">
              <table>
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>Occupancy</th>
                    <th style={{ textAlign: "center" }}>Increase Price</th>
                    <th style={{ textAlign: "center" }}>Status Toggle</th>
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(records) && records.length > 0 ? (
                    records.map((record, index) => (
                      <PriceItem
                        key={index}
                        item={record}
                        editable={editable}
                        onEdit={handleAddRecordModal}
                      />
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        No Data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </div>
        </Col>
      </Row>

      <UpdatePrice
        visible={state.showPriceModal}
        showModal={handleUpdatePriceModal}
        resourceType={resource_type}
        resourceName={resourceTypes[resource_type]?.name}
      />

      <AddRecord
        visible={state.showRecordModal}
        showModal={handleAddRecordModal}
        selectedRecord={records.find((record) => record?._id === state.slab_id)}
        records={records}
        onSave={onSave}
        editable={editable}
      />
    </>
  );
};

export default Pricing;
