import { put, takeLatest, call } from "redux-saga/effects";
import { showNotification } from "../../utils/CommonFunctions";
import ACTIONS from "./DashboardActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const getDashboardRequest = (params) => {
  return axios({
    method: "GET",
    url: `${AppUrl.WORKSPACE}/${params?.space_id}/dashboard`,
    params: params,
  });
};

const getMemberDashboardRequest = () => {
  return axios({
    method: "GET",
    url: `${AppUrl.WORKSPACE}/dashboard`,
  });
};

function* getDashboard(action) {
  try {
    let resources = yield call(getDashboardRequest, action.payload);
    let _data = resources?.data;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      _data.meta.space_id = action.payload?.space_id;
      yield put({
        type: ACTIONS.FETCH_DASHBOARD_FAILURE,
        payload: _data?.meta,
      });
    } else {
      _data.data.space_id = action.payload?.space_id;
      yield put({ type: ACTIONS.FETCH_DASHBOARD_SUCCESS, payload: _data.data });
    }
  } catch (error) {
    console.log("fetch workspace error", error);
  }
}
function* getMemberDashboard(action) {
  try {
    let resources = yield call(getMemberDashboardRequest, action.payload);
    let _data = resources?.data;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.FETCH_MEMBER_DASHBOARD_FAILURE,
        payload: _data?.meta,
      });
    } else {
      yield put({
        type: ACTIONS.FETCH_MEMBER_DASHBOARD_SUCCESS,
        payload: _data.data,
      });
    }
  } catch (error) {
    console.log("fetch workspace error", error);
  }
}

// ACTION WATCHER
export default function* dashboardSaga() {
  yield takeLatest(ACTIONS.FETCH_DASHBOARD, getDashboard);
  yield takeLatest(ACTIONS.FETCH_MEMBER_DASHBOARD, getMemberDashboard);
}
