const Actions = {
    FETCH_ALL_XEROS: 'FETCH_ALL_XEROS',
    FETCH_ALL_XEROS_SUCCESS: 'FETCH_ALL_XEROS_SUCCESS',
    FETCH_ALL_XEROS_FAILURE: 'FETCH_ALL_XEROS_FAILURE',

    FETCH_XERO: 'FETCH_XERO',
    FETCH_XERO_SUCCESS: 'FETCH_XERO_SUCCESS',
    FETCH_XERO_FAILURE: 'FETCH_XERO_FAILURE',
}

export default Actions;