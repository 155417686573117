import React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Table } from "antd";
import { billingColumns } from "./BillingColumns";
import MTable from "../../utils/MTable";

const Invoices = () => {
  const history = useSelector((state) => state?.billing?.history);
  const historyList = useSelector((state) => state?.billing?.historyList);

  const invoices = [];
  historyList &&
    historyList.forEach((id, index) => {
      const mHistory = history?.[id];
      const amount = mHistory?.amount;
      const plan = mHistory?.description;

      const card = mHistory?.payment_method_details?.card?.last4;
      const date = mHistory?.created;
      let status = mHistory?.status;

      if (status === "failed") status = "Failed";
      else if (status === "succeeded") status = "Successfull";

      invoices.push({
        key: id,
        plan,

        amount,
        card,
        date,
        status,
      });
    });

  return (
    <>
      <Row className="table-heading" > Last 10 transactions </Row>
      <MTable
        className="mt20 fw"
        pagination={false}
        dataSource={invoices}
        columns={billingColumns()}
      />
    </>
  );
};

export default Invoices;
