const Actions = {
    FETCH_ALL_PLANS: 'FETCH_ALL_PLANS',
    FETCH_ALL_PLANS_SUCCESS: 'FETCH_ALL_PLANS_SUCCESS',
    FETCH_ALL_PLANS_FAILURE: 'FETCH_ALL_PLANS_FAILURE',

    FETCH_PLAN: 'FETCH_PLAN',
    FETCH_PLAN_SUCCESS: 'FETCH_PLAN_SUCCESS',
    FETCH_PLAN_FAILURE: 'FETCH_PLAN_FAILURE',

    ADD_PLAN: 'ADD_PLAN',
    ADD_PLAN_SUCCESS: 'ADD_PLAN_SUCCESS',
    ADD_PLAN_FAILURE: 'ADD_PLAN_FAILURE',

    UPDATE_PLAN: 'UPDATE_PLAN',
    UPDATE_PLAN_SUCCESS: 'UPDATE_PLAN_SUCCESS',
    UPDATE_PLAN_FAILURE: 'UPDATE_PLAN_FAILURE',
}

export default Actions;