import React, { useMemo } from "react";
import { Card, Row, Tag, Tooltip, Typography } from "antd";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { checkPermission, resourceTypes } from "../../utils/CommonFunctions";
import Routes from "../../config/Routes";
import desk from "../../assets/images/desk.png";
const { Paragraph } = Typography;

const DeskAreaCard = (props) => {
  const { id, onEdit = () => {} } = props;

  const history = useHistory();

  const isMeetingRoomsEditable = useMemo(
    () => checkPermission({ row: "resources", subRow: "meetingRooms" }),
    []
  );
  const resourceMap = useSelector((state) => state.resources?.resourceMap);
  const mResource = useMemo(() => resourceMap?.[id] || {}, [resourceMap, id]);

  const ExtraIcon = isMeetingRoomsEditable ? EditOutlined : EyeOutlined;

  return (
    <Card
      style={{
        width: 320,
        margin: "10px 20px 10px 0",
        borderRadius: 5,
      }}
      onClick={() => history.push(`${Routes.desk}/${id}`)}
      title={
        <Row
          justify="space-between"
          align="middle"
          onClick={(e) => e.stopPropagation()}
        >
          {mResource?.name}
          <Tooltip title={isMeetingRoomsEditable ? "Edit" : "More Details"}>
            <ExtraIcon
              style={{ fontSize: 12 }}
              onClick={() => onEdit(true, id)}
            />
          </Tooltip>
        </Row>
      }
      bordered={false}
      hoverable
      cover={<img alt="Desk Area" src={desk} />}
    >
      {mResource?.desk_type?.map?.((val, index) => {
        const type = Object.keys(val)[0];
        const resourceType = resourceTypes[type];

        if (val[type]?.total) {
          return (
            <Tag
              key={index}
              style={{ marginBottom: 10 }}
              color={resourceType?.color}
            >
              {resourceType?.name}

              <span style={{ fontWeight: 500, fontSize: 14 }} className="ml5">
                {`- ${val[type]?.total}`}
              </span>
            </Tag>
          );
        }
      })}

      {mResource?.description && (
        <div className="mb15" style={{ fontWeight: 400 }}>
          <Paragraph ellipsis={{ rows: 3 }}>{mResource?.description}</Paragraph>
        </div>
      )}

      {mResource?.amenities_id?.length > 0 && (
        <div className="mt5" style={{ fontWeight: 600 }}>
          {mResource?.amenities_id
            ?.filter((a) => a?.active)
            ?.map((amenity) => amenity?.title)
            .join(", ")}
        </div>
      )}
    </Card>
  );
};

export default DeskAreaCard;
