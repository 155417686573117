import React, { useEffect, useState } from "react";
import { Button, Card, Carousel, Col, Row, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import "./css/search.scss";

import meeting from "../assets/images/meeting.png";
import office from "../assets/images/office.png";
import desk from "../assets/images/desk.png";

import WebLayoutContainer from "./component/WebLayoutContainer";
import { getAllWorkSpaceRequest } from "../workspace/duck/WorkSpaceActions";
import GoogleMapReact from "google-map-react";
import Tourform from "./Tourform";
import Routes from "../config/Routes";

const color = ["green", "orange", "red", "purple", "cyan", "pink", "yellow"];

const resourceTypes = {
  meeting_room: {
    name: "Meeting Room",
    image: meeting,
    desc: "A space usually set aside for people to get together, often informally to hold meetings, for issues to be discussed, priorities set and decisions made",
  },
  private_office: {
    name: "Private Office",
    image: office,
    desc: "Lockable office space for teams of all sizes",
  },
  dedicated_desk: {
    name: "Dedicated Desk",
    image: desk,
    desc: "Desk space in a shared lockable office",
  },
  flexi_desk: {
    name: "Flexi Desk",
    image: desk,
    desc: "On-demand access to shared workspace and common areas",
  },
};

Object.freeze(resourceTypes);

const Space = () => {
  const [popup, setPopup] = useState(false);
  const params = useParams();
  const { spaceId } = params;

  const dispatch = useDispatch();
  const history = useHistory();

  const space = useSelector((state) => state.workSpace.workSpaceMap[spaceId]);

  useEffect(() => {
    dispatch(getAllWorkSpaceRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchAvailableWorkspace = () => {
    history.push(`${Routes.homeSearch}?spaceId=${spaceId}`);
  };

  const handleClick = () => {
    setPopup(!popup);
  };



  return (
    <>
      <WebLayoutContainer>
        <Col className="container">
          <Row
            className="mt20 mb20 pt20 pb20"
            align="middle"
            justify="space-between"
          >
            <Col span={16} className="pr20">
              <Row
                className="headind-1"
                style={{ fontSize: "4rem", fontWeight: 600 }}
              >
                {space?.name}
              </Row>
              <Row style={{ fontSize: 15, fontWeight: 400 }}>
                {space?.description}
              </Row>
            </Col>

            <Col
              span={8}
              style={{ fontSize: 16, fontWeight: 500, textAlign: "right" }}
            >
              {space?.area}
            </Col>
          </Row>
        </Col>

        <Col className="container">
          <Row
            className="mt20 mb20 pt20 pb20"
            align="middle"
            justify="space-between"
          >
            <Col span={16} className="pr20">
              <Carousel autoplay>
                {Array.isArray(space?.pictures) &&
                  space?.pictures.map(
                    (picture) =>
                      picture?.status &&
                      picture?.thumb_location && (
                        <div key={picture?._id} className="img_con">
                          <Image
                            preview={false}
                            src={picture?.original || picture?.thumb_location}
                            alt={picture?.filename}
                            className="cover new_contain"
                            wrapperStyle={{ height: 430 }}
                            width={"100%"}
                            height={"100%"}
                            style={{ borderRadius: 4 }}
                          />
                        </div>
                      )
                  )}
              </Carousel>
            </Col>
            <Col span={8}>
              <div
                style={{
                  width: "100%",
                  height: 426,
                  borderRadius: 4,
                  overflow: "hidden",
                }}
              >
                <GoogleMapReact
                  // bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
                  defaultCenter={{
                    lat: Number(space?.latitude ?? 59.95),
                    lng: Number(space?.longitude ?? 30.33),
                  }}
                  defaultZoom={15}
                >
                  {space?.latitude && space?.longitude && (
                    <div
                      lat={Number(space?.latitude)}
                      lng={Number(space?.longitude)}
                    >
                      {space?.name}
                    </div>
                  )}
                </GoogleMapReact>
              </div>
            </Col>
          </Row>
        </Col>

        <Col className="container">
          <Row className="mt20 mb20 pt20 pb20">
            <Col span={16} className="pr20">
              {/* Available Workspace */}
              <Col>
                <Col
                  className="mb20 headind-2"
                  style={{ fontSize: "2.5rem", fontWeight: 500 }}
                >
                  Available workspace
                </Col>
                <Row style={{ fontSize: 15, fontWeight: 400 }}>
                  List of resources available
                </Row>
                {space?.resources_available.map((resource) => (
                  <Col
                    key={resource}
                    className="mt20 mb20"
                    onClick={onSearchAvailableWorkspace}
                  >
                    <Card style={{ margin: 0, borderRadius: 4 }} hoverable>
                      <Row align="middle">
                        <Col span={5} className="mr15">
                          <img
                            style={{ width: "100%" }}
                            src={resourceTypes[resource]?.image}
                            alt=""
                          />
                        </Col>

                        <Col span={16}>
                          <div
                            style={{
                              fontSize: 18,
                              fontWeight: 500,
                              marginBottom: 4,
                            }}
                          >
                            {resourceTypes[resource]?.name}
                          </div>
                          <div style={{ fontSize: 13 }}>
                            {resourceTypes[resource]?.desc}
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))}
              </Col>
              {/* Amenities */}
              <Col className="pt20 pb20 mt20 mb20">
                <Col className="pt20 pb20">
                  <Col
                    className="mb20 headind-2"
                    style={{ fontSize: "2.5rem", fontWeight: 500 }}
                  >
                    Amenities
                  </Col>
                  <Row style={{ fontSize: 15, fontWeight: 400 }}>
                    Updated with your health and safety in mind
                  </Row>
                  <Row className="mt20">
                    {space?.amenities?.map((amenity) => (
                      <Row
                        key={amenity?._id}
                        className="mr20 mb20"
                        align="middle"
                        justify="center"
                        style={{
                          backgroundColor: "#f4f4f4",
                          borderRadius: 4,
                          width: 150,
                          height: 120,
                          padding: "10px 15px",
                          fontSize: 15,
                          textAlign: "center",
                          // borderRadius:'50%'
                        }}
                      >
                        {amenity?.title}
                      </Row>
                    ))}
                  </Row>
                </Col>
                {/* Working Days */}
                <Col className="pt20 pb20 mt20 mb20">
                  <Col
                    className="mb20 headind-2"
                    style={{ fontSize: "2.5rem", fontWeight: 500 }}
                  >
                    Working Days
                  </Col>
                  <Row style={{ fontSize: 15, fontWeight: 400 }}>
                    We are available for you for the given days
                  </Row>
                  <Row className="mt20">
                    {space?.no_of_days?.map((day, index) => (
                      <Row
                        key={index}
                        className={`mr10 mb10 --avatar ${color[index]}`}
                        align="middle"
                        justify="center"
                        style={{
                          // backgroundColor:'#f4f4f4',
                          borderRadius: 4,
                          width: 120,
                          height: 45,
                          // padding:'10px 15px'
                        }}
                      >
                        {day}
                      </Row>
                    ))}
                  </Row>
                </Col>
                {/* Healthier workplace */}
                <Col className="pt20 pb20">
                  <Col
                    className="mb20 headind-2"
                    style={{ fontSize: "2.5rem", fontWeight: 500 }}
                  >
                    Creating a healthier workplace
                  </Col>
                  <Row
                    style={{ fontSize: 15, fontWeight: 400 }}
                    className="pr20"
                  >
                    We’ve been working around the clock to reimagine our spaces
                    to prioritize your health and safety. We’ve also brought in
                    experts, working with International SOS to provide strategic
                    direction on medical considerations and quality assurance,
                    and Bureau Veritas to audit and endorse our policies so we
                    can constantly improve.
                  </Row>
                </Col>
              </Col>
            </Col>
            <Col span={8}>
              {/* popup component */}
              <Card
                bodyStyle={{ padding: 0 }}
                style={{
                  margin: 0,
                  borderRadius: 4,
                  backgroundColor: "#f4f4f4",
                  position: "sticky",
                  top: 5,
                  zIndex: 2,
                }}
              >
                {!popup && (
                  <Col style={{ padding: 24 }}>
                    <Row
                      className="mb20"
                      style={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      Interested in this building?
                    </Row>

                    <Row
                      className="mb20"
                      style={{ fontSize: 16, fontWeight: "500" }}
                    >
                      View available space and reserve online.
                    </Row>
                    <Row className="mb20">
                      <Button
                        type="primary"
                        className="fw"
                        style={{ height: 45, fontSize: 16 }}
                        onClick={onSearchAvailableWorkspace}
                      >
                        Book Workspace
                      </Button>
                    </Row>

                    <Row
                      className="mb20"
                      style={{ fontSize: 16, fontWeight: "500" }}
                    >
                      Prefer to see it in person?
                    </Row>
                    <Row>
                      <Button
                        type="primary-outline"
                        className="fw"
                        style={{ height: 45, fontSize: 16 }}
                        onClick={handleClick}
                      >
                        Book a tour
                      </Button>
                    </Row>
                  </Col>
                )}
                {popup && (
                  <Tourform handlePopup={handleClick} space_id={spaceId} />
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </WebLayoutContainer>
    </>
  );
};

export default Space;
