import React, { useEffect, useMemo, useState } from "react";
import { Button, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import Spinner from "../spinner/Spinner";
import OurSpaceCard from "./components/OurSpaceCard";
// import AddOurSpaceModal from "./components/AddOurSpaceModal";
import PageMetaModal from "./components/PageMetaModal";

import { getAllCMS, updateCMS } from "./duck/CMSActions";
import { checkPermission } from "../utils/CommonFunctions";
import constants from "../utils/Constants";
import Routes from "../config/Routes";

const { CMS_TYPES, CMS_PAGES } = constants;

const OurSpace = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // const [state, setState] = useState({});
  const [showPageMetaModal, setShowPageMetaModal] = useState(false);
  const [boardKey, setBoardKey] = useState();

  const pageMetaBoardParams = useMemo(
    () => ({ type: CMS_TYPES.PAGE_META, page_name: CMS_PAGES.OUR_SPACE }),
    []
  );

  const { cmsBoard, mPageMetaId } = useSelector(({ cms }) => {
    const boards = cms.boards;
    const cmsBoard = boards[JSON.stringify(boardKey)];
    const mPageMetaId = boards[JSON.stringify(pageMetaBoardParams)]?.list?.[0];

    return { cmsBoard, mPageMetaId };
  });

  const isCMSEditable = useMemo(() => checkPermission({ row: "cms" }), []);

  useEffect(() => {
    const params = { type: CMS_TYPES.OUR_SPACE };
    setBoardKey(params);
    dispatch(getAllCMS(params));
    dispatch(getAllCMS(pageMetaBoardParams));
  }, [dispatch, pageMetaBoardParams]);

  // const handleModal = useCallback((show = false, spaceId) => {
  //   show = typeof show === "boolean" && show;

  //   setState((preState) => ({ ...preState, showModal: show, spaceId }));
  // }, []);

  const deleteOurSpaces = (id) => {
    const params = {
      _id: id,
      status: false,
      active: false,
    };
    dispatch(updateCMS(params));
  };

  const loading = cmsBoard?.loading && !cmsBoard?.list?.length;

  return loading ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb10">
        <span className="nw_heading">Our Spaces</span>

        <span>
          {isCMSEditable && (
            <Button
              type="primary-outline mr10"
              onClick={() => setShowPageMetaModal(true)}
            >
              Edit Page Details
            </Button>
          )}

          {isCMSEditable && (
            <Button
              type="primary-outline"
              onClick={() => history.push(`${Routes.ourSpace}/createNewSpace`)}
            >
              + Add New Space
            </Button>
          )}
        </span>
      </Row>

      <Row>
        {cmsBoard?.list?.map((id, i) => (
          <OurSpaceCard
            key={i}
            id={id}
            deleteOurSpaces={deleteOurSpaces}
            showModal={() => history.push(`${Routes.ourSpace}/${id}`)}
            isCMSEditable={isCMSEditable}
          />
        ))}
      </Row>
      <PageMetaModal
        visible={showPageMetaModal}
        selectedId={mPageMetaId}
        showModal={() => setShowPageMetaModal(false)}
        isCMSEditable={isCMSEditable}
        pageName={CMS_PAGES.OUR_SPACE}
        headingInputType="TextEditor"
        descriptionInputType="TextEditor"
        otherDetails
      />
    </div>
  );
};

export default OurSpace;
