import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Row, Input, Button, Card, Col, Popconfirm } from "antd";
import {
  EyeOutlined,
  SearchOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../spinner/Spinner";
import { contactColumns } from "./component/ContactColumns";
import { deleteContactLead, getAllContacts } from "./duck/ContactsActions";
import ContactDetails from "./component/ContactDetails";
import MTable from "../utils/MTable";
import axios from "axios";
import AppUrl from "../config/AppUrl";
import { checkPermission, showNotification } from "../utils/CommonFunctions";
import fileDownload from "js-file-download";

const Contacts = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const [myFilter, setMyFilter] = useState({});

  const editable = useMemo(() => checkPermission({ row: "contactUs" }), []);

  const activeWorkspace = useSelector((state) => state?.workSpace?.active);
  const { contacts, contactMap, filter } = useSelector(
    (state) => state?.contacts
  );
  const mContacts = contacts?.[filter];
  const isSpin = !mContacts?.list && mContacts?.loading;

  const preload = useCallback(() => {
    dispatch(getAllContacts({ space_id: activeWorkspace }));
  }, [activeWorkspace, dispatch]);

  useEffect(() => {
    activeWorkspace && preload();
    setMyFilter({});
  }, [activeWorkspace, preload]);

  const onSearch = () => {
    let params = {
      space_id: activeWorkspace,
    };

    if (myFilter?.text) params.text = myFilter?.text;

    dispatch(getAllContacts(params));
  };
  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setMyFilter((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );
  const print = useCallback(() => {
    axios({
      method: "GET",
      url: `${AppUrl.BOOKING_TOUR}/export`,
      responseType: "arraybuffer",
    })
      .then((response) => {
        if (response.data) {
          let filename = response.headers["content-type"].split("=");
          fileDownload(response.data, filename[1]);
          showNotification("success", "File downloaded.");
        } else {
          showNotification("error", "Error while downloading file.");
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("error", "Something went wrong");
      });
  }, []);

  const handleModal = (show = false, contactId) => {
    show = typeof show === "boolean" && show;
    setState((preState) => ({ ...preState, visible: show, contactId }));
  };

  let data = [];
  mContacts?.list?.forEach?.((id, index) => {
    const contact = contactMap[id];
    const isNew = contact?.type === "book_tour" && !contact?.meta;

    data.push({
      key: id,
      sn: ++index,
      name: {
        name: contact?.name,
        isNew,
      },
      email: contact?.email,
      mobile: contact?.mobile_no,
      created_at: contact?.created_at,
      space_id: contact?.space_id,
      type: contact?.type,
      action: (
        <Row align="middle" justify="space-around">
          <EyeOutlined onClick={() => handleModal(true, id)} />
          {editable && (
            <span className="--avatar red small circle">
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => dispatch(deleteContactLead(id))}
                okText="Yes"
                cancelText="No"
                placement="bottom"
              >
                <DeleteOutlined />
              </Popconfirm>
            </span>
          )}
        </Row>
      ),
    });
  });

  return isSpin ? (
    <Spinner />
  ) : (
    <div className="mainMember">
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Contact Us</span>
      </Row>

      <Card title="Filter" size="small" bordered={false}>
        <Row align="middle" justify="space-between">
          <Col span={18}>
            <Input
              size="middle"
              className="custom-input mr10"
              style={{ width: "35%", fontWeight: "500", marginRight: "15px" }}
              placeholder="Search Name, Email, Phone"
              prefix={<SearchOutlined />}
              value={myFilter?.text}
              onChange={handleChange("text")}
              allowClear
            />

            <Button className="mr10" type="primary" onClick={() => onSearch()}>
              Search
            </Button>
            <Button
              className="mr10"
              type="primary-outline"
              onClick={() => {
                setMyFilter({});
                dispatch(getAllContacts({ space_id: activeWorkspace }));
              }}
            >
              Reset
            </Button>
          </Col>
          <Col span={5}>
            <Button type="primary" onClick={print}>
              <DownloadOutlined />
              Download as Excel file
            </Button>
          </Col>
        </Row>
      </Card>

      <MTable
        className="mt20"
        bordered={false}
        columns={contactColumns()}
        dataSource={data}
      />

      <ContactDetails
        visible={state?.visible}
        contactId={state?.contactId}
        handleModal={handleModal}
      />
    </div>
  );
};

export default Contacts;
