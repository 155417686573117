import appUrl from './AppUrl';
import openSocket from 'socket.io-client';
let socket = undefined;

const initializeSocket = function(){
    socket = openSocket(appUrl.SOCKET_URL);
    console.log('connecting?');
    socket.on('connect', function () {
        socket
            .emit('authenticate', {token: localStorage.getItem('accessToken')}) //send the jwt
            .on('authenticated', function () {
                console.log('Authenticated')
            }).on('unauthorized', (msg) => {
            console.log(`unauthorized: ${JSON.stringify(msg.data)}`);
            // throw new Error(msg.data.type);
        });
    });
};

const closeSocket = function() {
    socket = socket.disconnect();
}

export {socket, initializeSocket, closeSocket};