import Actions from "./WorkSpaceActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";
import { getUserWorkspace } from "../../utils/CommonFunctions";

const initialState = {
  workSpaceList: [],
  workSpaceMap: {},
  loading: false,
  error: null,
  active: null,
  newlyCreated: null,
};

const workSpaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ALL_WORKSPACE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Actions.ADD_WORKSPACE_REQUEST: {
      return {
        ...state,
        adding: true,
        error: null,
        newlyCreated: null,
      };
    }
    case Actions.UPDATE_WORKSPACE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Actions.ADD_WORKSPACE_SUCCESS: {
      const mWorkSpace = action?.payload;

      if (!state.workSpaceList.includes(mWorkSpace?._id)) {
        return Object.assign({}, state, {
          workSpaceList: [...state.workSpaceList, mWorkSpace?._id],
          workSpaceMap: {
            ...state.workSpaceMap,
            [mWorkSpace?._id]: mWorkSpace,
          },
          active: mWorkSpace?._id,
          adding: false,
          newlyCreated: mWorkSpace?._id,
        });
      } else {
        return state;
      }
    }

    case Actions.UPDATE_WORKSPACE_SUCCESS: {
      return Object.assign({}, state, {
        workSpaceMap: Object.assign({}, state?.workSpaceMap, {
          [action?.payload?._id]: action?.payload,
        }),
        loading: false,
      });
    }

    case Actions.GET_ALL_WORKSPACE_SUCCESS: {
      const allSpaces = action?.payload;

      return Object.assign({}, state, {
        workSpaceMap: Object.assign(
          {},
          state?.workSpaceMap,
          arrToObjMap(allSpaces)
        ),
        workSpaceList: [...new Set(getArrOrder(allSpaces))],
        active: state.active || getUserWorkspace?.[0] || null,
        loading: false,
      });
    }

    case Actions.GET_WORKSPACE_SUCCESS: {
      const space = action?.payload;
      return Object.assign({}, state, {
        workSpaceMap: Object.assign({}, state.workSpaceMap, {
          [space?._id]: space,
        }),
        active: space?._id,
      });
    }

    case Actions.SET_ACTIVE_WORKSPACE:
      return Object.assign({}, state, {
        active: action?.payload,
      });

    case Actions.ADD_WORKSPACE_FAIL: {
      return {
        ...state,
        adding: false,
        error: action.payload,
      };
    }
    case Actions.UPDATE_WORKSPACE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default workSpaceReducer;
