import { useCallback, useState } from "react";
import { Button, Input, Row, Col, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "./duck/LoginActions";
import { Link, Redirect, Route, useHistory } from "react-router-dom";
import {
  isUserAuthenticated,
  isValidPassword,
  validateEmail,
  emailAddress,
} from "../utils/CommonFunctions";
import "../assets/css/login.scss";
import loginSvg from "../assets/svg/logo_full.svg";
import { ArrowLeftOutlined } from "@ant-design/icons";
// import logo from "../assets/svg/logo.svg";
import Routes from "../config/Routes";
import AuthBgContainer from "./components/AuthBgContainer";

const LoginContainer = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [err, handleError] = useState({ email: null, password: null });

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { location } = useHistory();

  const loading = user?.loading;

  const login = useCallback(() => {
    if (validateEmail(email) && isValidPassword(password)) {
      dispatch(loginRequest({ email, password }));
    } else {
      let emailErr = null;
      let passErr = null;
      if (email?.trim() === "") {
        emailErr = "Email cannot be blank";
      } else if (!validateEmail(email)) {
        emailErr = "Email is not valid";
      }
      if (password?.trim() === "") {
        passErr = "Password cannot be blank";
      } else if (!isValidPassword(password)) {
        passErr = "Password length should be of minimum 4 characters";
      }
      handleError({ email: emailErr, password: passErr });
    }
  }, [email, password]);

  if (isUserAuthenticated()) {
    let pathname = location?.state?.from;
    if (!(user?.loggedInUser?.email === emailAddress)) {
      if (pathname) {
        return <Redirect to={pathname} />;
      } else if (localStorage.getItem("bookResource")) {
        return <Redirect to={`${Routes.payNow}/new_booking`} />;
      } else if (localStorage.getItem("cmsSidebar") == "true") {
        return <Redirect to={Routes.slider} />;
      }

      return <Redirect to={Routes.dashboard} />;
    } else {
      return <Redirect to={Routes.calendar} />;
    }
  }

  return (
    <AuthBgContainer
      title={"Welcome to Garden Office"}
      subtitle={"Sign-in to your account and book your awesome space!"}
    >
      {/* input email start */}
      <Col className="fw mb20">
        <Col className="label mb5">Email</Col>

        <Input
          placeholder="user@cockpit.com"
          size="large"
          allowClear
          onChange={(e) => {
            setEmail(e.target.value);
            handleError({ email: null, password: null });
          }}
          value={email}
          onPressEnter={login}
          autoFocus
        />

        <Col className="error mt5">{err.email}</Col>
      </Col>
      {/* input email end */}

      {/* input password start */}
      <Col className="mb20">
        <Col className="label mb5">Password</Col>

        <Input
          placeholder="Password"
          size="large"
          type="password"
          allowClear
          onChange={(e) => {
            setPassword(e.target.value);
            handleError({ email: null, password: null });
          }}
          value={password}
          onPressEnter={login}
        />
        <Col className="error mt5">{err.password}</Col>
      </Col>
      {/* input password end */}

      <Row justify="end" className="mb20">
        <Link
          className="btn-link forgot-password-link"
          to={Routes.forgotPassword}
          align="right"
        >
          Forgot Password?
        </Link>
      </Row>

      <Button
        className="mt20 mb20"
        type="primary"
        loading={loading}
        size="large"
        onClick={login}
        block
      >
        Sign In
      </Button>

      <Col className="mt20">
        <Row className="mb5" justify="center">
          <Typography.Text align="center">
            Don’t have an account?
          </Typography.Text>
        </Row>

        <Row justify="center">
          <Link className="btn-link create-account-link" to={Routes.signup}>
            <h2>Create an account</h2>
          </Link>
        </Row>
      </Col>
    </AuthBgContainer>
  );
};

export default LoginContainer;
