import React from "react";
import { Route, Switch } from "react-router-dom";
import LoginContainer from "../login/LoginContainer";
import SignupContainer from "../login/SignupContainer";
import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "../login/ForgotPassword";
import ResetPassword from "../login/ResetPassword";
import ActivationEmail from "../login/ActivationEmail";
import VerifyOtp from "../login/VerifyOtp";
import AppLayoutContainer from "../appLayout/AppLayoutContainer";
import Home from "../website/Home";
import Space from "../website/Space";
import SearchWorkspace from "../website/SearchWorkspace";
import PaymentDone from "../payNow/PaymentDone";
import Routes from "../config/Routes";
import PreBooking from "../payNow/PreBooking";

const AuthRouters = () => {
  return (
    <Switch>
      <Route path={Routes.login} component={LoginContainer} />
      <Route path={Routes.signup} component={SignupContainer} />
      <Route path={Routes.preBooking} component={PreBooking} />

      <Route path={Routes.forgotPassword} component={ForgotPassword} />
      <Route path={Routes.resetPassword} component={ResetPassword} />
      <Route path={Routes.accountActivate} component={ActivationEmail} />
      <Route path={Routes.verifyOtp} component={VerifyOtp} />
      <Route path={Routes.home} exact component={Home} />
      <PrivateRoute path={Routes.app} component={AppLayoutContainer} />
      <Route path={Routes.space + "/:spaceId"} component={Space} />
      <Route path={Routes.homeSearch} component={SearchWorkspace} />
      <Route path={Routes.paymentDone} component={PaymentDone} />
    </Switch>
  );
};

export default AuthRouters;
