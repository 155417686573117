import { Row, Col, Avatar, Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { getAvatarColor, getNameInitials } from "../../utils/CommonFunctions";

export const xeroColumns = (handleClick) => {
  const columns = [
    {
      title: "Invoice no",
      dataIndex: "sno",
      key: "sno",
      align: "center",
    },
    {
      title: "Ref",
      dataIndex: "ref",
      key: "ref",
      align: "center"
    },
    {
      title: "Paid by",
      dataIndex: "to",
      key: "to",
      align: "center"
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center"
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "center"
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      align: "center"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center"
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 130,
      align: "center",
      render: (id) => (
          <a href={id}>
            <Row align="middle" justify="center">
              <Tooltip title="Preview">
                <DownloadOutlined className="mr15" />
              </Tooltip>
            </Row>
          </a>
      ),
    },
  ];
  return columns;
};
