const Actions = {
  FETCH_ALL_MEMBERS: "FETCH_ALL_MEMBERS",
  FETCH_ALL_MEMBERS_SUCCESS: "FETCH_ALL_MEMBERS_SUCCESS",
  FETCH_ALL_MEMBERS_FAILURE: "FETCH_ALL_MEMBERS_FAILURE",

  ADD_MEMBER: "ADD_MEMBER",
  ADD_MEMBER_SUCCESS: "ADD_MEMBER_SUCCESS",
  ADD_MEMBER_FAILURE: "ADD_MEMBER_FAILURE",

  UPDATE_MEMBER: "UPDATE_MEMBER",
  UPDATE_MEMBER_SUCCESS: "UPDATE_MEMBER_SUCCESS",
  UPDATE_MEMBER_FAILURE: "UPDATE_MEMBER_FAILURE",
};

export default Actions;
