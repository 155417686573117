import { put, takeEvery, takeLatest, call } from "redux-saga/effects";
import { showNotification } from "../../utils/CommonFunctions";
import ACTIONS from "./CMSActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const getAllCMSRequest = (params = {}) => {
  return axios({
    method: "GET",
    url: AppUrl.DASHBOARD,
    params: params,
  });
};

function* getAllCMS(action) {
  try {
    let resources = yield call(getAllCMSRequest, action.payload);
    let _data = resources?.data;
    _data.isReset = !action.payload?.offset;

    delete action.payload?.offset;
    delete action.payload?.limit;
    _data.filter = JSON.stringify(action.payload);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.FETCH_ALL_CMS_FAILURE,
        payload: _data,
      });
    } else {
      yield put({
        type: ACTIONS.FETCH_ALL_CMS_SUCCESS,
        payload: _data,
      });
    }
  } catch (error) {
    console.log("There is an error in cms fetch", error);
  }
}

const createCMSRequest = (payload = {}) => {
  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    if (typeof payload[key] !== "undefined") {
      if (key === "files" && Array.isArray(payload[key])) {
        payload[key]?.forEach?.((file) => {
          formData.append("file", file);
        });
      } else if (Array.isArray(payload[key])) {
        payload[key]?.forEach?.((value) => formData.append(key, value));
      } else {
        formData.append(key, payload[key]);
      }
    }
  });

  return axios({
    method: "POST",
    url: AppUrl.DASHBOARD,
    data: formData,
  });
};

function* createCMS(action) {
  console.log("action payload", action);
  try {
    let resources = yield call(createCMSRequest, action.payload);
    let _data = resources?.data;

    const params = {
      type: action.payload?.type,
    };

    if (action.payload?.meta?.includes("page_name")) {
      const payloadMeta = JSON.parse(action.payload?.meta);
      params.page_name = payloadMeta?.page_name;
    }
    _data.filter = JSON.stringify(params);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.CREATE_CMS_FAILURE, payload: _data });
    } else {
      yield put({ type: ACTIONS.CREATE_CMS_SUCCESS, payload: _data });
    }
  } catch (error) {
    console.log("There is an error in cms creation", error);
  }
}

const updateCMSRequest = (payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    if (typeof payload[key] !== "undefined") {
      if (key === "files" && Array.isArray(payload[key])) {
        payload[key]?.forEach?.((file) => {
          formData.append("file", file);
        });
      } else if (Array.isArray(payload[key])) {
        payload[key]?.forEach?.((value) => formData.append(key, value));
      } else {
        formData.append(key, payload[key]);
      }
    }
  });

  return axios({
    method: "PUT",
    url: `${AppUrl.DASHBOARD}/${payload?._id}`,
    data: formData,
  });
};

function* updateCMS(action) {
  try {
    let response = yield call(updateCMSRequest, action?.payload);
    let _data = response?.data;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.UPDATE_CMS_FAILURE, payload: _data });
    } else {
      showNotification("success", "Successfully updated CMS details");
      yield put({ type: ACTIONS.UPDATE_CMS_SUCCESS, payload: _data });
    }
  } catch (error) {
    console.log("There is an error in cms updation", error);
  }
}

// ACTION WATCHER
export default function* cmsSaga() {
  yield takeEvery(ACTIONS.FETCH_ALL_CMS, getAllCMS);
  yield takeEvery(ACTIONS.CREATE_CMS, createCMS);
  yield takeLatest(ACTIONS.UPDATE_CMS, updateCMS);
}
