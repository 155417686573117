import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../assets/css/userdashboard.scss";
import {
  CalendarOutlined,
  CarryOutOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Row, Col, Card, Layout, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Spinner from "../spinner/Spinner";
import { checkPermission } from "../utils/CommonFunctions";
import Routes from "../config/Routes";
import { memberDashboardColumns } from "./components/MemberDashboardColumns";
import { getMemberDashboard } from "./duck/DashboardActions";
import NewBooking from "../booking/components/NewBooking";
import { getAllBookingResources } from "../booking/duck/BookingActions";
import Reports from "./components/Reports";
import { getAllReports } from "../reports/duck/ReportActions";
import MTable from "../utils/MTable";

const { Content } = Layout;

const MemberDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.loggedInUser);
  const activeWorkSpace = useSelector((state) => state?.workSpace?.active);
  const reports = useSelector((state) => state?.reports?.reports);
  let activitiesList = reports?.["{}"]?.list;

  const [showAddNewBooking, setShowAddNewBooking] = useState(false);
  const [selectedBooking, selectBooking] = useState(null);

  let isBookingEditable = checkPermission({
    row: "booking",
    subRow: "booking",
  });

  let calendarShow = checkPermission({
    row: "booking",
    subRow: "calendar",
    type: "show",
  });

  const { dashboards, loading } = useSelector((state) => state?.dashboard);
  const { booking, booking_count } = dashboards;
  const dashboardSpin = !Object.keys(dashboards).length && loading;

  useEffect(() => {
    dispatch(getMemberDashboard());
    dispatch(getAllBookingResources());
    dispatch(getAllReports({ limit: 20 }));
  }, []);

  const handleShowNewBooking = (show = false) => {
    show = typeof show === "boolean" && show;
    setShowAddNewBooking(show);
    if (!show) selectBooking(null);
  };

  const updateMeeting = (e) => {
    selectBooking(e);
    handleShowNewBooking(true);
  };

  const data = [];
  booking &&
    booking.forEach((mBooking) => {
      const today = moment();
      let status = "Upcoming";

      if (
        mBooking?.active &&
        !(
          today.isBetween(moment(mBooking?.from), moment(mBooking?.to)) ||
          moment(mBooking?.from).isBefore(today, "time")
        )
      ) {
        data.push({
          key: mBooking?._id,
          location: mBooking?.space_id?.name,
          address: mBooking?.space_id?.area,
          date: mBooking?.from,
          time: mBooking?.from,
          price: mBooking?.price?.total || mBooking?.price?.net,
          action: mBooking,
          status,
        });
      }
    });

  return dashboardSpin ? (
    <Spinner />
  ) : (
    <>
      <Row className="nw_30 nw_align_center">
        <Col flex="auto">
          <div className="x">
            <h2 className="nw_heading">Dashboard</h2>
            <p className="nw_para">Welcome to {user?.name}</p>
          </div>
        </Col>
      </Row>

      <Layout>
        <Content flex="auto">
          <Row gutter={16}>
            <Col span={8} xs={24} md={12} lg={8} className="widget-stat">
              <Card
                bordered={false}
                style={{
                  width: "100%",
                  margin: "0 20px 10px 0",
                  borderRadius: 4,
                }}
                hoverable
              >
                <Row justify="space-between" align="middle">
                  <span>
                    <div className="card-heading">Check-in Time</div>
                    <div className="card-value">00:00 hrs</div>
                  </span>
                  <span>
                    <div
                      className="--avatar bgl-primary bg_3"
                      style={{ height: 50, width: 50, fontSize: 20 }}
                    >
                      <CalendarOutlined />
                    </div>
                  </span>
                </Row>
              </Card>
            </Col>

            <Col span={8} xs={24} md={12} lg={8} className="widget-stat">
              <Card
                bordered={false}
                style={{
                  width: "100%",
                  margin: "0 20px 10px 0",
                  borderRadius: 4,
                }}
                hoverable
              >
                <Row justify="space-between" align="middle">
                  <span>
                    <div className="card-heading">Total Booking</div>
                    <div className="card-value">{booking_count}</div>
                  </span>
                  <span>
                    <div
                      className="--avatar bgl-primary bg_1"
                      style={{ height: 50, width: 50, fontSize: 20 }}
                    >
                      <CarryOutOutlined />
                    </div>
                  </span>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Card
                bordered={false}
                style={{ width: "100%", overflow: "auto", borderRadius: 4 }}
                className="nw_table_container"
              /* hoverable */
              >
                <Row justify="space-between" className="card-heading">
                  <span className="table-heading">Upcoming Bookings</span>

                  {calendarShow && (
                    <Button
                      type="link"
                      onClick={() => history.push(Routes.calendar)}
                    >
                      Go to calendar
                    </Button>
                  )}
                </Row>

                <MTable
                  className="mt20 fw nw_table_container_box"
                  columns={memberDashboardColumns(updateMeeting)}
                  dataSource={data}
                />
              </Card>
            </Col>
          </Row>

          <Row gutter={16} className="cardHover">
            <Col span={24}>
              <Card
                style={{
                  width: "100%",
                  margin: "20px 20px 10px 0",
                  borderRadius: 4,
                }}
                hoverable
              >
                <Row className="card-heading table-heading" align="top">
                  Activity Feed
                </Row>
                <div className="nw_report">
                  <Reports activitiesList={activitiesList} />
                </div>
              </Card>
            </Col>
          </Row>
        </Content>

        <NewBooking
          visible={showAddNewBooking}
          bookingId={selectedBooking?._id}
          handleModal={handleShowNewBooking}
          selectedBooking={selectedBooking}
          selectBooking={selectBooking}
          editable={isBookingEditable}
        />
      </Layout>
    </>
  );
};

export default MemberDashboard;
