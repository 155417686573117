import { Row, Table } from "antd";
import Spinner from "../spinner/Spinner";
import { useEffect } from "react";
import { fetchAllUserActivity } from "./duck/UserActivityActions";
import { useSelector, useDispatch } from "react-redux";
import { userActivityColumns } from "./component/userActivityColumns";
import MTable from "../utils/MTable";

const UserActivity = () => {
  const dispatch = useDispatch();
  const activeWorkspace = useSelector((state) => state?.workSpace?.active);
  const userActivity = useSelector((state) => state?.userActivity);
  let data = userActivity?.userActivity?.[activeWorkspace]?.map((obj) => ({
    ...obj,
    key: obj?._id,
  }));

  let isSpin = !data && userActivity?.loading;

  useEffect(() => {
    !data &&
      activeWorkspace &&
      dispatch(fetchAllUserActivity({ space_id: activeWorkspace }));
  }, [activeWorkspace]);

  return isSpin ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">User Activity</span>
      </Row>
      <MTable
        columns={userActivityColumns()}
        dataSource={data}
        className="mt20"
        bordered={false}
      />
    </div>
  );
};

export default UserActivity;
