import { put, takeLatest, call } from "redux-saga/effects";
import { showNotification } from "../../utils/CommonFunctions";
import ACTIONS from "./PlanActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const getAllPlansRequest = (params) => {
  return axios({
    method: "GET",
    url: AppUrl.PLANS,
    params: params,
  });
};

function* getAllPlans(action) {
  try {
    let response = yield call(getAllPlansRequest, action.payload);
    let _data = response?.data;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.FETCH_ALL_PLANS_FAILURE,
        payload: _data?.meta,
      });
    } else {
      yield put({
        type: ACTIONS.FETCH_ALL_PLANS_SUCCESS,
        payload: {
          data: _data?.data,
          space_id: action.payload?.space_id,
          resource_type: action.payload?.resource_type,
        },
      });
    }
  } catch (error) {
    console.log("fetch all plans error", error);
  }
}

const getPlanRequest = (id) => {
  return axios({
    method: "GET",
    url: `${AppUrl.PLANS}/${id}`,
  });
};

function* getPlan(action) {
  try {
    let response = yield call(getPlanRequest, action.payload);
    let _data = response?.data;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.FETCH_PLAN_FAILURE, payload: _data?.meta });
    } else {
      yield put({ type: ACTIONS.FETCH_PLAN_SUCCESS, payload: _data?.data });
    }
  } catch (error) {
    console.log("fetch all plans error", error);
  }
}

const addNewPlanRequest = (data) => {
  return axios({
    method: "POST",
    url: AppUrl.PLANS,
    data: data,
  });
};

function* addNewPlan(action) {
  try {
    let response = yield call(addNewPlanRequest, action.payload);
    let _data = response?.data;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.ADD_PLAN_FAILURE, payload: _data?.meta });
    } else {
      showNotification("success", "Successfully add new plan");
      yield put({ type: ACTIONS.ADD_PLAN_SUCCESS, payload: _data?.data });
    }
  } catch (error) {
    console.log("add new plan error", error);
  }
}

const updatePlanRequest = (data) => {
  return axios({
    method: "PUT",
    url: `${AppUrl.PLANS}/${data?._id}`,
    data: data,
  });
};

function* updatePlan(action) {
  try {
    let response = yield call(updatePlanRequest, action.payload);
    let _data = response?.data;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.UPDATE_PLAN_FAILURE, payload: _data?.meta });
    } else {
      showNotification("success", "Successfully update new plan");
      yield put({ type: ACTIONS.UPDATE_PLAN_SUCCESS, payload: _data?.data });
    }
  } catch (error) {
    console.log("fetch all plans error", error);
  }
}

// ACTION WATCHER
export default function* plansSaga() {
  yield takeLatest(ACTIONS.FETCH_ALL_PLANS, getAllPlans);
  yield takeLatest(ACTIONS.FETCH_PLAN, getPlan);
  yield takeLatest(ACTIONS.ADD_PLAN, addNewPlan);
  yield takeLatest(ACTIONS.UPDATE_PLAN, updatePlan);
}
