import React, { useState } from "react";
import { Layout } from "antd";
import "../css/main.scss";
import "../css/home.scss";

import Headermain from "./Header/Header";
import Footermain from "./Footer/Footermain";

import AddContact from "../../contacts/component/AddContact";

const { Content } = Layout;

const WebLayoutContainer = ({ children, showFooter = true }) => {
  const [state, setState] = useState({});

  const handleContactUs = (show = false) => {
    show = typeof show === "boolean" && show;
    setState({ showContactUs: show });
  };

  return (
    <Layout className="cockpit_web">
      <Headermain onClickContactUs={() => handleContactUs(true)} />

      <Content>
        {children}

        <AddContact
          visible={state.showContactUs}
          handleModal={handleContactUs}
        />
      </Content>

      {/* {showFooter && <Footermain />} */}
    </Layout>
  );
};

export default WebLayoutContainer;
