const {
  REACT_APP_BASE_URL,
  REACT_APP_WEBSITE_URL,
  REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  REACT_APP_STRIPE_SECRET_KEY,
  REACT_APP_STRIPE_WEBHOOK_SECRET,
  REACT_APP_GOOGLE_CALENDAR_CLIENT_ID,
} = process.env;

const BASE_URL = REACT_APP_BASE_URL;
const NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY =
  REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY;
const STRIPE_SECRET_KEY = REACT_APP_STRIPE_SECRET_KEY;
const GOOGLE_CALENDAR_CLIENT_ID = REACT_APP_GOOGLE_CALENDAR_CLIENT_ID;
const STRIPE_WEBHOOK_SECRET = REACT_APP_STRIPE_WEBHOOK_SECRET;

const API_BASE_URL = `${BASE_URL}/api/v1`;

const AppUrl = {
  SOCKET_URL: BASE_URL,
  WEBSITE_URL: REACT_APP_WEBSITE_URL,
  AUTH_URL: API_BASE_URL + "/auth",
  LOGIN_URL: API_BASE_URL + "/auth/login",
  SIGN_UP_URL: API_BASE_URL + "/auth/signup",
  SET_TOKEN: API_BASE_URL + "/google-calendar/update-token/",
  VERIFY_EMAIL: API_BASE_URL + "/auth/verify-account",
  VERIFY_OTP: API_BASE_URL + "/auth/verify-otp",
  RESEND_OTP: API_BASE_URL + "/auth/resend-otp",
  FORGOT_PASSWORD: API_BASE_URL + "/auth/reset-password",
  RESET_PASSWORD: API_BASE_URL + "/auth/set-password",
  USERS_URL: API_BASE_URL + "/users",
  ROLES_URL: API_BASE_URL + "/roles",
  REPORTS: API_BASE_URL + "/activities",
  WORKSPACE: API_BASE_URL + "/space",
  GETKISI: API_BASE_URL + "/getkisi",
  PLANS: API_BASE_URL + "/plans",
  CREATE_WORKSPACE: API_BASE_URL + "/space/add-space",
  CREATE_MEETING: API_BASE_URL + "/resources/meeting",
  RESOURCES: API_BASE_URL + "/resources",
  RESOURCE_ITEMS: API_BASE_URL + "/resources/items",
  BOOKING: API_BASE_URL + "/bookings",
  RESCHEDULE_BOOKING: API_BASE_URL + "/bookings/reschedule-request",
  BOOKING_TOUR: API_BASE_URL + "/booking-tour",
  BOOKING_RESOURCE_WITHOUT_PAY: API_BASE_URL + "/bookings/resource-without-pay",
  LEADS: API_BASE_URL + "/facebook/leads",
  CHANGE_BOOKED_RESOURCE: API_BASE_URL + "/bookings/raised-request",
  CHANGE_BOOKED_RESOURCE_APPROVE: API_BASE_URL + "/bookings/raised-approve",
  CHANGE_BOOKING_REQUEST: API_BASE_URL + "/bookings/reserve-resource",
  BOOKING_REMARK: API_BASE_URL + "/bookings/remark-request",
  INVOICE: API_BASE_URL + "/invoice",
  XERO: API_BASE_URL + "/xero",
  AMENITIES: API_BASE_URL + "/amenities",
  ATTACHMENTS: API_BASE_URL + "/attachments",
  STRIPE_PAYMENT: API_BASE_URL + "/create-checkout-session",
  ATTACH_PAYMENT: API_BASE_URL + "/stripe/attachPayment",
  PAYMENT_SOURCE: API_BASE_URL + "/stripe/payment-source",
  CHARGE_SOURCE: API_BASE_URL + "/stripe/payCharge",
  CHANGE_DEFAULT_PAYMENT: API_BASE_URL + "/stripe/change-default-payment",
  BILLING_DETAILS: API_BASE_URL + "/stripe/billingDetails",
  STRIPE_PAYMENT_DONE: API_BASE_URL + "/stripe/success",
  MAIL_TEMPLATE: API_BASE_URL + "/mailchimp/template/list",
  SEND_CAMPAIGN_MAIL: API_BASE_URL + "/mailchimp/campaign/send",
  DASHBOARD: API_BASE_URL + "/dashboard",
  STRIPE_SECRET_KEY: STRIPE_SECRET_KEY,
  STRIPE_PUBLISHABLE_KEY: NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  STRIPE_WEBHOOK_SECRET: STRIPE_WEBHOOK_SECRET,
  GOOGLE_CALENDAR_CLIENT_ID: GOOGLE_CALENDAR_CLIENT_ID,
};

export default AppUrl;
