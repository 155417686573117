import { memo } from "react";
import { Drawer, Row } from "antd";
import { useLocation } from "react-router-dom";

import CmsSideMenu from "./CmsSideMenu";
import AppSideMenu from "./AppSideMenu";

// import logo from "../../assets/svg/logo.svg";
import logoFull from "../../assets/svg/logo_full.svg";

import Routes from "../../config/Routes";

const AppDrawer = (props) => {
  const location = useLocation();
  const _key = [
    Routes.meetingRooms,
    Routes.privateOffices,
    Routes.desk,
  ].includes(location.pathname);
  const _key1 = [Routes.calendar, Routes.booking].includes(location.pathname);

  return (
    <Drawer
      className="app-drawer"
      onClose={props.toggleDrawer}
      visible={props.isDrawerOpen}
      placement="left"
      title={
        <Row align="middle" className="logo-container pl15 pt5 pb5">
          <img className="logo" alt="gobermondsey" src={logoFull} />
        </Row>
      }
    >
      {props.showCmsSidebarMenu ? (
        <CmsSideMenu
          isCollapsed={props?.collapsed}
          selectedKeys={[location.pathname]}
          toggleDrawer={props.toggleDrawer}
        />
      ) : (
        <AppSideMenu
          isCollapsed={props?.collapsed}
          _key={_key}
          _key1={_key1}
          selectedKeys={[location.pathname]}
          toggleDrawer={props.toggleDrawer}
        />
      )}
    </Drawer>
  );
};

export default memo(AppDrawer);
