import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Progress } from "antd";
import {
  CalendarOutlined,
  PoundOutlined,
  UserOutlined,
  HddOutlined,
} from "@ant-design/icons";
import { checkPermission, resourceTypes } from "../utils/CommonFunctions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router";
import { Bar } from "react-chartjs-2";
import Spinner from "../spinner/Spinner";
import Routes from "../config/Routes";
import { dashboardColumns, options } from "./components/DashboardColumns";
import { getAllBookingResources } from "../booking/duck/BookingActions";
import Reports from "./components/Reports";
import { getAllReports } from "../reports/duck/ReportActions";
import MTable from "../utils/MTable";

const Dashboard = () => {
  const [boardKey, setBoardKey] = useState("{}");

  const history = useHistory();
  const dispatch = useDispatch();
  let calendarShow = checkPermission({
    row: "booking",
    subRow: "calendar",
    type: "show",
  });

  const activeWorkSpace = useSelector((state) => state?.workSpace?.active);
  const resources = useSelector((state) => state?.resources);
  const booking = useSelector((state) => state?.booking);
  const reports = useSelector((state) => state?.reports?.reports);

  let activitiesList =
    reports?.[JSON.stringify({ space_id: activeWorkSpace })]?.list;

  const mBookingBoard = booking.bookings?.[boardKey] ?? {};
  const bookings = mBookingBoard?.list;

  let allDashboard = useSelector((state) => state?.dashboard);

  let dashboard = allDashboard?.dashboards?.[activeWorkSpace];

  useEffect(() => {
    if (activeWorkSpace) {
      const params = {
        space_id: activeWorkSpace,
        booking_status: "upcoming",
        payment_status: "completed",
        page: "dashboard",
      };

      setBoardKey(JSON.stringify({ ...params }));

      params.limit = 10;
      dispatch(getAllBookingResources(params));
      dispatch(
        getAllReports({
          space_id: activeWorkSpace,
          limit: 20,
        })
      );
    }
  }, [activeWorkSpace]);

  const dashboardSpin =
    !Object.keys(allDashboard.dashboards).length && allDashboard?.loading;

  const data = [];
  bookings &&
    bookings.forEach((id, index) => {
      const mBooking = booking?.bookingMap?.[id];
      const startDateTime = new Date(mBooking?.from).getTime();
      const endDateTime = new Date(mBooking?.to).getTime();
      const diffInTime = (endDateTime - startDateTime) / (1000 * 3600);
      const today = moment();
      let status = "Upcoming";
      let resourceType = mBooking?.type;

      if (today.isBetween(moment(mBooking?.from), moment(mBooking?.to))) {
        status = "Ongoing";
      } else if (moment(mBooking?.from).isBefore(today, "time")) {
        status = "Past";
      }

      let resource = [];
      resource = [
        mBooking?.items?.map?.((item) => item?.seat_no).join?.() ||
        mBooking?.resource_id?.name,
        mBooking?.resource_id?.color,
        mBooking?.title || resourceTypes?.[mBooking?.type]?.name,
      ];

      if (mBooking?.active && status !== "Past") {
        data.push({
          key: id,
          serial: ++index,
          resource: resource,
          date: [mBooking?.from, mBooking?.to],
          price: [mBooking?.price?.total || mBooking?.price?.net, diffInTime],
          members: mBooking?.members,
          action: mBooking,
          status,
        });
      }
    });

  let totalDesk = { dedicated_desk: 0, flexi_desk: 0 };
  Object.keys(resources?.deskItemsMap).forEach((id) => {
    // if(resources?.deskItemsMap?.[id]?.space_id === activeWorkSpace){
    if (resources?.deskItemsMap?.[id]?.desk_type === "dedicated_desk") {
      totalDesk["dedicated_desk"] += 1;
    }
    if (resources?.deskItemsMap?.[id]?.desk_type === "flexi_desk") {
      totalDesk["flexi_desk"] += 1;
    }
    // }
  });

  if (!dashboard) {
    dashboard = {};
  }

  return dashboardSpin ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Dashboard</span>
      </Row>

      <div>
        <Row>
          <Col span={17}>
            <Row>
              <Col span={8}>
                <Card
                  bordered={false}
                  style={{
                    width: "95%",
                    margin: "0 20px 10px 0",
                    borderRadius: 4,
                  }}
                  hoverable
                >
                  <Row justify="space-between" align="middle">
                    <span>
                      <div style={{ fontSize: 18, fontWeight: 500 }}>
                        Total Booking
                      </div>
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "#28c76f",
                        }}
                      >
                        {dashboard?.total_booking_count || 0}
                      </div>
                    </span>
                    <span>
                      <div
                        className="--avatar green circle"
                        style={{ height: 50, width: 50, fontSize: 20 }}
                      >
                        <CalendarOutlined />
                      </div>
                    </span>
                  </Row>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  bordered={false}
                  style={{
                    width: "95%",
                    margin: "0 20px 10px 0",
                    borderRadius: 4,
                  }}
                  hoverable
                >
                  <Row justify="space-between" align="middle">
                    <span>
                      <div style={{ fontSize: 18, fontWeight: 500 }}>
                        Revenue
                      </div>
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "#ff9f43",
                        }}
                      >
                        £{parseFloat(dashboard.booking_revenue || 0).toFixed(2)}
                      </div>
                    </span>
                    <span>
                      <div
                        className="--avatar orange circle"
                        style={{ height: 50, width: 50, fontSize: 20 }}
                      >
                        <PoundOutlined />
                      </div>
                    </span>
                  </Row>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  bordered={false}
                  style={{
                    width: "95%",
                    margin: "0 20px 10px 0",
                    borderRadius: 4,
                  }}
                  hoverable
                >
                  <Row justify="space-between" align="middle">
                    <span>
                      <div style={{ fontSize: 18, fontWeight: 500 }}>
                        Active Members
                      </div>
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "#7367f0",
                        }}
                      >
                        {dashboard?.active_members_count || 0}
                      </div>
                    </span>
                    <span>
                      <div
                        className="--avatar purple circle"
                        style={{ height: 50, width: 50, fontSize: 20 }}
                      >
                        <UserOutlined />
                      </div>
                    </span>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row className="mt5">
              <Col span={24}>
                <Card
                  bordered={false}
                  style={{
                    width: "calc(100% - 15px)",
                    height: 538,
                    overflow: "auto",
                    margin: "0 20px 10px 0",
                    borderRadius: 4,
                  }}
                  hoverable
                  className="mainDashTable"
                >
                  <Row
                    justify="space-between"
                    style={{ fontSize: 18, fontWeight: 500 }}
                  >
                    Booking Details
                    {calendarShow && (
                      <Button
                        style={{ fontWeight: 500 }}
                        type="link"
                        onClick={() => history.push(Routes.calendar)}
                      >
                        Go to calendar
                      </Button>
                    )}
                  </Row>
                  <MTable
                    columns={dashboardColumns()}
                    dataSource={data}
                    className="mt20 fw"
                    bordered={false}
                    pagination={false}
                    size="middle"
                  />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={7}>
            <Col span={24}>
              <Card
                bordered={false}
                style={{ width: "99%", borderRadius: 4 }}
                hoverable
                className="custom-card"
              >
                <Row
                  style={{ fontSize: 18, fontWeight: 500 }}
                  justify="space-between"
                  align="top"
                >
                  Resources Breakup
                  <div
                    className="--avatar green circle"
                    style={{ height: 50, width: 50, fontSize: 20 }}
                  >
                    <HddOutlined />
                  </div>
                </Row>
                <Row justify="space-between" className="mt15 pt10 pb10">
                  <span style={{ fontSize: 17, fontWeight: 400 }}>
                    Meeting Rooms
                  </span>
                  <span style={{ fontSize: 17, fontWeight: 400 }}>
                    {dashboard?.meeting_room_count || 0}
                  </span>
                </Row>
                <Row justify="space-between" className="mt15 pt10 pb10">
                  <span style={{ fontSize: 17, fontWeight: 400 }}>
                    Private Offices
                  </span>
                  <span style={{ fontSize: 17, fontWeight: 400 }}>
                    {dashboard?.private_office_count || 0}
                  </span>
                </Row>
                <Row justify="space-between" className="mt15 pt10 pb10">
                  <span style={{ fontSize: 17, fontWeight: 400 }}>
                    Dedicated Desks
                  </span>
                  <span style={{ fontSize: 17, fontWeight: 400 }}>
                    {(dashboard.desks && dashboard.desks?.dedicated_desk) || 0}
                  </span>
                </Row>
                <Row justify="space-between" className="mt15 pt10 pb10">
                  <span style={{ fontSize: 17, fontWeight: 400 }}>
                    Flexi Desks
                  </span>
                  <span style={{ fontSize: 17, fontWeight: 400 }}>
                    {(dashboard.desks && dashboard.desks.flexi_desk) || 0}
                  </span>
                </Row>
              </Card>
            </Col>

            <Col span={24}>
              <Card
                bordered={false}
                style={{
                  width: "99%",
                  borderRadius: 4,
                  marginTop: 5,
                  marginBottom: 40,
                }}
                hoverable
              >
                <Col
                  style={{ fontSize: 18, fontWeight: 500 }}
                  className="pb10"
                  align="top"
                >
                  <Row>
                    <span style={{ fontWeight: "bold" }}>TODAYS OCCUPANCY</span>
                  </Row>

                  <span style={{ fontSize: 17 }}>
                    Average Resources Occupancy
                  </span>
                </Col>

                <Col
                  justify="space-between"
                  className="pb10"
                  style={{ width: "85%" }}
                >
                  <Row>
                    <span style={{ fontSize: 17, fontWeight: 400 }}>
                      Private Office
                    </span>
                  </Row>

                  <span style={{ fontSize: 17, fontWeight: 400 }}>
                    <Progress
                      strokeLinecap="square"
                      strokeColor="#333"
                      trailColor="#aaa"
                      percent={parseFloat(
                        dashboard?.occupancy?.private_office || 0
                      ).toFixed(2)}
                      status="normal"
                    />
                  </span>
                </Col>

                <Col
                  justify="space-between"
                  className="pb10"
                  style={{ width: "85%" }}
                >
                  <Row>
                    <span style={{ fontSize: 17, fontWeight: 400 }}>
                      Flexi Desk
                    </span>
                  </Row>
                  <span style={{ fontSize: 17, fontWeight: 400 }}>
                    <Progress
                      strokeLinecap="square"
                      strokeColor="#333"
                      trailColor="#aaa"
                      percent={parseFloat(
                        dashboard?.occupancy?.flexi_desk || 0
                      ).toFixed(2)}
                      status="normal"
                    />
                  </span>
                </Col>

                <Col
                  justify="space-between"
                  className="pb10"
                  style={{ width: "85%" }}
                >
                  <Row>
                    <span style={{ fontSize: 17, fontWeight: 400 }}>
                      Dedicated Desk
                    </span>
                  </Row>
                  <span style={{ fontSize: 17, fontWeight: 400 }}>
                    <Progress
                      strokeLinecap="square"
                      strokeColor="#333"
                      trailColor="#aaa"
                      percent={parseFloat(
                        dashboard?.occupancy?.dedicated_desk || 0
                      ).toFixed(2)}
                      status="normal"
                    />
                  </span>
                </Col>
              </Card>
            </Col>
          </Col>
        </Row>
      </div>
      <Row style={{ position: "relative", top: "-25px" }}>
        <Col span={12}>
          <Card style={{ width: "98%", borderRadius: 4 }} hoverable>
            <span style={{ fontSize: 17, fontWeight: 400 }}>
              Dedicated Desk Weekly Occupancy
            </span>
            <Row className="mt20">
              <Bar
                data={{
                  labels: dashboard?.occupancy_breakup?.dedicated_desk?.date.map(o => moment(o).utc().format("Do MMM' YY")) || [],
                  datasets: [
                    {
                      label: "Booked",
                      data:
                        dashboard?.occupancy_breakup?.dedicated_desk?.booked ||
                        [],
                      backgroundColor: "#333",
                    },
                    {
                      label: "Available",
                      data: dashboard?.occupancy_breakup?.dedicated_desk?.total?.map(
                        (t, i) =>
                          t -
                          (dashboard?.occupancy_breakup?.dedicated_desk
                            ?.booked?.[i] || 0)
                      ),
                      backgroundColor: "#aaa",
                    },
                  ],
                }}
                options={options}
              />
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card style={{ width: "100%", borderRadius: 4 }} hoverable>
            <span style={{ fontSize: 17, fontWeight: 400 }}>
              Flexible Desk Weekly Occupancy
            </span>
            <Row className="mt20">
              <Bar
                data={{
                  labels: dashboard?.occupancy_breakup?.dedicated_desk?.date.map(o => moment(o).utc().format("Do MMM' YY")) || [],

                  datasets: [
                    {
                      label: "Booked",
                      data:
                        dashboard?.occupancy_breakup?.flexi_desk?.booked || [],
                      backgroundColor: "#333",
                    },
                    {
                      label: "Available",
                      data: dashboard?.occupancy_breakup?.flexi_desk?.total?.map(
                        (t, i) =>
                          t -
                          (dashboard?.occupancy_breakup?.flexi_desk?.booked?.[
                            i
                          ] || 0)
                      ),
                      backgroundColor: "#aaa",
                    },
                  ],
                }}
                options={options}
              />
            </Row>
          </Card>
        </Col>
      </Row>
      <Row style={{ position: "relative", top: "-20px" }}>
        <Col span={24}>
          <Card style={{ borderRadius: 4 }} hoverable>
            <Row style={{ fontSize: 18, fontWeight: 500 }} align="top">
              Activity Feed
            </Row>

            <Reports activitiesList={activitiesList} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
