import React, { useCallback, useMemo, useState } from "react";
import { Badge, Button, Card, Col, Drawer, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { SendOutlined } from "@ant-design/icons";
import axios from "axios";

import NoDataFound from "../../utils/NoDataFound";

import AppUrl from "../../config/AppUrl";
import { resourceTypes, showNotification } from "../../utils/CommonFunctions";
import BookingActionTypes from "../duck/BookingActionTypes";

const BookingHistoryDrawer = ({
  bookingId = null,
  isOpen = false,
  onClose = () => {},
}) => {
  const dispatch = useDispatch();
  const mBooking = useSelector(
    ({ booking }) => booking.bookingMap?.[bookingId]
  );

  const [state, setState] = useState({
    message: "",
    loading: false,
  });

  const bookingHistoryList = useMemo(() => {
    const mHistory = [];

    if (isOpen) {
      let historyOrder = Object.keys(mBooking?.meta || {});
      historyOrder.sort((a, b) => b - a);
      historyOrder.forEach((id) => {
        mHistory.push({
          ...mBooking?.meta?.[id],
          _id: id,
          modified_at: moment(Number(id)),
        });
      });
    }

    return mHistory;
  }, [mBooking, isOpen]);

  const handleChange = useCallback((event) => {
    let { value, name } = event.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const onCreateRemark = async () => {
    try {
      let { message } = state;
      message = message.trim();

      if (message) {
        setState((prevState) => ({
          ...prevState,
          loading: true,
        }));

        const payload = {
          remark: message,
        };
        const booking = await axios({
          method: "PUT",
          url: `${AppUrl.BOOKING_REMARK}/${bookingId}`,
          data: payload,
        });
        const _data = booking.data;

        if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
          showNotification("error", _data?.meta?.message);
        } else {
          dispatch({
            type: BookingActionTypes.UPDATE_BOOKING_SUCCESS,
            payload: _data,
          });
        }
      }
    } catch (err) {
      console.log("on create remark ", err);
      showNotification("error", "Something went wrong.");
    } finally {
      setState((prevState) => ({
        ...prevState,
        message: "",
        loading: false,
      }));
    }
  };

  return (
    <Drawer
      className="booking-history-drawer"
      title="Booking History"
      footer={
        <Row className="fw" align="bottom" justify="space-between">
          <Col span={22} lg={20} xl={21}>
            <Input.TextArea
              className="custom-input"
              placeholder="Type a message"
              name="message"
              value={state.message}
              onChange={handleChange}
              onPressEnter={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  onCreateRemark();
                }
              }}
              autoSize={{ minRows: 1, maxRows: 5 }}
              disabled={state.loading}
              size="large"
            />
          </Col>

          <Col span={2} lg={3} xl={2}>
            <Button
              type="primary-outline"
              icon={<SendOutlined />}
              onClick={onCreateRemark}
              loading={state.loading}
              disabled={state.loading || !state.message}
              size="large"
            />
          </Col>
        </Row>
      }
      visible={isOpen}
      onClose={onClose}
      width="30%"
      placement="right"
    >
      {!bookingHistoryList.length ? (
        <Row className="h-full" align="middle" justify="center">
          <NoDataFound />
        </Row>
      ) : (
        <Col>
          {bookingHistoryList.map(
            (
              {
                type,
                from,
                to,
                resource_id,
                reschedule_reason,
                remark,
                modified_by,
                modified_at,
              },
              i
            ) => (
              <Badge.Ribbon
                key={i}
                text={remark ? "Manual note" : "Edits"}
                color={remark ? "cyan" : "purple"}
              >
                <Card className="booking-history-card mb15">
                  {!remark && (
                    <Row>
                      <Col className="label mr5">Resource Type:</Col>
                      <Col className="value">
                        {resourceTypes?.[type]?.name || "-"}
                      </Col>
                    </Row>
                  )}

                  {!remark && (
                    <Row>
                      <Col className="label mr5">Space:</Col>
                      <Col className="value">{resource_id?.name || "-"}</Col>
                    </Row>
                  )}

                  {!remark && (
                    <Row>
                      <Col className="label mr5">Date:</Col>
                      <Col className="value">
                        {moment(from).format("DD-MM-YYYY")}
                      </Col>
                    </Row>
                  )}

                  {!remark && (
                    <Row>
                      <Col className="label mr5">Time:</Col>
                      <Col className="value">
                        {moment(from).format("HH:mm")} -{" "}
                        {moment(to).format("HH:mm")} (
                        {moment
                          .duration(moment(to).diff(moment(from)))
                          .asHours()}{" "}
                        Hr)
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col className="label mr5">
                      Edited:
                      {/* {remark ? "Remark" : "Edited"}: */}
                    </Col>
                    <Col className="value">{reschedule_reason || remark}</Col>
                  </Row>

                  <Row>
                    <Col className="label mr5">
                      {/* {remark ? "Remark by" : "Last updated by"}: */}
                      Last updated by:
                    </Col>
                    <Col className="value">
                      {modified_by?.name && modified_by?.email ? (
                        <span>
                          {modified_by?.name} ({modified_by?.email})
                        </span>
                      ) : (
                        modified_by?.name || modified_by?.email
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col className="label mr5">
                      {/* {remark ? "Remark at" : "Last updated at"}: */}
                      Last updated at:
                    </Col>
                    <Col className="value">
                      {moment(modified_at).format("DD-MM-YYYY HH:mm")}
                    </Col>
                  </Row>
                </Card>
              </Badge.Ribbon>
            )
          )}
        </Col>
      )}
    </Drawer>
  );
};

export default React.memo(BookingHistoryDrawer);
