import Actions from "./MemberActionTypes";

export const getAllMembers = (params = {}) => {
  return {
    type: Actions.FETCH_ALL_MEMBERS,
    payload: params,
  };
};

export const addMember = (params = {}) => {
  return {
    type: Actions.ADD_MEMBER,
    payload: params,
  };
};

export const editMember = (params = {}) => {
  return {
    type: Actions.UPDATE_MEMBER,
    payload: params,
  };
};
