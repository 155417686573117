import { Col, Tag, Typography } from "antd";
import moment from "moment";
const { Paragraph } = Typography;

export const doorColumns = () => {
  const columns = [
    {
      title: "S.no",
      dataIndex: "id",
      key: "id",
      width: 60,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, obj) => {
        return (
          <Col style={{ fontWeight: 400 }}>
            <p style={{ fontWeight: 500, margin: 0 }}>{text}</p>
            <p style={{ margin: 0 }}>
              <Paragraph ellipsis={{ rows: 2 }}>{obj.description}</Paragraph>
            </p>
          </Col>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: 130,
      render: (text) => <Col>{moment(text).format("DD-MMM-YYYY HH:MM A")}</Col>,
    },
    {
      title: "Modified At",
      dataIndex: "modified_at",
      key: "modified_at",
      width: 130,

      render: (text) => <Col>{moment(text).format("DD-MMM-YYYY HH:MM A")}</Col>,
    },
    {
      title: "Status",
      dataIndex: "online",
      key: "status",
      width: 100,
      render: (text) => (
        <Tag color={text ? "green" : "red"}>{text ? "Online" : "Offline"}</Tag>
      ),
    },
  ];
  return columns;
};
