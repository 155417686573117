const Actions = {
    FETCH_ALL_MAIL_TEMPLATE: 'FETCH_ALL_MAIL_TEMPLATE',
    FETCH_ALL_MAIL_TEMPLATE_SUCCESS: 'FETCH_ALL_MAIL_TEMPLATE_SUCCESS',
    FETCH_ALL_MAIL_TEMPLATE_FAILURE: 'FETCH_ALL_MAIL_TEMPLATE_FAILURE',

    SEND_CAMPAIGN_MAIL: 'SEND_CAMPAIGN_MAIL',
    SEND_CAMPAIGN_MAIL_SUCCESS: 'SEND_CAMPAIGN_MAIL_SUCCESS',
    SEND_CAMPAIGN_MAIL_FAILURE: 'SEND_CAMPAIGN_MAIL_FAILURE',
}

export default Actions;