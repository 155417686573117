import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Space,
  TimePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import CustomModal from "../../utils/CustomModal";

import { disabledDate, resourceTypes } from "../../utils/CommonFunctions";
import { adminAcknowledgeContact } from "../duck/ContactsActions";
import constants from "../../utils/Constants";

const { BOOK_TOUR_ACTIONS } = constants;

const ContactDetails = (props) => {
  const { visible, contactId, handleModal } = props;

  const dispatch = useDispatch();

  const [state, setState] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    if (!visible) {
      setState({});
      setError({});
    }
  }, [visible]);

  const contact = useSelector(
    (state) => state.contacts?.contactMap?.[contactId]
  );

  const showAdminApproval = useMemo(() => {
    if (contact?.type === "book_tour") return true;
    return false;
  }, [contact?.type]);

  const showSubmitBtn = useMemo(() => {
    if (contact?.type === "book_tour" && !contact?.meta?.action) return true;
    return false;
  }, [contact]);

  const handleChange = useCallback(
    (name) => (e) => {
      const value = e?.target?.value ?? e;
      setState((prevState) => ({ ...prevState, [name]: value }));
      setError({});
    },
    []
  );

  const hasError = useCallback(() => {
    const error = {};
    let { action, tour_date, tour_time, remark } = state;
    action = action?.trim?.();
    remark = remark?.trim?.();

    if (!action) {
      error.action = "Please Select Request Type";
    }

    if (BOOK_TOUR_ACTIONS.approve._id !== action) {
      if (BOOK_TOUR_ACTIONS.reschedule._id === action) {
        if (!tour_date || !tour_time) {
          error.new_tour_timestamp = "Please Select Date and Time";
        }
      }

      if (!remark) {
        error.remark = "Please Enter Remark";
      }
    }

    setError(error);
    return Object.keys(error).length > 0;
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      let { action, remark, tour_date, tour_time } = state;
      action = action?.trim?.();
      remark = remark?.trim?.();

      const new_tour_timestamp = moment(tour_date);
      new_tour_timestamp.set({
        hour: moment(tour_time).get("hour"),
        minute: moment(tour_time).get("minute"),
        second: 0,
      });

      const payload = {
        _id: contactId,
        action,
      };

      if (BOOK_TOUR_ACTIONS.approve._id !== action) {
        if (BOOK_TOUR_ACTIONS.reschedule._id === action) {
          payload.new_tour_timestamp = new_tour_timestamp;
        }

        payload.remark = remark;
      }

      dispatch(adminAcknowledgeContact(payload));
    }
  }, [dispatch, contactId, state, hasError]);

  const disabled = useMemo(() => contact?.loading, [contact?.loading]);

  return (
    <CustomModal
      title={"Contact Us"}
      width={750}
      visible={visible}
      onCancel={handleModal}
      footer={
        <>
          <Button onClick={handleModal}>Close</Button>

          {showSubmitBtn && (
            <Button
              type="primary"
              onClick={onSubmit}
              loading={contact?.loading}
            >
              Submit
            </Button>
          )}
        </>
      }
    >
      {/* Customer Information start */}
      <>
        <div
          className="--avatar mb15 mt15"
          style={{ width: "100%", backgroundColor: "#EEEDFD", fontWeight: 500 }}
        >
          Customer Information
        </div>

        {contact?.name && (
          <Row align="middle">
            <Col span={6}>
              <label className="label">Full name</label>
            </Col>

            <Col span={18}>{contact?.name}</Col>
          </Row>
        )}

        {contact?.email && (
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">Email</label>
            </Col>

            <Col span={18}>{contact?.email}</Col>
          </Row>
        )}

        {contact?.mobile_no && (
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">Phone number</label>
            </Col>

            <Col span={18}>{contact?.mobile_no}</Col>
          </Row>
        )}

        {contact?.company_name && (
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">Company name</label>
            </Col>

            <Col span={18}>{contact?.company_name}</Col>
          </Row>
        )}
        {contact?.hear_me && (
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">Lead Source</label>
            </Col>

            <Col span={18}>{contact?.hear_me}</Col>
          </Row>
        )}
      </>
      {/* Customer Information end */}

      {/* Workspace Info start */}
      <>
        <div
          className="--avatar mb15 mt15"
          style={{ width: "100%", backgroundColor: "#EEEDFD", fontWeight: 500 }}
        >
          Workspace Info
        </div>

        {contact?.space_id && (
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">Workspace name</label>
            </Col>

            <Col span={18}>{contact?.space_id?.name}</Col>
          </Row>
        )}

        {contact?.resource_type && (
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">Resource type</label>
            </Col>

            <Col span={18}>
              {resourceTypes?.[contact?.resource_type]?.name ||
                contact?.resource_type}
            </Col>
          </Row>
        )}

        {contact?.timestamp && contact?.type === "book_tour" && (
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">Tour Date Time</label>
            </Col>

            <Col span={18}>
              {moment(contact?.timestamp).format("MMM Do YY h:mm a")}
            </Col>
          </Row>
        )}

        {contact?.query && (
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">Additional Details</label>
            </Col>

            <Col span={18}>{contact?.query}</Col>
          </Row>
        )}
      </>
      {/* Workspace Info end */}

      {/* Admin Approval start */}
      {showAdminApproval && (
        <>
          <div
            className="--avatar mb15 mt15"
            style={{
              width: "100%",
              backgroundColor: "#EEEDFD",
              fontWeight: 500,
            }}
          >
            Admin Approval
          </div>

          {/* admin approval status start */}
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">Status</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18}>
              {contact?.meta?.action ? (
                BOOK_TOUR_ACTIONS[contact?.meta?.action]?.name ||
                contact?.meta?.action
              ) : (
                <>
                  <Select
                    className="custom-select fw"
                    placeholder={"Select Request Type"}
                    value={state.action}
                    onChange={handleChange("action")}
                    allowClear
                    size="large"
                    disabled={disabled}
                  >
                    {Object.values(BOOK_TOUR_ACTIONS).map((actionType) => (
                      <Select.Option
                        key={actionType?._id}
                        value={actionType?._id}
                      >
                        {actionType?.name}
                      </Select.Option>
                    ))}
                  </Select>

                  <Row className="error mt5">{error.action}</Row>
                </>
              )}
            </Col>
          </Row>
          {/* admin approval status end */}

          {/* New Tour Date Time start */}
          {(state.action === BOOK_TOUR_ACTIONS.reschedule._id ||
            contact?.meta?.new_tour_timestamp) && (
            <Row align="middle" className="mt10">
              <Col span={6}>
                <label className="label">New Tour Date Time</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>

              <Col span={18}>
                {contact?.meta ? (
                  moment(contact?.meta?.new_tour_timestamp).format(
                    "MMM Do YY h:mm a"
                  )
                ) : (
                  <>
                    <Space direction="horizontal" size={12}>
                      <DatePicker
                        className="custom-input"
                        size="large"
                        format="DD/MM/YYYY"
                        value={state.tour_date}
                        onChange={handleChange("tour_date")}
                        disabled={disabled}
                        disabledDate={disabledDate({
                          toDisabledDate: moment().subtract(1, "days"),
                        })}
                      />

                      <TimePicker
                        className="custom-input"
                        size="large"
                        format="HH:mm"
                        minuteStep={30}
                        value={state.tour_time}
                        onChange={handleChange("tour_time")}
                        disabled={disabled}
                        showNow={false}
                        hideDisabledOptions={true}
                      />
                    </Space>

                    <Row className="error mt5">{error.new_tour_timestamp}</Row>
                  </>
                )}
              </Col>
            </Row>
          )}
          {/* New Tour Date Time end */}

          {/* remark start */}
          {(state.action === BOOK_TOUR_ACTIONS.reschedule._id ||
            state.action === BOOK_TOUR_ACTIONS.reject._id ||
            contact?.meta?.remark) && (
            <Row className="mt10">
              <Col span={6}>
                <label className="label">Remark</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>

              <Col span={18}>
                {contact?.meta?.remark ? (
                  contact?.meta?.remark
                ) : (
                  <>
                    <Input.TextArea
                      className="custom-input"
                      placeholder="Add remark"
                      allowClear
                      value={state.remark}
                      onChange={handleChange("remark")}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      disabled={disabled}
                    />

                    <Row className="error mt5">{error.remark}</Row>
                  </>
                )}
              </Col>
            </Row>
          )}
          {/* remark end */}
        </>
      )}
      {/* Admin Approval end */}
    </CustomModal>
  );
};

export default ContactDetails;
