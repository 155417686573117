import moment from "moment";
import { Col, Row, Tag } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "S.no",
    dataIndex: "sn",
    key: "sn",
    width: 10,
    align: "center",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Mobile",
    dataIndex: "mobile",
    key: "mobile",
    width: 250,
  },
  {
    title: "Social Media",
    dataIndex: "type",
    key: "type",
    width: 250,
    align: "center",
    render: (type) => <Tag color="orange">{type || "-"}</Tag>,
  },
  {
    title: "Created on",
    dataIndex: "created_at",
    key: "created_at",
    width: 250,
    render: (date) => (
      <Row justify="start" align="middle">
        {date && (
          <>
            <Col
              className="--avatar green mr15 circle"
              justify="center"
              align="middle"
            >
              <CalendarOutlined />
            </Col>
            <Col>
              <h4 className="nm">{moment(date).format("ddd, MMM Do, YYYY")}</h4>
            </Col>
          </>
        )}
      </Row>
    ),
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: 100,
    align: "center",
  },
];

export default columns;
