import { put, takeLatest, call } from "redux-saga/effects";
import { showNotification } from "../../utils/CommonFunctions";
import ACTIONS from "./AdminActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const getAllAdminsRequest = (params = {}) => {
  return axios({
    method: "GET",
    url: `${AppUrl.USERS_URL}/team-members`,
    params: params,
  });
};

const addAdminRequest = (data) => {
  return axios({
    method: "POST",
    url: `${AppUrl.USERS_URL}/team-member`,
    data,
  });
};

const updateAdminRequest = (data) => {
  return axios({
    method: "PUT",
    url: `${AppUrl.USERS_URL}/update-team-member`,
    data,
  });
};

function* getAllAdmins(action) {
  try {
    let response = yield call(getAllAdminsRequest, action.payload);
    let _data = response?.data;

    delete action.payload.offset;
    delete action.payload.limit;

    _data.filter = JSON.stringify(action.payload);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.FETCH_ALL_ADMINS_FAILURE, payload: _data });
    } else {
      yield put({ type: ACTIONS.FETCH_ALL_ADMINS_SUCCESS, payload: _data });
    }
  } catch (error) {
    console.log("fetch workspace error", error);
  }
}

function* addAdmin(action) {
  try {
    let response = yield call(addAdminRequest, action.payload);
    let _data = response?.data;

    let params = {
      space_id: action?.payload?.space_id,
    };
    _data.filter = JSON.stringify(params);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.ADD_ADMIN_FAILURE, payload: _data?.meta });
    } else {
      yield put({ type: ACTIONS.ADD_ADMIN_SUCCESS, payload: _data });
      showNotification("success", "New Team Member added successfully!");
    }
  } catch (error) {
    console.log("add admin room error", error);
  }
}

function* updateAdmin(action) {
  try {
    let response = yield call(updateAdminRequest, action.payload);
    let _data = response?.data;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.UPDATE_ADMIN_FAILURE,
        payload: "Internal server Error",
      });
    } else {
      yield put({
        type: ACTIONS.UPDATE_ADMIN_SUCCESS,
        payload: _data,
      });
      showNotification("success", "Admin Updated!");
    }
  } catch (error) {
    console.log("update admin error", error);
  }
}

// ACTION WATCHER
export default function* adminsSaga() {
  yield takeLatest(ACTIONS.FETCH_ALL_ADMINS, getAllAdmins);
  yield takeLatest(ACTIONS.ADD_ADMIN, addAdmin);
  yield takeLatest(ACTIONS.UPDATE_ADMIN, updateAdmin);
}
