import { all } from "redux-saga/effects";
import loginSaga from "../login/duck/LoginSaga";
import WorkSpaceSaga from "../workspace/duck/WorkSpaceSaga";
import resourcesSaga from "../resources/duck/ResourceSaga";
import membersSaga from "../members/duck/MemberSaga";
import bookingSaga from "../booking/duck/BookingSaga";
import amenitiesSaga from "../settings/duck/AmenitiesSaga";
import dashboardSaga from "../dashboard/duck/DashboardSaga";
import planSaga from "../plan/duck/PlanSaga";
import invoiceSaga from "../invoice/duck/InvoiceSaga";
import mailSaga from "../mail/duck/MailSaga";
import billingSaga from "../billing/duck/BillingSaga";
import roleSaga from "../role/duck/RoleSaga";
import adminsSaga from "../adminAndTeams/duck/AdminSaga";
import doorSaga from "../doorList/duck/DoorSaga";
import userActivitySaga from "../userActivity/duck/UserActivitySaga";
import contactSaga from "../contacts/duck/ContactsSaga";
import leadSaga from "../socialMediaLead/duck/LeadsSaga";
import reportSaga from "../reports/duck/ReportSaga";
import cmsSaga from "../cms/duck/CMSSaga";
import xeroSaga from "../xero/duck/XeroSaga";

function* RootSaga() {
  yield all([
    loginSaga(),
    WorkSpaceSaga(),
    resourcesSaga(),
    bookingSaga(),
    membersSaga(),
    amenitiesSaga(),
    dashboardSaga(),
    planSaga(),
    invoiceSaga(),
    mailSaga(),
    billingSaga(),
    roleSaga(),
    adminsSaga(),
    doorSaga(),
    contactSaga(),
    leadSaga(),
    userActivitySaga(),
    reportSaga(),
    cmsSaga(),
    xeroSaga(),
  ]);
}

export { RootSaga };
