import { put, takeLatest, call } from "redux-saga/effects";
import axios from "axios";

import ACTIONS from "./ContactsActionTypes";

import AppUrl from "../../config/AppUrl";
import { showNotification } from "../../utils/CommonFunctions";

const getAllContactsRequest = (params = {}) => {
  return axios({
    method: "GET",
    url: AppUrl.BOOKING_TOUR,
    params: params,
  });
};

function* getAllContacts(action) {
  try {
    const resources = yield call(getAllContactsRequest, action.payload);
    let _data = resources?.data;

    let params = Object.assign({}, action?.payload);
    delete params?.offset;
    delete params?.limit;
    _data.filter = JSON.stringify(params);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.FETCH_ALL_CONTACTS_FAILURE, payload: _data });
    } else {
      yield put({ type: ACTIONS.FETCH_ALL_CONTACTS_SUCCESS, payload: _data });
    }
  } catch (error) {
    console.log("get all contacts error", error);
  }
}

const addContactRequest = (data) => {
  return axios({
    method: "POST",
    url: AppUrl.BOOKING_TOUR,
    data,
  });
};

function* addContact(action) {
  try {
    const resources = yield call(addContactRequest, action.payload);
    let _data = resources?.data;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", "Something went wrong");
      yield put({ type: ACTIONS.ADD_CONTACT_FAILURE, payload: _data });
    } else {
      showNotification("success", "Your details has been saved.");
      yield put({ type: ACTIONS.ADD_CONTACT_SUCCESS, payload: _data });
    }
  } catch (error) {
    console.log("add contact error", error);
  }
}

const adminAcknowledgeContactRequest = (data) => {
  return axios({
    method: "PUT",
    url: `${AppUrl.BOOKING_TOUR}/admin-acknowledge/${data?._id}`,
    data,
  });
};

function* adminAcknowledgeContact(action) {
  try {
    const resources = yield call(
      adminAcknowledgeContactRequest,
      action.payload
    );
    let _data = resources?.data;
    _data._id = action.payload._id;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", "Something went wrong");
      yield put({
        type: ACTIONS.UPDATE_CONTACT_FAILURE,
        payload: _data,
      });
    } else {
      showNotification("success", "Your details has been saved.");
      yield put({
        type: ACTIONS.UPDATE_CONTACT_SUCCESS,
        payload: _data,
      });
    }
  } catch (error) {
    console.log("update contact error", error);
  }
}

const deleteContactRequest = (id) => {
  return axios({
    method: "DELETE",
    url: `${AppUrl.BOOKING_TOUR}/${id}`,
  });
};

function* deleteContact(action) {
  try {
    const resources = yield call(deleteContactRequest, action.payload);
    let _data = resources?.data;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", "Something went wrong");
      yield put({ type: ACTIONS.DELETE_CONTACT_FAILURE, payload: _data?.meta });
    } else {
      yield put({ type: ACTIONS.DELETE_CONTACT_SUCCESS, payload: _data?.data });
    }
  } catch (error) {
    console.log("delete contact error", error);
  }
}

// ACTION WATCHER
export default function* contactSaga() {
  yield takeLatest(ACTIONS.FETCH_ALL_CONTACTS, getAllContacts);
  yield takeLatest(ACTIONS.ADD_CONTACT, addContact);
  yield takeLatest(ACTIONS.ADMIN_ACKNOWLEDGE_CONTACT, adminAcknowledgeContact);
  yield takeLatest(ACTIONS.DELETE_CONTACT, deleteContact);
}
