const Actions = {
  FETCH_ALL_BOOKING_RESOURCES: "FETCH_ALL_BOOKING_RESOURCES",
  FETCH_ALL_BOOKING_RESOURCES_SUCCESS: "FETCH_ALL_BOOKING_RESOURCES_SUCCESS",
  FETCH_ALL_BOOKING_RESOURCES_FAILURE: "FETCH_ALL_BOOKING_RESOURCES_FAILURE",

  FETCH_BOOKING_RESOURCES: "FETCH_BOOKING_RESOURCES",
  FETCH_BOOKING_RESOURCES_SUCCESS: "FETCH_BOOKING_RESOURCES_SUCCESS",
  FETCH_BOOKING_RESOURCES_FAILURE: "FETCH_BOOKING_RESOURCES_FAILURE",

  BOOK_RESOURCE_WITHOUT_PAY: "BOOK_RESOURCE_WITHOUT_PAY",
  BOOK_RESOURCE: "BOOK_RESOURCE",
  BOOK_RESOURCE_SUCCESS: "BOOK_RESOURCE_SUCCESS",
  BOOK_RESOURCE_FAILURE: "BOOK_RESOURCE_FAILURE",

  SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
  SET_REFRESH_TOKEN_SUCCESS: "SET_REFRESH_TOKEN_SUCCESS",
  SET_REFRESH_TOKEN_FAILURE: "SET_REFRESH_TOKEN_FAILURE",

  FETCH_ALL_AVAILABLE_RESOURCES: "FETCH_ALL_AVAILABLE_RESOURCES",
  FETCH_ALL_AVAILABLE_RESOURCES_SUCCESS:
    "FETCH_ALL_AVAILABLE_RESOURCES_SUCCESS",
  FETCH_ALL_AVAILABLE_RESOURCES_FAILURE:
    "FETCH_ALL_AVAILABLE_RESOURCES_FAILURE",

  CANCEL_BOOKING: "CANCEL_BOOKING",
  CANCEL_BOOKING_SUCCESS: "CANCEL_BOOKING_SUCCESS",
  CANCEL_BOOKING_FAILURE: "CANCEL_BOOKING_FAILURE",

  UPDATE_BOOKING: "UPDATE_BOOKING",
  UPDATE_DRAG_BOOKING: "UPDATE_DRAG_BOOKING",
  UPDATE_BOOKING_SUCCESS: "UPDATE_BOOKING_SUCCESS",
  UPDATE_BOOKING_FAILURE: "UPDATE_BOOKING_FAILURE",

  CHANGE_BOOKING_REQUEST: "CHANGE_BOOKING_REQUEST",
  CHANGE_BOOKING_REQUEST_SUCCESS: "CHANGE_BOOKING_REQUEST_SUCCESS",
  CHANGE_BOOKING_REQUEST_FAILURE: "CHANGE_BOOKING_REQUEST_FAILURE",

  CHANGE_BOOKED_RESOURCE: "CHANGE_BOOKED_RESOURCE",
  CHANGE_BOOKED_RESOURCE_SUCCESS: "CHANGE_BOOKED_RESOURCE_SUCCESS",
  CHANGE_BOOKED_RESOURCE_FAILURE: "CHANGE_BOOKED_RESOURCE_FAILURE",

  CHANGE_BOOKED_RESOURCE_APPROVE: "CHANGE_BOOKED_RESOURCE_APPROVE",
  CHANGE_BOOKED_RESOURCE_APPROVE_SUCCESS:
    "CHANGE_BOOKED_RESOURCE_APPROVE_SUCCESS",
  CHANGE_BOOKED_RESOURCE_APPROVE_FAILURE:
    "CHANGE_BOOKED_RESOURCE_APPROVE_FAILURE",

  PAY_CHARGE: "PAY_CHARGE",
  PAY_CHARGE_SUCCESS: "PAY_CHARGE_SUCCESS",
  PAY_CHARGE_FAILURE: "PAY_CHARGE_FAILURE",
};

export default Actions;
