import React, { useMemo } from "react";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";

import { getStripeCardColor } from "../../utils/CommonFunctions";

import "../../billing/components/StripeCard.scss";

const StripeCardPayDetails = (props) => {
  const { sourceId, index } = props;

  const source = useSelector((state) => state.billing?.sources[sourceId]);

  const bgColors = useMemo(() => getStripeCardColor(index), [index]);

  if (!sourceId || !source) {
    return <></>;
  }

  const card = source?.card;

  const lastFourDigit = card?.last4;
  const month = `0${card?.exp_month || ""}`.slice(-2);
  const year = `${card?.exp_year || ""}`.slice(-2);
  const valid = `${month}/${year}`;
  const cardBrand = card?.brand?.toLowerCase?.();

  return (
    <Row
      className="--card-layout stripe-card-pay-details-container"
      style={{ backgroundColor: bgColors.bgColor }}
    >
      <Row
        className="--card-layout stripe-card-content fw"
        justify="space-between"
        style={{ background: bgColors.overlayColor }}
      >
        <Col className="card-owner-name text-white">{source?.owner?.name}</Col>

        <Col className="card-mask-digit">
          XXXX XXXX XXXX
          <span className="card-last-four-digit text-white ml10">
            {lastFourDigit}
          </span>
        </Col>

        <Row className="fw" align="middle" justify="space-between">
          <Col className="card-validity-details text-white">
            Valid Thru <span className="card-valid ml10"> {valid}</span>
          </Col>

          <Col className={`credit-card-brand ${cardBrand}`} />
        </Row>
      </Row>
    </Row>
  );
};

export default React.memo(StripeCardPayDetails);
