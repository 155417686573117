import Actions from "./CMSActionTypes";

export const getAllCMS = (params = {}) => {
  return {
    type: Actions.FETCH_ALL_CMS,
    payload: params,
  };
};

export const createCMS = (payload = {}) => {
  console.log("createCMS", payload);
  return {
    type: Actions.CREATE_CMS,
    payload: payload,
  };
};

export const updateCMS = (payload = {}) => {
  console.log("payloadss", payload);
  return {
    type: Actions.UPDATE_CMS,
    payload: payload,
  };
};
