import Actions from "./RoleActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";

const initialState = {
  roles: {},
  rolesList: [],
  error: null,
  loading: false,
};

const roleReducer = (state = initialState, action) => {
  let payload = action?.payload;

  switch (action.type) {
    /** fetch all members start */

    case Actions.FETCH_ALL_ROLES: {
      return Object.assign({}, state, {
        error: null,
        loading: true,
      });
    }

    case Actions.FETCH_ALL_ROLES_SUCCESS: {
      return Object.assign({}, state, {
        roles: arrToObjMap(payload?.data),
        rolesList: [...new Set(getArrOrder(payload?.data))],
        loading: false,
      });
    }

    case Actions.FETCH_ALL_ROLES_FAILURE: {
      return Object.assign({}, state, {
        error: payload,
        loading: false,
      });
    }

    /** fetch all members end */

    /** add member start */

    case Actions.ADD_ROLE: {
      return Object.assign({}, state, {
        error: null,
        loading: true,
      });
    }

    case Actions.ADD_ROLE_SUCCESS: {
      const roleId = payload?._id;
      let list = [...(state?.rolesList || [])];
      return Object.assign({}, state, {
        roles: { ...state.roles, [roleId]: payload },
        rolesList: [...new Set([...list, roleId])],
        loading: false,
      });
    }

    case Actions.UPDATE_ROLE: {
      return Object.assign({}, state, {
        error: null,
        loading: true,
      });
    }

    case Actions.UPDATE_ROLE_SUCCESS: {
      return Object.assign({}, state, {
        roles: { ...state.roles, [payload?._id]: payload },
        loading: false,
      });
    }

    case Actions.GET_ONE_ROLE_SUCCESS: {
      return Object.assign({}, state, {
        roles: { ...state.roles, [payload?._id]: payload },
        loading: false,
      });
    }

    case Actions.ADD_ROLE_FAILURE:
    case Actions.GET_ONE_ROLE_FAILURE:
    case Actions.UPDATE_ROLE_FAILURE: {
      return Object.assign({}, state, {
        error: payload,
        loading: false,
      });
    }

    /** add member end */

    default:
      return state;
  }
};

export default roleReducer;
