import React, { useMemo } from "react";
import { Card, Image, Tooltip, Typography } from "antd";
import { useSelector } from "react-redux";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import noImage from "../../assets/images/no-image.png";
import { resourceTypes } from "../../utils/CommonFunctions";
import Routes from "../../config/Routes";

const { Paragraph } = Typography;

const SliderTabCard = (props) => {
  const { id, isCMSEditable } = props;
  const history = useHistory();

  const mCms = useSelector((state) => state?.cms?.cmsMap?.[id]);

  const ExtraIcon = useMemo(
    () => (isCMSEditable ? EditOutlined : EyeOutlined),
    [isCMSEditable]
  );

  const resource_type = mCms?.meta?.resource_type;
  const imgSrc = mCms?.pictures?.[0]?.thumb_location;
  const price = mCms?.price || "tbc";

  // const unit = price
  //   ? resource_type === "meeting_room"
  //     ? "/hr"
  //     : "pcm"
  //   : "tbc";

  return (
    <Card
      style={{
        width: 300,
        margin: "10px 20px 10px 0",
        borderRadius: 5,
      }}
      title={mCms?.name || resourceTypes?.[resource_type]?.name}
      bordered={false}
      hoverable
      extra={
        <Tooltip title={isCMSEditable ? "Edit" : "More Details"}>
          <ExtraIcon
            style={{ fontSize: 12 }}
            onClick={() => history.push(`${Routes.editSlider}/${id}`)}
          />
        </Tooltip>
      }
      cover={
        <Image
          className="cover new_image-fluid"
          preview={false}
          width={"100%"}
          height={"100%"}
          wrapperStyle={{ height: 150 }}
          src={imgSrc}
        />
      }
    >
      <Paragraph ellipsis={{ rows: 5 }}>{mCms?.description}</Paragraph>

      {price && <p>Price: {price}</p>}
    </Card>
  );
};

export default SliderTabCard;
