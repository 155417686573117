import React from "react";
import { Col, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import loginSvg from "../../assets/svg/logo_full.svg";

import "../../assets/css/login.scss";

const AuthBgContainer = (props) => {
  return (
    <Col className="auth-container">
      <Col className="--container">

        <Row className="header-container">
          <Link className="btn-link" to={"/"}>
            <ArrowLeftOutlined /> Back To Home
          </Link>
        </Row>
       <Col className="nw_mob_set_scb">
        <Col className="content-container">
          <Col className="logo-container mb20" align="middle">
            <img src={loginSvg} alt="Garden Office" className="logo" />
          </Col>

          <Col className="title-container mt10 mb20" align="middle">
            <Typography.Title level={1}>{props.title}</Typography.Title>

            <Col className="form-container mb20 pl20 pr20">
              <Typography.Text align="center">{props.subtitle}</Typography.Text>
            </Col>
          </Col>

          <Row justify="center">
            <Col className="form-container">{props.children}</Col>
          </Row>
        </Col>
      </Col>
      </Col>
    </Col>
  );
};

export default React.memo(AuthBgContainer);
