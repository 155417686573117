import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { Store } from "./config/Store";
import { BrowserRouter as Router } from "react-router-dom";
import AuthRouters from "./routers/AuthRouters";
import { DefaultConfig } from "./config/Axios";
import ErrorBoundary from "./utils/ErrorBoundary";
import "antd/dist/antd.css";
import "./assets/css/App.scss";
import "react-quill/dist/quill.snow.css";
import moment from "moment-timezone";

moment.tz.setDefault("Europe/London");

DefaultConfig.setRequests();
ReactDOM.render(
  <Provider store={Store}>
    <Router>
      <ErrorBoundary>
        <AuthRouters />
      </ErrorBoundary>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
