import Actions from "./BillingActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";

const initialState = {
  customer: {},
  sources: {},
  history: {},
  sourceList: [],
  historyList: [],
  loading: false,
  error: null,
};

const billingReducer = (state = initialState, action) => {
  const payload = action?.payload;

  switch (action.type) {
    case Actions.FETCH_ALL_CARD: {
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    }

    case Actions.FETCH_ALL_CARD_SUCCESS: {
      return Object.assign({}, state, {
        default_source: payload?.customer.default_source,
        customer: Object.assign({}, state.customer, payload?.customer),
        sources: Object.assign(
          {},
          state.sources,
          arrToObjMap(payload?.sources)
        ),
        history: Object.assign(
          {},
          state.history,
          arrToObjMap(payload?.history)
        ),
        sourceList: getArrOrder(payload?.sources),
        historyList: getArrOrder(payload?.history),
        loading: false,
      });
    }

    case Actions.FETCH_ALL_CARD_FAILURE: {
      return Object.assign({}, state, {
        error: action?.payload,
        loading: false,
      });
    }

    /** add new card start */
    case Actions.ADD_CARD: {
      return Object.assign({}, state, {
        adding: true,
        error: null,
      });
    }

    case Actions.ADD_CARD_SUCCESS: {
      return Object.assign({}, state, {
        default_source: payload?.src_id,
        sources: Object.assign({}, state.sources, {
          [payload?.src_id]: payload,
        }),
        sourceList: [...state.sourceList, payload?.src_id],
        adding: false,
      });
    }

    case Actions.ADD_CARD_FAILURE: {
      return Object.assign({}, state, {
        adding: false,
        error: payload,
      });
    }

    /** add new card end */

    /** set default source card start */
    case Actions.SET_DEFAULT_CARD: {
      return Object.assign({}, state, {
        sources: Object.assign({}, state.sources, {
          [payload?.src_id]: Object.assign({}, state.sources[payload?.src_id], {
            makeDefaultPaymentSourceLoader: true,
          }),
        }),
      });
    }

    case Actions.SET_DEFAULT_CARD_SUCCESS: {
      return Object.assign({}, state, {
        default_source: payload?.src_id,
        sources: Object.assign({}, state.sources, {
          [payload?.src_id]: Object.assign({}, state.sources[payload?.src_id], {
            makeDefaultPaymentSourceLoader: false,
            error: null,
          }),
        }),
      });
    }

    case Actions.SET_DEFAULT_CARD_FAILURE: {
      return Object.assign({}, state, {
        sources: Object.assign({}, state.sources, {
          [payload?.src_id]: Object.assign({}, state.sources[payload?.src_id], {
            makeDefaultPaymentSourceLoader: false,
            error: payload,
          }),
        }),
      });
    }
    /** set default source card end */

    /** delete card start */
    case Actions.DELETE_CARD: {
      return Object.assign({}, state, {
        sources: Object.assign({}, state.sources, {
          [payload?.src_id]: Object.assign({}, state.sources[payload?.src_id], {
            deleteLoader: true,
          }),
        }),
      });
    }

    case Actions.DELETE_CARD_SUCCESS: {
      return Object.assign({}, state, {
        sources: Object.assign({}, state.sources, {
          [payload?.src_id]: undefined,
        }),
        sourceList: state.sourceList.filter((id) => id !== payload?.src_id),
        default_source:
          state.default_source === payload?.src_id
            ? null
            : state.default_source,
      });
    }

    case Actions.DELETE_CARD_FAILURE: {
      return Object.assign({}, state, {
        sources: Object.assign({}, state.sources, {
          [payload?.src_id]: Object.assign({}, state.sources[payload?.src_id], {
            deleteLoader: false,
            error: payload,
          }),
        }),
      });
    }

    /** delete card end */
    default:
      return state;
  }
};

export default billingReducer;
