import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <>
      <div className="">
        <Link to="">&nbsp;</Link>
      </div>
    </>
  );
};

export default Logo;
