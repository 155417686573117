import { Button, Row } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import ResourceCard from "./components/ResourceCard";
import Spinner from "../spinner/Spinner";
import AddPrivateOffice from "./components/AddPrivateOffice";

import { checkPermission } from "../utils/CommonFunctions";
import resourceSvg from "../assets/svg/resource.svg";

const PrivateOffices = () => {
  const [visible, showModal] = useState(false);
  const [selectedOffice, selectOffice] = useState(null);

  const editable = useMemo(
    () =>
      checkPermission({
        row: "resources",
        subRow: "privateOffices",
      }),
    []
  );

  const activeWorkSpace = useSelector((state) => state?.workSpace?.active);
  const resources = useSelector((state) => state.resources?.resources);
  const privateOffices = useMemo(
    () => resources?.[activeWorkSpace]?.privateOffices || [],
    [resources, activeWorkSpace]
  );
  const privateOfficesSpin = !privateOffices?.length && resources?.loading;

  const handleModal = useCallback((show = false, resourceId) => {
    show = typeof show === "boolean" && show;
    showModal(show);
    selectOffice(resourceId);
  }, []);

  return privateOfficesSpin ? (
    <Spinner />
  ) : (
    <div className="resourceMain">
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Private Offices</span>
        {editable && (
          <Button
            type="primary-outline"
            onClick={() => handleModal(true, null)}
          >
            + Add Private Office
          </Button>
        )}
      </Row>

      {privateOffices?.length === 0 && (
        <Row justify="center" align="middle" className="mt20 pt20">
          <img src={resourceSvg} alt="No resource added!" />
        </Row>
      )}

      <Row>
        {privateOffices?.map?.((id) => (
          <ResourceCard
            key={id}
            id={id}
            type={"private_office"}
            onEdit={handleModal}
          />
        ))}
      </Row>

      <AddPrivateOffice
        visible={visible}
        selectedOffice={selectedOffice}
        handleModal={handleModal}
        editable={editable}
      />
    </div>
  );
};

export default PrivateOffices;
