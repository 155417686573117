import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Table, Row } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getAllReports } from "./duck/ReportActions";
import { useHistory, useParams } from "react-router-dom";
import { reportColumns } from "./components/ReportColumns";
import MTable from "../utils/MTable";

const Reports = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const prevProps = useRef();
  const actor = useParams()?.actor;

  const activeWorkSpace = useSelector((state) => state?.workSpace?.active);
  const allReports = useSelector((state) => state?.reports);

  const [boardKey, setBoardKey] = useState();

  const mReportBoard = useMemo(
    () => allReports?.reports?.[boardKey] ?? {},
    [allReports?.reports, boardKey]
  );
  const loadMore = useCallback(
    (offset = 0, limit = 30) => {
      const { meta, list, loading } = mReportBoard;
      if (!loading && (offset === 0 || meta?.totalCount > list?.length)) {
        let params = { actor, space_id: activeWorkSpace };
        setBoardKey(JSON.stringify({ ...params }));

        params.offset = offset;
        params.limit = limit;

        dispatch(getAllReports(params));
      }
    },
    [mReportBoard, actor, activeWorkSpace, dispatch]
  );

  useEffect(() => {
    if (prevProps?.current?.activeWorkSpace !== activeWorkSpace) {
      setBoardKey("");
    } else if (actor && !Object.keys(mReportBoard)?.length) loadMore();

    return () => {
      prevProps.current = { activeWorkSpace };
    };
  }, [activeWorkSpace, actor, loadMore, mReportBoard]);

  const handleTableChange = useCallback(
    (pagination) => {
      const { current, pageSize } = pagination;
      const { list } = mReportBoard;

      const endIndex = current * pageSize;

      if (endIndex > list?.length) {
        loadMore(list?.length, endIndex - list?.length);
      }
    },
    [mReportBoard, loadMore]
  );

  const reports = mReportBoard?.list;

  const data = useMemo(() => {
    let mData = [];
    if (reports) {
      reports?.forEach((id) => {
        let mReport = allReports?.reportsMap?.[id];
        mData.push({
          key: id,
          report: mReport,
        });
      });
    }
    return mData;
  }, [reports, allReports?.reportsMap]);

  return (
    <div>
      <Row justify="space-between" align="middle" className="mb20">
        <span>
          <h2 style={{ fontSize: 22, fontWeight: 600, marginBottom: 0 }}>
            Activity Feed
          </h2>
        </span>
        <span>
          <Button className="ml10" onClick={() => history.goBack()}>
            <ArrowLeftOutlined />
            Back
          </Button>
        </span>
      </Row>
      <MTable
        size="middle"
        columns={reportColumns()}
        showHeader={false}
        dataSource={data}
        className="mt20"
        bordered={false}
        loading={mReportBoard?.loading}
        pagination={{
          total: mReportBoard?.meta?.totalCount || 0,
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Reports;
