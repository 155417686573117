import { Button, Col, Input, Row } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";

import CustomModal from "../../utils/CustomModal";

import { createCMS, updateCMS } from "../duck/CMSActions";
import constants from "../../utils/Constants";

const { CMS_TYPES } = constants;

const OtherPageModal = (props) => {
  const prevProp = useRef();
  const dispatch = useDispatch();

  const { visible, selectedId, showModal, isCMSEditable } = props;

  const mCms = useSelector(({ cms }) =>
    selectedId
      ? cms.cmsMap[selectedId]
      : { loading: cms.loading, error: cms.error }
  );

  const [state, setState] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    if (prevProp?.current?.loading && !mCms?.loading) {
      setState({});
      showModal?.(false);
    } else if (!prevProp?.current?.visible && visible) {
      let mState = {};

      if (mCms) {
        const meta = mCms?.meta;
        const pageMeta = meta?.pageMeta;

        mState = {
          metaTitle: pageMeta?.title,
          metaDescription: pageMeta?.description,
          name: mCms?.name,
          description: mCms?.description,
          slug: meta?.slug,
        };
      }

      setState(mState);
      setError({});
    }

    return () => {
      prevProp.current = {
        loading: mCms?.loading,
        visible,
      };
    };
  }, [mCms, visible, showModal]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      if (name === "slug" && typeof value === "string") {
        value = value.replace(" ", "-").toLowerCase();
      }

      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const hasError = useCallback(() => {
    let { metaTitle, metaDescription, name, slug, description } = state;
    metaTitle = metaTitle?.trim?.();
    metaDescription = metaDescription?.trim?.();

    const error = {};

    if (!metaTitle) {
      error.metaTitle = "Title cannot be blank";
    }

    if (!metaDescription) {
      error.metaDescription = "Description cannot be blank";
    }

    if (!name) {
      error.name = "Name cannot be blank";
    }

    if (!slug) {
      error.slug = "Slug cannot be blank";
    }

    if (!description) {
      error.description = "Description cannot be blank";
    }

    setError(error);
    return Object.keys(error).length;
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      let { metaTitle, metaDescription, name, slug, description } = state;
      name = name?.trim();
      slug = slug?.trim();
      description = description?.trim();

      metaTitle = metaTitle?.trim();
      metaDescription = metaDescription?.trim();
      const pageMeta = { title: metaTitle, description: metaDescription };
      const meta = {
        slug,
        pageMeta,
      };

      const payload = {
        name,
        description,
        type: CMS_TYPES.OTHER_PAGE,
        meta: JSON.stringify(meta),
      };

      if (selectedId) {
        payload._id = selectedId;
        dispatch(updateCMS(payload));
      } else {
        dispatch(createCMS(payload));
      }
    }
  }, [selectedId, state, hasError, dispatch, mCms.meta]);

  const disabled = useMemo(
    () => !isCMSEditable || mCms?.loading,
    [isCMSEditable, mCms?.loading]
  );

  return (
    <CustomModal
      className="cms-modal"
      visible={visible}
      title={"Page Details"}
      onCancel={props.showModal}
      footer={
        <>
          <Button onClick={props.showModal}>Close</Button>

          {isCMSEditable && (
            <Button type="primary" loading={mCms?.loading} onClick={onSubmit}>
              Update
            </Button>
          )}
        </>
      }
    >
      <Col className="content">
        {/* name start */}
        <Row className="mb10" align="middle">
          <Col span={6} sm={24} lg={6} xl={5} xxl={4}>
            <label className="label">Name </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col span={18} sm={24} lg={18} xl={19} xxl={20}>
            <Input
              className="custom-input"
              placeholder="Name"
              size="large"
              type="text"
              value={state.name}
              onChange={handleChange("name")}
              disabled={disabled}
            />

            <Row className="error mt5">{error.name}</Row>
          </Col>
        </Row>
        {/* name end */}

        {/* slug start */}
        <Row className="mb10" align="middle">
          <Col span={6} sm={24} lg={6} xl={5} xxl={4}>
            <label className="label">Slug</label>
          </Col>

          <Col span={18} sm={24} lg={18} xl={19} xxl={20}>
            <Input
              className="custom-input"
              placeholder="Slug"
              size="large"
              type="text"
              value={state.slug}
              onChange={handleChange("slug")}
              disabled={disabled}
            />
            <Row className="error mt5">{error.slug}</Row>
          </Col>
        </Row>
        {/* slug end */}

        {/* meta details satrt */}
        <>
          <Row className="section mb10" align="middle" justify="center">
            Meta Details
          </Row>

          {/* title start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={6} xl={5} xxl={4}>
              <label className="label">Title</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={18} xl={19} xxl={20}>
              <Input
                className="custom-input"
                placeholder="Title"
                size="large"
                type="text"
                value={state.metaTitle}
                onChange={handleChange("metaTitle")}
                disabled={disabled}
              />

              <Row className="error mt5">{error.metaTitle}</Row>
            </Col>
          </Row>
          {/* title end */}

          {/* description start */}
          <Row className="mb10">
            <Col span={6} xs={24} sm={24} lg={6} xl={5} xxl={4}>
              <label className="label">Description </label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={18} xl={19} xxl={20}>
              <Input.TextArea
                className="custom-input"
                placeholder="Description"
                size="large"
                type="text"
                value={state.metaDescription}
                onChange={handleChange("metaDescription")}
                autoSize={{ minRows: 2, maxRows: 3 }}
                disabled={disabled}
              />

              <Row className="error mt5">{error.metaDescription}</Row>
            </Col>
          </Row>
          {/* description end */}
        </>
        {/* meta details end */}

        {/* other details start */}
        <>
          <Row className="section mb10" align="middle" justify="center">
            Others Details
          </Row>

          {/* content start */}
          <Row className="mt10">
            <Col span={6} xs={24} sm={24} lg={6} xl={5} xxl={4}>
              <label className="label">Content</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={18} xl={19} xxl={20}>
              <ReactQuill
                className="custom-input-editor"
                placeholder="Content"
                theme="snow"
                value={state.description}
                onChange={handleChange("description")}
              />

              <Row className="error mt5">{error.description}</Row>
            </Col>
          </Row>
          {/* content end */}
        </>
        {/* other details end */}
      </Col>
    </CustomModal>
  );
};

export default React.memo(OtherPageModal);
