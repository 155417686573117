import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Bannerhome from "./component/Bannerhome";

import WebLayoutContainer from "./component/WebLayoutContainer";
import { getAllWorkSpaceRequest } from "../workspace/duck/WorkSpaceActions";
import Routes from "../config/Routes";

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const preload = () => {
    getAllWorkSpaceRequest && dispatch(getAllWorkSpaceRequest());
  };

  useEffect(() => {
    history.replace(Routes.dashboard);
    preload();
  }, []);

  return (
    <WebLayoutContainer>
      <div className="container">
        <Bannerhome />
      </div>
    </WebLayoutContainer>
  );
};

export default Home;
