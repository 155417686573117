import { useState, useCallback } from "react";
import { Button, Input, Row, Col, Typography } from "antd";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";

import AuthBgContainer from "./components/AuthBgContainer";

import AppUrl from "../config/AppUrl";
import {
  isUserAuthenticated,
  showNotification,
} from "../utils/CommonFunctions";
import Routes from "../config/Routes";

import "../assets/css/login.scss";

const ForgotPassword = () => {
  const [otp, setOtp] = useState("");
  const [err, handleError] = useState({});
  const [loading, setLoader] = useState(false);

  const verifyOtpRequest = useCallback(async () => {
    setLoader(true);
    try {
      const response = await axios.post(AppUrl.VERIFY_OTP, { otp });
      let resData = response.data;
      if (resData?.meta?.success !== true || resData?.meta?.status !== 200) {
        showNotification("error", resData?.meta?.message);
        setLoader(false);
      } else {
        showNotification("success", "Account activated successfully!");
        setLoader(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoader(false);
    }
  }, [otp]);

  const onSubmit = useCallback(() => {
    if (otp.trim()) {
      verifyOtpRequest();
    } else {
      const err = {};

      if (!otp?.trim()) {
        err.otp = "OTP cannot be blank";
      }

      handleError(err);
    }
  }, [otp, verifyOtpRequest]);

  const resendOtpRequest = useCallback(async () => {
    setOtp("");

    try {
      const response = await axios.get(AppUrl.RESEND_OTP);
      const resData = response.data;

      if (resData?.meta?.success !== true || resData?.meta?.status !== 200) {
        showNotification("error", resData?.meta?.message);
      } else {
        showNotification("success", "OTP send to your email successfully!");
      }
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  if (isUserAuthenticated()) {
    return <Redirect to={Routes.dashboard} />;
  }

  return (
    <AuthBgContainer
      title={"OTP Verification"}
      subtitle={"OTP has been sent to you on your email. please enter it below"}
    >
      <Col className="mb20">
        <Col className="label mt5 mb5">OTP</Col>

        <Input
          placeholder="OTP"
          size="large"
          allowClear
          onChange={(e) => {
            setOtp(e.target.value.toUpperCase());
            handleError({ otp: null });
          }}
          value={otp}
          onPressEnter={onSubmit}
          autoFocus
        />
        <Col className="error mt5">{err.otp}</Col>
      </Col>

      <Button
        type="primary"
        size="large"
        block
        onClick={onSubmit}
        loading={loading}
      >
        Verify
      </Button>

      <Row className="mt20" align="middle" justify="center">
        <Typography.Text align="center">
          If you don't recived a code! &nbsp;
        </Typography.Text>

        <Link className="btn-link resend-otp-link" onClick={resendOtpRequest}>
          Resend
        </Link>
      </Row>

      <Row className="mt20" justify="center">
        <Link className="btn-link" to={Routes.login}>
          Back to login
        </Link>
      </Row>
    </AuthBgContainer>
  );
};

export default ForgotPassword;
