import { socket } from "../config/Socket";
import LOGIN_ACTIONS from "../login/duck/LoginActionTypes";
import WORKSPACE_ACTIONS from "../workspace/duck/WorkSpaceActionTypes";
import RESOURCES_ACTIONS from "../resources/duck/ResourceActionTypes";
import BOOKING_ACTIONS from "../booking/duck/BookingActionTypes";
import MEMBER_ACTIONS from "../members/duck/MemberActionTypes";
import AMENITIES_ACTIONS from "../settings/duck/AmenitiesActionTypes";
import DASHBOARD_ACTIONS from "../dashboard/duck/DashboardActionTypes";
import PLAN_ACTIONS from "../plan/duck/PlanActionTypes";
import REPORT_ACTIONS from "../reports/duck/ReportActionTypes";
import INVOICE_ACTIONS from "../invoice/duck/InvoiceActionTypes";
import MAIL_ACTIONS from "../mail/duck/MailActionTypes";
import BILLING_ACTIONS from "../billing/duck/BillingActionTypes";
import ROLE_ACTIONS from "../role/duck/RoleActionTypes";
import ADMIN_ACTIONS from "../adminAndTeams/duck/AdminActionTypes";
import DOOR_ACTIONS from "../doorList/duck/DoorActionTypes";
import USER_ACTIVITY_ACTIONS from "../userActivity/duck/UserActivityActionTypes";
import CONTACT_ACTIONS from "../contacts/duck/ContactsActionTypes";

import {
  getAllWorkspaceResources,
  addDesk,
  addItem,
  addMeetingRoom,
  addPrivateOffice,
  deleteItem,
  updateDesk,
  updateItem,
  updateMeetingRoom,
  updatePrivateOffice,
} from "../resources/duck/ResourceActions";
import { setRefreshToken } from "../booking/duck/BookingActions";
import { checkPermission } from "./CommonFunctions";

import { Store } from "../config/Store";
import jwtDecode from "jwt-decode";

const { dispatch } = Store;

function userSocket() {
  socket.on("users", function (user) {
    console.log("user socket update", user);
    switch (user.meta.action) {
      case "create":
        break;

      case "update":
        break;

      case "delete":
        break;

      case "createMember":
        break;

      case "updateMember":
        break;

      default:
        break;
    }
  });
}

function spaceSocket() {
  socket.on("spaces", function (space) {
    console.log("space socket update", space);
    switch (space.meta.action) {
      case "create":
        dispatch({
          type: WORKSPACE_ACTIONS.ADD_WORKSPACE_SUCCESS,
          payload: space.data,
        });
        break;

      case "update":
        dispatch({
          type: WORKSPACE_ACTIONS.UPDATE_WORKSPACE_SUCCESS,
          payload: space.data,
        });
        break;

      default:
        break;
    }
  });
}

function roleSocket() {
  socket.on("roles", function (role) {
    console.log("role socket update", role);
    switch (role.meta.action) {
      case "create":
        dispatch({ type: ROLE_ACTIONS.ADD_ROLE_SUCCESS, payload: role?.data });
        break;

      case "update":
        dispatch({ type: ROLE_ACTIONS.UPDATE_ROLE_SUCCESS, payload: role });
        break;

      case "delete":
        break;

      default:
        break;
    }
  });
}
function invoiceSocket() {
  socket.on("invoices", function (invoice) {
    console.log("invoice socket update", invoice);
    switch (invoice.meta.action) {
      case "create":
        {
          const showAllBooking = checkPermission({ initial: "showAllBooking" });
          let params = {};
          if (showAllBooking) {
            const mInvoice = invoice?.data;
            const mSpaceId = mInvoice?.space_id?._id || mInvoice?.space_id;
            params.space_id = mSpaceId;
          }

          invoice.filter = JSON.stringify(params);
          invoice.data = [invoice?.data];

          dispatch({
            type: INVOICE_ACTIONS.FETCH_ALL_INVOICES_SUCCESS,
            payload: invoice,
          });
        }
        break;

      default:
        break;
    }
  });
}

function resourceSocket() {
  socket.on("resources", function (resource) {
    console.log("resource socket update", resource);
    switch (resource.meta.action) {
      case "create": {
        const dispatchPayload = {
          type: "",
          payload: resource?.data,
        };

        const resourceType = resource?.data?.type;
        if (resourceType === "meeting_room") {
          dispatchPayload.type = RESOURCES_ACTIONS.ADD_MEETING_SUCCESS;
        } else if (resourceType === "private_office") {
          dispatchPayload.type = RESOURCES_ACTIONS.ADD_PRIVATE_OFFICE_SUCCESS;
        } else if (resourceType === "desks") {
          dispatchPayload.type = RESOURCES_ACTIONS.ADD_DESK_SUCCESS;
        }

        dispatch(dispatchPayload);
        break;
      }

      case "update": {
        dispatch({
          type: RESOURCES_ACTIONS.FETCH_RESOURCES_SUCCESS,
          payload: resource?.data,
        });
        break;
      }

      case "createItem": {
        dispatch({
          type: RESOURCES_ACTIONS.ADD_ITEM_SUCCESS,
          payload: resource?.data,
        });
        break;
      }

      case "updateItem": {
        dispatch({
          type: RESOURCES_ACTIONS.UPDATE_ITEM_SUCCESS,
          payload: resource?.data,
        });
        break;
      }

      case "deleteItem": {
        dispatch({
          type: RESOURCES_ACTIONS.DELETE_ITEM_SUCCESS,
          payload: resource?.data,
        });
        break;
      }

      default:
        break;
    }
  });
}

function planSocket() {
  socket.on("plans", function (plan) {
    console.log("plan socket update", plan);
    switch (plan.meta.action) {
      case "create":
        dispatch({ type: PLAN_ACTIONS.ADD_PLAN_SUCCESS, payload: plan });
        break;

      case "update":
        dispatch({ type: PLAN_ACTIONS.UPDATE_PLAN_SUCCESS, payload: plan });
        break;

      case "delete":
        break;

      default:
        break;
    }
  });
}

function globalSocket() {
  socket.on("global", function (global) {
    console.log("global socket update", global);
    switch (global.meta.action) {
      case "update":
        break;

      default:
        break;
    }
  });
}

function bookingSocket() {
  socket.on("book-resources", function (booking) {
    console.log("book-resource socket update", booking);
    switch (booking.meta.action) {
      case "create": {
        const showAllBooking = checkPermission({ initial: "showAllBooking" });

        let params = {};
        if (showAllBooking) {
          const mBooking = booking?.data;
          const mSpaceId = mBooking?.space_id?._id || mBooking?.space_id;
          params.space_id = mSpaceId;
        }

        booking.filter = JSON.stringify(params);
        dispatch({
          type: BOOKING_ACTIONS.BOOK_RESOURCE_SUCCESS,
          payload: booking,
        });
        break;
      }

      case "update":
        dispatch({
          type: BOOKING_ACTIONS.UPDATE_BOOKING_SUCCESS,
          payload: booking,
        });
        break;

      case "delete":
        dispatch({
          type: BOOKING_ACTIONS.CANCEL_BOOKING_SUCCESS,
          payload: booking,
        });
        break;

      case "raised": {
        const showAllBooking = checkPermission({ initial: "showAllBooking" });

        let params = {};
        if (showAllBooking) {
          const mBooking = booking?.data;
          const mSpaceId = mBooking?.space_id?._id || mBooking?.space_id;
          params.space_id = mSpaceId;
        }
        params.seat_swap_status = "All";
        booking.filter = JSON.stringify(params);

        dispatch({
          type: BOOKING_ACTIONS.BOOK_RESOURCE_SUCCESS,
          payload: booking,
        });

        dispatch({
          type: BOOKING_ACTIONS.CHANGE_BOOKED_RESOURCE_SUCCESS,
          payload: booking,
        });
        break;
      }

      case "raisedApprove":
        dispatch({
          type: BOOKING_ACTIONS.CHANGE_BOOKED_RESOURCE_APPROVE_SUCCESS,
          payload: booking,
        });
        break;

      default:
        break;
    }
  });
}

function attachmentSocket() {
  socket.on("attachments", function (attachment) {
    console.log("attachments socket update", attachment);
    switch (attachment.meta.action) {
      case "create":
        break;

      case "update":
        break;

      case "delete":
        break;

      default:
        break;
    }
  });
}

function amenitiesSocket() {
  socket.on("amenities", function (amenity) {
    console.log("amenities socket update", amenity);
    switch (amenity.meta.action) {
      case "create":
        break;

      case "update":
        break;

      case "delete":
        break;

      default:
        break;
    }
  });
}

function activitiesSocket() {
  socket.on("activities", function (activity) {
    console.log("activities socket update", activity);
    switch (activity.meta.action) {
      case "create":
        {
          const showAllBooking = checkPermission({ initial: "showAllBooking" });
          let params = {};
          if (showAllBooking) {
            const mActivity = activity?.data;
            const mSpaceId = mActivity?.space_id?._id || mActivity?.space_id;
            params.space_id = mSpaceId;
          }

          activity.filter = JSON.stringify(params);
          activity.data = [activity?.data];

          dispatch({
            type: REPORT_ACTIONS.FETCH_REPORTS_SUCCESS,
            payload: activity,
          });
        }
        break;

      default:
        break;
    }
  });
}

export {
  userSocket,
  spaceSocket,
  roleSocket,
  invoiceSocket,
  resourceSocket,
  planSocket,
  globalSocket,
  bookingSocket,
  attachmentSocket,
  amenitiesSocket,
  activitiesSocket,
};
