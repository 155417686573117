import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "antd";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import "./enterCardForm.scss";

const CARD_ELEMENT_OPTIONS = Object.freeze({
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Roboto", Helvetica, sans-serif',
      fontSize: "14px",
      "::placeholder": {
        color: "#aab7c4",
      },
      width: 300,
    },
  },
});

const EnterCardForm = (props) => {
  const [error, setError] = useState({});
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    props.setStripe(stripe);
    props.setElements(elements);
  }, [props]);

  const onChange = useCallback((event, type) => {
    setError((preState) => ({
      ...preState,
      [type]: event.error?.message,
    }));
  }, []);

  return (
    <Col className="--stripe-credit-card-form-container">
      <Col className="card-number-container mb20">
        <Col className="label">Credit card number</Col>

        <CardNumberElement
          className="stripe-input card-number-element fw"
          options={CARD_ELEMENT_OPTIONS}
          onChange={(e) => onChange(e, "CardNumberElement")}
        />

        <Col className="error mt5">{error.CardNumberElement}</Col>
      </Col>

      <Row className="mb20">
        <Col className="card-expiry-container mr15" span={8}>
          <Row className="label">Expiry</Row>

          <CardExpiryElement
            className="stripe-input card-number-element fw"
            options={CARD_ELEMENT_OPTIONS}
            onChange={(e) => onChange(e, "CardExpiryElement")}
          />
          <Col className="error mt5">{error.CardExpiryElement}</Col>
        </Col>

        <Col className="card-cvv-container mr15" span={8}>
          <Row className="label">CVV</Row>

          <CardCvcElement
            className="stripe-input card-number-element fw"
            options={CARD_ELEMENT_OPTIONS}
            onChange={(e) => onChange(e, "CardCvcElement")}
          />

          <Col className="error mt5">{error.CardCvcElement}</Col>
        </Col>
      </Row>
    </Col>
  );
};

export default EnterCardForm;
