import Actions from "./XeroActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";

const initialState = {
  xero: [],
  loading: false,
  error: null,
};

const xeroReducer = (state = initialState, action) => {
  let payload = action?.payload;

  switch (action.type) {
    /** fetch all xeros start  */

    case Actions.FETCH_ALL_XEROS: {
      return Object.assign({}, state, {
        error: null,
        loading: true,
      });
    }

    case Actions.FETCH_ALL_XEROS_SUCCESS: {
      return Object.assign({}, state, {
        xero: payload,
        loading: false,
      });
    }

    case Actions.FETCH_ALL_XEROS_FAILURE: {
      return Object.assign({}, state, {
        error: payload,
        loading: false,
      });
    }

    /** fetch all xeros end */

    default:
      return state;
  }
};

export default xeroReducer;
