import { Button, Col, Row } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";

import CustomModal from "../../utils/CustomModal";

const FaqQNAModal = (props) => {
  const { visible, selectedObj, showModal, isCMSEditable, handleQNA } = props;

  const modalTitle = useMemo(
    () => (isCMSEditable ? (selectedObj ? "Edit FAQ" : "Add FAQ") : "FAQ Item"),
    [isCMSEditable, selectedObj]
  );

  const [qnaObj, setQNAObj] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    setError({});
    setQNAObj({
      question: selectedObj?.question,
      answer: selectedObj?.answer,
    });
  }, [visible, showModal, selectedObj?.answer, selectedObj?.question]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value;
      value = e?.target?.value ?? e;
      setQNAObj((preState) => ({ ...preState, [name]: value }));
    },
    []
  );

  const hasError = useCallback(() => {
    let { question, answer } = qnaObj;
    question = question?.trim?.();
    answer = answer?.trim?.();

    const error = {};

    if (!question) {
      error.question = "Question cannot be blank";
    }

    if (!answer) {
      error.answer = "Answer cannot be blank";
    }

    setError(error);
    return Object.keys(error).length;
  }, [qnaObj]);

  const add = useCallback(() => {
    if (!hasError()) {
      let payload = { ...qnaObj };
      if (selectedObj && selectedObj.index !== undefined)
        payload.index = selectedObj.index;
      handleQNA(payload);
      showModal(false);
    }
  }, [handleQNA, hasError, qnaObj, selectedObj, showModal]);
  const disabled = !isCMSEditable;

  return (
    <CustomModal
      visible={visible}
      title={modalTitle}
      onCancel={showModal}
      width={700}
      footer={
        <>
          <Button onClick={showModal}>Close</Button>
          {isCMSEditable && (
            <Button type="primary" onClick={add}>
              {selectedObj ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      <div>
        {/* Question start */}
        <Row align="middle" className="mt10">
          <Col span={6} xs={24} sm={24} lg={6} xl={5} xxl={4}>
            <label className="label">Question </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18} xs={24} sm={24} lg={18} xl={19} xxl={20}>
            <ReactQuill
              placeholder="Yes! absolutely"
              theme="snow"
              value={qnaObj?.question}
              onChange={handleChange("question")}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline", "link"], // toggled buttons
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["clean"],
                ],
              }}
            />
            <Row className="error mt5">{error?.question}</Row>
          </Col>
        </Row>
        {/* Title end */}

        {/* Answer start */}
        <Row className="mt10">
          <Col span={6} xs={24} sm={24} lg={6} xl={5} xxl={4}>
            <label className="label">Answer</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col span={18} xs={24} sm={24} lg={18} xl={19} xxl={20}>
            <ReactQuill
              style={{
                height: "200px",
                marginBottom: "50px",
              }}
              placeholder="Yes! absolutely"
              theme="snow"
              value={qnaObj?.answer}
              onChange={handleChange("answer")}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline", "link"], // toggled buttons
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["clean"],
                ],
              }}
            />

            <Row className="error mt5">{error?.answer}</Row>
          </Col>
        </Row>
        {/* Answer end */}
      </div>
    </CustomModal>
  );
};

export default FaqQNAModal;
