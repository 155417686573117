import Actions from "./InvoiceActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";

const initialState = {
  invoiceMap: {},
  invoices: {},
};

const invoiceReducer = (state = initialState, action) => {
  let payload = action?.payload;

  switch (action.type) {
    /** fetch all invoices start  */

    case Actions.FETCH_ALL_INVOICES: {
      delete payload?.offset;
      delete payload?.limit;
      const mFilter = JSON.stringify(Object.assign({}, payload));
      return Object.assign({}, state, {
        invoices: Object.assign({}, state.invoices, {
          [mFilter]: Object.assign({}, state.invoices?.[mFilter], {
            error: null,
            loading: true,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_INVOICES_SUCCESS: {
      const mFilter = payload?.filter;
      const mInvoiceBoard = state.invoices?.[mFilter];

      let list = payload?.isReset ? [] : mInvoiceBoard?.list || [];
      list = [...getArrOrder(payload?.data), ...(list || [])];

      return Object.assign({}, state, {
        invoiceMap: Object.assign(
          {},
          state.invoiceMap,
          arrToObjMap(payload?.data)
        ),
        invoices: Object.assign({}, state.invoices, {
          [mFilter]: Object.assign({}, mInvoiceBoard, {
            list: [...new Set(list)],
            meta: Object.assign({}, mInvoiceBoard?.meta, payload?.meta),
            loading: false,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_INVOICES_FAILURE: {
      const mFilter = JSON.stringify(Object.assign({}, payload));
      return Object.assign({}, state, {
        invoices: Object.assign({}, state.invoices, {
          [mFilter]: Object.assign({}, state.invoices[mFilter], {
            error: payload,
            loading: false,
          }),
        }),
      });
    }

    /** fetch all invoices end */

    default:
      return state;
  }
};

export default invoiceReducer;
