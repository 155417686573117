import React from "react";
import { Col, Row, Input } from "antd";

const ImageSize = ({ state, handleChange, disabledInput }) => {
  return (
    <>
      <Row align="top" className="mb15">
        <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
          <label className="label">Upload Images Size</label>
          {/* <sup style={{ color: "red" }}>*</sup> */}
        </Col>

        <Row align="middle" className="mb15" justify="start">
          <Col span={10}>
            <Input
              name="widthData"
              placeholder="Image Width"
              value={state?.imageWidth}
              disabled={disabledInput}
              onChange={handleChange("imageWidth")}
            />
          </Col>
          <Col span={2} style={{ textAlign: "center" }}>
            x
          </Col>
          <Col span={10}>
            <Input
              name="heightData"
              placeholder="Image Height"
              value={state?.imageHeight}
              disabled={disabledInput}
              onChange={handleChange("imageHeight")}
            />
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default React.memo(ImageSize);
