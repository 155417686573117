import Actions from "./MemberActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";

const initialState = {
  memberMap: {},
  members: {},
};

const membersReducer = (state = initialState, action) => {
  let payload = Object.assign({}, action?.payload);

  switch (action.type) {
    /** fetch all members start */

    case Actions.FETCH_ALL_MEMBERS: {
      delete payload?.offset;
      delete payload?.limit;
      const mFilter = JSON.stringify(Object.assign({}, payload));
      return Object.assign({}, state, {
        members: Object.assign({}, state.members, {
          [mFilter]: Object.assign({}, state.members?.[mFilter], {
            error: null,
            loading: true,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_MEMBERS_SUCCESS: {
      const mFilter = payload?.filter;
      const mMemberBoard = state.members?.[mFilter];

      let list = payload?.isReset ? [] : mMemberBoard?.list || [];
      list = [...(list || []), ...getArrOrder(payload?.data)];

      return Object.assign({}, state, {
        memberMap: Object.assign(
          {},
          state.memberMap,
          arrToObjMap(payload?.data)
        ),
        members: Object.assign({}, state.members, {
          [mFilter]: Object.assign({}, mMemberBoard, {
            list: [...new Set(list)],
            meta: Object.assign({}, mMemberBoard?.meta, payload?.meta),
            loading: false,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_MEMBERS_FAILURE: {
      const mFilter = JSON.stringify(Object.assign({}, payload));

      return Object.assign({}, state, {
        members: Object.assign({}, state.members, {
          [mFilter]: Object.assign({}, state.members[mFilter], {
            error: payload,
            loading: false,
          }),
        }),
      });
    }

    /** fetch all members end */

    /** add member start */

    case Actions.ADD_MEMBER: {
      return Object.assign({}, state, {
        error: null,
        adding: true,
      });
    }

    case Actions.ADD_MEMBER_SUCCESS: {
      const mMember = payload?.data;
      const mFilter = payload?.filter;

      const mMemberBoard = state.members?.[mFilter] || {};
      let list = [...(mMemberBoard?.list || [])];

      return Object.assign({}, state, {
        memberMap: Object.assign({}, state.memberMap, {
          [mMember?._id]: mMember,
        }),
        members: Object.assign({}, state.members, {
          [mFilter]: {
            list: [...new Set([mMember?._id, ...list])],
            meta: Object.assign({}, mMemberBoard?.meta, {
              totalCount: (mMemberBoard?.meta?.totalCount || 0) + 1,
            }),
          },
        }),
        adding: false,
      });
    }

    case Actions.ADD_MEMBER_FAILURE: {
      return Object.assign({}, state, {
        error: action?.payload,
        adding: false,
      });
    }
    /** add member end */

    /** update member start */
    case Actions.UPDATE_MEMBER: {
      return Object.assign({}, state, {
        error: null,
        loading: true,
      });
    }

    case Actions.UPDATE_MEMBER_SUCCESS: {
      let updatedMember = payload?.data;

      return Object.assign({}, state, {
        memberMap: Object.assign({}, state.memberMap, {
          [updatedMember?._id]: updatedMember,
        }),

        loading: false,
      });
    }

    case Actions.UPDATE_MEMBER_FAILURE: {
      return Object.assign({}, state, {
        error: action?.payload,

        loading: false,
      });
    }

    /** add member end */

    default:
      return state;
  }
};

export default membersReducer;
