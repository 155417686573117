import React, { useMemo } from "react";
import {
  Card,
  Image,
  message,
  Tooltip,
  Typography,
  Button,
  Popconfirm,
} from "antd";
import { useSelector } from "react-redux";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import noImage from "../../assets/images/no-image.png";
import { getTagFreeContent } from "../../utils/CommonFunctions";

const { Paragraph } = Typography;

const OurSpaceCard = (props) => {
  const { id, isCMSEditable, showExtra = true } = props;

  const mCms = useSelector(({ cms }) => cms.cmsMap[id]);
  const imgSrc = useMemo(
    () =>
      mCms?.pictures?.find((picture) => picture.type === "thumbnail")
        ?.thumb_location || noImage,
    [mCms?.pictures]
  );

  const ExtraIcon = useMemo(
    () => (isCMSEditable ? EditOutlined : EyeOutlined),
    [isCMSEditable]
  );

  if (!mCms) {
    return <></>;
  }

  const deleteOurSpaces = () => {
    console.log(id);
    props.deleteOurSpaces(id);
  };

  return (
    <Card
      className="cms-card"
      title={getTagFreeContent(mCms?.name)}
      // bordered={false}
      hoverable
      extra={
        showExtra && (
          <>
            {isCMSEditable && (
              <Popconfirm
                title="Are you sure you want to delete this space?"
                onConfirm={deleteOurSpaces}
                onCancel={() => null}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined style={{ fontSize: 12, marginRight: 10 }} />
              </Popconfirm>
            )}
            <Tooltip title={isCMSEditable ? "Edit" : "More Details"}>
              <ExtraIcon style={{ fontSize: 12 }} onClick={props?.showModal} />
            </Tooltip>
          </>
        )
      }
      cover={
        <Image
          className="cover new_image-fluid"
          preview={false}
          width={"100%"}
          height={"100%"}
          wrapperStyle={{ height: 150 }}
          src={imgSrc}
        />
      }
    >
      <Paragraph ellipsis={{ rows: 2 }}>
        {mCms?.description
          ? getTagFreeContent(mCms?.description)
          : "--- --- ---"}
      </Paragraph>
    </Card>
  );
};

export default React.memo(OurSpaceCard);
