import React, { useState } from "react";
import { Col, Row, Button, Input, Popover, Slider } from "antd";
import banner2 from "../../images/banner_img_2.png";
import { useLocation } from "react-router";


const SelectCapacityScreen = (props) => {
  const [capacity, setCapacity] = useState("");
  const { search } = useLocation();

  const onNext = () => {
    capacity && props.onNext && props.onNext(`${search}&capacity=${capacity}`);
  };

  return (
    <>
      <Row className="spacerTBMain">
        <Col xs={24} md={12}>
          <div className="leftpanelContainer">
            <h2>
              2.{" "}
              {props?.resource_type === "meeting_room"
                ? "What is the capacity of meeting room you are looking for ?"
                : "How many desks are you looking for?"}
            </h2>
            <div className="li_c_b">
              <div className="inputContainer">
                <div className="i_i_c_b">
                  <span className="s_c_d_b">
                    <i>&nbsp;</i>
                  </span>

                  <Input
                    style={{ borderRadius: "0px", border: "none" }}
                    placeholder="Capacity"
                    value={capacity}
                    onChange={(e) => setCapacity(e.target.value)}
                    readOnly
                  />
                </div>
                <div className="d_c_b_m_d">
                  {[...new Array(20)].map((_, i) => (
                    <span
                      className={
                        "d_b_cs " +
                        (capacity && capacity > 0 && capacity === i + 1
                          ? "active"
                          : "")
                      }
                      key={i}
                      onClick={() => setCapacity(i + 1)}
                    >
                      {i + 1}
                    </span>
                  ))}
                  <span
                    onClick={() => setCapacity(21)}
                    className={`d_b_cs ${
                      capacity && capacity > 20 ? "active" : ""
                    }`}
                  >
                    20+
                  </span>
                </div>
                {capacity > 20 && (
                  <div className="slider-container">
                    <span>Custom desks number(starts from 21+)</span>
                    <Slider
                      min={21}
                      max={100}
                      defaultValue={21}
                      onChange={setCapacity}
                      marks={{21:'21',100:'100'}}
                    />
                  </div>
                )}
              </div>

              <div className="btn_s_c">
                <div className="spacerdata">
                  <Button type="primary" onClick={onNext} disabled={!capacity}>
                    Next
                  </Button>
                  <Button onClick={props?.onBack}>Back</Button>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} md={12}>
          <div className="bannercontainer">
            <img src={banner2} alt="Banner" />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SelectCapacityScreen;
