import { useState, useCallback } from "react";
import { Button, Input, Row, Col, Typography } from "antd";
import { Link, Redirect } from "react-router-dom";
import {
  isUserAuthenticated,
  validateEmail,
  showNotification,
} from "../utils/CommonFunctions";
import AppUrl from "../config/AppUrl";
import axios from "axios";
import forgetSvg from "../assets/images/forget.jpg";
import { ArrowLeftOutlined } from "@ant-design/icons";
// import logo from "../assets/svg/logo.svg";
import loginSvg from "../assets/svg/logo_full.svg";

import AuthBgContainer from "./components/AuthBgContainer";

import Routes from "../config/Routes";

import "../assets/css/login.scss";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [err, handleError] = useState({ email: null });
  const [loading, setLoader] = useState(false);

  const forgotPassword = useCallback(() => {
    if (validateEmail(email)) {
      forgotPasswordRequest({ email });
    } else {
      let emailErr = null;

      if (email?.trim() === "") {
        emailErr = "Email cannot be blank";
      } else if (!validateEmail(email)) {
        emailErr = "Email is not valid";
      }

      handleError({ email: emailErr });
    }
  }, [email]);

  const forgotPasswordRequest = async () => {
    setLoader(true);
    try {
      const userRes = await axios.post(AppUrl.FORGOT_PASSWORD, { email });
      let userData = userRes.data;
      if (userData?.meta?.success === false || userData?.meta?.status !== 200) {
        showNotification("error", userData?.meta?.message);
        setLoader(false);
      } else {
        showNotification(
          "success",
          "Successfully sent reset link to the registered email."
        );
        setLoader(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoader(false);
    }
  };

  if (isUserAuthenticated()) {
    return <Redirect to={Routes.dashboard} />;
  }

  return (
    <AuthBgContainer
      title={"Forgot Password?"}
      subtitle={
        "Enter your registered email and we'll send you instructions to reset your password"
      }
    >
      <Col className="mb20">
        <Col className="label mt5 mb5">Email</Col>

        <Input
          placeholder="user@cockpit.com"
          size="large"
          allowClear
          onChange={(e) => {
            setEmail(e.target.value);
            handleError({ email: null });
          }}
          value={email}
          onPressEnter={forgotPassword}
          autoFocus
        />
        <Col className="error mt5">{err.email}</Col>
      </Col>

      <Button
        type="primary"
        size="large"
        block
        onClick={forgotPassword}
        loading={loading}
        className="btn_r_l_cb"
      >
        Send reset link
      </Button>

      <Row className="mt20" justify="center">
        <Link className="btn-link" to={Routes.login}>
          Back to login
        </Link>
      </Row>
    </AuthBgContainer>
  );
};

export default ForgotPassword;
