import React, { useEffect, useRef } from "react";
import { Row, Col, Image, Tooltip, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import constants from "../../utils/Constants";

const { FALLBACK_IMG } = constants;

const MUploadItem = (props) => {
  const { file, actions, handleDragOver, handleDrag, handleDrop } = props;
  const handleChange = (name) => (event) => {
    const value = event?.target?.value;
    file[name] = value;
    props?.handleTagChange?.(file);
  };
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    console.log("available", video);
    if (video) {
      const startTime = 1;
      video.currentTime = startTime;
    }
  }, [file]);
  return (
    <Col
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragStart={handleDrag}
      id={file._id || file.uid}
      draggable={true}
      span={24}
      className="mUploadItem mb10"
    >
      <Row align="middle" justify="space-between">
        <Col span={6}>
          <Row className="pr5" align="middle">
            {file?.thumb ? (
              <Col onClick={() => window.open(file?.url)}>{file?.thumb}</Col>
            ) : (
              <>
                {file.mimetype === "video/mp4" &&
                "video/webm" &&
                "video/ogg" &&
                "video/avi" &&
                "video/quicktime" ? (
                  <video
                    ref={videoRef}
                    className="thumb-img"
                    height={44}
                    width={104}
                    onClick={() => window.open(file?.url)}
                  >
                    <source src={file?.url} type="video/mp4" />
                  </video>
                ) : (
                  <Image
                    className="thumb-img"
                    height={44}
                    width={104}
                    src={file?.thumb_location || FALLBACK_IMG}
                    fallback={FALLBACK_IMG}
                    onClick={() =>
                      file.mimetype === "application/pdf" &&
                      window.open(file?.url)
                    }
                    preview={
                      !props.disabled &&
                      file.mimetype !== "application/pdf" && {
                        src: file?.url,
                      }
                    }
                  />
                )}
              </>
            )}
          </Row>
        </Col>

        <Col span={18}>
          <Row className="input-container" align="middle">
            <Row className="fw mr15">
              <Input
                className="custom-input"
                placeholder="Enter alt tag"
                value={file?.tag}
                onChange={handleChange("tag")}
                disabled={props.disabled}
              />
            </Row>

            {!props.disabled && (
              <Row>
                <Tooltip title="Remove File">
                  <DeleteOutlined onClick={actions?.remove} disabled />
                </Tooltip>
              </Row>
            )}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(MUploadItem);
