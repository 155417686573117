import React, { useEffect, useMemo, useState, useId, useCallback } from "react";
import { Button, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Spinner from "../spinner/Spinner";
import PageMetaModal from "./components/PageMetaModal";

import NoDataFound from "../utils/NoDataFound";

import { getAllCMS } from "./duck/CMSActions";
import { checkPermission } from "../utils/CommonFunctions";
import FoldCard from "./components/FoldCard";
import Routes from "../config/Routes";
import constants from "../utils/Constants";

const { CMS_TYPES } = constants;

const ServicePage = (props) => {
  const {
    title,
    show_title = true,
    cms_type,
    page_name,
    page_id,
    path,
    show_edit_meta_details = true,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const [boardKey, setBoardKey] = useState();
  const [showPageMetaModal, setShowPageMetaModal] = useState(false);

  const pageMetaBoardParams = useMemo(
    () => ({ type: CMS_TYPES.PAGE_META, page_name }),
    [page_name]
  );

  const { cmsBoard, mPageMetaId } = useSelector(({ cms }) => {
    const boards = cms.boards;
    const cmsBoard = boards[JSON.stringify(boardKey)];
    const mPageMetaId = boards[JSON.stringify(pageMetaBoardParams)]?.list?.[0];

    return { cmsBoard, mPageMetaId };
  });

  const isCMSEditable = useMemo(() => checkPermission({ row: "cms" }), []);

  useEffect(() => {
    const params = { type: cms_type };
    if (page_id) {
      params.page_name = page_id;
    }

    setBoardKey(params);
    dispatch(getAllCMS(params));

    if (show_edit_meta_details) {
      dispatch(getAllCMS(pageMetaBoardParams));
    }
  }, [
    show_edit_meta_details,
    page_id,
    cms_type,
    dispatch,
    pageMetaBoardParams,
  ]);

  const loading = cmsBoard?.loading && !cmsBoard?.list?.length;

  const showFoldDetails = useCallback(
    (id = "") => {
      history.push(`${Routes.editFoldPage}/${id}`, {
        title,
        page_name: page_id,
        cms_type,
        returnPath: path,
      });
    },
    [history, title, page_id, cms_type, path]
  );

  return loading ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb10">
        <span className="nw_heading">{show_title && title}</span>

        <span>
          {show_edit_meta_details && isCMSEditable && (
            <Button
              type="primary-outline mr10"
              onClick={() => setShowPageMetaModal(true)}
            >
              Edit Page Meta
            </Button>
          )}

          {isCMSEditable && (
            <Button
              type="primary-outline"
              // onClick={() =>
              //   history.push(Routes.editFoldPage, {
              //     page_name: page_id,
              //     cms_type,
              //     returnPath: path,
              //     title,
              //   })
              // }
              onClick={() => showFoldDetails()}
            >
              + Add New Fold
            </Button>
          )}
        </span>
      </Row>
      {!cmsBoard?.list?.length && (
        <Row align="center">
          <NoDataFound />
        </Row>
      )}

      <Row>
        {cmsBoard?.list?.map((id, i) => (
          <FoldCard
            key={id}
            id={id}
            pageName={page_id}
            cmsType={cms_type}
            returnPath={path}
            isCMSEditable={isCMSEditable}
            foldTitle={title}
            showDetails={() => showFoldDetails(id)}
          />
        ))}
      </Row>
      <PageMetaModal
        visible={showPageMetaModal}
        selectedId={mPageMetaId}
        showModal={() => setShowPageMetaModal(false)}
        isCMSEditable={isCMSEditable}
        pageName={page_name}
      />
    </div>
  );
};

export default ServicePage;
