import React from "react";
import { Select } from "antd";
import { connect } from "react-redux";

const { Option } = Select;

class SearchInput extends React.Component {
  state = {
    data: [],
    value: undefined,
  };

  componentDidMount() {
    let { workSpace } = this.props;
    this.setState({ data: workSpace });
  }

  componentDidUpdate(preProps) {
    let { workSpaces } = this.props;
    if (preProps.workSpaces !== workSpaces && workSpaces?.length > 0) {
      this.setState({ data: workSpaces });
    }
  }

  handleSearch = (value) => {
    let { workSpaces = [] } = this.props;
    if (value && workSpaces?.length > 0) {
      this.setState({
        data: workSpaces?.filter((ws) => ws.text.toLowerCase().includes(value)),
      });
    } else {
      this.setState({ data: workSpaces });
    }
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  render() {
    let { data = [] } = this.state;
    const options = data.map((d) => <Option key={d.value}>{d.text}</Option>);
    return (
      <Select
        showSearch
        value={this.props.value}
        placeholder={this.props.placeholder}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={this.handleSearch}
        onChange={this.props.onChange}
        notFoundContent={null}
      >
        {options}
      </Select>
    );
  }
}

const mapStateToProps = (state) => {
  let { active, workSpaceMap } = state.workSpace;
  let workSpaces = Object.keys(workSpaceMap)
    ?.filter((id) => workSpaceMap?.[id]?.active)
    ?.map((id) => ({
      value: id,
      text: workSpaceMap[id]?.name,
    }));

  return {
    workSpaces: workSpaces,
    activeWorkSpace: active,
  };
};

export default connect(mapStateToProps)(SearchInput);
