export const roleColumns = () => {
  const columns = [
    {
      title: "S.no",
      dataIndex: "sn",
      key: "sn",
      width: 10,
      align: "center",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 200,
      align: "center",
    },
  ];
  return columns;
};
