import Actions from './XeroActionTypes'

export const getAllXeros = (params = {}) => {
    return {
        type: Actions.FETCH_ALL_XEROS,
        payload: params,
    }
}

export const getXero = (id) => {
    return {
        type: Actions.FETCH_XERO,
        payload: id,
    }
}
