import {
  Button,
  Checkbox,
  Col,
  Input,
  Popconfirm,
  Popover,
  Row,
  Select,
  Tooltip,
  Upload,
} from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";

import CustomModal from "../../utils/CustomModal";
import MUploadItem from "./MUploadItem";

import { createCMS, updateCMS } from "../duck/CMSActions";

import AppUrl from "../../config/AppUrl";
import { showNotification } from "../../utils/CommonFunctions";
import SvgIcons from "../../utils/SvgIcon";
import constants from "../../utils/Constants";
import { amenitieIconMap } from "../../utils/AmenitieIcons";

const { CMS_TYPES } = constants;

const initialState = {
  keyFacilities: [],
  includeInPriceFacilities: [],
  facilities: [],

  thumbnails: [],
  banners: [],
  images: [],
  floorPlans: [],
};

const OfferDetailsModal = (props) => {
  const prevProp = useRef();
  const dispatch = useDispatch();

  const { visible, selectedId, showModal, isCMSEditable } = props;

  const cms = useSelector(({ cms }) => cms);
  const mCms = useMemo(
    () =>
      selectedId
        ? cms?.cmsMap?.[selectedId]
        : { loading: cms?.loading, error: cms?.error },
    [selectedId, cms]
  );

  const [state, setState] = useState(initialState);
  const [error, setError] = useState({});
  const [dragId, setDragId] = useState();

  const modalTitle = useMemo(
    () =>
      isCMSEditable
        ? selectedId
          ? "Edit Offer Details"
          : "Add Offer Details"
        : "Offer Details",
    [isCMSEditable, selectedId]
  );

  useEffect(() => {
    if (prevProp?.current?.loading && !mCms?.loading) {
      setState(initialState);
      showModal?.(false);
    } else if (!prevProp?.current?.visible && visible) {
      let mState = initialState;

      if (selectedId) {
        const thumbnails = [];
        const banners = [];
        const floorPlans = [];
        const images = [];

        const meta = mCms?.meta || {};
        const pageMeta = meta?.pageMeta;

        if (mCms?.pictures?.length > 0) {
          mCms.pictures?.forEach((file, i) => {
            if (file.type === "thumbnail") {
              thumbnails.push({ ...file });
            } else if (file.type === "banner") {
              banners.push({ ...file });
            } else if (file.type === "floor_plan") {
              floorPlans.push({ ...file });
            } else if (file.type === "image") {
              images.push({ ...file });
            }
          });
        }

        mState = {
          metaTitle: pageMeta?.title,
          metaDescription: pageMeta?.description,

          name: mCms?.name,
          description: mCms?.description,
          subheading: meta?.subheading,
          actual_price: meta?.actual_price,
          discounted_price: meta?.discounted_price,

          offer_name: meta?.offerTag?.name,
          discount_percent: meta?.offerTag?.discount_percent,

          ribbon_line_1: meta?.ribbonTag?.line_1,
          ribbon_line_2: meta?.ribbonTag?.line_2,

          keyFacilities: meta?.keyFacilities || [],
          includeInPriceFacilities: meta?.includeInPriceFacilities || [],
          facilities: meta?.facilities || [],

          thumbnails,
          banners,
          floorPlans,
          images,

          active: mCms?.active,
        };
      }

      setState(mState);
      setError({});
    }

    return () => {
      prevProp.current = {
        loading: mCms?.loading,
        visible,
      };
    };
  }, [selectedId, mCms, visible, showModal]);

  // console.log(state, "state data");

  const beforeUploadFloorPlan = useCallback((file) => {
    const supportedFileType = ["application/pdf"];

    const isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification("error", "Please upload PDF file only");
    }

    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const uploadFloorPlan = useCallback(({ file }) => {
    const fileObj = {
      uid: file?.uid,
      name: file?.name,
      size: file?.size,
      type: file?.type,
      thumb: SvgIcons.Pdf,
      url: URL.createObjectURL(file),
      tag: "",
      file,
    };

    setError({});
    setState((prevState) =>
      Object.assign({}, prevState, {
        floorPlans: [...prevState.floorPlans, fileObj],
      })
    );
  }, []);

  const onRemoveFloorPlan = useCallback((file) => {
    const fileId = file?._id || file?.uid;

    setState((prevState) =>
      Object.assign({}, prevState, {
        floorPlans: prevState.floorPlans.filter(
          (p) => (p?._id || p?.uid) !== fileId
        ),
      })
    );
  }, []);

  const beforeUploadImage = useCallback((file) => {
    const supportedFileType = ["image/png", "image/jpg", "image/jpeg"];

    let isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification(
        "error",
        "Please upload JEPG, JGP, PNG or GIF file only"
      );
    }
    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const onSelectImage = useCallback(
    (name) =>
      ({ file }) => {
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = function () {
          const width = img.naturalWidth,
            height = img.naturalHeight;

          URL.revokeObjectURL(img.src);
          if (width === 1440 && height === 820) {
            const fileObj = {
              uid: file?.uid,
              name: file?.name,
              size: file?.size,
              type: file?.type,
              thumb_location: URL.createObjectURL(file),
              url: URL.createObjectURL(file),
              tag: "",
              file,
            };

            setError({});
            setState((preState) => {
              return Object.assign({}, preState, {
                [name]: [
                  ...preState?.[name],
                  { ...fileObj, extra: { order: preState?.[name].length } },
                ],
                // Object.assign({}, a, {img: [...a.img, {...file, extra: 1}]})
              });
            });
          } else {
            showNotification(
              "error",
              "Please upload exact image size of 1440x820"
            );
          }
        };
      },
    []
  );

  // [name]: [...preState?.[name], fileObj],

  const onRemoveImage = useCallback(
    (name) => (file) => {
      const fileId = file?._id || file?.uid;
      setState((preState) => {
        let images = preState?.[name] || [];
        images = images
          .filter?.((p) => (p?._id || p?.uid) !== fileId)
          ?.map((file, i) => ({
            ...file,
            extra: { ...file?.extra, order: i },
          }));

        return Object.assign({}, preState, {
          [name]: images,
        });
      });
    },
    []
  );

  const handleTagChange = useCallback(
    (name) => (file) => {
      setState((pervState) => {
        const attachments = pervState[name];
        attachments?.map?.((attachment) =>
          attachment?._id === file._id || attachment?.uid === file.uid
            ? file
            : attachment
        );

        return (pervState = Object.assign({}, pervState, {
          [name]: attachments,
        }));
      });
      setError({});
    },
    []
  );

  const handleChange = useCallback(
    (name, isCheckbox) => (e) => {
      let value;
      if (isCheckbox) {
        value = e?.target?.checked;
      } else {
        value = e?.target?.value ?? e;
      }

      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const onAddFacilities = useCallback(
    (name) => () => {
      setState((preState) => {
        const services = preState[name] || [];

        return Object.assign({}, preState, {
          [name]: [...services, {}],
        });
      });
      setError({});
    },
    []
  );

  const onRemoveFacilities = useCallback(
    (name) => (i) => {
      setState((preState) => {
        const services = preState[name] || [];
        services.splice(i, 1);

        return Object.assign({}, preState, {
          [name]: [...services],
        });
      });
      setError({});
    },
    []
  );

  const handleFacilitieDetailsChange = useCallback(
    (name) =>
      (i = 0, valueKey = "") =>
      (e) => {
        const value = e?.target?.value ?? e;

        setState((preState) => {
          const services = preState[name] || [];
          services[i][valueKey] = value;

          return Object.assign({}, preState, {
            [name]: [...services],
          });
        });
        setError({});
      },
    []
  );

  const hasError = useCallback(() => {
    let {
      metaTitle,
      metaDescription,

      name,
      description,
      subheading,
      actual_price,
      discounted_price,

      offer_name,
      discount_percent,

      ribbon_line_1,
      ribbon_line_2,

      keyFacilities,
      includeInPriceFacilities,
      facilities,

      thumbnails,
      banners,
      images,
      floorPlans,
    } = state;
    metaTitle = metaTitle?.trim?.();
    metaDescription = metaDescription?.trim?.();

    name = name?.trim?.();
    description = description?.trim?.();

    const error = {};

    if (!metaTitle) error.metaTitle = "Title cannot be blank";
    if (!metaDescription) error.metaDescription = "Description cannot be blank";

    if (!name) error.name = "Name cannot be blank";
    if (!description) error.description = "Description cannot be blank";
    if (!subheading) error.subheading = "Description cannot be blank";
    if (!actual_price) error.actual_price = "Actual price cannot be blank";
    if (!discounted_price)
      error.discounted_price = "Discounted price cannot be blank";

    if (!offer_name) error.offer_name = "Name cannot be blank";
    if (!discount_percent)
      error.discount_percent = "Discount Percent cannot be blank";

    if (!ribbon_line_1) error.ribbon_line_1 = "Line 1 cannot be blank";
    if (!ribbon_line_2) error.ribbon_line_2 = "Line 2 cannot be blank";

    if (!thumbnails?.length) error.thumbnails = "Please upload thumbnail";
    if (!banners?.length) error.banners = "Please upload banner image";
    if (!images?.length) error.images = "Please upload atleast one image";
    if (!floorPlans?.length) error.floorPlans = "Please upload floor plan";

    if (!keyFacilities?.length) {
      error.keyFacilitie = "Please add atleast one Facilitie";
    } else {
      const facilitieErrors = [];
      keyFacilities.forEach((facilitie, i) => {
        if (!facilitie?.name) {
          facilitieErrors[i] = "Facilitie name cannot be blank";
        }
      });

      if (facilitieErrors.length > 0) {
        error.keyFacilities = facilitieErrors;
      }
    }

    if (!includeInPriceFacilities?.length) {
      error.includeInPriceFacilitie = "Please add atleast one Facilitie";
    } else {
      const facilitieErrors = [];
      includeInPriceFacilities.forEach((facilitie, i) => {
        if (!facilitie?.name) {
          facilitieErrors[i] = "Facilitie name cannot be blank";
        }
      });

      if (facilitieErrors.length > 0) {
        error.includeInPriceFacilities = facilitieErrors;
      }
    }

    if (!facilities?.length) {
      error.facilitie = "Please add atleast one Facilitie";
    } else {
      const facilitieErrors = [];
      facilities.forEach((facilitie, i) => {
        if (!facilitie?.icon) {
          facilitieErrors[i] = "Please Select Icon";
        } else if (!facilitie?.name) {
          facilitieErrors[i] = "Facilitie name cannot be blank";
        }
      });

      if (facilitieErrors.length > 0) {
        error.facilitie = facilitieErrors;
      }
    }

    setError(error);
    return Object.keys(error).length;
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const {
        metaTitle,
        metaDescription,

        name,
        description,
        subheading,
        actual_price,
        discounted_price,

        offer_name,
        discount_percent = 0,

        ribbon_line_1,
        ribbon_line_2,

        keyFacilities = [],
        includeInPriceFacilities = [],
        facilities = [],

        thumbnails = [],
        banners = [],
        images = [],
        floorPlans = [],

        active,
      } = state;
      const pageMeta = { title: metaTitle, description: metaDescription };
      const payload = {
        name,
        description,
        active,
        thumbnail: [],
        banner: [],
        floor_plan: [],
        image: [],
        extra: [],
        tags: {
          thumbnail: [],
          banner: [],
          floor_plan: [],
          image: [],
        },
        meta: JSON.stringify({
          pageMeta,

          subheading,
          actual_price,
          discounted_price,

          keyFacilities,
          includeInPriceFacilities,
          facilities,
          offerTag: {
            name: offer_name,
            discount_percent: discount_percent,
          },
          ribbonTag: {
            line_1: ribbon_line_1,
            line_2: ribbon_line_2,
          },
        }),
        type: CMS_TYPES.OFFER,
      };

      thumbnails.forEach((picture) => {
        if (picture?.file) {
          payload.thumbnail.push(picture?.file);
          payload.tags.thumbnail.push(picture?.tag || "");
        }
      });
      banners.forEach((picture) => {
        if (picture?.file) {
          payload.banner.push(picture?.file);
          payload.tags.banner.push(picture?.tag || "");
        }
      });

      floorPlans.forEach((floorPlan) => {
        if (floorPlan?.file) {
          payload.floor_plan.push(floorPlan?.file);
          payload.tags.floor_plan.push(floorPlan?.tag || "");
        }
      });

      images.forEach((picture, i) => {
        if (picture?.file) {
          payload.image.push(picture?.file);
          payload.tags.image.push(picture?.tag || "");
          payload.extra.push({ ...picture?.extra, order: i } || "");
        }
      });
      payload.tags = JSON.stringify(payload.tags);
      payload.extra = JSON.stringify(payload.extra);

      if (selectedId) {
        payload._id = selectedId;
        const allAttachments = [
          ...thumbnails,
          ...banners,
          ...floorPlans,
          ...images,
        ];

        //const imagesAttachments = [...images];
        const oldFileListIds = mCms?.pictures?.map?.(
          (file) => file?._id || file?.uid
        );
        const currentUploadedFileListIds = allAttachments
          .filter((file) => !file?.file && file?._id)
          .map((file) => file?._id || file?.uid);

        const removedUploadedFileIds = oldFileListIds?.filter?.(
          (id) => !currentUploadedFileListIds?.includes(id)
        );

        removedUploadedFileIds.forEach((id) => {
          axios({
            method: "DELETE",
            url: `${AppUrl.ATTACHMENTS}/${id}`,
          });
        });

        /* imagesAttachments.forEach((file, index) => {
          if (file?._id) {
            const mTag = file.tag?.trim?.();
            const extra = { order: index, ...file?.extra };
            const mPicture = mCms?.pictures?.find((p) => p?._id === file?._id);

            if (mTag !== mPicture.tag) {
              const data = {
                tag: mTag,
              };

              console.log(data, "data");
              debugger;
              axios({
                method: "PUT",
                url: `${AppUrl.ATTACHMENTS}/${file?._id}`,
                data,
              });
            }
          }
        }); */

        allAttachments.forEach((file, index) => {
          if (file?._id) {
            const mTag = file.tag?.trim?.();
            const mPicture = mCms?.pictures?.find((p) => p?._id === file?._id);
            const extra = { order: index, ...file?.extra };

            /* mTag !== mPicture.tag ||
              index !== file?.extra?.order ||
              JSON.stringify(extra) !== JSON.stringify(mPicture?.extra) */
            if (
              mTag !== mPicture.tag ||
              index !== file?.extra?.order ||
              JSON.stringify(extra) !== JSON.stringify(mPicture?.extra)
            ) {
              const data = {
                tag: mTag,
                extra,
              };

              axios({
                method: "PUT",
                url: `${AppUrl.ATTACHMENTS}/${file?._id}`,
                data,
              });
            }
          }
        });

        dispatch(updateCMS(payload));
      } else {
        dispatch(createCMS(payload));
      }
    }
  }, [mCms?.pictures, selectedId, state, hasError, dispatch]);

  const SelectIcon = useCallback(
    (props) => (
      <Select
        className="custom-select-input-icon"
        style={{ width: 300 }}
        placeholder="Select Icon"
        size="large"
        showSearch
        {...props}
      >
        {Object.values(amenitieIconMap).map((icon, i) => (
          <Select.Option
            className="custom-select-input-icon-option"
            key={i}
            value={icon._id}
          >
            <Row className="select-icon-option" align="middle">
              <Col span={4}>
                <Row align="middle" justify="center">
                  <img
                    className="icon-img"
                    src={`/images/icons/${icon.icon}`}
                    alt=""
                  />
                </Row>
              </Col>

              <Col className="icon-name pl10 pr15" span={20}>
                {icon.name}
              </Col>
            </Row>
          </Select.Option>
        ))}
      </Select>
    ),
    []
  );

  const handleDragOver = (ev) => {
    ev.preventDefault();
  };

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const file = state.images;
    const dragBox = file.find((box) => {
      const id = box._id || box.uid;
      return id === dragId;
    });

    const dropBox = file.find((box) => {
      const id = box._id || box.uid;
      return id === ev.currentTarget.id;
    });

    const dragBoxOrder = dragBox?.extra?.order;
    const dropBoxOrder = dropBox?.extra?.order;
    const newBoxState = file.map((box) => {
      const id = box._id || box.uid;
      if (id === dragId) {
        box.extra.order = dropBoxOrder;
      }
      if (id === ev.currentTarget.id) {
        box.extra.order = dragBoxOrder;
      }
      return box;
    });

    setState((prevState) => {
      return {
        ...prevState,
        images: newBoxState,
      };
    });
  };

  const disabled = !isCMSEditable || mCms?.loading;

  if (!visible) return null;

  return (
    <CustomModal
      className="cms-modal"
      visible={visible}
      title={modalTitle}
      onCancel={props?.showModal}
      footer={
        <>
          <Button onClick={props?.showModal}>Close</Button>

          {isCMSEditable && (
            <Button type="primary" loading={mCms?.loading} onClick={onSubmit}>
              {selectedId ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      <Col className="content">
        {/* page meta details start */}
        <>
          <Row className="section mb10" align="middle" justify="center">
            Page Meta Details
          </Row>

          {/* title start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Title</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input
                className="custom-input"
                placeholder="Title"
                size="large"
                type="text"
                value={state.metaTitle}
                onChange={handleChange("metaTitle")}
                disabled={disabled}
              />

              <Row className="error mt5">{error.metaTitle}</Row>
            </Col>
          </Row>
          {/* title end */}

          {/* description start */}
          <Row className="mb10">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Description </label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input.TextArea
                className="custom-input"
                placeholder="Description"
                size="large"
                type="text"
                value={state.metaDescription}
                onChange={handleChange("metaDescription")}
                autoSize={{ minRows: 2, maxRows: 3 }}
                disabled={disabled}
              />
              <Row className="error mt5">{error.metaDescription}</Row>
            </Col>
          </Row>
          {/* description end */}
        </>
        {/* page meta details end */}

        {/* offer details start */}
        <>
          <Row className="section mb10" align="middle" justify="center">
            Offer Details
          </Row>

          {/* name start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Name</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input
                className="custom-input fw mt10"
                placeholder="Name"
                size="large"
                allowClear
                value={state.name}
                onChange={handleChange("name")}
                disabled={disabled}
              />
              <Row className="error mt5">{error.name}</Row>
            </Col>
          </Row>
          {/* name end */}

          {/* description start */}
          <Row className="mb10">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Description</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input.TextArea
                className="custom-input"
                placeholder="Description"
                size="large"
                type="text"
                value={state.description}
                onChange={handleChange("description")}
                autoSize={{ minRows: 2, maxRows: 4 }}
                disabled={disabled}
              />

              <Row className="error mt5">{error.description}</Row>
            </Col>
          </Row>
          {/* description end */}

          {/* Actual Price start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Actual Price</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input
                className="custom-input fw mt10"
                placeholder="Actual Price"
                size="large"
                allowClear
                value={state.actual_price}
                onChange={handleChange("actual_price")}
                disabled={disabled}
              />
              <Row className="error mt5">{error.actual_price}</Row>
            </Col>
          </Row>
          {/* Actual Price end */}

          {/* Discount Price start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Discounted Price</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input
                className="custom-input fw mt10"
                placeholder="Discounted Price"
                size="large"
                allowClear
                value={state.discounted_price}
                onChange={handleChange("discounted_price")}
                disabled={disabled}
              />
              <Row className="error mt5">{error.discounted_price}</Row>
            </Col>
          </Row>
          {/* Discount Price end */}

          {/* Key/Main Facilities start */}
          <Row className="mb10">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Key Facilities</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Row>
                {state.keyFacilities.map((keyInfo, i) => (
                  <Col key={i} className="mb5" span={12}>
                    <Row align="middle" justify="space-between">
                      <Col span={20}>
                        <Input
                          className="custom-input"
                          placeholder="Facilitie"
                          size="large"
                          type="text"
                          value={keyInfo.name}
                          onChange={handleFacilitieDetailsChange(
                            "keyFacilities"
                          )(i, "name")}
                          disabled={disabled}
                        />
                      </Col>

                      <Col span={4}>
                        {isCMSEditable && (
                          <Tooltip title="Delete">
                            <Popconfirm
                              title="Are you sure to delete this?"
                              onConfirm={() =>
                                onRemoveFacilities("keyFacilities")(i)
                              }
                              okText="Yes"
                              cancelText="No"
                              placement="bottom"
                              disabled={disabled}
                            >
                              <DeleteOutlined className="ml20" />
                            </Popconfirm>
                          </Tooltip>
                        )}
                      </Col>
                    </Row>

                    <Row className="error mt5">{error.keyFacilities?.[i]}</Row>
                  </Col>
                ))}
              </Row>
              {/* btn Key Facilities start */}
              <Col span={12}>
                <Button
                  className={state.keyFacilities.length > 0 ? "mt5" : ""}
                  size="large"
                  block
                  onClick={onAddFacilities("keyFacilities")}
                  disabled={disabled}
                >
                  + Add Key Facilitie
                </Button>

                <Row className="error mt5">{error.keyFacilitie}</Row>
              </Col>
              {/* btn Key Facilities end */}
            </Col>
          </Row>
          {/* Key/Main Facilities end */}

          {/* subheading start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Subheading</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input
                className="custom-input fw mt10"
                placeholder="Subheading"
                size="large"
                allowClear
                value={state.subheading}
                onChange={handleChange("subheading")}
                disabled={disabled}
              />
              <Row className="error mt5">{error.subheading}</Row>
            </Col>
          </Row>
          {/* subheading end */}
        </>
        {/* offer details end */}

        {/* offer tag details start */}
        <>
          <Row className="section mb10" align="middle" justify="center">
            Offer Tag Details
          </Row>

          {/* offer name start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Name</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input
                className="custom-input fw mt10"
                placeholder="Name"
                size="large"
                allowClear
                value={state.offer_name}
                onChange={handleChange("offer_name")}
                disabled={disabled}
              />
              <Row className="error mt5">{error.offer_name}</Row>
            </Col>
          </Row>
          {/* offer name end */}

          {/* Discount percent start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Discount Percent</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input
                className="custom-input fw mt10"
                placeholder="Discount Percent"
                size="large"
                allowClear
                value={state.discount_percent}
                onChange={handleChange("discount_percent")}
                disabled={disabled}
              />
              <Row className="error mt5">{error.discount_percent}</Row>
            </Col>
          </Row>
          {/* Discount percent end */}
        </>
        {/* offer tag details end */}

        {/* ribbon tag start */}
        <>
          <Row className="section mb10" align="middle" justify="center">
            Ribbon Tag Details
          </Row>

          {/* line 1 start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Line 1</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input
                className="custom-input fw mt10"
                placeholder="Line 1"
                size="large"
                allowClear
                value={state.ribbon_line_1}
                onChange={handleChange("ribbon_line_1")}
                disabled={disabled}
              />

              <Row className="error mt5">{error.ribbon_line_1}</Row>
            </Col>
          </Row>
          {/* line 1 end */}

          {/* line 2 start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Line 2</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input
                className="custom-input fw mt10"
                placeholder="Line 2"
                size="large"
                allowClear
                value={state.ribbon_line_2}
                onChange={handleChange("ribbon_line_2")}
                disabled={disabled}
              />

              <Row className="error mt5">{error.ribbon_line_2}</Row>
            </Col>
          </Row>
          {/* line 2 end */}
        </>
        {/* ribbon tag start */}

        {/* other details start */}
        <>
          <Row className="section mb10" align="middle" justify="center">
            Others Details
          </Row>

          {/* thumbnail images start */}
          <Row className="mb10">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Thumbnail Image</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Upload
                className="custom-upload-list"
                accept={".jpeg, .jpg, .png"}
                beforeUpload={beforeUploadImage}
                listType="picture"
                fileList={state.thumbnails}
                onRemove={onRemoveImage("thumbnails")}
                customRequest={onSelectImage("thumbnails")}
                disabled={disabled}
                itemRender={(originNode, file, _, actions) => (
                  <MUploadItem
                    originNode={originNode}
                    file={file}
                    actions={actions}
                    handleTagChange={handleTagChange("thumbnails")}
                    disabled={disabled}
                  />
                )}
              >
                {state.thumbnails.length === 0 && (
                  <Row
                    className="custom-upload-image mb10"
                    align="middle"
                    justify="center"
                  >
                    <Col className="mt5">
                      <UploadOutlined className="mr5" /> Upload 1440x820
                      <sup style={{ color: "red" }}>*</sup>
                    </Col>
                  </Row>
                )}
              </Upload>
              <Row className="error mt5">{error.thumbnails}</Row>
            </Col>
          </Row>
          {/* thumbnail images end */}

          {/* banner image start */}
          <Row className="mb10">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Banner Image</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Upload
                className="custom-upload-list"
                accept={".jpeg, .jpg, .png"}
                beforeUpload={beforeUploadImage}
                listType="picture"
                fileList={state.banners}
                onRemove={onRemoveImage("banners")}
                customRequest={onSelectImage("banners")}
                disabled={disabled}
                itemRender={(originNode, file, _, actions) => (
                  <MUploadItem
                    originNode={originNode}
                    file={file}
                    actions={actions}
                    handleTagChange={handleTagChange("banners")}
                    disabled={disabled}
                  />
                )}
              >
                {state.banners.length === 0 && (
                  <Row
                    className="custom-upload-image mb10"
                    align="middle"
                    justify="center"
                  >
                    <Col className="mt5">
                      <UploadOutlined className="mr5" /> Upload 1440x820
                      <sup style={{ color: "red" }}>*</sup>
                    </Col>
                  </Row>
                )}
              </Upload>
              <Row className="error mt5">{error.banners}</Row>
            </Col>
          </Row>
          {/* banner image end */}

          {/* floor plan start */}
          <Row className="mb10">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Floor Plan</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Upload
                className="custom-upload-list"
                accept={".pdf"}
                beforeUpload={beforeUploadFloorPlan}
                listType="picture"
                fileList={state.floorPlans}
                onRemove={onRemoveFloorPlan}
                customRequest={uploadFloorPlan}
                disabled={disabled}
                itemRender={(originNode, file, _, actions) => (
                  <MUploadItem
                    originNode={originNode}
                    file={file}
                    actions={actions}
                    handleTagChange={handleTagChange("floorPlans")}
                    disabled={disabled}
                  />
                )}
              >
                {/* {floorPlans.length === 0 && ( */}
                <Row
                  className="custom-upload-image mb10"
                  align="middle"
                  justify="center"
                >
                  <Col className="mt5">
                    <UploadOutlined className="mr5" /> Upload 1440x820
                    <sup style={{ color: "red" }}>*</sup>
                  </Col>
                </Row>
                {/* )} */}
              </Upload>

              <Row className="error mt5">{error.floorPlans}</Row>
            </Col>
          </Row>
          {/* floor plan end */}

          {/* Include in Price Facilities start */}
          <Row className="mb10">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Price Includes</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Row>
                {state.includeInPriceFacilities.map((facilitie, i) => (
                  <Col key={i} className="mb5" span={12}>
                    <Row align="middle" justify="space-between">
                      <Col span={20}>
                        <Input
                          className="custom-input"
                          placeholder="Facilitie"
                          size="large"
                          type="text"
                          value={facilitie.name}
                          onChange={handleFacilitieDetailsChange(
                            "includeInPriceFacilities"
                          )(i, "name")}
                          disabled={disabled}
                        />
                      </Col>

                      <Col span={4}>
                        {isCMSEditable && (
                          <Tooltip title="Delete">
                            <Popconfirm
                              title="Are you sure to delete this?"
                              onConfirm={() =>
                                onRemoveFacilities("includeInPriceFacilities")(
                                  i
                                )
                              }
                              okText="Yes"
                              cancelText="No"
                              placement="bottom"
                              disabled={disabled}
                            >
                              <DeleteOutlined className="ml20" />
                            </Popconfirm>
                          </Tooltip>
                        )}
                      </Col>
                    </Row>

                    <Row className="error mt5">
                      {error.includeInPriceFacilities?.[i]}
                    </Row>
                  </Col>
                ))}
              </Row>

              {/* btn Include in Price start */}
              <Col span={12}>
                <Button
                  className={
                    state.includeInPriceFacilities.length > 0 ? "mt5" : ""
                  }
                  size="large"
                  block
                  onClick={onAddFacilities("includeInPriceFacilities")}
                  disabled={disabled}
                >
                  + Add Include In Price Facilitie
                </Button>

                <Row className="error mt5">{error.includeInPriceFacilitie}</Row>
              </Col>
              {/* btn Include in Price end */}
            </Col>
          </Row>
          {/* Include in Price Facilities end */}

          {/* All Facilities start */}
          <Row className="mb10">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Facilities Included</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Row gutter={[5, 5]}>
                {state.facilities.map((keyInfo, i) => (
                  <Col key={i} span={12}>
                    <Row align="middle" justify="space-between">
                      <Col span={4} className="pr10">
                        <Row
                          className="custom-select-icon"
                          align="middle"
                          justify="center"
                        >
                          <Popover
                            content={
                              <SelectIcon
                                value={keyInfo.icon}
                                onChange={handleFacilitieDetailsChange(
                                  "facilities"
                                )(i, "icon")}
                              />
                            }
                            placement={"bottomLeft"}
                            trigger="click"
                            bordered={false}
                            showArrow={false}
                          >
                            <Button type="text">
                              <img
                                className="icon-img"
                                src={`/images/icons/${
                                  amenitieIconMap[keyInfo.icon]?.icon
                                }`}
                                alt={amenitieIconMap[keyInfo.icon]?.icon || ""}
                              />
                            </Button>
                          </Popover>
                        </Row>
                      </Col>

                      <Col span={16}>
                        <Input
                          className="custom-input"
                          placeholder="Facilitie"
                          size="large"
                          type="text"
                          value={keyInfo.name}
                          onChange={handleFacilitieDetailsChange("facilities")(
                            i,
                            "name"
                          )}
                          disabled={disabled}
                        />
                      </Col>

                      <Col span={4}>
                        {isCMSEditable && (
                          <Tooltip title="Delete">
                            <Popconfirm
                              title="Are you sure to delete this?"
                              onConfirm={() =>
                                onRemoveFacilities("facilities")(i)
                              }
                              okText="Yes"
                              cancelText="No"
                              placement="bottom"
                              disabled={disabled}
                            >
                              <DeleteOutlined className="ml20" />
                            </Popconfirm>
                          </Tooltip>
                        )}
                      </Col>
                    </Row>

                    <Row className="error mt5">{error.facilities?.[i]}</Row>
                  </Col>
                ))}
              </Row>

              {/* add facilitie btn start */}
              <Col span={12}>
                <Button
                  size="large"
                  block
                  onClick={onAddFacilities("facilities")}
                  disabled={disabled}
                >
                  + Add New Facilitie
                </Button>

                <Row className="error mt5">{error.facilitie}</Row>
              </Col>
              {/* add facilitie btn end */}
            </Col>
          </Row>
          {/* All Facilities end */}

          {/* gallery images start */}
          <Row className="mb10">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Images</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Upload
                className="custom-upload-list"
                accept={".jpeg, .jpg, .png"}
                beforeUpload={beforeUploadImage}
                listType="picture"
                fileList={state.images?.sort(
                  (a, b) => a?.extra?.order - b?.extra?.order
                )}
                onRemove={onRemoveImage("images")}
                customRequest={onSelectImage("images")}
                disabled={disabled}
                itemRender={(originNode, file, _, actions) => (
                  <MUploadItem
                    originNode={originNode}
                    file={file}
                    actions={actions}
                    handleTagChange={handleTagChange("images")}
                    disabled={disabled}
                    handleDragOver={handleDragOver}
                    handleDrag={handleDrag}
                    handleDrop={handleDrop}
                  />
                )}
              >
                <Row
                  className="custom-upload-image mb10"
                  align="middle"
                  justify="center"
                >
                  <Col className="mt5">
                    <UploadOutlined className="mr5" /> Upload 1440x820
                    <sup style={{ color: "red" }}>*</sup>
                  </Col>
                </Row>
              </Upload>
              <Row className="error mt5">{error.images}</Row>
            </Col>
          </Row>
          {/* gallery images end */}

          {/* active start */}
          {selectedId && (
            <Row align="top" className="mt10">
              <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4} />

              <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
                <Checkbox
                  disabled={disabled}
                  checked={state.active}
                  onChange={handleChange("active", true)}
                >
                  Active
                </Checkbox>
              </Col>
            </Row>
          )}
          {/* active end */}
        </>
        {/* other details end */}
      </Col>
    </CustomModal>
  );
};

export default React.memo(OfferDetailsModal);
