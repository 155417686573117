import { Row } from "antd";
import "./spinner.scss";

const Spinner = () => {
  return (
    <Row className="spinner">
      <div className="loader"></div>
    </Row>
  );
};

export default Spinner;
