import { Row, Col, Tag } from "antd";
import moment from "moment";
import { PoundOutlined, CalendarOutlined } from "@ant-design/icons";

export const billingColumns = () => {
  const columns = [
    {
      key: "plan",
      title: "Description",
      dataIndex: "plan",
    },

    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      render: (amount) => {
        return (
          <Row justify="start" align="middle" className="mt5">
            <Col
              className="--avatar orange mr15 circle"
              justify="center"
              align="middle"
            >
              <PoundOutlined style={{ fontSize: 18 }} />
            </Col>
            <Col>
              <h4 className="nm">£ {parseFloat(amount).toFixed(2)}</h4>
            </Col>
          </Row>
        );
      },
    },
    {
      key: "card",
      title: "Card Number",
      dataIndex: "card",
      render: (last4) => {
        return `**** ${last4}`;
      },
    },
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
      render: (date) => {
        return (
          <Row justify="start" align="middle">
            <Col
              className="--avatar green mr15 circle"
              justify="center"
              align="middle"
            >
              <CalendarOutlined />
            </Col>
            <Col>
              <h4 className="nm">
                {moment.unix(date).format("ddd, MMMM Do, YYYY")}
              </h4>
              <small>{moment.unix(date).format("h:mm a")}</small>
            </Col>
          </Row>
        );
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          color={
            status === "Failed"
              ? "red"
              : status === "Successfull"
              ? "green"
              : "orange"
          }
        >
          {status}
        </Tag>
      ),
    },
  ];
  return columns;
};
