import React, { useEffect, useRef, useState, useCallback } from "react";
import { Col, Input, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../../utils/CommonFunctions";
import CustomModal from "../../utils/CustomModal";
import { addContact } from "../duck/ContactsActions";

const AddContact = (props) => {
  const { visible, handleModal } = props;

  const dispatch = useDispatch();

  const preProps = useRef();

  const contacts = useSelector((state) => state.contacts);

  const workSpaceMap = useSelector((state) => state.workSpace?.workSpaceMap);
  const workSpaceList = Object.keys(workSpaceMap)?.filter(
    (id) => workSpaceMap?.[id]?.active
  );

  const [state, setState] = useState({
    prefix: "44",
  });
  const [error, setError] = useState({});

  const preload = () => {
    if (preProps.current?.loading && !contacts?.loading) {
      if (!contacts?.error) {
        handleModal();
      }
    } else if (!visible) {
      setState({ prefix: "44" });
      setError({});
    }
  };

  useEffect(() => {
    preload();

    return () => {
      preProps.current = { loading: contacts?.loading };
    };
  }, [visible, contacts?.loading]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const hasError = () => {
    let { fullName, email, phoneNumber, companyName, workspace } = state;
    let error = {};

    fullName = fullName?.trim?.();
    if (!fullName) {
      error.fullName = "Please enter your Full name";
    }

    email = email?.trim?.();
    if (!email) {
      error.email = "Please enter your Email";
    } else if (!validateEmail(email)) {
      error.email = "Please enter valid email";
    }

    phoneNumber = phoneNumber?.trim?.();
    if (!phoneNumber) {
      error.phoneNumber = "Please enter your Phone number";
    }

    companyName = companyName?.trim?.();
    if (!companyName) {
      error.companyName = "Please enter your Company name";
    }

    workspace = workspace?.trim?.();
    if (!workspace) {
      error.workspace = "Please select Workspace";
    }

    if (Object.keys(error).length > 0) {
      setError(error);
      return true;
    } else {
      return false;
    }
  };
  const prefixSelector = (
    <Select
      value={state.prefix}
      onChange={handleChange("prefix")}
      disabled={contacts?.loading}
    >
      <Select.Option key="44" value="44">
        +44
      </Select.Option>
      <Select.Option key="91" value="91">
        +91
      </Select.Option>
    </Select>
  );
  const onSubmit = () => {
    if (!hasError()) {
      const {
        fullName,
        email,
        prefix,
        phoneNumber,
        companyName,
        workspace,
        query,
      } = state;

      const payload = {
        name: fullName,
        email: email,
        mobile_no: `+${prefix || "44"}${phoneNumber}`,
        company_name: companyName,
        space_id: workspace,
        query: query,
        type: "contact_us",
      };
      dispatch(addContact(payload));
    }
  };

  return (
    <CustomModal
      title={"Interested in Cockpit?"}
      visible={visible}
      okButtonText={"Submit"}
      cancelButtonText={"Close"}
      width={500}
      handleSuccess={onSubmit}
      onCancel={handleModal}
      loading={contacts?.loading}
    >
      <p>
        Complete this form and our team will get in touch to address your
        concern.
      </p>

      <Row align="middle">
        <Input
          className="custom-input fw"
          placeholder={"Your name"}
          size="large"
          allowClear
          value={state.fullName}
          onChange={handleChange("fullName")}
          disabled={contacts?.loading}
        />
        <Row className="error mt5">{error?.fullName}</Row>
      </Row>

      <Row align="middle" className="mt10">
        <Col span={4} />

        <Input
          className="custom-input fw"
          placeholder={"Email"}
          size="large"
          allowClear
          value={state.email}
          onChange={handleChange("email")}
          disabled={contacts?.loading}
        />
        <Row className="error mt5">{error?.email}</Row>
      </Row>

      <Row align="middle" className="mt10">
        <Input
          className="custom-input fw"
          placeholder={"Phone number"}
          style={{ background: "#f8fafb" }}
          size="large"
          allowClear
          value={state.phoneNumber}
          addonBefore={prefixSelector}
          onChange={handleChange("phoneNumber")}
          disabled={contacts?.loading}
        />

        <Row className="error mt5">{error?.phoneNumber}</Row>
      </Row>

      <Row align="middle" className="mt10">
        <Input
          className="custom-input fw"
          placeholder={"Company name"}
          size="large"
          allowClear
          value={state.companyName}
          onChange={handleChange("companyName")}
          disabled={contacts?.loading}
        />
        <Row className="error mt5">{error?.companyName}</Row>
      </Row>

      <Row align="middle" className="mt10">
        <Select
          className="custom-select fw"
          placeholder="Select workspace"
          size="large"
          allowClear
          value={state.workspace}
          onChange={handleChange("workspace")}
          disabled={contacts?.loading}
        >
          {workSpaceList?.map((_id) => (
            <Select.Option key={_id} value={_id}>
              {workSpaceMap[_id]?.name}
            </Select.Option>
          ))}
        </Select>
        <Row className="error mt5">{error?.workspace}</Row>
      </Row>

      <Row align="middle" className="mt10">
        <Input.TextArea
          className="custom-input fw"
          placeholder={"Additional details"}
          size="large"
          allowClear
          value={state.query}
          onChange={handleChange("query")}
          disabled={contacts?.loading}
        />
        <Row className="error mt5">{error?.query}</Row>
      </Row>
    </CustomModal>
  );
};

export default AddContact;
