import {
  EyeOutlined,
  EditOutlined,
  CalendarOutlined,
  PoundOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { getAvatarColor, getNameInitials } from "../../utils/CommonFunctions";
import { Row, Tag, Col, Avatar, Tooltip } from "antd";
import moment from "moment";

export const bookingColumns = (
  editable,
  updateMeeting,
  handleShowSwapRequest
) => {
  const eye = (obj) => <EyeOutlined onClick={() => updateMeeting(obj)} />;
  const edit = (obj) => <EditOutlined onClick={() => updateMeeting(obj)} />;

  const columns = [
    {
      title: "S.no.",
      dataIndex: "serial",
      key: "serial",
      align: "center",
      width: 60,
    },
    {
      title: "Work Space",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Resource Name",
      dataIndex: "resource",
      key: "resource",
      onCell: () => {
        return {
          style: {
            maxWidth: 175,
          },
        };
      },
      render: (resource) => (
        <div>
          <h4 className="nm">{resource[2]}</h4>
          <small>
            <span
              className="mr10"
              style={{
                width: 8,
                height: 8,
                display: "inline-block",
                backgroundColor: resource[1] || "grey",
                borderRadius: "50%",
              }}
            ></span>

            <span>{resource[0]}</span>
          </small>
        </div>
      ),
    },
    {
      title: "Location",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Event Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return (
          <Row justify="start" align="middle">
            <Col
              className="--avatar green mr15 circle"
              justify="center"
              align="middle"
            >
              <CalendarOutlined />
            </Col>
            <Col>
              <h4 className="nm">
                {moment(date[0]).format("ddd, MMMM Do, YYYY")}
              </h4>
              <small>
                {moment(date[0]).format("h:mm a")}
                <span style={{ margin: "0 5px", fontSize: 13 }}>to</span>
                {moment(date[1]).format("h:mm a")}
              </small>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          color={
            status === "Past" || status === "Canceled" || status === "Failed"
              ? "red"
              : status === "Ongoing" || status === "Payment Pending"
              ? "orange"
              : "green"
          }
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Price (£)",
      dataIndex: "price",
      key: "price",
      render: (price) => {
        return (
          <Row justify="start" align="middle" className="mt5">
            <Col
              className="--avatar orange mr15 circle"
              justify="center"
              align="middle"
            >
              <PoundOutlined style={{ fontSize: 18 }} />
            </Col>
            <Col>
              <h4 className="nm">£ {parseFloat(price[0] || 0).toFixed(2)}</h4>
              <small>Booked for {parseFloat(price[1])} hour</small>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Members",
      dataIndex: "members",
      key: "members",
      render: (members) => (
        <Row justify="start" align="middle" className="mt5">
          {/* <Col className='--avatar purple mr15 circle' justify='center' align='middle'>
                            <TeamOutlined style={{fontSize:18}} />
                        </Col> */}
          <Avatar.Group
            maxCount={2}
            maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
          >
            {members?.map((user, i) => (
              <Tooltip
                placement="bottom"
                title={user?.name || user?.email}
                key={i}
              >
                <Avatar
                  style={{ backgroundColor: getAvatarColor(user?._id || i) }}
                >
                  {getNameInitials(user?.name || user?.email)}
                </Avatar>
              </Tooltip>
            ))}
          </Avatar.Group>
        </Row>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      render: (obj) => {
        const today = moment();
        let status = "";
        if (!obj?.active) {
          status = "canceled";
        } else if (today.isBetween(moment(obj?.from), moment(obj?.to))) {
          status = "ongoing";
        } else if (moment(obj?.from).isBefore(today, "time")) {
          status = "past";
        }
        return (
          <>
            <Tooltip
              placement="top"
              title={editable ? (status ? "Preview" : "Edit Booking") : "View"}
            >
              {status || !editable || obj?.payment_status === "failed"
                ? eye(obj)
                : edit(obj)}
            </Tooltip>

            {editable &&
              obj?.active &&
              obj?.resource_id?.type === "desks" &&
              !obj?.seat_swap_status &&
              obj?.payment_status !== "failed" &&
              status !== "past" && (
                <Tooltip
                  className="ml10"
                  placement="top"
                  title={"Raise Desk Swap Request"}
                >
                  <SwapOutlined
                    onClick={() => handleShowSwapRequest(true, obj)}
                  />
                </Tooltip>
              )}
          </>
        );
      },
    },
  ];
  return columns;
};
