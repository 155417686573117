import React from "react";
import { Result } from "antd";

class ErrorBoundary extends React.Component {
  state = {
    error: "",
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.log("error", error);
    console.log("info", info);
  }

  render() {
    if (this.state.hasError) {
      return <Result status="warning" title="Oops Something went wrong." />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
