import React, { useCallback, useEffect, useState } from "react";
import { Button } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

const MyPagination = ({
  totalCount,
  onChangePage,
  pageSize = 10,
  initialPage = 1,
}) => {
  const [pageObj, setPageObj] = useState({});

  useEffect(() => {
    setPageObj(getPageInfo(totalCount, initialPage, pageSize));
  }, [totalCount]);

  const setPage = (current) => {
    if (current > pageObj?.totalPages) return;

    setPageObj(getPageInfo(totalCount, current, pageSize));
    onChangePage({
      current,
      pageSize,
    });
    document.getElementsByTagName("main")?.[0]?.scrollTo(0, 0);
  };

  const getPageInfo = useCallback(
    (totalCount, currentPage = 1, pageSize) => {
      let totalPages = Math.ceil(totalCount / pageSize);

      let startPage, endPage;
      if (totalPages <= 5) {
        // less than 5 total pages so show all
        startPage = 1;
        endPage = totalPages;
      } else {
        // more than 5 total pages so calculate start and end pages
        if (currentPage <= 3) {
          startPage = 1;
          endPage = 5;
        } else if (currentPage + 3 >= totalPages) {
          startPage = totalPages - 5;
          endPage = totalPages;
        } else {
          startPage = currentPage - 3;
          endPage = currentPage + 2;
        }
      }

      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize - 1, totalCount - 1);

      // create an array of pages to ng-repeat in the page control
      let pages = [...Array(endPage + 1 - startPage).keys()].map(
        (i) => startPage + i
      );

      return {
        totalCount,
        currentPage,
        pageSize,
        totalPages,
        startPage,
        endPage,
        startIndex,
        endIndex,
        pages,
      };
    },
    [totalCount]
  );
  if (!pageObj.pages || pageObj.pages.length <= 1) {
    return null;
  }

  return (
    <ul
      className="ant-pagination ant-table-pagination ant-table-pagination-right"
      unselectable="unselectable"
    >
      <li
        title="Previous Page"
        className="ant-pagination-prev ant-pagination-disabled"
        aria-disabled="true"
      >
        <Button
          type="outline"
          disabled={pageObj.currentPage === 1}
          onClick={() => setPage(pageObj.currentPage - 1)}
        >
          <LeftOutlined />
        </Button>
      </li>

      {pageObj.pages.map((page, index) => (
        <li
          key={index}
          tabIndex="0"
          className={`ant-pagination-item ${
            pageObj.currentPage === page ? "ant-pagination-item-active" : ""
          }`}
        >
          <a onClick={() => setPage(page)} rel="nofollow">
            {page}
          </a>
        </li>
      ))}

      <li
        title="Next Page"
        tabIndex="0"
        className="ant-pagination-next"
        aria-disabled="false"
      >
        <Button
          type="outline"
          disabled={pageObj.currentPage === pageObj.totalPages}
          onClick={() => setPage(pageObj.currentPage + 1)}
        >
          <RightOutlined />
        </Button>
      </li>
    </ul>
  );
};

export default MyPagination;
