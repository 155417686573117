import { put, takeLatest, call } from 'redux-saga/effects';
import { showNotification } from '../../utils/CommonFunctions';
import ACTIONS from './MailActionTypes';
import AppUrl from '../../config/AppUrl'
import axios from 'axios';

const getAllMailTemplateRequest = (params = {}) => {
    return axios ({
        method: 'GET',
        url: AppUrl.MAIL_TEMPLATE,
        params: params,
    });
}

function* getAllMailTemplate(action) {
    try {
        let resources = yield call(getAllMailTemplateRequest, action.payload);
        let _data = resources?.data;
        _data.space_id = action.payload?.space_id;

        if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
            showNotification("error", _data?.meta?.message);
            yield put({ type: ACTIONS.FETCH_ALL_MAIL_TEMPLATE_FAILURE, payload: _data });
        } else {
            yield put({ type: ACTIONS.FETCH_ALL_MAIL_TEMPLATE_SUCCESS, payload: _data });
        }
    } catch (error) {
        console.log("get all mail templates error", error);
    }
}

const sendCampaignMailRequest = (data = {}) => {
    return axios ({
        method: 'POST',
        url: AppUrl.SEND_CAMPAIGN_MAIL,
        data: data,
    });
}

function* sendCampaignMail(action) {
    try {
        let resources = yield call(sendCampaignMailRequest, action.payload);
        let _data = resources?.data;
        _data.space_id = action.payload?.space_id;

        console.log("_data", _data);

        if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
            // showNotification("error", _data?.meta?.message);
            showNotification("error", "Something went wrong while sending mail");
            yield put({ type: ACTIONS.SEND_CAMPAIGN_MAIL_FAILURE, payload: _data });
        } else {
            showNotification("success", _data?.meta?.message);
            yield put({ type: ACTIONS.SEND_CAMPAIGN_MAIL_SUCCESS, payload: _data });
        }
    } catch (error) {
        console.log("send mail error", error);
    }
}

// ACTION WATCHER
export default function* mailSaga() {
    yield takeLatest(ACTIONS.FETCH_ALL_MAIL_TEMPLATE, getAllMailTemplate);
    yield takeLatest(ACTIONS.SEND_CAMPAIGN_MAIL, sendCampaignMail);
}
