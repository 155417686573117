import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./components/calendar.scss";
import NewBooking from "./components/NewBooking";
import {
  checkPermission,
  days,
  resourceTypes,
  showNotification,
} from "../utils/CommonFunctions";
import { getAllBookingResources } from "./duck/BookingActions";
import { getAllAvailableDragResources } from "./duck/BookingActions";
import { updateDragBooking } from "./duck/BookingActions";
import BookingHistory from "./components/BookingHistory";
import AddBookingRequestModal from "./../bookingRequest/component/AddBookingRequestModal";
import "antd/dist/antd.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);
const BOOKING_TYPE = {
  meeting_room: "meetingRooms",
  private_office: "privateOffices",
  desk: "desks",
};

Object.freeze(BOOKING_TYPE);

const CalendarView = () => {
  const { confirm } = Modal;
  const preProps = useRef();
  const [showAddNewBooking, setShowAddNewBooking] = useState(false);
  const [showBookingHistory, setShowBookingHistory] = useState(false);
  const [selectedBooking, selectBooking] = useState(null);
  const [bookingStatus, setBookingStatus] = useState("new");
  const [fromToDate, setFromToDate] = useState();
  const [boardKey, setBoardKey] = useState("");
  const [showAddBookingRequest, setShowAddBookingRequest] = useState(false);

  const [eventDate, setEventDate] = useState([]);
  const [objData, setObjData] = useState({});
  const { activeWorkSpace, mWorkspace } = useSelector(({ workSpace }) => {
    const { workSpaceMap, active } = workSpace;

    return {
      activeWorkSpace: active,
      mWorkspace: workSpaceMap[active],
    };
  });
  const booking = useSelector((state) => state?.booking);
  console.log(booking)
  const mBookingBoard = useMemo(
    () => booking.bookings?.[boardKey] ?? {},
    [booking.bookings, boardKey]
  );
  const bookingList = useMemo(
    () => {
      const totalBooking = Object.values(booking?.bookingMap);
      if(totalBooking.length > 0){
        const filterList = totalBooking?.filter(o=>o.payment_status === "completed")?.map(o=>o._id);
        return filterList || [];
      } else {
        return []
      }
    },
    [mBookingBoard?.list, booking?.bookingMap]
  );

  const dispatch = useDispatch();

  const isBookingEditable = useMemo(
    () => checkPermission({ row: "booking", subRow: "booking" }),
    []
  );
  const showAllBooking = useMemo(
    () => checkPermission({ initial: "showAllBooking" }),
    []
  );

  const office_hours_start = mWorkspace?.office_hours?.[0];
  const office_hours_end = mWorkspace?.office_hours?.[1];
  const startHours = moment(office_hours_start).format("HH");
  const startMinutes = moment(office_hours_start).format("mm");
  const endHours = moment(office_hours_end).format("HH");
  const endMinutes = moment(office_hours_end).format("mm");

  const bookingParams = useMemo(() => {
    let params = {};
    if (showAllBooking && activeWorkSpace) params.space_id = activeWorkSpace;
    // params.payment_status = "completed";
    params.page = "Calendar";
    return params;
  }, [showAllBooking, activeWorkSpace]);

  const loadMore = useCallback(
    (offset = 0, limit = 100) => {
      const { meta, list, loading } = mBookingBoard;

      if (!loading && (offset === 0 || meta?.totalCount > list?.length)) {
        const params = Object.assign({}, bookingParams);
        setBoardKey(JSON.stringify({ ...params }));

        params.offset = offset;
        params.limit = limit;
        dispatch(getAllBookingResources(params));
      }
    },
    [mBookingBoard, bookingParams, dispatch]
  );

  useEffect(() => {
    if (activeWorkSpace) {
      const { meta, list } = mBookingBoard;
      if (!meta || meta?.totalCount >= 1) {
        loadMore(list?.length ?? 0);
      }
    }

    if (
      preProps?.current?.activeWorkSpace &&
      preProps?.current?.activeWorkSpace !== activeWorkSpace
    ) {
      setBoardKey(JSON.stringify(bookingParams));
    }

    if (!localStorage.getItem("calendarView"))
      localStorage.setItem("calendarView", "month");

    return () => {
      preProps.current = { activeWorkSpace };
    };
  }, [activeWorkSpace, mBookingBoard, bookingParams, loadMore]);

  // useEffect(() => {
  //   if (
  //     activeWorkSpace &&
  //     preProps?.current?.activeWorkSpace !== activeWorkSpace
  //   ) {

  //     setBoardKey(JSON.stringify(bookingParams));
  //   } else {
  //     // TODO: need to improve load more
  //     const { meta, list } = mBookingBoard;

  //     if (!meta || meta?.totalCount >= 1) {
  //       loadMore(list?.length ?? 0);
  //     }
  //   }

  //   if (!localStorage.getItem("calendarView"))
  //     localStorage.setItem("calendarView", "month");

  //   return () => {
  //     preProps.current = { activeWorkSpace };
  //   };
  // }, [activeWorkSpace, mBookingBoard, bookingParams, loadMore]);

  const events = useMemo(() => {
    const mEvents = [];
    bookingList.forEach((id) => {
      const mBooking = booking?.bookingMap?.[id];
      const assignedUser = mBooking?.assignedUser;
      const members = mBooking?.members;

      if (mBooking?.active) {
        const resourceType = resourceTypes[mBooking?.type];
        const resource = mBooking?.resource_id;

        const bookedEventDetails = {
          id,
          title: "",
          start: new Date(mBooking?.from),
          end: new Date(mBooking?.to),
          color: resource?.color,
          type: resource.type,
          space_id: activeWorkSpace,
          resource_id: resource._id,
          dynamic_price: mBooking.price,
          capacity: mBooking.resource_id.capacity,
          ids: [mBooking.items[0]._id],
        };

        if (resource?.name) {
          bookedEventDetails.title = `${resource?.name}`;
        }

        let bookedFor;
        if (assignedUser) {
          if (typeof assignedUser === "object") {
            let { name = "", email = "", number = "" } = assignedUser;
            name = name?.trim?.();
            email = email?.trim?.();
            number = number?.trim?.();

            if (name && email) {
              bookedFor = `${name}(${email})`;
            } else {
              bookedFor = name || email || number;
            }
          } else {
            bookedFor = null;
          }
        } else if (Array.isArray(members) && members.length > 0) {
          const member = members[0];

          if (typeof member === "object") {
            let { name = "", email = "", number = "" } = member;
            name = name?.trim?.();
            email = email?.trim?.();
            number = number?.trim?.();

            if (name && email) {
              bookedFor = `${name}(${email})`;
            } else {
              bookedFor = name || email || number;
            }
          } else {
            bookedFor = null;
          }
        }

        if (bookedFor) {
          if (bookedEventDetails.title) {
            bookedEventDetails.title = `${bookedFor} | ${bookedEventDetails.title}`;
          } else {
            bookedEventDetails.title = `${bookedFor}`;
          }
        }

        if (mBooking?.type === "meeting_room") {
          mEvents.push(bookedEventDetails);
        } else {
          const bookedFromDate = moment(mBooking?.from);
          const bookedToDate = moment(mBooking?.to);
          const bookingDaysCount = bookedToDate.diff(bookedFromDate, "days");

          if (bookingDaysCount > 0) {
            const bookedSpace = mBooking?.space_id;
            const workingDays = bookedSpace?.no_of_days || [];

            const startOfficeHours = bookedSpace?.office_hours?.[0];
            const endOfficeHours = bookedSpace?.office_hours?.[1];

            for (let i = 0; i <= bookingDaysCount; i++) {
              const mBookedDate = moment(mBooking?.from).add(i, "days");
              const currentDayIndex = mBookedDate.day();
              const currentDay = days[currentDayIndex];

              if (workingDays.includes(currentDay)) {
                // check if start date is not set then set booking event start date
                if (!bookedEventDetails.start) {
                  bookedEventDetails.start = mBookedDate
                    .set({
                      hour: moment(startOfficeHours).format("HH"),
                      minute: moment(startOfficeHours).format("mm"),
                      second: 0,
                    })
                    .toDate();
                }

                const nextDay = days[currentDayIndex + 1];

                // check if current day is last day or next day is not working then set booking event end date
                if (i === bookingDaysCount || !workingDays.includes(nextDay)) {
                  bookedEventDetails.end = mBookedDate
                    .set({
                      hour: moment(endOfficeHours).format("HH"),
                      minute: moment(endOfficeHours).format("mm"),
                      second: 0,
                    })
                    .toDate();

                  mEvents.push({ ...bookedEventDetails });
                  bookedEventDetails.start = null;
                }
              }
            }
          } else {
            mEvents.push(bookedEventDetails);
          }
        }
      }
    });
    console.log("----->",bookingList)
    setEventDate(mEvents);
    return mEvents;
  }, [bookingList, booking?.bookingMap]);

  const eventPropGetter = useCallback((event) => {
    return {
      style: {
        backgroundColor: event.color,
        borderColor: "lightgrey",
      },
    };
  }, []);

  const viewChange = useCallback(
    (e) => localStorage.setItem("calendarView", e),
    []
  );

  const handleShowNewBooking = useCallback((show = false) => {
    show = typeof show === "boolean" && show;
    setShowAddNewBooking(show);
    if (!show) {
      selectBooking(null);
      setBookingStatus("new");
    }
  }, []);

  const createNewEvent = useCallback(
    ({ start, end }) => {
      const calendarView = localStorage?.getItem("calendarView");
      const today = moment();
      if (
        moment(start).isAfter(today, "time") ||
        (calendarView === "month" && today.isSame(moment(start), "days"))
      ) {
        end = moment(end).subtract(1, "days");
        if (calendarView === "month" && today.isSame(moment(start), "days")) {
          const roundedUp = Math.ceil(today.minute() / 30) * 30;
          let dateTime = today.minute(roundedUp).second(0);
          setFromToDate({ from: dateTime, to: end });
        } else {
          setFromToDate({ from: start, to: end });
        }
        setBookingStatus("new");
        //handleShowNewBooking(true);
        setShowAddBookingRequest(true);
      } else if (moment(start).isBefore(today, "time")) {
        showNotification("warning", "You cannot create an event from the past");
      }
    },
    [setShowAddBookingRequest]
    /*  [handleShowNewBooking] */
  );

  const dayPropGetter = useCallback(
    (date) => {
      const style = {};

      if (showAllBooking) {
        const spaceWrokingDays = mWorkspace?.no_of_days || [];
        const currentDay = days[date.getDay()];

        if (!spaceWrokingDays.includes(currentDay)) {
          style.backgroundColor = "#e6e6e6";
        }
      }

      return {
        style: style,
      };
    },
    [showAllBooking, mWorkspace]
  );

  const eventHandler = useCallback(({ event }) => {
    return (
      <span>
        <strong>{event.title}</strong>
      </span>
    );
  }, []);

  const eventAgenda = useCallback(({ event }) => {
    return (
      <span>
        <em style={{ fontWeight: 500 }}>{event.title}</em>
        {event?.desc && <div>{event.desc}</div>}
      </span>
    );
  }, []);

  const updateMeeting = useCallback(
    (e) => {
      const today = moment();
      let status = "";
      if (today.isBetween(moment(e?.start), moment(e?.end))) {
        status = "ongoing";
      } else if (moment(e?.start).isBefore(today, "time")) {
        status = "past";
      }

      setBookingStatus(status);
      selectBooking(booking?.bookingMap?.[e?.id]);
      handleShowNewBooking(true);
    },
    [booking?.bookingMap]
  );

  const handleEventDrop = async ({ event, start, end }) => {
    const currentDate = moment().startOf("day");
    console.log("start", start);
    if (moment(start).isBefore(currentDate)) {
      showNotification("warning", "You cannot create an event from the past");
      return;
    }

    const idx = eventDate.indexOf(event);
    const updatedEvent = { ...event, start, end };
    const nextEvents = [...eventDate];
    const existingEvent = eventDate[idx];
    const mBookings = booking?.bookingMap?.[event.id];
    const availableDays = mBookings.space_id.no_of_days;
    const updatedEventDay = moment(start).format("dddd");

    const handleUpdate = () => {
      nextEvents.splice(idx, 1, updatedEvent);
      dispatch(updateDragBooking(updatedEvent));
      setObjData(updatedEvent);
      setEventDate(nextEvents);
      dispatch(getAllAvailableDragResources(updatedEvent));
    };
    if (availableDays.includes(updatedEventDay)) {
      if (existingEvent.resource_id === updatedEvent.resource_id) {
        if (start < existingEvent.end && end > existingEvent.start) {
          showNotification("warning", "No seat available for this slot");
        } else {
          confirm({
            title: "Would you like to confirm the update for this event?",
            icon: <ExclamationCircleOutlined />,
            okText: "Yes",
            okType: "",
            cancelText: "No",
            onOk: handleUpdate,
            onCancel() {
              console.log("Cancel");
            },
          });
        }
      } else {
        confirm({
          title: "Would you like to confirm the update for this event?",
          icon: <ExclamationCircleOutlined />,
          // content: "Some descriptions",
          okText: "Yes",
          okType: "",
          cancelText: "No",
          onOk: handleUpdate,
          onCancel() {
            console.log("Cancel");
          },
        });
      }
    } else {
      showNotification(
        "warning",
        `Event update is not allowed on ${updatedEventDay}.`
      );
    }
  };

  const handleResizeEvent = ({ event, start, end }) => {
    console.log("event", event);
    const nextEvents = eventDate.map((existingEvent) =>
      existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    );

    const currentDate = moment().startOf("day");

    if (moment(start).isBefore(currentDate)) {
      showNotification("warning", "You cannot create an event from the past");
      return;
    }

    const idx = eventDate.findIndex((e) => e.id === event.id);
    const updatedEvent = { ...event, start, end };
    const existingEvent = eventDate[idx];

    const mBookings = booking?.bookingMap?.[event.id];
    const availableDays = mBookings.space_id.no_of_days;
    const updatedEventDay = moment(start).format("dddd");

    const handleUpdate = () => {
      nextEvents.splice(idx, 1, updatedEvent);
      dispatch(updateDragBooking(updatedEvent));
      setObjData(updatedEvent);
      setEventDate(nextEvents);
      dispatch(getAllAvailableDragResources(updatedEvent));
    };

    if (availableDays.includes(updatedEventDay)) {
      if (
        moment(start).isSame(existingEvent.start, "day") &&
        moment(end).isSame(existingEvent.end, "day")
      ) {
        if (existingEvent.resource_id === updatedEvent.resource_id) {
          let isOverlap = false;
          for (const e of nextEvents) {
            if (
              e.id !== updatedEvent.id &&
              e.resource_id === updatedEvent.resource_id
            ) {
              if (start < e.end && end > e.start) {
                isOverlap = true;
                break;
              }
            }
          }

          if (isOverlap) {
            showNotification("warning", "No seat available for this slot");
          } else {
            confirm({
              title: "Would you like to confirm the update for this event?",
              icon: <ExclamationCircleOutlined />,
              // content: "Some descriptions",
              okText: "Yes",
              okType: "",
              cancelText: "No",
              onOk: handleUpdate,
              onCancel() {
                console.log("Cancel");
              },
            });
          }
        } else {
          confirm({
            title: "Would you like to confirm the update for this event?",
            icon: <ExclamationCircleOutlined />,
            // content: "Some descriptions",
            okText: "Yes",
            okType: "",
            cancelText: "No",
            onOk: handleUpdate,
            onCancel() {
              console.log("Cancel");
            },
          });
        }
      } else {
        showNotification("warning", "Event date cannot be extended.");
      }
    } else {
      showNotification(
        "warning",
        `Event update is not allowed on ${updatedEventDay}.`
      );
    }
  };

  return (
    <div className="--cockpit-calendar-view">
      {/* <Calendar
        popup
        selectable={showAllBooking}
        localizer={localizer}
        events={events || []}
        style={{ height: "calc(100vh - 124px)" }}
        eventPropGetter={eventPropGetter}
        onView={viewChange}
        defaultView={localStorage?.getItem("calendarView") || "month"}
        scrollToTime={new Date()}
        onSelectEvent={(e) => updateMeeting(e)}
        {...(showAllBooking && { onSelectSlot: createNewEvent })}
        dayPropGetter={dayPropGetter}
        components={{
          event: eventHandler,
          agenda: {
            event: eventAgenda,
          },
        }}
        min={new Date(0, 0, 0, 10, 0, 0)}
        max={new Date(0, 0, 0, 23, 0, 0)}
      /> */}

      <DnDCalendar
        popup
        selectable={showAllBooking}
        localizer={localizer}
        events={eventDate || []}
        style={{ height: "calc(100vh - 124px)" }}
        eventPropGetter={eventPropGetter}
        onView={viewChange}
        defaultView={localStorage?.getItem("calendarView") || "month"}
        scrollToTime={new Date()}
        onSelectEvent={(e) => updateMeeting(e)}
        {...(showAllBooking && { onSelectSlot: createNewEvent })}
        dayPropGetter={dayPropGetter}
        components={{
          event: eventHandler,
          agenda: {
            event: eventAgenda,
          },
        }}
        min={new Date(0, 0, 0, startHours, startMinutes, 0)}
        max={new Date(0, 0, 0, endHours, endMinutes, 0)}
        onEventDrop={handleEventDrop}
        onEventResize={handleResizeEvent}
      />

      <NewBooking
        visible={showAddNewBooking}
        bookingId={selectedBooking?._id}
        handleModal={handleShowNewBooking}
        selectedBooking={selectedBooking}
        fromToDate={fromToDate}
        selectBooking={selectBooking}
        bookingStatus={bookingStatus}
        editable={isBookingEditable}
        onShowBookingHistory={() => setShowBookingHistory(true)}
      />
      <AddBookingRequestModal
        visible={showAddBookingRequest}
        handleModal={() => setShowAddBookingRequest(false)}
      />
      <BookingHistory
        bookingId={selectedBooking?._id}
        isOpen={showBookingHistory}
        onClose={() => setShowBookingHistory(false)}
      />
    </div>
  );
};

export default CalendarView;
