import moment from "moment";
import reportsTransformer from "./ReportsTransformer";
import { Row, Col } from "antd";

export const reportColumns = () => {
  const columns = [
    {
      dataIndex: "report",
      key: "report",
      columnWidth: "0px",
      render: (item) => (
        <Row justify="space-between" align="middle">
          <Col
            style={{ fontSize: 18, fontWeight: 500 }}
            align="top"
            key={item?._id}
          >
            {reportsTransformer(item?.onModel)}
            {/* {item?.actor?.name}{" "} */}
            <span style={{ fontSize: 15 }}>{item?.message} </span>
          </Col>
          <Col>{moment(item?.timestamp).fromNow()}</Col>
        </Row>
      ),
    },
  ];
  return columns;
};
