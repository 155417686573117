import { useSelector } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import WorkSpace from "../workspace/WorkSpace";
import MeetingRooms from "../resources/MeetingRooms";
import HotDesk from "../resources/HotDesk";
import DeskListItems from "../resources/components/DeskListItems";
import PrivateOffices from "../resources/PrivateOffices";
import SearchResource from "../resources/SearchResource";
import Members from "../members/Members";
import Booking from "../booking/Booking";
import DeskSwap from "../deskSwap/DeskSwap";
import InvoiceList from "../invoice/InvoiceList";
import InvoicePreview from "../invoice/InvoicePreview";
import Dashboard from "../dashboard/Dashboard";
import MemberDashboard from "../dashboard/MemberDashboard";
import Calendar from "../booking/Calendar";
import Amenities from "../settings/Amenities";
import PayNow from "../payNow/PayNow";
import Profile from "../profile/Profile";
import Plan from "../plan/Plan";
import Pricing from "../pricing/Pricing";
import Billing from "../billing/Billing";
import DoorList from "../doorList/DoorList";
import Mail from "../mail/Mail";
import Role from "../role/Role";
import EditRole from "../role/components/EditRole";
import AdminAndTeams from "../adminAndTeams/AdminAndTeams";
import UserActivity from "../userActivity/UserActivity";
import Contacts from "../contacts/Contacts";
import SocialMediaLead from "../socialMediaLead//SocialMediaLead";
import Reports from "../reports/Reports";
import Routes from "../config/Routes";
import AboutUs from "../cms/AboutUs";
import Slider from "../cms/Slider";
import ImageGallery from "../cms/ImageGallery";
import OurSpace from "../cms/OurSpace";
import AddOurSpacePage from "../cms/components/AddOurSpacePage";
import KnowMore from "../cms/KnowMore";
import OfferPage from "../cms/OfferPage";
import OtherPage from "../cms/OtherPage";
import EditSliderPage from "../cms/components/EditSliderPage";
import Xero from "../xero/XeroList";
import BookingRequest from "../bookingRequest/BookingRequest";
import FaqPage from "../cms/FaqPage";
import EditFaqPage from "../cms/components/EditFaqPage";
import Testimonial from "../cms/Testimonial";
import ServicePage from "../cms/ServicePage";
import EditFoldPage from "../cms/components/EditFoldPage";
import { cmsFoldPages } from "../utils/CommonFunctions";

const scrollToTop = () => {
  document.documentElement.scrollTop = 0;
};

const AppRouters = () => {
  const user = useSelector((state) => state?.user?.loggedInUser);
  const {
    showAllBooking,
    adminAndTeams,
    dashboard,
    resources,
    members,
    roles,
    booking,
    deskSwap,
    bookingRequest,
    invoice,
    billing,
    userActivity,
    plan,
    pricing,
    door,
    mail,
    contactUs,
    socialMediaLead,
    cms,
    settings,
    xero,
  } = user?.role_id?.permissions || {};
  scrollToTop();
  return (
    <>
      {dashboard?.show && (
        <PrivateRoute
          path={Routes.dashboard}
          component={showAllBooking ? Dashboard : MemberDashboard}
        />
      )}
      {adminAndTeams?.show && (
        <PrivateRoute path={Routes.adminAndTeams} component={AdminAndTeams} />
      )}
      {resources?.meetingRooms?.show && (
        <PrivateRoute path={Routes.meetingRooms} component={MeetingRooms} />
      )}
      {resources?.desk?.show && (
        <>
          <PrivateRoute path={Routes.desk} component={HotDesk} />
          <PrivateRoute path={Routes.desk + "/:id"} component={DeskListItems} />
        </>
      )}
      {resources?.privateOffices?.show && (
        <PrivateRoute path={Routes.privateOffices} component={PrivateOffices} />
      )}
      {members?.show && (
        <PrivateRoute path={Routes.members} component={Members} />
      )}
      {roles?.show && (
        <>
          <PrivateRoute path={Routes.roles} component={Role} />
          <PrivateRoute path={Routes.role} component={EditRole} />
        </>
      )}

      {booking?.booking?.show && (
        <>
          <PrivateRoute path={Routes.booking} component={Booking} />
          <PrivateRoute path={Routes.search} component={SearchResource} />
        </>
      )}
      {deskSwap?.show && (
        <PrivateRoute path={Routes.deskSwap} component={DeskSwap} />
      )}
      {bookingRequest?.show && (
        <PrivateRoute path={Routes.bookingRequest} component={BookingRequest} />
      )}
      {booking?.calendar?.show && (
        <PrivateRoute path={Routes.calendar} component={Calendar} />
      )}
      {invoice?.show && (
        <>
          <PrivateRoute path={Routes.invoice} component={InvoiceList} />
          <PrivateRoute
            path={Routes.invoicePreview + "/:id"}
            component={InvoicePreview}
          />
        </>
      )}
      {xero?.show && (
        <>
          <PrivateRoute path={Routes.xero} component={Xero} />
        </>
      )}

      {door?.show && <PrivateRoute path={Routes.door} component={DoorList} />}
      {cms?.show && (
        <>
          <PrivateRoute path={Routes.slider} component={Slider} />
          <PrivateRoute
            path={[Routes.editSlider, Routes.editSlider + "/:id"]}
            component={EditSliderPage}
          />
          {/* Foldable pages start */}
          {cmsFoldPages.map((page) => (
            <PrivateRoute
              key={page.route}
              path={page.route}
              {...page}
              component={ServicePage}
            />
          ))}
          <PrivateRoute
            path={[Routes.editFoldPage, Routes.editFoldPage + "/:id"]}
            component={EditFoldPage}
          />
          {/* Foldable pages end */}
          <PrivateRoute path={Routes.testimonial} component={Testimonial} />
          {/* <PrivateRoute path={Routes.aboutUs} component={AboutUs} /> */}
          <PrivateRoute path={Routes.imageGallery} component={ImageGallery} />
          <PrivateRoute path={Routes.ourSpace} component={OurSpace} />
          <PrivateRoute
            path={[`${Routes.ourSpace}/:id`]}
            component={AddOurSpacePage}
          />
          <PrivateRoute path={Routes.knowMore} component={KnowMore} />
          <PrivateRoute path={Routes.otherPage} component={OtherPage} />
          <PrivateRoute path={Routes.faqPage} component={FaqPage} />
          <PrivateRoute path={Routes.offerPage} component={OfferPage} />
          <PrivateRoute
            path={[Routes.editFaq, Routes.editFaq + "/:id"]}
            component={EditFaqPage}
          />
        </>
      )}
      {settings?.workspace?.show && (
        <PrivateRoute path={Routes.workspace} component={WorkSpace} />
      )}
      {settings?.amenities?.show && (
        <PrivateRoute path={Routes.amenities} component={Amenities} />
      )}
      {billing?.show && (
        <PrivateRoute path={Routes.billing} component={Billing} />
      )}
      {mail?.show && <PrivateRoute path={Routes.mail} component={Mail} />}

      {contactUs?.show && (
        <PrivateRoute path={Routes.contactUs} component={Contacts} />
      )}

      {socialMediaLead?.show && (
        <PrivateRoute
          path={Routes.socialMediaLead}
          component={SocialMediaLead}
        />
      )}

      {userActivity?.show && (
        <PrivateRoute path={Routes.userActivity} component={UserActivity} />
      )}

      {(plan?.flexiDesk?.show ||
        plan?.dedicatedDesk?.show ||
        plan?.privateOffice?.show) && (
        <PrivateRoute path={Routes.plan.plan} component={Plan} />
      )}
      {(pricing?.flexiDesk?.show || pricing?.dedicatedDesk?.show) && (
        <PrivateRoute path={Routes.pricing.pricing} component={Pricing} />
      )}
      <PrivateRoute path={`${Routes.payNow}/:bookingId`} component={PayNow} />
      <PrivateRoute path={Routes.profile} component={Profile} />
      <PrivateRoute path={Routes.reports + "/:actor"} component={Reports} />
    </>
  );
};

export default AppRouters;
