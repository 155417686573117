import React, { useMemo } from "react";
import { Card, Tooltip, Typography } from "antd";
import { useSelector } from "react-redux";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;

const OtherPageCard = (props) => {
  const { id, isCMSEditable } = props;

  const mCms = useSelector(({ cms }) => cms.cmsMap[id]);
  const ExtraIcon = useMemo(
    () => (isCMSEditable ? EditOutlined : EyeOutlined),
    [isCMSEditable]
  );

  const content = useMemo(
    () => mCms?.description?.replace(/(<([^>]+)>)/gi, ""),
    [mCms?.description]
  );

  if (!mCms) {
    return <></>;
  }

  return (
    <Card
      className="cms-card"
      title={mCms.name}
      bordered={false}
      hoverable
      extra={
        <Tooltip title={isCMSEditable ? "Edit" : "More Details"}>
          <ExtraIcon
            style={{ fontSize: 12 }}
            onClick={() => props.showModal(true, id)}
          />
        </Tooltip>
      }
    >
      <Paragraph ellipsis={{ rows: 5 }}>{content}</Paragraph>
    </Card>
  );
};

export default React.memo(OtherPageCard);
