import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { Row, Select, DatePicker, Card, Button, Table } from "antd";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAllInvoices } from "./duck/InvoiceActions";
import moment from "moment";
import Routes from "../config/Routes";
import { invoiceColumns } from "./components/InvoiceColumns";
import {
  resourceTypes,
  disabledDate,
  checkPermission,
} from "../utils/CommonFunctions";
import MTable from "../utils/MTable";

const InvoiceList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const prevProps = useRef();
  const activeWorkSpace = useSelector((state) => state?.workSpace?.active);
  const invoice = useSelector((state) => state?.invoice);
  const showAllBooking = useMemo(
    () => checkPermission({ initial: "showAllBooking" }),
    []
  );

  const [filter, setFilter] = useState({});
  const [boardKey, setBoardKey] = useState();

  const mInvoiceBoard = useMemo(
    () => invoice.invoices?.[boardKey] ?? {},
    [invoice.invoices, boardKey]
  );

  const loadMore = useCallback(
    (offset = 0, limit = 30) => {
      const { meta, list, loading } = mInvoiceBoard;
      if (!loading && (offset === 0 || meta?.totalCount > list?.length)) {
        const { resource_type, from, to } = filter;
        let params = {};
        if (showAllBooking && activeWorkSpace)
          params.space_id = activeWorkSpace;
        if (resource_type) params.resource_type = filter?.resource_type;
        if (from)
          params.from = moment(filter?.from).startOf("day").utc().format();
        if (to) params.to = moment(filter?.to).endOf("day").utc().format();
        setBoardKey(JSON.stringify({ ...params }));
        params.offset = offset;
        params.limit = limit;

        dispatch(getAllInvoices(params));
      }
    },
    [mInvoiceBoard, filter, showAllBooking, activeWorkSpace, dispatch]
  );

  const onSearch = () => loadMore();
  const onReset = () => {
    setFilter({});
    setBoardKey("");
  };

  useEffect(() => {
    if (prevProps?.current?.activeWorkSpace !== activeWorkSpace) {
      onReset();
    } else if (!Object.keys(mInvoiceBoard)?.length) loadMore();

    return () => {
      prevProps.current = { activeWorkSpace };
    };
  }, [activeWorkSpace, loadMore, mInvoiceBoard]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setFilter((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );

  const handleClick = (id) => {
    id && history.push(`${Routes.invoicePreview}/${id}`);
  };
  const invoices = mInvoiceBoard?.list;

  const data = useMemo(() => {
    let mData = [];
    if (invoices) {
      invoices?.forEach((id, i) => {
        let mInvoice = invoice?.invoiceMap[id];
        const tax_percent = mInvoice?.price?.tax || 20;
        const net_price = mInvoice?.price?.net || 0;
        const tax = (net_price * tax_percent) / 100;
        const totalPrice = mInvoice?.price?.total || net_price + tax;

        let bookedItem = mInvoice?.items[0];

        mData.push({
          sno: i + 1,
          key: id,
          id: `#${mInvoice?.invoice_id}`,
          booked_by: mInvoice?.created_by,
          members: mInvoice?.members,
          total: `£ ${parseFloat(totalPrice || 0).toFixed(2)}`,
          issuedate: moment(mInvoice?.invoice_date).format("DD MMM YYYY"),
          action: id,
          resource:
            resourceTypes[bookedItem?.desk_type || bookedItem?.type]?.name,
        });
      });
    }

    return mData;
  }, [invoice?.invoiceMap, invoices]);

  return (
    <div>
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Invoice Listing</span>
      </Row>
      <Card
        title="Filter"
        className="nw_filter_container"
        size="small"
        bordered={false}
      >
        <DatePicker
          style={{
            width: "17%",
            fontWeight: "500",
            marginRight: "15px",
          }}
          className="nw_mobile_container"
          onChange={handleChange("from")}
          placeholder="From"
          value={filter?.from}
          disabledDate={disabledDate({ isFutureDateDisabled: true })}
          allowClear
          size="large"
          inputReadOnly={true}
        />
        <DatePicker
          style={{
            width: "17%",
            fontWeight: "500",
            marginRight: "15px",
          }}
          onChange={handleChange("to")}
          placeholder="To"
          value={filter?.to}
          disabledDate={disabledDate({ isFutureDateDisabled: true })}
          allowClear
          size="large"
          className="nw_mobile_container"
          inputReadOnly={true}
        />
        <Select
          className="mr5 nw_mobile_container"
          style={{
            paddingTop: "5px",
            width: "15%",
            fontWeight: "500",
            marginRight: "15px",
          }}
          placeholder={"Resource Type"}
          value={filter?.resource_type}
          onChange={handleChange("resource_type")}
          allowClear
          size="large"
        >
          {Object.values(resourceTypes).map((resourceType) => (
            <Select.Option key={resourceType?._id} value={resourceType?._id}>
              {resourceType?.name}
            </Select.Option>
          ))}
        </Select>

        <Button
          className="mr10 nw_btn_black"
          size="large"
          type="primary"
          onClick={onSearch}
        >
          Search
        </Button>
        <Button
          className="mr10 nw_btn_white"
          size="large"
          type="primary-outline"
          onClick={onReset}
        >
          Reset
        </Button>
      </Card>

      <MTable
        columns={invoiceColumns(handleClick)}
        dataSource={data}
        className="mt20"
        bordered={false}
        pagination={true}
      />
    </div>
  );
};

export default InvoiceList;
