import React, { useState, useCallback } from "react";
import moment from "moment";
import {
  Col,
  Row,
  Button,
  DatePicker,
  TimePicker,
  Input,
  Segmented,
  InputNumber,
  Slider,
} from "antd";
import { useLocation } from "react-router";
import { ClockCircleOutlined } from "@ant-design/icons";
import {
  formatDateTime,
  calculateBusinessDays,
  searchStringToObjMap,
  segmentedDays,
} from "../../../utils/CommonFunctions";
import banner3 from "../../images/banner_img_3.png";

import {
  disabledDate,
  disabledHours,
  disabledMinutes,
} from "../../../utils/CommonFunctions";
import { useSelector } from "react-redux";

const SelectDateTimeScreen = (props) => {
  const { resource_type } = props;

  const [state, setState] = useState({
    from_to_date: [],
    from_date: null,
    from_time: null,
    duration: null,
    noof_days: 1,
    to_month: null,
  });
  const { search } = useLocation();
  const { spaceId } = searchStringToObjMap(search);

  const mWorkspace = useSelector(
    (state) => state.workSpace.workSpaceMap?.[spaceId]
  );

  const {
    from_to_date,
    from_date,
    from_time,
    duration,
    to_month,
    noof_days,
    durationType = "Days",
  } = state;

  const onNext = () => {
    if (
      resource_type === "meeting_room" &&
      from_date &&
      from_time &&
      duration
    ) {
      let m_from_date = formatDateTime(from_date, "dt");
      let m_from_time = formatDateTime(from_time, "dt");

      props?.onNext?.(
        `${search}&from_date=${m_from_date}&from_time=${m_from_time}&duration=${duration}`
      );
    } else {
      if (durationType === "Days" && from_to_date.length) {
        if (from_to_date?.length === 2) {
          let from_date_format = formatDateTime(from_to_date[0], "d");
          let to_date_format = formatDateTime(from_to_date[1], "d");

          props?.onNext?.(
            `${search}&from_date=${from_date_format}&to_date=${to_date_format}`
          );
        }
      } else if (durationType === "Months" && from_date && to_month) {
        let from_date_format = formatDateTime(from_date, "d");
        let to_date_format = formatDateTime(
          moment(to_month).endOf("month"),
          "d"
        );
        props?.onNext?.(
          `${search}&from_date=${from_date_format}&to_date=${to_date_format}`
        );
      } else if (durationType === "Longer" && from_date && noof_days) {
        let from_date_format = formatDateTime(from_date, "d");
        let to_date_format = formatDateTime(
          moment(from_date).add(noof_days - 1, "days"),
          "d"
        );
        props?.onNext?.(
          `${search}&from_date=${from_date_format}&to_date=${to_date_format}`
        );
      }
    }
  };

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      const newState = { [name]: value };

      if (durationType === "Months") {
        if (name === "from_date") {
          newState.totalWorkingDays = 0;
          newState.to_month = null;
        } else if (name === "to_month" && from_date) {
          const startDate = from_date;
          const endDate = moment(value).endOf("month");

          newState.totalWorkingDays = calculateBusinessDays(
            startDate,
            endDate,
            mWorkspace?.no_of_days
          );
        }
      }

      setState((preState) => ({
        ...preState,
        ...newState,
      }));
    },
    [mWorkspace?.no_of_days, durationType, from_date]
  );

  return (
    <>
      <Row className="spacerTBMain">
        <Col lg={12} sm={24}>
          <div className="leftpanelContainer">
            <h2>
              2.{" "}
              {props?.resource_type === "meeting_room"
                ? "What's your booking date & how long you're willing to stay?"
                : "What's your moving date & how long you're willing to stay?"}
            </h2>
            <div className="li_c_b">
              {props?.resource_type !== "meeting_room" && (
                <Col className="booking-duration-container mb15">
                  <Segmented
                    options={segmentedDays}
                    value={durationType}
                    onChange={handleChange("durationType")}
                    block
                    size="large"
                  />
                </Col>
              )}
              <Row className="inputContainer data_s_c_b">
                {resource_type === "meeting_room" ? (
                  <>
                    <Col md={9} xs={24} className="i_i_c_b">
                      <span className="s_c_d_b">
                        <i>&nbsp;</i>
                      </span>

                      <DatePicker
                        className="datarangecontainer data_c_c_b"
                        style={{
                          width: "100%",
                          fontWeight: "700",
                          textAlign: "center",
                        }}
                        bordered={false}
                        onChange={handleChange("from_date")}
                        disabledDate={disabledDate({
                          activeDays: mWorkspace?.no_of_days,
                        })}
                        size="large"
                        inputReadOnly={true}
                      />
                    </Col>

                    <Col md={9} xs={24} className="i_i_c_b">
                      <span className="s_c_d_b">
                        <ClockCircleOutlined
                          style={{ fontSize: 24, color: "rgba(0, 0, 0, 0.3)" }}
                        />
                      </span>

                      <TimePicker
                        className="datarangecontainer data_c_c_b"
                        style={{
                          width: "100%",
                          fontWeight: "700",
                          textAlign: "center",
                        }}
                        bordered={false}
                        size="large"
                        format="HH:mm"
                        value={from_time}
                        minuteStep={30}
                        onChange={handleChange("from_time")}
                        disabledHours={() =>
                          disabledHours(mWorkspace, from_date)
                        }
                        disabledMinutes={() => disabledMinutes(from_date)}
                        showNow={false}
                        hideDisabledOptions={true}
                        inputReadOnly={true}
                      />
                    </Col>

                    <Col md={6} xs={24} className="i_i_c_b" style={{ borderLeft: 0 }}>
                      <Input
                        className="datarangecontainer data_c_c_b"
                        style={{
                          width: "100%",
                          fontWeight: "700",
                          textAlign: "center",
                        }}
                        bordered={false}
                        type="number"
                        placeholder="In hours"
                        min={1}
                        step={0.5}
                        value={duration}
                        onChange={handleChange("duration")}
                        size="large"
                      />
                    </Col>
                  </>
                ) : (
                  <Col style={{ width: "100%" }}>
                    {durationType === "Days" && (
                      <div className="i_i_c_b">
                        <span className="s_c_d_b">
                          <i>&nbsp;</i>
                        </span>

                        <DatePicker.RangePicker
                          className="datarangecontainer data_c_c_b"
                          style={{
                            width: "100%",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                          bordered={false}
                          onChange={handleChange("from_to_date")}
                          disabledDate={disabledDate({
                            activeDays: mWorkspace?.no_of_days,
                          })}
                          inputReadOnly={true}
                        />
                      </div>
                    )}

                    {durationType === "Months" && (
                      <>
                        <Row className="mt10 nw_flex_center">
                          <Col md={8} xs={24}>
                            <label className="label mob_spcer">Move-in date</label>
                          </Col>
                          <Col md={16} xs={24}>
                            <DatePicker
                              style={{ width: "100%" }}
                              className="custom-input"
                              value={from_date}
                              onChange={handleChange("from_date")}
                              disabledDate={disabledDate({
                                activeDays: mWorkspace?.no_of_days,
                              })}
                              size="large"
                              inputReadOnly={true}
                            />
                          </Col>
                        </Row>

                        <Row className="mt10 nw_flex_center">
                          <Col md={8} xs={24}>
                            <label className="label mob_spcer">Select end-month</label>
                          </Col>
                          <Col md={16} xs={24}>
                            <DatePicker
                              style={{ width: "100%" }}
                              className="custom-input"
                              picker="month"
                              value={state?.to_month}
                              format={"MMM-YY"}
                              onChange={handleChange("to_month")}
                              disabled={!from_date}
                              disabledDate={disabledDate({
                                activeDays: mWorkspace?.no_of_days,
                                toDisabledDate: state?.from_date,
                              })}
                              size="large"
                              inputReadOnly={true}
                            />
                          </Col>
                        </Row>
                        {!!state?.totalWorkingDays && (
                          <Row className="mt10">
                            <Col span={6}>
                              <label className="label">
                                Total Working Days
                              </label>
                            </Col>

                            <Col span={18}>
                              <Input
                                className="custom-input"
                                style={{ width: 150 }}
                                type="number"
                                min={1}
                                step={1}
                                value={state?.totalWorkingDays}
                                disabled
                              />
                            </Col>
                          </Row>
                        )}
                      </>
                    )}

                    {durationType === "Longer" && (
                      <>
                        <Row className="mt10 nw_flex_center">
                          <Col md={8} xs={24}>
                            <label className="label mob_spcer">Move-in date</label>
                          </Col>

                          <Col md={16} xs={24}>
                            <DatePicker
                              className="custom-input"
                              value={from_date}
                              onChange={handleChange("from_date")}
                              disabledDate={disabledDate({
                                activeDays: mWorkspace?.no_of_days,
                              })}
                              size="large"
                              style={{ width: "100%" }}
                              inputReadOnly={true}
                            />
                          </Col>
                        </Row>

                        <Row className="mt10 nw_flex_center mb15">
                          <Col md={8} xs={24}>
                            <label className="label mob_spcer">No of days</label>
                          </Col>

                          <Col md={16} xs={24}>
                            <InputNumber
                              className="custom-input"
                              value={noof_days}
                              min={1}
                              max={365}
                              defaultValue={noof_days}
                              onChange={handleChange("noof_days")}
                              size="large"
                              style={{ width: "100%" }}
                            />
                          </Col>
                        </Row>
                        <Slider
                          value={noof_days}
                          min={1}
                          max={365}
                          defaultValue={noof_days}
                          onChange={handleChange("noof_days")}
                          marks={{
                            1: "1",
                            365: "365",
                          }}

                        />
                        {state?.from_date && state?.noof_days && (
                          <Row className="mt10">
                            <Col span={6}>
                              <label className="label">End Date</label>
                            </Col>

                            <Col span={18}>
                              <Input
                                className="custom-input"
                                value={moment(state?.from_date)
                                  .add(state?.noof_days - 1, "days")
                                  .format("DD MMM YYYY")}
                                disabled
                              />
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  </Col>
                )}
              </Row>

              <div className="btn_s_c">
                <div className="spacerdata">
                  <Button type="primary" onClick={onNext}>
                    Next
                  </Button>
                  <Button onClick={props?.onBack}>Back</Button>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col lg={12} sm={24}>
          <div className="bannercontainer">
            <img src={banner3} alt="Banner" />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SelectDateTimeScreen;
