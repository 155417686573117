import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../spinner/Spinner";
import AboutUsTabCard from "./components/AboutUsTabCard";
import AboutUsTabModal from "./components/AboutUsTabModal";
import PageMetaModal from "./components/PageMetaModal";

import { getAllCMS } from "./duck/CMSActions";
import { checkPermission } from "../utils/CommonFunctions";
import constants from "../utils/Constants";

const { CMS_TYPES, CMS_PAGES } = constants;

const AboutUs = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({});
  const [showPageMetaModal, setShowPageMetaModal] = useState(false);
  const [boardKey, setBoardKey] = useState();
  const isCMSEditable = useMemo(() => checkPermission({ row: "cms" }), []);

  const cmsBoard = useSelector(
    (state) => state?.cms?.boards?.[JSON.stringify(boardKey)]
  );

  const pageMetaBoardParams = useMemo(
    () => ({ type: CMS_TYPES.PAGE_META, page_name: CMS_PAGES.ABOUT_US }),
    []
  );
  const pageMeta = useSelector(({ cms }) => {
    const boards = cms.boards[JSON.stringify(pageMetaBoardParams)];
    const pageMetaId = boards?.list?.[0];

    return Object.assign({}, cms.cmsMap[pageMetaId]);
  });

  useEffect(() => {
    const params = { type: CMS_TYPES.ABOUT_US };
    setBoardKey(params);
    dispatch(getAllCMS(params));
    dispatch(getAllCMS(pageMetaBoardParams));
  }, [dispatch, pageMetaBoardParams]);

  const handleModal = useCallback((show = false, tabId) => {
    show = typeof show === "boolean" && show;

    setState((preState) => ({ ...preState, showModal: show, tabId }));
  }, []);

  const loading = cmsBoard?.loading && !cmsBoard?.list?.length;

  return loading ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb10">
        <span className="nw_heading">About Us</span>

        <span>
          {isCMSEditable && (
            <Button
              type="primary-outline mr10"
              onClick={() => setShowPageMetaModal(true)}
            >
              Edit Page Meta
            </Button>
          )}

          {isCMSEditable && (
            <Button type="primary-outline" onClick={() => handleModal(true)}>
              + Add Tab
            </Button>
          )}
        </span>
      </Row>

      <Row>
        {cmsBoard?.list?.map((id, i) => (
          <AboutUsTabCard
            key={i}
            id={id}
            showModal={handleModal}
            isCMSEditable={isCMSEditable}
          />
        ))}
      </Row>

      <AboutUsTabModal
        visible={state?.showModal}
        selectedId={state?.tabId}
        showModal={handleModal}
        isCMSEditable={isCMSEditable}
      />

      <PageMetaModal
        visible={showPageMetaModal}
        selectedId={pageMeta?._id}
        showModal={() => setShowPageMetaModal(false)}
        isCMSEditable={isCMSEditable}
        pageName={CMS_PAGES.ABOUT_US}
      />
    </div>
  );
};

export default AboutUs;
