import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import {
  Row,
  Col,
  Input,
  Checkbox,
  Button,
  Typography,
  Tooltip,
  Popconfirm,
} from "antd";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { createCMS, getAllCMS, updateCMS } from "../duck/CMSActions";
import {
  checkPermission,
  getTagFreeContent,
} from "../../utils/CommonFunctions";
import Spinner from "../../spinner/Spinner";
import Routes from "../../config/Routes";

import FaqQNAModal from "./FaqQNAModal";
const { Paragraph } = Typography;

const EditFaqPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const preProps = useRef();
  const categoryId = useParams()?.id;

  const cms = useSelector((state) => state?.cms);
  const isCMSEditable = useMemo(() => checkPermission({ row: "cms" }), []);
  const ExtraIcon = useMemo(
    () => (isCMSEditable ? EditOutlined : EyeOutlined),
    [isCMSEditable]
  );

  const mCms = useMemo(
    () =>
      categoryId
        ? cms?.cmsMap?.[categoryId]
        : { loading: cms?.loading, error: cms?.error },
    [categoryId, cms]
  );
  const [err, handleErr] = useState({});
  const [qna, setQNA] = useState([]);

  const [state, setState] = useState({});

  const [faqObj, setFaqObj] = useState({
    name: "",
    active: true,
  });

  const preload = useCallback(() => {
    handleErr({});
    setFaqObj({
      name: mCms?.name,
      active: mCms?.active,
    });
    setQNA(mCms?.meta?.qna ?? []);
  }, [mCms?.active, mCms?.meta?.qna, mCms?.name]);

  useEffect(() => {
    if (categoryId && !mCms) dispatch(getAllCMS({ type: "Faq" }));

    if (preProps?.current?.loading && !mCms?.loading) {
      history.replace(Routes.faqPage);
    }
    if (mCms && !mCms?.loading) preload();

    return () => {
      preProps.current = { loading: mCms?.loading };
    };
  }, [categoryId, mCms, dispatch, history, preload]);

  const handleQNA = useCallback(
    (qnaObj) => {
      let { index, ...rest } = qnaObj;
      if (index !== undefined) {
        setQNA(qna.map((obj, i) => (i === index ? rest : obj)));
      } else {
        setQNA((preState) => [...preState, qnaObj]);
      }
    },
    [qna]
  );
  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e?.target?.checked ?? e;

      setFaqObj((preState) => ({ ...preState, [name]: value }));
      handleErr({});
    },
    []
  );
  const handleRemove = useCallback(
    (index) => {
      setQNA(qna.filter((_, i) => i !== index));
    },
    [qna]
  );

  const handleModal = useCallback((show = false, selectedObj) => {
    show = typeof show === "boolean" && show;

    setState((preState) => ({ ...preState, showModal: show, selectedObj }));
  }, []);

  let { name, active } = faqObj;

  const hasError = useCallback(() => {
    let error = {};
    if (!name) {
      error.name = "Please Enter Category Name";
    }

    handleErr(error);
    return !!Object.keys(error).length;
  }, [name]);

  const add = useCallback(() => {
    if (!hasError()) {
      let { name, active } = faqObj;
      const payload = {
        type: "Faq",
        name,
        active,
        meta: JSON.stringify({
          qna,
        }),
      };
      if (categoryId) {
        payload._id = categoryId;

        dispatch(updateCMS(payload));
      } else {
        dispatch(createCMS(payload));
      }
    }
  }, [hasError, faqObj, qna, categoryId, dispatch]);

  const disabled = !isCMSEditable || mCms?.loading;

  return categoryId && !mCms ? (
    <Spinner />
  ) : (
    <div className="mainMember">
      <Row align="middle" justify="space-between" className="mb20">
        <span>
          <h2 style={{ fontSize: 22, fontWeight: 600, marginBottom: 0 }}>
            {categoryId ? "Edit Category" : "Add Category"}
          </h2>
        </span>
        <span>
          {isCMSEditable && (
            <Button type="primary" loading={mCms?.loading} onClick={add}>
              Save Category
            </Button>
          )}

          <Button
            className="ml10"
            disabled={mCms?.loading}
            onClick={() => history.replace(Routes.faqPage)}
          >
            <ArrowLeftOutlined />
            Back
          </Button>
        </span>
      </Row>
      <div className="mainMember-content">
        <Col>
          {/* category name start */}
          <Row align="middle">
            <Col span={4}>
              <label className="label">Category Name</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col span={6}>
              <Input
                className="custom-input fw mt10"
                placeholder={"Name"}
                size="large"
                allowClear
                value={name}
                onChange={handleChange("name")}
                disabled={disabled}
              />
              <Row className="error mt5">{err?.name}</Row>
            </Col>
          </Row>

          {/* category name end */}

          {/* Active start */}
          {categoryId && (
            <Row align="top" className="mt10">
              <Col span={4}>
                <label className="label">Status</label>
              </Col>
              <Col span={6} className="mt10">
                <Checkbox
                  checked={active}
                  disabled={disabled}
                  onChange={handleChange("active")}
                >
                  Active
                </Checkbox>
              </Col>
            </Row>
          )}
          {/* Active End */}

          {/* QNA Start */}
          <Row align="middle" className="mt20">
            <Col span={4}>
              <label className="label">FAQs</label>
            </Col>
            <Col span={4} className="mb20">
              <Button type="primary-outline" onClick={() => handleModal(true)}>
                + Add QNA
              </Button>
            </Col>
          </Row>
          {qna?.map((item, index) => (
            <Row align="middle" className="mb10" key={index}>
              <Col span={4} />
              <Col span={14}>
                <Row>
                  <Col span={3}>
                    <label className="label">Question</label>
                  </Col>
                  <Col span={12}>
                    <Paragraph>
                      {getTagFreeContent(item?.question)}
                    </Paragraph>
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <label className="label">Answer</label>
                  </Col>
                  <Col span={15}>
                    <Paragraph ellipsis={{ rows: 2 }}>
                      <Col dangerouslySetInnerHTML={{ __html: item?.answer }} />
                    </Paragraph>
                  </Col>
                  {isCMSEditable && (
                    <Tooltip title="Delete">
                      <Popconfirm
                        title="Are you sure to delete this?"
                        onConfirm={() => handleRemove(index)}
                        okText="Yes"
                        cancelText="No"
                        placement="bottom"
                        disabled={disabled}
                      >
                        <DeleteOutlined
                          style={{
                            fontSize: "17px",
                            cursor: "pointer",
                            margin: " 0 20px",
                          }}
                        />
                      </Popconfirm>
                    </Tooltip>
                  )}
                  <Tooltip title="Edit">
                    <ExtraIcon
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleModal(true, { ...item, index })}
                    />
                  </Tooltip>
                </Row>
                <hr />
              </Col>
            </Row>
          ))}
          {/* QNA End */}
        </Col>
      </div>
      <FaqQNAModal
        visible={state?.showModal}
        selectedObj={state?.selectedObj}
        showModal={handleModal}
        isCMSEditable={isCMSEditable}
        handleQNA={handleQNA}
      />
    </div>
  );
};

export default EditFaqPage;
