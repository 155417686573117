import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'antd';
import { useLocation } from 'react-router';

import banner1 from '../../images/banner_img.png';

import { resourceTypes } from '../../../utils/CommonFunctions';

const SelectResourceScreen = (props) => {
    const { showOnlyResources = ["flexi_desk", "dedicated_desk", "private_office"] } = props;

    const { search } = useLocation();

    const [state, setState] = useState({
        resource_type: null,
        options: [],
    });

    useEffect(() => {
        let options = [];

        if (Array.isArray(showOnlyResources) && showOnlyResources?.length > 0) {
            options = showOnlyResources.map(r_id => resourceTypes[r_id]);
            options = options.filter(option => option);
        }

        setState({
            resource_type: options?.[0]?._id,
            options,
        });
    }, []);

    const onNext = () => {
        if (state?.resource_type) {
            props?.onNext?.(`${search}&resource_type=${state?.resource_type}`);
        }
    }

    const { resource_type, options } = state;

    return (
        <>
            <Row className="spacerTBMain">
                <Col lg={12} sm={24}>
                    <div className="leftpanelContainer">
                        <h2>1. What type of workspace are you looking for?</h2>

                        <div className="li_c_b">
                            {
                                options?.map?.(option =>
                                    <div
                                        key={option?._id}
                                        className={`d_c_b ${option?._id === resource_type && 'activeRadio'}`}
                                        onClick={() => setState(preState => ({ ...preState, resource_type: option?._id }))}
                                    >
                                        <span className="d_d_c_b"><i></i> {option?.name}</span>
                                        {
                                            option?.icon &&
                                            <div className="img_c_b">
                                                <img src={option?.icon} alt="Desk_image" />
                                            </div>
                                        }
                                    </div>
                                )
                            }

                            <div className="btn_s_c">
                                <div className="spacerdata">
                                    <Button type="primary" onClick={onNext}>Next</Button>
                                    <Button onClick={props?.onBack}>Back</Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </Col>

                <Col lg={12} sm={24}>
                    <div className="bannercontainer">
                        <img src={banner1} alt="Banner" />
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default SelectResourceScreen;