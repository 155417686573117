import { Row, Table } from "antd";
import Spinner from "../spinner/Spinner";
import { useEffect } from "react";
import { fetchAllDoors } from "./duck/DoorActions";
import { useSelector, useDispatch } from "react-redux";
import { doorColumns } from "./components/DoorColumns";
import MTable from "../utils/MTable";

const DoorList = () => {
  const dispatch = useDispatch();
  const activeWorkspace = useSelector((state) => state?.workSpace?.active);
  const door = useSelector((state) => state?.door);
  let data = door?.doors?.[activeWorkspace]?.map((obj) => ({
    ...obj,
    key: obj?.id,
  }));

  useEffect(() => {
    !data &&
      activeWorkspace &&
      dispatch(fetchAllDoors({ space_id: activeWorkspace }));
  }, [activeWorkspace]);

  let doorSpin = !data && door?.loading;

  return doorSpin ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Door Listing</span>
      </Row>
      <MTable
        columns={doorColumns()}
        dataSource={data}
        className="mt20"
        bordered={false}
      />
    </div>
  );
};

export default DoorList;
