import { Row, Button, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoles } from "./duck/RoleActions";
import { EditOutlined, SearchOutlined, EyeOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { checkPermission } from "../utils/CommonFunctions";
import Spinner from "../spinner/Spinner";
import { useHistory } from "react-router";
import "./components/customTable.scss";
import Routes from "../config/Routes";
import { roleColumns } from "./components/RoleColumns";
import MTable from "../utils/MTable";

const Role = () => {
  const roles = useSelector((state) => state?.role);
  const allRoles = useSelector((state) => state?.role.roles);
  const { rolesList, loading } = roles;

  let editable = checkPermission({ row: "roles" });

  const dispatch = useDispatch();
  const history = useHistory();

  const roleSpin = !rolesList.length && loading;

  const [search, toggleSearch] = useState(false);
  const [searchField, setSearchField] = useState("");

  useEffect(() => {
    !rolesList.length && dispatch(getAllRoles());
  }, []);

  let data = [];
  rolesList &&
    rolesList?.forEach((elm, index) => {
      const role = allRoles[elm];
      data.push({
        key: role?._id,
        sn: ++index,
        role: role?.name,
        status: role?.active ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
        action: editable ? (
          <EditOutlined
            onClick={() => history.push(`${Routes.role}?id=${role?._id}`)}
          />
        ) : (
          <EyeOutlined
            onClick={() => history.push(`${Routes.role}?id=${role?._id}`)}
          />
        ),
      });
    });

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };
  data = data.filter((elm) => elm?.role?.includes(searchField));

  return roleSpin ? (
    <Spinner />
  ) : (
    <div className="mainMember">
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Roles</span>
        <Row>
          <Input
            size="middle"
            onFocus={() => toggleSearch(true)}
            onBlur={() => toggleSearch(false)}
            className="custom-input mr10 white"
            style={{ width: search ? 320 : 160 }}
            placeholder="Search Role"
            prefix={<SearchOutlined />}
            value={searchField}
            onChange={(e) => handleChange(e)}
          />

          {editable && (
            <Button
              type="primary-outline"
              onClick={() => history.push(Routes.role)}
            >
              + Add Role
            </Button>
          )}
        </Row>
      </Row>

      <MTable
        columns={roleColumns()}
        dataSource={data}
        className="mt20"
        bordered={false}
      />
    </div>
  );
};

export default Role;
