import { Button, Row } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import AddHotDesk from "./components/AddHotDesk";
import Spinner from "../spinner/Spinner";
import DeskAreaCard from "./components/DeskAreaCard";

import { checkPermission } from "../utils/CommonFunctions";
import resourceSvg from "../assets/svg/resource.svg";

const HotDesk = () => {
  const [visible, showModal] = useState(false);
  const [selectedDesk, selectDesk] = useState(null);
  const editable = useMemo(
    () => checkPermission({ row: "resources", subRow: "desk" }),
    []
  );

  const activeWorkSpace = useSelector((state) => state.workSpace?.active);
  const resources = useSelector((state) => state.resources?.resources);
  const desks = useMemo(
    () => resources?.[activeWorkSpace]?.desks || [],
    [resources, activeWorkSpace]
  );
  const desksSpin = !desks?.length && resources?.loading;

  const handleModal = useCallback((show = false, resourceId) => {
    show = typeof show === "boolean" && show;
    showModal(show);
    selectDesk(resourceId);
  }, []);

  return desksSpin ? (
    <Spinner />
  ) : (
    <div className="resourceMain">
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Desk Area</span>
        {editable && (
          <Button
            type="primary-outline"
            onClick={() => handleModal(true, null)}
          >
            + Add Desk Area
          </Button>
        )}
      </Row>

      {desks?.length === 0 && (
        <Row justify="center" align="middle" className="mt20 pt20">
          <img src={resourceSvg} alt="No resource added!" />
        </Row>
      )}

      <Row>
        {desks?.map?.((id) => (
          <DeskAreaCard key={id} id={id} onEdit={handleModal} />
        ))}
      </Row>

      <AddHotDesk
        visible={visible}
        handleModal={handleModal}
        selectedDesk={selectedDesk}
        editable={editable}
      />
    </div>
  );
};

export default HotDesk;
