import React, { useMemo } from "react";
import { Card, Tooltip, Typography } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { EyeOutlined, EditOutlined, EllipsisOutlined } from "@ant-design/icons";
import Routes from "../../config/Routes";

import { getTagFreeContent } from "../../utils/CommonFunctions";

const { Paragraph } = Typography;

const FaqCategoryCard = (props) => {
  const { id, isCMSEditable } = props;
  const history = useHistory();

  const mCms = useSelector((state) => state?.cms?.cmsMap?.[id]);
  const ExtraIcon = useMemo(
    () => (isCMSEditable ? EditOutlined : EyeOutlined),
    [isCMSEditable]
  );

  return (
    <Card
      style={{
        width: 300,
        margin: "10px 20px 10px 0",
        borderRadius: 5,
      }}
      title={mCms?.name}
      bordered={false}
      hoverable
      extra={
        <Tooltip title={isCMSEditable ? "Edit" : "More Details"}>
          <ExtraIcon
            style={{ fontSize: 12 }}
            onClick={() => history.push(`${Routes.editFaq}/${id}`)}
          />
        </Tooltip>
      }
    >
      <Paragraph ellipsis={{ rows: 5 }}>
        {getTagFreeContent(mCms?.meta?.qna?.[0]?.question)}
      </Paragraph>
      <EllipsisOutlined style={{ fontSize: 12 }} />
    </Card>
  );
};

export default FaqCategoryCard;
