import Actions from "./DashboardActionTypes";

const initialState = {
  dashboards: {},
  loading: false,
  error: null,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_DASHBOARD:
    case Actions.FETCH_MEMBER_DASHBOARD:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });

    case Actions.FETCH_DASHBOARD_SUCCESS: {
      const workSpaceId = action?.payload?.space_id;

      return Object.assign({}, state, {
        dashboards: Object.assign({}, state?.dashboards, {
          [workSpaceId]: Object.assign(
            {},
            state?.dashboards[workSpaceId],
            action?.payload
          ),
        }),
        loading: false,
      });
    }

    case Actions.FETCH_MEMBER_DASHBOARD_SUCCESS: {
      return Object.assign({}, state, {
        dashboards: Object.assign({}, state?.dashboards, action.payload),
        loading: false,
      });
    }

    case Actions.FETCH_DASHBOARD_FAILURE:
    case Actions.FETCH_MEMBER_DASHBOARD_FAILURE: {
      return Object.assign({}, state, {
        error: action?.payload,
        loading: false,
      });
    }

    default:
      return state;
  }
};

export default dashboardReducer;
