import { Button, Checkbox, Col, Input, Row, Select } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomModal from "../../utils/CustomModal";

import { createCMS, updateCMS } from "../duck/CMSActions";
import constants from "../../utils/Constants";

const { CMS_TYPES } = constants;

const OfferPageMetaModal = (props) => {
  const prevProp = useRef();
  const dispatch = useDispatch();

  const { visible, selectedId, showModal, isCMSEditable, pageName } = props;

  const [state, setState] = useState({});
  const [error, setError] = useState({});

  const cms = useSelector(({ cms }) => cms);
  const mCms = useMemo(
    () =>
      selectedId
        ? cms.cmsMap[selectedId]
        : { loading: cms.loading, error: cms.error },
    [selectedId, cms]
  );
  const cmsBoard = useMemo(
    () => cms?.boards?.[JSON.stringify({ type: CMS_TYPES.OFFER })],
    [cms]
  );

  useEffect(() => {
    if (prevProp?.current?.loading && !mCms?.loading) {
      setState({});
      showModal?.(false);
    } else if (!prevProp?.current?.visible && visible) {
      let mState = {};

      if (mCms) {
        const page = mCms?.meta?.page;
        const ribbonTag = mCms?.meta?.ribbonTag;
        const offerTag = mCms?.meta?.offerTag;

        mState = {
          metaTitle: mCms?.name,
          metaDescription: mCms?.description,
          ribbon_line_1: ribbonTag?.line_1,
          ribbon_line_2: ribbonTag?.line_2,
          ...page,
          ...offerTag,
        };
      }

      setState(mState);
      setError({});
    }

    return () => {
      prevProp.current = {
        loading: mCms?.loading,
        visible,
      };
    };
  }, [mCms, visible, showModal]);

  const handleChange = useCallback(
    (name, isCheckbox = false) =>
      (e) => {
        let value;
        if (isCheckbox) {
          value = e?.target?.checked;
        } else {
          value = e?.target?.value ?? e;
        }

        setState((preState) => ({ ...preState, [name]: value }));
        setError({});
      },
    []
  );

  const hasError = useCallback(() => {
    let {
      metaTitle,
      metaDescription,
      heading,
      description,
      ribbon_line_1,
      ribbon_line_2,
      offer_name,
    } = state;
    metaTitle = metaTitle?.trim?.();
    metaDescription = metaDescription?.trim?.();

    heading = heading?.trim?.();
    description = description?.trim?.();

    ribbon_line_1 = ribbon_line_1?.trim?.();
    ribbon_line_2 = ribbon_line_2?.trim?.();

    offer_name = offer_name?.trim?.();

    const error = {};

    if (!metaTitle) error.metaTitle = "Title cannot be blank";
    if (!metaDescription) error.metaDescription = "Description cannot be blank";

    if (!heading) error.heading = "Heading cannot be blank";
    if (!description) error.description = "Description cannot be blank";

    if (!ribbon_line_1) error.ribbon_line_1 = "Line 1 cannot be blank";
    if (!ribbon_line_2) error.ribbon_line_2 = "Line 2 cannot be blank";

    if (!offer_name) error.offer_name = "Offer name cannot be blank";

    setError(error);
    return Object.keys(error).length;
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      let {
        metaTitle,
        metaDescription,
        heading,
        description,

        ribbon_line_1,
        ribbon_line_2,

        offer_name,
        navigate_to,
        active_tag,
      } = state;
      metaTitle = metaTitle?.trim?.();
      metaDescription = metaDescription?.trim?.();

      heading = heading?.trim?.();
      description = description?.trim?.();

      ribbon_line_1 = ribbon_line_1?.trim?.();
      ribbon_line_2 = ribbon_line_2?.trim?.();

      const meta = {
        page: { heading, description },
        ribbonTag: { line_1: ribbon_line_1, line_2: ribbon_line_2 },
        offerTag: { offer_name, navigate_to, active_tag: !!active_tag },
        page_name: pageName,
      };

      const payload = {
        name: metaTitle,
        description: metaDescription,
        type: CMS_TYPES.PAGE_META,
        meta: JSON.stringify(meta),
      };

      if (selectedId) {
        payload._id = selectedId;
        dispatch(updateCMS(payload));
      } else {
        dispatch(createCMS(payload));
      }
    }
  }, [selectedId, state, hasError, dispatch, pageName]);

  const disabled = useMemo(
    () => !isCMSEditable || mCms?.loading,
    [isCMSEditable, mCms?.loading]
  );

  return (
    <CustomModal
      className="cms-modal"
      visible={visible}
      title={"Page Details"}
      onCancel={props.showModal}
      footer={
        <>
          <Button onClick={props.showModal}>Close</Button>

          {isCMSEditable && (
            <Button type="primary" loading={mCms?.loading} onClick={onSubmit}>
              Update
            </Button>
          )}
        </>
      }
    >
      <Col className="content">
        {/* meta details satrt */}
        <>
          <Row className="section mb10" align="middle" justify="center">
            Meta Details
          </Row>

          {/* title start */}
          <Row className="mb10" align="middle">
            <Col span={6} sm={24} lg={6} xl={5} xxl={4}>
              <label className="label">Title</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} sm={24} lg={18} xl={19} xxl={20}>
              <Input
                className="custom-input"
                placeholder="Title"
                size="large"
                type="text"
                value={state.metaTitle}
                onChange={handleChange("metaTitle")}
                disabled={disabled}
              />

              <Row className="error mt5">{error.metaTitle}</Row>
            </Col>
          </Row>
          {/* title end */}

          {/* description start */}
          <Row className="mb10">
            <Col span={6} sm={24} lg={6} xl={5} xxl={4}>
              <label className="label">Description </label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} sm={24} lg={18} xl={19} xxl={20}>
              <Input.TextArea
                className="custom-input"
                placeholder="Description"
                size="large"
                type="text"
                value={state.metaDescription}
                onChange={handleChange("metaDescription")}
                autoSize={{ minRows: 2, maxRows: 3 }}
                disabled={disabled}
              />

              <Row className="error mt5">{error.metaDescription}</Row>
            </Col>
          </Row>
          {/* description end */}
        </>
        {/* meta details end */}

        {/* other details start */}
        <>
          <Row className="section mb10" align="middle" justify="center">
            Others Details
          </Row>

          {/* heading start */}
          <Row className="mb10" align="middle">
            <Col span={6} sm={24} lg={6} xl={5} xxl={4}>
              <label className="label">Heading</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} sm={24} lg={18} xl={19} xxl={20}>
              <Input
                className="custom-input"
                placeholder="Heading"
                size="large"
                type="text"
                value={state.heading}
                onChange={handleChange("heading")}
                disabled={disabled}
              />

              <Row className="error mt5">{error.heading}</Row>
            </Col>
          </Row>

          {/* heading end */}

          {/* description start */}
          <Row className="mb10">
            <Col span={6} sm={24} lg={6} xl={5} xxl={4}>
              <label className="label">Description</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} sm={24} lg={18} xl={19} xxl={20}>
              <Input.TextArea
                className="custom-input"
                placeholder="Description"
                size="large"
                type="text"
                value={state.description}
                onChange={handleChange("description")}
                autoSize={{ minRows: 2, maxRows: 3 }}
                disabled={disabled}
              />

              <Row className="error mt5">{error.description}</Row>
            </Col>
          </Row>
          {/* description end */}
        </>
        {/* other details end */}

        {/* ribbon tag start */}
        <>
          <Row className="section mb10" align="middle" justify="center">
            Ribbon Tag Details
          </Row>

          {/* line 1 start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Line 1</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input
                className="custom-input fw mt10"
                placeholder="Line 1"
                size="large"
                allowClear
                value={state.ribbon_line_1}
                onChange={handleChange("ribbon_line_1")}
                disabled={disabled}
              />

              <Row className="error mt5">{error.ribbon_line_1}</Row>
            </Col>
          </Row>
          {/* line 1 end */}

          {/* line 2 start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Line 2</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input
                className="custom-input fw mt10"
                placeholder="Line 2"
                size="large"
                allowClear
                value={state.ribbon_line_2}
                onChange={handleChange("ribbon_line_2")}
                disabled={disabled}
              />

              <Row className="error mt5">{error.ribbon_line_2}</Row>
            </Col>
          </Row>
          {/* line 2 end */}
        </>
        {/* ribbon tag start */}

        {/* offer tag details start */}
        <>
          <Row className="section mb10" align="middle" justify="center">
            Offering Tag Details (for Intro/Home Page)
          </Row>

          {/* offer name start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Name</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Input
                className="custom-input fw mt10"
                placeholder="Name"
                size="large"
                allowClear
                value={state.offer_name}
                onChange={handleChange("offer_name")}
                disabled={disabled}
              />
              <Row className="error mt5">{error.offer_name}</Row>
            </Col>
          </Row>
          {/* offer name end */}

          {/* navigate to start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Navigate To</label>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Select
                className="custom-select fw"
                placeholder="All Offers Page"
                value={state.navigate_to}
                onChange={handleChange("navigate_to")}
                disabled={disabled}
                size="large"
                allowClear
              >
                {cmsBoard?.list?.map?.((_id) => (
                  <Select.Option key={_id} value={_id}>
                    {cms.cmsMap[_id]?.name}
                  </Select.Option>
                ))}
              </Select>

              <Row className="error mt5">{error.navigate_to}</Row>
            </Col>
          </Row>
          {/* navigate to end */}

          {/* navigate to start */}
          <Row className="mb10" align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Show tag</label>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Checkbox
                checked={state.active_tag}
                onChange={handleChange("active_tag", true)}
                disabled={disabled}
              />
            </Col>
          </Row>
          {/* navigate to end */}
        </>
        {/* offer tag details end */}
      </Col>
    </CustomModal>
  );
};

export default React.memo(OfferPageMetaModal);
