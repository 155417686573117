import React, { useCallback, useMemo, useState } from "react";
import TitleComponent from "./miniComponents/TitleComponent";
import DescriptionComponent from "./miniComponents/DescriptionComponent";
import FaqQNAModal from "../components/FaqQNAModal";
import { Button, Col, Popconfirm, Row, Tooltip, Typography } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
const { Paragraph } = Typography;

const FaqComponent = (props) => {
  const {
    state,
    error,
    handleChange,
    items,
    setItems,
    setError,
    isCMSEditable,
    disabled,
  } = props;

  const ExtraIcon = useMemo(
    () => (isCMSEditable ? EditOutlined : EyeOutlined),
    [isCMSEditable]
  );

  const [visible, setVisible] = useState(false);
  const [selectedObj, setSelectedObj] = useState({});

  const handleModal = useCallback((show = false, faqObj = null) => {
    show = typeof show === "boolean" && show;
    setVisible(show);
    setSelectedObj(faqObj);
  }, []);

  const handleQNA = useCallback(
    (qnaObj) => {
      let { index, ...rest } = qnaObj;
      if (index !== undefined) {
        setItems((preItems) =>
          preItems.map((obj, i) => (i === index ? rest : obj))
        );
      } else {
        setItems((preState) => [...preState, qnaObj]);
      }
      setError({});
    },
    [setError, setItems]
  );

  const handleRemove = useCallback(
    (index) => {
      setItems((preItems) => preItems.filter((_, i) => i !== index));
    },
    [setItems]
  );

  return (
    <>
      {/* Title start */}
      <TitleComponent
        value={state?.title}
        handleChange={handleChange}
        disabled={disabled}
        // error={error?.title}
      />
      {/* Title end */}

      {/* Description start */}
      <DescriptionComponent
        value={state?.description}
        handleChange={handleChange}
        disabled={disabled}
      />
      {/* Description end */}

      {/* QNA Start */}
      <Row align="top" className="mt20">
        <Col span={4}>
          <label className="label">FAQs</label>
          <sup style={{ color: "red" }}>*</sup>
        </Col>
        <Col span={4} className="mb20">
          <Button type="primary-outline" onClick={() => handleModal(true)}>
            + Add QNA
          </Button>
          <Row className="error mt5">{error?.foldItems}</Row>
        </Col>
      </Row>
      {items?.map((item, index) => (
        <Row align="middle" className="mb10" key={index}>
          <Col span={4} />
          <Col span={14}>
            <Row>
              <Col span={3}>
                <label className="label">Question</label>
              </Col>
              <Col span={12}>
                <Paragraph>
                  <Col dangerouslySetInnerHTML={{ __html: item?.question }} />
                </Paragraph>
              </Col>
            </Row>
            <Row>
              <Col span={3}>
                <label className="label">Answer</label>
              </Col>
              <Col span={15}>
                <Paragraph ellipsis={{ rows: 2 }}>
                  <Col dangerouslySetInnerHTML={{ __html: item?.answer }} />
                </Paragraph>
              </Col>
              {isCMSEditable && (
                <Tooltip title="Delete">
                  <Popconfirm
                    title="Are you sure to delete this?"
                    onConfirm={() => handleRemove(index)}
                    okText="Yes"
                    cancelText="No"
                    placement="bottom"
                    disabled={disabled}
                  >
                    <DeleteOutlined
                      style={{
                        fontSize: "17px",
                        cursor: "pointer",
                        margin: " 0 20px",
                      }}
                    />
                  </Popconfirm>
                </Tooltip>
              )}
              <Tooltip title="Edit">
                <ExtraIcon
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleModal(true, { ...item, index })}
                />
              </Tooltip>
            </Row>
            <hr />
          </Col>
        </Row>
      ))}
      {/* QNA End */}
      <FaqQNAModal
        visible={visible}
        selectedObj={selectedObj}
        showModal={handleModal}
        isCMSEditable={isCMSEditable}
        handleQNA={handleQNA}
      />
    </>
  );
};

export default FaqComponent;
