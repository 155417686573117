const Actions = {
    FETCH_ALL_INVOICES: 'FETCH_ALL_INVOICES',
    FETCH_ALL_INVOICES_SUCCESS: 'FETCH_ALL_INVOICES_SUCCESS',
    FETCH_ALL_INVOICES_FAILURE: 'FETCH_ALL_INVOICES_FAILURE',

    FETCH_INVOICE: 'FETCH_INVOICE',
    FETCH_INVOICE_SUCCESS: 'FETCH_INVOICE_SUCCESS',
    FETCH_INVOICE_FAILURE: 'FETCH_INVOICE_FAILURE',
}

export default Actions;