import React from "react";
import { Row, Col, Carousel } from "antd";
import img1 from "../images/img1.jpg";
import Locationhome from "./Locationhome";
const Bannerhome = () => {
  return (
    <>
      <Row className="nw_c_c">
        <Col span={24}>
          <Carousel autoplay>
            <div className="img_con">
              <img src={img1} alt="data_image 1" />
            </div>
            <div className="img_con">
              <img src={img1} alt="data_image 2" />
            </div>

            <div className="img_con">
              <img src={img1} alt="data_image 3" />
            </div>
          </Carousel>
        </Col>

        <Locationhome />
      </Row>
    </>
  );
};

export default Bannerhome;
