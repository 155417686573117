import { Row, Col, List } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import reportsTransformer from "../../reports/components/ReportsTransformer";
import NoDataFound from "../../utils/NoDataFound";

const Reports = (props) => {
  let { activitiesList } = props;
  const reportsMap = useSelector((state) => state?.reports?.reportsMap);

  let data = [];
  activitiesList &&
    activitiesList?.forEach((id) => data.push(reportsMap?.[id]));
  return (
    <div>
      <List
        itemLayout="vertical"
        dataSource={data}
        pagination={{
          pageSize: 5,
        }}
        locale={{
          emptyText: <NoDataFound />,
        }}
        renderItem={(item) => (
          <List.Item style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Row justify="space-between" align="middle">
              <Col
                style={{ fontSize: 18, fontWeight: 500, margin: "1px" }}
                align="top"
                key={item?._id}
              >
                {reportsTransformer(item?.onModel)}
                <span style={{ fontSize: 14 }}>{item?.message}</span>
              </Col>
              <Col>{moment(item?.timestamp).fromNow()}</Col>
            </Row>
          </List.Item>
        )}
      />
    </div>
  );
};

export default Reports;
