import React, { useMemo } from "react";
import { Card, Carousel, Image, Tooltip, Typography } from "antd";
import { useSelector } from "react-redux";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";

import { getTagFreeContent } from "../../utils/CommonFunctions";
import noImage from "../../assets/images/no-image.png";

const { Paragraph } = Typography;

const FoldCard = (props) => {
  const { id, isCMSEditable, showDetails } = props;

  const mCms = useSelector((state) => state?.cms?.cmsMap?.[id]);

  const ExtraIcon = useMemo(
    () => (isCMSEditable ? EditOutlined : EyeOutlined),
    [isCMSEditable]
  );

  return (
    <Card
      style={{
        width: 300,
        margin: "10px 20px 10px 0",
        borderRadius: 5,
      }}
      title={getTagFreeContent(mCms?.name)}
      hoverable
      extra={
        <Tooltip title={isCMSEditable ? "Edit" : "More Details"}>
          <ExtraIcon style={{ fontSize: 12 }} onClick={showDetails} />
        </Tooltip>
      }
      cover={
        mCms?.pictures?.length ? (
          <Carousel autoplay>
            {mCms?.pictures?.map((picture, i) => (
              <Image
                className="cover new_image-fluid"
                key={i}
                width={"100%"}
                height={"100%"}
                preview={false}
                wrapperStyle={{ height: 150 }}
                src={picture?.thumb_location}
                alt={`${mCms?.name}-${i + 1}`}
              />
            ))}
          </Carousel>
        ) : (
          <Image
            className="cover new_image-fluid"
            preview={false}
            width={"100%"}
            height={"100%"}
            wrapperStyle={{ height: 150 }}
            src={noImage}
          />
        )
      }
    >
      <Paragraph ellipsis={{ rows: 5 }}>
        {mCms?.description
          ? getTagFreeContent(mCms?.description)
          : "--- --- ---"}
      </Paragraph>
    </Card>
  );
};

export default FoldCard;
