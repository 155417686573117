import { put, takeLatest, call } from "redux-saga/effects";
import { showNotification } from "../../utils/CommonFunctions";
import ACTIONS from "./WorkSpaceActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const addWorkSpaceRequest = (data) => {
  return axios({
    method: "POST",
    url: AppUrl.CREATE_WORKSPACE,
    data,
  });
};

const updateWorkSpaceRequest = (data) => {
  return axios({
    method: "PUT",
    url: `${AppUrl.CREATE_WORKSPACE}/${data?._id}`,
    data,
  });
};

const getAllWorkSpaceRequest = () => {
  return axios({
    method: "GET",
    url: AppUrl.WORKSPACE,
  });
};

const getWorkSpaceRequest = (id) => {
  return axios({
    method: "GET",
    url: `${AppUrl.WORKSPACE}/${id}`,
  });
};

function* addWorkSpace(action) {
  try {
    let workSpace = yield call(addWorkSpaceRequest, action.payload);
    let data = workSpace?.data;
    if (data?.meta?.success === false || data?.meta?.status !== 200) {
      showNotification("error", data?.meta?.message);
      yield put({ type: ACTIONS.ADD_WORKSPACE_FAIL, payload: data?.meta });
    } else {
      showNotification("success", "Workspace Added Successfully");
      yield put({ type: ACTIONS.ADD_WORKSPACE_SUCCESS, payload: data?.data });
    }
  } catch (error) {
    console.log("create workspace error", error);
  }
}
function* updateWorkSpace(action) {
  try {
    let workSpace = yield call(updateWorkSpaceRequest, action.payload);
    let data = workSpace?.data;
    if (data?.meta?.success === false || data?.meta?.status !== 200) {
      showNotification("error", data?.meta?.message);
      yield put({ type: ACTIONS.UPDATE_WORKSPACE_FAIL, payload: data?.meta });
    } else {
      showNotification("success", "Workspace Updated Successfully");
      yield put({
        type: ACTIONS.UPDATE_WORKSPACE_SUCCESS,
        payload: data?.data,
      });
    }
  } catch (error) {
    console.log("update workspace error", error);
  }
}

function* getAllWorkSpace(action) {
  try {
    let workSpace = yield call(getAllWorkSpaceRequest);
    let data = workSpace?.data;

    if (data?.meta?.success === false || data?.meta?.status !== 200) {
      yield put({ type: ACTIONS.GET_ALL_WORKSPACE_FAIL, payload: data?.meta });
    } else {
      yield put({
        type: ACTIONS.GET_ALL_WORKSPACE_SUCCESS,
        payload: data?.data,
      });
    }
  } catch (error) {
    console.log("get all workspace error", error);
  }
}

function* getWorkSpace(action) {
  try {
    let workSpace = yield call(getWorkSpaceRequest, action.payload);
    let data = workSpace?.data;

    if (data?.meta?.success === false || data?.meta?.status !== 200) {
      yield put({ type: ACTIONS.GET_WORKSPACE_REQUEST, payload: data?.meta });
    } else {
      yield put({ type: ACTIONS.GET_WORKSPACE_SUCCESS, payload: data?.data });
    }
  } catch (error) {
    console.log("get workspace error", error);
  }
}

// ACTION WATCHER
export default function* workSpaceSaga() {
  // user can logout without waiting for login action.
  yield takeLatest(ACTIONS.ADD_WORKSPACE_REQUEST, addWorkSpace);
  yield takeLatest(ACTIONS.UPDATE_WORKSPACE_REQUEST, updateWorkSpace);
  yield takeLatest(ACTIONS.GET_ALL_WORKSPACE_REQUEST, getAllWorkSpace);
  yield takeLatest(ACTIONS.GET_WORKSPACE_REQUEST, getWorkSpace);
}
