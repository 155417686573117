import Actions from './MailActionTypes'

export const getAllMailTemplate = (params = {}) => {
    return {
        type: Actions.FETCH_ALL_MAIL_TEMPLATE,
        payload: params,
    }
}

export const sendCampaignMail = (params = {}) => {
    return {
        type: Actions.SEND_CAMPAIGN_MAIL,
        payload: params,
    }
}
