import { Button, Row, Col, Card, Badge } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import AddPlan from "./components/AddPlan";
import logoMeeting from "../assets/images/logo-meeting.png";
import { getAllPlans } from "./duck/PlanActions";
import { useParams } from "react-router-dom";
import { checkPermission } from "../utils/CommonFunctions";
import Spinner from "../spinner/Spinner";

const Plan = () => {
  const resourceTypes = Object.freeze({
    private_office: {
      id: "private_office",
      name: "Private Office",
      editKey: "privateOffice",
    },
    flexi_desk: {
      id: "flexi_desk",
      name: "Flexi Desk",
      editKey: "flexiDesk",
      price: 10,
    },
    dedicated_desk: {
      id: "dedicated_desk",
      name: "Dedicated Desk",
      editKey: "dedicatedDesk",
      price: 20,
    },
  });

  const [visible, showModal] = useState(false);
  const [selectedPlan, selectPlan] = useState(null);

  const workSpace = useSelector((state) => state?.workSpace);
  const mWorkSpace =
    workSpace?.active && workSpace?.workSpaceMap?.[workSpace?.active];

  const privateOffices = useSelector((state) => state.resources?.resourceMap);

  const plan = useSelector((state) => state.plan);

  const planSpin = !Object.keys(plan?.planMap).length && plan?.loading;
  const { resource_type } = useParams();

  const plans = workSpace?.active && plan?.plans[workSpace?.active];
  const mPlans = plans && plans[resource_type];

  const dispatch = useDispatch();

  const preload = () => {
    if (workSpace?.active) {
      let params = {
        resource_type,
        space_id: workSpace?.active,
      };

      !mPlans && dispatch(getAllPlans(params));
    }
  };

  useEffect(() => {
    preload();
  }, [workSpace?.active, resource_type]);

  const handlePlanModal = (show = false, plan_id) => {
    show = typeof show === "boolean" && show;
    selectPlan(plan_id);
    showModal(show);
  };
  let editable = checkPermission({
    row: "plan",
    subRow: resourceTypes?.[resource_type]?.editKey,
  });

  return planSpin ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">
          {resource_type && resourceTypes[resource_type]?.name} Plans
        </span>
        {editable && (
          <Button type="primary-outline" onClick={() => showModal(true)}>
            + Add Plan
          </Button>
        )}
      </Row>

      {Array.isArray(mPlans) && mPlans.length == 0 && (
        <Row className="mt5">
          <Col span={24}>
            <Card
              bordered={false}
              style={{
                width: "100%",
                minHeight: "550px",
                padding: "30px",
                borderRadius: 4,
                textAlign: "center",
              }}
            >
              <img
                src={`${logoMeeting}`}
                alt="images"
                style={{ maxWidth: "100%" }}
              />
            </Card>
          </Col>
        </Row>
      )}

      <Row>
        {Array.isArray(mPlans) &&
          mPlans.length > 0 &&
          mPlans.map((plan_id, i) => {
            let mPlan = plan?.planMap[plan_id];
            let resource =
              privateOffices &&
              privateOffices[mPlan?.resource_id?._id || mPlan?.resource_id];

            let actual_price =
              resource?.price ?? mWorkSpace?.price?.[resource_type];
            let discounted_price = Math.ceil(
              (actual_price * (mPlan?.discount || 0)) / 100
            );

            return (
              mPlan && (
                <Badge.Ribbon
                  key={i}
                  text={mPlan?.name}
                  placement="start"
                  style={{ top: 24 }}
                  color="blue"
                >
                  <Card
                    key={i}
                    // title={mPlan?.name}
                    // headStyle={{ backgroundColor: '#74BA58', color: "#FFF" }}
                    bordered={false}
                    style={{
                      width: 300,
                      margin: "10px 20px 10px 0",
                      borderRadius: 5,
                    }}
                    hoverable
                    extra={
                      editable ? (
                        <EditOutlined
                          style={{ fontSize: 12 }}
                          onClick={() => handlePlanModal(true, plan_id)}
                        />
                      ) : (
                        <EyeOutlined
                          style={{ fontSize: 12 }}
                          onClick={() => handlePlanModal(true, plan_id)}
                        />
                      )
                    }
                  >
                    {resource && (
                      <p>
                        <strong>Office space :</strong> {resource?.name}
                      </p>
                    )}
                    <p>
                      <strong>No of days :</strong> {mPlan?.tenure}
                    </p>
                    <p>
                      <strong>Discounted Price :</strong> £
                      {mPlan?.discount <= 0
                        ? parseFloat(actual_price).toFixed(2)
                        : parseFloat(actual_price - discounted_price).toFixed(
                            2
                          )}
                      /day
                    </p>
                    <p>
                      <strong>Discount :</strong>{" "}
                      {parseFloat(mPlan?.discount).toFixed(2)}%
                    </p>
                  </Card>
                </Badge.Ribbon>
              )
            );
          })}
      </Row>

      <AddPlan
        visible={visible}
        showModal={handlePlanModal}
        selectedPlan={selectedPlan}
        resource_type={resource_type}
        editable={editable}
      />
    </div>
  );
};

export default Plan;
