import { useState, useCallback, useEffect } from "react";
import { Layout } from "antd";
import AppSidebar from "./components/AppSidebar";
// import AppCMSSidebar from "./components/AppCMSSidebar";
import AppHeader from "./components/AppHeader";
import AppContent from "./components/AppContent";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUserRequest } from "../login/duck/LoginActions";
import { getAllWorkSpaceRequest } from "../workspace/duck/WorkSpaceActions";
import { getAllWorkspaceResources } from "../resources/duck/ResourceActions";
/* import { getAllBookingResources } from "../booking/duck/BookingActions"; */
import { fetchAllAmenities } from "../settings/duck/AmenitiesActions";
import {
  getDashboard,
  getMemberDashboard,
} from "../dashboard/duck/DashboardActions";
import { getAllMembers } from "../members/duck/MembersActions";
import { getAllAdmins } from "../adminAndTeams/duck/AdminActions";
/* import { getAllInvoices } from "../invoice/duck/InvoiceActions"; */
import { fetchAllCard } from "../billing/duck/BillingActions";
import jwtDecode from "jwt-decode";
import moment from "moment";
/* import { initializeSocket, closeSocket } from "../config/Socket"; */
import { initializeSocket } from "../config/Socket";
import {
  userSocket,
  spaceSocket,
  roleSocket,
  invoiceSocket,
  resourceSocket,
  planSocket,
  globalSocket,
  bookingSocket,
  attachmentSocket,
  amenitiesSocket,
  activitiesSocket,
} from "../utils/SocketApi";
import AppDrawer from "./components/AppDrawer";

const AppLayoutContainer = () => {
  const [collapsed, handleCollapsible] = useState(
    localStorage.getItem("isCollapsed") === "true"
  );
  const [showCmsSidebarMenu, setShowCmsSidebarMenu] = useState(
    localStorage.getItem("cmsSidebar") === "true"
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const dispatch = useDispatch();
  const activeWorkspace = useSelector((state) => state?.workSpace?.active);
  const user = useSelector((state) => state.user?.loggedInUser);

  const { showAllBooking, adminAndTeams, dashboard, members, billing } =
    user?.role_id?.permissions || {};

  const preload = () => {
    const accessToken = localStorage.getItem("accessToken");
    const decodeToken = accessToken && jwtDecode(accessToken);
    if (decodeToken?.user?._id) {
      !user && dispatch(getLoggedInUserRequest(decodeToken.user._id));
    }
    initializeSocket();
    !activeWorkspace &&
      getAllWorkSpaceRequest &&
      dispatch(getAllWorkSpaceRequest());
  };

  useEffect(() => {
    preload();
    if (activeWorkspace) {
      showAllBooking && dispatch(getAllWorkspaceResources(activeWorkspace));
      billing?.show && dispatch(fetchAllCard());
      fetchAllAmenities && dispatch(fetchAllAmenities());
      adminAndTeams?.show &&
        dispatch(getAllAdmins({ space_id: activeWorkspace }));
      socketObjectCall();
      members?.show && dispatch(getAllMembers({ space_id: activeWorkspace }));

      if (dashboard?.show) {
        if (showAllBooking) {
          const fromTime = {
            hour: 8,
            minute: 0,
            second: 0,
          };
          const toTime = {
            hour: 18,
            minute: 0,
            second: 0,
          };
          const dashboardParams = {
            space_id: activeWorkspace,
            from: moment().set(fromTime).toDate(),
            to: moment().set(toTime).toDate(),
          };

          dispatch(getDashboard(dashboardParams));
        } else {
          dispatch(getMemberDashboard());
        }
      }
    }
  }, [activeWorkspace, user?.role_id?.permissions]);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      handleCollapsible(true);
    }
  }, []);

  const toggleSidebar = useCallback(() => {
    handleCollapsible(!collapsed);
    localStorage.setItem("isCollapsed", !collapsed);
  }, [collapsed]);

  const toggleCMSSidebar = useCallback(() => {
    setShowCmsSidebarMenu((preState) => {
      localStorage.setItem("cmsSidebar", !preState);

      return !preState;
    });
  }, []);

  const toggleDrawer = useCallback(() => {
    setIsDrawerOpen((prevState) => !prevState);
  }, []);

  const socketObjectCall = () => {
    userSocket();
    spaceSocket();
    roleSocket();
    invoiceSocket();
    resourceSocket();
    planSocket();
    globalSocket();
    bookingSocket();
    attachmentSocket();
    amenitiesSocket();
    activitiesSocket();
  };

  return (
    <Layout className="fh --main-container">
      <AppSidebar
        collapsed={collapsed}
        showCmsSidebarMenu={showCmsSidebarMenu}
      />

      <AppDrawer
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        showCmsSidebarMenu={showCmsSidebarMenu}
      />

      <Layout className="site-layout">
        <AppHeader
          toggleSidebar={toggleSidebar}
          showCmsSidebarMenu={showCmsSidebarMenu}
          toggleCMSSidebar={toggleCMSSidebar}
          toggleDrawer={toggleDrawer}
          collapsed={collapsed}
        />
        <AppContent />
      </Layout>
    </Layout>
  );
};

export default AppLayoutContainer;
