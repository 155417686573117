import React, { useCallback, useMemo } from "react";
import { Switch, Popconfirm, Col, Row } from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import {
  getStripeCardColor,
  showNotification,
} from "../../utils/CommonFunctions";
import { setDefaultCard, deleteCard } from "../duck/BillingActions";

import "./StripeCard.scss";

const StripeCardDetails = (props) => {
  const { sourceId, index } = props;

  const dispatch = useDispatch();

  const source = useSelector((state) => state.billing?.sources[sourceId]);

  const defaultSourceId = useSelector((state) => state.billing?.default_source);
  const isDefaultSource = useMemo(
    () => defaultSourceId === sourceId,
    [defaultSourceId, sourceId]
  );

  const makeDefaultPaymentSource = useCallback(
    (checked) => {
      if (!checked) {
        showNotification("warning", "Enable a different card to disable this.");
      } else if (checked) {
        dispatch(setDefaultCard({ src_id: sourceId }));
      }
    },
    [sourceId]
  );

  const bgColors = useMemo(() => getStripeCardColor(index), [index]);

  if (!sourceId || !source) {
    return <></>;
  }

  const card = source?.card;

  const lastFourDigit = card?.last4;
  const month = `0${card?.exp_month || ""}`.slice(-2);
  const year = `${card?.exp_year || ""}`.slice(-2);
  const valid = `${month}/${year}`;
  const cardBrand = card?.brand?.toLowerCase?.();

  return (
    <Col
      className="--card-layout stripe-card-details-container"
      style={{ backgroundColor: bgColors.bgColor }}
    >
      <Col
        className="--card-layout stripe-card-content"
        style={{ background: bgColors.overlayColor }}
      >
        <Row className="mb20" align="middle" justify="space-between">
          <Col className="card-owner-name">{source?.owner?.name}</Col>

          <Col>
            {source?.deleteLoader || source?.makeDefaultPaymentSourceLoader ? (
              <LoadingOutlined className="loading" />
            ) : (
              <Popconfirm
                title="Are you sure to delete this?"
                okText="Yes"
                cancelText="No"
                placement="bottom"
                onConfirm={() => dispatch(deleteCard({ src_id: sourceId }))}
              >
                <DeleteOutlined className="btn-delete" />
              </Popconfirm>
            )}
          </Col>
        </Row>

        <Row className="mb20" align="middle">
          <Col className="card-mask-digit">
            XXXX XXXX XXXX
            <span className="card-last-four-digit"> {lastFourDigit}</span>
          </Col>
        </Row>

        <Row className="mb20" align="middle" justify="space-between">
          <Col className="card-validity-details">
            Valid Thru <span className="card-valid ml10"> {valid}</span>
          </Col>

          <Col className={`credit-card-brand ${cardBrand}`} />
        </Row>

        <Row className="" align="middle" justify="space-between">
          <Row className="make-default-payment" align="middle">
            <span>Mark default for payment?</span>

            <Switch
              className="default-card-switch ml10"
              checked={isDefaultSource}
              onChange={makeDefaultPaymentSource}
              loading={source?.makeDefaultPaymentSourceLoader}
              size="small"
            />
          </Row>

          <Col />
        </Row>
      </Col>
    </Col>
  );
};

export default React.memo(StripeCardDetails);
