import { useCallback, useEffect, useRef, useState } from "react";
import CustomModal from "../../utils/CustomModal";
import {
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  TimePicker,
  Divider,
  Upload,
  Button,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { workSpaceAddRequest, updateWorkSpace } from "../duck/WorkSpaceActions";
import { createNewAmenity } from "../../settings/duck/AmenitiesActions";
import { PlusOutlined } from "@ant-design/icons";
import { showNotification, days } from "../../utils/CommonFunctions";
import axios from "axios";
import AppUrl from "../../config/AppUrl";
import moment from "moment";

const AddWorkSpace = (props) => {
  const prevVal = useRef();

  const { selectedWorkspace, editable, visible } = props;
  const workSpace = useSelector((state) => state?.workSpace);
  let { loading, adding } = workSpace;
  const amenities = useSelector((state) => state?.amenities?.amenities);
  let modalTitle = editable
    ? selectedWorkspace
      ? "Update Workspace"
      : "Add Workspace"
    : "Workspace";

  const dispatch = useDispatch();

  const [workspaceObj, setWorkspaceObj] = useState({});
  const [error, handleError] = useState({});
  const [amenityTitle, setAmenityTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  const uploadImage = useCallback(
    (id) => {
      fileList?.forEach((picture) => {
        if (picture?.file) {
          const formData = new FormData();
          formData.append("file", picture?.file);
          formData.append("onModel", "Spaces");
          formData.append("model_id", id);
          axios.post(AppUrl.ATTACHMENTS, formData);
        }
      });
    },
    [fileList]
  );

  useEffect(() => {
    if (
      (prevVal?.current?.loading || prevVal?.current?.adding) &&
      (!loading || !adding)
    ) {
      workSpace?.newlyCreated && uploadImage(workSpace?.newlyCreated);
      props.selectWorkspace(null);
      props.showModal(false);
    } else if (!prevVal?.current?.visible && visible) {
      const startTime = moment(selectedWorkspace?.office_hours?.[0]);
      const endTime = moment(selectedWorkspace?.office_hours?.[1]);
      setWorkspaceObj({
        name: selectedWorkspace?.name ?? null,
        area: selectedWorkspace?.area ?? null,
        description: selectedWorkspace?.description ?? null,
        resources_available:
          selectedWorkspace?.resources_available ?? undefined,
        amenities:
          selectedWorkspace?.amenities
            ?.filter((a) => a && a?.active)
            ?.map((a) => a?._id) ?? undefined,
        no_of_days: selectedWorkspace?.no_of_days ?? undefined,
        office_hours: [startTime, endTime] ?? null,
        active: selectedWorkspace?.active ?? true,
      });
      setFileList(selectedWorkspace?.pictures || []);
      handleError({});
    }
    return () => {
      prevVal.current = { loading, adding, visible };
    };
  }, [
    workSpace?.active,
    selectedWorkspace,
    workSpace?.newlyCreated,
    loading,
    adding,
    visible,
    uploadImage,
    props,
  ]);

  const hasError = useCallback(() => {
    const { name, area, no_of_days, office_hours } = workspaceObj;
    const error = {};

    if (!name || name?.trim() === "") {
      error.name = "Workspace name cannot be left blank";
    }
    if (!area || area?.trim() === "") {
      error.area = "Workspace area cannot be left blank";
    }
    if (!no_of_days || no_of_days?.length === 0) {
      error.no_of_days = "Please select number of days";
    }
    if (!office_hours || office_hours?.length === 0) {
      error.office_hours = "Please select office hours";
    }
    if (!fileList?.length) {
      error.fileList = "Please upload atleast one image";
    }
    handleError(error);
    return Object.keys(error).length;
  }, [workspaceObj, fileList]);

  const handleChange = useCallback(
    (name) => (e) => {
      const value = e?.target?.value ?? e;
      setWorkspaceObj((preState) => ({
        ...preState,
        [name]: value,
      }));
      handleError({});
    },
    []
  );

  const addWorkspace = useCallback(() => {
    if (!hasError()) {
      if (selectedWorkspace) {
        const oldFileListIds = selectedWorkspace?.pictures?.map?.(
          (file) => file?._id || file?.uid
        );
        const currentUploadedFileListIds = fileList
          ?.filter?.((file) => !file?.file && file?._id)
          ?.map((file) => file?._id || file?.uid);
        const removedUploadedFileIds = oldFileListIds?.filter?.(
          (id) => !currentUploadedFileListIds?.includes(id)
        );

        removedUploadedFileIds?.length &&
          removedUploadedFileIds.forEach((id) => {
            axios({
              method: "DELETE",
              url: `${AppUrl.ATTACHMENTS}/${id}`,
            });
          });
        uploadImage(selectedWorkspace?._id);
        dispatch(
          updateWorkSpace({ _id: selectedWorkspace?._id, ...workspaceObj })
        );
      } else dispatch(workSpaceAddRequest(workspaceObj));
    }
  }, [
    selectedWorkspace,
    workspaceObj,
    hasError,
    dispatch,
    fileList,
    uploadImage,
  ]);

  const addNewAmenity = useCallback(() => {
    if (amenityTitle && amenityTitle?.trim() !== "") {
      dispatch(createNewAmenity({ title: amenityTitle }));
      setAmenityTitle("");
    } else {
      showNotification("error", "Please add amenity title");
    }
  }, [amenityTitle, dispatch]);

  const deleteImage = useCallback((file) => {
    const fileId = file?._id || file?.uid;
    setFileList((preState) =>
      preState.filter((p) => (p?._id || p?.uid) !== fileId)
    );
  }, []);

  const beforeUpload = useCallback((file) => {
    const supportedFileType = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
    ];
    let isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable)
      showNotification(
        "error",
        "Please upload JEPG, JGP, PNG or GIF file only"
      );

    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const updateImageUpload = useCallback(({ file }) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = function () {
      const width = Number(img.naturalWidth);
      const height = Number(img.naturalHeight);

      URL.revokeObjectURL(img.src);
      if (width === 1440 && height === 820) {
        const fileObj = {
          uid: file?.uid,
          name: file?.name,
          size: file?.size,
          type: file?.type,
          url: URL.createObjectURL(file),
          file,
        };
        handleError({});
        setFileList((preState) => [...preState, fileObj]);
      } else {
        showNotification("error", "Please upload exact image size of 1440x820");
      }
    };
  }, []);

  let disabled = adding || loading || !editable;

  return (
    <CustomModal
      visible={visible}
      title={modalTitle}
      onCancel={() => props.showModal(false)}
      footer={
        <>
          <Button onClick={() => props.showModal(false)}>Close</Button>
          {editable && (
            <Button
              type="primary"
              onClick={addWorkspace}
              loading={adding || loading}
            >
              {selectedWorkspace ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      <div>
        <Row align="middle">
          <Col span={5}>
            <label className="label">Name</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={19}>
            <Input
              className="custom-input"
              placeholder="Cockpit"
              size="large"
              autoFocus
              allowClear
              disabled={disabled}
              value={workspaceObj?.name}
              onChange={handleChange("name")}
            />
            <Row className="error mt5">{error?.name}</Row>
          </Col>
        </Row>

        <Row align="top" className="mt5">
          <Col span={5}>
            <label className="label">Address</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={19}>
            <Input.TextArea
              className="custom-input"
              placeholder="Add workspace location"
              disabled={disabled}
              size="large"
              allowClear
              value={workspaceObj?.area}
              onChange={handleChange("area")}
            />
            <Row className="error mt5">{error?.area}</Row>
          </Col>
        </Row>

        <Row align="top" className="mt5">
          <Col span={5}>
            <label className="label">Description</label>
          </Col>
          <Col span={19}>
            <Input.TextArea
              className="custom-input"
              placeholder="Add workspace description"
              size="large"
              disabled={disabled}
              allowClear
              value={workspaceObj?.description}
              onChange={handleChange("description")}
            />
          </Col>
        </Row>

        <Row align="middle" className="mt10">
          <Col span={5}>
            <label className="label">Resources</label>
          </Col>
          <Col span={19}>
            <Select
              mode="multiple"
              className="custom-select fw"
              placeholder="Select resources"
              disabled={disabled}
              allowClear
              size="large"
              value={workspaceObj?.resources_available}
              onChange={handleChange("resources_available")}
            >
              <Select.Option key={"meeting_room"}>Meeting Rooms</Select.Option>
              <Select.Option key={"private_office"}>
                Private Offices
              </Select.Option>
              <Select.Option key={"dedicated_desk"}>
                Dedicated Desks
              </Select.Option>
              <Select.Option key={"flexi_desk"}>Flexible Desks</Select.Option>
            </Select>
          </Col>
        </Row>

        <Row align="middle" className="mt10">
          <Col span={5}>
            <label className="label">Amenities</label>
          </Col>
          <Col span={19}>
            <Select
              mode={"multiple"}
              className="custom-select fw"
              placeholder="Select amenities"
              allowClear
              disabled={disabled}
              size="large"
              value={workspaceObj?.amenities}
              onChange={handleChange("amenities")}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div
                    style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                  >
                    <Input
                      style={{ flex: "auto" }}
                      className="custom-input"
                      placeholder="Enter amenity title"
                      disabled={disabled}
                      value={amenityTitle}
                      onChange={(e) => setAmenityTitle(e?.target?.value)}
                    />
                    <Button type="link" onClick={addNewAmenity}>
                      <PlusOutlined /> Click to add new amenity
                    </Button>
                  </div>
                </div>
              )}
            >
              {amenities.map(
                (amenity) =>
                  amenity?.active && (
                    <Select.Option key={amenity?._id}>
                      {amenity?.title}
                    </Select.Option>
                  )
              )}
            </Select>
          </Col>
        </Row>

        <Row align="middle" className="mt10">
          <Col span={5}>
            <label className="label">No of days open</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={19}>
            <Select
              mode="multiple"
              className="custom-select fw"
              placeholder="Select days"
              disabled={disabled}
              allowClear
              size="large"
              value={workspaceObj?.no_of_days}
              onChange={handleChange("no_of_days")}
            >
              {days.map((day) => (
                <Select.Option key={day}>{day}</Select.Option>
              ))}
            </Select>
            <Row className="error mt5">{error?.no_of_days}</Row>
          </Col>
        </Row>

        <Row align="middle" className="mt10">
          <Col span={5}>
            <label className="label">Office Hours</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={19}>
            <TimePicker.RangePicker
              size="large"
              className="custom-input"
              use12Hours={true}
              disabled={disabled}
              format={"HH:mm"}
              minuteStep={30}
              value={workspaceObj?.office_hours}
              onChange={handleChange("office_hours")}
            />
            <Row className="error mt5">{error?.office_hours}</Row>
          </Col>
        </Row>

        <Row align="middle" className="mt10">
          <Col span={5}>
            <label className="label">Images</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={19}>
            <Upload
              accept={".jpeg, .jpg, .png, .gif"}
              beforeUpload={beforeUpload}
              listType="picture-card"
              multiple={true}
              fileList={fileList}
              customRequest={updateImageUpload}
              onRemove={deleteImage}
              disabled={disabled}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
                <div style={{ marginTop: 5 }}>
                  1440x820<sup style={{ color: "red" }}>*</sup>
                </div>
              </div>
            </Upload>
            <Row className="error mt5">{error?.fileList}</Row>
          </Col>
        </Row>

        <Row align="top" className="mt15">
          <Col span={5}></Col>
          <Col span={19}>
            <Checkbox
              disabled={disabled}
              checked={workspaceObj?.active}
              onChange={(e) =>
                setWorkspaceObj({ ...workspaceObj, active: e?.target?.checked })
              }
            >
              Workspace status
            </Checkbox>
          </Col>
        </Row>
      </div>
    </CustomModal>
  );
};

export default AddWorkSpace;
