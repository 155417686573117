import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Row, Input, Select, Button, Card, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { bookingRequestColumns } from "./component/BookingRequestColumns";
import { getAllBookingResources } from "../booking/duck/BookingActions";
import RequestDetails from "./component/RequestDetails";
import MTable from "../utils/MTable";
import { checkPermission, resourceTypes } from "../utils/CommonFunctions";
import MyPagination from "../utils/MyPagination";
import AddBookingRequestModal from "./component/AddBookingRequestModal";

const BookingRequest = () => {
  const preProps = useRef();
  const dispatch = useDispatch();

  const editable = useMemo(
    () => checkPermission({ row: "bookingRequest" }),
    []
  );

  const showAllBooking = useMemo(
    () => checkPermission({ initial: "bookingRequest" }),
    []
  );

  const [filter, setFilter] = useState({});
  const [boardKey, setBoardKey] = useState("");
  const [showAddBookingRequest, setShowAddBookingRequest] = useState(false);
  const [showBookingRequest, setShowBookingRequest] = useState(false);
  const [selectedBookingId, selectBooking] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const activeWorkSpace = useSelector((state) => state?.workSpace?.active);

  const booking = useSelector((state) => state?.booking);

  const mBookingBoard = useMemo(
    () => booking.bookings?.[boardKey] ?? {},
    [booking.bookings, boardKey]
  );

  const loadMore = useCallback(
    (offset = 0, limit = 30) => {
      const { meta, list, loading } = mBookingBoard;

      if (!loading && (offset === 0 || meta?.totalCount > list?.length)) {
        const { resource_type, booking_status } = filter;

        const params = {
          booking_request: true,
        };
        if (showAllBooking && activeWorkSpace)
          params.space_id = activeWorkSpace;
        if (booking_status) params.booking_status = booking_status;
        if (resource_type) params.resource_type = resource_type;

        setBoardKey(JSON.stringify({ ...params }));

        params.offset = offset;
        params.limit = limit;
        dispatch(getAllBookingResources(params));
      }
    },
    [mBookingBoard, filter, showAllBooking, activeWorkSpace, dispatch]
  );
  const handleEditRequest = (show = false) => {
    show = typeof show === "boolean" && show;
    setShowBookingRequest(show);
  };

  const updateRequest = (e) => {
    selectBooking(e);
    handleEditRequest(true);
  };

  const onSearch = () => loadMore();
  const onReset = useCallback(() => {
    setFilter({});
    setBoardKey("");
    setStartIndex(0);
  }, []);

  useEffect(() => {
    if (preProps?.current?.activeWorkSpace !== activeWorkSpace) {
      onReset();
    } else if (!Object.keys(mBookingBoard).length) {
      loadMore();
    }

    return () => {
      preProps.current = { activeWorkSpace };
    };
  }, [activeWorkSpace, mBookingBoard, loadMore, onReset]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setFilter((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );

  const handleTableChange = useCallback(
    (pagination) => {
      const { current, pageSize } = pagination;
      setStartIndex((current - 1) * pageSize);

      const { list } = mBookingBoard;
      const endIndex = current * pageSize;

      if (endIndex > list?.length) {
        loadMore(list?.length, endIndex - list?.length);
      }
    },
    [loadMore, mBookingBoard]
  );

  const bookings = mBookingBoard?.list;
  const data = useMemo(() => {
    let mData = [];

    if (bookings) {
      bookings.forEach((id, index) => {
        const mBooking = booking?.bookingMap?.[id];
        const name = mBooking?.name;
        const email = mBooking?.email;
        const status = mBooking?.booking_status;

        const startDateTime = new Date(mBooking?.from).getTime();
        const endDateTime = new Date(mBooking?.to).getTime();
        const diffInTime = (endDateTime - startDateTime) / (1000 * 3600);

        const resource = [
          mBooking?.items?.map?.((item) => item?.seat_no).join?.() ||
            mBooking?.resource_id?.name,
          mBooking?.resource_id?.color,
          mBooking?.title || resourceTypes?.[mBooking?.type]?.name,
        ];

        mData.push({
          key: id,
          sn: ++index,
          user: [name, email, id],
          resource,
          date: [mBooking?.from, mBooking?.to],
          price: [mBooking?.price?.total || mBooking?.price?.net, diffInTime],
          status,
          action: mBooking,
        });
      });
    }

    return mData;
  }, [booking?.bookingMap, bookings]);

  return (
    <div className="mainMember">
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Booking Request</span>

        {editable && (
          <Button
            type="primary-outline"
            className="nw_btn_common"
            onClick={() => setShowAddBookingRequest(true)}
          >
            + New Booking Request
          </Button>
        )}
      </Row>

      <Card title="Filter" size="small" bordered={false}>
        <Select
          className="mr5 nw_mobile_container"
          style={{
            paddingTop: "5px",
            width: "15%",
            fontWeight: "500",
            marginRight: "15px",
          }}
          placeholder={"Resource Type"}
          value={filter?.resource_type}
          onChange={handleChange("resource_type")}
          allowClear
          size="large"
        >
          {Object.values(resourceTypes).map((resourceType) => (
            <Select.Option key={resourceType?._id} value={resourceType?._id}>
              {resourceType?.name}
            </Select.Option>
          ))}
        </Select>

        <Select
          style={{
            width: "15%",
            fontWeight: "500",
            marginRight: "15px",
          }}
          className="nw_mobile_container"
          placeholder={"Request Status"}
          value={filter?.booking_status}
          onChange={handleChange("booking_status")}
          size="large"
        >
          <Select.Option key="Assigned" value="Assigned">
            Pending
          </Select.Option>
          <Select.Option key="Approved" value="Approved">
            Approved
          </Select.Option>
          <Select.Option key="Rejected" value="Rejected">
            Rejected
          </Select.Option>
        </Select>

        <Button className="mr10" type="primary" onClick={onSearch}>
          Search
        </Button>
        <Button className="mr10" type="primary-outline" onClick={onReset}>
          Reset
        </Button>
      </Card>

      <Row>
        <MTable
          className="mt20 fw"
          columns={bookingRequestColumns(editable, updateRequest)}
          dataSource={data.slice(startIndex, startIndex + 10)}
          bordered={false}
          loading={mBookingBoard?.loading}
          pagination={false}
          onChange={handleTableChange}
        />
        <Col span={24}>
          <MyPagination
            totalCount={mBookingBoard?.meta?.totalCount || 0}
            onChangePage={handleTableChange}
          />
        </Col>
      </Row>

      <AddBookingRequestModal
        visible={showAddBookingRequest}
        handleModal={() => setShowAddBookingRequest(false)}
      />

      <RequestDetails
        editable={editable}
        selectBooking={selectBooking}
        visible={showBookingRequest}
        selectedBookingId={selectedBookingId}
        handleModal={handleEditRequest}
      />
    </div>
  );
};

export default BookingRequest;
