import React, { useEffect, useRef, useState, useCallback } from "react";
import { Row, Col, Button, Input, Select, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import "../assets/css/profile.scss";
import { updateLoggedInUserRequest } from "../login/duck/LoginActions";
import AppUrl from "../config/AppUrl";
import {
  isValidMobileNumber,
  showNotification,
} from "../utils/CommonFunctions";
import ChangeProfilePassword from "./components/ChangeProfilePassword";
import { useHistory } from "react-router-dom";
import Spinner from "../spinner/Spinner";
import Routes from "../config/Routes";

import profileSideImg from "../assets/svg/profile-side-img.svg";

const { Option } = Select;
const Profile = () => {
  const preProps = useRef();
  const user = useSelector((state) => state?.user);

  const dispatch = useDispatch();
  const history = useHistory();

  const [fileList, setFileList] = useState([]);
  const profileSpin = !user?.loggedInUser && user?.loading ? true : false;

  const [state, setState] = useState({
    name: null,
    company_name: null,
    email: null,
    address: "",
    country_code: "44",
    mobile: null,
    profile_image: null,
    loading: false,
  });
  const [error, setError] = useState({});

  const preload = () => {
    if (state.loading) {
      if (preProps.current?.loading && !user?.loading) {
        if (!user?.error) {
          history.replace(Routes.dashboard);
        }

        setState((state) => ({
          ...state,
          loading: false,
        }));
      }
    } else if (user?.loggedInUser) {
      const {
        email,
        name,
        company_name,
        phone,
        address,
        pictures = [],
      } = user?.loggedInUser || {};
      const countryCode = phone?.slice?.(1, 3) || "44";
      const mobile = phone?.slice(3);
      let userDetails = {
        name,
        company_name,
        email,
        country_code: countryCode,
        mobile,
        address,
      };

      setState((preState) => ({
        ...preState,
        ...userDetails,
      }));

      if (pictures?.length > 0) {
        let picture = pictures[0];
        let picObj = {
          _id: picture?._id,
          name: picture?.filename,
          size: picture?.size,
          type: picture?.mimetype,
          url: picture?.thumb_location,
        };

        setFileList([picObj]);
      }
    }
  };

  useEffect(() => {
    preload();

    return () => {
      preProps.current = { loading: user?.loading };
    };
  }, [user]);

  const hasError = () => {
    let { name, company_name, country_code, mobile, address } = state;
    name = name?.trim?.();
    company_name = company_name?.trim?.();
    mobile = mobile?.trim?.();
    address = address?.trim?.();

    let error = {};

    if (!name) {
      error.name = "Please enter your name";
    }

    if (!company_name) {
      error.company_name = "Please enter your company name";
    }

    if (!mobile) {
      error.mobile = "Please enter your mobile number";
    } else if (!isValidMobileNumber(country_code, mobile)) {
      error.mobile = "Please enter valid mobile number";
    }

    if (!address) {
      error.address = "Please enter your address";
    }

    setError(error);

    return Object.keys(error).length !== 0;
  };

  const onSubmit = () => {
    const { name, company_name, country_code, mobile, address, loading } =
      state;

    if (hasError() || loading) return;

    setState((preState) => ({
      ...preState,
      loading: true,
    }));

    let params = {
      _id: user?.loggedInUser?._id,
      name,
      phone: `+${country_code}${mobile}`,
      company_name,
      address,
    };

    dispatch(updateLoggedInUserRequest(params));
  };

  const onRemoveImage = (file) => {
    let pictures = user?.loggedInUser?.pictures;
    pictures = [];
    const fileId = file?._id || file?.uid;
    const index = pictures?.findIndex((p) => (p?._id || p?.uid) === fileId);
    pictures.splice(index, 1);
    setFileList(pictures);
    if (user?.loggedInUser?._id) {
      axios({
        method: "DELETE",
        url: `${AppUrl.ATTACHMENTS}/${fileId}`,
      }).then((res) => {
        if (res?.data?.meta?.status === 200) {
          console.log("res", res);
        }
      });
    }
  };

  const beforeUpload = (file) => {
    const supportedFileType = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
    ];
    let isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable)
      showNotification(
        "error",
        "Please upload JEPG, JGP, PNG or GIF file only"
      );

    return isAcceptable;
  };

  const updateImageUpload = ({ file }) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("onModel", "Users");
    formData.append("model_id", user?.loggedInUser?._id);

    axios
      .post(AppUrl.ATTACHMENTS, formData)
      .then((res) => {
        let resData = res.data?.data;
        let file = Array.isArray(resData) ? resData : [];
        if (file?.length > 0) {
          file?.forEach((p) => {
            let picObj = {
              _id: p?._id,
              name: p?.filename,
              size: p?.size,
              type: p?.mimetype,
              url: p?.thumb_location,
            };

            setFileList([picObj]);
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((preState) => ({
        ...preState,
        [name]: value,
      }));
      setError({});
    },
    []
  );

  const prefixSelector = (
    <Select
      className="custom-select"
      defaultValue="44"
      value={state.country_code}
      onChange={handleChange("country_code")}
      disabled={state.loading}
    >
      <Option key="44" value="44">
        +44
      </Option>
      <Option key="91" value="91">
        +91
      </Option>
    </Select>
  );

  const [show, setShow] = useState(false);

  return profileSpin ? (
    <Spinner />
  ) : (
    <Col className="profile-container">
      <Row className="mb20">
        <span className="nw_heading">My Profile</span>
      </Row>

      <Row className="page-content" align="top" justify="space-between">
        <Col className="form-container" xl={12} md={24} sm={24} xs={24}>
          <Row className="mb20">
            <Col lg={6} md={24} sm={24} xs={24} >
              <label className="label">Name</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Input
                className="custom-input fw"
                name="name"
                placeholder="Name"
                value={state?.name}
                onChange={handleChange("name")}
                disabled={state.loading}
                size="large"
              />

              <Row className="error mt5">{error?.name}</Row>
            </Col>
          </Row>

          <Row className="mb20">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label className="label">Company Name</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Input
                className="custom-input fw"
                name="company_name"
                placeholder="Company Name"
                value={state?.company_name}
                onChange={handleChange("company_name")}
                disabled={state.loading}
                size="large"
              />

              <Row className="error mt5">{error?.company_name}</Row>
            </Col>
          </Row>

          <Row className="mb20">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label className="label">Email Address</label>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>{state?.email}</Col>
          </Row>

          <Row className="mb20">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label className="label">Mobile no</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Input
                className="custom-input fw"
                name="mobile"
                placeholder="Your Mobile Number"
                addonBefore={prefixSelector}
                value={state?.mobile}
                onChange={handleChange("mobile")}
                disabled={state.loading}
                size="large"
              />

              <Row className="error mt5">{error?.mobile}</Row>
            </Col>
          </Row>

          <Row className="mb20">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label className="label">Address</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Input.TextArea
                className="custom-input fw"
                rows={4}
                name="address"
                placeholder="Address"
                value={state?.address}
                onChange={handleChange("address")}
                disabled={state.loading}
              />

              <Row className="error mt5">{error?.address}</Row>
            </Col>
          </Row>

          <Row className="mb20">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label className="label">Profile image</label>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <ImgCrop rotate beforeCrop={beforeUpload}>
                <Upload
                  accept={".jpeg, .jpg, .png, .gif"}
                  listType="picture-card"
                  fileList={fileList}
                  customRequest={updateImageUpload}
                  onRemove={onRemoveImage}
                  disabled={state.loading}
                >
                  {fileList.length < 1 && "+ Upload"}
                </Upload>
              </ImgCrop>
            </Col>
          </Row>

          <Row className="mb20">
            <Col lg={6} md={24} sm={24} xs={24}></Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Row>
                <Col className="mb10">
                  <Button
                    className="mr20 nw_btn_black btn_green"
                    type="primary"
                    loading={state.loading}
                    onClick={onSubmit}
                    size="large"
                  >
                    Submit
                  </Button>
                </Col>

                <Col className="mb10">
                  <Button type="primary-outline" className="nw_btn_white" size="large" onClick={() => setShow(!show)}>
                    Change Password
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col className="side-image-container" md={0} xl={12}>
          <Row className="image-container fw" align="middle" justify="center">
            <img className="image" src={profileSideImg} alt="" style={{ maxWidth: "100%" }} />
          </Row>
        </Col>
      </Row>

      <ChangeProfilePassword visible={show} showModal={() => setShow(!show)} />
    </Col>
  );
};

export default Profile;
