import { Button, Input, Row } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import {
  escapeRegex,
  checkPermission,
  getUserWorkspace,
} from "../utils/CommonFunctions";
import Spinner from "../spinner/Spinner";
import resourceSvg from "../assets/svg/resource.svg";
import AddWorkSpace from "./components/AddWorkSpace";
import WorkSpaceCard from "./components/WorkSpaceCard";
import React, { useMemo } from "react";

const WorkSpace = () => {
  const workSpace = useSelector((state) => state?.workSpace);
  const workSpaceMap = workSpace?.workSpaceMap;

  const [visible, showModal] = useState(false);
  const [search, toggleSearch] = useState(false);
  const [searchValue, handleSearch] = useState("");
  const [selectedWorkspace, selectWorkspace] = useState(null);
  let workSpaceList = getUserWorkspace();

  const editable = useMemo(
    () => checkPermission({ row: "settings", subRow: "workspace" }),
    []
  );

  const toggleModal = (val) => {
    showModal(val);
    selectWorkspace(null);
  };

  const workSpaceSpin = !workSpaceList?.length && workSpace?.loading;

  if (searchValue) {
    const regExp = new RegExp(escapeRegex(searchValue), "i");
    workSpaceList = workSpaceList.filter((id) =>
      regExp.test(workSpaceMap?.[id]?.name)
    );
  }

  return workSpaceSpin ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Workspace</span>
        <span>
          <Input
            size="middle"
            onFocus={() => toggleSearch(true)}
            onBlur={() => toggleSearch(false)}
            className="custom-input mr10 white"
            style={{ width: search ? 220 : 120 }}
            placeholder="Search..."
            prefix={<SearchOutlined />}
            value={searchValue}
            onChange={(e) => handleSearch(e?.target?.value)}
          />
          {editable && (
            <Button type="primary-outline" onClick={() => showModal(true)}>
              + Add Workspace
            </Button>
          )}
        </span>
      </Row>
      {workSpaceList?.length === 0 && (
        <Row justify="center" align="middle" className="mt20 pt20">
          <img src={resourceSvg} alt="No resource added!" />
        </Row>
      )}
      <Row>
        {workSpaceList?.map((id) => (
          <WorkSpaceCard
            id={id}
            key={id}
            editable={editable}
            showModal={showModal}
            selectWorkspace={selectWorkspace}
          />
        ))}
      </Row>

      <AddWorkSpace
        visible={visible}
        showModal={toggleModal}
        selectedWorkspace={selectedWorkspace || null}
        selectWorkspace={selectWorkspace}
        editable={editable}
      />
    </div>
  );
};

export default WorkSpace;
