import Actions from "./CMSActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";

const initialState = {
  cmsMap: {},
  boards: {},
  loading: false,
  error: null,
};

const cmsReducer = (state = initialState, action) => {
  let payload = Object.assign({}, action?.payload);

  switch (action.type) {
    /** get all cms start */
    case Actions.FETCH_ALL_CMS: {
      delete payload?.offset;
      delete payload?.limit;
      const mFilter = JSON.stringify(Object.assign({}, payload));

      return Object.assign({}, state, {
        boards: Object.assign({}, state.boards, {
          [mFilter]: Object.assign({}, state.boards?.[mFilter], {
            error: null,
            loading: true,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_CMS_SUCCESS: {
      const mFilter = payload?.filter;
      const mBoard = state.boards?.[mFilter];

      let list = payload?.isReset ? [] : mBoard?.list || [];
      list = [...(list || []), ...getArrOrder(payload?.data)];

      return Object.assign({}, state, {
        cmsMap: Object.assign({}, state.cmsMap, arrToObjMap(payload?.data)),
        boards: Object.assign({}, state.boards, {
          [mFilter]: Object.assign({}, mBoard, {
            list: [...new Set(list)],
            meta: Object.assign({}, mBoard?.meta, payload?.meta),
            loading: false,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_CMS_FAILURE: {
      const mFilter = payload?.filter;

      return Object.assign({}, state, {
        boards: Object.assign({}, state.boards, {
          [mFilter]: Object.assign({}, state.boards[mFilter], {
            error: payload,
            loading: false,
          }),
        }),
      });
    }

    /** get all cms end */

    /** create cms start */
    case Actions.CREATE_CMS:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });

    case Actions.CREATE_CMS_SUCCESS: {
      const mCMS = payload?.data;
      const mFilter = payload?.filter;

      const mBoard = state.boards?.[mFilter] || {};
      let list = [...(mBoard?.list || [])];

      if (!list.includes(mCMS?._id)) {
        list.push(mCMS?._id);
        return Object.assign({}, state, {
          cmsMap: Object.assign({}, state.cmsMap, {
            [mCMS?._id]: mCMS,
          }),
          boards: Object.assign({}, state.boards, {
            [mFilter]: Object.assign({}, mBoard, {
              list: [...new Set(list)],
              meta: Object.assign({}, mBoard?.meta, {
                totalCount: (mBoard?.meta?.totalCount || 0) + 1,
              }),
            }),
          }),
          loading: false,
        });
      } else {
        return state;
      }
    }

    case Actions.CREATE_CMS_FAILURE:
      return Object.assign({}, state, {
        error: payload,
        loading: false,
      });

    /** create cms end */

    /** update cms start */

    case Actions.UPDATE_CMS: {
      return Object.assign({}, state, {
        cmsMap: Object.assign({}, state.cmsMap, {
          [payload?._id]: Object.assign({}, state.cmsMap?.[payload?._id], {
            error: false,
            loading: true,
          }),
        }),
      });
    }

    case Actions.UPDATE_CMS_SUCCESS: {
      if(payload.data.status === false){
        const mCMS = payload?.data;
        const mFilter =`{"type":"OurSpace"}`;
        const mBoard = state.boards?.[mFilter] || {};
        let list = [...(mBoard?.list || [])];
        list = list.filter(o=>o !== mCMS._id)
        return Object.assign({}, state, {
          cmsMap: Object.assign({}, state.cmsMap, {
            [mCMS?._id]: undefined,
          }),
          // boards: Object.assign({}, state.boards, {
          //   [mFilter]: Object.assign({}, mBoard, {
          //     list: [...new Set(list)],
          //     meta: Object.assign({}, mBoard?.meta),
          //     loading: false,
          //   }),
          // }),
        });

      } else {
        return Object.assign({}, state, {
          cmsMap: Object.assign({}, state.cmsMap, {
            [payload?.data?._id]: payload?.data,
          }),
        });
      }

    }

    case Actions.UPDATE_CMS_FAILURE: {
      return Object.assign({}, state, {
        cmsMap: Object.assign({}, state.cmsMap, {
          [payload?._id]: Object.assign({}, state.cmsMap?.[payload?._id], {
            error: payload,
            loading: false,
          }),
        }),
      });
    }

    /** update cms end */

    default:
      return state;
  }
};

export default cmsReducer;
