import React, { useMemo } from "react";
import { Button, Card, Col, Row } from "antd";
import moment from "moment";

import { formatDateTime, resourceTypes } from "../../utils/CommonFunctions";

const BookingDetails = (props) => {
  const { booking = {}, onPayNow, disabled } = props;

  const resource = booking?.resource_id;
  const resourceType = useMemo(
    () => resourceTypes[booking?.type],
    [booking?.type]
  );
  const price = booking?.price;
  const pricePerSeat = booking?.space_id?.price?.[booking?.type];

  const bookingDate = useMemo(
    () =>
      resourceType?._id === "meeting_room" ? (
        formatDateTime(booking?.from, "d")
      ) : (
        <span>
          {moment(booking?.from).format("DD/MMM/YY")}
          <span className="time-to"> to </span>
          {moment(booking?.to).format("DD/MMM/YY")}
        </span>
      ),
    [resourceType, booking]
  );

  const bookingTimeSlot = useMemo(
    () =>
      `${formatDateTime(booking?.from, "t")} to ${formatDateTime(
        booking?.to,
        "t"
      )}`,
    [booking]
  );

  const resourcePrice = useMemo(() => {
    const price = resourceType?._id?.includes?.("desk")
      ? pricePerSeat
      : resource?.price;
    const priceUnit = resourceType?._id === "meeting_room" ? "hr" : "day";

    return (
      <span>
        £ {price} <span className="price-unit">per {priceUnit}</span>
      </span>
    );
  }, [resourceType, pricePerSeat, resource]);

  return (
    <Card className="booking-details-container">
      <Col className="booking-details-content">
        <Row className="resource-price-container mb10" justify="space-between">
          <Col className="label" span={9}>
            {resourceType?.name}
          </Col>

          <Col className="value" span={15}>
            <Row align="middle" justify="end">
              {resourcePrice}
            </Row>
          </Col>
        </Row>

        <Row className="booking-date-conatiner mb10" justify="space-between">
          <Col className="label" span={9}>
            Booking Date
          </Col>

          <Col className="value" span={15}>
            <Row justify="end">{bookingDate}</Row>
          </Col>
        </Row>

        {resourceType?._id === "meeting_room" && (
          <Row
            className="booking-time-slot-container mb10"
            justify="space-between"
          >
            <Col className="label" span={9}>
              Booking Time Slot
            </Col>

            <Col className="value" span={15}>
              <Row justify="end">{bookingTimeSlot}</Row>
            </Col>
          </Row>
        )}

        {resourceType?._id?.includes("desk") && (
          <Row className="booked-seats-container mb10" justify="space-between">
            <Col className="label" span={9}>
              Booked Seats
            </Col>

            <Col className="value" span={15}>
              <Row justify="end">{booking?.capacity}</Row>
            </Col>
          </Row>
        )}

        <Row className="price-container mb10" justify="space-between">
          <Col className="label" span={9}>
            Price
          </Col>

          <Col className="value" span={15}>
            <Row justify="end">
              {`£ ${parseFloat(price?.net || 0).toFixed(2)}`}
            </Row>
          </Col>
        </Row>

        {booking?.plan?.discount && (
          <Row className="discount-container mb10" justify="space-between">
            <Col className="label" span={9}>
              Discount
            </Col>

            <Col className="value" span={15}>
              <Row justify="end">
                {parseFloat(booking?.plan?.discount || 0).toFixed(2)}%
              </Row>
            </Col>
          </Row>
        )}

        <Row className="tax-container mb10" justify="space-between">
          <Col className="label" span={9}>
            Tax
          </Col>

          <Col className="value" span={15}>
            <Row justify="end">{price?.tax || 0}%</Row>
          </Col>
        </Row>

        <Row className="total-price-container mb10" justify="space-between">
          <Col className="label" span={9}>
            Total Price
          </Col>

          <Col className="value" span={15}>
            <Row justify="end">
              {`£ ${parseFloat(price?.total || 0).toFixed(2)}`}
            </Row>
          </Col>
        </Row>

        <Col>
          <Button
            className="btn-pay-now"
            type="primary"
            size="large"
            block
            disabled={disabled}
            onClick={onPayNow}
          >
            Pay Now
          </Button>
        </Col>
      </Col>
    </Card>
  );
};

export default React.memo(BookingDetails);
