import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Row, Col, Input, Select, Button } from "antd";
import CustomModal from "../../utils/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { addNewPlan, updatePlan } from "../duck/PlanActions";

const AddPlan = (props) => {
  const prevProp = useRef();

  const { selectedPlan, resource_type, editable, visible, showModal } = props;
  const workSpace = useSelector((state) => state?.workSpace);
  const mWorkSpace =
    workSpace?.active && workSpace?.workSpaceMap?.[workSpace?.active];

  const { resources, resourceMap } = useSelector((state) => state.resources);

  const modalTitle = useMemo(
    () => (editable ? (selectedPlan ? "Edit Plan" : "Add Plan") : "Plan"),
    [selectedPlan, editable]
  );
  const privateOffices = useMemo(
    () => resources[workSpace?.active]?.privateOffices,
    [workSpace?.active, resources]
  );

  const plan = useSelector((state) => state?.plan);
  const { adding, loading, planMap } = plan;
  const mPlan = planMap?.[selectedPlan];
  const dispatch = useDispatch();

  const [planObj, setPlanObj] = useState({});
  const [error, handleError] = useState({});

  useEffect(() => {
    if (
      (prevProp?.current?.loading || prevProp?.current?.adding) &&
      (!loading || !adding)
    ) {
      setPlanObj({});
      showModal(false);
    } else if (!prevProp?.current?.visible && visible) {
      const resource_price =
        mPlan?.resource_id?.price ?? mWorkSpace?.price?.[resource_type];

      let plan = {
        price: resource_price,
        active: mPlan?.active ?? true,
      };

      if (selectedPlan) {
        const discount = mPlan?.discount || 0;
        const discount_price = (resource_price * discount) / 100;
        const total_price = resource_price - discount_price;

        plan.resource_id = mPlan?.resource_id?._id;
        plan.name = mPlan?.name;
        plan.days = mPlan?.tenure;
        plan.discount = discount;
        plan.discount_price = parseFloat(discount_price).toFixed(2);
        plan.total_price = parseFloat(total_price).toFixed(2);
      }

      setPlanObj(plan);
      handleError({});
    }
    return () => {
      prevProp.current = { loading, adding, visible };
    };
  }, [
    selectedPlan,
    mPlan,
    mWorkSpace?.price,
    resource_type,
    loading,
    adding,
    visible,
    showModal,
  ]);

  const hasError = useCallback(() => {
    let { resource_id, name, days, price, discount_price } = planObj;
    name = name?.trim?.();

    let error = {};

    if (resource_type === "private_office" && !resource_id) {
      error.resource_id = "Please select office space";
    }
    if (!name) {
      error.name = "Plan name cannot be blank";
    }
    if (!days || Number(days) <= 0) {
      error.days = "No of days must be greater than 0";
    }
    if (!price) {
      error.price = "Price must be greater than 0";
    }

    if (!discount_price || Number(discount_price) < 0) {
      error.discount_price = "Discount Price must be greater than 0";
    }

    handleError(error);
    return Object.keys(error).length;
  }, [planObj, resource_type]);

  const add = useCallback(() => {
    if (!hasError()) {
      let params = {
        name: planObj?.name,
        tenure: planObj?.days,
        discount: planObj?.discount,
        resource_type: resource_type,
        space_id: workSpace?.active,
        active: planObj?.active,
      };

      if (resource_type === "private_office") {
        params.resource_id = planObj?.resource_id;
      }
      if (selectedPlan) {
        params._id = selectedPlan;
        dispatch(updatePlan(params));
      } else {
        dispatch(addNewPlan(params));
      }
    }
  }, [
    selectedPlan,
    planObj,
    resource_type,
    workSpace?.active,
    dispatch,
    hasError,
  ]);

  const handleChange = useCallback(
    (name, isCheckbox) => (e) => {
      let value;
      let newState = {};
      if (isCheckbox) {
        value = e?.target?.checked;
      } else {
        value = e?.target?.value ?? e;

        if (["days", "discount_price"].includes(name)) {
          value = value?.replace?.(/[^0-9]/g, "");
        }
      }

      if (name === "discount_price") {
        const discount_price = value && Math.ceil(value);
        const discount = (discount_price * 100) / (planObj?.price || 1);
        const total_price = planObj?.price - discount_price;

        newState.discount_price = discount_price;
        newState.discount = discount && parseFloat(discount).toFixed(2);
        newState.total_price = total_price;
      } else {
        if (name === "resource_id") {
          newState.price = resourceMap[value]?.price;
        }

        newState[name] = value;
      }

      setPlanObj((preState) => Object.assign({}, preState, newState));
      handleError({});
    },
    [resourceMap, planObj?.price]
  );
  const disabled = useMemo(
    () => adding || loading || !editable,
    [adding, loading, editable]
  );
  return (
    <CustomModal
      visible={visible}
      title={modalTitle}
      onCancel={() => props.showModal(false)}
      footer={
        <>
          <Button onClick={() => props.showModal(false)}>Close</Button>
          {editable && (
            <Button type="primary" onClick={add} loading={loading || adding}>
              {selectedPlan ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      <div>
        {/* Office Space start */}
        {resource_type === "private_office" && (
          <Row align="middle" className="mb10">
            <Col span={6}>
              <label className="label">Office Space </label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18}>
              <Select
                className="custom-select fw"
                placeholder="Select Office Space"
                size="large"
                allowClear
                disabled={disabled}
                value={planObj?.resource_id}
                onChange={handleChange("resource_id")}
              >
                {privateOffices &&
                  privateOffices?.map((office_id, i) => (
                    <Select.Option key={i} value={office_id}>
                      {resourceMap?.[office_id]?.name}
                    </Select.Option>
                  ))}
              </Select>

              <Row className="error mt5">{error?.resource_id}</Row>
            </Col>
          </Row>
        )}
        {/* Office Space end */}

        {/* Name start */}
        <Row align="middle">
          <Col span={6}>
            <label className="label">Name </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18}>
            <Input
              className="custom-input"
              placeholder="One Month Plan"
              size="large"
              type="text"
              value={planObj?.name}
              disabled={disabled}
              onChange={handleChange("name")}
            />

            <Row className="error mt5">{error?.name}</Row>
          </Col>
        </Row>
        {/* Name end */}

        {/* No of days start */}
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">No of days</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col span={18}>
            <Input
              className="custom-input"
              placeholder="days"
              size="large"
              type="number"
              min={1}
              step={1}
              style={{ width: 150 }}
              value={planObj?.days}
              onChange={handleChange("days")}
              disabled={disabled}
            />

            <Row className="error mt5">{error?.days}</Row>
          </Col>
        </Row>
        {/* No of days end */}

        {/* Price start */}
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Price</label>
          </Col>

          <Col span={18}>
            <Input
              className="custom-input"
              placeholder="Price"
              size="large"
              type="number"
              min={0}
              step={0.1}
              style={{ width: 150 }}
              value={planObj?.price}
              disabled
            />
            <span style={{ color: "darkgray" }}>&nbsp; £/day</span>

            <Row className="error mt5">{error?.price}</Row>
          </Col>
        </Row>
        {/* Price end */}

        {/* Discount start */}
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Discount</label>
          </Col>

          <Col span={18}>
            <Input
              className="custom-input"
              placeholder="Discount"
              size="large"
              type="number"
              min={0}
              step={0.1}
              style={{ width: 150 }}
              value={parseFloat(planObj?.discount || 0).toFixed(2)}
              disabled
            />
            <span style={{ color: "darkgray" }}>&nbsp; %</span>

            <Row className="error mt5">{error?.discount}</Row>
          </Col>
        </Row>
        {/* Discount end */}

        {/* Discount Price start */}
        <Row align="middle" className="mt10">
          <Col span={6}>
            <Col>
              <label className="label">Discount Price</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <i>(Round off)</i>
          </Col>

          <Col span={18}>
            <Input
              className="custom-input"
              placeholder="Discount Price"
              size="large"
              type="number"
              min={0}
              step={1}
              style={{ width: 150 }}
              value={planObj?.discount_price}
              onChange={handleChange("discount_price")}
              disabled={disabled}
            />
            <span style={{ color: "darkgray" }}>&nbsp; £/day</span>

            <Row className="error mt5">{error?.discount_price}</Row>
          </Col>
        </Row>
        {/* Discount Price end */}

        {/* Total Price start */}
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Total Price</label>
          </Col>

          <Col span={18}>
            <Input
              className="custom-input"
              placeholder="Total Price"
              size="large"
              type="number"
              min={0}
              step={0.1}
              style={{ width: 150 }}
              value={planObj?.total_price}
              disabled
            />
            <span style={{ color: "darkgray" }}>&nbsp; £/day</span>
          </Col>
        </Row>
        {/* Total Price end */}

        {/* active start */}
        <Row align="top" className="mt10">
          <Col span={6}></Col>
          <Col span={18}>
            <Checkbox
              disabled={disabled}
              checked={planObj?.active}
              onChange={handleChange("active", true)}
            >
              Active
            </Checkbox>
          </Col>
        </Row>
        {/* active end */}
      </div>
    </CustomModal>
  );
};

export default AddPlan;
