const Actions = {
  FETCH_ALL_CONTACTS: "FETCH_ALL_CONTACTS",
  FETCH_ALL_CONTACTS_SUCCESS: "FETCH_ALL_CONTACTS_SUCCESS",
  FETCH_ALL_CONTACTS_FAILURE: "FETCH_ALL_CONTACTS_FAILURE",

  ADD_CONTACT: "ADD_CONTACT",
  ADD_CONTACT_SUCCESS: "ADD_CONTACT_SUCCESS",
  ADD_CONTACT_FAILURE: "ADD_CONTACT_FAILURE",

  ADMIN_ACKNOWLEDGE_CONTACT: "ADMIN_ACKNOWLEDGE_CONTACT",
  UPDATE_CONTACT: "UPDATE_CONTACT",
  UPDATE_CONTACT_SUCCESS: "UPDATE_CONTACT_SUCCESS",
  UPDATE_CONTACT_FAILURE: "UPDATE_CONTACT_FAILURE",

  DELETE_CONTACT: "DELETE_CONTACT",
  DELETE_CONTACT_SUCCESS: "DELETE_CONTACT_SUCCESS",
  DELETE_CONTACT_FAILURE: "DELETE_CONTACT_FAILURE",
};

export default Actions;
