import moment from "moment";

export const userActivityColumns = () => {
  const columns = [
    {
      title: "Member",
      dataIndex: "member_name",
      key: "member_name",
    },
    {
      title: "First Unlock",
      dataIndex: "first_unlock_at",
      key: "first_unlock_at",
      render: (text) => <a>{moment(text).format("LLL")}</a>,
    },
    {
      title: "Latest Unlock",
      dataIndex: "last_unlock_at",
      key: "last_unlock_at",
      render: (text) => <a>{moment(text).format("LLL")}</a>,
    },
    {
      title: "Latest Door Unlocked",
      dataIndex: "last_unlocked_lock_name",
      key: "last_unlocked_lock_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];
  return columns;
};
