import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../spinner/Spinner";
import OfferPageMetaModal from "./components/OfferPageMetaModal";
import OfferDetailsModal from "./components/OfferDetailsModal";
import OfferItemCard from "./components/OfferItemCard";

import { getAllCMS } from "./duck/CMSActions";
import { checkPermission } from "../utils/CommonFunctions";
import constants from "../utils/Constants";

const { CMS_TYPES, CMS_PAGES } = constants;

const OfferPage = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({});
  const [boardKey, setBoardKey] = useState();
  const [showPageMetaModal, setShowPageMetaModal] = useState(false);
  const isCMSEditable = useMemo(() => checkPermission({ row: "cms" }), []);

  const cmsBoard = useSelector(
    ({ cms }) => cms?.boards?.[JSON.stringify(boardKey)]
  );

  const pageMetaBoardParams = useMemo(
    () => ({ type: CMS_TYPES.PAGE_META, page_name: CMS_PAGES.OFFER }),
    []
  );
  const pageMeta = useSelector(({ cms }) => {
    const boards = cms.boards[JSON.stringify(pageMetaBoardParams)];
    const pageMetaId = boards?.list?.[0];

    return Object.assign({}, cms.cmsMap[pageMetaId]);
  });

  useEffect(() => {
    const params = { type: CMS_TYPES.OFFER };
    setBoardKey(params);
    dispatch(getAllCMS(params));
    dispatch(getAllCMS(pageMetaBoardParams));
  }, [dispatch, pageMetaBoardParams]);

  const handleDetailsModal = useCallback((show = false, _id = null) => {
    show = typeof show === "boolean" && show;
    setState((preState) => ({ ...preState, showDetailsModal: show, _id }));
  }, []);

  const loading = cmsBoard?.loading && !cmsBoard?.list?.length;

  if (loading) {
    return <Spinner />;
  }

  return (
    <Col>
      <Row justify="space-between" align="middle" className="mb10">
        <span className="nw_heading">Offers</span>

        <span>
          {isCMSEditable && (
            <Button
              type="primary-outline mr10"
              onClick={() => setShowPageMetaModal(true)}
            >
              Edit Page Meta
            </Button>
          )}

          {isCMSEditable && (
            <Button
              type="primary-outline"
              onClick={() => handleDetailsModal(true)}
            >
              + Add New Offer
            </Button>
          )}
        </span>
      </Row>
      <Row>
        {cmsBoard?.list?.map((id, i) => (
          <OfferItemCard
            key={i}
            id={id}
            isCMSEditable={isCMSEditable}
            showModal={() => handleDetailsModal(true, id)}
          />
        ))}
      </Row>
      <OfferPageMetaModal
        visible={showPageMetaModal}
        selectedId={pageMeta?._id}
        showModal={() => setShowPageMetaModal(false)}
        isCMSEditable={isCMSEditable}
        pageName={CMS_PAGES.OFFER}
      />
      <OfferDetailsModal
        visible={state.showDetailsModal}
        selectedId={state._id}
        showModal={handleDetailsModal}
        isCMSEditable={isCMSEditable}
      />
    </Col>
  );
};

export default React.memo(OfferPage);
