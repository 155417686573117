const Actions = {
    ADD_WORKSPACE_REQUEST: 'ADD_WORKSPACE_REQUEST',
    ADD_WORKSPACE_SUCCESS: 'ADD_WORKSPACE_SUCCESS',
    ADD_WORKSPACE_FAIL   : 'ADD_WORKSPACE_FAIL',

    UPDATE_WORKSPACE_REQUEST: 'UPDATE_WORKSPACE_REQUEST',
    UPDATE_WORKSPACE_SUCCESS: 'UPDATE_WORKSPACE_SUCCESS',
    UPDATE_WORKSPACE_FAIL   : 'UPDATE_WORKSPACE_FAIL',

    GET_ALL_WORKSPACE_REQUEST: 'GET_ALL_WORKSPACE_REQUEST',
    GET_ALL_WORKSPACE_SUCCESS: 'GET_ALL_WORKSPACE_SUCCESS',
    GET_ALL_WORKSPACE_FAIL   : 'GET_ALL_WORKSPACE_FAIL',

    GET_WORKSPACE_REQUEST: 'GET_WORKSPACE_REQUEST',
    GET_WORKSPACE_SUCCESS: 'GET_WORKSPACE_SUCCESS',
    GET_WORKSPACE_FAIL   : 'GET_WORKSPACE_FAIL',

    SET_ACTIVE_WORKSPACE: 'SET_ACTIVE_WORKSPACE'
}

export default Actions