import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Input,
  Popconfirm,
  Popover,
  Row,
  Select,
  Tooltip,
  Upload,
  message,
} from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import ReactQuill from "react-quill";

import Spinner from "../../spinner/Spinner";
import MUploadItem from "./MUploadItem";
import ServicePage from "../ServicePage";
import SvgIcons from "../../utils/SvgIcon";

import AppUrl from "../../config/AppUrl";
import { createCMS, updateCMS } from "../duck/CMSActions";
import { checkPermission, showNotification } from "../../utils/CommonFunctions";
import { amenitieIconMap } from "../../utils/AmenitieIcons";
import Routes from "../../config/Routes";
import constants from "../../utils/Constants";

const { CMS_TYPES } = constants;
const createNewSpace = "createNewSpace";

const AddOurSpacePage = () => {
  const history = useHistory();
  const params = useParams();
  const selectedId = params?.id === createNewSpace ? null : params?.id;

  const prevProp = useRef();
  const dispatch = useDispatch();

  const isCMSEditable = useMemo(() => checkPermission({ row: "cms" }), []);

  const cms = useSelector(({ cms }) => cms);
  const mCms = useMemo(
    () =>
      selectedId
        ? cms?.cmsMap?.[selectedId]
        : { loading: cms?.loading, error: cms?.error },
    [selectedId, cms]
  );
  console.log("mCms", mCms);
  const [state, setState] = useState({});
  const [keyInfos, setKeyInfos] = useState([]);
  const [prices, setPrices] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [floorPlans, setFloorPlans] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [videoFileList, setVideoFileList] = useState([]);
  const [error, setError] = useState({});
  const [dragId, setDragId] = useState();

  console.log("fileList", fileList);
  console.log("videoFileList", videoFileList);
  const modalTitle = useMemo(
    () =>
      isCMSEditable
        ? selectedId
          ? "Edit Space Details"
          : "Add Space Details"
        : "Space Details",
    [isCMSEditable, selectedId]
  );

  useEffect(() => {
    let mState = {};
    let thumbnails = [];
    let floorPlans = [];
    let pictureFiles = [];
    let videoFiles = [];
    let mKeyInfos = [];
    let mPrices = [];

    if (selectedId) {
      const meta = mCms?.meta || {};
      const pageMeta = meta?.pageMeta;
      mState = {
        name: mCms?.name,
        description: mCms?.description,
        metaTitle: pageMeta?.title,
        metaDescription: pageMeta?.description,
        metaSlug: pageMeta?.slug,
        brief: meta?.brief,
        active: mCms?.active,
      };

      if (Array.isArray(meta.keyInfos) && meta.keyInfos.length > 0) {
        if (typeof meta.keyInfos[0] === "string") {
          mKeyInfos = [...meta.keyInfos].map((keyInfo) => ({
            name: keyInfo,
          }));
        } else {
          mKeyInfos = [...meta.keyInfos];
        }
      }
      if (meta?.prices) {
        mPrices = JSON.parse(JSON.stringify([...meta?.prices]));
      }
      if (mCms?.pictures) {
        const mThumbnailFiles = mCms.pictures.filter(
          (file) => file.type === "thumbnail"
        );
        const mFloorPlanFiles = mCms.pictures.filter(
          (file) => file.type === "floor_plan"
        );
        const mPictureFiles = mCms.pictures.filter(
          (file) => file.type === "file"
        );
        const mVideoFiles = mCms.pictures.filter(
          (file) => file.type === "video"
        );

        thumbnails = JSON.parse(JSON.stringify(mThumbnailFiles));
        floorPlans = JSON.parse(JSON.stringify(mFloorPlanFiles));
        pictureFiles = JSON.parse(JSON.stringify(mPictureFiles));
        videoFiles = JSON.parse(JSON.stringify(mVideoFiles));
      }
    }

    setState(mState);
    setThumbnails(thumbnails);
    setFloorPlans(floorPlans);
    setKeyInfos(mKeyInfos);
    setPrices(mPrices);
    setFileList(pictureFiles);
    setVideoFileList(videoFiles);
    setError({});
  }, [selectedId]);

  useEffect(() => {
    if (prevProp?.current?.loading && !mCms?.loading) {
      history.goBack();
    }

    return () => {
      prevProp.current = {
        loading: mCms?.loading,
      };
    };
  }, [selectedId, mCms, history]);

  const beforeUploadThumbnailImage = useCallback((file) => {
    const supportedFileType = ["image/png", "image/jpg", "image/jpeg"];
    const isAcceptable = supportedFileType.includes(file?.type);

    if (!isAcceptable) {
      showNotification(
        "error",
        "Please upload JEPG, JGP, PNG or GIF file only"
      );
    }

    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const updateThumbnailImageUpload = useCallback(({ file }) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = function () {
      const width = img.naturalWidth,
        height = img.naturalHeight;

      URL.revokeObjectURL(img.src);
      if (width === 1440 && height === 820) {
        const fileObj = {
          uid: file?.uid,
          name: file?.name,
          size: file?.size,
          type: file?.type,
          thumb_location: URL.createObjectURL(file),
          url: URL.createObjectURL(file),
          tag: "",
          file,
        };

        setError({});
        setThumbnails((preState) => [...preState, fileObj]);
      } else {
        showNotification("error", "Please upload exact image size of 1440x820");
      }
    };
  }, []);

  const onRemoveThumbnailImage = useCallback((file) => {
    const fileId = file?._id || file?.uid;
    setThumbnails((preState) =>
      preState.filter((p) => (p?._id || p?.uid) !== fileId)
    );
  }, []);

  const beforeUploadFloorPlan = useCallback((file) => {
    const supportedFileType = ["application/pdf"];

    const isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification("error", "Please upload PDF file only");
    }

    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const uploadFloorPlan = useCallback(({ file }) => {
    const fileObj = {
      uid: file?.uid,
      name: file?.name,
      size: file?.size,
      type: file?.type,
      thumb: SvgIcons.Pdf,
      url: URL.createObjectURL(file),
      tag: "",
      file,
    };

    setError({});
    setFloorPlans((preState) => [...preState, fileObj]);
  }, []);

  const onRemoveFloorPlan = useCallback((file) => {
    const fileId = file?._id || file?.uid;
    setFloorPlans((preState) =>
      preState.filter((p) => (p?._id || p?.uid) !== fileId)
    );
  }, []);

  const beforeUpload = useCallback((file) => {
    const supportedFileType = ["image/png", "image/jpg", "image/jpeg"];

    let isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification(
        "error",
        "Please upload JEPG, JGP, PNG or GIF file only"
      );
    }
    return isAcceptable || Upload.LIST_IGNORE;
  }, []);
  const beforeVideoUpload = useCallback((file) => {
    const isAcceptable = file?.type.split("/")[0] === "video";

    if (!isAcceptable) {
      showNotification("error", "Please upload a video file.");
    }

    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const updateImageUpload = useCallback(({ file }) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = function () {
      const width = img.naturalWidth,
        height = img.naturalHeight;

      URL.revokeObjectURL(img.src);
      if (width == 1440 && height == 820) {
        const fileObj = {
          uid: file?.uid,
          name: file?.name,
          size: file?.size,
          type: file?.type,
          thumb_location: URL.createObjectURL(file),
          url: URL.createObjectURL(file),
          tag: "",
          file,
        };

        setError({});
        setFileList((preState) => {
          return [
            ...preState,
            { ...fileObj, extra: { order: preState.length } },
          ];
        });
      } else {
        showNotification("error", "Please upload exact image size of 1440x820");
      }
    };
  }, []);

  const updateVideoUpload = useCallback(({ file }) => {
    const video = document.createElement("video");
    video.onloadedmetadata = function () {
      const maxSize = 50 * 1024 * 1024;

      if (file.size > maxSize) {
        showNotification("error", "Please upload a video up to 50 MB in size.");
        return;
      }

      const fileObj = {
        uid: file?.uid,
        name: file?.name,
        size: file?.size,
        type: file?.type,
        thumb_location: URL.createObjectURL(file),
        url: URL.createObjectURL(file),
        tag: "",
        file,
      };

      setError({});
      setVideoFileList((prevState) => {
        return [
          ...prevState,
          { ...fileObj, extra: { order: prevState.length } },
        ];
      });
    };

    const blob = new Blob([file], { type: file?.type });
    video.src = URL.createObjectURL(blob);
  }, []);

  const onRemoveImage = useCallback((file) => {
    const fileId = file?._id || file?.uid;
    setFileList((preState) =>
      preState
        .filter((p) => (p?._id || p?.uid) !== fileId)
        ?.map((file, i) => ({ ...file, extra: { ...file?.extra, order: i } }))
    );
  }, []);
  const onRemoveVideo = useCallback((file) => {
    const fileId = file?._id || file?.uid;
    setVideoFileList((preState) =>
      preState
        .filter((p) => (p?._id || p?.uid) !== fileId)
        ?.map((file, i) => ({ ...file, extra: { ...file?.extra, order: i } }))
    );
  }, []);
  const handleTagChange = useCallback((file) => {
    setFloorPlans((preState) =>
      preState.map((perFile) =>
        (perFile._id && perFile._id === file._id) || perFile.uid === file.uid
          ? file
          : perFile
      )
    );
    setFileList((preState) =>
      preState.map((perFile) =>
        (perFile._id && perFile._id === file._id) || perFile.uid === file.uid
          ? file
          : perFile
      )
    );
    setVideoFileList((preState) =>
      preState.map((perFile) =>
        (perFile._id && perFile._id === file._id) || perFile.uid === file.uid
          ? file
          : perFile
      )
    );
    setError({});
  }, []);

  const handleChange = useCallback(
    (name, isCheckbox) => (e) => {
      let value;
      if (isCheckbox) {
        value = e?.target?.checked;
      } else {
        value = e?.target?.value ?? e;
      }

      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const onAddKeyInfo = useCallback(() => {
    setKeyInfos((preState) => {
      preState.push({});
      return [...preState];
    });
    setError({});
  }, []);

  const onRemoveKeyInfo = useCallback((i) => {
    setKeyInfos((preState) => {
      preState.splice(i, 1);
      return [...preState];
    });
    setError({});
  }, []);

  const handleKeyInfoChange = useCallback(
    (i = 0, name = "") =>
      (e) => {
        const value = e?.target?.value ?? e;

        setKeyInfos((preState) => {
          preState[i][name] = value;
          return [...preState];
        });
        setError({});
      },
    []
  );

  const onAddPrice = useCallback(() => {
    setPrices((preState) => {
      preState.push({
        label: "",
        value: "",
      });
      return [...preState];
    });
    setError({});
  }, []);

  const onRemovePrice = useCallback((i) => {
    setPrices((preState) => {
      preState.splice(i, 1);
      return [...preState];
    });
    setError({});
  }, []);

  const handlePriceChange = useCallback((e, i) => {
    const { name, value } = e.target;

    setPrices((preState) => {
      preState[i][name] = value;
      return [...preState];
    });
    setError({});
  }, []);

  const hasError = useCallback(() => {
    let { name, description, metaTitle, metaDescription, metaSlug, brief } =
      state;
    name = name?.trim?.();
    description = description?.trim?.();
    metaTitle = metaTitle?.trim?.();
    metaDescription = metaDescription?.trim?.();
    brief = brief?.trim?.();
    const error = {};

    if (!metaTitle) {
      error.metaTitle = "Title cannot be blank";
    }

    if (!metaDescription) {
      error.metaDescription = "Description cannot be blank";
    }
    /* if (!metaSlug) {
      error.metaSlug = "Slug cannot be blank";
    } */

    if (!name) {
      error.name = "Name cannot be blank";
    }
    if (!brief) {
      error.brief = "Brief cannot be blank";
    }
    if (!description) {
      error.description = "Description cannot be blank";
    }

    if (!thumbnails?.length) {
      error.thumbnails = "Please upload thumbnail";
    }

    if (!floorPlans?.length) {
      error.floorPlans = "Please upload floor plan";
    }

    if (!keyInfos?.length) {
      error.keyInfos = "Please add atleast one Key Information";
    } else {
      const keyInfoErrors = [];
      keyInfos.forEach((keyInfo, i) => {
        if (!keyInfo?.icon) {
          keyInfoErrors[i] = "Please Select Icon";
        } else if (!keyInfo?.name) {
          keyInfoErrors[i] = "Key Information cannot be blank";
        }
      });

      if (keyInfoErrors.length > 0) {
        error.keyInfo = keyInfoErrors;
      }
    }

    if (!prices?.length) {
      error.prices = "Please add atleast one price";
    } else {
      const priceErrors = [];
      prices.forEach((p, i) => {
        const label = p.label.trim?.();
        const value = p.value?.trim?.();

        if (!label) {
          priceErrors[i] = Object.assign({}, priceErrors[i], {
            label: "Price label cannot be blank",
          });
        }
        if (!value) {
          priceErrors[i] = Object.assign({}, priceErrors[i], {
            value: "Price cannot be blank",
          });
        }
      });

      if (priceErrors.length > 0) {
        error.price = priceErrors;
      }
    }

    if (!fileList?.length) {
      error.fileList = "Please upload atleast one image";
    }

    setError(error);
    return Object.keys(error).length;
  }, [state, thumbnails, floorPlans, keyInfos, prices, fileList]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const {
        name,
        description,
        metaTitle,
        metaDescription,
        metaSlug,
        active,
        brief,
      } = state;
      const pageMeta = {
        title: metaTitle,
        description: metaDescription,
        slug: metaSlug,
      };
      const payload = {
        name,
        description,
        active,
        thumbnail: [],
        floor_plan: [],
        files: [],
        video: [],
        extra: [],
        tags: {
          thumbnail: [],
          file: [],
          floor_plan: [],
          video: [],
        },
        meta: JSON.stringify({
          pageMeta,
          keyInfos,
          prices,
          brief,
        }),
        type: CMS_TYPES.OUR_SPACE,
      };

      console.log("payload", payload);
      thumbnails.forEach((picture) => {
        if (picture?.file) {
          payload.thumbnail.push(picture?.file);
          payload.tags.thumbnail.push(picture?.tag || "");
        }
      });

      floorPlans.forEach((floorPlan) => {
        if (floorPlan?.file) {
          payload.floor_plan.push(floorPlan?.file);
          payload.tags.floor_plan.push(floorPlan?.tag || "");
        }
      });

      fileList.forEach((picture, i) => {
        if (picture?.file) {
          payload.files.push(picture?.file);
          // payload.tags.push(picture?.tag || "");
          payload.tags.file.push(picture?.tag || "");
          payload.extra.push({ ...picture?.extra, order: i } || "");
        }
      });
      videoFileList.forEach((videos, i) => {
        if (videos?.file) {
          payload.video.push(videos?.file);
          payload.tags.video.push(videos?.tag || "");
          payload.extra.push({ ...videos?.extra, order: i } || "");
        }
      });
      payload.tags = JSON.stringify(payload.tags);
      payload.extra = JSON.stringify(payload.extra);

      if (selectedId) {
        payload._id = selectedId;

        const allAttachments = [
          ...thumbnails,
          ...floorPlans,
          ...fileList,
          ...videoFileList,
        ];

        const oldFileListIds = mCms?.pictures?.map?.(
          (file) => file?._id || file?.uid
        );
        const currentUploadedFileListIds = allAttachments
          .filter((file) => !file?.file && file?._id)
          .map((file) => file?._id || file?.uid);

        const removedUploadedFileIds = oldFileListIds?.filter?.(
          (id) => !currentUploadedFileListIds?.includes(id)
        );

        removedUploadedFileIds.forEach((id) => {
          axios({
            method: "DELETE",
            url: `${AppUrl.ATTACHMENTS}/${id}`,
          });
        });

        allAttachments.forEach((file, index) => {
          if (file?._id) {
            const mTag = file.tag?.trim?.();
            const mPicture = mCms?.pictures?.find((p) => p?._id === file?._id);
            const extra = { order: index, ...file?.extra };

            if (
              mTag !== mPicture.tag ||
              index !== file?.extra?.order ||
              JSON.stringify(extra) !== JSON.stringify(mPicture?.extra)
            ) {
              const data = {
                tag: mTag,
                extra,
              };

              axios({
                method: "PUT",
                url: `${AppUrl.ATTACHMENTS}/${file?._id}`,
                data,
              });
            }
          }
        });

        dispatch(updateCMS(payload));
      } else {
        dispatch(createCMS(payload));
      }
    }
  }, [
    mCms?.pictures,
    selectedId,
    state,
    keyInfos,
    prices,
    thumbnails,
    floorPlans,
    fileList,
    videoFileList,
    hasError,
    dispatch,
  ]);

  const SelectIcon = useCallback(
    (props) => (
      <Select
        className="custom-select-input-icon"
        style={{ width: 300 }}
        placeholder="Select Icon"
        size="large"
        showSearch
        {...props}
      >
        {Object.values(amenitieIconMap).map((icon, i) => (
          <Select.Option
            className="custom-select-input-icon-option"
            key={i}
            value={icon._id}
          >
            <Row className="select-icon-option" align="middle">
              <Col span={4}>
                <Row align="middle" justify="center">
                  <img
                    className="icon-img"
                    src={`/images/icons/${icon.icon}`}
                    alt=""
                  />
                </Row>
              </Col>

              <Col className="icon-name pl10 pr15" span={20}>
                {icon.name}
              </Col>
            </Row>
          </Select.Option>
        ))}
      </Select>
    ),
    []
  );

  const handleDragOver = (ev) => {
    ev.preventDefault();
  };

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const dragBox = fileList.find((box) => {
      const id = box._id || box.uid;
      return id === dragId;
    });

    const dropBox = fileList.find((box) => {
      const id = box._id || box.uid;
      return id === ev.currentTarget.id;
    });

    const dragBoxOrder = dragBox?.extra?.order;
    const dropBoxOrder = dropBox?.extra?.order;
    const newBoxState = fileList.map((box) => {
      const id = box._id || box.uid;
      if (id === dragId) {
        box.extra.order = dropBoxOrder;
      }
      if (id === ev.currentTarget.id) {
        box.extra.order = dragBoxOrder;
      }
      return box;
    });

    setFileList(newBoxState);
    setVideoFileList(newBoxState);

    /* setState((prevState) => {
      return {
        ...prevState,
        images: newBoxState,
      };
    }); */
  };

  const disabled = !isCMSEditable || mCms?.loading;

  if (params?.id !== createNewSpace && !mCms?._id) {
    history.replace(Routes.ourSpace);
    return <Spinner />;
  }

  return (
    <div>
      <Row justify="space-between" align="middle" className="mb10">
        <span className="nw_heading">{modalTitle}</span>

        <span>
          {isCMSEditable && (
            <Button type="primary" loading={mCms?.loading} onClick={onSubmit}>
              Save
            </Button>
          )}

          <Button
            className="ml10"
            disabled={mCms?.loading}
            onClick={() => history.goBack()}
          >
            <ArrowLeftOutlined />
            Back
          </Button>
        </span>
      </Row>

      <Col className="content">
        <Collapse
          className="collapse-section"
          defaultActiveKey={[
            "SpaceDetails",
            "MetaDetails",
            "OthersDetails",
            "FoldsDetails",
          ]}
        >
          {/* space details start */}
          <Collapse.Panel key={"SpaceDetails"} header="Space Basic Details">
            {/* name start */}
            <Row className="mb10">
              <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
                <label className="label">Name</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>

              <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
                <Input
                  className="custom-input fw mt10"
                  placeholder={"Name"}
                  size="large"
                  allowClear
                  value={state?.name}
                  onChange={handleChange("name")}
                  disabled={disabled}
                />

                <Row className="error mt5">{error?.name}</Row>
              </Col>
            </Row>
            {/* name end */}
            {/* brief start */}
            <Row className="mb10">
              <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
                <label className="label">Brief</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>

              <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
                <Input
                  className="custom-input fw mt10"
                  placeholder={"Brief"}
                  size="large"
                  allowClear
                  value={state?.brief}
                  onChange={handleChange("brief")}
                  disabled={disabled}
                />

                <Row className="error mt5">{error?.name}</Row>
              </Col>
            </Row>
            {/* brief end */}
            {/* description start */}
            <Row>
              <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
                <label className="label">Description/Details </label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>

              <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
                <ReactQuill
                  className="disabled"
                  style={{
                    height: 200,
                    marginBottom: 50,
                  }}
                  placeholder="Description"
                  theme="snow"
                  value={state.description}
                  onChange={handleChange("description")}
                />

                <Row className="error mt5">{error.description}</Row>
              </Col>
            </Row>
            {/* description end */}
          </Collapse.Panel>
          {/* space details end */}

          {/* page meta details start */}
          <Collapse.Panel key={"MetaDetails"} header="Page Meta Details">
            <>
              {/* title start */}
              <Row className="mb10" align="middle">
                <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
                  <label className="label">Title</label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
                  <Input
                    className="custom-input"
                    placeholder="Title"
                    size="large"
                    type="text"
                    value={state.metaTitle}
                    onChange={handleChange("metaTitle")}
                    disabled={disabled}
                  />

                  <Row className="error mt5">{error.metaTitle}</Row>
                </Col>
              </Row>
              {/* title end */}

              {/* description start */}
              <Row>
                <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
                  <label className="label">Description </label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
                  <Input.TextArea
                    className="custom-input"
                    placeholder="Description"
                    size="large"
                    type="text"
                    value={state.metaDescription}
                    onChange={handleChange("metaDescription")}
                    autoSize={{ minRows: 2, maxRows: 3 }}
                    disabled={disabled}
                  />

                  <Row className="error mt5">{error.metaDescription}</Row>
                </Col>
              </Row>
              {/* description end */}
            </>
          </Collapse.Panel>
          {/* page meta details end */}

          {/* other details start */}
          <Collapse.Panel key={"OthersDetails"} header="Others Details">
            {/* page slug start */}
            <Row className="mb10" align="middle">
              <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
                <label className="label">Page Slug</label>
                {/* <sup style={{ color: "red" }}>*</sup> */}
              </Col>

              <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
                <Input
                  className="custom-input"
                  placeholder="Slug"
                  size="large"
                  type="text"
                  value={state.metaSlug}
                  onChange={handleChange("metaSlug")}
                  disabled={disabled}
                />

                <Row className="error mt5">{error.metaSlug}</Row>
              </Col>
            </Row>
            {/* page slug end */}
            {/* thumbnail images start */}
            <Row className="mb10">
              <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
                <label className="label">Thumbnail Image</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>
              <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
                <Upload
                  className="custom-upload-list"
                  accept={".jpeg, .jpg, .png"}
                  beforeUpload={beforeUploadThumbnailImage}
                  listType="picture"
                  fileList={thumbnails}
                  onRemove={onRemoveThumbnailImage}
                  customRequest={updateThumbnailImageUpload}
                  disabled={disabled}
                  itemRender={(originNode, file, _, actions) => (
                    <MUploadItem
                      originNode={originNode}
                      file={file}
                      actions={actions}
                      handleTagChange={handleTagChange}
                      disabled={disabled}
                    />
                  )}
                >
                  {thumbnails.length === 0 && (
                    <Row
                      className="custom-upload-image mb10"
                      align="middle"
                      justify="center"
                    >
                      <Col className="mt5">
                        <UploadOutlined className="mr5" /> Upload 1440x820
                        <sup style={{ color: "red" }}>*</sup>
                      </Col>
                    </Row>
                  )}
                </Upload>
                <Row className="error mt5">{error?.thumbnails}</Row>
              </Col>
            </Row>
            {/* thumbnail images end */}

            {/* floor plan start */}
            <Row className="mb10">
              <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
                <label className="label">Floor Plan</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>

              <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
                <Upload
                  className="custom-upload-list"
                  accept={".pdf"}
                  beforeUpload={beforeUploadFloorPlan}
                  listType="picture"
                  fileList={floorPlans}
                  onRemove={onRemoveFloorPlan}
                  customRequest={uploadFloorPlan}
                  disabled={disabled}
                  itemRender={(originNode, file, _, actions) => (
                    <MUploadItem
                      originNode={originNode}
                      file={file}
                      actions={actions}
                      handleTagChange={handleTagChange}
                      disabled={disabled}
                    />
                  )}
                >
                  {/* {floorPlans.length === 0 && ( */}
                  <Row
                    className="custom-upload-image mb10"
                    align="middle"
                    justify="center"
                  >
                    <Col className="mt5">
                      <UploadOutlined className="mr5" /> Upload 1440x820
                      <sup style={{ color: "red" }}>*</sup>
                    </Col>
                  </Row>
                  {/* )} */}
                </Upload>

                <Row className="error mt5">{error.floorPlans}</Row>
              </Col>
            </Row>
            {/* floor plan end */}

            {/* Key Information start */}
            <Row className="mb10">
              <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
                <label className="label">Key Information </label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>

              <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
                <Row>
                  {keyInfos.map((keyInfo, i) => (
                    <Col key={i} className="mb5" span={12}>
                      <Row align="middle" justify="space-between">
                        <Col span={4} className="pr10">
                          <Row
                            className="custom-select-icon"
                            align="middle"
                            justify="center"
                          >
                            <Popover
                              content={
                                <SelectIcon
                                  value={keyInfo.icon}
                                  onChange={handleKeyInfoChange(i, "icon")}
                                />
                              }
                              placement={"bottomLeft"}
                              trigger="click"
                              bordered={false}
                              showArrow={false}
                            >
                              <Button type="text">
                                <img
                                  className="icon-img"
                                  src={`/images/icons/${
                                    amenitieIconMap[keyInfo.icon]?.icon
                                  }`}
                                  alt=""
                                />
                              </Button>
                            </Popover>
                          </Row>
                        </Col>

                        <Col span={16}>
                          <Input
                            className="custom-input"
                            placeholder="Key Information"
                            size="large"
                            type="text"
                            value={keyInfo.name}
                            onChange={handleKeyInfoChange(i, "name")}
                            disabled={disabled}
                          />
                        </Col>

                        <Col span={4}>
                          {isCMSEditable && (
                            <Tooltip title="Delete">
                              <Popconfirm
                                title="Are you sure to delete this?"
                                onConfirm={() => onRemoveKeyInfo(i)}
                                okText="Yes"
                                cancelText="No"
                                placement="bottom"
                                disabled={disabled}
                              >
                                <DeleteOutlined className="ml20" />
                              </Popconfirm>
                            </Tooltip>
                          )}
                        </Col>
                      </Row>

                      <Row className="error mt5">{error.keyInfo?.[i]}</Row>
                    </Col>
                  ))}
                </Row>

                {/* btn key information start */}
                <Col span={12}>
                  <Button
                    className={keyInfos.length > 0 ? "mt5" : ""}
                    size="large"
                    block
                    onClick={onAddKeyInfo}
                    disabled={disabled}
                  >
                    + Add New Key Information
                  </Button>

                  <Row className="error mt5">{error.keyInfos}</Row>
                </Col>
                {/* btn key information end */}
              </Col>
            </Row>
            {/* Key Information end */}

            {/* Prices list start */}
            <Row className="mb10">
              <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
                <label className="label">Prices </label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>

              <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
                {prices.map((price, i) => (
                  <Col key={i} className="mb5">
                    <Row justify="space-between">
                      {/* price label start */}
                      <Col span={11} className="pr5">
                        <Input
                          className="custom-input"
                          placeholder="Label"
                          name="label"
                          size="large"
                          type="text"
                          value={price.label}
                          onChange={(e) => handlePriceChange(e, i)}
                          disabled={disabled}
                        />

                        <Row className="error mt5">
                          {error.price?.[i]?.label}
                        </Row>
                      </Col>
                      {/* price label end */}

                      {/* price value start */}
                      <Col span={11} className="pr5">
                        <Input
                          className="custom-input"
                          placeholder="Value"
                          name="value"
                          size="large"
                          type="text"
                          value={price.value}
                          onChange={(e) => handlePriceChange(e, i)}
                          disabled={disabled}
                        />

                        <Row className="error mt5">
                          {error.price?.[i]?.value}
                        </Row>
                      </Col>
                      {/* price value end */}

                      {/* btn delete price start */}
                      <Col span={2}>
                        {isCMSEditable && (
                          <Tooltip title="Delete">
                            <Popconfirm
                              title="Are you sure to delete this?"
                              onConfirm={() => onRemovePrice(i)}
                              okText="Yes"
                              cancelText="No"
                              placement="bottom"
                              disabled={disabled}
                            >
                              <DeleteOutlined className="ml10 mt15" />
                            </Popconfirm>
                          </Tooltip>
                        )}
                      </Col>
                      {/* btn delete price end */}
                    </Row>
                  </Col>
                ))}

                {/* btn add price satrt */}
                <Col span={12}>
                  <Button
                    className={prices.length > 0 ? "mt5" : ""}
                    size="large"
                    block
                    onClick={onAddPrice}
                    disabled={disabled}
                  >
                    + Add New Price
                  </Button>

                  <Row className="error mt5">{error.prices}</Row>
                </Col>
                {/* btn add price end */}
              </Col>
            </Row>
            {/* Prices list end */}

            {/* gallery images start */}
            <Row className="mb10">
              <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
                <label className="label">Images</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>

              <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
                <Upload
                  className="custom-upload-list"
                  accept={".jpeg, .jpg, .png"}
                  beforeUpload={beforeUpload}
                  listType="picture"
                  fileList={fileList?.sort(
                    (a, b) => a?.extra?.order - b?.extra?.order
                  )}
                  onRemove={onRemoveImage}
                  customRequest={updateImageUpload}
                  disabled={disabled}
                  itemRender={(originNode, file, _, actions) => (
                    <MUploadItem
                      originNode={originNode}
                      file={file}
                      actions={actions}
                      handleTagChange={handleTagChange}
                      disabled={disabled}
                      handleDragOver={handleDragOver}
                      handleDrag={handleDrag}
                      handleDrop={handleDrop}
                    />
                  )}
                >
                  <Row
                    className="custom-upload-image mb10"
                    align="middle"
                    justify="center"
                  >
                    <Col className="mt5">
                      <UploadOutlined className="mr5" /> Upload 1440x820
                      <sup style={{ color: "red" }}>*</sup>
                    </Col>
                  </Row>
                </Upload>
                <Row className="error mt5">{error?.fileList}</Row>
              </Col>
            </Row>
            {/* gallery images end */}

            {/* gallery videos start */}
            <Row className="mb10">
              <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
                <label className="label">Videos</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>

              <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
                <Upload
                  className="custom-upload-list"
                  accept={"video"}
                  fileList={videoFileList}
                  beforeUpload={beforeVideoUpload}
                  listType="picture"
                  videoFileList={videoFileList?.sort(
                    (a, b) => a?.extra?.order - b?.extra?.order
                  )}
                  onRemove={onRemoveVideo}
                  customRequest={updateVideoUpload}
                  disabled={disabled}
                  itemRender={(originNode, file, _, actions) => (
                    <MUploadItem
                      originNode={originNode}
                      actions={actions}
                      file={file}
                      handleTagChange={handleTagChange}
                      disabled={disabled}
                      // handleDragOver={handleDragOver}
                      // handleDrag={handleDrag}
                      // handleDrop={handleDrop}
                    />
                  )}
                >
                  <Row
                    className="custom-upload-image mb10"
                    align="middle"
                    justify="center"
                  >
                    <Col className="mt5">
                      <UploadOutlined className="mr5" /> Upload Videos
                      <sup style={{ color: "red" }}>*</sup>
                    </Col>
                  </Row>
                </Upload>
                <Row className="error mt5">{error?.videoFileList}</Row>
              </Col>
            </Row>

            {/* gallery videos end */}

            {/* active start */}
            {selectedId && (
              <Row align="top" className="mt10">
                <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4} />

                <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
                  <Checkbox
                    disabled={disabled}
                    checked={state?.active}
                    onChange={handleChange("active", true)}
                  >
                    Active
                  </Checkbox>
                </Col>
              </Row>
            )}
            {/* active end */}
          </Collapse.Panel>
          {/* other details end */}

          {/* our space details page folds start */}
          {selectedId && (
            <Collapse.Panel key={"FoldsDetails"} header="Folds Details">
              <ServicePage
                title={`${mCms?.name} Page`}
                show_title={false}
                page_id={selectedId}
                cms_type={CMS_TYPES.PAGE_FOLD}
                show_edit_meta_details={false}
              />
            </Collapse.Panel>
          )}
          {/* our space details page folds end */}
        </Collapse>
      </Col>
    </div>
  );
};

export default React.memo(AddOurSpacePage);
