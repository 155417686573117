import Actions from "./MailActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";

const initialState = {
  mailTemplateMap: {},
  mailTemplates: {},
  loading: false,
  error: null,
};

const mailReducer = (state = initialState, action) => {
  const payload = action?.payload;

  switch (action.type) {
    /** get all mail template start */

    case Actions.FETCH_ALL_MAIL_TEMPLATE: {
      return Object.assign({}, state, {
        mailTemplates: Object.assign({}, state.mailTemplates, {
          [payload?.space_id]: Object.assign(
            {},
            state.mailTemplates?.[payload?.space_id]
          ),
        }),
        error: false,
        fetching: true,
      });
    }

    case Actions.FETCH_ALL_MAIL_TEMPLATE_SUCCESS: {
      return Object.assign({}, state, {
        mailTemplateMap: Object.assign(
          {},
          state.mailTemplateMap,
          arrToObjMap(payload?.data)
        ),
        mailTemplates: Object.assign({}, state.mailTemplates, {
          [payload?.space_id]: Object.assign(
            {},
            state.mailTemplates?.[payload?.space_id],
            {
              list: getArrOrder(payload?.data),
            }
          ),
        }),
        fetching: false,
      });
    }

    case Actions.FETCH_ALL_MAIL_TEMPLATE_FAILURE: {
      return Object.assign({}, state, {
        mailTemplates: Object.assign({}, state.mailTemplates, {
          [payload?.space_id]: Object.assign(
            {},
            state.mailTemplates?.[payload?.space_id],
            {
              error: false,
            }
          ),
        }),
        fetching: false,
      });
    }

    /** get all mail template end */

    /** send mail start */

    case Actions.SEND_CAMPAIGN_MAIL: {
      return Object.assign({}, state, {
        error: false,
        loading: true,
      });
    }

    case Actions.SEND_CAMPAIGN_MAIL_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case Actions.SEND_CAMPAIGN_MAIL_FAILURE: {
      return Object.assign({}, state, {
        error: payload,
        loading: false,
      });
    }

    /** send mail start */

    default:
      return state;
  }
};

export default mailReducer;
