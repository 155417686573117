import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Modal, Button, Row, Col } from "antd";
import { Elements, CardNumberElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import appUrl from "../../config/AppUrl";
import EnterCardForm from "./EnterCardForm/EnterCardForm";
import { addCardStart } from "../duck/BillingActions";
import { showNotification } from "../../utils/CommonFunctions";

import "./AddNewCard.scss";

const stripePromise = loadStripe(appUrl.STRIPE_PUBLISHABLE_KEY);

const AddNewCard = (props) => {
  const { visible, handleModal } = props;
  const prevProps = useRef();

  const [state, setState] = useState({});
  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);

  const loggedInUser = useSelector((state) => state.user.loggedInUser);
  const adding = useSelector((state) => state.billing.adding);

  const dispatch = useDispatch();

  useEffect(() => {
    if (prevProps.current?.adding && !adding) {
      handleModal();
    }

    return () => {
      prevProps.current = { adding };
    };
  }, [adding]);

  const createSource = useCallback(async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return {
        error: "We experienced some error, please refresh and try again.",
      };
    }

    return await stripe.createSource(elements.getElement(CardNumberElement), {
      type: "card",
      owner: {
        email: loggedInUser?.email,
        name: state.cardNameHolder,
      },
    });
  }, [stripe, elements, loggedInUser?.email, state.cardNameHolder]);

  const hasError = () => {
    let { cardNameHolder } = state;

    let nameError = "";
    let isError = false;

    cardNameHolder = cardNameHolder?.trim();
    if (!cardNameHolder) {
      nameError = "Please enter name on card";
      isError = true;
    }

    setState({
      ...state,
      nameError,
    });

    return isError;
  };

  const addNewCard = useCallback(async () => {
    if (!hasError()) {
      setState((preState) => ({ ...preState, loading: true }));
      const result = await createSource();

      if (!result.error) {
        dispatch(addCardStart(result?.source));
      } else {
        setState((preState) => ({ ...preState, loading: false }));
        showNotification("error", result?.error?.message);
      }
    }
  }, [hasError, createSource]);

  const setCardNameHolder = (e) => {
    const value = e.target.value;

    setState({
      ...state,
      cardNameHolder: value,
      nameError: "",
    });
  };

  const { cardNameHolder, nameError } = state;

  return (
    <Modal
      visible={visible}
      onCancel={handleModal}
      className="custom-modal add-stripe-card-modal"
      title={"Add card"}
      footer={[
        <Button onClick={handleModal}>Cancel</Button>,

        <Button
          type="primary"
          onClick={addNewCard}
          loading={adding || state.loading}
        >
          Add
        </Button>,
      ]}
    >
      <Elements stripe={stripePromise}>
        <Col className="mb20">
          <Col>
            <label className="label">Name on Card</label>
          </Col>

          <Input
            placeholder={"Peter"}
            value={cardNameHolder}
            onChange={setCardNameHolder}
            disabled={adding}
            allowClear
          />
          <Col className="error mt5">{nameError}</Col>
        </Col>

        <EnterCardForm setStripe={setStripe} setElements={setElements} />
      </Elements>
    </Modal>
  );
};

export default AddNewCard;
