import React, { useCallback } from "react";
import TitleComponent from "./miniComponents/TitleComponent";
import { Col, Row } from "antd";
import Mselectableitem from "../components/Mselectableitem";
import constants from "../../utils/Constants";
import DescriptionComponent from "./miniComponents/DescriptionComponent";

const { CMS_TYPES } = constants;

const TestimonialComponent = (props) => {
  const { state, error, setError, handleChange, disabled, items, setItems } =
    props;

  const handleItems = useCallback(
    (idOrObj) => {
      setItems((prevItems) => {
        if (prevItems?.includes(idOrObj)) {
          return prevItems?.filter((itemId) => itemId !== idOrObj);
        } else {
          return [...prevItems, idOrObj];
        }
      });

      setError({});
    },
    [setError, setItems]
  );

  return (
    <>
      {/* Title start */}
      <TitleComponent
        value={state?.title}
        handleChange={handleChange}
        disabled={disabled}
        // error={error?.title}
      />
      {/* Title end */}

      {/* Description start */}
      <DescriptionComponent
        value={state?.description}
        handleChange={handleChange}
        disabled={disabled}
      />
      {/* Description end */}

      {/* Testimonial Select Start */}
      <Row align="top" className="mt20">
        <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
          <label className="label">Select Items</label>
          <sup style={{ color: "red" }}>*</sup>
        </Col>
        <Col span={8} xs={24} sm={24} lg={18} xl={18} xxl={20}>
          <Row className="error mt5">{error?.foldItems}</Row>
          <Mselectableitem
            items={items}
            foldType={CMS_TYPES.TESTIMONIAL}
            handleItems={handleItems}
          />
        </Col>
      </Row>

      {/* Testimonial Select end */}
    </>
  );
};

export default TestimonialComponent;
