import React from "react";
import { Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const BlankCard = (props) => {
  return (
    <Row
      className="--card-layout blank-card-container"
      align="middle"
      justify="center"
      onClick={props.handleModal}
    >
      <Col>
        <Row className="title mb5" align="middle">
          <PlusOutlined className="mr10" /> Add new card
        </Row>

        <Row className="subtitle" align="middle" justify="center">
          Your saved cards
        </Row>
      </Col>
    </Row>
  );
};

export default BlankCard;
