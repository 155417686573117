import Actions from './InvoiceActionTypes'

export const getAllInvoices = (params = {}) => {
    return {
        type: Actions.FETCH_ALL_INVOICES,
        payload: params,
    }
}

export const getInvoice = (id) => {
    return {
        type: Actions.FETCH_INVOICE,
        payload: id,
    }
}
