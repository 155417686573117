import { Button, Col, Input, Row } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";

import CustomModal from "../../utils/CustomModal";

import { createCMS, updateCMS } from "../duck/CMSActions";
import constants from "../../utils/Constants";

const { CMS_TYPES } = constants;

const PageMetaModal = (props) => {
  const prevProp = useRef();
  const dispatch = useDispatch();

  const {
    visible,
    selectedId,
    showModal,
    isCMSEditable,
    pageName,
    showHeadingInput = true,
    showDescriptionInput = true,
    descriptionLabel = "Description",
  } = props;

  const cms = useSelector((state) => state.cms);
  const mCms = useMemo(
    () =>
      selectedId
        ? cms.cmsMap[selectedId]
        : { loading: cms.loading, error: cms.error },
    [selectedId, cms]
  );

  const [state, setState] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    if (prevProp?.current?.loading && !mCms?.loading) {
      setState({});
      showModal?.(false);
    } else if (!prevProp?.current?.visible && visible) {
      let mState = {};

      if (mCms) {
        const page = mCms?.meta?.page;
        mState = {
          metaTitle: mCms?.name,
          metaDescription: mCms?.description,
          ...page,
        };
      }

      setState(mState);
      setError({});
    }

    return () => {
      prevProp.current = {
        loading: mCms?.loading,
        visible,
      };
    };
  }, [mCms, visible, showModal]);

  const handleChange = useCallback(
    (name) => (e) => {
      const value = e?.target?.value ?? e;
      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const hasError = useCallback(() => {
    let { metaTitle, metaDescription, heading, description } = state;
    metaTitle = metaTitle?.trim?.();
    metaDescription = metaDescription?.trim?.();

    const error = {};

    if (!metaTitle) {
      error.metaTitle = "Title cannot be blank";
    }

    if (!metaDescription) {
      error.metaDescription = "Description cannot be blank";
    }

    if (props.otherDetails) {
      if (props.showHeadingInput && !heading) {
        error.heading = "Heading cannot be blank";
      }

      if (props.showDescriptionInput && !description) {
        error.description = "Description cannot be blank";
      }
    }

    setError(error);
    return Object.keys(error).length;
  }, [state, props]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      let { metaTitle, metaDescription, heading, description } = state;
      metaTitle = metaTitle?.trim();
      metaDescription = metaDescription?.trim();
      heading = heading?.trim();
      description = description?.trim();

      const meta = {
        page: { heading, description },
        page_name: pageName,
      };

      const payload = {
        name: metaTitle,
        description: metaDescription,
        type: CMS_TYPES.PAGE_META,
        meta: JSON.stringify(meta),
      };

      if (selectedId) {
        payload._id = selectedId;
        dispatch(updateCMS(payload));
      } else {
        dispatch(createCMS(payload));
      }
    }
  }, [selectedId, state, hasError, dispatch, pageName]);

  const disabled = useMemo(
    () => !isCMSEditable || mCms?.loading,
    [isCMSEditable, mCms?.loading]
  );

  return (
    <CustomModal
      className="cms-modal"
      visible={visible}
      title={"Page Details"}
      onCancel={props.showModal}
      footer={
        <>
          <Button onClick={props.showModal}>Close</Button>

          {isCMSEditable && (
            <Button type="primary" loading={mCms?.loading} onClick={onSubmit}>
              Update
            </Button>
          )}
        </>
      }
    >
      <div className="content">
        {/* meta details satrt */}
        <>
          <Row className="section mb10" align="middle" justify="center">
            Meta Details
          </Row>

          {/* title start */}
          <Row className="mb10" align="middle">
            <Col span={6} sm={24} lg={6} xl={5} xxl={4}>
              <label className="label">Title</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} sm={24} lg={18} xl={19} xxl={20}>
              <Input
                className="custom-input"
                placeholder="Title"
                size="large"
                type="text"
                value={state.metaTitle}
                onChange={handleChange("metaTitle")}
                disabled={disabled}
              />

              <Row className="error mt5">{error.metaTitle}</Row>
            </Col>
          </Row>
          {/* title end */}

          {/* description start */}
          <Row className="mb10">
            <Col span={6} sm={24} lg={6} xl={5} xxl={4}>
              <label className="label">Description </label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} sm={24} lg={18} xl={19} xxl={20}>
              <Input.TextArea
                className="custom-input"
                placeholder="Description"
                size="large"
                type="text"
                value={state.metaDescription}
                onChange={handleChange("metaDescription")}
                autoSize={{ minRows: 3, maxRows: 4 }}
                disabled={disabled}
              />

              <Row className="error mt5">{error.metaDescription}</Row>
            </Col>
          </Row>
          {/* description end */}
        </>
        {/* meta details end */}

        {props.otherDetails && (
          <>
            <Row className="section mb10" align="middle" justify="center">
              Others Details
            </Row>

            {/* heading start */}
            {showHeadingInput && (
              <Row align="middle">
                <Col span={6} sm={24} lg={6} xl={5} xxl={4}>
                  <label className="label">Heading</label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Col span={18} sm={24} lg={18} xl={19} xxl={20}>
                  {props.headingInputType === "TextEditor" ? (
                    <ReactQuill
                      placeholder="Content"
                      theme="snow"
                      value={state.heading}
                      onChange={handleChange("heading")}
                    />
                  ) : (
                    <Input
                      className="custom-input"
                      placeholder="Heading"
                      size="large"
                      type="text"
                      value={state.heading}
                      onChange={handleChange("heading")}
                      disabled={disabled}
                    />
                  )}

                  <Row className="error mt5">{error.heading}</Row>
                </Col>
              </Row>
            )}
            {/* heading end */}

            {/* description start */}
            {showDescriptionInput && (
              <Row className="mt10">
                <Col span={6} sm={24} lg={6} xl={5} xxl={4}>
                  <label className="label">{descriptionLabel} </label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Col span={18} sm={24} lg={18} xl={19} xxl={20}>
                  {props.descriptionInputType === "TextEditor" ? (
                    <ReactQuill
                      className="custom-input-editor"
                      placeholder="Content"
                      theme="snow"
                      value={state.description}
                      onChange={handleChange("description")}
                    />
                  ) : (
                    <Input.TextArea
                      className="custom-input"
                      placeholder={descriptionLabel}
                      size="large"
                      type="text"
                      value={state.description}
                      onChange={handleChange("description")}
                      autoSize={{ minRows: 3, maxRows: 4 }}
                      disabled={disabled}
                    />
                  )}

                  <Row className="error mt5">{error.description}</Row>
                </Col>
              </Row>
            )}
            {/* description end */}
          </>
        )}
      </div>
    </CustomModal>
  );
};

export default React.memo(PageMetaModal);
