import React from "react";
import { Badge, Menu, Row } from "antd";
import { Link } from "react-router-dom";
import {
  DashboardOutlined,
  TeamOutlined,
  CreditCardOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
  SettingOutlined,
  FileDoneOutlined,
  MoneyCollectOutlined,
  SwapOutlined,
  MailOutlined,
  ContactsOutlined,
  FundOutlined,
  FormOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

import Routes from "../../config/Routes";

const AppSideMenu = (props) => {
  const allResources = useSelector((state) => state?.resources?.resources);
  const activeWorkspace = useSelector((state) => state?.workSpace?.active);
  const user = useSelector((state) => state?.user?.loggedInUser);
  const {
    userActivity,
    adminAndTeams,
    dashboard,
    resources,
    members,
    roles,
    booking,
    deskSwap,
    bookingRequest,
    invoice,
    xero,
    billing,
    plan,
    pricing,
    door,
    mail,
    contactUs,
    socialMediaLead,
    settings,
  } = user?.role_id?.permissions || {};

  return (
    <Menu
      theme="dark"
      mode="inline"
      style={{
        borderRight: 0,
        overflow: "hidden auto",
        height: "calc(100vh - 59px)",
      }}
      defaultOpenKeys={
        !props.isCollapsed
          ? props._key
            ? ["resources"]
            : props._key1
            ? ["booking"]
            : []
          : []
      }
      selectedKeys={props.selectedKeys}
      onClick={props.toggleDrawer}
    >
      {dashboard?.show && (
        <Menu.Item icon={<DashboardOutlined />} key={Routes.dashboard}>
          <Link to={Routes.dashboard}>Dashboard</Link>
        </Menu.Item>
      )}

      {(resources?.meetingRooms?.show ||
        resources?.desk?.show ||
        resources?.privateOffices?.show) && (
        <>
          <Menu.SubMenu
            key="resources"
            icon={<DatabaseOutlined />}
            title="Resources"
          >
            {resources?.meetingRooms?.show && (
              <Menu.Item key={Routes.meetingRooms}>
                <Link to={Routes.meetingRooms}>
                  <Row justify="space-between" align="middle">
                    <span>Meeting Rooms</span>
                    <Badge
                      className="resource-badge-count mr5"
                      count={
                        allResources?.[activeWorkspace]?.meetingRooms?.length ||
                        0
                      }
                    />
                  </Row>
                </Link>
              </Menu.Item>
            )}
            {resources?.privateOffices?.show && (
              <Menu.Item key={Routes.privateOffices}>
                <Link to={Routes.privateOffices}>
                  <Row justify="space-between" align="middle">
                    <span>Private Offices</span>
                    <Badge
                      className="resource-badge-count mr5"
                      count={
                        allResources?.[activeWorkspace]?.privateOffices
                          ?.length || 0
                      }
                    />
                  </Row>
                </Link>
              </Menu.Item>
            )}
            {resources?.desk?.show && (
              <Menu.Item key={Routes.desk}>
                <Link to={Routes.desk}>
                  <Row justify="space-between" align="middle">
                    <span>Desk Inventory</span>
                    <Badge
                      className="resource-badge-count mr5"
                      count={
                        allResources?.[activeWorkspace]?.desks?.length || 0
                      }
                    />
                  </Row>
                </Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        </>
      )}

      {members?.show && (
        <Menu.Item key={Routes.members} icon={<TeamOutlined />}>
          <Link to={Routes.members}>Members</Link>
        </Menu.Item>
      )}

      {adminAndTeams?.show && (
        <Menu.Item key={Routes.adminAndTeams} icon={<TeamOutlined />}>
          <Link to={Routes.adminAndTeams}>Admin & Teams</Link>
        </Menu.Item>
      )}

      {roles?.show && (
        <Menu.Item key={Routes.roles} icon={<UnorderedListOutlined />}>
          <Link to={Routes.roles}>Role Management</Link>
        </Menu.Item>
      )}

      {(booking?.booking?.show || booking?.calendar?.show) && (
        <Menu.SubMenu key="booking" icon={<CalendarOutlined />} title="Booking">
          {booking?.booking?.show && (
            <Menu.Item key={Routes.booking}>
              <Link to={Routes.booking}>Booking</Link>
            </Menu.Item>
          )}
          {booking?.calendar?.show && (
            <Menu.Item key={Routes.calendar}>
              <Link to={Routes.calendar}>Calendar</Link>
            </Menu.Item>
          )}
        </Menu.SubMenu>
      )}

      {deskSwap?.show && (
        <Menu.Item key={Routes.deskSwap} icon={<SwapOutlined />}>
          <Link to={Routes.deskSwap}>
            <Row justify="space-between" align="middle">
              <span>Desk Swap</span>
            </Row>
          </Link>
        </Menu.Item>
      )}
      {bookingRequest?.show && (
        <Menu.Item key={Routes.bookingRequest} icon={<FileSyncOutlined />}>
          <Link to={Routes.bookingRequest}>
            <Row justify="space-between" align="middle">
              <span>Booking Request</span>
            </Row>
          </Link>
        </Menu.Item>
      )}

      {invoice?.show && (
        <Menu.Item key={Routes.invoice} icon={<ContainerOutlined />}>
          <Link to={Routes.invoice}>
            <Row justify="space-between" align="middle">
              <span>Invoice</span>
            </Row>
          </Link>
        </Menu.Item>
      )}

      {xero?.show && (
        <Menu.Item key={Routes.xero} icon={<ContainerOutlined />}>
          <Link to={Routes.xero}>
            <Row justify="space-between" align="middle">
              <span>Xero</span>
            </Row>
          </Link>
        </Menu.Item>
      )}

      {billing?.show && (
        <Menu.Item key={Routes.billing} icon={<CreditCardOutlined />}>
          <Link to={Routes.billing}>
            <Row justify="space-between" align="middle">
              <span>Billing</span>
            </Row>
          </Link>
        </Menu.Item>
      )}

      {userActivity?.show && (
        <Menu.Item key={Routes.userActivity} icon={<FormOutlined />}>
          <Link to={Routes.userActivity}>
            <Row justify="space-between" align="middle">
              <span>User Activity</span>
            </Row>
          </Link>
        </Menu.Item>
      )}

      {(plan?.flexiDesk?.show ||
        plan?.dedicatedDesk?.show ||
        plan?.privateOffice?.show) && (
        <>
          <Menu.SubMenu key="plan" icon={<FileDoneOutlined />} title="Plan">
            {plan?.flexiDesk?.show && (
              <Menu.Item key={Routes.plan.flexi_desk}>
                <Link to={Routes.plan.flexi_desk}>Fixed Desk</Link>
              </Menu.Item>
            )}
            {plan?.dedicatedDesk?.show && (
              <Menu.Item key={Routes.plan.dedicated_desk}>
                <Link to={Routes.plan.dedicated_desk}>Dedicated Desk</Link>
              </Menu.Item>
            )}
            {plan?.privateOffice?.show && (
              <Menu.Item key={Routes.plan.private_office}>
                <Link to={Routes.plan.private_office}>Private Office</Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        </>
      )}

      {(pricing?.flexiDesk?.show || pricing?.dedicatedDesk?.show) && (
        <Menu.SubMenu
          key="pricing"
          icon={<MoneyCollectOutlined />}
          title="Pricing"
        >
          {pricing?.flexiDesk?.show && (
            <Menu.Item key={Routes.pricing.flexi_desk}>
              <Link to={Routes.pricing.flexi_desk}>
                <Row justify="space-between" align="middle">
                  <span>Flexi Desk</span>
                </Row>
              </Link>
            </Menu.Item>
          )}

          {pricing?.dedicatedDesk?.show && (
            <Menu.Item key={Routes.pricing.dedicated_desk}>
              <Link to={Routes.pricing.dedicated_desk}>Dedicated Desk</Link>
            </Menu.Item>
          )}
        </Menu.SubMenu>
      )}

      {door?.show && (
        <Menu.Item key={Routes.door} icon={<ContainerOutlined />}>
          <Link to={Routes.door}>
            <Row justify="space-between" align="middle">
              <span>Door</span>
            </Row>
          </Link>
        </Menu.Item>
      )}

      {mail?.show && (
        <Menu.Item key={Routes.mail} icon={<MailOutlined />}>
          <Link to={Routes.mail}>
            <Row justify="space-between" align="middle">
              <span>Mailchimp</span>
            </Row>
          </Link>
        </Menu.Item>
      )}

      {contactUs?.show && (
        <Menu.Item key={Routes.contactUs} icon={<ContactsOutlined />}>
          <Link to={Routes.contactUs}>
            <Row justify="space-between" align="middle">
              <span>Contact Us</span>
            </Row>
          </Link>
        </Menu.Item>
      )}

      {socialMediaLead?.show && (
        <Menu.Item key={Routes.socialMediaLead} icon={<FundOutlined />}>
          <Link to={Routes.socialMediaLead}>
            <Row justify="space-between" align="middle">
              <span>Social Media Leads</span>
            </Row>
          </Link>
        </Menu.Item>
      )}

      {(settings?.amenities?.show || settings?.workspace?.show) && (
        <Menu.SubMenu
          key="settings"
          icon={<SettingOutlined />}
          title="Settings"
        >
          {settings?.workspace?.show && (
            <Menu.Item key={Routes.workspace}>
              <Link to={Routes.workspace}>
                <Row justify="space-between" align="middle">
                  <span>Workspace</span>
                </Row>
              </Link>
            </Menu.Item>
          )}
          {settings?.amenities?.show && (
            <Menu.Item key={Routes.amenities}>
              <Link to={Routes.amenities}>Amenities</Link>
            </Menu.Item>
          )}
        </Menu.SubMenu>
      )}
    </Menu>
  );
};

export default React.memo(AppSideMenu);
