import Actions from "./UserActivityActionTypes";

const initialState = {
  userActivity: {},
  loading: false,
  error: null,
};

const getUserActivityReducer = (state = initialState, action) => {
  let payload = action?.payload;

  switch (action.type) {
    case Actions.FETCH_ALL_USER_ACTIVITY:
      return Object.assign({}, state, {
        error: null,
        loading: true,
      });

    case Actions.FETCH_ALL_USER_ACTIVITY_SUCCESS: {
      let workSpaceId = payload?.space_id;
      let userActivity = payload?.data;

      return Object.assign({}, state, {
        userActivity: Object.assign({}, state.userActivity, {
          [workSpaceId]: userActivity,
        }),
        loading: false,
      });
    }

    case Actions.FETCH_ALL_USER_ACTIVITY_FAILURE:
      return Object.assign({}, state, {
        error: payload,
        loading: false
      });

    default:
      return state;
  }
};

export default getUserActivityReducer;
