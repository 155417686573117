const Actions = {
  ADD_CARD: "ADD_CARD",
  ADD_CARD_SUCCESS: "ADD_CARD_SUCCESS",
  ADD_CARD_FAILURE: "ADD_CARD_FAILURE",

  FETCH_ALL_CARD: "FETCH_ALL_CARD",
  FETCH_ALL_CARD_SUCCESS: "FETCH_ALL_CARD_SUCCESS",
  FETCH_ALL_CARD_FAILURE: "FETCH_ALL_CARD_FAILURE",

  SET_DEFAULT_CARD: "SET_DEFAULT_CARD",
  SET_DEFAULT_CARD_SUCCESS: "SET_DEFAULT_CARD_SUCCESS",
  SET_DEFAULT_CARD_FAILURE: "SET_DEFAULT_CARD_FAILURE",

  DELETE_CARD: "DELETE_CARD",
  DELETE_CARD_SUCCESS: "DELETE_CARD_SUCCESS",
  DELETE_CARD_FAILURE: "DELETE_CARD_FAILURE",
};

export default Actions;
