import { Col, Row, Layout } from "antd";
import React from "react";
import "../../css/footer.scss";
import facebook from "../../images/facebook.png";
import linkedin from "../../images/linkedin.png";
import { Link } from "react-router-dom";

const { Footer } = Layout;

const Footermain = () => {
  return (
    <>
      <Footer className="nw_f_m_c">
        <div className="container">
          <Row>
            <Col span={4}>
              <div className="nw_f_l_c">
                {/* <div className="nw_f_i_l_c">
                                    <Link to="/" className="nw_logo">&nbsp;</Link>
                                    <p>London City Group provides a wide range of business transfer services for to SME's and their owners.</p>
                                </div> */}
              </div>
            </Col>

            <Col span={15}>
              <Row>
                <Col span={6}>
                  <div className="nw_f_l_c nw_f_lr_space">
                    <div className="nw_f_i_l_c">
                      <h6>Menu</h6>
                      <ul>
                        <li>
                          <Link to="">WORKSPACE</Link>
                        </li>

                        <li>
                          <Link to="">MEETINGS</Link>
                        </li>

                        <li>
                          <Link to="">PRICING</Link>
                        </li>

                        <li>
                          <Link to="">CONTACT US</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                <Col span={9}>
                  <div className="nw_f_l_c nw_f_lr_space">
                    <div className="nw_f_i_l_c">
                      <h6>Quick Links</h6>
                      <ul>
                        <li>
                          <Link to="">About US</Link>
                        </li>

                        <li>
                          <Link to="">Careers</Link>
                        </li>

                        <li>
                          <Link to="">Terms & Conditions</Link>
                        </li>

                        <li>
                          <Link to="">Privacy & Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                <Col span={9}>
                  <div className="nw_f_l_c nw_f_lr_space">
                    <div className="nw_f_i_l_c">
                      <h6>Our Spaces</h6>
                      <ul>
                        <li>
                          <Link to="">Minimilistic Look</Link>
                        </li>

                        <li>
                          <Link to="">Simple & Modern</Link>
                        </li>

                        <li>
                          <Link to="">Virtual Office</Link>
                        </li>

                        <li>
                          <Link to="">Coworking</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col span={5}>
              <div className="nw_f_l_c">
                <div className="nw_f_i_l_c">
                  <h6>Contact Us</h6>
                  <ul className="nw_f_loc">
                    <li>
                      <span>
                        London City Group Ltd 46 New Broad Street London, EC2M
                        1JH
                      </span>
                    </li>
                    <li>
                      <Link to="tel:07972634187">07972634187</Link>
                    </li>
                    <li>
                      <Link to="mailto:Londoncitygroup@gmail.com">
                        Londoncitygroup@gmail.com
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="nw_c_s_c">
            <Col>
              <div className="nw_c_c_b">
                <p>
                  © 2021. The content on this website is owned by us and our
                  licensors. Do not copy any content (including images) without
                  our consent.
                </p>
              </div>
            </Col>

            <Col>
              <div className="nw_c_s_b">
                <ul>
                  <li>
                    <Link to="">
                      <img src={facebook} alt="facebook" />
                    </Link>
                  </li>

                  <li>
                    <Link to="">
                      <img src={linkedin} alt="linkedin" />
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Footer>
    </>
  );
};

export default Footermain;
