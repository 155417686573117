import React from "react";
import {
  Col,
  Row,
  Input,
  Button,
  Select,
  DatePicker,
  TimePicker,
  Space,
} from "antd";
import { useSelector } from "react-redux";
import map from "../../images/map.svg";
import officedesk from "../../images/office_desk.svg";
import usericon from "../../images/user_1.svg";
import calendericon from "../../images/calendar.svg";
import { getUserWorkspace } from "../../../utils/CommonFunctions";

import {
  disabledDate,
  disabledHours,
  disabledMinutes,
  resourceTypes,
} from "../../../utils/CommonFunctions";

const SearchResourceHeader = ({
  workspace,
  resource_type,
  capacity,
  from_to_date,
  onSearch = () => { },
  filter,
  handleChange = (value) => value,
  showOnlyResources = ["flexi_desk", "dedicated_desk", "private_office"],
  ...props
}) => {
  const loggedInUser = useSelector((state) => state?.user?.loggedInUser);
  let { workSpaceList, workSpaceMap } = useSelector((state) => state.workSpace);
  const selectedWorkspace = workSpaceMap?.[workspace];

  workSpaceList = Object.keys(workSpaceMap)?.filter(
    (id) => workSpaceMap?.[id]?.active
  );

  const workSpaces = loggedInUser ? getUserWorkspace() : workSpaceList;
  const options = workSpaces?.map((s_id) => ({
    _id: s_id,
    value: workSpaceMap[s_id]?.name,
  }));

  const disabled = !filter;
  return (
    <>
      <Col
        className="searchContainer"
        style={{ position: "sticky", top: 0, zIndex: 1 }}
      >
        <Col className="container">
          <Row className="rowContainer">
            <Col xs={24} md={10} lg={10} xl={7} className="spacerLR">
              <div className="locationContainer">
                <i>
                  <img src={map} alt="map icon" />
                </i>
                <span className="mainLocation">London</span>

                <Select
                  style={{
                    width: "100%",
                    marginTop: "-5px",
                    fontWeight: "700",
                  }}
                  placeholder={"Select Location"}
                  value={workspace}
                  onChange={handleChange("workspace")}
                >
                  {options?.map((option) => (
                    <Select.Option key={option?._id} value={option?._id}>
                      {option?.value}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>

            {(!disabled || resource_type) && (
              <Col xs={24} md={10} lg={10} xl={4} className="spacerLR arrowset">
                <div className="locationContainer">
                  <i>
                    <img src={officedesk} alt="office icon" />
                  </i>
                  <span className="mainLocation">Workspace Type</span>
                  <Select
                    style={{
                      width: "100%",
                      marginTop: "-5px",
                      fontWeight: "700",
                    }}
                    value={resource_type}
                    onChange={handleChange("resource_type")}
                    disabled={disabled}
                  >
                    {showOnlyResources.map(
                      (r_type) =>
                        resourceTypes[r_type] && (
                          <Select.Option key={r_type} value={r_type}>
                            {resourceTypes[r_type]?.name}
                          </Select.Option>
                        )
                    )}
                  </Select>
                </div>
              </Col>
            )}

            {(!disabled ||
              from_to_date?.length ||
              (props?.from_date && props?.from_time && props?.duration)) && (
                <Col xs={24} md={10} lg={10} xl={6} className="spacerLR">
                  <div className="locationContainer">
                    <i>
                      <img src={calendericon} alt="calender icon" />
                    </i>

                    <Space direction="vertical" size={12}>
                      {resource_type === "meeting_room" ? (
                        <Row>
                          <Col xs={10} md={8}>
                            <span
                              className="mainLocation"
                              style={{ padding: "0px 11px" }}
                            >
                              MOVE-IN Date
                            </span>
                            <DatePicker
                              className="datarangecontainer"
                              style={{ paddingTop: 0, paddingBottom: 0 }}
                              value={props?.from_date}
                              onChange={handleChange("from_date")}
                              disabledDate={disabledDate({
                                activeDays: selectedWorkspace?.no_of_days,
                              })}
                              disabled={disabled}
                              bordered={false}
                              inputReadOnly={true}
                            />
                          </Col>

                          <Col xs={7} md={8}>
                            <span
                              className="mainLocation"
                              style={{ padding: "0px 11px" }}
                            >
                              Time
                            </span>
                            <TimePicker
                              className="datarangecontainer"
                              style={{ paddingTop: 0, paddingBottom: 0 }}
                              value={props?.from_time}
                              onChange={handleChange("from_time")}
                              format="HH:mm"
                              minuteStep={30}
                              disabledHours={() =>
                                disabledHours(selectedWorkspace, props?.from_date)
                              }
                              disabledMinutes={() =>
                                disabledMinutes(props?.from_date)
                              }
                              disabled={disabled}
                              bordered={false}
                              hideDisabledOptions={true}
                            />
                          </Col>

                          <Col xs={7} md={7}>
                            <span className="mainLocation" style={{ padding: 0 }}>
                              Duration
                            </span>
                            <Input
                              className="datarangecontainer mobremovebl"
                              style={{ paddingTop: 0, paddingBottom: 0 }}
                              type="number"
                              placeholder="In hours"
                              min={1}
                              step={0.5}
                              value={props?.duration}
                              onChange={handleChange("duration")}
                              bordered={false}
                              disabled={disabled}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <Col>
                          <span className="mainLocation">MOVE-IN</span>
                          <DatePicker.RangePicker
                            className="datarangecontainer"
                            style={{
                              width: "100%",
                              marginTop: "-5px",
                              fontWeight: "700",
                              paddingLeft: "0px",
                              paddingRight: "0px",
                              border: "none",
                            }}
                            value={from_to_date}
                            onChange={handleChange("from_to_date")}
                            disabledDate={disabledDate({
                              activeDays: selectedWorkspace?.no_of_days,
                            })}
                            disabled={disabled}
                            bordered={false}
                            inputReadOnly={true}
                          />
                        </Col>
                      )}
                    </Space>
                  </div>
                </Col>
              )}

            {(!disabled || capacity) && (
              <Col xs={24} md={4} lg={4} xl={3} className="spacerLR">
                <div className="locationContainer">
                  <i>
                    <img src={usericon} alt="user icon" />
                  </i>
                  <span className="mainLocation">Capacity</span>

                  <Input
                    placeholder="Capacity"
                    className="inputstyle"
                    value={capacity}
                    onChange={handleChange("capacity")}
                    disabled={disabled}
                  />
                </div>
              </Col>
            )}

            {!disabled && (<Col xs={24} md={10} lg={10} xl={4} className="spacerLR btnContainer" > <Button type="primary" block onClick={onSearch}> Modify Search </Button> </Col>)}
          </Row>
        </Col>
      </Col>
    </>
  );
};

export default SearchResourceHeader;
