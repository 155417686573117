import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  Row,
  Col,
  Card,
  Divider,
  Button,
  Select,
  Input,
  Modal,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import PaymentModal from "./components/PaymentModal";
import {
  getAllAvailableResources,
  bookResource,
  getBookingResources,
} from "../booking/duck/BookingActions";
import {
  checkPermission,
  formatDateTime,
  resourceTypes,
  showNotification,
  validateEmail,
} from "../utils/CommonFunctions";
import Routes from "../config/Routes";
import Spinner from "../spinner/Spinner";
import ResourceDetails from "./components/ResourceDetails";
import BookingDetails from "./components/BookingDetails";

import "./PayNow.scss";

const { Paragraph } = Typography;
const PayNow = () => {
  const { bookingId } = useParams();

  const loggedInUser = useSelector((state) => state.user?.loggedInUser);
  const workSpaceMap = useSelector((state) => state?.workSpace?.workSpaceMap);

  const [selectedResource, setResource] = useState({});
  const [error, setError] = useState({});
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    memberEmails: [],
    member: [],
  });

  const [prevPath, setPrevPath] = useState("");
  const booking = useSelector((state) => state.booking);
  const { bookingMap, availableResources } = useMemo(
    () => ({
      bookingMap: booking?.bookingMap,
      availableResources: booking?.availableResources,
    }),
    [booking?.bookingMap, booking?.availableResources]
  );

  const mBooking = useMemo(
    () => bookingMap?.[bookingId],
    [bookingMap, bookingId]
  );
  const resourceType = useMemo(
    () => resourceTypes[mBooking?.type],
    [mBooking?.type]
  );

  const mAvailableResource = useMemo(
    () => availableResources?.[selectedResource?.filter],
    [availableResources, selectedResource?.filter]
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const prevProps = useRef();

  const bookResourcePreload = useCallback(() => {
    const { filter, fetchingAvailableResource, newBooking } =
      prevProps?.current || {};

    // get data booking data from local store
    if (!filter) {
      let myResource = localStorage.getItem("bookResource");
      myResource = myResource && JSON.parse(myResource);

      if (
        !myResource ||
        !(
          myResource?.exp_timestamp &&
          moment().isBefore(myResource?.exp_timestamp)
        )
      ) {
        localStorage.removeItem("bookResource");
        history.replace(Routes.booking);
      }

      setResource(myResource || {});

      if (myResource) {
        let {
          resource_type,
          from_to_date,
          from_date,
          from_time,
          duration,
          space_id,
          capacity,
        } = myResource;

        //set previous path in case of back button
        if (resource_type && capacity > 0) {
          let search = `?spaceId=${space_id}`;
          search = `${search}&resource_type=${resource_type}`;
          search = `${search}&capacity=${capacity}`;

          if (
            resource_type === "meeting_room" &&
            from_date &&
            from_time &&
            duration
          ) {
            from_date = formatDateTime(from_date, "dt");
            from_time = formatDateTime(from_time, "dt");
            search = `${search}&from_date=${from_date}`;
            search = `${search}&from_time=${from_time}`;
            search = `${search}&duration=${duration}`;
          } else if (
            resource_type !== "meeting_room" &&
            from_to_date?.length === 2
          ) {
            search = `${search}&from_date=${formatDateTime(
              from_to_date[0],
              "d"
            )}`;
            search = `${search}&to_date=${formatDateTime(
              from_to_date[1],
              "d"
            )}`;
          }
          setPrevPath(search);
        }

        const activeSpace = workSpaceMap?.[space_id];
        let startDateTime;
        let endDateTime;

        if (resource_type === "meeting_room") {
          if (!from_date || !from_time || !duration) {
            localStorage.removeItem("bookResource");
            history.replace(Routes.dashboard);
            return;
          }

          startDateTime = moment(
            `${formatDateTime(from_date, "d")} ${formatDateTime(
              from_time,
              "t"
            )}`
          );
          endDateTime = moment(startDateTime).add(duration, "hours");
        } else {
          if (!from_to_date && from_to_date?.length <= 0) {
            localStorage.removeItem("bookResource");
            history.replace(Routes.dashboard);
            return;
          }
          const office_hours_start = activeSpace?.office_hours?.[0];
          const office_hours_end = activeSpace?.office_hours?.[1];

          startDateTime = moment(from_to_date[0]).set({
            hour: moment(office_hours_start).format("HH"),
            minute: moment(office_hours_start).format("mm"),
          });
          endDateTime = moment(from_to_date[1]).set({
            hour: moment(office_hours_end).format("HH"),
            minute: moment(office_hours_end).format("mm"),
          });
        }

        const filter = myResource?.filter;
        const availableResParams = JSON.parse(filter);

        setResource((state) => ({
          ...state,
          filter,
          ...(myResource || {}),
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        }));

        if (filter !== selectedResource?.filter) {
          dispatch(getAllAvailableResources(availableResParams));
        }
      }
    }
    // check if resource or resource plan is still available now
    // then booking done
    else if (fetchingAvailableResource && !mAvailableResource?.loading) {
      let {
        space_id,
        resource_type,
        resource_id,
        from_to_date,
        startDateTime,
        endDateTime,
        capacity,
        plan_id,
      } = selectedResource;
      const resource = mAvailableResource?.list?.find(
        (resource) => resource?._id === resource_id
      );
      const plan = resource?.plan?.find?.((p) => p?._id === plan_id);

      const isResourceAvailable = resource && (plan_id ? plan : true);
      if (isResourceAvailable) {
        let bookingItemIds =
          resource?.items?.length > 0 ? resource.items.slice(0, capacity) : [];
        bookingItemIds = bookingItemIds.map((i) => i?._id);

        const bookResourcePrice =
          plan?.dynamic_price || resource?.dynamic_price || {};

        if (plan) {
          let planTenure = (plan?.tenure || 1) - 1;
          endDateTime =
            Array.isArray(from_to_date) &&
            from_to_date?.length === 2 &&
            moment(from_to_date[1]).startOf("days").add(planTenure, "days");
        }

        const bookResourceParams = {
          from: moment(startDateTime)?.toDate(),
          to: moment(endDateTime)?.toDate(),
          resource_id,
          dynamic_price: bookResourcePrice,
          assignedUser: loggedInUser?.email,
          members: [loggedInUser?.email],
          space_id,
          ids: bookingItemIds,
          type: resource_type,
          capacity,
          isShowNotification: false,
        };

        if (plan_id) {
          bookResourceParams.plan_id = plan_id;
        }

        dispatch(bookResource(bookResourceParams));
      } else {
        showNotification("error", "This Resource is already booked");
        history.replace(Routes.booking);
        localStorage.removeItem("bookResource");
      }
    }
    // check if booking is done
    // then go to pay now
    else if (newBooking && !booking?.loading) {
      if (!booking?.error) {
        const boardParams = {};
        if (showAllBooking) {
          boardParams.space_id = selectedResource?.space_id;
        }
        const bookingBoardKey = JSON.stringify(boardParams);
        const mBookingList = booking?.bookings?.[bookingBoardKey]?.list || [];
        const newBookingId = mBookingList[0];

        history.replace(`${Routes.payNow}/${newBookingId}`);
      } else {
        history.replace(Routes.booking);
      }
      localStorage.removeItem("bookResource");
    }
  }, [
    loggedInUser,
    selectedResource,
    mAvailableResource,
    booking?.bookings,
    booking?.loading,
    booking?.error,
  ]);

  const bookingPaymentPreload = useCallback(() => {
    const { processingPayment } = prevProps?.current || {};
    if (mBooking) {
      if (!state?.memberEmails?.length) {
        const memberEmails = mBooking?.members?.map((m) => m?.email) || [];

        setState((preState) => ({
          ...preState,
          memberEmails: memberEmails,
          member: memberEmails,
        }));
      } else if (processingPayment && !mBooking?.processingPayment) {
        if (!mBooking?.error) {
          history.replace(Routes.booking);
        }
      }
    } else {
      dispatch(getBookingResources(bookingId));
    }
  }, [mBooking, bookingId]);

  const showAllBooking = useMemo(
    () => checkPermission({ initial: "showAllBooking" }),
    [loggedInUser]
  );

  useEffect(() => {
    if (loggedInUser && bookingId) {
      if (bookingId === "new_booking") {
        bookResourcePreload();
      } else {
        bookingPaymentPreload();
      }
    }

    return () => {
      let current = {};

      if (bookingId === "new_booking") {
        current = {
          filter: selectedResource?.filter,
          fetchingAvailableResource: mAvailableResource?.loading,
          newBooking: booking?.loading,
          fetchBooking: mBooking?.loading,
        };
      } else {
        current = {
          processingPayment: mBooking?.processingPayment,
        };
      }

      prevProps.current = current;
    };
  }, [
    bookingId,
    loggedInUser,
    selectedResource?.filter,
    mAvailableResource?.loading,
    booking?.loading,
    mBooking?.loading,
    mBooking?.processingPayment,
  ]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event?.target?.checked ?? event;

      setState((preState) => ({
        ...preState,
        [name]: value,
      }));
      setError({});
    },
    []
  );

  // confirm modal
  const confirmChange = () => {
    Modal.confirm({
      title: "Confirm change",
      width: 500,
      icon: <ExclamationCircleOutlined />,
      content:
        "Your booking is still pending. Do you really want to return to the previous screen?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => history.replace(`${Routes.search}${prevPath}`),
    });
  };

  const onAddEmail = useCallback(() => {
    let { email, memberEmails = [] } = state;
    email = email?.trim?.();

    let error = {};
    if (!email) {
      error.email = "Please enter member email";
    } else if (!validateEmail(email)) {
      error.email = "Please enter valid email";
    } else if (memberEmails.includes(email)) {
      error.email = "Email already exists";
    }

    if (Object.keys(error).length >= 1) {
      setError(error);
    } else {
      setState((preState) => ({
        ...preState,
        memberEmails: [...new Set([...(preState?.memberEmails || []), email])],
        member: [...new Set([...(preState?.member || []), email])],
        email: "",
      }));
    }
  }, [state]);

  const _dropdownRender = useCallback(
    (menu) => (
      <Col className="fw" span={24}>
        {menu}
        <Divider style={{ margin: "4px 0" }} />

        <Col className="p5" span={24}>
          <Col>
            {state?.member?.length === Number(mBooking?.capacity) && (
              <Row className="error mb5">
                You already reached your selected capacity of{" "}
                {Number(mBooking?.capacity)} member.
              </Row>
            )}
          </Col>

          <Row className="fw">
            <Col className="pr5" span={20} xs={24} sm={20} md={19} lg={21}>
              <Input
                className="custom-input"
                placeholder="Enter email"
                value={state?.email}
                onChange={handleChange("email")}
              />

              <Row className="error mt5">{error?.email}</Row>
            </Col>

            <Col span={4} xs={24} sm={4} md={5} lg={3}>
              <Button
                type="primary-outline"
                onClick={onAddEmail}
                block
                disabled={state?.member?.length === Number(mBooking?.capacity)}
              >
                + <UserOutlined />
              </Button>
            </Col>
          </Row>
        </Col>
      </Col>
    ),
    [
      state?.member?.length,
      state?.email,
      mBooking?.capacity,
      handleChange,
      error?.email,
      onAddEmail,
    ]
  );

  const onMemberListVisibleChange = useCallback((isOpen) => {
    if (!isOpen) {
      setState((preState) => ({
        ...preState,
        email: "",
      }));

      setError((preError) => ({
        ...preError,
        email: "",
      }));
    }
  }, []);

  if (!mBooking) {
    return <Spinner />;
  }

  const { capacity } = mBooking;

  return (
    <Col className="pay-now-container">
      <Row justify="space-between" align="middle" className="mb20">
        <span className="page_heading">Your cart</span>

        <Button onClick={() => confirmChange()}>
          <ArrowLeftOutlined />
          Back
        </Button>
      </Row>

      <Row className="mb10">
        <Paragraph className="fw">
          <ExclamationCircleOutlined className="mr5" />

          <span>
            You have only 30 minutes to finish the booking. Failure to do so may
            result in an unsuccessful booking for the given inventory, and you
            may need to start a new.
          </span>
        </Paragraph>
      </Row>

      <Row>
        <Col
          className="--left-container pr20"
          xs={24}
          sm={24}
          md={15}
          lg={16}
          xl={16}
        >
          <Col className="mb10">
            <ResourceDetails booking={mBooking} />
          </Col>

          <Col className="mb10">
            <Card className="members-container" bordered={true}>
              <Row className="members-content">
                <Col span={4} xs={24} md={4}>
                  <label className="label">Member</label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Col span={20} xs={24} md={20}>
                  <Select
                    className="custom-select fw"
                    placeholder="Select member"
                    value={state.member}
                    size="large"
                    allowClear
                    onChange={handleChange("member")}
                    mode={"multiple"}
                    dropdownRender={_dropdownRender}
                    onDropdownVisibleChange={onMemberListVisibleChange}
                    maxTagCount="responsive"
                  >
                    {state.memberEmails.map(
                      (email) =>
                        email && (
                          <Select.Option
                            disabled={
                              !state.member.includes(email) &&
                              state.member.length === Number(capacity)
                            }
                            key={email}
                            value={email}
                          >
                            {email}
                          </Select.Option>
                        )
                    )}
                  </Select>
                  <Row className="error mt5">{error?.member}</Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Col>

        <Col className="--rigth-container" xs={24} sm={24} md={9} lg={8} xl={8}>
          <BookingDetails
            booking={mBooking}
            onPayNow={() => setShow(true)}
            disabled={(state?.member?.length || 0) <= 0}
          />

          {show && (
            <PaymentModal
              visible={show}
              handleModal={() => setShow(false)}
              description={
                mBooking?.plan
                  ? mBooking?.plan?.name
                  : `Book ${resourceType?.name}`
              }
              bookingId={bookingId}
              price={mBooking?.price?.total}
              members={state?.member}
              loading={mBooking?.processingPayment}
            />
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default PayNow;
