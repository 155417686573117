import { put, takeLatest, call } from "redux-saga/effects";
import { showNotification } from "../../utils/CommonFunctions";
import ACTIONS from "./MemberActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const getAllMembersRequest = (params) => {
  return axios({
    method: "GET",
    url: `${AppUrl.WORKSPACE}/${params?.space_id}/members`,
    params: params,
  });
};

const addMemberRequest = (data) => {
  return axios({
    method: "POST",
    url: `${AppUrl.USERS_URL}/add-member`,
    data,
  });
};

const updateMemberRequest = (data) => {
  return axios({
    method: "POST",
    url: `${AppUrl.USERS_URL}/update-member`,
    data,
  });
};

function* getAllMembers(action) {
  try {
    let response = yield call(getAllMembersRequest, action.payload);
    let _data = response?.data;
    delete action.payload.offset;
    delete action.payload.limit;

    _data.filter = JSON.stringify(action.payload);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.FETCH_ALL_MEMBERS_FAILURE, payload: _data });
    } else {
      yield put({ type: ACTIONS.FETCH_ALL_MEMBERS_SUCCESS, payload: _data });
    }
  } catch (error) {
    console.log("fetch workspace error", error);
  }
}

function* addMember(action) {
  try {
    let response = yield call(addMemberRequest, action.payload);
    let _data = response?.data;

    let params = {
      space_id: action?.payload?.space_id,
    };
    _data.filter = JSON.stringify(params);

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.ADD_MEMBER_FAILURE, payload: _data });
    } else {
      yield put({ type: ACTIONS.ADD_MEMBER_SUCCESS, payload: _data });
      showNotification("success", "New member added successfully!");
    }
  } catch (error) {
    console.log("add member room error", error);
  }
}

function* updateMember(action) {
  try {
    let response = yield call(updateMemberRequest, action.payload);
    let _data = response?.data;
    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.UPDATE_MEMBER_FAILURE,
        payload: "Internal server Error",
      });
    } else {
      yield put({
        type: ACTIONS.UPDATE_MEMBER_SUCCESS,
        payload: _data,
      });
      showNotification("success", "User Updated!");
    }
  } catch (error) {
    console.log("update member error", error);
  }
}

// ACTION WATCHER
export default function* membersSaga() {
  yield takeLatest(ACTIONS.FETCH_ALL_MEMBERS, getAllMembers);
  yield takeLatest(ACTIONS.ADD_MEMBER, addMember);
  yield takeLatest(ACTIONS.UPDATE_MEMBER, updateMember);
}
