const Actions = {
    FETCH_ALL_AMENITIES: 'FETCH_ALL_AMENITIES',
    FETCH_ALL_AMENITIES_SUCCESS: 'FETCH_ALL_AMENITIES_SUCCESS',
    FETCH_ALL_AMENITIES_FAILURE: 'FETCH_ALL_AMENITIES_FAILURE',

    CREATE_AMENITY: 'CREATE_AMENITY',
    CREATE_AMENITY_SUCCESS: 'CREATE_AMENITY_SUCCESS',
    CREATE_AMENITY_FAILURE: 'CREATE_AMENITY_FAILURE',

    UPDATE_AMENITY: 'UPDATE_AMENITY',
    UPDATE_AMENITY_SUCCESS: 'UPDATE_AMENITY_SUCCESS',
    UPDATE_AMENITY_FAILURE: 'UPDATE_AMENITY_FAILURE',

    DELETE_AMENITY: 'DELETE_AMENITY',
    DELETE_AMENITY_SUCCESS: 'DELETE_AMENITY_SUCCESS',
    DELETE_AMENITY_FAILURE: 'DELETE_AMENITY_FAILURE',
}

export default Actions