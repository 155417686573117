const Routes = {
  dashboard: "/app/dashboard",
  adminAndTeams: "/app/adminAndTeams",
  meetingRooms: "/app/meetingRooms",
  desk: "/app/desk",
  deskList: "/app/desk/:id",
  privateOffices: "/app/privateOffices",
  members: "/app/members",
  roles: "/app/roles",
  role: "/app/role",
  reports: "/app/reports",
  booking: "/app/booking",
  search: "/app/search",
  deskSwap: "/app/deskSwap",
  bookingRequest: "/app/bookingRequest",
  calendar: "/app/calendar",
  invoice: "/app/invoice",
  xero: "/app/xero",
  invoicePreview: "/app/invoicePreview",
  door: "/app/door",
  workspace: "/app/workspace",
  amenities: "/app/amenities",
  billing: "/app/billing",
  mail: "/app/mail",
  contactUs: "/app/contact-us",
  socialMediaLead: "/app/social-media-lead",
  userActivity: "/app/user_activity",
  plan: {
    plan: "/app/plan/:resource_type",
    flexi_desk: "/app/plan/flexi_desk",
    dedicated_desk: "/app/plan/dedicated_desk",
    private_office: "/app/plan/private_office",
  },
  pricing: {
    pricing: "/app/pricing/:resource_type",
    flexi_desk: "/app/pricing/flexi_desk",
    dedicated_desk: "/app/pricing/dedicated_desk",
  },
  payNow: "/app/payNow",
  preBooking: "/app/preBooking",
  // App CMS Start
  slider: "/app/cms/slider",
  editSlider: "/app/cms/editSlider",
  homePage: "/app/cms/homePage",
  availabilityPage: "/app/cms/availabilityPage",
  foldableOfferPage: "/app/cms/offerPage",
  meetingRoomPage: "/app/cms/meetingRoomPage",
  privateOfficePage: "/app/cms/privateOfficePage",
  dedicatedDeskPage: "/app/cms/dedicatedDeskPage",
  flexiDeskPage: "/app/cms/flexiDeskPage",
  editFoldPage: "/app/cms/editFoldPage",
  testimonial: "/app/cms/testimonial",
  aboutUs: "/app/cms/aboutUs",
  imageGallery: "/app/cms/imageGallery",
  ourSpace: "/app/cms/ourSpace",
  knowMore: "/app/cms/knowMore",
  otherPage: "/app/cms/otherPage",
  faqPage: "/app/cms/faq",
  offerPage: "/app/cms/offer",
  officeList: "/app/cms/officeList",
  editFaq: "/app/cms/faq/category",
  // App CMS ENd
  profile: "/app/profile",
  login: "/login",
  signup: "/signup",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  accountActivate: "/account-activate",
  verifyOtp: "/verify-otp",
  home: "/",
  app: "/app",
  space: "/space",
  homeSearch: "/search",
  paymentDone: "/payment-done/:session_id",
};

export default Routes;
