import React, { useEffect, useState } from "react";
import { Result, Row } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { SettingOutlined } from "@ant-design/icons";
import axios from "axios";

import AppUrl from "../config/AppUrl";
import { showNotification } from "../utils/CommonFunctions";
import Routes from "../config/Routes";

const PaymentDone = () => {
  const params = useParams();
  const history = useHistory();
  const [state, setState] = useState({});

  useEffect(() => {
    setState({ loading: true });

    axios({
      method: "GET",
      url: AppUrl.STRIPE_PAYMENT_DONE,
      params: { session_id: params?.session_id },
    })
      .then((resource) => {
        let _data = resource?.data;

        if (_data?.meta?.success !== true || _data?.meta?.status !== 201) {
          showNotification("error", _data?.meta?.message);
          setState({ error: _data });
        } else {
          showNotification("success", _data?.meta?.message);
          setState({ data: _data });
          setTimeout(() => {
            history.push(Routes.booking);
          }, 1000);
        }
      })
      .catch((error) => {
        showNotification(
          "success",
          "Something went wrong while processing payment"
        );
        setState({ error });
      });
  }, []);

  return (
    <Row style={{ height: "100vh" }} justify="center" align="middle">
      {state?.loading && (
        <Result
          icon={<SettingOutlined spin />}
          title="Please wait, processing payment..."
        />
      )}

      {state?.data && (
        <Result status="success" title="Successfully payment done!" />
      )}

      {state?.error && (
        <Result status="500" title="Sorry, something went wrong." />
      )}
    </Row>
  );
};

export default PaymentDone;
