import { Button, Row } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import AddMeetingRoom from "./components/AddMeetingRoom";
import Spinner from "../spinner/Spinner";
import ResourceCard from "./components/ResourceCard";

import resourceSvg from "../assets/svg/resource.svg";
import { checkPermission } from "../utils/CommonFunctions";

const MeetingRooms = () => {
  const [visible, showModal] = useState(false);
  const [selectedMeetingRoom, selectMeetingRoom] = useState(null);

  const editable = useMemo(
    () => checkPermission({ row: "resources", subRow: "meetingRooms" }),
    []
  );
  const activeWorkSpace = useSelector((state) => state?.workSpace?.active);
  const resources = useSelector((state) => state.resources?.resources);
  const meetingRooms = useMemo(
    () => resources?.[activeWorkSpace]?.meetingRooms || [],
    [resources, activeWorkSpace]
  );

  const meetingRoomSpin = !meetingRooms?.length && resources?.loading;

  const handleModal = useCallback((show = false, resourceId) => {
    show = typeof show === "boolean" && show;
    showModal(show);
    selectMeetingRoom(resourceId);
  }, []);

  return meetingRoomSpin ? (
    <Spinner />
  ) : (
    <div className="resourceMain">
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Meeting Rooms</span>
        {editable && (
          <Button
            type="primary-outline"
            onClick={() => handleModal(true, null)}
          >
            + Add Meeting Room
          </Button>
        )}
      </Row>

      {meetingRooms?.length === 0 && (
        <Row justify="center" align="middle" className="mt20 pt20">
          <img src={resourceSvg} alt="No resource added!" />
        </Row>
      )}

      <Row>
        {meetingRooms?.map?.((id) => (
          <ResourceCard
            key={id}
            id={id}
            type={"meeting_room"}
            onEdit={handleModal}
          />
        ))}
      </Row>

      <AddMeetingRoom
        visible={visible}
        handleModal={handleModal}
        selectedMeetingRoom={selectedMeetingRoom}
        editable={editable}
      />
    </div>
  );
};

export default MeetingRooms;
