import React, { useMemo } from "react";
import { Card, Tooltip, Typography } from "antd";
import { useSelector } from "react-redux";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { resourceTypes } from "../../utils/CommonFunctions";

const { Paragraph } = Typography;

const KnowMoreCard = (props) => {
  const { id, isCMSEditable } = props;

  const mCms = useSelector(({ cms }) => cms.cmsMap[id]);
  const ExtraIcon = useMemo(
    () => (isCMSEditable ? EditOutlined : EyeOutlined),
    [isCMSEditable]
  );

  const { name, content } = useMemo(() => {
    const meta = mCms?.meta;

    const name = resourceTypes[meta?.resource_type]?.name || mCms?.name;
    const content = mCms?.description?.replace(/(<([^>]+)>)/gi, "");

    return { name, content };
  }, [mCms]);

  if (!mCms) {
    return <></>;
  }

  return (
    <Card
      className="cms-card"
      title={name}
      bordered={false}
      hoverable
      extra={
        <Tooltip title={isCMSEditable ? "Edit" : "More Details"}>
          <ExtraIcon
            style={{ fontSize: 12 }}
            onClick={() => props.showModal(true, id)}
          />
        </Tooltip>
      }
    >
      <Paragraph ellipsis={{ rows: 5 }}>{content}</Paragraph>
    </Card>
  );
};

export default React.memo(KnowMoreCard);
