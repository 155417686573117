import { put, takeLatest, call } from "redux-saga/effects";
import { showNotification } from "../../utils/CommonFunctions";
import ACTIONS from "./UserActivityActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const getAllUserActivityRequest = (params) => {
  return axios({
    method: "GET",
    url: `${AppUrl.WORKSPACE}/${params?.space_id}/getkisi`,
    params: params,
  });
};

function* getAllUserActivity(action) {
  try {
    let resources = yield call(getAllUserActivityRequest, action.payload);
    let _data = resources?.data;
    _data.space_id = action.payload?.space_id;

    if (_data?.meta?.success !== true || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.FETCH_ALL_USER_ACTIVITY_FAILURE,
        payload: _data,
      });
    } else {
      yield put({
        type: ACTIONS.FETCH_ALL_USER_ACTIVITY_SUCCESS,
        payload: _data,
      });
    }
  } catch (error) {
    console.log("get all user activity error", error);
  }
}

// ACTION WATCHER
export default function* doorSaga() {
  yield takeLatest(ACTIONS.FETCH_ALL_USER_ACTIVITY, getAllUserActivity);
}
