import { useState, useCallback } from "react";
import { Button, Input, Row, Col, Typography } from "antd";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import {
  isUserAuthenticated,
  isValidPassword,
  showNotification,
} from "../utils/CommonFunctions";
import AppUrl from "../config/AppUrl";
import axios from "axios";
import "../assets/css/login.scss";
import resetSvg from "../assets/svg/reset.svg";
import { ArrowLeftOutlined } from "@ant-design/icons";
// import logo from "../assets/svg/logo.svg";
import Routes from "../config/Routes";

import loginSvg from "../assets/svg/logo_full.svg";
import AuthBgContainer from "./components/AuthBgContainer";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [err, handleError] = useState({
    password: null,
    confirmPassword: null,
  });
  const [loading, setLoader] = useState(false);

  const history = useHistory();

  const resetPassword = useCallback(() => {
    if (isValidPassword(password) && confirmPassword === password) {
      resetPasswordRequest({ password });
    } else {
      let passErr = null;
      let confirmpassErr = null;
      if (password?.trim() === "") {
        passErr = "Password cannot be blank";
      } else if (!isValidPassword(password)) {
        passErr = "Password length should be of minimum 4 characters";
      }
      if (confirmPassword?.trim() === "") {
        confirmpassErr = "Confirm password cannot be blank";
      } else {
        confirmpassErr = "Password did not match";
      }

      handleError({ password: passErr, confirmPassword: confirmpassErr });
    }
  }, [password, confirmPassword]);

  const token = useLocation()?.search?.split("=")?.[1];

  const resetPasswordRequest = async () => {
    setLoader(true);
    try {
      const userRes = await axios.post(AppUrl.RESET_PASSWORD, {
        password,
        link: token,
      });
      let userData = userRes.data;
      if (userData?.meta?.success === false || userData?.meta?.status !== 200) {
        showNotification("error", userData?.meta?.message);
        setLoader(false);
      } else {
        showNotification("success", "Successfully set new password!");
        localStorage.setItem("accessToken", userData?.data?.token);
        history.push(Routes.dashboard);
      }
    } catch (error) {
      console.log("error", error);
      setLoader(false);
    }
  };

  if (isUserAuthenticated()) {
    return <Redirect to={Routes.dashboard} />;
  }

  return (
    <AuthBgContainer
      title={"Create New Password"}
      subtitle={
        "Your new password must be different from your previous used passwords"
      }
    >
      <Col className="mb20">
        <Col className="label mb5">New Password</Col>

        <Input
          placeholder="New Password"
          size="large"
          type="password"
          allowClear
          onChange={(e) => {
            setPassword(e.target.value);
            handleError({ password: null });
          }}
          value={password}
          onPressEnter={resetPassword}
          autoFocus
        />
        <Col className="error mt5">{err.password}</Col>
      </Col>

      <Col className="mb20">
        <Col className="label mb5">Confirm Password</Col>

        <Input
          placeholder="Confirm Password"
          size="large"
          type="password"
          allowClear
          onChange={(e) => {
            setconfirmPassword(e.target.value);
            handleError({ password: null });
          }}
          value={confirmPassword}
          onPressEnter={resetPassword}
        />
        <Col className="error mt5">{err.confirmPassword}</Col>
      </Col>

      <Button
        className="mt20"
        type="primary"
        size="large"
        block
        onClick={resetPassword}
        loading={loading}
      >
        Reset
      </Button>
    </AuthBgContainer>
  );
};

export default ResetPassword;
