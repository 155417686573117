export const amenitieIconMap = Object.freeze({
  bookable_meeting_room: {
    _id: "bookable_meeting_room",
    name: "Bookable Meeting Room",
    icon: "bookable_meeting_room.svg",
  },
  cleaning: {
    _id: "cleaning",
    name: "Cleaning",
    icon: "cleaning.svg",
  },
  collaboration_space: {
    _id: "collaboration_space",
    name: "Collaboration Space",
    icon: "collaboration_space.svg",
  },
  fully_cabled: {
    _id: "fully_cabled",
    name: "Fully Cabled",
    icon: "fully_cabled.svg",
  },
  fully_furnished: {
    _id: "fully_furnished",
    name: "Fully Furnished",
    icon: "fully_furnished.svg",
  },
  led_lighting: {
    _id: "led_lighting",
    name: "Led Lighting",
    icon: "led_lighting.svg",
  },
  lockers: {
    _id: "lockers",
    name: "Lockers",
    icon: "lockers.svg",
  },
  pet_friendly: {
    _id: "pet_friendly",
    name: "Pet Friendly",
    icon: "pet_friendly.svg",
  },
  phone: {
    _id: "phone",
    name: "Phone",
    icon: "phone.svg",
  },
  breakout: {
    _id: "breakout",
    name: "Breakout",
    icon: "breakout.svg",
  },
  kitchen: {
    _id: "kitchen",
    name: "Kitchen",
    icon: "kitchen.svg",
  },
  meeting_room: {
    _id: "meeting_room",
    name: "Meeting Room",
    icon: "meeting_room.svg",
  },
  shower_facilities: {
    _id: "shower_facilities",
    name: "Shower Facilities",
    icon: "shower_facilities.svg",
  },
  wcs: {
    _id: "wcs",
    name: "WCS",
    icon: "wcs.svg",
  },
  reception: {
    _id: "reception",
    name: "Reception",
    icon: "reception.svg",
  },
  remote_door_entry: {
    _id: "remote_door_entry",
    name: "Remote Door Entry",
    icon: "remote_door_entry.svg",
  },
  bike: {
    _id: "bike",
    name: "Bike",
    icon: "bike.svg",
  },
  under_floor_heating: {
    _id: "under_floor_heating",
    name: "Under Floor Heating",
    icon: "under_floor_heating.svg",
  },
  wifi: {
    _id: "wifi",
    name: "Wi-Fi",
    icon: "wifi.svg",
  },
});
