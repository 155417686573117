import React, { useEffect, useState, useRef, useCallback } from "react";
import CustomModal from "../../utils/CustomModal";
import { Row, Col, Input, Select, Checkbox, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addAdmin, editAdmin } from "../duck/AdminActions";
import { validateEmail } from "../../utils/CommonFunctions";
import { getAllRoles } from "../../role/duck/RoleActions";

const AddAdmin = (props) => {
  const { visible, showModal, selectedAdmin, selectAdmin, editable } = props;

  let modalTitle = editable
    ? selectedAdmin
      ? "Edit Team Member"
      : "Add Team Member"
    : "Team Member";

  const roles = useSelector((state) => state?.role.roles);
  const workSpaceReducer = useSelector((state) => state?.workSpace);
  const { workSpaceMap, active } = workSpaceReducer;

  const prevProp = useRef();
  const dispatch = useDispatch();
  const admins = useSelector((state) => state?.admins);
  const { loading, adding, error } = admins;

  const [state, setState] = useState({});

  useEffect(() => {
    !Object.keys(roles).length && dispatch(getAllRoles());
    if (
      (prevProp?.current?.loading || prevProp?.current?.adding) &&
      (!loading || !adding)
    ) {
      if (!error) {
        selectAdmin(null);
        showModal(false);
      }
    } else if (!prevProp?.current?.visible && visible) {
      setState({
        name: selectedAdmin?.name ?? null,
        email: selectedAdmin?.email ?? null,
        role: selectedAdmin?.role_id?._id ?? null,
        workSpaces: selectedAdmin?.spaces
          .filter((sp) => sp?.active)
          .map((r) => workSpaceMap?.[r?.space]?._id),
        status: selectedAdmin?.status,
      });
    }

    return () => {
      prevProp.current = { loading, adding, visible };
    };
  }, [selectedAdmin, loading, adding, visible]);

  const hasError = () => {
    let { email, workSpaces, role } = state;

    let emailError = "";
    let roleError = "";
    let workSpaceError = "";
    let isError = false;

    email = email?.trim();
    if (!email) {
      emailError = "Please enter admin email";
      isError = true;
    } else if (!validateEmail(email)) {
      emailError = "Please enter valid email";
      isError = true;
    }
    if (!roles?.[role]?.permissions?.showAllWorkSpaces) {
      if (!workSpaces?.length) {
        workSpaceError = "Please Select atleast one workspace";
        isError = true;
      }
    }
    if (!role) {
      roleError = "Please Select role";
      isError = true;
    }

    setState({
      ...state,
      emailError,
      workSpaceError,
      roleError,
    });

    return isError;
  };

  const addOrEdit = useCallback(() => {
    if (!hasError()) {
      if (selectedAdmin) {
        let updatedData = {
          active: status,
          user_id: selectedAdmin?._id,
          space_id: active,
          active_spaces: workSpaces,
          role_id: role,
        };
        dispatch(editAdmin(updatedData));
      } else {
        let newAdmin = {
          email: email.trim(),
          space_id: active,
          active_spaces: workSpaces,
          role_id: role,
        };
        dispatch(addAdmin(newAdmin));
      }
    }
  }, [state, active]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event?.target?.checked ?? event;

      setState((preState) => ({
        ...preState,
        [name]: value,
        emailError: "",
        workSpaceError: "",
        roleError: "",
      }));
    },
    []
  );

  let {
    name,
    email,
    workSpaces,
    role,
    roleError,
    emailError,
    workSpaceError,
    status,
  } = state;

  return (
    <CustomModal
      visible={visible}
      title={modalTitle}
      onCancel={() => showModal(false)}
      footer={
        <>
          <Button onClick={() => showModal(false)}>Close</Button>
          {editable && (
            <Button
              type="primary"
              onClick={addOrEdit}
              loading={loading || adding}
            >
              {selectedAdmin ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      {name && (
        <Row align="middle">
          <Col span={6}>
            <label className="label">Name</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col span={18}>{name}</Col>
        </Row>
      )}

      <Row align="middle" className="mt10">
        <Col span={6}>
          <label className="label">Email</label>
          <sup style={{ color: "red" }}>*</sup>
        </Col>

        <Col span={18}>
          {selectedAdmin ? (
            <span>{email}</span>
          ) : (
            <>
              <Input
                className="custom-input"
                placeholder="abc@cockpit.com"
                value={email}
                onChange={handleChange("email")}
                size="large"
                allowClear
                disabled={adding}
              />
              <Row className="error mt5">{emailError}</Row>
            </>
          )}
        </Col>
      </Row>

      <Row align="middle" className="mt10">
        <Col span={6}>
          <label className="label">Role</label>
          <sup style={{ color: "red" }}>*</sup>
        </Col>

        <Col span={18}>
          <Select
            placeholder="Select Role"
            value={roles?.[role]?.name}
            className="custom-select fw"
            onChange={handleChange("role")}
            disabled={loading || adding || !editable}
          >
            {Object.keys(roles)?.map((id) => (
              <Select.Option key={id} value={id}>
                {roles?.[id]?.name}
              </Select.Option>
            ))}
          </Select>
          <Row className="error mt5">{roleError}</Row>
        </Col>
      </Row>

      {!roles?.[role]?.permissions?.showAllWorkSpaces && (
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Work Spaces</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col span={18}>
            <Select
              className="custom-select fw"
              placeholder="Select Workspace"
              size="large"
              value={workSpaces}
              allowClear
              onChange={handleChange("workSpaces")}
              mode={"multiple"}
              disabled={loading || adding || !editable}
            >
              {Object.keys(workSpaceMap).map((space) => (
                <Select.Option key={space} value={space}>
                  {workSpaceMap?.[space]?.name}
                </Select.Option>
              ))}
            </Select>

            <Row className="error mt5">{workSpaceError}</Row>
          </Col>
        </Row>
      )}
      {selectedAdmin && (
        <Row align="top" className="mt10">
          <Col span={6}></Col>
          <Col span={18}>
            <Checkbox
              checked={status}
              disabled={loading || adding || !editable}
              onChange={handleChange("status")}
            >
              Active
            </Checkbox>
          </Col>
        </Row>
      )}
    </CustomModal>
  );
};

export default AddAdmin;
