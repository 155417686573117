import React, { useCallback } from "react";
import { Col, Row, Upload } from "antd";
import MUploadItem from "../../components/MUploadItem";
import { showNotification } from "../../../utils/CommonFunctions";
import { UploadOutlined } from "@ant-design/icons";

const ImageUploadComponent = (props) => {
  const {
    disabled,
    fileList,
    setFileList,
    setError,
    imgWidth = null,
    imgHeight = null,
  } = props;

  const maxLimit = props?.limit ?? 100;

  const beforeUpload = useCallback((file) => {
    const supportedFileType = ["image/png", "image/jpg", "image/jpeg"];

    let isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification("error", "Please upload JEPG, JGP or PNG file only");
    }
    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const updateImageUpload = useCallback(
    ({ file }) => {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = function () {
        // const width = Number(img.naturalWidth),
        //   height = Number(img.naturalHeight);

        URL.revokeObjectURL(img.src);
        // if (width === imgWidth && imgHeight === height) {
        const fileObj = {
          uid: file?.uid,
          name: file?.name,
          size: file?.size,
          type: file?.type,
          thumb_location: URL.createObjectURL(file),
          url: URL.createObjectURL(file),
          tag: "",
          file,
        };

        setError({});
        setFileList((preState) => [...preState, fileObj]);
        // } else {
        //   showNotification(
        //     "error",
        //     `Please upload exact image size of ${imgWidth}x${imgHeight}`
        //   );
        // }
      };
    },
    [setError, setFileList]
  );

  const onRemoveImage = useCallback(
    (file) => {
      const fileId = file?._id || file?.uid;
      setFileList((preState) =>
        preState.filter((p) => (p?._id || p?.uid) !== fileId)
      );
    },
    [setFileList]
  );

  const handleTagChange = useCallback(
    (file) => {
      setFileList((preState) =>
        preState.map((perFile) =>
          (perFile._id && perFile._id === file._id) || perFile.uid === file.uid
            ? file
            : perFile
        )
      );
      setError({});
    },
    [setError, setFileList]
  );

  return (
    <Row align="top" className="mb15">
      <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
        <label className="label">Images</label>
        <sup style={{ color: "red" }}>*</sup>
      </Col>
      <Col span={8} xs={24} sm={24} lg={18} xl={18} xxl={20}>
        <Upload
          className="custom-upload-list"
          accept={".jpeg, .jpg, .png"}
          beforeUpload={beforeUpload}
          listType="picture"
          fileList={fileList}
          onRemove={onRemoveImage}
          customRequest={updateImageUpload}
          disabled={disabled}
          itemRender={(originNode, file, _, actions) => (
            <MUploadItem
              originNode={originNode}
              file={file}
              actions={actions}
              handleTagChange={handleTagChange}
              disabled={disabled}
            />
          )}
        >
          {fileList?.length < maxLimit && (
            <Row
              className="custom-upload-image fw mb10"
              align="middle"
              justify="center"
              aria-disabled={true}
            >
              <Col className="mt5">
                <UploadOutlined className="mr5" />
                Click to Upload
                <sup style={{ color: "red" }}>*</sup>
                {imgWidth && imgHeight ? ` ${imgWidth}x${imgHeight}` : ""}
              </Col>
            </Row>
          )}
        </Upload>
        <Row className="error mt5">{props?.error}</Row>
      </Col>
    </Row>
  );
};

export default React.memo(ImageUploadComponent);
