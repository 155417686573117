import React, { useEffect, useState, useRef, useCallback } from "react";
import CustomModal from "../../utils/CustomModal";
import { Row, Col, Input, Checkbox, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addMember, editMember } from "../duck/MembersActions";
import { validateEmail } from "../../utils/CommonFunctions";

const AddMember = (props) => {
  const { visible, showModal, selectedMember, selectMember, editable } = props;
  const activeWorkspace = useSelector((state) => state?.workSpace?.active);

  let modalTitle = editable
    ? selectedMember
      ? "Edit Member"
      : "Add Member"
    : "Member";

  const prevProp = useRef();
  const dispatch = useDispatch();
  const members = useSelector((state) => state?.members);
  const { loading, adding, error } = members;

  const [state, setState] = useState({});

  useEffect(() => {
    if (
      (prevProp?.current?.loading || prevProp?.current?.adding) &&
      (!loading || !adding)
    ) {
      if (!error) {
        selectMember(null);
        setState({
          active: true,
        });
        showModal(false);
      }
    } else if (!prevProp?.current?.visible && visible) {
      setState({
        name: selectedMember?.name ?? null,
        email: selectedMember?.email ?? null,
        address: selectedMember?.address ?? null,
        phone: selectedMember?.phone ?? null,
        active: selectedMember?.spaces?.find(
          (sp) => sp?.space === activeWorkspace
        ).active,
      });
    }
    return () => {
      prevProp.current = { loading, adding, visible };
    };
  }, [selectedMember, loading, adding, visible]);

  const hasError = () => {
    let { email } = state;
    let emailError = "";
    let isError = false;

    email = email?.trim();
    if (!email) {
      emailError = "Please enter member email";
      isError = true;
    } else if (!validateEmail(email)) {
      emailError = "Please enter valid email";
      isError = true;
    }

    setState({
      ...state,
      emailError,
    });

    return isError;
  };

  const addOrEdit = useCallback(() => {
    if (!hasError()) {
      if (selectedMember) {
        let updatedData = {
          active,
          user_id: selectedMember?._id,
          space_id: activeWorkspace,
        };

        dispatch(editMember(updatedData));
      } else {
        let newMember = {
          email: email.trim(),
          space_id: activeWorkspace,
        };
        dispatch(addMember(newMember));
      }
    }
  }, [state]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event?.target?.checked;

      setState((pre) => ({
        ...pre,
        [name]: value,
        emailError: "",
      }));
    },
    []
  );

  let { name, email, address, active, emailError, phone } = state;

  return (
    <CustomModal
      visible={visible}
      title={modalTitle}
      onCancel={() => showModal(false)}
      footer={
        <>
          <Button onClick={() => showModal(false)}>Close</Button>
          {editable && (
            <Button
              type="primary"
              onClick={addOrEdit}
              loading={loading || adding}
            >
              {selectedMember ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      <div>
        {name && (
          <Row align="middle" className="mb10">
            <Col span={6}>
              <label className="label">Name </label>
            </Col>
            <Col span={18}>{name}</Col>
          </Row>
        )}

        <Row align="middle" className="mb10">
          <Col span={6}>
            <label className="label">Email </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18}>
            {selectedMember ? (
              <span>{email}</span>
            ) : (
              <>
                <Input
                  className="custom-input"
                  placeholder="abc@cockpit.com"
                  value={email}
                  onChange={handleChange("email")}
                  size="large"
                  allowClear
                  disabled={adding || !editable}
                />
                <Row className="error mt5">{emailError}</Row>
              </>
            )}
          </Col>
        </Row>
        {phone && (
          <Row align="middle" className="mb10">
            <Col span={6}>
              <label className="label">Phone </label>
            </Col>
            <Col span={18}>{phone}</Col>
          </Row>
        )}
        {address && (
          <Row align="middle" className="mb10">
            <Col span={6}>
              <label className="label">Address </label>
            </Col>
            <Col span={18}>{address}</Col>
          </Row>
        )}

        {selectedMember && (
          <Row align="top" className="mt10">
            <Col span={6}></Col>
            <Col span={18}>
              <Checkbox
                checked={active}
                disabled={loading || adding || !editable}
                onChange={handleChange("active")}
              >
                Active
              </Checkbox>
            </Col>
          </Row>
        )}
      </div>
    </CustomModal>
  );
};

export default AddMember;
