import Actions from "./DoorActionTypes";

const initialState = {
  doors: {},
  loading: false,
  error: null,
};

const doorReducer = (state = initialState, action) => {
  let payload = action?.payload;

  switch (action.type) {
    case Actions.FETCH_ALL_DOOR:
      return Object.assign({}, state, {
        error: null,
        loading: true,
      });

    case Actions.FETCH_ALL_DOOR_SUCCESS: {
      let workSpaceId = payload?.space_id;
      let doors = payload?.data;

      return Object.assign({}, state, {
        doors: Object.assign({}, state.doors, {
          [workSpaceId]: doors,
        }),
        loading: false,
      });
    }

    case Actions.FETCH_ALL_DOOR_FAILURE:
      return Object.assign({}, state, {
        error: payload,
        loading: false,
      });

    default:
      return state;
  }
};

export default doorReducer;
