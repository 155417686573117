import {memo} from 'react'
import {Layout} from 'antd'
import AppRouters from '../../routers/AppRouters'

const AppContent = () => {
    return (
        <Layout.Content className='--cockpit-container'>
            <div className='m20 p10'>
                <AppRouters />
            </div>
        </Layout.Content>
    )
}

export default memo(AppContent)