import { useState, useCallback } from "react";
import { Button, Input, Row, Col, Typography } from "antd";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  isUserAuthenticated,
  isValidPassword,
  validateEmail,
  checkSpecialCharacters,
  showNotification,
} from "../utils/CommonFunctions";
import "../assets/css/login.scss";
import signupSvg from "../assets/images/signup.jpg";
import { ArrowLeftOutlined } from "@ant-design/icons";
import AppUrl from "../config/AppUrl";
import axios from "axios";
// import logo from "../assets/svg/logo.svg";
import Routes from "../config/Routes";

import loginSvg from "../assets/svg/logo_full.svg";
import AuthBgContainer from "./components/AuthBgContainer";

const SignupContainer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [err, handleError] = useState({
    name: null,
    email: null,
    password: null,
  });
  const [loading, setLoader] = useState(false);
  const history = useHistory();

  const signup = useCallback(() => {
    if (
      checkSpecialCharacters(name) &&
      validateEmail(email) &&
      isValidPassword(password) &&
      !/\s/.test(password)
    ) {
      signupRequest({ name, email, password });
    } else {
      let nameErr = null;
      let emailErr = null;
      let passErr = null;
      if (name?.trim() === "") {
        nameErr = "Name cannot be blank";
      } else if (!checkSpecialCharacters(name)) {
        nameErr = "Special character is not allowed";
      }
      if (email?.trim() === "") {
        emailErr = "Email cannot be blank";
      } else if (!validateEmail(email)) {
        emailErr = "Email is not valid";
      }
      if (password?.trim() === "") {
        passErr = "Password cannot be blank";
      } else if (/\s/.test(password)) {
        passErr = "Blank Spaces are not allowed";
      } else if (!isValidPassword(password)) {
        passErr = "Password length should be of minimum 4 characters";
      }
      handleError({ name: nameErr, email: emailErr, password: passErr });
    }
  }, [name, email, password]);

  const signupRequest = async () => {
    setLoader(true);
    try {
      const userRes = await axios.post(AppUrl.SIGN_UP_URL, {
        name,
        email,
        password,
      });
      let userData = userRes.data;
      if (userData?.meta?.success === false || userData?.meta?.status !== 200) {
        showNotification("error", userData?.meta?.message);
        setLoader(false);
      } else {
        showNotification(
          "success",
          "An activation link has been sent to your mail, please verify your account."
        );

        history.push(Routes.login);
      }
    } catch (error) {
      console.log("error", error);
      setLoader(false);
    }
  };

  if (isUserAuthenticated()) {
    return <Redirect to={Routes.dashboard} />;
  }

  return (
    <AuthBgContainer
      title={"Create New Account"}
      subtitle={"Sign-up to start your journey with Garden Office!"}
    >
      <Col className="mb20">
        <Col className="label mb5">Name</Col>

        <Input
          placeholder="Sergio Marquina"
          size="large"
          allowClear
          onChange={(e) => {
            setName(e.target.value);
            handleError({ name: null, email: null, password: null });
          }}
          value={name}
          onPressEnter={signup}
          autoFocus
        />
        <Col className="error mt5">{err.name}</Col>
      </Col>

      <Col className="mb20">
        <Col className="label mb5">Email</Col>
        <Input
          placeholder="user@cockpit.com"
          size="large"
          allowClear
          onChange={(e) => {
            setEmail(e.target.value);
            handleError({ name: null, email: null, password: null });
          }}
          onPressEnter={signup}
          value={email}
        />
        <Col className="error mt5">{err.email}</Col>
      </Col>

      <Col className="mb20">
        <Col className="label mb5">Password</Col>

        <Input
          placeholder="Password"
          size="large"
          type="password"
          allowClear
          onChange={(e) => {
            setPassword(e.target.value);
            handleError({ name: null, email: null, password: null });
          }}
          value={password}
          onPressEnter={signup}
        />
        <Col className="error mt5">{err.password}</Col>
      </Col>

      <Col className="mb20" style={{ fontSize: 12 }}>
        <Typography.Text>
          By signing up, you agree to our Privacy Policy and Terms of Service.
        </Typography.Text>
      </Col>

      <Button
        className="mt20"
        type="primary"
        loading={loading}
        size="large"
        block
        onClick={signup}
      >
        Sign Up
      </Button>

      <Row className="mt20" justify="center">
        <Link className="btn-link" to={Routes.login}>
          Back to login
        </Link>
      </Row>
    </AuthBgContainer>
  );
};

export default SignupContainer;
