import React, { useMemo } from "react";
import { Card, Tooltip, Typography } from "antd";
import { useSelector } from "react-redux";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;

const OfferItemCard = (props) => {
  const { id, isCMSEditable } = props;

  const mCms = useSelector(({ cms }) => cms?.cmsMap?.[id]);
  const ExtraIcon = useMemo(
    () => (isCMSEditable ? EditOutlined : EyeOutlined),
    [isCMSEditable]
  );

  return (
    <Card
      style={{
        width: 300,
        margin: "10px 20px 10px 0",
        borderRadius: 5,
      }}
      onClick={props.showModal}
      title={mCms?.name}
      bordered={false}
      hoverable
      extra={
        <Tooltip title={isCMSEditable ? "Edit" : "More Details"}>
          <ExtraIcon style={{ fontSize: 12 }} />
        </Tooltip>
      }
    >
      <Paragraph className="font-bold" ellipsis={{ rows: 2 }}>
        {mCms?.meta?.subheading}
      </Paragraph>

      <Paragraph ellipsis={{ rows: 5 }}>{mCms?.description}</Paragraph>
    </Card>
  );
};

export default React.memo(OfferItemCard);
