import { Row, Col, Avatar, Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { getAvatarColor, getNameInitials } from "../../utils/CommonFunctions";

export const invoiceColumns = (handleClick) => {
  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",
      key: "sno",
      width: 60,
      align: "center",
    },
    {
      title: "Invoice no.",
      dataIndex: "id",
      key: "id",
      width: 100,
      align: "center",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Booked By",
      dataIndex: "booked_by",
      key: "booked_by",
      key2: "email",
      render: (booked_by) => (
        <Row align="middle">
          <Col className="mr15">
            <Avatar style={{ backgroundColor: getAvatarColor(booked_by?._id) }}>
              {getNameInitials(booked_by?.name)}
            </Avatar>
          </Col>
          <Col>
            <div>{booked_by?.name}</div>
            <div style={{ color: "#c1bdbd" }}>{booked_by?.email}</div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Resource",
      dataIndex: "resource",
      key: "resource",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Issue Date",
      dataIndex: "issuedate",
      key: "issuedate",
    },
    {
      title: "Members",
      dataIndex: "members",
      key: "members",
      render: (members) => (
        <Row justify="start" align="middle" className="mt5">
          <Avatar.Group
            maxCount={3}
            maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
          >
            {members?.map?.((user) => (
              <Tooltip
                placement="bottom"
                title={user?.name || user?.email}
                key={user?._id}
              >
                <Avatar style={{ backgroundColor: getAvatarColor(user?._id) }}>
                  {getNameInitials(user?.name || user?.email)}
                </Avatar>
              </Tooltip>
            ))}
          </Avatar.Group>
        </Row>
      ),
    },
    {
      title: "Preview",
      dataIndex: "action",
      key: "action",
      width: 130,
      align: "center",
      render: (id) => (
        <Row align="middle" justify="center">
          <Tooltip title="Preview">
            <EyeOutlined onClick={() => handleClick(id)} className="mr15" />
          </Tooltip>
        </Row>
      ),
    },
  ];
  return columns;
};
