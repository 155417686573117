import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Col, Row } from "antd";
import ReactQuill from "react-quill";
import CustomModal from "../../../utils/CustomModal";
import ImageUploadComponent from "./ImageUploadComponent";
import CtaButonComponent from "./CtaButonComponent";

const FoldDataModal = (props) => {
  const {
    state,
    visible,
    selectedImg,
    showModal,
    isCMSEditable,
    handleData,
    title,
    disabled,
    totalImages,
  } = props;

  const prevProp = useRef();

  const modalTitle = useMemo(
    () =>
      isCMSEditable
        ? selectedImg
          ? `Edit ${title} `
          : `Add ${title} `
        : `${title} Details`,
    [isCMSEditable, selectedImg, title]
  );

  const [fileList, setFileList] = useState([]);
  const [imgObj, setImgObj] = useState({});
  const [extra, setExtra] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    if (!prevProp?.current?.visible && visible) {
      setFileList(selectedImg ? [selectedImg] : []);
      setImgObj(selectedImg ? selectedImg : {});
      setExtra(selectedImg ? selectedImg?.extra : {});
    }
    setError({});
    return () => {
      prevProp.current = {
        visible,
      };
    };
  }, [selectedImg, visible, selectedImg?.extra]);

  const handleChange = useCallback(
    (name, isCheckbox) => (e) => {
      let value;
      if (isCheckbox) {
        value = e?.target?.checked;
      } else {
        value = e?.target?.value ?? e;
      }
      setImgObj((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );
  const handleExtra = useCallback(
    (name, isCheckbox) => (e) => {
      let value;
      if (isCheckbox) {
        value = e?.target?.checked;
      } else {
        value = e?.target?.value ?? e;
      }
      setExtra((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const hasError = useCallback(() => {
    const { showCTAButton, ctaBtnText, ctaBtnURL } = extra;

    const error = {};
    if (!fileList?.length) {
      error.fileList = "Please upload an image";
    }

    if (showCTAButton) {
      if (!ctaBtnText) {
        error.ctaBtnText = "Please enter button text";
      }
      if (!ctaBtnURL) {
        error.ctaBtnURL = "Please enter button url";
      }
    }

    setError(error);
    return Object.keys(error).length;
  }, [fileList?.length, extra]);

  const add = useCallback(() => {
    if (!hasError()) {
      const { title, imgDescription } = imgObj;
      const payload = {
        ...fileList?.[0],
        title,
        imgDescription,
        extra: { order: totalImages, ...extra },
      };
      let action;
      if (selectedImg) {
        const selectedFileId = selectedImg?._id || selectedImg?.uid;
        const fileId = fileList?.[0]?._id || fileList?.[0]?.uid;
        if (selectedFileId === fileId) {
          action = "update";
        } else {
          action = "replace";
        }
      }

      // console.log(payload, "payload");
      handleData(payload, action);
      showModal(false);
    }
  }, [
    hasError,
    imgObj,
    fileList,
    totalImages,
    extra,
    selectedImg,
    handleData,
    showModal,
  ]);

  return (
    <CustomModal
      visible={visible}
      title={modalTitle}
      onCancel={showModal}
      width={800}
      footer={
        <>
          <Button onClick={showModal}>Close</Button>
          {isCMSEditable && (
            <Button type="primary" onClick={add}>
              {selectedImg ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      <div>
        <ImageUploadComponent
          fileList={fileList}
          setFileList={setFileList}
          error={error?.fileList}
          setError={setError}
          disabled={!isCMSEditable}
          limit={1}
          imgWidth={state?.imageWidth}
          imgHeight={state?.imageHeight}
          imageWithExtra={props?.imageWithExtra}
        />

        {fileList?.length > 0 && (
          <>
            <Row align="middle" className="mt10">
              <Col span={6} xs={24} sm={24} lg={6} xl={5} xxl={4}>
                <label className="label">Title </label>
              </Col>
              <Col span={18} xs={24} sm={24} lg={18} xl={19} xxl={20}>
                <ReactQuill
                  placeholder="Description"
                  size="large"
                  type="text"
                  value={imgObj?.title}
                  onChange={handleChange("title")}
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ["bold", "italic", "underline", "link"], // toggled buttons
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["clean"],
                    ],
                  }}
                />
              </Col>
            </Row>

            <Row className="mt10">
              <Col span={6} xs={24} sm={24} lg={6} xl={5} xxl={4}>
                <label className="label">Image Description</label>
              </Col>

              <Col span={18} xs={24} sm={24} lg={18} xl={19} xxl={20}>
                <ReactQuill
                  style={{
                    height: "100px",
                    marginBottom: 40,
                  }}
                  placeholder="Description"
                  size="large"
                  type="text"
                  value={imgObj?.imgDescription}
                  onChange={handleChange("imgDescription")}
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ["bold", "italic", "underline", "link"], // toggled buttons
                      [{ list: "ordered" }, { list: "bullet" }],

                      ["clean"],
                    ],
                  }}
                />
              </Col>
            </Row>

            <Row className="mt20">
              <Col span={24}>
                <CtaButonComponent
                  value={extra?.showCTAButton}
                  ctaBtnText={extra?.ctaBtnText}
                  ctaBtnURL={extra?.ctaBtnURL}
                  handleChange={handleExtra}
                  error={error}
                  disabled={disabled}
                />
              </Col>
            </Row>
          </>
        )}
        {/* <CtaButonComponent
              value={state?.showCTAButton}
              ctaBtnText={state?.ctaBtnText}
              ctaBtnURL={state?.ctaBtnURL}
              handleChange={handleChange}
              error={error}
              disabled={disabled}
            /> */}
      </div>
    </CustomModal>
  );
};

export default FoldDataModal;
