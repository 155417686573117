import { combineReducers } from "redux";
import loginReducer from "../login/duck/LoginReducer";
import workSpaceReducer from "../workspace/duck/WorkSpaceReducer";
import resourcesReducer from "../resources/duck/ResourceReducer";
import bookingReducer from "../booking/duck/BookingReducer";
import membersReducer from "../members/duck/MemberReducer";
import amenitiesReducer from "../settings/duck/AmenitiesReducer";
import dashboardReducer from "../dashboard/duck/DashboardReducer";
import planReducer from "../plan/duck/PlanReducer";
import invoiceReducer from "../invoice/duck/InvoiceReducer";
import mailReducer from "../mail/duck/MailReducer";
import billingReducer from "../billing/duck/BillingReducer";
import roleReducer from "../role/duck/RoleReducer";
import adminReducer from "../adminAndTeams/duck/AdminReducer";
import doorReducer from "../doorList/duck/DoorReducer";
import userActivityReducer from "../userActivity/duck/UserActivityReducer";
import contactsReducer from "../contacts/duck/ContactsReducer";
import leadsReducer from "../socialMediaLead/duck/LeadsReducer";
import reportReducer from "../reports/duck/ReportReducer";
import cmsReducer from "../cms/duck/CMSReducer";
import xeroReducer from "../xero/duck/XeroReducer";

const appReducer = combineReducers({
  user: loginReducer,
  workSpace: workSpaceReducer,
  resources: resourcesReducer,
  booking: bookingReducer,
  members: membersReducer,
  admins: adminReducer,
  amenities: amenitiesReducer,
  dashboard: dashboardReducer,
  plan: planReducer,
  invoice: invoiceReducer,
  mail: mailReducer,
  billing: billingReducer,
  role: roleReducer,
  door: doorReducer,
  userActivity: userActivityReducer,
  contacts: contactsReducer,
  leads: leadsReducer,
  reports: reportReducer,
  cms: cmsReducer,
  xero: xeroReducer,
});

const RootReducer = (state, action) => {
  // clearing redux state when user logs out
  if (action.type === "LOGOUT_SUCCESS") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default RootReducer;
