import Actions from "./AmenitiesActionTypes";
import { put, takeLatest, call } from 'redux-saga/effects';
import { showNotification } from '../../utils/CommonFunctions';
import AppUrl from '../../config/AppUrl'
import axios from 'axios';

const getAllAmenities = () => {
    return axios({
        method: 'GET',
        url: AppUrl.AMENITIES
    })
}

const addNewAmenityRequest = (data) => {
    return axios({
        method: 'POST',
        url: AppUrl.AMENITIES,
        data
    })
}

const updateAmenityRequest = (data) => {
    return axios({
        method: 'PUT',
        url: `${AppUrl.AMENITIES}/${data?._id}`,
        data
    })
}

const deleteAmenityRequest = (id) => {
    return axios({
        method: "DELETE",
        url: `${AppUrl.AMENITIES}/${id}`,
    })
}

function * fetchAllAmenities(action){
    try {
        let amenity = yield call(getAllAmenities, action.payload);
        let _data = amenity?.data;
        if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
            showNotification("error", _data?.meta?.message);
            yield put({ type: Actions.FETCH_ALL_AMENITIES_FAILURE, payload: _data?.meta })
        } else {
            yield put({ type: Actions.FETCH_ALL_AMENITIES_SUCCESS, payload: _data?.data })
        }
    } catch (error) {
        console.log("fetch amentities error", error);
    }
}

function * addNewAmenity(action){
    try {
        let amenity = yield call(addNewAmenityRequest, action.payload);
        let _data = amenity?.data;
        if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
            showNotification("error", _data?.meta?.message);
            yield put({ type: Actions.CREATE_AMENITY_FAILURE, payload: _data?.meta })
        } else {
            yield put({ type: Actions.CREATE_AMENITY_SUCCESS, payload: _data?.data })
        }
    } catch (error) {
        console.log("create amenity error", error);
    }
}

function * updateAmenity(action){
    try {
        let amenity = yield call(updateAmenityRequest, action.payload);
        let _data = amenity?.data;
        if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
            showNotification("error", _data?.meta?.message);
            yield put({ type: Actions.UPDATE_AMENITY_FAILURE, payload: _data?.meta })
        } else {
            yield put({ type: Actions.UPDATE_AMENITY_SUCCESS, payload: _data?.data })
        }
    } catch (error) {
        console.log("create amenity error", error);
    }
}

function * deleteAmenity(action){
    try {
        let amenity = yield call(deleteAmenityRequest, action.payload);
        let _data = amenity?.data;
        if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
            showNotification("error", _data?.meta?.message);
            yield put({ type: Actions.DELETE_AMENITY_FAILURE, payload: _data?.meta })
        } else {
            yield put({ type: Actions.DELETE_AMENITY_SUCCESS, payload: _data?.data })
        }
    } catch (error) {
        console.log("create amenity error", error);
    }
}

export default function *amenitiesSaga(){
    yield takeLatest(Actions.FETCH_ALL_AMENITIES, fetchAllAmenities);
    yield takeLatest(Actions.CREATE_AMENITY, addNewAmenity);
    yield takeLatest(Actions.UPDATE_AMENITY, updateAmenity);
    yield takeLatest(Actions.DELETE_AMENITY, deleteAmenity);
}