import Actions from "./LeadsActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";

const initialState = {
  leadMap: {},
  leads: {},
  filter: null,
  loading: false,
  error: null,
};

const leadReducer = (state = initialState, action) => {
  let payload = action?.payload;

  switch (action.type) {
    /** get all leads start */

    case Actions.FETCH_ALL_LEADS: {
      let params = Object.assign({}, payload);
      delete params?.offset;
      delete params?.limit;
      const filter = JSON.stringify(params);

      return Object.assign({}, state, {
        leads: Object.assign({}, state.leads, {
          [filter]: Object.assign({}, state.leads?.[filter], {
            loading: true,
            error: null,
          }),
        }),
        filter: filter,
      });
    }

    case Actions.FETCH_ALL_LEADS_SUCCESS: {
      const filter = payload?.filter;

      return Object.assign({}, state, {
        leadMap: Object.assign({}, state.leadMap, arrToObjMap(payload?.data)),
        leads: Object.assign({}, state.leads, {
          [filter]: Object.assign({}, state.leads?.[filter], {
            list: getArrOrder(payload?.data),
            loading: false,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_LEADS_FAILURE: {
      const filter = payload?.filter;

      return Object.assign({}, state, {
        leads: Object.assign({}, state.leads, {
          [filter]: Object.assign({}, state.leads?.[filter], {
            error: payload,
            loading: false,
          }),
        }),
      });
    }
    /** get all leads end */

    default:
      return state;
  }
};

export default leadReducer;
