import React from 'react';
import { Col, Row } from 'antd';

const Timelinedata = (props) => {
    let {
        resource_type,
        capacity,
        from_to_date,
    } = props;

    return (
        <>
            <Col className="timeline_container">
                {/* <Row> */}
                <Col className="container">
                    <div className="inner_t_c">
                        <ul>
                            <li className={`
                                    f_li_c
                                    ${!resource_type && "active"}
                                    ${resource_type && "active_data"}
                                `}>
                                <span>
                                    <i></i>
                                </span>
                            </li>
                            <li className={`
                                    s_li_c
                                    ${resource_type && !capacity && "active"}
                                    ${resource_type && capacity && "active_data"}
                                `}>
                                <span>
                                    <i></i>
                                </span>
                            </li>
                            <li className={`
                                    t_li_c
                                    ${resource_type && capacity && !from_to_date && "active"}
                                    ${resource_type && capacity && from_to_date && "active_data"}
                                `}>
                                <span>
                                    <i></i>
                                </span>
                            </li>
                        </ul>
                    </div>
                </Col>
                {/* </Row> */}
            </Col>
        </>
    );
}

export default Timelinedata;