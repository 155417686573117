import React, { useCallback, useEffect, useState, useMemo } from "react";
import CustomModal from "../../utils/CustomModal";
import { Row, Col, Input, Button, Select } from "antd";

const AddKnowMoreModal = (props) => {
  const {
    visible,
    selectedObj,
    showModal,
    isCMSEditable,
    knowMoreTypes,
    handleKnowMore,
  } = props;

  const modalTitle = useMemo(
    () =>
      isCMSEditable
        ? selectedObj
          ? "Edit Know More"
          : "Add Know More"
        : "Know More Item",
    [isCMSEditable, selectedObj]
  );

  const [knowMoreObj, setKnowMoreObj] = useState({});
  const [error, handleError] = useState({});

  useEffect(() => {
    handleError({});
    setKnowMoreObj({
      type: selectedObj?.type || null,
      value: selectedObj?.value,
    });
  }, [visible, showModal, selectedObj?.type, selectedObj?.value]);

  const hasError = useCallback(() => {
    let { type, value } = knowMoreObj;

    let error = {};
    if (!type) {
      error.type = "Please select a type";
    }
    if (!value) {
      error.value = "Value Required";
    }

    handleError(error);
    return !!Object.keys(error).length;
  }, [knowMoreObj]);

  const add = useCallback(() => {
    if (!hasError()) {
      let payload = { ...knowMoreObj };
      if (selectedObj && selectedObj.index !== undefined)
        payload.index = selectedObj.index;
      handleKnowMore(payload);
      showModal(false);
    }
  }, [handleKnowMore, hasError, knowMoreObj, selectedObj, showModal]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e?.target?.checked ?? e;

      setKnowMoreObj((preState) => ({ ...preState, [name]: value }));
      handleError({});
    },
    []
  );
  const { type, value } = knowMoreObj;

  const disabled = !isCMSEditable;

  return (
    <CustomModal
      visible={visible}
      title={modalTitle}
      onCancel={showModal}
      footer={
        <>
          <Button onClick={showModal}>Close</Button>
          {isCMSEditable && (
            <Button type="primary" onClick={add}>
              {selectedObj ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      <div>
        {/* type start */}
        <Row align="middle" className="mb10">
          <Col span={6}>
            <label className="label">Type</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18}>
            <Select
              className="custom-select fw"
              placeholder={"Select Resource Type"}
              value={type}
              onChange={handleChange("type")}
              disabled={disabled}
            >
              {Object.values(knowMoreTypes).map((knowMoreTypes) => (
                <Select.Option
                  key={knowMoreTypes?._id}
                  value={knowMoreTypes?._id}
                >
                  {knowMoreTypes?.name}
                </Select.Option>
              ))}
            </Select>
            <Row className="error mt5">{error?.type}</Row>
          </Col>
        </Row>
        {/* type end */}

        {/* value start */}
        {
          <Row align="middle">
            <Col span={6}>
              <label className="label">Description</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col span={18}>
              {type === "content" ? (
                <Input.TextArea
                  className="custom-input"
                  placeholder="Description"
                  autoSize={{ minRows: 5, maxRows: 15 }}
                  value={value}
                  disabled={disabled}
                  onChange={handleChange("value")}
                />
              ) : (
                <Input
                  className="custom-input fw mt10"
                  placeholder={"Title"}
                  size="large"
                  value={value}
                  onChange={handleChange("value")}
                  disabled={disabled}
                />
              )}

              <Row className="error mt5">{error?.value}</Row>
            </Col>
          </Row>
        }
        {/* value end */}
      </div>
    </CustomModal>
  );
};

export default AddKnowMoreModal;
