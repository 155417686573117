import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../spinner/Spinner";
import AboutUsTabCard from "./components/AboutUsTabCard";

import { getAllCMS } from "./duck/CMSActions";
import { checkPermission } from "../utils/CommonFunctions";
import constants from "../utils/Constants";
import AddTestimonialModal from "./components/AddTestimonialModal";

const { CMS_TYPES } = constants;

const Testimonial = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({});
  const [boardKey, setBoardKey] = useState();
  const isCMSEditable = useMemo(() => checkPermission({ row: "cms" }), []);

  const cmsBoard = useSelector(
    (state) => state?.cms?.boards?.[JSON.stringify(boardKey)]
  );

  useEffect(() => {
    const params = { type: CMS_TYPES.TESTIMONIAL };
    setBoardKey(params);
    dispatch(getAllCMS(params));
  }, [dispatch]);

  const handleModal = useCallback((show = false, tabId) => {
    show = typeof show === "boolean" && show;

    setState((preState) => ({ ...preState, showModal: show, tabId }));
  }, []);

  const loading = cmsBoard?.loading && !cmsBoard?.list?.length;

  return loading ? (
    <Spinner />
  ) : (
    <div>
      <Row justify="space-between" align="middle" className="mb10">
        <span className="nw_heading">Testimonial</span>

        <span>
          {isCMSEditable && (
            <Button type="primary-outline" onClick={() => handleModal(true)}>
              + Add Testimonial
            </Button>
          )}
        </span>
      </Row>

      <Row>
        {cmsBoard?.list?.map((id, i) => (
          <AboutUsTabCard
            key={i}
            id={id}
            showModal={handleModal}
            isCMSEditable={isCMSEditable}
          />
        ))}
      </Row>

      <AddTestimonialModal
        visible={state?.showModal}
        selectedId={state?.tabId}
        showModal={handleModal}
        isCMSEditable={isCMSEditable}
      />
    </div>
  );
};

export default Testimonial;
