import Actions from "./BookingActionTypes";

export const getAllBookingResources = (workSpaceId) => {
  return {
    type: Actions.FETCH_ALL_BOOKING_RESOURCES,
    payload: workSpaceId,
  };
};

export const getBookingResources = (id) => {
  return {
    type: Actions.FETCH_BOOKING_RESOURCES,
    payload: { _id: id },
  };
};

export const payCharge = (payload = {}) => {
  return {
    type: Actions.PAY_CHARGE,
    payload: payload,
  };
};

export const bookResource = (params = {}) => {
  return {
    type: Actions.BOOK_RESOURCE,
    payload: {
      isShowNotification: true,
      ...params,
    },
  };
};

export const getAllAvailableResources = (params = {}) => {
  return {
    type: Actions.FETCH_ALL_AVAILABLE_RESOURCES,
    payload: params,
  };
};
export const getAllAvailableDragResources = ({ id, ...params } = {}) => {
  const newObj = {
    booking_id: id,
    ...params,
  };
  return {
    type: Actions.FETCH_ALL_AVAILABLE_RESOURCES,
    payload: newObj,
  };
};
export const cancelBooking = (id) => {
  return {
    type: Actions.CANCEL_BOOKING,
    payload: { _id: id },
  };
};

export const updateBooking = (obj) => {
  return {
    type: Actions.UPDATE_BOOKING,
    payload: obj,
  };
};
export const updateDragBooking = (obj) => {
  const { id } = obj;
  const newObj = {
    ...obj,
    _id: id,
  };
  return {
    type: Actions.UPDATE_DRAG_BOOKING,
    payload: newObj,
  };
};

export const changeBookedResource = (obj) => {
  return {
    type: Actions.CHANGE_BOOKED_RESOURCE,
    payload: obj,
  };
};

export const bookResourceWithoutPay = (payload = {}) => {
  return {
    type: Actions.BOOK_RESOURCE_WITHOUT_PAY,
    payload,
  };
};

export const changeBookingRequest = (obj) => {
  return {
    type: Actions.CHANGE_BOOKING_REQUEST,
    payload: obj,
  };
};

export const changeBookedResourceApprove = (obj) => {
  return {
    type: Actions.CHANGE_BOOKED_RESOURCE_APPROVE,
    payload: obj,
  };
};

export const setRefreshToken = (code) => {
  return {
    type: Actions.SET_REFRESH_TOKEN,
    payload: code,
  };
};
