import Actions from "./BookingActionTypes";
import { getArrOrder } from "../../utils/CommonFunctions";

const arrToObjMap = (arr = []) => {
  const obj = arr.reduce((mObj, item) => {
    let key = item?._id || item?.id;

    if (!item.members?.length) {
      item.members = [
        {
          _id: item.email,
          name: item.name,
          email: item.email,
          number: item.number,
        },
      ];
    }

    mObj[key] = item;
    return mObj;
  }, {});

  return Object.assign({}, obj);
};

const initialState = {
  bookingMap: {},
  bookings: {},
  availableResourceMap: {},
  availableResources: {},
  filter: {},
};

const resourcesReducer = (state = initialState, action) => {
  let payload = Object.assign({}, action?.payload);

  switch (action.type) {
    case Actions.FETCH_ALL_BOOKING_RESOURCES: {
      delete payload?.offset;
      delete payload?.limit;
      const mFilter = JSON.stringify(Object.assign({}, payload));

      return Object.assign({}, state, {
        bookings: Object.assign({}, state.bookings, {
          [mFilter]: Object.assign({}, state.bookings[mFilter], {
            error: null,
            loading: true,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_BOOKING_RESOURCES_SUCCESS: {
      const mFilter = payload?.filter;
      const mBookingBoard = state.bookings?.[mFilter];

      let list = payload?.isReset ? [] : mBookingBoard?.list || [];
      list = [...(list || []), ...getArrOrder(payload?.data)];

      return Object.assign({}, state, {
        bookingMap: Object.assign(
          {},
          state.bookingMap,
          arrToObjMap(payload?.data)
        ),
        bookings: Object.assign({}, state.bookings, {
          [mFilter]: Object.assign({}, mBookingBoard, {
            list: [...new Set(list)],
            meta: Object.assign({}, mBookingBoard?.meta, payload?.meta),
            loading: false,
          }),
        }),
        filter: Object.assign({}, state.filter, {}),
      });
    }

    case Actions.FETCH_ALL_BOOKING_RESOURCES_FAILURE: {
      const mFilter = payload?.filter;

      return Object.assign({}, state, {
        bookings: Object.assign({}, state.bookings, {
          [mFilter]: Object.assign({}, state.bookings[mFilter], {
            error: payload,
            loading: false,
          }),
        }),
      });
    }

    /** get all booking resource end */

    /** get & update one booking resource start */

    case Actions.FETCH_BOOKING_RESOURCES:
    case Actions.CHANGE_BOOKED_RESOURCE:
    case Actions.CHANGE_BOOKED_RESOURCE_APPROVE:
    case Actions.CHANGE_BOOKING_REQUEST:
    case Actions.UPDATE_BOOKING: {
      return Object.assign({}, state, {
        bookingMap: Object.assign({}, state.bookingMap, {
          [payload?._id]: Object.assign({}, state.bookingMap?.[payload?._id], {
            error: false,
            loading: true,
          }),
        }),
      });
    }

    case Actions.UPDATE_DRAG_BOOKING: {
      return Object.assign({}, state, {
        bookingMap: Object.assign({}, state.bookingMap, {
          [payload?.booking_id]: Object.assign(
            {},
            state.bookingMap?.[payload?.booking_id],
            {
              error: false,
              loading: true,
            }
          ),
        }),
      });
    }

    case Actions.FETCH_BOOKING_RESOURCES_SUCCESS:
    case Actions.CHANGE_BOOKED_RESOURCE_SUCCESS:
    case Actions.CHANGE_BOOKED_RESOURCE_APPROVE_SUCCESS:
    case Actions.CHANGE_BOOKING_REQUEST_SUCCESS:
    case Actions.UPDATE_BOOKING_SUCCESS: {
      const mBooking = payload?.data;
      if (!mBooking.members?.length) {
        mBooking.members = [
          {
            _id: mBooking.email,
            name: mBooking.name,
            email: mBooking.email,
            number: mBooking.number,
          },
        ];
      }

      return Object.assign({}, state, {
        bookingMap: Object.assign({}, state.bookingMap, {
          [payload?.data?._id]: mBooking,
        }),
      });
    }

    case Actions.FETCH_BOOKING_RESOURCES_FAILURE:
    case Actions.CHANGE_BOOKED_RESOURCE_FAILURE:
    case Actions.CHANGE_BOOKED_RESOURCE_APPROVE_FAILURE:
    case Actions.CHANGE_BOOKING_REQUEST_FAILURE:
    case Actions.UPDATE_BOOKING_FAILURE: {
      return Object.assign({}, state, {
        bookingMap: Object.assign({}, state.bookingMap, {
          [payload?._id]: Object.assign(
            {},
            payload?._id && state.bookingMap?.[payload?._id],
            {
              error: payload,
              loading: false,
            }
          ),
        }),
      });
    }

    /** get & update one booking resource end */

    /** pay charge booking resource start */

    case Actions.PAY_CHARGE: {
      return Object.assign({}, state, {
        bookingMap: Object.assign({}, state.bookingMap, {
          [payload?._id]: Object.assign({}, state.bookingMap?.[payload?._id], {
            error: null,
            processingPayment: true,
          }),
        }),
      });
    }

    case Actions.PAY_CHARGE_SUCCESS: {
      return Object.assign({}, state, {
        bookingMap: Object.assign({}, state.bookingMap, {
          [payload?._id]: Object.assign({}, state.bookingMap?.[payload?._id], {
            processingPayment: false,
          }),
        }),
      });
    }

    case Actions.PAY_CHARGE_FAILURE: {
      return Object.assign({}, state, {
        bookingMap: Object.assign({}, state.bookingMap, {
          [payload?._id]: Object.assign({}, state.bookingMap?.[payload?._id], {
            error: payload,
            processingPayment: false,
          }),
        }),
      });
    }

    /** pay charge booking resource end */

    /** cancel booked resource start */
    case Actions.CANCEL_BOOKING: {
      return Object.assign({}, state, {
        bookingMap: Object.assign({}, state.bookingMap, {
          [payload?._id]: Object.assign({}, state.bookingMap?.[payload?._id], {
            error: false,
            loading: true,
          }),
        }),
      });
    }

    case Actions.CANCEL_BOOKING_SUCCESS: {
      const bookingId = payload?.data?._id;
      return Object.assign({}, state, {
        bookingMap: Object.assign({}, state.bookingMap, {
          [bookingId]: Object.assign({}, state?.bookingMap?.[bookingId], {
            active: false,
            loading: false,
          }),
        }),
      });
    }

    case Actions.CANCEL_BOOKING_FAILURE: {
      return Object.assign({}, state, {
        bookingMap: Object.assign({}, state.bookingMap, {
          [payload?._id]: Object.assign(
            {},
            payload?._id && state.bookingMap?.[payload?._id],
            {
              error: payload,
              loading: false,
            }
          ),
        }),
      });
    }

    /** cancel booked resource end */

    /** book resource start */
    case Actions.BOOK_RESOURCE_WITHOUT_PAY:
    case Actions.BOOK_RESOURCE:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });

    case Actions.BOOK_RESOURCE_SUCCESS: {
      const mBooking = payload?.data;
      const mFilter = payload?.filter;

      const mBookingBoard = state.bookings?.[mFilter] || {};
      let list = [...(mBookingBoard?.list || [])];

      if (!list.includes(mBooking?._id)) {
        list = [mBooking?._id, ...list];
        if (!mBooking.members?.length) {
          mBooking.members = [
            {
              _id: mBooking.email,
              name: mBooking.name,
              email: mBooking.email,
              number: mBooking.number,
            },
          ];
        }

        return Object.assign({}, state, {
          bookingMap: Object.assign({}, state.bookingMap, {
            [mBooking?._id]: mBooking,
          }),
          bookings: Object.assign({}, state.bookings, {
            [mFilter]: Object.assign({}, mBookingBoard, {
              list: [...new Set(list)],
              meta: Object.assign({}, mBookingBoard?.meta, {
                totalCount: (mBookingBoard?.meta?.totalCount || 0) + 1,
              }),
            }),
          }),
          loading: false,
        });
      } else {
        return state;
      }
    }

    case Actions.BOOK_RESOURCE_FAILURE:
      return Object.assign({}, state, {
        error: payload,
        loading: false,
      });

    /** book resource end */

    /** get all booking resource start */
    case Actions.FETCH_ALL_AVAILABLE_RESOURCES: {
      delete payload?.offset;
      delete payload?.limit;
      const mFilter = JSON.stringify(Object.assign({}, payload));
      return Object.assign({}, state, {
        availableResources: Object.assign({}, state.availableResources, {
          [mFilter]: {
            error: null,
            loading: true,
          },
        }),
      });
    }

    case Actions.FETCH_ALL_AVAILABLE_RESOURCES_SUCCESS: {
      const mFilter = JSON.stringify(payload?.filter);

      return Object.assign({}, state, {
        availableResources: Object.assign({}, state.availableResources, {
          [mFilter]: Object.assign({}, state.availableResources[mFilter], {
            list: payload?.data,
            loading: false,
          }),
        }),
      });
    }

    case Actions.FETCH_ALL_AVAILABLE_RESOURCES_FAILURE: {
      const mFilter = JSON.stringify(payload?.filter);

      return Object.assign({}, state, {
        availableResources: Object.assign({}, state.availableResources, {
          [mFilter]: Object.assign({}, state.availableResources[mFilter], {
            list: [],
            error: payload,
            loading: false,
          }),
        }),
      });
    }

    /** get all booking resource end */

    default:
      return state;
  }
};

export default resourcesReducer;
