const Actions = {
  FETCH_DASHBOARD: "FETCH_DASHBOARD",
  FETCH_DASHBOARD_SUCCESS: "FETCH_DASHBOARD_SUCCESS",
  FETCH_DASHBOARD_FAILURE: "FETCH_DASHBOARD_FAILURE",

  FETCH_MEMBER_DASHBOARD: "FETCH_MEMBER_DASHBOARD",
  FETCH_MEMBER_DASHBOARD_SUCCESS: "FETCH_MEMBER_DASHBOARD_SUCCESS",
  FETCH_MEMBER_DASHBOARD_FAILURE: "FETCH_MEMBER_DASHBOARD_FAILURE",
};

export default Actions;
