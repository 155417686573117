import Actions from "./ReportActionTypes";
import { arrToObjMap, getArrOrder } from "../../utils/CommonFunctions";

const initialState = {
  reportsMap: {},
  reports: {},
};

const reportReducer = (state = initialState, action) => {
  let payload = Object.assign({}, action?.payload);

  switch (action.type) {
    /** fetch all reports start  */

    case Actions.FETCH_REPORTS: {
      delete payload?.offset;
      delete payload?.limit;
      const mFilter = JSON.stringify(Object.assign({}, payload));

      return Object.assign({}, state, {
        reports: Object.assign({}, state.reports, {
          [mFilter]: Object.assign({}, state?.reports?.[mFilter], {
            error: null,
            loading: true,
          }),
        }),
      });
    }

    case Actions.FETCH_REPORTS_SUCCESS: {
      const mFilter = payload?.filter;
      const mReportBoard = state.reports?.[mFilter];
      let list = mReportBoard?.list || [];
      list = [...getArrOrder(payload?.data), ...(list || [])];

      return Object.assign({}, state, {
        reportsMap: Object.assign(
          {},
          state.reportsMap,
          arrToObjMap(payload?.data)
        ),
        reports: Object.assign({}, state.reports, {
          [mFilter]: Object.assign({}, mReportBoard, {
            list: [...new Set(list)],
            meta: Object.assign({}, mReportBoard?.meta, payload?.meta),
            loading: false,
          }),
        }),
      });
    }

    case Actions.FETCH_REPORTS_FAILURE: {
      const mFilter = JSON.stringify(Object.assign({}, payload));

      return Object.assign({}, state, {
        reports: Object.assign({}, state.reports, {
          [mFilter]: Object.assign({}, state?.reports?.[mFilter], {
            error: payload,
            loading: false,
          }),
        }),
      });
    }

    /** fetch all reports end */

    default:
      return state;
  }
};

export default reportReducer;
