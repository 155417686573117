import { put, takeLatest, call } from "redux-saga/effects";
import { showNotification } from "../../utils/CommonFunctions";
import Actions from "./BillingActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const addCardRequest = (data) => {
  return axios({
    method: "POST",
    url: `${AppUrl.ATTACH_PAYMENT}`,
    data,
  });
};

const fetchAllCardRequest = () => {
  return axios({
    method: "GET",
    url: `${AppUrl.BILLING_DETAILS}`,
  });
};

const setDefaultCardRequest = (data) => {
  return axios({
    method: "POST",
    url: AppUrl.CHANGE_DEFAULT_PAYMENT,
    data,
  });
};

const deleteCardRequest = (data) => {
  return axios({
    method: "DELETE",
    url: `${AppUrl.PAYMENT_SOURCE}`,
    data,
  });
};

function* addCard(action) {
  try {
    let resources = yield call(addCardRequest, { src_id: action?.payload?.id });
    let _data = resources?.data;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: Actions.ADD_CARD_FAILURE, payload: _data });
    } else {
      action.payload.src_id = action?.payload?.id;
      yield put({ type: Actions.ADD_CARD_SUCCESS, payload: action?.payload });
      showNotification("success", _data?.meta?.message);
    }
  } catch (error) {
    console.log("Internal Server Error", error);
  }
}

function* fetchAllCard(action) {
  try {
    let resources = yield call(fetchAllCardRequest);
    let _data = resources?.data;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      yield put({
        type: Actions.FETCH_ALL_CARD_FAILURE,
        payload: _data,
      });
    } else {
      yield put({
        type: Actions.FETCH_ALL_CARD_SUCCESS,
        payload: _data.data,
      });
    }
  } catch (error) {
    console.log("Internal Server Error", error);
  }
}

function* setDefaultCard(action) {
  try {
    let resources = yield call(setDefaultCardRequest, action?.payload);
    let _data = resources?.data;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: Actions.SET_DEFAULT_CARD_FAILURE, payload: _data });
    } else {
      yield put({
        type: Actions.SET_DEFAULT_CARD_SUCCESS,
        payload: action?.payload,
      });
      showNotification("success", _data?.meta?.message);
    }
  } catch (error) {
    console.log("Internal Server Error", error);
  }
}

function* deleteCard(action) {
  try {
    let resources = yield call(deleteCardRequest, action.payload);
    let _data = resources?.data;
    _data.data.src_id = action?.payload?.src_id;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: Actions.DELETE_CARD_FAILURE,
        payload: _data,
      });
    } else {
      yield put({
        type: Actions.DELETE_CARD_SUCCESS,
        payload: _data.data,
      });
      showNotification("success", _data?.meta?.message);
    }
  } catch (error) {
    console.log("Internal Server Error", error);
  }
}

// ACTION WATCHER
export default function* billingSaga() {
  yield takeLatest(Actions.ADD_CARD, addCard);
  yield takeLatest(Actions.FETCH_ALL_CARD, fetchAllCard);
  yield takeLatest(Actions.SET_DEFAULT_CARD, setDefaultCard);
  yield takeLatest(Actions.DELETE_CARD, deleteCard);
}
