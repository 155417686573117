import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { Row, Col, Input, Upload, Checkbox, Button, Select } from "antd";
import {
  ArrowLeftOutlined,
  EyeOutlined,
  UploadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { createCMS, getAllCMS, updateCMS } from "../duck/CMSActions";
import {
  showNotification,
  resourceTypes,
  checkPermission,
} from "../../utils/CommonFunctions";
import axios from "axios";
import AppUrl from "../../config/AppUrl";
import Spinner from "../../spinner/Spinner";
import Routes from "../../config/Routes";
import AddKnowMoreModal from "./AddKnowMoreModal";

const knowMoreTypes = {
  heading: {
    _id: "heading",
    name: "Heading",
  },
  subHeading: {
    _id: "subHeading",
    name: "Sub Heading",
  },
  content: {
    _id: "content",
    name: "Content",
  },
};

const EditSliderPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const preProps = useRef();
  const sliderId = useParams()?.id;

  const cms = useSelector((state) => state?.cms);
  const isCMSEditable = useMemo(() => checkPermission({ row: "cms" }), []);
  const ExtraIcon = useMemo(
    () => (isCMSEditable ? EditOutlined : EyeOutlined),
    [isCMSEditable]
  );

  const mCms = useMemo(
    () =>
      sliderId
        ? cms?.cmsMap?.[sliderId]
        : { loading: cms?.loading, error: cms?.error },
    [sliderId, cms]
  );
  const [err, handleErr] = useState({});
  const [fileList, setFileList] = useState([]);
  const [knowMore, setKnowMore] = useState([]);

  const [state, setState] = useState({});

  const [sliderObj, setSliderObj] = useState({
    resource_type: null,
    description: "",
    price: "",
    name: "",
    tag: "",
    active: true,
  });

  const preload = useCallback(() => {
    let tag = "";
    if (mCms?.pictures?.length) {
      const picture = { ...mCms?.pictures?.[0] };
      const picObj = {
        _id: picture?._id,
        name: picture?.filename,
        size: picture?.size,
        type: picture?.mimetype,
        url: picture?.thumb_location,
      };
      tag = picture?.tag || "";
      setFileList([picObj]);
    } else {
      setFileList([]);
    }
    handleErr({});
    setSliderObj({
      resource_type: mCms?.meta?.resource_type,
      name: mCms?.name,
      price: mCms?.price,
      description: mCms?.description,
      tag: tag,
      active: mCms?.active,
    });
    setKnowMore(mCms?.meta?.knowMore ?? []);
  }, [
    mCms?.active,
    mCms?.description,
    mCms?.meta?.knowMore,
    mCms?.meta?.resource_type,
    mCms?.name,
    mCms?.pictures,
    mCms?.price,
  ]);

  useEffect(() => {
    if (sliderId && !mCms) dispatch(getAllCMS({ type: "Slider" }));

    if (preProps?.current?.loading && !mCms?.loading) {
      history.replace(Routes.slider);
    }
    if (mCms && !mCms?.loading) preload();

    return () => {
      preProps.current = { loading: mCms?.loading };
    };
  }, [sliderId, mCms, dispatch, history, preload]);

  const beforeUpload = (file) => {
    const supportedFileType = ["image/png", "image/jpg", "image/jpeg"];

    let isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification(
        "error",
        "Please upload JEPG, JGP, PNG or GIF file only"
      );
    }
    return isAcceptable || Upload.LIST_IGNORE;
  };

  const updateImageUpload = useCallback(({ file }) => {
    var img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = function () {
      var width = img.naturalWidth,
        height = img.naturalHeight;

      URL.revokeObjectURL(img.src);

      if (width == 492 && height == 600) {
        const fileObj = {
          uid: file?.uid,
          name: file?.name,
          size: file?.size,
          type: file?.type,
          url: URL.createObjectURL(file),
          file,
        };

        handleErr({});
        setFileList([fileObj]);
      } else {
        showNotification("error", "Please upload exact image size of 492x600");
      }
    };
  }, []);

  const onRemoveImage = (file) => {
    setFileList([]);
  };
  const handleKnowMore = useCallback(
    (knowMoreObj) => {
      let { index, ...rest } = knowMoreObj;
      if (index !== undefined) {
        setKnowMore(knowMore.map((obj, i) => (i === index ? rest : obj)));
      } else {
        setKnowMore((preState) => [...preState, knowMoreObj]);
      }
    },
    [knowMore]
  );
  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e?.target?.checked ?? e;

      setSliderObj((preState) => ({ ...preState, [name]: value }));
      handleErr({});
    },
    []
  );
  const handleRemove = useCallback(
    (index) => {
      setKnowMore(knowMore.filter((obj, i) => i !== index));
    },
    [knowMore]
  );

  const handleModal = useCallback((show = false, selectedObj) => {
    show = typeof show === "boolean" && show;

    setState((preState) => ({ ...preState, showModal: show, selectedObj }));
  }, []);

  let { resource_type, description, price, name, tag, active } = sliderObj;

  const hasError = useCallback(() => {
    let { resource_type, name, description } = sliderObj;
    resource_type =
      sliderObj?.resource_type && sliderObj?.resource_type?.trim?.();

    let error = {};
    if (!resource_type) {
      error.resource_type = "Resource type cannot be blank";
    }
    if (!description) {
      error.description = "Description cannot be blank";
    }

    if (!fileList?.length) {
      error.fileList = "Image required";
    }
    if (resource_type === "other" && !name) {
      error.name = "Please specify a name";
    }

    handleErr(error);
    return !!Object.keys(error).length;
  }, [sliderObj, fileList]);

  const add = useCallback(() => {
    if (!hasError()) {
      let { resource_type, description, price, name, tag, active } = sliderObj;
      const mTag = tag?.trim?.();

      const payload = {
        type: "Slider",
        description,
        price,
        active,
        file: fileList?.[0]?.file,
        tags: JSON.stringify([mTag]),
        meta: JSON.stringify({
          resource_type,
          knowMore,
        }),
      };
      if (resource_type === "other" && name) payload.name = name;
      if (sliderId) {
        payload._id = sliderId;
        const oldFileListIds = mCms?.pictures?.map?.(
          (file) => file?._id || file?.uid
        );
        const currentUploadedFileListIds = fileList
          ?.filter?.((file) => !file?.file && file?._id)
          ?.map((file) => file?._id || file?.uid);
        const removedUploadedFileIds = oldFileListIds?.filter?.(
          (id) => !currentUploadedFileListIds?.includes(id)
        );

        removedUploadedFileIds.forEach((id) => {
          axios({
            method: "DELETE",
            url: `${AppUrl.ATTACHMENTS}/${id}`,
          });
        });

        fileList.forEach((file) => {
          const mPicture = mCms?.pictures?.[0];

          if (file?._id && file?._id === mPicture?._id) {
            if (mTag !== mPicture?.tag) {
              const data = { tag: mTag };

              axios({
                method: "PUT",
                url: `${AppUrl.ATTACHMENTS}/${file?._id}`,
                data,
              });
            }
          }
        });
        dispatch(updateCMS(payload));
      } else {
        dispatch(createCMS(payload));
      }
    }
  }, [
    hasError,
    sliderObj,
    fileList,
    knowMore,
    sliderId,
    mCms?.pictures,
    dispatch,
  ]);

  const disabled = !isCMSEditable || mCms?.loading;

  return sliderId && !mCms ? (
    <Spinner />
  ) : (
    <div className="mainMember">
      <Row align="middle" justify="space-between" className="mb20">
        <span>
          <h2 style={{ fontSize: 22, fontWeight: 600, marginBottom: 0 }}>
            {sliderId ? "Edit Slider" : "Add Slider"}
          </h2>
        </span>
        <span>
          {isCMSEditable && (
            <Button type="primary" loading={mCms?.loading} onClick={add}>
              Save Slider
            </Button>
          )}

          <Button
            className="ml10"
            disabled={mCms?.loading}
            onClick={() => history.replace(Routes.slider)}
          >
            <ArrowLeftOutlined />
            Back
          </Button>
        </span>
      </Row>
      <div className="mainMember-content">
        <Col>
          <Row className="mb15">
            <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
              <label className="label mt10">Resource</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col span={8} xs={24} sm={24} lg={18} xl={14} xxl={10}>
              <Select
                className="custom-select fw"
                placeholder={"Select Resource Type"}
                value={resource_type}
                onChange={handleChange("resource_type")}
                disabled={disabled}
                allowClear
              >
                {Object.values(resourceTypes).map((resourceType) => (
                  <Select.Option
                    key={resourceType?._id}
                    value={resourceType?._id}
                  >
                    {resourceType?.name}
                  </Select.Option>
                ))}
                <Select.Option key="other" value="other">
                  Other
                </Select.Option>
              </Select>
              <Row className="error mt5">{err?.resource_type}</Row>
            </Col>
          </Row>

          {/* name start */}
          {resource_type === "other" && (
            <Row align="middle">
              <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
                <label className="label">Name</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>
              <Col span={5}>
                <Input
                  className="custom-input fw mt10"
                  placeholder={"Name"}
                  size="large"
                  allowClear
                  value={name}
                  onChange={handleChange("name")}
                  disabled={disabled}
                />
                <Row className="error mt5">{err?.name}</Row>
              </Col>
            </Row>
          )}
          {/* name end */}

          {/* Price start */}
          {resource_type !== "other" && (
            <Row align="middle" className="mt10">
              <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
                <label className="label">Price</label>
              </Col>

              <Col span={8} xs={24} sm={24} lg={18} xl={14} xxl={10}>
                <Input
                  className="custom-input fw"
                  placeholder="Price from"
                  size="large"
                  // type="number"
                  // min={0}
                  // style={{ width: 150 }}
                  value={price}
                  disabled={disabled}
                  onChange={handleChange("price")}
                />
                {/* <span style={{ color: "darkgray" }}>
                  &nbsp; {resource_type === "meeting_room" ? "/hour" : "pcm"}
                </span> */}
              </Col>
            </Row>
          )}
          {/* Price end */}

          {/* Description start */}
          <Row align="top" className="mt10">
            <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
              <label className="label">Description</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col span={8} xs={24} sm={24} lg={18} xl={14} xxl={10}>
              <Input.TextArea
                className="custom-input mt10"
                placeholder="Description"
                autoSize={{ minRows: 5, maxRows: 8 }}
                allowClear
                value={description}
                disabled={disabled}
                onChange={handleChange("description")}
              />
              <Row className="error mt5">{err?.description}</Row>
            </Col>
          </Row>
          {/* Description end */}

          {/* Image Upload Start */}
          <Row align="top" className="mt10">
            <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
              <label className="label">
                Image <small>(492x600)</small>
              </label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col span={8} xs={24} sm={24} lg={18} xl={14} xxl={10}>
              <Upload
                accept={".jpeg, .jpg, .png"}
                beforeUpload={beforeUpload}
                listType="picture-card"
                fileList={fileList}
                onRemove={onRemoveImage}
                customRequest={updateImageUpload}
                disabled={disabled}
              >
                {fileList?.length < 1 && (
                  <div>
                    <UploadOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                    <div style={{ marginTop: 5 }}>
                      492x600<sup style={{ color: "red" }}>*</sup>
                    </div>
                  </div>
                )}
              </Upload>
              <Row className="error mt5">{err?.fileList}</Row>
            </Col>
          </Row>
          {/* Image Upload End */}

          {/* Tag start */}
          <Row align="top" className="mt10">
            <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
              <label className="label">Tag</label>
            </Col>
            <Col span={8} xs={24} sm={24} lg={18} xl={14} xxl={10}>
              <Input
                className="custom-input"
                placeholder="Please enter tag"
                allowClear
                value={tag}
                disabled={disabled}
                onChange={handleChange("tag")}
              />
            </Col>
          </Row>
          {/* Tag end */}

          {/* Active start */}
          {sliderId && (
            <Row align="top" className="mt10">
              <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
                <label className="label">Status</label>
              </Col>
              <Col span={6} className="mt10">
                <Checkbox
                  checked={active}
                  disabled={disabled}
                  onChange={handleChange("active")}
                >
                  Active
                </Checkbox>
              </Col>
            </Row>
          )}
          {/* Active End */}

          {/* Know More Start */}
          {/* <Row align="middle" className="mt20">
            <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
              <label className="label">Know More</label>
            </Col>
            <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4} className="mb20">
              <Button type="primary-outline" onClick={() => handleModal(true)}>
                + Add Content Type
              </Button>
            </Col>
          </Row>

          {knowMore?.map((item, index) => (
            <Row align="middle" className="mb10" key={index}>
              <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4} />
              <Col style={{ width: "40%" }}>
                <Row className="mb10">
                  <Col span={5}>
                    <label className="label">Type</label>
                  </Col>
                  <Col span={6}>
                    <Tag>{knowMoreTypes?.[item?.type]?.name}</Tag>
                  </Col>
                </Row>
                <Row>
                  <Col span={5}>
                    <label className="label">Description</label>
                  </Col>
                  <Col span={13}>
                    <Paragraph ellipsis={{ rows: 2 }}>{item?.value}</Paragraph>
                  </Col>
                  {isCMSEditable && (
                    <Tooltip title="Delete">
                      <Popconfirm
                        title="Are you sure to delete this?"
                        onConfirm={() => handleRemove(index)}
                        okText="Yes"
                        cancelText="No"
                        placement="bottom"
                        disabled={disabled}
                      >
                        <DeleteOutlined
                          style={{
                            fontSize: "17px",
                            cursor: "pointer",
                            margin: " 0 20px",
                          }}
                        />
                      </Popconfirm>
                    </Tooltip>
                  )}
                  <Tooltip title="Edit">
                    <ExtraIcon
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleModal(true, { ...item, index })}
                    />
                  </Tooltip>
                </Row>
                <hr />
              </Col>
            </Row>
          ))} */}
          {/* Know More End */}
        </Col>
      </div>
      <AddKnowMoreModal
        visible={state?.showModal}
        selectedObj={state?.selectedObj}
        showModal={handleModal}
        isCMSEditable={isCMSEditable}
        knowMoreTypes={knowMoreTypes}
        handleKnowMore={handleKnowMore}
      />
    </div>
  );
};

export default EditSliderPage;
