import Actions from "./AmenitiesActionTypes"

const initialState = {
    amenities:[],
    loading: false,
    error: null
}

const amenitiesReducer = (state = initialState, action) => {

    switch(action.type) {

        case Actions.DELETE_AMENITY : 
        case Actions.UPDATE_AMENITY : 
        case Actions.FETCH_ALL_AMENITIES : {
            return Object.assign({}, state, {
                loading: true,
                error: null
            })
        }

        case Actions.FETCH_ALL_AMENITIES_SUCCESS : {
            return Object.assign({}, state, {
                amenities: action?.payload,
                loading: false
            })
        }

        case Actions.CREATE_AMENITY_SUCCESS : {
            return Object.assign({}, state, {
                amenities: [...state?.amenities, action?.payload],
                loading: false
            })
        }
        
        case Actions.UPDATE_AMENITY_SUCCESS : {
            const index = state?.amenities?.findIndex(res => res?._id === action?.payload?._id)
            if(index >= 0){
                state?.amenities?.splice(index, 1, action?.payload)
            }
            return Object.assign({}, state, {
                amenities: [...state?.amenities],
                loading: false
            })
        }
        
        case Actions.DELETE_AMENITY_SUCCESS : {
            return Object.assign({}, state, {
                amenities: state?.amenities.filter(res => res?._id !== action.payload?._id),
                loading: false
            })
        }

        case Actions.DELETE_AMENITY_FAILURE :
        case Actions.UPDATE_AMENITY_FAILURE :
        case Actions.FETCH_ALL_AMENITIES_FAILURE : {
            return Object.assign({}, state, {
                loading: false,
                error: action?.payload
            })
        }

        default: return state;
    }

}

export default amenitiesReducer