import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Row,
  Button,
  Table,
  Card,
  Col,
  DatePicker,
  Select,
  Typography,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import moment from "moment";

import AddSeat from "./AddSeat";

import { getResource } from "../duck/ResourceActions";

import { deskListItemsColumns } from "./DeskListItemsColumns";

import {
  checkPermission,
  disabledDate,
  deskTypeCount,
  resourceTypes,
} from "../../utils/CommonFunctions";
import MTable from "../../utils/MTable";
const { Paragraph } = Typography;

const DeskListItems = () => {
  const DESK_TYPE = {
    dedicated_desk: "Dedicated Desk",
    flexi_desk: "Flexi Desk",
  };
  Object.freeze(DESK_TYPE);
  const editable = useMemo(
    () =>
      checkPermission({
        row: "resources",
        subRow: "desk",
      }),
    []
  );
  const [visible, showModal] = useState(false);
  const [selectedSeat, selectSeat] = useState(null);
  const [state, setState] = useState({
    date: moment(),
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const deskId = useParams()?.id;
  const desk = useSelector((state) => state?.resources?.resourceMap?.[deskId]);
  const deskItemsMap = useSelector((state) => state?.resources?.deskItemsMap);
  const activeWorkSpace = useSelector((state) => state?.workSpace?.active);
  const price = useSelector(
    (state) => state?.workSpace?.workSpaceMap?.[activeWorkSpace]?.price
  );

  useEffect(() => {
    dispatch(getResource({ _id: deskId }));
  }, [deskId]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    []
  );
  const toggleModal = useCallback((val) => {
    showModal(val);
    selectSeat(null);
  }, []);

  if (activeWorkSpace !== (desk?.space_id?._id || desk?.space_id)) {
    return null;
  }

  let data = [];
  desk?.items?.forEach((item, index) => {
    const mDesk = deskItemsMap?.[item?._id];
    if (mDesk?.status && mDesk?.active) {
      const today = state?.date;
      const isBooked =
        mDesk?.booking_slot?.length > 0 &&
        mDesk?.booking_slot?.find((bs) =>
          today.isBetween(
            moment(bs?.from).startOf("day"),
            moment(bs?.to).endOf("day")
          )
        );

      data.push({
        key: item?._id,
        serial: index + 1,
        seat: mDesk?.seat_no,
        type: DESK_TYPE?.[mDesk?.desk_type],
        status: (
          <Row align="middle">
            <span
              className="mr10"
              style={{
                width: 12,
                height: 12,
                backgroundColor: isBooked ? "rgb(54, 162, 235)" : "#74BA58",
                borderRadius: "50%",
              }}
            ></span>
            <span>{isBooked ? "Booked" : "Available"}</span>
          </Row>
        ),
        action: item?._id,
      });
    }
  });

  if (state?.desk_type)
    data = data?.filter((obj) => obj?.type === state?.desk_type);

  return (
    <div>
      <Row justify="space-between" align="top" className="mb10">
        <Col span={18}>
          <div className="nw_heading">{desk?.name} &nbsp;</div>
          {desk?.description && (
            <div className="mb5" style={{ fontWeight: 500 }}>
              <Paragraph ellipsis={{ rows: 2, expandable: true }}>
                {desk?.description}
              </Paragraph>
            </div>
          )}
        </Col>
        <Col>
          {editable && (
            <Button
              type="primary"
              className="mr10"
              onClick={() => showModal(true)}
            >
              + Add Seat
            </Button>
          )}
          <Button onClick={() => history.goBack()}>
            <ArrowLeftOutlined />
            Back
          </Button>
        </Col>
      </Row>
      <Row justify="start">
        {desk &&
          deskTypeCount(desk?.items, state?.date).map((val, index) => {
            const deskId = Object.keys(val)?.[0];
            const desk_type = resourceTypes?.[deskId]?.name;

            if (!val?.[deskId]?.total) return <div key={index}></div>;

            return (
              <Col span={8} key={index}>
                <Card
                  key={index}
                  bordered={false}
                  style={{
                    width: "95%",
                    margin: "10px 20px 10px 10px",
                    borderRadius: 5,
                  }}
                  hoverable
                >
                  <Row key={index}>
                    <div style={{ width: "40%" }}>
                      <p style={{ fontSize: 18, fontWeight: 500 }}>
                        {desk_type}
                      </p>
                      <p>{`Cost: £ ${price?.[deskId]}/day`}</p>
                      <p>Total seats: {val?.[deskId]?.total}</p>
                    </div>
                    <div style={{ width: "60%" }}>
                      <Doughnut
                        data={{
                          labels: ["Available", "Booked"],
                          datasets: [
                            {
                              data: [
                                val?.[deskId]?.total - val[deskId]?.booked,
                                val?.[deskId]?.booked,
                              ],
                              backgroundColor: ["#74BA58", "rgb(54, 162, 235)"],
                              hoverOffset: 4,
                            },
                          ],
                        }}
                        options={{
                          plugins: {
                            legend: {
                              display: false,
                              position: "bottom",
                            },
                          },
                          maintainAspectRatio: false,
                          responsive: true,
                        }}
                      />
                    </div>
                  </Row>
                </Card>
              </Col>
            );
          })}
      </Row>

      <Card title="Filter" size="small" bordered={false}>
        <DatePicker
          style={{
            width: "17%",
            fontWeight: "500",
            marginRight: "15px",
          }}
          onChange={handleChange("date")}
          placeholder="Date"
          value={state?.date}
          disabledDate={disabledDate()}
          allowClear={false}
        />

        <Select
          className="mr5"
          style={{
            paddingTop: "5px",
            width: "15%",
            fontWeight: "500",
            marginRight: "15px",
          }}
          placeholder={"Desk Type"}
          value={state?.desk_type}
          onChange={handleChange("desk_type")}
          allowClear
        >
          {Object.values(DESK_TYPE).map((desk_type) => (
            <Select.Option key={desk_type} value={desk_type}>
              {desk_type}
            </Select.Option>
          ))}
        </Select>

        <Button
          className="mr10"
          type="primary-outline"
          onClick={() => {
            setState({ date: moment() });
          }}
        >
          Reset
        </Button>
      </Card>

      <MTable
        columns={deskListItemsColumns(
          dispatch,
          showModal,
          selectSeat,
          editable,
          deskItemsMap
        )}
        bordered={false}
        dataSource={data}
        className="mt20"
      />

      <AddSeat
        visible={visible}
        showModal={toggleModal}
        selectedSeat={selectedSeat}
      />
    </div>
  );
};

export default DeskListItems;
