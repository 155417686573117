import Actions from "./ContactsActionTypes";

export const getAllContacts = (params = {}) => {
  return {
    type: Actions.FETCH_ALL_CONTACTS,
    payload: params,
  };
};
export const deleteContactLead = (id) => {
  return {
    type: Actions.DELETE_CONTACT,
    payload: id,
  };
};

export const addContact = (payload = {}) => {
  return {
    type: Actions.ADD_CONTACT,
    payload,
  };
};

export const adminAcknowledgeContact = (payload = {}) => {
  return {
    type: Actions.ADMIN_ACKNOWLEDGE_CONTACT,
    payload,
  };
};
