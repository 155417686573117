import Actions from "./LoginActionTypes";

export const loginRequest = (params = {}) => {
  return {
    type: Actions.LOGIN_REQUEST,
    payload: params,
  };
};

export const signupRequest = (params = {}) => {
  return {
    type: Actions.SIGNUP_REQUEST,
    payload: params,
  };
};

export const getLoggedInUserRequest = (id) => {
  return {
    type: Actions.GET_LOGGED_IN_USER_REQUEST,
    payload: id,
  };
};

export const updateLoggedInUserRequest = (params) => {
  return {
    type: Actions.UPDATE_LOGGED_IN_USER_REQUEST,
    payload: params,
  };
};

export const updatePassword = (params = {}) => ({
  type: Actions.UPDATE_PASSWORD,
  payload: params,
});
