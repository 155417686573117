import Actions from "./AdminActionTypes";

export const getAllAdmins = (params = {}) => {
  return {
    type: Actions.FETCH_ALL_ADMINS,
    payload: params,
  };
};

export const addAdmin = (params = {}) => {
  return {
    type: Actions.ADD_ADMIN,
    payload: params,
  };
};

export const editAdmin = (params = {}) => {
  return {
    type: Actions.UPDATE_ADMIN,
    payload: params,
  };
};
