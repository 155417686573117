import React, { memo, useCallback, useEffect } from "react";
import {
  Row,
  Layout,
  Avatar,
  Tag,
  Menu,
  Dropdown,
  Col,
  Typography,
  Button,
} from "antd";
import { Switch } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PoweroffOutlined,
  FormOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  getNameInitials,
  getAvatarColor,
  checkPermission,
  getUserWorkspace,
} from "../../utils/CommonFunctions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setActiveWorkSpace } from "../../workspace/duck/WorkSpaceActions";
import Routes from "../../config/Routes";
import appUrl from "../../config/AppUrl";

const AppHeader = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.loggedInUser);
  const workSpace = useSelector((state) => state?.workSpace);
  let showAllBooking = checkPermission({ initial: "showAllBooking" });
  let allowedWorkSpace = getUserWorkspace();
  const showCMS = checkPermission({ row: "cms", type: "show" });

  useEffect(() => {
    if (
      allowedWorkSpace?.length &&
      !allowedWorkSpace?.includes(workSpace?.active)
    ) {
      dispatch(setActiveWorkSpace(allowedWorkSpace?.[0]));
    }
  }, [allowedWorkSpace]);

  const handleChange = (name) => (value) => {
    props.toggleCMSSidebar();
    if (value) {
      history.push(Routes.slider);
    } else {
      history.push(Routes.dashboard);
    }
  };

  const onBookNow = useCallback(() => {
    if (workSpace?.active) {
      window.location = `${appUrl.WEBSITE_URL}${Routes.homeSearch}?spaceId=${workSpace?.active}`;
    }
  }, [workSpace?.active]);

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("cmsSidebar");
    dispatch({ type: "LOGOUT_SUCCESS" });
    history.push(Routes.login);
  };

  const profile = () => {
    history.push(Routes.profile);
  };

  const menu = (
    <Menu>
      <Menu.Item key="profile" onClick={profile}>
        <FormOutlined />
        Profile
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" onClick={logout}>
        <PoweroffOutlined />
        Logout
      </Menu.Item>
    </Menu>
  );
  const imageUrl =
    user?.pictures?.[0]?.original || user?.pictures?.[0]?.thumb_location;

  const renderDropDown = () => {
    if (showAllBooking && allowedWorkSpace?.length) {
      return (
        <>
          <Dropdown
            arrow
            placement="bottomRight"
            overlay={() => (
              <Menu>
                {allowedWorkSpace?.map((id) => (
                  <Menu.Item
                    key={id}
                    onClick={() => dispatch(setActiveWorkSpace(id))}
                  >
                    {workSpace?.workSpaceMap?.[id]?.name}
                  </Menu.Item>
                ))}
              </Menu>
            )}
          >
            <Row
              align="middle"
              className="pointer"
              style={{ height: 53, marginTop: -13 }}
            >
              {workSpace?.active &&
                workSpace?.workSpaceMap?.[workSpace?.active]?.name}
              &nbsp; {<DownOutlined />}
            </Row>
          </Dropdown>
        </>
      );
    }
  };

  return (
    <Layout.Header className="mainHeader">
      <Row className="container" justify="space-between" align="middle">
        <Row align="middle">
          {React.createElement(
            props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "btn-slider-toggle trigger",
              onClick: props.toggleSidebar,
            }
          )}

          {React.createElement(MenuFoldOutlined, {
            className: "btn-drawer-toggle trigger",
            onClick: props.toggleDrawer,
          })}
          {renderDropDown()}
        </Row>

        <Row align="middle">
          {showCMS && (
            <Col>
              <Switch
                onChange={handleChange("cms")}
                checked={props.showCmsSidebarMenu}
              />
              <span className="ml10" style={{ fontSize: 15, fontWeight: 500 }}>
                Website CMS
              </span>
            </Col>
          )}

          <Col className="btn-book-now-container">
            <Button
              className="btn-book-now ml20"
              type="primary"
              onClick={onBookNow}
              size="large"
            >
              Book Now
            </Button>
          </Col>

          <Row style={{ padding: "0 24px" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Row align="middle" className="user-info-container pointer">
                <Avatar className="user-avatar mr10" size={40} src={imageUrl}>
                  {getNameInitials(user?.name || user?.email)}
                </Avatar>

                <Col className="user-info">
                  <Typography.Paragraph ellipsis className="name nm">
                    {user?.name || user?.email}
                  </Typography.Paragraph>

                  <Row className="role">{user?.role_id?.name}</Row>
                </Col>
              </Row>
            </Dropdown>
          </Row>
        </Row>
      </Row>
    </Layout.Header>
  );
};
export default memo(AppHeader);
