import React, { useEffect, useRef, useState } from 'react';
import { Col, Input, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import CustomModal from '../../utils/CustomModal';

import { changeBookedResource } from '../duck/BookingActions';

const RaiseSwapRequest = (props) => {
    const { selectedBookingId } = props;

    const preProps = useRef();
    const selectedBooking = useSelector(state => state.booking?.bookingMap?.[selectedBookingId]);

    const dispatch = useDispatch();
    const [state, setState] = useState({});

    const preload = () => {
        if (preProps?.current?.loading && !selectedBooking?.loading) {
            if (!selectedBooking?.error) {
                props?.handleModal?.();
            }
        } else if (!props?.visible) {
            setState({});
        }
    }

    useEffect(() => {
        preload();

        return () => {
            preProps.current = { loading: selectedBooking?.loading };
        }
    }, [props?.visible, selectedBooking]);

    const { customer_remark, customerRemarkError } = state;

    const onSubmit = () => {
        if (!customer_remark?.trim?.()) {
            setState(preState => ({
                ...preState,
                customerRemarkError: "Please enter remark"
            }));

            return;
        }

        let params = {
            _id: selectedBooking?._id,
            seat_swap_status: "Assigned",
            customer_remark,
        }

        dispatch(changeBookedResource(params));
        // props?.handleModal?.();
    }

    return (
        <CustomModal
            visible={props?.visible}
            title={"Raise Desk Swap Request"}
            onCancel={props?.handleModal}
            okButtonText={"Submit"}
            loading={selectedBooking?.loading}
            handleSuccess={onSubmit}
        >
            <Row align='top' className='mt10'>
                <Col span={6}>
                    <label className='label'>Remark</label>
                    <sup style={{color:'red'}}>*</sup>
                </Col>

                <Col span={18}>
                    <Input.TextArea
                        className='custom-input'
                        placeholder="Add remark"
                        autoSize={{ minRows: 2, maxRows: 5 }}
                        allowClear
                        value={customer_remark}
                        onChange={(e) => setState({ customer_remark: e?.target?.value, customerRemarkError: "" })}
                    />

                    <Row className='error mt5'>{customerRemarkError}</Row>
                </Col>
            </Row>
        </CustomModal>
    );
}

export default RaiseSwapRequest;
