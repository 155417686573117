import React from "react";
import TitleComponent from "./miniComponents/TitleComponent";
import CtaButonComponent from "./miniComponents/CtaButonComponent";
import DescriptionComponent from "./miniComponents/DescriptionComponent";
import ImageUploadComponent from "./miniComponents/ImageUploadComponent";
import ImageSize from "./miniComponents/ImageSize";

const DefaultComponent = (props) => {
  const {
    state,
    error,
    handleChange,
    fileList,
    setFileList,
    setError,
    disabled,
  } = props;

  return (
    <>
      {/* Title start */}
      <TitleComponent
        value={state?.title}
        handleChange={handleChange}
        disabled={disabled}
        // error={error?.title}
      />
      {/* Title end */}
      {/* Description start */}
      <DescriptionComponent
        value={state?.description}
        handleChange={handleChange}
        disabled={disabled}
      />
      {/* Description end */}
      {/* CTA start */}
      <CtaButonComponent
        value={state?.showCTAButton}
        ctaBtnText={state?.ctaBtnText}
        ctaBtnURL={state?.ctaBtnURL}
        handleChange={handleChange}
        error={error}
        disabled={disabled}
      />
      {/* CTA end */}
      {/* Image Upload start */}
      <ImageUploadComponent
        fileList={fileList}
        setFileList={setFileList}
        error={error?.fileList}
        setError={setError}
        disabled={disabled}
        imgWidth={state?.imageWidth}
        imgHeight={state?.imageHeight}
        limit={1}
        // imgWidth={492}
        // imgHeight={500}
      />
      {/* Image Upload end */}
      <ImageSize
        state={state}
        handleChange={handleChange}
        disabledInput={true}
      />
    </>
  );
};

export default DefaultComponent;
