import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Row, Button, Tag, Tooltip, Card } from "antd";
import { useSelector, useDispatch } from "react-redux";
import AddAdmin from "./components/AddAdmin";
import {
  EditOutlined,
  SearchOutlined,
  EyeOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Input } from "antd";
import { checkPermission } from "../utils/CommonFunctions";
import { useHistory } from "react-router-dom";
import { getAllAdmins } from "./duck/AdminActions";
import { adminColumns } from "./components/AdminColumns";
import Routes from "../config/Routes";
import MTable from "../utils/MTable";

const AdminAndTeams = () => {
  const dispatch = useDispatch();
  const prevProp = useRef();
  const history = useHistory();

  const allAdmins = useSelector((state) => state?.admins);
  const activeWorkspace = useSelector((state) => state?.workSpace?.active);

  const isAdminAndTeamsEditable = useMemo(
    () =>
      checkPermission({
        row: "adminAndTeams",
      }),
    []
  );

  const [visible, showModal] = useState(false);
  const [selectedAdmin, selectAdmin] = useState(null);

  const [filter, setFilter] = useState({});
  const [boardKey, setBoardKey] = useState();

  const mAdminAndTeamsBoard = useMemo(
    () => allAdmins.admins?.[boardKey] ?? {},
    [allAdmins.admins, boardKey]
  );

  const loadMore = useCallback(
    (offset = 0, limit = 30) => {
      const { meta, list, loading } = mAdminAndTeamsBoard;
      if (!loading && (offset === 0 || meta?.totalCount > list?.length)) {
        let params = { space_id: activeWorkspace };
        if (filter.text) params.text = filter.text;

        setBoardKey(JSON.stringify({ ...params }));

        params.offset = offset;
        params.limit = limit;
        dispatch(getAllAdmins(params));
      }
    },
    [activeWorkspace, mAdminAndTeamsBoard, filter, dispatch]
  );

  const handleTableChange = useCallback(
    (pagination) => {
      const { current, pageSize } = pagination;
      const { list } = mAdminAndTeamsBoard;
      const endIndex = current * pageSize;

      if (endIndex > list?.length) {
        loadMore(list?.length, endIndex - list?.length);
      }
    },
    [mAdminAndTeamsBoard, loadMore]
  );
  const onSearch = () => loadMore();
  const onReset = () => {
    setFilter({});
    setBoardKey("");
  };

  useEffect(() => {
    if (prevProp?.current?.activeWorkspace !== activeWorkspace) {
      onReset();
    } else if (activeWorkspace && !Object.keys(mAdminAndTeamsBoard)?.length)
      loadMore();

    return () => {
      prevProp.current = { activeWorkspace };
    };
  }, [activeWorkspace, mAdminAndTeamsBoard]);

  const handleModal = useCallback(
    (show = false, id) => {
      show = typeof show === "boolean" && show;
      const mAdmin = show &&
        allAdmins?.adminMap?.[id] && { ...allAdmins?.adminMap?.[id] };

      showModal(show);
      selectAdmin(mAdmin);
    },
    [allAdmins?.adminMap]
  );

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setFilter((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );
  const admins = mAdminAndTeamsBoard?.list;

  const data = useMemo(() => {
    let mData = [];
    if (admins) {
      admins?.forEach((id, index) => {
        let mAdmin = allAdmins?.adminMap?.[id];
        mData.push({
          key: id,
          sn: ++index,
          name: mAdmin?.name,
          email: mAdmin?.email,
          role: mAdmin?.role_id?.name,
          status: mAdmin?.status ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          ),
          action: (
            <>
              {isAdminAndTeamsEditable ? (
                <EditOutlined onClick={() => handleModal(true, id)} />
              ) : (
                <EyeOutlined onClick={() => handleModal(true, id)} />
              )}
              <Tooltip placement="top" title={"Activities"}>
                <FileTextOutlined
                  className="ml10"
                  onClick={() => history.push(`${Routes.reports}/${id}`)}
                />
              </Tooltip>
            </>
          ),
        });
      });
    }

    return mData;
  }, [admins, allAdmins?.adminMap]);

  return (
    <div className="mainMember">
      <Row justify="space-between" align="middle" className="mb20">
        <span className="nw_heading">Admin & Teams</span>
        <Row>
          {isAdminAndTeamsEditable && (
            <Button type="primary-outline" onClick={() => handleModal(true)}>
              Invite
            </Button>
          )}
        </Row>
      </Row>
      <Card title="Filter" size="small" bordered={false}>
        <Input
          size="middle"
          className="custom-input mr10"
          style={{ width: "25%", fontWeight: "500", marginRight: "15px" }}
          placeholder="Search Name or Email"
          prefix={<SearchOutlined />}
          value={filter?.text}
          onChange={handleChange("text")}
          allowClear
        />

        <Button className="mr10" type="primary" onClick={onSearch}>
          Search
        </Button>
        <Button className="mr10" type="primary-outline" onClick={onReset}>
          Reset
        </Button>
      </Card>

      <MTable
        columns={adminColumns()}
        dataSource={data}
        className="mt20"
        bordered={false}
        loading={mAdminAndTeamsBoard?.loading}
        pagination={{
          total: mAdminAndTeamsBoard?.meta?.totalCount || 0,
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
      />

      <AddAdmin
        visible={visible}
        showModal={handleModal}
        selectedAdmin={selectedAdmin || null}
        selectAdmin={selectAdmin}
        editable={isAdminAndTeamsEditable}
      />
    </div>
  );
};

export default AdminAndTeams;
