import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCMS } from "../duck/CMSActions";
import { Col, Row } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

import AboutUsTabCard from "./AboutUsTabCard";

const Mselectableitem = (props) => {
  const dispatch = useDispatch();

  const { foldType, handleItems, items } = props;

  const [boardKey, setBoardKey] = useState();

  useEffect(() => {
    if (foldType) {
      const params = { type: foldType };
      setBoardKey(params);
      dispatch(getAllCMS(params));
    }
  }, [dispatch, foldType]);

  const cmsBoard = useSelector(
    (state) => state?.cms?.boards?.[JSON.stringify(boardKey)]
  );

  return (
    <Col span={24}>
      <Row>
        {cmsBoard?.list?.length === 0 ? (
          <Row className="error mt5">
            No testimonial exists.Please add some to proceed.
          </Row>
        ) : (
          cmsBoard?.list?.map((id, i) => (
            <Row
              key={id}
              style={{ position: "relative" }}
              onClick={() => handleItems(id)}
            >
              <AboutUsTabCard id={id} showExtra={false} />
              {items?.includes(id) && (
                <CheckCircleFilled
                  style={{
                    color: "green",
                    fontSize: 18,
                    position: "absolute",
                    top: 15,
                    right: 30,
                  }}
                />
              )}
            </Row>
          ))
        )}
      </Row>
    </Col>
  );
};

export default memo(Mselectableitem);
