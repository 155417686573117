import React, { useCallback, useEffect, useMemo, useState } from "react";
import TitleComponent from "./miniComponents/TitleComponent";
import DescriptionComponent from "./miniComponents/DescriptionComponent";
import CtaButonComponent from "./miniComponents/CtaButonComponent";
import {
  Button,
  Card,
  Col,
  Image,
  Popconfirm,
  Row,
  Tooltip,
  Typography,
} from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import FoldDataModal from "./miniComponents/FoldDataModal";
import ImageSize from "./miniComponents/ImageSize";

const { Paragraph } = Typography;

const ListComponent = (props) => {
  const {
    state,
    error,
    handleChange,
    fileList,
    setFileList,
    setError,
    isCMSEditable,
    disabled,
  } = props;

  const ExtraIcon = useMemo(
    () => (isCMSEditable ? EditOutlined : EyeOutlined),
    [isCMSEditable]
  );

  const [visible, setVisible] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [dragId, setDragId] = useState();

  const handleModal = useCallback((show = false, imgObj = null) => {
    show = typeof show === "boolean" && show;
    setVisible(show);
    setSelectedImg(imgObj);
  }, []);

  const handleData = useCallback(
    (file, action = null) => {
      setFileList((preState) => {
        let newState = [...preState, file];
        if (action && action === "update") {
          newState = preState.map((perFile) =>
            (perFile._id && perFile._id === file._id) ||
            perFile.uid === file.uid
              ? file
              : perFile
          );
        } else if (action && action === "replace") {
          newState = newState.filter(
            (file) => !file?.status || file?.status !== "removed"
          );
        }
        return newState;
      });
      setError({});
    },
    [setError, setFileList]
  );

  const onRemoveImage = useCallback(
    (file) => {
      const fileId = file?._id || file?.uid;
      setFileList((preState) =>
        preState
          .filter((p) => (p?._id || p?.uid) !== fileId)
          ?.map((file, i) => ({ ...file, extra: { ...file?.extra, order: i } }))
      );
    },
    [setFileList]
  );

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const dragBox = fileList.find((box) => {
      const id = box._id || box.uid;
      return id === dragId;
    });

    const dropBox = fileList.find((box) => {
      const id = box._id || box.uid;
      return id === ev.currentTarget.id;
    });

    const dragBoxOrder = dragBox?.extra?.order;
    const dropBoxOrder = dropBox?.extra?.order;
    const newBoxState = fileList.map((box) => {
      const id = box._id || box.uid;
      if (id === dragId) {
        box.extra.order = dropBoxOrder;
      }
      if (id === ev.currentTarget.id) {
        box.extra.order = dragBoxOrder;
      }
      return box;
    });
    setFileList(newBoxState);
  };

  return (
    <>
      <TitleComponent
        value={state?.title}
        handleChange={handleChange}
        error={error?.title}
        disabled={disabled}
      />

      <DescriptionComponent
        value={state?.description}
        handleChange={handleChange}
        disabled={disabled}
      />

      <CtaButonComponent
        value={state?.showCTAButton}
        ctaBtnText={state?.ctaBtnText}
        ctaBtnURL={state?.ctaBtnURL}
        handleChange={handleChange}
        error={error}
        disabled={disabled}
      />

      <Row align="top" className="mt20">
        <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}>
          <label className="label">Images</label>
          <sup style={{ color: "red" }}>*</sup>
        </Col>
        <Col span={8} xs={24} sm={24} lg={18} xl={18} xxl={20}>
          <Row className="error mt5">{error?.fileList}</Row>
          <Button type="primary-outline" onClick={() => handleModal(true)}>
            + Add Image
          </Button>
        </Col>
      </Row>
      <Row align="top" className="mt20">
        <Col span={24}>
          <ImageSize
            state={state}
            handleChange={handleChange}
            disabledInput={true}
          />
        </Col>
      </Row>

      <Row align="top">
        <Col span={4} xs={4} sm={4} lg={4} xl={4} xxl={4}></Col>
        <Col span={8} xs={24} sm={24} lg={18} xl={18} xxl={20}>
          <Col span={24}>
            {/*  ?.sort((a, b) => a?.extra?.order - b?.extra?.order)
                  onDragOver={(ev) => ev.preventDefault()}
                  onDragStart={handleDrag}
                  onDrop={handleDrop}
                  draggable={true}
            */}
            <Row>
              {fileList
                ?.sort((a, b) => a?.extra?.order - b?.extra?.order)
                ?.map((file) => (
                  <Card
                    onDragOver={(ev) => ev.preventDefault()}
                    onDragStart={handleDrag}
                    onDrop={handleDrop}
                    draggable={true}
                    id={file._id || file.uid}
                    key={file._id || file.uid}
                    style={{
                      width: 250,
                      margin: "10px",
                      border: "1px solid #ccc",
                    }}
                    title={
                      <span
                        style={{ fontSize: 12 }}
                        dangerouslySetInnerHTML={{
                          __html: file?.title,
                        }}
                      />
                    }
                    bordered={false}
                    hoverable
                    extra={
                      <>
                        {isCMSEditable && (
                          <Tooltip title="Delete">
                            <Popconfirm
                              title="Are you sure to delete this?"
                              onConfirm={() => onRemoveImage(file)}
                              okText="Yes"
                              cancelText="No"
                              placement="bottom"
                              disabled={disabled}
                            >
                              <DeleteOutlined
                                style={{
                                  fontSize: "17px",
                                  cursor: "pointer",
                                  margin: " 0 20px",
                                }}
                              />
                            </Popconfirm>
                          </Tooltip>
                        )}
                        <Tooltip
                          title={isCMSEditable ? "Edit" : "More Details"}
                        >
                          <ExtraIcon
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleModal(true, file)}
                          />
                        </Tooltip>
                      </>
                    }
                    cover={
                      <Image
                        className="cover new_image-fluid"
                        preview={false}
                        width={"100%"}
                        src={file?.thumb_location}
                        style={{
                          marginTop: "10px",
                          height: "150px",
                          marginBottom: "10px",
                        }}
                      />
                    }
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: file?.imgDescription,
                      }}
                    />
                  </Card>
                ))}
            </Row>
          </Col>
        </Col>
      </Row>
      <FoldDataModal
        state={state}
        title={"Carousel"}
        visible={visible}
        selectedImg={selectedImg}
        showModal={handleModal}
        isCMSEditable={isCMSEditable}
        handleData={handleData}
        disabled={disabled}
        totalImages={fileList?.length}
      />
    </>
  );
};

export default ListComponent;
