import React from "react";
import { Col, Row, Tag } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";

const PriceItem = (props) => {
  const { item, editable } = props;

  if (!item) {
    return <div />;
  }

  const onEdit = () => {
    props?.onEdit?.(true, item?._id);
  };

  return (
    <tr>
      <td className="spacerLR">
        <Row>
          <Col span={12} style={{ textAlign: "center" }}>
            {item?.min}%
          </Col>

          <Col span={12} style={{ textAlign: "center" }}>
            {item?.max}%
          </Col>
        </Row>
      </td>

      <td className="spacerLR" style={{ textAlign: "center" }}>
        <span>{item?.value}%</span>
      </td>

      <td className="spacerLR" style={{ textAlign: "center" }}>
        <span className="actionStatus">
          <Tag color={item?.status ? "green" : "red"}>
            {item?.status ? "Active" : "In-Active"}
          </Tag>
        </span>
      </td>

      <td className="spacerLR" style={{ textAlign: "center" }}>
        <span className="actionStatus">
          {editable ? (
            <EditOutlined onClick={onEdit} />
          ) : (
            <EyeOutlined onClick={onEdit} />
          )}
        </span>
      </td>
    </tr>
  );
};

export default PriceItem;
