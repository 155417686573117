import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import CustomModal from "../../utils/CustomModal";
import { Row, Col, Input, Select, Checkbox, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addMeetingRoom, updateMeetingRoom } from "../duck/ResourceActions";

const AddMeetingRoom = (props) => {
  const prevProp = useRef();

  const { selectedMeetingRoom, editable, visible, handleModal } = props;
  const modalTitle = useMemo(
    () =>
      editable
        ? selectedMeetingRoom
          ? "Edit Meeting Room"
          : "Add Meeting Room"
        : "Meeting Room",
    [editable, selectedMeetingRoom]
  );

  const workSpace = useSelector((state) => state?.workSpace);

  const { resources, resourceMap } = useSelector((state) => state?.resources);
  const { adding } = useMemo(
    () => resources?.[workSpace?.active] || {},
    [resources, workSpace?.active]
  );
  const mResource = useMemo(
    () => resourceMap?.[selectedMeetingRoom] || {},
    [resourceMap, selectedMeetingRoom]
  );
  const { loading } = mResource;

  const amenities = useSelector((state) => state?.amenities?.amenities);

  const dispatch = useDispatch();

  const [meetingObj, setMeetingObj] = useState({});
  const [error, handleError] = useState({});

  useEffect(() => {
    if (
      (prevProp?.current?.loading && !loading) ||
      (prevProp?.current?.adding && !adding)
    ) {
      handleModal();
    } else if (!prevProp?.current?.visible && visible) {
      let amenities =
        (Array.isArray(mResource?.amenities_id) &&
          mResource?.amenities_id
            ?.filter((a) => a && a?.active)
            ?.map((a) => a?._id)) ||
        [];

      setMeetingObj({
        name: mResource?.name || null,
        space_id:
          mResource?.space_id?._id || mResource?.space_id || workSpace?.active,
        price: mResource?.price || 0,
        capacity: mResource?.capacity || 0,
        description: mResource?.description || null,
        cancellation_notice: mResource?.cancellation_notice || 0,
        active: mResource?.active ?? true,
        color: mResource?.color || "",
        amenities_id: amenities,
      });
      handleError({});
    }
    return () => {
      prevProp.current = { loading, adding, visible };
    };
  }, [
    workSpace?.active,
    selectedMeetingRoom,
    loading,
    adding,
    visible,
    mResource,
    handleModal,
  ]);

  const hasError = useCallback(() => {
    let { name, space_id, price, capacity } = meetingObj;

    name = meetingObj?.name && meetingObj?.name?.trim?.();
    price = meetingObj?.price && Number(meetingObj?.price);
    capacity = meetingObj?.capacity && Number(meetingObj?.capacity);

    let error = {};

    if (!name) {
      error.name = "Meeting room name cannot be blank";
    }

    if (!space_id) {
      error.space_id = "Please select a location";
    }

    if (typeof price === "number" && price < 0) {
      error.price = "Price must be greater than or equal to 0";
    }

    if (!capacity) {
      error.capacity = "Capacity must be greater than 0";
    }

    handleError(error);
    return Object.keys(error).length;
  }, [meetingObj]);

  const add = useCallback(() => {
    if (!hasError()) {
      if (selectedMeetingRoom) {
        dispatch(
          updateMeetingRoom({ _id: selectedMeetingRoom, ...meetingObj })
        );
      } else {
        dispatch(addMeetingRoom(meetingObj));
      }
    }
  }, [selectedMeetingRoom, meetingObj, hasError, dispatch]);

  const handleChange = useCallback(
    (name, isCheckbox) => (e) => {
      let value = (isCheckbox ? e?.target?.checked : e?.target?.value) ?? e;
      if (["capacity"].includes(name)) {
        value = value.replace(/[^0-9]/g, "");
      }

      setMeetingObj((preState) => ({ ...preState, [name]: value }));
      handleError({});
    },
    []
  );

  const disabled = useMemo(
    () => adding || loading || !editable,
    [adding, loading, editable]
  );

  return (
    <CustomModal
      visible={visible}
      title={modalTitle}
      onCancel={handleModal}
      footer={
        <>
          <Button onClick={handleModal}>Close</Button>
          {editable && (
            <Button type="primary" onClick={add} loading={loading || adding}>
              {selectedMeetingRoom ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      <div>
        {/* name start */}
        <Row align="middle">
          <Col span={6}>
            <label className="label">Name</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18}>
            <Input
              className="custom-input"
              placeholder="Cockpit Alpha"
              size="large"
              autoFocus
              allowClear
              value={meetingObj?.name}
              disabled={disabled}
              onChange={handleChange("name")}
            />
            <Row className="error mt5">{error?.name}</Row>
          </Col>
        </Row>
        {/* name end */}

        {/* Location start */}
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Location</label>
            {!selectedMeetingRoom && <sup style={{ color: "red" }}>*</sup>}
          </Col>
          <Col span={18}>
            {workSpace?.workSpaceMap?.[meetingObj?.space_id]?.name}
          </Col>
        </Row>
        {/* Location end */}

        {/* Price start */}
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Price</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18}>
            <Input
              className="custom-input"
              placeholder="Per hour"
              size="large"
              type="number"
              min={0}
              step={0.1}
              style={{ width: 150 }}
              value={meetingObj?.price}
              disabled={disabled}
              onChange={handleChange("price")}
            />
            <span style={{ color: "darkgray" }}>&nbsp; £/hour</span>
            <Row className="error mt5">{error?.price}</Row>
          </Col>
        </Row>
        {/* Price end */}

        {/* Capacity start */}
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Capacity</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18}>
            <Input
              className="custom-input"
              placeholder="Capacity"
              size="large"
              type="number"
              min={0}
              style={{ width: 150 }}
              value={meetingObj?.capacity}
              disabled={disabled}
              onChange={handleChange("capacity")}
            />
            <span style={{ color: "darkgray" }}>&nbsp;people</span>
            <Row className="error mt5">{error?.capacity}</Row>
          </Col>
        </Row>
        {/* Capacity end */}

        {/* Description start */}
        <Row align="top" className="mt10">
          <Col span={6}>
            <label className="label">Description</label>
          </Col>
          <Col span={18}>
            <Input.TextArea
              className="custom-input"
              placeholder="Add room description"
              autoSize={{ minRows: 2, maxRows: 5 }}
              allowClear
              value={meetingObj?.description}
              disabled={disabled}
              onChange={handleChange("description")}
            />
          </Col>
        </Row>
        {/* Description end */}

        {/* Cancellation notice start */}
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Cancellation notice</label>
          </Col>
          <Col span={18}>
            <Input
              className="custom-input"
              placeholder="In hours"
              size="large"
              type="number"
              min={1}
              step={0.5}
              style={{ width: 150 }}
              value={meetingObj?.cancellation_notice}
              disabled={disabled}
              onChange={handleChange("cancellation_notice")}
            />
            <span style={{ color: "darkgray" }}>&nbsp;hour</span>
          </Col>
        </Row>
        {/* Cancellation notice end */}

        {/* Color start */}
        <Row align="top" className="mt10">
          <Col span={6}>
            <label className="label">Color</label>
          </Col>
          <Col span={18}>
            <Input
              type="color"
              style={{ width: 100 }}
              value={meetingObj?.color}
              disabled={disabled}
              onChange={handleChange("color")}
            />
          </Col>
        </Row>
        {/* Color end */}

        {/* Amenities start */}
        <Row align="middle" className="mt10">
          <Col span={6}>
            <label className="label">Amenities</label>
          </Col>
          <Col span={18}>
            <Select
              className="custom-select fw"
              placeholder="Select amenities"
              size="large"
              mode="multiple"
              allowClear
              value={meetingObj?.amenities_id}
              disabled={disabled}
              onChange={handleChange("amenities_id")}
            >
              {Array.isArray(amenities) &&
                amenities.map(
                  (amenity) =>
                    amenity?.active && (
                      <Select.Option key={amenity?._id} value={amenity?._id}>
                        {amenity?.title}
                      </Select.Option>
                    )
                )}
            </Select>
          </Col>
        </Row>
        {/* Amenities end */}

        {/* Active start */}
        <Row align="top" className="mt10">
          <Col span={6}></Col>
          <Col span={18}>
            <Checkbox
              checked={meetingObj?.active}
              disabled={disabled}
              onChange={handleChange("active", true)}
            >
              Meeting room status
            </Checkbox>
          </Col>
        </Row>
        {/* Active end */}
      </div>
    </CustomModal>
  );
};

export default AddMeetingRoom;
