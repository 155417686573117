import moment from "moment";
import { Col, Row, Tag } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

export const contactColumns = () => {
  const columns = [
    {
      title: "S.no",
      dataIndex: "sn",
      key: "sn",
      width: 10,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: ({ name, isNew }) => {
        return (
          <Col>
            {name}

            {isNew && <sup style={{ color: "red" }}> New</sup>}
          </Col>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 100,
      align: "center",
      render: (type) => {
        let mContactType;
        if (type === "contact_us") {
          mContactType = "Contact us";
        } else if (type === "book_tour") {
          mContactType = "Book tour";
        }

        return <Tag color="orange">{mContactType || "-"}</Tag>;
      },
    },
    {
      title: "Raised on",
      dataIndex: "created_at",
      key: "created_at",
      width: 250,
      render: (date) => {
        return (
          <Row justify="start" align="middle">
            {date && (
              <>
                <Col
                  className="--avatar green mr15 circle"
                  justify="center"
                  align="middle"
                >
                  <CalendarOutlined />
                </Col>
                <Col>
                  <h4 className="nm">
                    {moment(date).format("ddd, MMM Do, YYYY")}
                  </h4>
                </Col>
              </>
            )}
          </Row>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      align: "center",
    },
  ];
  return columns;
};
