import { Button, Col, Input, Row, Select } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";

import CustomModal from "../../utils/CustomModal";

import { createCMS, updateCMS } from "../duck/CMSActions";
import constants from "../../utils/Constants";
import { resourceTypes } from "../../utils/CommonFunctions";

const { CMS_TYPES } = constants;

const KnowMoreModal = (props) => {
  const prevProp = useRef();
  const dispatch = useDispatch();

  const { visible, selectedId, showModal, isCMSEditable } = props;

  const mCms = useSelector(({ cms }) =>
    selectedId
      ? cms.cmsMap[selectedId]
      : { loading: cms.loading, error: cms.error }
  );

  const [state, setState] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    if (prevProp?.current?.loading && !mCms?.loading) {
      setState({});
      showModal?.(false);
    } else if (!prevProp?.current?.visible && visible) {
      let mState = {};

      if (mCms) {
        const meta = mCms?.meta;

        mState = {
          name: mCms?.name,
          resource_type: meta?.resource_type,
          description: mCms?.description,
        };
      }

      setState(mState);
      setError({});
    }

    return () => {
      prevProp.current = {
        loading: mCms?.loading,
        visible,
      };
    };
  }, [mCms, visible, showModal]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      if (name === "slug" && typeof value === "string") {
        value = value.replace(" ", "-").toLowerCase();
      }

      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const hasError = useCallback(() => {
    let { name, resource_type } = state;

    const error = {};
    if (!resource_type) {
      error.resource_type = "Please select resource type";
    }

    if (resource_type === "other" && !name) {
      error.name = "Please specify a name";
    }

    setError(error);
    return Object.keys(error).length;
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      let { name, resource_type, description } = state;

      resource_type = resource_type?.trim();
      description = description?.trim();

      const meta = {
        resource_type,
      };

      const payload = {
        name: name,
        description,
        type: CMS_TYPES.KNOW_MORE,
        meta: JSON.stringify(meta),
      };

      if (selectedId) {
        payload._id = selectedId;
        dispatch(updateCMS(payload));
      } else {
        dispatch(createCMS(payload));
      }
    }
  }, [selectedId, state, hasError, dispatch]);

  const disabled = useMemo(
    () => !isCMSEditable || mCms?.loading,
    [isCMSEditable, mCms?.loading]
  );

  return (
    <CustomModal
      className="cms-modal"
      visible={visible}
      title={"Know More Details"}
      onCancel={props.showModal}
      footer={
        <>
          <Button onClick={props.showModal}>Close</Button>

          {isCMSEditable && (
            <Button type="primary" loading={mCms?.loading} onClick={onSubmit}>
              {selectedId ? "Update" : "Add"}
            </Button>
          )}
        </>
      }
    >
      <Col className="content">
        {/* resource type start */}
        <Row className="mb10" align="middle">
          <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
            <label className="label">Resource</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
            <Col span={18} xs={24} sm={24} lg={12} xl={12} xxl={12}>
              <Select
                className="custom-select fw"
                placeholder={"Select Resource Type"}
                value={state.resource_type}
                onChange={handleChange("resource_type")}
                disabled={disabled}
                allowClear
              >
                {Object.values(resourceTypes).map((resourceType) => (
                  <Select.Option
                    key={resourceType?._id}
                    value={resourceType?._id}
                  >
                    {resourceType?.name}
                  </Select.Option>
                ))}
                <Select.Option key="other" value="other">
                  Other
                </Select.Option>
              </Select>

              <Row className="error mt5">{error.resource_type}</Row>
            </Col>
          </Col>
        </Row>
        {/* resource type end */}

        {/* name start */}
        {state.resource_type === "other" && (
          <Row align="middle">
            <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
              <label className="label">Name</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
              <Col span={18} xs={24} sm={24} lg={12} xl={12} xxl={12}>
                <Input
                  className="custom-input fw mt10"
                  placeholder="Name"
                  size="large"
                  allowClear
                  value={state.name}
                  onChange={handleChange("name")}
                  disabled={disabled}
                />

                <Row className="error mt5">{error.name}</Row>
              </Col>
            </Col>
          </Row>
        )}
        {/* name end */}

        {/* content start */}
        <Row className="mt10">
          <Col span={6} xs={24} sm={24} lg={4} xl={4} xxl={4}>
            <label className="label">Content</label>
          </Col>

          <Col span={18} xs={24} sm={24} lg={20} xl={20} xxl={20}>
            <ReactQuill
              className="custom-input-editor"
              placeholder="Content"
              theme="snow"
              value={state.description}
              onChange={handleChange("description")}
            />

            <Row className="error mt5">{error.description}</Row>
          </Col>
        </Row>
        {/* content end */}
      </Col>
    </CustomModal>
  );
};

export default React.memo(KnowMoreModal);
