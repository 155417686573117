import React, { useMemo } from "react";
import { Card, Carousel, Image, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";

import { resourceTypes } from "../../utils/CommonFunctions";
import noImage from "../../assets/images/no-image.png";

const ImageGalleryCard = (props) => {
  const { id, isCMSEditable } = props;

  const mCms = useSelector((state) => state?.cms?.cmsMap?.[id]);

  const ExtraIcon = useMemo(
    () => (isCMSEditable ? EditOutlined : EyeOutlined),
    [isCMSEditable]
  );

  const resourceType = useMemo(
    () => resourceTypes[mCms?.meta?.resource_type],
    [mCms?.meta?.resource_type]
  );
  const title = useMemo(
    () => mCms?.name || resourceType?.name,
    [mCms, resourceType]
  );

  return (
    <Card
      style={{
        width: 300,
        margin: "10px 20px 10px 0",
        borderRadius: 5,
      }}
      title={title}
      bordered={false}
      hoverable
      extra={
        <Tooltip title={isCMSEditable ? "Edit" : "More Details"}>
          <ExtraIcon
            style={{ fontSize: 12 }}
            onClick={() => props?.showModal?.(true, id)}
          />
        </Tooltip>
      }
      cover={
        <Carousel autoplay>
          {mCms?.pictures?.length > 0 ? (
            mCms?.pictures?.map((picture, i) => (
              <Image
                className="cover new_image-fluid"
                key={i}
                width={"100%"}
                height={"100%"}
                preview={false}
                wrapperStyle={{ height: 150 }}
                src={picture?.thumb_location}
                alt={`${title}-${i + 1}`}
              />
            ))
          ) : (
            <Image
              className="cover new_image-fluid"
              preview={false}
              width={"100%"}
              height={"100%"}
              wrapperStyle={{ height: 150 }}
              src={noImage}
            />
          )}
        </Carousel>
      }
    >
      <strong>Last Update At: </strong>
      {moment().format("ddd, MMMM Do, YYYY")}
    </Card>
  );
};

export default ImageGalleryCard;
