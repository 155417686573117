import React from "react";
import { Row } from "antd";

import noDataFoundImg from "../assets/svg/no-data-found.svg";

const NoDataFound = (props) => {
  return (
    <Row align="middle" justify="center">
      <img src={noDataFoundImg} alt="NoDataFound" />
    </Row>
  );
};

export default NoDataFound;
