import { memo, useMemo } from "react";
import { Drawer, Layout, Row } from "antd";
import { useLocation } from "react-router-dom";

import CmsSideMenu from "./CmsSideMenu";
import AppSideMenu from "./AppSideMenu";

import logo from "../../assets/svg/logo.svg";
import logoFull from "../../assets/svg/logo_full.svg";

import Routes from "../../config/Routes";

const AppSidebar = (props) => {
  const location = useLocation();
  const _key = [
    Routes.meetingRooms,
    Routes.privateOffices,
    Routes.desk,
  ].includes(location.pathname);
  const _key1 = [Routes.calendar, Routes.booking].includes(location.pathname);

  const sideBarLogo = useMemo(
    () => (props?.collapsed ? logo : logoFull),
    [props?.collapsed]
  );

  return (
    <Layout.Sider
      width={250}
      trigger={null}
      collapsible
      collapsed={props?.collapsed}
      theme="dark"
      className="app-sidebar"
    >
      <Row align="middle" className="logo-container pl15 pt5 pb5">
        <img className="logo" alt="gobermondsey" src={sideBarLogo} />
      </Row>

      {props.showCmsSidebarMenu ? (
        <CmsSideMenu
          isCollapsed={props?.collapsed}
          selectedKeys={[location.pathname]}
        />
      ) : (
        <AppSideMenu
          isCollapsed={props?.collapsed}
          _key={_key}
          _key1={_key1}
          selectedKeys={[location.pathname]}
        />
      )}
    </Layout.Sider>
  );
};

export default memo(AppSidebar);
