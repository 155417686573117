import Actions from './AmenitiesActionTypes'

export const fetchAllAmenities = () => {
    return {
        type: Actions.FETCH_ALL_AMENITIES,
    }
}

export const createNewAmenity = (data) => {
    return {
        type: Actions.CREATE_AMENITY,
        payload: data
    }
}

export const updateAmenity = (data) => {
    return {
        type: Actions.UPDATE_AMENITY,
        payload: data
    }
}

export const deleteAmenity = (id) => {
    return {
        type: Actions.DELETE_AMENITY,
        payload: id
    }
}