import { put, takeLatest, call } from "redux-saga/effects";
import { showNotification } from "../../utils/CommonFunctions";
import ACTIONS from "./ResourceActionTypes";
import AppUrl from "../../config/AppUrl";
import axios from "axios";

const getAllResourcesRequest = (id) => {
  return axios({
    method: "GET",
    url: `${AppUrl.WORKSPACE}/${id}/resources`,
  });
};

const getResourceRequest = (params) => {
  return axios({
    method: "GET",
    url: `${AppUrl.RESOURCES}/${params?._id}`,
  });
};

const addMeetingRoomRequest = (data) => {
  return axios({
    method: "POST",
    url: `${AppUrl.RESOURCES}`,
    data: { ...data, type: "meeting_room" },
  });
};

const addPrivateOfficeRequest = (data) => {
  return axios({
    method: "POST",
    url: `${AppUrl.RESOURCES}`,
    data: { ...data, type: "private_office" },
  });
};

const addDeskRequest = (data) => {
  return axios({
    method: "POST",
    url: `${AppUrl.RESOURCES}`,
    data: { ...data, type: "desks" },
  });
};

const updateMeetingRoomRequest = (data) => {
  return axios({
    method: "PUT",
    url: `${AppUrl.RESOURCES}/${data?._id}`,
    data,
  });
};

const updatePrivateOfficeRequest = (data) => {
  return axios({
    method: "PUT",
    url: `${AppUrl.RESOURCES}/${data?._id}`,
    data,
  });
};

const updateDeskRequest = (data) => {
  return axios({
    method: "PUT",
    url: `${AppUrl.RESOURCES}/${data?._id}`,
    data,
  });
};

const addItemRequest = (data) => {
  return axios({
    method: "POST",
    url: AppUrl.RESOURCE_ITEMS,
    data,
  });
};

const updateItemRequest = (data) => {
  return axios({
    method: "PUT",
    url: `${AppUrl.RESOURCE_ITEMS}/${data?._id}`,
    data,
  });
};

const deleteItemRequest = (id) => {
  return axios({
    method: "DELETE",
    url: `${AppUrl.RESOURCE_ITEMS}/${id}`,
  });
};

function* getAllResources(action) {
  try {
    let resources = yield call(getAllResourcesRequest, action.payload);
    let _data = resources?.data;
    _data.space_id = action.payload;
    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.FETCH_ALL_WORKSPACE_RESOURCES_FAILURE,
        payload: _data,
      });
    } else {
      yield put({
        type: ACTIONS.FETCH_ALL_WORKSPACE_RESOURCES_SUCCESS,
        payload: { ..._data?.data, space_id: action.payload },
      });
    }
  } catch (error) {
    console.log("fetch workspace error", error);
  }
}

function* getResource(action) {
  try {
    let resources = yield call(getResourceRequest, action.payload);
    let _data = resources?.data;
    _data._id = action.payload?._id;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.FETCH_RESOURCES_FAILURE,
        payload: _data,
      });
    } else {
      yield put({
        type: ACTIONS.FETCH_RESOURCES_SUCCESS,
        payload: _data?.data,
      });
    }
  } catch (error) {
    console.log("fetch workspace error", error);
  }
}

function* addMeetingRoom(action) {
  try {
    let meeting = yield call(addMeetingRoomRequest, action.payload);
    let _data = meeting?.data;
    _data.space_id = action.payload?.space_id;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.ADD_MEETING_FAILURE, payload: _data });
    } else {
      showNotification("success", "Meeting room successfully created");
      yield put({ type: ACTIONS.ADD_MEETING_SUCCESS, payload: _data?.data });
    }
  } catch (error) {
    console.log("add meeting room error", error);
  }
}

function* addPrivateOffice(action) {
  try {
    let office = yield call(addPrivateOfficeRequest, action.payload);
    let _data = office?.data;
    _data.space_id = action.payload?.space_id;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.ADD_PRIVATE_OFFICE_FAILURE,
        payload: _data,
      });
    } else {
      showNotification("success", "Private office successfully created");
      yield put({
        type: ACTIONS.ADD_PRIVATE_OFFICE_SUCCESS,
        payload: _data?.data,
      });
    }
  } catch (error) {
    console.log("add private office error", error);
  }
}

function* addDesk(action) {
  try {
    let desk = yield call(addDeskRequest, action.payload);
    let _data = desk?.data;
    _data.space_id = action.payload?.space_id;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.ADD_DESK_FAILURE, payload: _data });
    } else {
      showNotification("success", "Desk successfully created");
      yield put({ type: ACTIONS.ADD_DESK_SUCCESS, payload: _data?.data });
    }
  } catch (error) {
    console.log("add desk error", error);
  }
}

function* updateMeetingRoom(action) {
  try {
    let _meeting = yield call(updateMeetingRoomRequest, action.payload);
    let _data = _meeting?.data;
    _data._id = action.payload?._id;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.UPDATE_MEETING_ROOM_FAILURE,
        payload: _data,
      });
    } else {
      showNotification("success", "Meeting room updated successfully");
      yield put({
        type: ACTIONS.UPDATE_MEETING_ROOM_SUCCESS,
        payload: _data?.data,
      });
    }
  } catch (error) {
    console.log("update meeting room error", error);
  }
}

function* updatePrivateOffice(action) {
  try {
    let _office = yield call(updatePrivateOfficeRequest, action.payload);
    let _data = _office?.data;
    _data._id = action.payload?._id;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({
        type: ACTIONS.UPDATE_PRIVATE_OFFICE_FAILURE,
        payload: _data,
      });
    } else {
      showNotification("success", "Private office updated successfully");
      yield put({
        type: ACTIONS.UPDATE_PRIVATE_OFFICE_SUCCESS,
        payload: _data?.data,
      });
    }
  } catch (error) {
    console.log("update private office error", error);
  }
}

function* updateDesk(action) {
  try {
    let _desk = yield call(updateDeskRequest, action.payload);
    let _data = _desk?.data;
    _data._id = action.payload?._id;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.UPDATE_DESK_FAILURE, payload: _data });
    } else {
      showNotification("success", "Desk updated successfully");
      yield put({ type: ACTIONS.UPDATE_DESK_SUCCESS, payload: _data?.data });
    }
  } catch (error) {
    console.log("update desk error", error);
  }
}

function* addItem(action) {
  try {
    let _desk = yield call(addItemRequest, action.payload);
    let _data = _desk?.data;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.ADD_ITEM_FAILURE, payload: _data });
    } else {
      yield put({ type: ACTIONS.ADD_ITEM_SUCCESS, payload: _data?.data });
    }
  } catch (error) {
    console.log("add item error", error);
  }
}

function* updateItem(action) {
  try {
    let _desk = yield call(updateItemRequest, action.payload);
    let _data = _desk?.data;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.UPDATE_ITEM_FAILURE, payload: _data });
    } else {
      yield put({ type: ACTIONS.UPDATE_ITEM_SUCCESS, payload: _data?.data });
    }
  } catch (error) {
    console.log("update item error", error);
  }
}

function* deleteItem(action) {
  try {
    let _desk = yield call(deleteItemRequest, action.payload);
    let _data = _desk?.data;

    if (_data?.meta?.success === false || _data?.meta?.status !== 200) {
      showNotification("error", _data?.meta?.message);
      yield put({ type: ACTIONS.DELETE_ITEM_FAILURE, payload: _data });
    } else {
      yield put({ type: ACTIONS.DELETE_ITEM_SUCCESS, payload: _data?.data });
    }
  } catch (error) {
    console.log("delete item error", error);
  }
}

// ACTION WATCHER
export default function* resourcesSaga() {
  // user can logout without waiting for login action.
  yield takeLatest(ACTIONS.FETCH_ALL_WORKSPACE_RESOURCES, getAllResources);
  yield takeLatest(ACTIONS.FETCH_RESOURCES, getResource);
  yield takeLatest(ACTIONS.ADD_MEETING, addMeetingRoom);
  yield takeLatest(ACTIONS.ADD_PRIVATE_OFFICE, addPrivateOffice);
  yield takeLatest(ACTIONS.ADD_DESK, addDesk);
  yield takeLatest(ACTIONS.UPDATE_MEETING_ROOM, updateMeetingRoom);
  yield takeLatest(ACTIONS.UPDATE_PRIVATE_OFFICE, updatePrivateOffice);
  yield takeLatest(ACTIONS.UPDATE_DESK, updateDesk);
  yield takeLatest(ACTIONS.ADD_ITEM, addItem);
  yield takeLatest(ACTIONS.UPDATE_ITEM, updateItem);
  yield takeLatest(ACTIONS.DELETE_ITEM, deleteItem);
}
