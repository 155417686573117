import moment from "moment";
import { Avatar, Col, Row, Tag } from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
  PoundOutlined,
} from "@ant-design/icons";
import { getAvatarColor, getNameInitials } from "../../utils/CommonFunctions";

export const bookingRequestColumns = (editable, updateRequest) => {
  const info = (obj) => (
    <InfoCircleOutlined onClick={() => updateRequest(obj)} />
  );
  const edit = (obj) => <EditOutlined onClick={() => updateRequest(obj)} />;
  const columns = [
    {
      title: "S.no",
      dataIndex: "sn",
      key: "sn",
      width: 10,
      align: "center",
    },
    {
      title: "User",
      dataIndex: "user",
      render: (user) => (
        <Row justify="start" align="middle">
          <Avatar
            size={"default"}
            shape="circle"
            style={{ backgroundColor: getAvatarColor(user?.[2]) }}
          >
            {getNameInitials(user?.[0])}
          </Avatar>
          <span className="ml15">
            <h4 className="nm">{user?.[0]}</h4>
            <span>
              <small>{user?.[1]}</small>
            </span>
          </span>
        </Row>
      ),
    },
    {
      title: "Resource",
      dataIndex: "resource",
      key: "resource",
      onCell: () => {
        return {
          style: {
            maxWidth: 175,
          },
        };
      },
      render: (resource) => (
        <div>
          <h4 className="nm">{resource[2]}</h4>
          <small>
            <span
              className="mr10"
              style={{
                width: 8,
                height: 8,
                display: "inline-block",
                backgroundColor: resource[1] || "grey",
                borderRadius: "50%",
              }}
            ></span>

            <span>{resource[0]}</span>
          </small>
        </div>
      ),
    },
    {
      title: "Event Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return (
          <Row justify="start" align="middle">
            <Col
              className="--avatar green mr15 circle"
              justify="center"
              align="middle"
            >
              <CalendarOutlined />
            </Col>
            <Col>
              <h4 className="nm">
                {moment(date[0]).format("ddd, MMMM Do, YYYY")}
              </h4>
              <small>
                {moment(date[0]).format("h:mm a")}
                <span style={{ margin: "0 5px", fontSize: 13 }}>to</span>
                {moment(date[1]).format("h:mm a")}
              </small>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Price (£)",
      dataIndex: "price",
      key: "price",
      onCell: () => {
        return {
          style: {
            maxWidth: 100,
          },
        };
      },
      render: (price) => {
        return (
          <Row justify="start" align="middle" className="mt5">
            <Col
              className="--avatar orange mr15 circle"
              justify="center"
              align="middle"
            >
              <PoundOutlined style={{ fontSize: 18 }} />
            </Col>
            <Col>
              <h4 className="nm">£ {parseFloat(price[0] || 0).toFixed(2)}</h4>
              <small>Booked for {parseFloat(price[1])} hour</small>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => {
        switch (status) {
          case "Approved":
            return <Tag color={"green"}>APPROVED</Tag>;
          case "Rejected":
            return <Tag color={"red"}>REJECTED</Tag>;
          case "cancelled":
            return <Tag color={"red"}>CANCELLED</Tag>;

          default:
            return <Tag color={"orange"}>PENDING</Tag>;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      render: (obj) =>
        obj?.booking_status === "Assigned" && editable
          ? edit(obj?._id)
          : info(obj?._id),
    },
  ];
  return columns;
};
