import React from "react";
import { Menu, Row } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  InfoCircleOutlined,
  PictureOutlined,
  SlidersOutlined,
  PicCenterOutlined,
  FileOutlined,
  QuestionCircleOutlined,
  PercentageOutlined,
  TeamOutlined,
  HomeOutlined,
  LayoutOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

import Routes from "../../config/Routes";

const CmsSideMenu = (props) => {
  const user = useSelector((state) => state?.user?.loggedInUser);
  const { settings } = user?.role_id?.permissions || {};

  return (
    <Menu
      style={props.style}
      theme="dark"
      mode="inline"
      selectedKeys={props.selectedKeys}
    >
      <Menu.Item key={Routes.slider} icon={<SlidersOutlined />}>
        <Link to={Routes.slider}>
          <Row justify="space-between" align="middle">
            <span>Slider</span>
          </Row>
        </Link>
      </Menu.Item>
      <Menu.Item key={Routes.homePage} icon={<HomeOutlined />}>
        <Link to={Routes.homePage}>
          <Row justify="space-between" align="middle">
            <span>Home Page</span>
          </Row>
        </Link>
      </Menu.Item>

      <Menu.Item key={Routes.officeList} icon={<LayoutOutlined />}>
        <Link to={Routes.officeList}>
          <Row justify="space-between" align="middle">
            <span>Office Listing Page</span>
          </Row>
        </Link>
      </Menu.Item>

      <Menu.SubMenu
        key="service-page"
        icon={<LayoutOutlined />}
        title="Service Page"
      >
        {[
          { title: "Meeting Room", route: Routes.meetingRoomPage },
          { title: "Private Office", route: Routes.privateOfficePage },
          { title: "Dedicated Desk", route: Routes.dedicatedDeskPage },
          { title: "Flexi Desk", route: Routes.flexiDeskPage },
        ].map((item) => (
          <Menu.Item key={item.route}>
            <Link to={item.route}>
              <Row justify="space-between" align="middle">
                <span>{item.title}</span>
              </Row>
            </Link>
          </Menu.Item>
        ))}
      </Menu.SubMenu>

      <Menu.Item key={Routes.imageGallery} icon={<PictureOutlined />}>
        <Link to={Routes.imageGallery}>
          <Row justify="space-between" align="middle">
            <span>Image Gallery</span>
          </Row>
        </Link>
      </Menu.Item>

      <Menu.Item key={Routes.knowMore} icon={<InfoCircleOutlined />}>
        <Link to={Routes.knowMore}>
          <Row justify="space-between" align="middle">
            <span>Know More</span>
          </Row>
        </Link>
      </Menu.Item>

      <Menu.SubMenu
        key="availability"
        icon={<LayoutOutlined />}
        title="Availability"
      >
        <Menu.Item key={Routes.ourSpace}>
          <Link to={Routes.ourSpace}>
            <Row justify="space-between" align="middle">
              <span>Our Spaces</span>
            </Row>
          </Link>
        </Menu.Item>

        <Menu.Item key={Routes.availabilityPage}>
          <Link to={Routes.availabilityPage}>
            <Row justify="space-between" align="middle">
              <span>Availability Page</span>
            </Row>
          </Link>
        </Menu.Item>
      </Menu.SubMenu>

      <Menu.SubMenu
        key="offer-page"
        icon={<PercentageOutlined />}
        title="Offer"
      >
        <Menu.Item key={Routes.offerPage}>
          <Link to={Routes.offerPage}>
            <Row justify="space-between" align="middle">
              <span>Offers</span>
            </Row>
          </Link>
        </Menu.Item>

        <Menu.Item key={Routes.foldableOfferPage}>
          <Link to={Routes.foldableOfferPage}>
            <Row justify="space-between" align="middle">
              <span>Offer Page</span>
            </Row>
          </Link>
        </Menu.Item>
      </Menu.SubMenu>

      <Menu.Item key={Routes.testimonial} icon={<TeamOutlined />}>
        <Link to={Routes.testimonial}>
          <Row justify="space-between" align="middle">
            <span>Testimonial</span>
          </Row>
        </Link>
      </Menu.Item>

      <Menu.Item key={Routes.aboutUs} icon={<InfoCircleOutlined />}>
        <Link to={Routes.aboutUs}>
          <Row justify="space-between" align="middle">
            <span>About Us</span>
          </Row>
        </Link>
      </Menu.Item>

      <Menu.Item key={Routes.otherPage} icon={<FileOutlined />}>
        <Link to={Routes.otherPage}>
          <Row justify="space-between" align="middle">
            <span>Other Pages</span>
          </Row>
        </Link>
      </Menu.Item>

      <Menu.Item key={Routes.faqPage} icon={<QuestionCircleOutlined />}>
        <Link to={Routes.faqPage}>
          <Row justify="space-between" align="middle">
            <span>FAQs</span>
          </Row>
        </Link>
      </Menu.Item>

      {settings?.workspace?.show && (
        <Menu.Item key={Routes.workspace} icon={<PicCenterOutlined />}>
          <Link to={Routes.workspace}>
            <Row justify="space-between" align="middle">
              <span>Workspaces</span>
            </Row>
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default React.memo(CmsSideMenu);
